<mat-tab-group style="margin-bottom: 10px;" [selectedIndex]="mes-auxMes" scrollDistance="300px" (selectedTabChange)="fnChangeMonthHistoric($event['tab']['textLabel'])">
    <mat-tab label="{{getSingleTextLabel('5013')}} {{auxMes+(i)}}" *ngFor="let item of numbers; let i = index;" [disabled]="(i+auxMes) > mes || isfinished"></mat-tab>
</mat-tab-group>
<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <div class="calibriFont style1" style="    display: flex;justify-content: space-between;">
        <p>{{getSingleTextLabel('7002.1')}}</p>
        <!-- <button [ngClass]="{'grow': !isLocked}" (click)="lockCff()" *ngIf="isFacilitator" class="btn btn2 btn-primary">
            Lock CFF
        </button> -->
    </div>
    <div *ngIf="!isfinished; else finishWS" class="calibriFont style2b">
        <p style="text-align: center;">{{getSingleTextLabel('5013')}} {{monthHistoric}}</p>
    </div>
    <ng-template #finishWS>
        <div class="calibriFont style2b">
            <p style="text-align: center;">{{getSingleTextLabel('5013')}} {{monthHistoric-1}}</p>
        </div>
    </ng-template>
    <div class="row" class="marginwhite">
        <div class="grid-container">
            <div *ngIf="!isfinished; else finishWS2" class="marginblack style12 calibriFont colorFontBlue" style="width: 315px; text-align: center;">
                <span> {{getSingleTextLabel('5013')}} {{monthHistoric}} </span>
            </div>
            <ng-template #finishWS2>
                <div class="marginblack style12 calibriFont colorFontBlue" style="width: 315px; text-align: center;">
                    <span> {{getSingleTextLabel('5013')}} {{monthHistoric-1}} </span>
                </div>
            </ng-template>
            <div class="grid-container">
                <div>
                    <div class=" marginLeft style3 calibriFont" style="text-align: center; border: 1px solid;
                    border-bottom: 0px;
                    border-left: 0px;"><span>{{getSingleTextLabel('5035')}} 1</span></div>
                    <div class="grid-container">
                        <div class=" marginLeft marginblack style3 calibriFont" style="width: 60px; text-align: center;">
                            <strong>{{getSingleTextLabel('5036')}}</strong>
                        </div>
                        <div class=" marginLeft marginblack style3 calibriFont" style="width: 60px; text-align: center;">
                            <strong>{{getSingleTextLabel('47')}} {{getSingleTextLabel('5037')}}</strong>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="grid-container">
                <div>
                    <div class=" marginLeft style3 calibriFont" style="text-align: center; border: 1px solid;
                    border-bottom: 0px;
                    border-left: 0px;"><span>{{getSingleTextLabel('5035')}} 2</span></div>
                    <div class="grid-container">
                        <div class=" marginLeft marginblack style3 calibriFont" style="width: 60px; text-align: center;">
                            <strong>{{getSingleTextLabel('5036')}}</strong>
                        </div>
                        <div class=" marginLeft marginblack style3 calibriFont" style="width: 60px; text-align: center;">
                            <strong>{{getSingleTextLabel('47')}} {{getSingleTextLabel('5037')}}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" class="marginwhite2">
        <div class="grid-container">
            <div class="marginRigth colorblue style2aBold calibriFont textoazul">{{getSingleTextLabel('5038')}} {{getSingleTextLabel('47')}}</div>
            <div class="textoblanco"></div>
            <div class="margin4 textoInput">
                <input (keyup.enter)="nextInputExm1.focus()" type="number" placeholder = "0"  id="input100" matInput class="w style5bBold calibriFont" [(ngModel)]="datosFacilitador.startCash"
                    [disabled] = "isLocked" (change)="updateInputExample('startCash', 0)"
                     min="0">
                
            </div>
            <div class="margin4 textoInput">
                <input type="number" placeholder = "0"  [(ngModel)]="datosFacilitador.startCash" id="input101" matInput class="wblueSinBorde style6c calibriFont" readonly
                     min="0">
                
            </div>
            <div class="margin4 textoInput">
                <input (keyup.enter)="nextInput1.focus()" type="number" placeholder = "0"  id="input100" matInput class="w style5bBold calibriFont" [(ngModel)]="datos.startCash"
                    (change)="updateInput('startCash', 0)"
                     min="0">
                
            </div>
            <div class="margin4 textoInput">
                <input type="number" placeholder = "0"  [(ngModel)]="datos.startCash" id="input101" matInput class="wblueSinBorde style6c calibriFont" readonly
                     min="0">
                
            </div>
        </div>
    </div>
    

    <div class="row" class="marginwhite2">
        <div class="grid-container">
            <div class="marginRigth colorblue textoazul style2a calibriFont"> {{getSingleTextLabel('5039')}}</div>
            <div class="textoblanco" style="display: inline-flex;">
                <div style="width: 90%;"></div>
            </div>
            <div class="margin4 textoInput">
                <input (keyup.enter)="nextInputExm2.focus()" #nextInputExm1 type="number" placeholder = "0" id="input200" matInput class="w style5b calibriFont" [(ngModel)]="datosFacilitador.daysReceivables"
                    [disabled] = "isLocked" (change)="updateInputExample('daysReceivables', 1)"
                     min="0">
                
            </div>
            <div class="margin4 textoInput">
                <input type="number" placeholder = "0" id="input201" matInput class="wblueSinBorde style6c calibriFont" readonly [(ngModel)]="datosFacilitador.result1"
                     min="0">
                
            </div>
            <div class="margin4 textoInput">
                <input (keyup.enter)="nextInput2.focus()"  #nextInput1 type="number" placeholder = "0" id="input200" matInput class="w style5b calibriFont" [(ngModel)]="datos.daysReceivables"
                    (change)="updateInput('daysReceivables', 1)"
                     min="0">
                
            </div>
            <div class="margin4 textoInput">
                <input type="number" placeholder = "0" id="input201" matInput class="wblueSinBorde style6c calibriFont" readonly [(ngModel)]="datos.result1"
                     min="0">
                
            </div>
        </div>
    </div>
    

    <div class="row" class="marginwhite2">
        <div class="grid-container">
            <div class="marginRigth colorblue textoazul style2a calibriFont">  {{getSingleTextLabel('5040')}}</div>
            <div class="textoblanco" style="display: inline-flex;">
                <div style="width: 90%;"></div>
            </div>
            <div class="margin5 textoInput2 wblue">
                <input (keyup.enter)="nextInputExm3.focus()" #nextInputExm2 type="number" placeholder = "0" id="input300" matInput class="w style5b calibriFont" [(ngModel)]="datosFacilitador.daysLoansPayables"
                    [disabled] = "isLocked" (change)="updateInputExample('daysLoansPayables', 2)">
                
            </div>
            <div class="margin5 textoInput2 wblue">
                <input type="number" placeholder = "0" id="input301" matInput class="wblueSinBorde style6c calibriFont" readonly [(ngModel)]="datosFacilitador.result2"
                    [ngStyle]="{'color': datosFacilitador.result2 < 0 ? 'red':'black'}">
                
            </div>
            <div class="margin5 textoInput2 wblue">
                <input  (keyup.enter)="nextInput3.focus()" #nextInput2 type="number" placeholder = "0" id="input300" matInput class="w style5b calibriFont" [(ngModel)]="datos.daysLoansPayables"
                    (change)="updateInput('daysLoansPayables', 2)">
                
            </div>
            <div class="margin5 textoInput2 wblue">
                <input type="number" placeholder = "0" id="input301" matInput class="wblueSinBorde style6c calibriFont" readonly [(ngModel)]="datos.result2"
                    [ngStyle]="{'color': datos.result2 < 0 ? 'red':'black'}">
                
            </div>
        </div>
    </div>
    

    <div class="row" class="marginwhite2">
        <div class="grid-container">
            <div class="marginRigth colorblue textoazul style2a calibriFont">  {{getSingleTextLabel('5041')}}</div>
            <div class="textoblanco" style="display: inline-flex;">
                <div style="width: 90%;"></div>
            </div>
            <div class="margin4 textoInput">
                <input (keyup.enter)="nextInputExm4.focus()" #nextInputExm3 type="number" placeholder = "0" id="input400" matInput [(ngModel)]="datosFacilitador.upgrade" 
                    class="w style5b calibriFont"  min="0"
                    [disabled] = "isLocked" (change)="updateInputExample('upgrade', 3)">
                
            </div>
            <div class="margin4 textoInput">
                <input type="number" placeholder = "0" id="input401" matInput class="wblueSinBorde style6c calibriFont" readonly [(ngModel)]="datosFacilitador.result3"
                    [ngStyle]="{'color': datosFacilitador.result3 < 0 ? 'red':'black'}" 
                    min="0">
                
            </div>
            <div class="margin4 textoInput">
                <input (keyup.enter)="nextInput4.focus()" #nextInput3 type="number" placeholder = "0" id="input400" matInput [(ngModel)]="datos.upgrade"
                    class="w style5b calibriFont"  min="0"
                    (change)="updateInput('upgrade', 3)">
                
            </div>
            <div class="margin4 textoInput">
                <input type="number" placeholder = "0" id="input401" matInput class="wblueSinBorde style6c calibriFont" readonly [(ngModel)]="datos.result3"
                    [ngStyle]="{'color': datos.result3 < 0 ? 'red':'black'}" 
                    min="0">
                
            </div>
        </div>
    </div>
    

    <div class="row" class="marginwhite2">
        <div class="grid-container">
            <div class="marginRigth colorblue textoazul style2a calibriFont">  {{getSingleTextLabel('33')}}</div>
            <div class="textoblanco" style="display: inline-flex;">
                <div style="width: 90%;"></div>
            </div>
            <div class="margin5 textoInput2 ">
                <input (keyup.enter)="nextInputExm5.focus()" #nextInputExm4 type="number" placeholder = "0" id="input500" matInput class="w style5b calibriFont" [(ngModel)]="datosFacilitador.recruitment"
                    [disabled] = "isLocked" (change)="updateInputExample('recruitment', 4)">
                
            </div>
            <div class="margin5 textoInput2">
                <input type="number" placeholder = "0" id="input501" matInput class="wblueSinBorde style6c calibriFont" readonly [(ngModel)]="datosFacilitador.result4"
                    [ngStyle]="{'color': datosFacilitador.result4 < 0 ? 'red':'black'}">
                
            </div>
            <div class="margin5 textoInput2">
                <input (keyup.enter)="nextInput5.focus()" #nextInput4 type="number" placeholder = "0" id="input500" matInput class="w style5b calibriFont" [(ngModel)]="datos.recruitment"
                    (change)="updateInput('recruitment', 4)">
                
            </div>
            <div class="margin5 textoInput2">
                <input type="number" placeholder = "0" id="input501" matInput class="wblueSinBorde style6c calibriFont" readonly [(ngModel)]="datos.result4"
                    [ngStyle]="{'color': datos.result4 < 0 ? 'red':'black'}">
                
            </div>
        </div>
    </div>
    

    <div class="row" class="marginwhite2">
        <div class="grid-container">
            <div class="marginRigth colorblue textoazul style2a calibriFont">  {{ businessModel == 'Mfg EBIT' ? getSingleTextLabel('5042') : getSingleTextLabel('4011.1')}}</div>
            <div class="textoblanco" style="display: inline-flex;">
                <div style="width: 90%;"></div>
            </div>
            <div class="margin4 textoInput">
                <input (keyup.enter)="nextInputExm6.focus()" #nextInputExm5 type="number" placeholder = "0" id="input600" matInput class="w style5b calibriFont" [(ngModel)]="datosFacilitador.rawMaterials"
                     min="0"
                    [disabled] = "isLocked" (change)="updateInputExample('rawMaterials', 5)">
                
            </div>
            <div class="margin4 textoInput">
                <input type="number" placeholder = "0" id="input601" matInput class="wblueSinBorde style6c calibriFont" readonly [(ngModel)]="datosFacilitador.result5"
                    [ngStyle]="{'color': datosFacilitador.result5 < 0 ? 'red':'black'}" 
                    min="0">
                
            </div>
            <div class="margin4 textoInput">
                <input (keyup.enter)="nextInput6.focus()" #nextInput5 type="number" placeholder = "0" id="input600" matInput class="w style5b calibriFont" [(ngModel)]="datos.rawMaterials"
                     min="0"
                    (change)="updateInput('rawMaterials', 5)">
                
            </div>
            <div class="margin4 textoInput">
                <input type="number" placeholder = "0" id="input601" matInput class="wblueSinBorde style6c calibriFont" readonly [(ngModel)]="datos.result5"
                    [ngStyle]="{'color': datos.result5 < 0 ? 'red':'black'}" 
                    min="0">
                
            </div>
        </div>
    </div>
    

    <div class="row" class="marginwhite2">
        <div class="grid-container">
            <div class="marginRigth colorblue textoazul style2a calibriFont">  {{getSingleTextLabel('5043')}}</div>
            <div class="textoblanco" style="display: inline-flex;">
                <div style="width: 90%;"></div>
            </div>
            <div class="margin5 textoInput2 wblue">
                <input (keyup.enter)="nextInputExm7.focus()" #nextInputExm6 type="number" placeholder = "0" id="input700" matInput class="w style5b calibriFont" [(ngModel)]="datosFacilitador.labour"
                    [disabled] = "isLocked" (change)="updateInputExample('labour', 6)">
                
            </div>
            <div class="margin5 textoInput2 wblue">
                <input type="number" placeholder = "0" id="input701" matInput class="wblueSinBorde style6c calibriFont" readonly [(ngModel)]="datosFacilitador.result6"
                    [ngStyle]="{'color': datosFacilitador.result6 < 0 ? 'red':'black'}">
                
            </div>
            <div class="margin5 textoInput2 wblue">
                <input (keyup.enter)="nextInput7.focus()" #nextInput6 type="number" placeholder = "0" id="input700" matInput class="w style5b calibriFont" [(ngModel)]="datos.labour"
                    (change)="updateInput('labour', 6)">
                
            </div>
            <div class="margin5 textoInput2 wblue">
                <input type="number" placeholder = "0" id="input701" matInput class="wblueSinBorde style6c calibriFont" readonly [(ngModel)]="datos.result6"
                    [ngStyle]="{'color': datos.result6 < 0 ? 'red':'black'}">
                
            </div>
        </div>
    </div>
    

    <div class="row" class="marginwhite2">
        <div class="grid-container">
            <div class="marginRigth colorblue textoazul style2a calibriFont">  {{getSingleTextLabel('5044')}}</div>
            <div class="textoblanco" style="display: inline-flex;">
                <div style="width: 90%;"></div>
            </div>
            <div class="margin4 textoInput">
                <input (keyup.enter)="nextInputExm8.focus()" #nextInputExm7 type="number" placeholder = "0" id="input800" matInput class="w style5b calibriFont" [(ngModel)]="datosFacilitador.overheads"
                    [disabled] = "isLocked" (change)="updateInputExample('overheads', 7)"  min="0">
                
            </div>
            <div class="margin4 textoInput">
                <input type="number" placeholder = "0" id="input801" matInput class="wblueSinBorde style6c calibriFont" readonly [(ngModel)]="datosFacilitador.result7"
                    [ngStyle]="{'color': datosFacilitador.result7 < 0 ? 'red':'black'}" 
                    min="0">
                
            </div>
            <div class="margin4 textoInput">
                <input (keyup.enter)="nextInput8.focus()" #nextInput7 type="number" placeholder = "0" id="input800" matInput class="w style5b calibriFont" [(ngModel)]="datos.overheads"
                    (change)="updateInput('overheads', 7)"  min="0">
                
            </div>
            <div class="margin4 textoInput">
                <input type="number" placeholder = "0" id="input801" matInput class="wblueSinBorde style6c calibriFont" readonly [(ngModel)]="datos.result7"
                    [ngStyle]="{'color': datos.result7 < 0 ? 'red':'black'}" 
                    min="0">
                
            </div>
        </div>
    </div>
    

    <div class="row" class="marginwhite2">
        <div class="grid-container">
            <div class="marginRigth colorblue textoazul style2a calibriFont">  {{getSingleTextLabel('30.1')}}</div>
            <div class="textoblanco" style="display: inline-flex;">
                <div style="width: 90%;"></div>
            </div>
            <div class="margin5 textoInput2 wblue">
                <input (keyup.enter)="nextInputExm9.focus()" #nextInputExm8 type="number" placeholder = "0" id="input900" matInput class="w style5b calibriFont" [(ngModel)]="datosFacilitador.advertising"
                [disabled] = "isLocked" (change)="updateInputExample('advertising', 8)">
            </div>
            <div class="margin5 textoInput2 wblue">
                <input type="number" placeholder = "0" id="input901" matInput class="wblueSinBorde style6c calibriFont" readonly [(ngModel)]="datosFacilitador.result8"
                    [ngStyle]="{'color': datosFacilitador.result8 < 0 ? 'red':'black'}">
            </div>
            <div class="margin5 textoInput2 wblue">
                <input (keyup.enter)="nextInput9.focus()" #nextInput8 type="number" placeholder = "0" id="input900" matInput class="w style5b calibriFont" [(ngModel)]="datos.advertising"
                (change)="updateInput('advertising', 8)">
            </div>
            <div class="margin5 textoInput2 wblue">
                <input type="number" placeholder = "0" id="input901" matInput class="wblueSinBorde style6c calibriFont" readonly [(ngModel)]="datos.result8"
                    [ngStyle]="{'color': datos.result8 < 0 ? 'red':'black'}">
            </div>
        </div>
    </div>

    

    <div class="row" class="marginwhite2">
        <div class="grid-container">
            <div class="marginRigth colorblue textoazul style2a calibriFont">{{getSingleTextLabel('47')}} {{getSingleTextLabel('15')}}</div>
            
            <div class="textoblanco" style="display: inline-flex;">
                <div style="width: 90%;"></div>
            </div>
            <div class="margin4 textoInput">
                <input  #nextInputExm9 type="number" placeholder = "0" id="input1000" matInput class="w style5b calibriFont" [(ngModel)]="datosFacilitador.cashSales"
                     min="0"
                    [disabled] = "isLocked" (change)="updateInputExample('cashSales', 9)">
                
            </div>
            <div class="margin4 textoInput">
                <input type="number" placeholder = "0" id="input1001" matInput class="wblueSinBorde style6c calibriFont" readonly [(ngModel)]="datosFacilitador.result9"
                    [ngStyle]="{'color': datosFacilitador.result9 < 0 ? 'red':'black'}" 
                    min="0">
                
            </div>
            <div class="margin4 textoInput">
                <input  #nextInput9 type="number" placeholder = "0" id="input1000" matInput class="w style5b calibriFont" [(ngModel)]="datos.cashSales"
                     min="0"
                    (change)="updateInput('cashSales', 9)">
                
            </div>
            <div class="margin4 textoInput">
                <input type="number" placeholder = "0" id="input1001" matInput class="wblueSinBorde style6c calibriFont" readonly [(ngModel)]="datos.result9"
                    [ngStyle]="{'color': datos.result9 < 0 ? 'red':'black'}" 
                    min="0">
                
            </div>
        </div>
    </div>
    

    <div class="row" class="marginwhite2">
        <div class="grid-container">
            <div class="marginRigth colorblue textoazul style2aBold text-right calibriFont">{{getSingleTextLabel('5045')}}</div>
            <div class="textoblanco textRigth">
                <strong style="display: inline-flex;">
                    
                    <span class="equal" (click)="clickEqual('cashSales', 90)"
                        style="text-align: right; height: 1px; font-size: 20px; cursor: pointer; width: 100%;">&#61;</span>
                </strong>
            </div>
            <div class="margin5 textoInput2 wblue">
                <!-- <input type="number" placeholder = "0" id="input1100" matInput class="w style5b calibriFont"
            >
            <div *ngIf="i != mes">
                {{jsnTeam['netIncome']}}
            </div>  -->
            </div>
            <div class="margin5 textoInput2 wblue">
                <input type="number" placeholder = "0" id="input1101" matInput class="wblueSinBorde style7c calibriFont" readonly [(ngModel)]="datosFacilitador.result10"
                    [ngStyle]="{'color': datosFacilitador.result10 < 0 ? 'red':'black'}">
                
            </div>
            <div class="margin5 textoInput2 wblue">
                <!-- <input type="number" placeholder = "0" id="input1100" matInput class="w style5b calibriFont"
            >
            <div *ngIf="i != mes">
                {{jsnTeam['netIncome']}}
            </div>  -->
            </div>
            <div class="margin5 textoInput2 wblue">
                <input type="number" placeholder = "0" id="input1101" matInput class="wblueSinBorde style7c calibriFont" readonly [(ngModel)]="datos.result10"
                    [ngStyle]="{'color': datos.result10 < 0 ? 'red':'black'}">
                
            </div>
        </div>
    </div>
    <div class="style2b calibriFont" style="padding: 10px;
    margin-top: 10px;">
        <p style="margin: 0px;">{{getSingleTextLabel('5046')}}</p>
    </div>
    <div mat-dialog-actions style="text-align: center; display: block !important">  
        <button class="btn btn-secondary mx-3 FontMontserratTextInputsCancel calibriFont" (click)="fnCloseModal()">&nbsp;{{getSingleTextLabel('135')}}&nbsp;</button>
    </div>
</div>