<div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="FontMontserratSubTitles" style="width: 100%;">
    <div style="display: inline-block; *display: inline; zoom: 1; vertical-align: top;width: 70%;">{{teamName}} -  <input style="font-size:small; width: 100px;text-align: left;border:none" type="text"  placeholder="your name here"  id="txtAvatar" value="{{userName}}" (focusout)="focusOutFunctionAvatar()"/></div>
    <div class="form-group" style="display: inline-block; *display: inline; zoom: 1; vertical-align: top;width: 30%; text-align: right !important;">
        <label title="Avatar" style="top: -70px;font-size: 12px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Profile</label>
        <div class="row"><br></div>
        <div class="row" style="text-align: right !important;position: relative !important;left: 40px;">
             <label class="filebutton">
                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="fas fa-cloud-upload-alt fa-lg" style="cursor: pointer !important;"></i>
                 <span>
                     <input id="myfile" name="myfile" class="form-control" (change)="onFileChange($event)" type="file" accept="image/png, image/jpeg">
                 </span>
             </label>
             <label>{{lblImgLogo}}</label>
            
        </div>

        

       
        <!--<div *ngIf="blnEditar && dependencia">
             <a *ngIf="dependencia['urlLogo']" href="{{dependencia['urlLogo']}}" target="_blank" rel="">{{dependencia['logo']?dependencia['logo']:''}}</a>
         </div>-->
     </div>

</div>

<section class="chatbox">
    <section class="chat-window">
        <div *ngFor="let itemChat of lstGroupChat; let i = index">
        
        <article class="msg-container msg-self" id="msg-0"  *ngIf="itemChat.UserId == userId" >
            <div class="msg-box">
                <div class="flr">
                    <div class="messages">
                        <p class="msg" id="msg-0">
                            {{itemChat.message}}
                        </p>
                    </div>
                    <span class="timestamp"><span class="username">{{itemChat.avatar}}</span>&bull;<span class="posttime">{{ itemChat.dateMessage | date:'medium'}}</span></span>
                </div>
                <img class="user-img" id="user-0" src="https://{{Iphost}}{{itemChat.UserId}}.png" />
            </div>  
        </article>
        <article class="msg-container msg-remote" id="msg-0" *ngIf="itemChat.UserId != userId">
            <div class="msg-box">
                <img class="user-img" id="user-0" src="https://{{Iphost}}{{itemChat.UserId}}.png" />
                <div class="flr">
                    <div class="messages">
                        <p class="msg" id="msg-0">
                            {{itemChat.message}}
                        </p>
                    </div>
                    <span class="timestamp"><span class="username">{{itemChat.avatar}}</span>&bull;<span class="posttime">{{itemChat.dateMessage | date:'medium'}}</span></span>
                </div>
            </div>  
        </article>
    </div>
        <!--<article class="msg-container msg-remote" id="msg-0"  [ngClass]="{'msg-remote': itemChat.isFaciltator == 1, 'msg-self': itemChat.isFaciltator == 0}" >
            <div class="msg-box">
                <img class="user-img" id="user-0" src="//gravatar.com/avatar/00034587632094500000000000000000?d=retro" />
                <div class="flr">
                    <div class="messages">
                        <p class="msg" id="msg-0">
                            Orale cabrones ya llenen el piche IncomeStatement
                        </p>
                    </div>
                    <span class="timestamp"><span class="username">Facilitator</span>&bull;<span class="posttime">3 minutes ago</span></span>
                </div>
            </div>  
        </article>
        <article class="msg-container msg-self" id="msg-0">
            <div class="msg-box"> 
                <div class="flr">
                    <div class="messages">
                        <p class="msg" id="msg-1">
                            Aguante facilitador esque la pinche vaca no deja
                        </p>
                        <p class="msg" id="msg-2">
                            xdxdxdxd
                        </p>
                    </div>
                    <span class="timestamp"><span class="username">beto</span>&bull;<span class="posttime">2 minutes ago</span></span>
                </div>
                <img class="user-img" id="user-0" src="//gravatar.com/avatar/56234674574535734573000000000001?d=retro" />
            </div>
        </article>
        <article class="msg-container msg-remote" id="msg-0">
            <div class="msg-box">
                <img class="user-img" id="user-0" src="//gravatar.com/avatar/002464562345234523523568978962?d=retro" />
                <div class="flr">
                    <div class="messages">
                        <p class="msg" id="msg-0">
                            En la madre, dejen voy a cagar!!
                        </p>
                    </div>
                    <span class="timestamp"><span class="username">Dani</span>&bull;<span class="posttime">1 minute ago</span></span>
                </div>
            </div>
        </article>-->
    
        
    </section>
    <form class="chat-input" onsubmit="return false;">
        <input type="text" autocomplete="on" placeholder="Type a message"  id="txtMessage"/>
        <button (click)="fnSendMessage()">
                <svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="rgba(0,0,0,.38)" d="M17,12L12,17V14H8V10H12V7L17,12M21,16.5C21,16.88 20.79,17.21 20.47,17.38L12.57,21.82C12.41,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L3.53,17.38C3.21,17.21 3,16.88 3,16.5V7.5C3,7.12 3.21,6.79 3.53,6.62L11.43,2.18C11.59,2.06 11.79,2 12,2C12.21,2 12.41,2.06 12.57,2.18L20.47,6.62C20.79,6.79 21,7.12 21,7.5V16.5M12,4.15L5,8.09V15.91L12,19.85L19,15.91V8.09L12,4.15Z" /></svg>
        </button>
    </form>
</section>
<br>
<mat-radio-group aria-label="Select an option" [(ngModel)]="selectedChat" >
    <mat-radio-button  *ngIf="isFacilitator == 1" value="5" (change)="fnCheckChat($event)">&nbsp;Some Team&nbsp;&nbsp;</mat-radio-button>
    <mat-radio-button  *ngIf="isFacilitator == 0" value="1" (change)="fnCheckChat($event)">&nbsp;Team&nbsp;&nbsp;</mat-radio-button>
    <mat-radio-button *ngIf="isFacilitator == 0" value="2" (change)="fnCheckChat($event)">&nbsp;Team + Facil.&nbsp;&nbsp;</mat-radio-button>
    <mat-radio-button value="3" (change)="fnCheckChat($event)">&nbsp;Group&nbsp;&nbsp;</mat-radio-button>
    <mat-radio-button *ngIf="isFacilitator == 1" value="4" (change)="fnCheckChat($event)">&nbsp;Special</mat-radio-button>
  </mat-radio-group>
<br><hr>
<!--
    <ng-container *ngIf="blnShowTeams">
    <ng-multiselect-dropdown [settings]="dropDownSettingslstTms" [data]="lstTeams" class="ddlSelect"
    name="lstTms" 
    [placeholder]="lblplaceholderlsTeam" (onSelect)="fnChangeTeamddl($event)"></ng-multiselect-dropdown>

</ng-container >
-->

<mat-form-field appearance="fill" *ngIf="blnShowTeams">
    <mat-label>Select team</mat-label>
    <mat-select >
      <mat-option *ngFor="let team of lstTeams" [value]="team.TeamId" (click)="selectTeamtosend2(team.TeamId)">
        {{team.Avatar}}
      </mat-option>
    </mat-select>
  </mat-form-field>
    <!--<mat-radio-group *ngIf="blnShowTeams" 
    aria-labelledby="example-radio-group-label"
    class="example-radio-group"
    >
    
    <mat-radio-button  name="fieldName" ngDefaultControl [(ngModel)]="selectedteam" class="example-radio-button" *ngFor="let team of lstTeams" [value]="team.TeamId" 
        (change)="fnCheckChatbyTeamId($event)">
        {{team.Avatar}}&nbsp;&nbsp;
        </mat-radio-button>&nbsp;&nbsp;<br>
    </mat-radio-group>-->