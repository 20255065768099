<div class="main">
    <div style="text-align: center;">
        <p class="style2a calibriFont" style="margin-bottom: 0px;">{{getSingleTextLabel('142')}}</p>
    </div>

    <div style="text-align: center;">
        <input matInput type="number" autocomplete="off" class="w field" [(ngModel)]="data" (keyup)="entra($event)" onkeypress="return event.charCode >= 48" min="0">
    </div>
</div>
<!--
<div style="text-align: center; padding: 0%; margin-top: 2px;">
    <button class="btn btn-primary mx-3 styleButtonOK" type="submit" (click)="fnSave()">&nbsp;&nbsp;&nbsp;Ok&nbsp;&nbsp;&nbsp;</button>
    <button class="btn btn-secondary mx-3 FontMontserratTextInputsCancel" (click)="fnCancel()">Cancel</button>
</div>
-->