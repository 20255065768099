import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { _URL_BALANCE} from './../../config/config';


@Injectable({
  providedIn: 'root'
})
export class BalanceSheetService {

  constructor(private http: HttpClient) { }

  getStep(teamId): Observable<any> {
    return this.http.get(_URL_BALANCE + '/getStep/' + teamId);
  }

  updateStep(workShopId: number,teamId: number, step: number,status:number): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({ workShopId: workShopId, teamId: teamId,  step: step, status: status});
    return this.http.post(_URL_BALANCE + '/modifyStep', params, {
      headers: httpHeaders,
    });
  }

  newRecord(WokshopId: number, teamId: number): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({WokshopId: WokshopId, teamId: teamId});
    return this.http.post(_URL_BALANCE + '/newRecord', params, {
      headers: httpHeaders,
    });
  }

  getLabelsByLanguageId(languageId): Observable<any> {
    return this.http.get(_URL_BALANCE + '/getLabels/' + languageId);
  }

  getCantidadesBoard(teamId): Observable<any> {
    return this.http.get(_URL_BALANCE + '/getCountBoard/' + teamId);
  }
  

  newRegister(WokshopId: number, teamId: number, month:number): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({WokshopId: WokshopId, teamId: teamId, month: month});
    return this.http.post(_URL_BALANCE  + '/newQuantities', params, {
      headers: httpHeaders,
    });
  }

  getCantidadesLlenado(teamId): Observable<any> {
    return this.http.get(_URL_BALANCE + '/getQuantities/' + teamId);
  }


  getCantidadesLlenadobyMonth(workshopId, month): Observable<any> {
    return this.http.get(_URL_BALANCE + '/getQuantitiesbyMonth/' + workshopId + '/' + month );
  }

  getBidsbyMonth(workshopId, month): Observable<any> {
    return this.http.get(_URL_BALANCE + '/getBidsbyMonth/' + workshopId + '/' + month );
  }


  updateCantidades(WokshopId: number, teamId: number, month: number, cash: string,receivables: string,
                  finishGood: string,workProcess: string,rawMaterials: string,
                  totalCurrent1: string,machine: string,land: string,totalFixed: string,totalCurrent2: string,
                  payables: string,loans: string,taxes: string,totalLiabilities: string,capital: string,
                  retained: string,totalShareholder: string,totalLiabilitiesAndEquity: string): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({WokshopId: WokshopId, teamId: teamId, month: month, cash:cash, receivables:receivables,finishGood:finishGood, 
      workProcess:workProcess,rawMaterials:rawMaterials, totalCurrent1:totalCurrent1, machine:machine, land:land, totalFixed:totalFixed,
      totalCurrent2:totalCurrent2, payables:payables, loans:loans, taxes:taxes, totalLiabilities:totalLiabilities, capital:capital,
      retained:retained, totalShareholder:totalShareholder, totalLiabilitiesAndEquity:totalLiabilitiesAndEquity});
    return this.http.post(_URL_BALANCE + '/updateQuantities', params, {
      headers: httpHeaders,
    });
  }

  updateCantidadesServicesBoard(WokshopId: number, teamId: number, month: number, cash: string,receivables: string,
    workProcess: string,
    totalCurrent1: string,machine: string,land: string,totalFixed: string,totalCurrent2: string,
    payables: string,loans: string,taxes: string,totalLiabilities: string,capital: string,
    retained: string,totalShareholder: string,totalLiabilitiesAndEquity: string): Observable<any> {
const httpHeaders = new HttpHeaders({
'Content-Type': 'application/json',
});
const params = JSON.stringify({WokshopId: WokshopId, teamId: teamId, month: month, cash:cash, receivables:receivables, 
workProcess:workProcess, totalCurrent1:totalCurrent1, machine:machine, land:land, totalFixed:totalFixed,
totalCurrent2:totalCurrent2, payables:payables, loans:loans, taxes:taxes, totalLiabilities:totalLiabilities, capital:capital,
retained:retained, totalShareholder:totalShareholder, totalLiabilitiesAndEquity:totalLiabilitiesAndEquity});
return this.http.post(_URL_BALANCE + '/updateQuantitiesServices', params, {
headers: httpHeaders,
});
}

  updateManuallyBalanceSheet(WokshopId: number, teamId: number, month: number, cash: string,receivables: string,
    finishGood: string,workProcess: string,rawMaterials: string,
    totalCurrent1: string,machine: string,land: string,totalFixed: string,totalCurrent2: string,
    payables: string,loans: string,taxes: string,totalLiabilities: string,capital: string,
    retained: string,totalShareholder: string,totalLiabilitiesAndEquity: string): Observable<any> {
const httpHeaders = new HttpHeaders({
'Content-Type': 'application/json',
});
const params = JSON.stringify({WokshopId: WokshopId, teamId: teamId, month: month, cash:cash, receivables:receivables,finishGood:finishGood, 
workProcess:workProcess,rawMaterials:rawMaterials, totalCurrent1:totalCurrent1, machine:machine, land:land, totalFixed:totalFixed,
totalCurrent2:totalCurrent2, payables:payables, loans:loans, taxes:taxes, totalLiabilities:totalLiabilities, capital:capital,
retained:retained, totalShareholder:totalShareholder, totalLiabilitiesAndEquity:totalLiabilitiesAndEquity});
return this.http.post(_URL_BALANCE + '/updateManuallyBalanceSheet', params, {
headers: httpHeaders,
});
}
  
  updateMonth(workShopId: number,teamId: number, month: number): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({ workShopId: workShopId, teamId: teamId,  month: month});
    return this.http.post(_URL_BALANCE + '/modifyMonth', params, {
      headers: httpHeaders,
    });
  }
  
  getCountCantidades(teamId): Observable<any> {
    return this.http.get(_URL_BALANCE + '/getRegistros/' + teamId);
  }

  deleteMonth(teamId: number, cantidad: number): Observable<any> {
    return this.http.delete(
      `${_URL_BALANCE}/deleteMonth/${teamId}/${cantidad}`
    );
  }


  getCountCantidadesTemp(teamId, month): Observable<any> {
    return this.http.get(`${_URL_BALANCE}/getQuantitiesBalanceTemp/${teamId}/${month}`);
  }
}