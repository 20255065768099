<div>
    <div class="row my-1 w-100" style="padding-left: 10%;">
        <div class="col-12 FontMontserrat">
            <label>Click 'edit' on the cards below to edit the team member's first and last name</label>
        </div>
    </div>
    <form class="container FontMontserratTextInputs" style="padding-left: 10%; padding-right: 10%"
        [formGroup]="teamMemberForm">
        <!-- <div class="row">
            <div class="col-12">
                <label>Click 'edit' on the cards below to edit the team member's first and last name</label>
            </div>
        </div> -->
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                <label for="firstName" class="">First name <span style="color: red;">*</span>
                </label>
                <br>
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>First name</mat-label>
                    <input matInput formControlName="FirstName"
                        [ngClass]="{ 'is-invalid': !teamMemberForm.get('FirstName').valid }"
                        placeholder="First name" name="firstName" id="firstName" />
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                <label for="lastName" class="">Last name <span style="color: red;">*</span> </label>
                <br>
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>Last name</mat-label>
                    <input matInput formControlName="LastName"
                        [ngClass]="{ 'is-invalid': !teamMemberForm.get('LastName').valid }" placeholder="Last name"
                        name="lastName" id="lastName" />
                </mat-form-field>
            </div>
            <!-- <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
                <label for="email" class="">Job Title</label>
                <br>
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>Job Title</mat-label>
                    <input matInput formControlName="JobTitle"
                        [ngClass]="{ 'is-invalid': !teamMemberForm.get('JobTitle').valid }"
                        placeholder="Job Title" name="jobTitle" id="jobTitle" />
                </mat-form-field>
            </div> -->
            <!-- <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
                <label for="email" class="">Email <span style="color: red;">*</span> </label>
                <br>
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="Email"
                        [ngClass]="{ 'is-invalid': !teamMemberForm.get('Email').valid }"
                        placeholder="email@example.com" name="email" id="email" />
                </mat-form-field>
            </div> -->
            <!-- <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
                <label for="email" class="">Team Number</label>
                <br>
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>Team Number</mat-label>
                    <input type="number" min="1" [max]="lstOfTeams.length" matInput formControlName="teamNumber"
                        [ngClass]="{ 'is-invalid': !teamMemberForm.get('teamNumber').valid }"
                        placeholder="0" name="teamNumber" id="teamNumber" />
                </mat-form-field>
            </div> -->
        </div>
        <div class="row ">
            <div class="col-12 text-right my-auto">
                <button class="btn btn-ok" style="color: white;" [disabled]="teamMemberForm.invalid" (click)="editTeamMember()">
                    Save changes                                
                </button>
            </div>
        </div>


    </form>
    <ngx-spinner bdColor="rgba(0,0,0,0.75)" size="large" color="#ffffff" type="ball-clip-rotate-multiple"
        [fullScreen]="true">
        <p style="color: white"> Loading... </p>
    </ngx-spinner>

    <div class="row my-1 w-100" style="padding-left: 10%; font-size: 11px">
        <div class="col-12 FontMontserrat" style="font-size: 11px;">
            <span style="color: red;"><strong>*</strong></span><strong> Mandatory fields.</strong>
        </div>
    </div>
</div>

<!-- drag and drop team members -->
<div class="row mx-0" cdkDropListGroup>
        <div class="example-container col-4" *ngFor="let team of lstOfTeams; let i = index">
            <!-- <div class="example-container" *ngFor="let team of ltsOfTeams.reverse()"> -->
                <div class="headerTeam">
                    <h2>{{team.Avatar}}</h2>
                    <span *ngIf="team.Avatar != 'Team Facilitator' && team.TeamId != 1" class="modificaTeam" (click)="modificarSesionTeams(team)">Restart All Team Sessions <i class="fa fa-refresh " style='color:#000000'></i></span>
        
                </div>
                <div 
                class="example-list" >
                <div class="example-box" *ngFor="let item of team.TeamMembers; let j = index" style="border-radius: 10px;padding-top: 2px;padding-bottom: 2px;">
                    <div [class]="'bgTeam'+i+' example-card' " class="" style="margin-left: 2px !important; margin-right: 2px; text-align: left;color: white;width: 600px !important;border-radius: 10px;">
                        
                        <p class="TextCards">&nbsp;&nbsp;<b>{{item.FirstName + ' ' + item.LastName}}</b>
                            <br>
                            &nbsp;&nbsp;{{item.Email}}<span style="margin-right: 15px;float: right;">&nbsp;&nbsp; {{item.JobTitle}}</span>
                        
                                <span *ngIf="item.FirstName != 'facilitator' && item.IsFacilitator != true">

                                <span (click)="cargarForm(item, i, j)" style="margin-right: 15px;float: right;">&nbsp;&nbsp;<i class="fas fa-edit    "></i>Edit</span>
                                
                                <mat-checkbox *ngIf="item['logged']==1" (click)="modificarSesion(item, i, j)" checked="true"  style="margin-top: 3px;margin-right: 10px;float: right;"  ></mat-checkbox>
                                <mat-checkbox *ngIf="item['logged']==0" (click)="modificarSesion(item, i, j)"   style="margin-top: 3px;margin-right: 10px;float: right;"  ></mat-checkbox>

                                <span style="margin-right: 10px;float: right;font-family: 'Montserrat';">Session</span>
                            </span>

                        </p>
                         
                     
                 </div>
                </div>
            </div>
        </div>
</div>

<div style="text-align: center; display: block !important">  
    <button class="btn btn-secondary mx-3 style9 calibriFont" [mat-dialog-close]>&nbsp;{{getSingleTextLabel('135')}}&nbsp;</button>
</div>