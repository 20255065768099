/// General Information
/// labels.service : < Archivo para conexiones al server relacionadas a las labels manejados en algunas vistas de la plataforma >
///
/// < Notas específicas del archivo >
///
///
/// Author:
///   <Daniel de la Cruz Marcial>
///
/// Copyright(C) <Linkthinks>
///
///
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { _URL_LABELS, _URL_LANGUAGES, _URL_USERS } from './../../config/config';

@Injectable({
  providedIn: 'root',
})
export class LabelsService {
  constructor(private http: HttpClient) {}
  /*
  getLabelsByLanguageId(id){
    return this.http.get(_URL_LABELS+'/getAllLabelsbyLanguage/'+id);
  }
*/

  updateLabel(jsonArrLabels): Observable<any> {
    console.log('recibo en el service:', jsonArrLabels);
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify(jsonArrLabels);
    console.log('recibo en el params:', params);
    return this.http.post(_URL_LABELS + '/updatelist', params, {
      headers: httpHeaders,
    });
  }

  createLabels(jsonArrLabels): Observable<any> {
    console.log('recibo en el service:', jsonArrLabels);
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify(jsonArrLabels);
    console.log('recibo en el params:', params);
    return this.http.post(_URL_LABELS + '/createlist', params, {
      headers: httpHeaders,
    });
  }

  createLanguage(jsonlanguage) {
    console.log('recibo en el service:', jsonlanguage);
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify(jsonlanguage);
    console.log('recibo en el params:', params);
    return this.http.post(_URL_LABELS + '/createlanguage', params, {
      headers: httpHeaders,
    });
  }

  getScreenwasCompleted(intIdLangGlobal,sreenIdGlobal): Observable<any>{
    return this.http.get(_URL_LABELS+'/getscreenCompleted/'+intIdLangGlobal+"/"+sreenIdGlobal);
  }

  setScreenwasCompleted(intIdLangGlobal,sreenIdGlobal,complet): Observable<any>{
    return this.http.get(_URL_LABELS+'/setscreenCompleted/'+intIdLangGlobal+"/"+sreenIdGlobal+"/"+complet);
  }

  updateLabelBase(label: any): Observable<any>{
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
      LabelId: label.LabelId,
      LabelBase: label.LabelBase,
      Label: label.Label
    });
    console.log('recibo en el params:', params);
    return this.http.put(_URL_LABELS + '/updateLabelBase', params, {
      headers: httpHeaders,
    });
  }

}
