<mat-tab-group>
    <mat-tab label="Set up">
        <div class="container mt-3 FontMontserratText pb-3 p-0">
            <div class="row w-100">
                <div class="col text-center">
                    <label class="FontMontserratTitles">{{getSingleTextLabel('106')}}</label>
                </div>
            </div>
        
            <div class="row w-100 mt-1">
                <div class="col text-right">
                    <label class="FontMontserratText">{{getSingleTextLabel('2002')}}: {{strFacilitators}}</label>
                </div>
            </div>
        
            <div class="row w-100 mt-1">
                <div class="col text-right">
                    <label class="FontMontserratText">{{getSingleTextLabel('107')}}: {{strObservers}}</label>
                </div>
            </div>
        
            <div class="w-100 mt-4 FontMontserratText">
                <form [formGroup]="teamForm" class="container FontMontserratTextInputs"
                    style="padding-left: 5%; padding-right: 5%">
                    <div *ngIf="blnFacilitador" class="row">
                        <div class="col-6" style="display: inline-flex; align-items: center;">
                            <i class="far fa-edit iconStyle"></i>
                            <label for="lstteamsCtrl" style="padding-right: 5px;">Show team: 
                            </label>
                        <mat-form-field appearance="fill">
                                <mat-label>{{selectedAvatar}}</mat-label>
                            <mat-select formControlName="lstteamsCtrl" >
                                  <mat-option *ngFor="let objteam of arrTeams" [value]="objteam.team.Avatar" (click)="selectTeam(objteam)" >
                                      <ng-container *ngIf="objteam.team.Avatar == null || objteam.team.Avatar == ''">
                                          <label>{{objteam.team.Name}}</label>
                                      </ng-container>   
                                      <ng-container *ngIf="objteam.team.Avatar != null || objteam.team.Avatar != ''">             
                                            <label>{{objteam.team.Avatar}}</label>
                                        </ng-container> 
                                   </mat-option>
                                           
                            </mat-select>
                        </mat-form-field>
        
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12" style="display: inline-flex; align-items: center;">
                            <i class="fas fa-users iconStyle"></i>
                            <label for="teamName" style="padding-right: 5px;">{{getSingleTextLabel('2001')}} <span style="color: red;">*</span>
                            </label>
                            <mat-form-field class="w-50 textColorBase" appearance="fill">
                                <mat-label>{{getSingleTextLabel('5012')}}</mat-label>
                                <input matInput formControlName="teamName" placeholder="Company"
                                name="teamName" id="teamName" maxlength="18" />
                                <!-- name="teamName" id="teamName" [disabled]="canEdit" maxlength="18" /> -->
                                    <!--!blnEnableEditAvatar: {{!blnEnableEditAvatar}} canedit:{{canEdit}} !blnfacilitator: {{!this.blnFacilitador}}-->
                            </mat-form-field>
                        </div>
                    </div>
                    <div style="display: inline-flex; margin-top: 2rem;">
                        <div style="width: 25px; height: 25px; background-color: gray; margin-right: 15px; border-radius: 5px;">
                        </div>
                        <label style="padding-right: 20px;">{{getSingleTextLabel('108')}}:<span style="color: red;">*</span> </label>
                        <div *ngFor="let teamColor of teamsColors" style="display: inline-flex;">
                            <div class="checkBoxStyle" *ngIf="!teamColor.disabled && teamColor.color!='#e4e4ec'" [ngClass]="{'checkBoxSelected': teamColor.selected}"
                            [ngStyle]="{'background-color': teamColor.color}" (click)="fnSelectColor(teamColor.id)">
                                <div class="checkMark" *ngIf="teamColor.selected">
                                </div>
                            </div>
                            
                                <div class="checkBoxStyle" *ngIf="teamColor.disabled && teamColor.color!='#e4e4ec'" style="cursor: default !important;" [ngClass]="{'checkBoxSelected': teamColor.selected}"
                            [ngStyle]="{'background-color': teamColor.color}" (click)="fnUnableColor()">
                                <div class="checkMark" *ngIf="teamColor.selected">
                                </div>
                            </div>

                        </div>
                    </div>
                    <br>
                    <div style="display: inline-flex; margin-top: 2rem;">
                        <i class="far fa-user-circle iconStyle" style="font-size: 22px !important;"></i>
                        <label style="padding-right: 20px;">{{getSingleTextLabel('109')}} </label>
                    </div>
        
                    <div style="width: 100%; display: inline-flex;">
                        <div style="width: 80%;">
                            <div class="example-containerMembers">
                                <div id="memberList" cdkDropList #memberList="cdkDropList" [cdkDropListData]="arrMembers"
                                    [cdkDropListConnectedTo]="[ceoList,cfoList,cmoList,cooList,ctoList]" class="example-listMembers"
                                    (cdkDropListDropped)="drop($event)">
                                    <div class="example-box mt-1" *ngFor="let item of arrMembers" style="color: white; margin-right: 15px !important;" [ngStyle]="{'background-color': item.bcolor}" cdkDrag [cdkDragData]="item">{{item.nameComplete}}</div>
                                </div>
                            </div>
                        </div>
                        <div style="width: 20%; text-align: right;">
                            <img class="iconTrash" src="{{urlTrash}}" alt="Delete Role" cdkDropList #deletedList="cdkDropList" [cdkDropListData]="arrDeleted"
                            [cdkDropListConnectedTo]="[]" (cdkDropListDropped)="dropDeleted($event)">
                            <!--i class="fas fa-trash-alt iconTrash" [ngClass]="{'iconTrashHover': blnDeleteItem}" cdkDropList #deletedList="cdkDropList" [cdkDropListData]="arrDeleted"
                            [cdkDropListConnectedTo]="[]" (cdkDropListDropped)="dropDeleted($event)">
                            </i-->
                        </div>
                    </div>
        
                    <div class="grid-container pl-0 pr-0">
                        <div class="example-containerType">
                            <p class="titleType">{{getSingleTextLabel('2003')}}</p>
                            <div id="ceoList" cdkDropList #ceoList="cdkDropList" [cdkDropListData]="arrCeo"
                                [cdkDropListConnectedTo]="[cfoList,cmoList,cooList,ctoList,deletedList]" class="example-listType"
                                (cdkDropListDropped)="drop($event)">
                                <div class="example-box" *ngFor="let item of arrCeo" (cdkDragStarted)="cdkStartMoving()" (cdkDragEnded)="cdkEndMoving()"
                                style="color: white;" [ngStyle]="{'background-color': item.bcolor}" cdkDrag>{{item.nameComplete}}</div>
                            </div>
                        </div>
                        <div class="example-containerType">
                            <p class="titleType">{{getSingleTextLabel('2004')}}&nbsp;</p>
                            <div id="cfoList" cdkDropList #cfoList="cdkDropList" [cdkDropListData]="arrCfo"
                                [cdkDropListConnectedTo]="[ceoList,cmoList,cooList,ctoList,deletedList]" class="example-listType"
                                (cdkDropListDropped)="drop($event)">
                                <div class="example-box" *ngFor="let item of arrCfo" (cdkDragStarted)="cdkStartMoving()" (cdkDragEnded)="cdkEndMoving()"
                                 style="color: white;" [ngStyle]="{'background-color': item.bcolor}" cdkDrag>{{item.nameComplete}}</div>
                            </div>
                        </div>
                        <div class="example-containerType">
                            <p class="titleType">{{getSingleTextLabel('2005')}}</p>
                            <div id="cmoList" cdkDropList #cmoList="cdkDropList" [cdkDropListData]="arrCmo"
                                [cdkDropListConnectedTo]="[ceoList,cfoList,cooList,ctoList,deletedList]" class="example-listType"
                                (cdkDropListDropped)="drop($event)">
                                <div class="example-box" *ngFor="let item of arrCmo" (cdkDragStarted)="cdkStartMoving()" (cdkDragEnded)="cdkEndMoving()" 
                                style="color: white;" [ngStyle]="{'background-color': item.bcolor}" cdkDrag>{{item.nameComplete}}</div>
                            </div>
                        </div>
                        <div class="example-containerType">
                            <p class="titleType">{{getSingleTextLabel('2006')}}</p>
                            <div id="cooList" cdkDropList #cooList="cdkDropList" [cdkDropListData]="arrCoo"
                                [cdkDropListConnectedTo]="[ceoList,cfoList,cmoList,ctoList,deletedList]" class="example-listType"
                                (cdkDropListDropped)="drop($event)">
                                <div class="example-box" *ngFor="let item of arrCoo" (cdkDragStarted)="cdkStartMoving()" (cdkDragEnded)="cdkEndMoving()" 
                                style="color: white;" [ngStyle]="{'background-color': item.bcolor}" cdkDrag>{{item.nameComplete}}</div>
                            </div>
                        </div>
                        <div class="example-containerType">
                            <p class="titleType">{{getSingleTextLabel('2007')}}</p>
                            <div id="ctoList" cdkDropList #ctoList="cdkDropList" [cdkDropListData]="arrCto"
                                [cdkDropListConnectedTo]="[ceoList,cfoList,cmoList,cooList,deletedList]" class="example-listType"
                                (cdkDropListDropped)="drop($event)">
                                <div class="example-box" *ngFor="let item of arrCto" (cdkDragStarted)="cdkStartMoving()" (cdkDragEnded)="cdkEndMoving()" 
                                style="color: white;" [ngStyle]="{'background-color': item.bcolor}" cdkDrag>{{item.nameComplete}}</div>
                            </div>
                        </div>
                    </div>
        
                    <div class="pl-0 pr-0">
                        <!--
                        <p style="color: red;">
                            *CEO and CFO roles must be assigned.
                        </p>
                        -->
                        <p style="color: red;">
                            * Mandatory fields.
                        </p>
                    </div>
                </form>
                <div class="row">
                    <div class="col-12 text-right" style="padding-right: 8%;">
                      
                        <!-- [disabled]="!distributorForm.get('companyName').valid"  -->
                        <button *ngIf="!blnFacilitador" class="btn btn-primary mx-3 btn-ok" 
                        [disabled]="!blnIsColorSelected || teamForm.invalid"
                        type="submit" (click)="fnSaveTeam()">&nbsp;&nbsp;{{getSingleTextLabel('136')}}&nbsp;&nbsp;</button>
                        <button *ngIf="blnFacilitador" class="btn btn-primary mx-3 btn-ok" 
                        [disabled]="!blnIsColorSelected || teamForm.invalid"
                        type="submit" (click)="fnSaveTeamFacilitator()">&nbsp;&nbsp;{{getSingleTextLabel('136')}}&nbsp;&nbsp;</button>
                        <button class="btn btn-secondary mx-3 FontMontserratTextInputsCancel"
                        (click)="fnCancel()">&nbsp;{{getSingleTextLabel('123')}}&nbsp;</button>
                    </div>
                </div>
                <ngx-spinner bdColor="rgba(0,0,0,0.75)" size="large" color="#ffffff" type="ball-clip-rotate-multiple"
                    [fullScreen]="true">
                    <p style="color: white"> Loading... </p>
                </ngx-spinner>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="Edit users" *ngIf="blnFacilitador">
        <ng-template matTabContent>
            <app-dialog-edit-users [workshopId]="workShopId" [lstGlobalTeamMemebers]="lstUsers"></app-dialog-edit-users>
        </ng-template>
    </mat-tab>
    <mat-tab label="Edit teams" *ngIf="blnFacilitador">
        <ng-template matTabContent>
            <app-dialog-edit-teams [workshopId]="workShopId" 
            [lstGlobalTeamMemebers]="lstUsers" 
            [Cycles] = "Cycles"
            [room] = "room" 
            [canDeleteOrAdd] = "canDeleteOrAdd"
            [arrTextLabels] = "_data.arrTextLabels"
            [boardMode] = "boardMode"></app-dialog-edit-teams>
        </ng-template>
    </mat-tab>
</mat-tab-group>


