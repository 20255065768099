export class ProfileInfo {
  public UserID: number;
  public type: number;
  public DistributorID: number;
  public CompanyName: string;
  public IsFacilitator: number;

  constructor() {
    //super();
  }

  static create(): ProfileInfo {
    return new ProfileInfo();
    //TODO
    // return new ProfileInfo(null, null, "", "", "", "", "", "", "", "", "", SalespeopleRoleEnum.Salespeople, null, "", null);
  }
}