import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { AuthService } from '../../services/auth/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-confirm-user',
  templateUrl: './confirm-user.component.html',
  styleUrls: ['./confirm-user.component.scss']
})
export class ConfirmUserComponent implements OnInit {
  selectedLang;
  pass;
  facilitatorEmail;
  constructor(
    private authService: AuthService,
    public matDialogRef: MatDialogRef<ConfirmUserComponent>,
    private usuarioService: UsuarioService,
      @Inject(MAT_DIALOG_DATA) private _data: any
      ) { 
        /*actualLanguage: this.idLenguage,
          lstLanguages: this.lstLanguagesBoard */
        console.log('this._data:', this._data );
        this.facilitatorEmail = this._data.facilitatorEmail;
        
      }

  ngOnInit(): void {
  }
  fnLogin(){
    if(this.selectedLang == 'Facilitador'){
      console.log(this.selectedLang)
      this.authService
        .login(
          this.facilitatorEmail,
          this.pass
        )
        .subscribe((response) => {
          console.log('Api login INICIADA OK:', response);
          if (response.intResponse != 200) {
            Swal.fire({
              text: '',
              icon: 'error',
              html: `<strong class="FontMontserratTitles" style="font-size: 22px;">119.- ${response.strAnswer}</strong>`,
            });
          } else {
            console.log("CORRECTOOO")
            this.matDialogRef.close({
              userType: 'Facilitator'
            });
          }
  
        })
    }else{
      this.matDialogRef.close({
        userType: 'User'
      });
    }

  }
}
