<div class="modal-header FontMontserrat">
    <h4 class="modal-title text-center" id="modal-title"><span style="color: #334D6E;"> <span><img
                    src="../../assets/icon-add.svg" alt=""></span> <strong class="ml-4">Create Members and Teams</strong> </span></h4>
</div>
<div class="w-100 mt-4 seccionCaptura FontMontserrat">
    <div class="row">
        <form class="container FontMontserratTextInputs" style="padding-left: 10%; padding-right: 10%"
            [formGroup]="teemMemberForm">
            <div class="row">
                <div class="col-12">
                    <label>Create team members by bulk upload </label>
                </div>
            </div>
            <div class="row ">
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 my-2 ">
                    <label for="file" class="">Select file <span style="color: red;">*</span> </label>
                    <br>
                
                <label for="file-input">
                    <i class="fas fa-cloud-upload-alt fa-4x" style="color: #0099CC; cursor: pointer;"></i>
                </label>
                <input type="file" style="display: none;" [ngClass]="{ 'is-invalid': !teemMemberForm.get('file').valid }" multiple formControlName="file" (change)="fileLoad($event)" name="file" id="file-input">
                {{filename}}
            </div>
            </div>
            <div class="row">
                <div class="col-12">
                    Create team members manually
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
                    <label for="firstName" class="">First name <span style="color: red;">*</span>
                    </label>
                    <br>
                    <mat-form-field class="w-100" appearance="fill">
                        <mat-label>First name</mat-label>
                        <input matInput formControlName="firstName"
                            [ngClass]="{ 'is-invalid': !teemMemberForm.get('firstName').valid }"
                            placeholder="First name" name="firstName" id="firstName" />
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
                    <label for="lastName" class="">Last name <span style="color: red;">*</span> </label>
                    <br>
                    <mat-form-field class="w-100" appearance="fill">
                        <mat-label>Last name</mat-label>
                        <input matInput formControlName="lastName"
                            [ngClass]="{ 'is-invalid': !teemMemberForm.get('lastName').valid }" placeholder="Last name"
                            name="lastName" id="lastName" />
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
                    <label for="email" class="">Job Title</label>
                    <br>
                    <mat-form-field class="w-100" appearance="fill">
                        <mat-label>Job Title</mat-label>
                        <input matInput formControlName="jobTitle"
                            [ngClass]="{ 'is-invalid': !teemMemberForm.get('jobTitle').valid }"
                            placeholder="Job Title" name="jobTitle" id="jobTitle" />
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
                    <label for="email" class="">Email <span style="color: red;">*</span> </label>
                    <br>
                    <mat-form-field class="w-100" appearance="fill">
                        <mat-label>Email</mat-label>
                        <input matInput formControlName="email"
                            [ngClass]="{ 'is-invalid': !teemMemberForm.get('email').valid }"
                            placeholder="email@example.com" name="email" id="email" />
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
                    <label for="email" class="">Team Number</label>
                    <br>
                    <mat-form-field class="w-100" appearance="fill">
                        <mat-label>Team Number</mat-label>
                        <input type="number" min="1" [max]="ltsOfTeams.length" matInput formControlName="teamNumber"
                            [ngClass]="{ 'is-invalid': !teemMemberForm.get('teamNumber').valid }"
                            placeholder="0" name="teamNumber" id="teamNumber" />
                    </mat-form-field>
                </div>
            </div>
            <div class="row ">
                <div class="col-12 text-right my-auto">
                    <button class="text-right" [disabled]="teemMemberForm.invalid" (click)="addContactTable()"
                        style="background-color: #ffffff;border: 0;color: #6FB45C;font-weight: bold;font-size: 130%;">
                        <ng-container *ngIf="isEditContact==-1; else elseLabelSaveContact">
                            <i class="fas fa-user-plus " style="color: #6FB45C;font-size: 150%;"></i> Add contact
                        </ng-container>
                        <ng-template #elseLabelSaveContact>
                            <i class="fas fa-user-plus " style="color: #6FB45C;font-size: 150%;"></i> Save changes                                
                        </ng-template>
                    </button>
                </div>
            </div>


        </form>
        <ngx-spinner bdColor="rgba(0,0,0,0.75)" size="large" color="#ffffff" type="ball-clip-rotate-multiple"
            [fullScreen]="true">
            <p style="color: white"> Loading... </p>
        </ngx-spinner>

        <div class="row my-1 w-100" style="padding-left: 10%; font-size: 11px">
            <div class="col-12 FontMontserrat" style="font-size: 11px;">
                <span style="color: red;"><strong>*</strong></span><strong> Mandatory fields.</strong>
            </div>
        </div>
    </div>
    <!-- Div table contactos -->
    <div  class="row">
        <div class="col-12">
            <table mat-table [dataSource]="dataSourceTable"
                style="border: 1px solid #D5DCE3;margin-left: 2.5%;margin-bottom: 2.5%;width: 95%;margin-right: 2.5%;"
                class="mt-2" matSort aria-label="Elements">

                <ng-container matColumnDef="firstName">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="mat-header-cell-text">
                            First name
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <label class="FontMontserratTextTables"> {{ row.FirstName }}</label>
                    </td>
                </ng-container>

                <ng-container matColumnDef="lastName">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="mat-header-cell-text">
                            Last name
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <label class="FontMontserratTextTables"> {{ row.LastName }}</label>
                    </td>
                </ng-container>

                <ng-container matColumnDef="jobTitle">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="mat-header-cell-text">
                            Job title
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <label class="FontMontserratTextTables">{{row.JobTitle}}</label>
                    </td>
                </ng-container>

                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="mat-header-cell-text">
                            Email
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <label class="FontMontserratTextTables">{{row.Email}}</label>
                    </td>
                </ng-container>

                <ng-container matColumnDef="teamNumber">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="mat-header-cell-text">
                            Team Number
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <label class="FontMontserratTextTables">{{(row.TeamId !=-1)?row.TeamId:'Unassigned'}}</label>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="mat-header-cell-text">
                            Actions
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let row; let i = index">
                        <img (click)="fnEditUser(row, i)" src="../../../assets/icon-edit.png" alt="Edit"
                            class="iconTable" data-toggle="tooltip" data-placement="top" title="Edit Contact">&nbsp;
                        <img (click)="fnDeleteUser(row)" src="../../../assets/icon-delete.png" alt="Edit"
                            class="iconTable" data-toggle="tooltip" data-placement="top" [attr.title]="'Delete'">
                    </td>
                </ng-container>
                <tr class="tableHeader" mat-header-row *matHeaderRowDef="titulos"></tr>
                <tr mat-row *matRowDef="let row; columns: titulos;"></tr>
            </table>
            <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons></mat-paginator>
        </div>
    </div>


    


    <div class="row">
        <div class="col-12 text-right" style="padding-right: 8%;padding-bottom: 3%;">
            <button class="btn btn-outline-danger mx-3 FontMontserratTextInputs"
                (click)="fnBtnCancelar()">&nbsp;Cancel&nbsp;</button>
            <button class="btn btn-outline-success mx-3 styleButtonOK" [disabled]="lstOfTeamMembers.length <= 0 "
                (click)="fnGuardarContactos()" type="submit">&nbsp;&nbsp;Save&nbsp;&nbsp;</button>
        </div>
    </div>
</div>

<!--
<div cdkDropList class="example-list" (cdkDropListDropped)="dropTeamMemberToTeam($event, {TeamId: 500})">
    AAAAA
</div>-->

        
    
        <!--<div class="container" style="border-radius: 15px;">
           <div class="row w-100" style="background-color: #334D6E; border-radius: 14px;">
                <div class="col-6">
                    <h2 class="FontMontserratTitles text-right" style="color: #ffffff;">Teams</h2>
                </div>
                <div class="col-6">
                    <span (click)="addTeam()"
                        style="cursor:pointer; text-align: right; color: #ffffff; font-size: 200%"> <i
                            class="far fa-plus-square" style="cursor:pointer;"></i> </span>
                </div>

            </div>
            <div >-->
                
           <!--</div>

        </div>-->
    
        

    
<div class="example-container-destinity" >
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center" >
            
                <button class="btn btn-outline-success mx-3 styleButtonAdd" [disabled]="lstOfTeamMembers.length <= 0 "
                (click)="addTeam()" type="submit">&nbsp;&nbsp;Add Team&nbsp;&nbsp;</button>

            </div>
        </div>
        <hr>
        <div class="row" cdkDropListGroup>
            <!-- <div cdkDropListGroup class="container">
                <div class="example-container col-sm-6 col-md-6 col-lg-6 col-xl-6" *ngFor="let lst of lstOfDragabbles">
                  <h2>{{lst.lstName}}</h2>
                  <div class="example-list" 
                    cdkDropList
                    [cdkDropListData]="lst.teamMembers"
                    (cdkDropListDropped)="drop($event)">
                      <div class="example-box" *ngFor="let item of lst.teamMembers" cdkDrag>
                        <div class="example-card" style="margin-left: 20px !important; margin-right: 20px; text-align: left;background-color: rgb(204, 190, 195);width: 600px !important;">
                        
                            <p class="TextCardsTitle">{{item.FirstName + ' ' + item.LastName}}</p>
                             <p class="TextCards">
                                 Team {{item.TeamId}}<br>
                                 {{item.Email}}
                             </p>
                         
                     </div>
                      </div>
                  </div>
                </div>
              </div> -->
            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 " >
                <div class="example-container">
                    <h2>Team Members list:</h2>
                    <div cdkDropList #todoList="cdkDropList" [cdkDropListData]="lstOfAllTeamMembers" id="unSetTeamMembers"
                        class="example-list" (cdkDropListDropped)="drop($event, {TeamId: -1})">
                        <div class="example-box" *ngFor="let item of lstOfAllTeamMembers" cdkDrag style="border-radius: 10px;padding-top: 2px;padding-bottom: 2px;">
                            <div class="example-card" style="border-width: 20px;border-color: transparent; text-align: left;background-color: rgb(223, 222, 222);width: 600px !important;border-radius: 10px;">
                                
                                <p class="TextCards">&nbsp;&nbsp;<b>{{item.FirstName + ' ' + item.LastName}}</b>
                                    <span style="margin-right: 15px;float: right;">&nbsp;&nbsp;Team unassigned</span>
                                    <br>
                                    &nbsp;&nbsp;{{item.Email}}<span style="margin-right: 15px;float: right;">&nbsp;&nbsp; {{item.JobTitle}}</span>
                                </p>
                                 
                                 
                             </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 ">
                <div class="example-container" *ngFor="let team of ltsOfTeams; let i = index">
                    <!-- <div class="example-container" *ngFor="let team of ltsOfTeams.reverse()"> -->
                    <h2>{{team.TeamName}}</h2>
                    <div cdkDropList #doneList="cdkDropList" [cdkDropListData]="team.teamMembers"
                        class="example-list" (cdkDropListDropped)="drop($event, team)">
                        <div class="example-box" *ngFor="let item of team.teamMembers" cdkDrag style="border-radius: 10px;padding-top: 2px;padding-bottom: 2px;">
                            <div [class]="'bgTeam'+i+' example-card' " class="" style="margin-left: 2px !important; margin-right: 2px; text-align: left;color: white;width: 600px !important;border-radius: 10px;">
                                
                                <p class="TextCards">&nbsp;&nbsp;<b>{{item.FirstName + ' ' + item.LastName}}</b><span style="margin-right: 15px;float: right;">&nbsp;&nbsp;Team {{item.TeamId}}</span>
                                    <br>
                                    &nbsp;&nbsp;{{item.Email}}<span style="margin-right: 15px;float: right;">&nbsp;&nbsp; {{item.JobTitle}}</span>
                                </p>
                                 
                             
                         </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</div>
<br><br>

<br>

<br>
<br>
