import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-help-info',
  templateUrl: './dialog-help-info.component.html',
  styleUrls: ['./dialog-help-info.component.scss']
})
export class DialogHelpInfoComponent implements OnInit {

  constructor(
    public matDialogRef: MatDialogRef<DialogHelpInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      type: 'operating income' | 'income statement' | 'balance sheet',
      arrTextLabels: Array<any>,
    },
  ) { }

  ngOnInit(): void { }

  get type() {
    return this.data.type;
  }

  getSingleTextLabel(labelNumber: string): string {
    const tmp = this.data.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    return tmp ? tmp.Label : '';
  }
}
