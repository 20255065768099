<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="container">
  <!-- confirm-dialog.component.html -->
  <p mat-dialog-title class="styleTitle calibriFont">
    Simple Balance Sheet Record
  </p>


  <div mat-dialog-content style="width: fit-content;">
    <div class="main" [ngClass]="{'grid-container2teams': arrAllTeams.length == 2,'grid-container3teams': arrAllTeams.length == 3,'grid-container': arrAllTeams.length == 4, 'grid-container2': arrAllTeams.length == 5,'grid-container3': arrAllTeams.length == 6}">
      <div class="marginRigth style2b calibriFont" style="text-align: center;">Step / Team</div>
      <ng-container *ngFor="let jsnTeam of arrAllTeams">
        <div class="marginRigth style2b calibriFont" style="text-align: center;">{{ jsnTeam['team']['Avatar'] }}</div>
      </ng-container>
    </div>
  
    <ng-container *ngFor="let step of [1, 2, 3, 4, 5, 6]">
      <div class="margin" [ngClass]="{'grid-container2teams': arrAllTeams.length == 2,'grid-container3teams': arrAllTeams.length == 3,'grid-container': arrAllTeams.length == 4, 'grid-container2': arrAllTeams.length == 5,'grid-container3': arrAllTeams.length == 6}"> 
        <div class="marginRigth style2b calibriFont" style="text-align: center;">{{ step }}</div>
  
        <ng-container *ngFor="let jsnTeam of arrAllTeams">
          <div class="marginRigth paddingLeft style2a calibriFont">
            <img *ngIf="fnResultado2(jsnTeam['team']['Simpleb_step'], step)"
              src="../../../assets/cheque.png" alt=""
              data-toggle="tooltip" data-placement="top" [attr.title]="'step ' + step + ' done'">
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
  
  <mat-dialog-actions align="end">
    <button type="button" class="btn btn-primary mr-3 styleSmall" (click)="fnGetAllTeamsAndMembers()" style="margin: 13px 4px;">Refresh</button>
    <button type="button" class="btn btn-secondary styleSmall" id="btncloseHide" (click)="close()">{{getSingleTextLabel('135')}}    </button>
  </mat-dialog-actions>

  <ngx-spinner bdColor="rgba(0,0,0,0.75)" size="large" color="#ffffff" type="ball-clip-rotate-multiple"
      [fullScreen]="false">
      <p style="color: white"> Loading... </p>
  </ngx-spinner>
  <!-- rest of the dialog -->
</div>