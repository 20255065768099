import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { _URL_BOARD } from 'src/app/config/config';
import { ModelColumnBudget } from 'src/app/views/dialog-budget-exercise/dialog-budget-exercise.component';
import { SocketioService } from '../../socketio.service';

@Injectable({
  providedIn: 'root'
})
export class ExerciseService {
  private $budget: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private $cashFlow: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  _budget: Observable<any> = this.$budget.asObservable();
  _cashFlow: Observable<any> = this.$cashFlow.asObservable();
  socket = this.socketioService.socket;
  constructor(private socketioService: SocketioService, private http: HttpClient) { }
  setupSocketConnection() {
    this.socket.on('updateInputBudgetServer', (data) => {
      console.log(data);
      this.$budget.next({ data, status: 'updateInputBudgetServer' });
    });
    this.socket.on('updateInputCashFlowServer', (data) => {
      this.$cashFlow.next({ data, status: 'updateInputCashFlowServer' });
    });
    this.socket.on('updateInputCashFlowExampleServer', (data) => {
      this.$cashFlow.next({ data, status: 'updateInputCashFlowExampleServer' });
    });
    this.socket.on('updateLockCashFlowServer', (data) => {
      this.$cashFlow.next({ data, status: 'updateLockCashFlowServer' });
    });
    
    this.socket.on('notifyFacilitatorBudgetServer', (data) => {
      this.$budget.next({ data, status: 'notifyFacilitatorBudgetServer' });
    });

    this.socket.on('updateBudgetLockStatus', (data) => {
      this.$budget.next({ data, status: 'updateBudgetLockStatus' });
    });

  }

  closeListener(){
    this.socket.off("updateInputBudgetServer", () => {});
    this.socket.off("updateInputCashFlowServer", () => {});
    this.socket.off("updateLockCashFlowServer", () => {});
    this.socket.off("updateInputBudgetExampleServer", () => {});
    this.socket.off("updateInputBudgetServer");
    this.socket.off("updateLockCashFlowServer");
    this.socket.off("updateInputCashFlowServer");
    this.socket.off("updateInputBudgetExampleServer");
  }

  
  updateInputBudget(column, row, value, resultado, room, teamId){
    const key = 'updateInputBudget';
    this.socket.emit(key, {
      value: value,
      column,
      row,
      room,
      teamId,
      resultado
    });
  }
  lockCashFlow(isLocked,room, teamId){
    const key = 'updateLockCashFlow';
    this.socket.emit(key, {
      isLocked,
      room,
      teamId
    });
  }
  updateInputCashFlow(id, value, resultado, room, teamId){
    const key = 'updateInputCashFlow';
    this.socket.emit(key, {
      value: value,
      id: id,
      room,
      resultado,
      teamId
    });
  }

  updateInputCashFlowExample(id, value, resultado, roomWorkshop, teamId){
    const key = 'updateInputCashFlowExample';
    this.socket.emit(key, {
      value: value,
      resultado,
      teamId,
      id: id,
      room: roomWorkshop,
    });
  }

  notifyFacilitatorBudget(idNotify: number, teamId: number){
    const key = 'notifyFacilitatorBudget';
    this.socket.emit(key, {
      idNotify,
      teamId,
    });
  }

  saveQuantitiesTargetColumn(data: ModelColumnBudget, teamId: number, month: number, isSave?){
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    data['teamId'] = teamId;
    data['month'] = month;
    data['isSave'] = isSave
    const params = JSON.stringify(data);
    return this.http.post(_URL_BOARD + '/updateBudgetTargetColumn', params, {
      headers: httpHeaders,
    });
  }
  saveQuantitiesbottomUpColumn(data: ModelColumnBudget, teamId: number, month: number){
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    data['teamId'] = teamId;
    data['month'] = month;
    const params = JSON.stringify(data);
    return this.http.post(_URL_BOARD + '/updateBudgetbottomUpColumn', params, {
      headers: httpHeaders,
    });
  }
  saveQuantitiestopDownColumn(data: ModelColumnBudget, teamId: number, month: number){
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    data['teamId'] = teamId;
    data['month'] = month;
    const params = JSON.stringify(data);
    return this.http.post(_URL_BOARD + '/updateBudgettopDownColumn', params, {
      headers: httpHeaders,
    });
  }

  async getSaveQuantitiesBotColumn(teamId: number, month:number){
    const response = await fetch(_URL_BOARD + `/getBudgetBotColumn/${teamId}/${month}`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      // body: JSON.stringify({teamId}) // body data type must match "Content-Type" header
    });
    return response.json();
  }
  async getSaveQuantitiesTopColumn(teamId: number, month:number){
    const response = await fetch(_URL_BOARD + `/getBudgetTopColumn/${teamId}/${month}`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      // body: JSON.stringify({teamId}) // body data type must match "Content-Type" header
    });
    return response.json();
  }
  async getSaveQuantitiesTargetColumn(teamId: number, month:number){
    const response = await fetch(_URL_BOARD + `/getBudgetTargetColumn/${teamId}/${month}`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      // body: JSON.stringify({teamId}) // body data type must match "Content-Type" header
    });
    return response.json();
  }
  async getCff(teamId: number, month:number){
    const response = await fetch(_URL_BOARD + `/getCff/${teamId}/${month}`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      // body: JSON.stringify({teamId}) // body data type must match "Content-Type" header
    });
    return response.json();
  }
  async getCffFac(teamId: number, month:number){
    const response = await fetch(_URL_BOARD + `/getCffFac/${teamId}/${month}`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      // body: JSON.stringify({teamId}) // body data type must match "Content-Type" header
    });
    return response.json();
  }
  updateCff(data: any, teamId: number, month: number){
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    data['teamId'] = teamId;
    data['month'] = month;
    const params = JSON.stringify(data);
    return this.http.post(_URL_BOARD + '/updateCff', params, {
      headers: httpHeaders,
    });
  }
  updateCffLock(workshopId: number, status: number){
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let data = {'workshopId': workshopId,
   'status':status
  };
    console.log(data)
    // data['workshopId'] = workshopId;
    // data['status'] = status;
    const params = JSON.stringify(data);
    return this.http.post(_URL_BOARD + '/updateCffLock', params, {
      headers: httpHeaders,
    });
  }
  async getCffLock(workshopId: number){
    const response = await fetch(_URL_BOARD + `/getCffLock/${workshopId}`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      // body: JSON.stringify({teamId}) // body data type must match "Content-Type" header
    });
    return response.json();
  }
  
  updateCffFac(data: any, teamId: number, month: number){
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    data['teamId'] = teamId;
    data['month'] = month;
    const params = JSON.stringify(data);
    return this.http.post(_URL_BOARD + '/updateCffFac', params, {
      headers: httpHeaders,
    });
  }
}
