<!--<li *ngFor='let in of counter(count) ;let i = index'>{{i}}</li>  -->

<!-- confirm-dialog.component.html -->
<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="container">
  <p mat-dialog-title  class="styleTitle calibriFont">
  </p>
  <div mat-dialog-content>
    <div class="grid-container3" style="margin: 15px">
      <div class="calibriFont styleTitle"><label [ngClass]="{'alineado6': count == 6, 'alineado5': count == 5, 'alineado4': count == 4 }" >Financial Statements Record</label></div>
      <div  style="text-align: center;" *ngFor='let in of counter(count) ;let i = index'> </div>
    </div>

    <div class="grid-container">
      <div class="border style2b calibriFont title" style="text-align: center;"><strong>Team / Month</strong> </div>
      <!--<div class="border style2b calibriFont" style="text-align: center;">Chief Finance Officer (CFO)</div>-->
      <div class=" " [ngStyle]="{'background-color':jsnTeam['team']['Color']?jsnTeam['team']['Color']:'#1b3f89','color':jsnTeam['team']['Color']=='#bec3ff' || jsnTeam['team']['Color']=='#84eeeb' || jsnTeam['team']['Color']=='#ffe998' || jsnTeam['team']['Color'] == '#e4e4ec'|| jsnTeam['team']['Color'] == '#7faeff'?'black':'white'}"  *ngFor="let jsnTeam of arrAllTeams; let j = index" style="font-size: 14px;">
        <div [ngStyle]="{'background-color':jsnTeam['team']['Color']?jsnTeam['team']['Color']:'#1b3f89','color':jsnTeam['team']['Color']=='#bec3ff' || jsnTeam['team']['Color']=='#84eeeb' || jsnTeam['team']['Color']=='#ffe998' || jsnTeam['team']['Color'] == '#e4e4ec'|| jsnTeam['team']['Color'] == '#7faeff'?'black':'white'}" style="text-align: center;" class="calibriFont" > <strong>{{jsnTeam['team']['Avatar']}}</strong> </div>
      </div>

    </div>

    <div class="grid-container" *ngFor='let in of counter(count) ;let i = index' style="font-size: 12px;">
      <!-- <div class="border style2b calibriFont " style="text-align: center;" >
        <div class="grid-container">
          <div class="marginRigth2">
            {{i +1}}
          </div>    
        </div>
        <div class="grid-container">
          <div class="marginRigth2">
            IS
          </div>    
        </div>
        <div class="grid-container">
          <div class="marginRigth3">
            BS
          </div>
        </div>
      </div> -->
      <div class="containerA border style2b">
        <div class="containerA_A" style="text-align: center;">{{i+1}}</div>
        <div class="containerA_B">
          <div class="containerA_B_A center border" >
            IS
          </div>
          <div class="containerA_B_A center border">
            BS
          </div>
        </div>
      </div>

    <div class=" "  *ngFor="let jsnTeam of arrAllTeams; let j = index" style="font-size: 12px;">
        <div class="border grid-container4">
          <div class="center style2a calibriFont" >
            &nbsp; 
            <!-- <img *ngIf="fnResultado2(jsnTeam['team']['Income_month'], i+1)"  src="../../../assets/cheque.png" alt=""  data-toggle="tooltip" data-placement="top" [attr.title]="'IS done'"> -->
            <mat-checkbox checked="true"  disabled class="check" *ngIf="fnResultado2(jsnTeam['team']['Income_month'], i+1)" ></mat-checkbox>
            <mat-checkbox  disabled class="check" *ngIf="!fnResultado2(jsnTeam['team']['Income_month'], i+1)" ></mat-checkbox>
          </div>
        </div>
        <div class="border grid-container4">
          <div class="center style2a calibriFont" >
            &nbsp; 
            <!-- <img *ngIf="fnResultado2(jsnTeam['team']['Standardb_month'], i+1)"  src="../../../assets/cheque.png" alt=""  data-toggle="tooltip" data-placement="top" [attr.title]="'BS done'"> -->
            <mat-checkbox checked="true"  disabled class="check" *ngIf="fnResultado2(jsnTeam['team']['Standardb_month'], i+1)" ></mat-checkbox>
            <mat-checkbox  disabled class="check" *ngIf="!fnResultado2(jsnTeam['team']['Standardb_month'], i+1)" ></mat-checkbox>

          </div>
        </div>
    
    </div>

    </div>
  </div>
  <mat-dialog-actions align="end">
    <button type="button" class="btn btn-primary mr-3 styleSmall" (click)="fnGetAllTeamsAndMembers()" style="margin: 15px;">Refresh</button>
    <button type="button" class="btn btn-secondary styleSmall" id="btncloseHide" (click)="close()">{{getSingleTextLabel('135')}}  </button>
  </mat-dialog-actions>

  <ngx-spinner bdColor="rgba(0,0,0,0.75)" size="large" color="#ffffff" type="ball-clip-rotate-multiple"
      [fullScreen]="false">
      <p style="color: white"> Loading... </p>
  </ngx-spinner>
  <!-- rest of the dialog -->
</div>