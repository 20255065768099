import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { BalanceSheetService } from 'src/app/services/balance-sheet/balance.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { BalanceIncomeService } from 'src/app/services/sockets/balance-income/balance-income.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SocketioService } from 'src/app/services/socketio.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-simple-balance-sheet-services',
  templateUrl: './simple-balance-sheet-services.component.html',
  styleUrls: ['./simple-balance-sheet-services.component.scss']
})
export class SimpleBalanceSheetServicesComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  subscriptionBalanceIncome: Subscription;

  step = 0;
  saveStep = 0;
  save: boolean = false;
  bolFlecha: boolean = false;
  allowAnim: boolean = false;
  clickSave: boolean = false;
  enableOk = false;

  data11; data12; data13; data14; data15;
  // data21; data22; data23; data24; data25;
  data31; data32; data33; data34; data35;
  data41; data42; data43; data44; data45;
  data51; data52; data53; data54; data55;

  data71; data72; data73; data74; data75;
  data81; data82; data83; data84; data85;
  tot11; tot12; tot13; tot14; tot15;
  tot21; tot22; tot23; tot24; tot25;

  updateStep1:string = " ";
  updateStep2:string = " ";
  updateStep3:string = " ";
  updateStep4:string = " ";
  updateStep5:string = " ";
  aux = "";
  statusboard: number;
  userId: number;
  canEdit = true;
  constructor(
    private balanceService: BalanceSheetService, public matDialogRef: MatDialogRef<SimpleBalanceSheetServicesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { teamId: any , workShopId: any, statusBoard: number, arrTextLabels: any, canEdit:boolean, userId: number},
    private balanceIncomeService: BalanceIncomeService,
    private socketService: SocketioService,
    private spinner: NgxSpinnerService,
  ) {
    this.initSocket();
    this.statusboard = this.data.statusBoard;
    this.enableOk = this.data.statusBoard == 2 || this.data.statusBoard == 6 || 
      this.data.statusBoard == 11 || this.data.statusBoard == 15 || this.data.statusBoard == 17;
  }
  ngOnDestroy(): void {
    // this.destroy$.next(true);
    // // // Now let's also unsubscribe from the subject itself:
    // this.destroy$.complete();
    this.subscriptionBalanceIncome.unsubscribe();
    this.balanceIncomeService.closeListener();
  }

  async ngOnInit(): Promise<void> {
    this.fnGetRules();
    console.log(this.data);
    console.log("ID TEAM",this.data.teamId);
    this.canEdit = this.data.canEdit;
    this.userId = this.data.userId;
    this.matDialogRef.keydownEvents().subscribe(event => {
      if (event.key === "Escape") {
          this.onCancel();
      }
    });
  }

  initSocket(){
    this.balanceIncomeService.setupSocketConnection();
    this.subscriptionBalanceIncome = this.balanceIncomeService._balance.subscribe(response => {
      if(response){
        if(response.status == 'updateInputBalanceSimpleServer'){
          let inputElement = document.getElementById(response.data.id) as HTMLInputElement;
          if(inputElement != null){
            inputElement.value = response.data.value;
            this[response.data.strNgModel ? response.data.strNgModel : 'aux'] = response.data.value;
            console.log(this[response.data.strNgModel ? response.data.strNgModel : 'aux']);
          }
        }
      }
    });
    this.socketService._coin
    .pipe(takeUntil(this.destroy$))
    .subscribe(response => {
      if (response.status == "pieceDisabled") {
        if(response.data.userId != this.userId){
          this.canEdit = false;
        }
      }
      else if (response.status == "controlDisabled") {
        if(response.data.userId != this.userId){
          this.canEdit = false;
        }
      }
    });
  }

  onCancel(): void {
    this.matDialogRef.close({
      status: 0
    });
  }

  fnGetRules() {
    this.balanceService.getStep(this.data.teamId).subscribe((response) => {
      console.log("Array Pasos: ", response['data'].Simpleb_step);
      this.step = response['data'].Simpleb_step == null ? 0 : response['data'].Simpleb_step == 6 ? 5 : response['data'].Simpleb_step;
      console.log("step: here",this.step)

      if (this.step == 1) {
        this.data11=60;
        this.data81=60;
        this.tot21 =60;
        this.tot11= 60;
      }
      if (this.step == 2) {
        console.log("3");
        this.data11=60;
        this.data81= 60;
        this.data12=40;
        this.data52=20;
        this.data82= 60;
        this.tot21 = 60;
        this.tot11= 60;
        this.tot22 = 60;
        this.tot12= 60;
      }
      if (this.step == 3) {
        console.log("4");
        this.data11=60;
        this.data81= 60;
        this.data12=40;
        this.data52=20;
        this.data82= 60;
        this.data83= 60;
        this.data13= 20;
        this.data43= 20;
        this.data53=20;
        this.tot21 = 60;
        this.tot11= 60;
        this.tot22 =60;
        this.tot12= 60;
        this.tot23 = 60;
        this.tot13= 60;
      }
      if (this.step == 4) {
        console.log("5");
        this.data11=60;
        this.data81= 60;
        this.data12=40;
        this.data52=20;
        this.data82=60;
        this.data83= 60;
        this.data13= 20;
        this.data43= 20;
        this.data53= 20;

        this.data84= 60;
        this.data14= 5;
        this.data34= 15;
        this.data44= 20;
        this.data54= 20;

        this.tot21 = 60;
        this.tot11= 60;
        this.tot22 =60;
        this.tot12= 60;
        this.tot23 = 60;
        this.tot13= 60;
        this.tot24 = 60;
        this.tot14= 60;
      }
      if (this.step == 5) {
        console.log("6");
        this.data11=60;
        this.data81= 60;
        this.data12=40;
        this.data52=20;
        this.data82= 60;
        this.data83= 60;
        this.data13= 20;
        this.data43= 20;
        this.data53= 20;

        this.data84= 60;
        this.data14= 5;
        this.data34= 15;
        this.data44= 20;
        this.data54= 20;

        this.data85= 60;
        this.data75= 20;
        this.data15= 25;
        this.data35= 15;
        //this.data25= 24;
        this.data45= 20;
        this.data55= 20;

        this.tot21 = 60;
        this.tot11= 60;
        this.tot22 = 60;
        this.tot12= 60;
        this.tot23 =60;
        this.tot13= 60;
        this.tot24 = 60;
        this.tot14= 60;
        this.tot25 = 80;
        this.tot15= 80;
      }
    });
  }


  clean(){
    console.log("entra");
    if(this.tot11 == 0){
      this.tot11 = null;
    }
    if(this.tot12 == 0){
      this.tot12 = null;
    }
    if(this.tot13 == 0){
      this.tot13 = null;
    }
    if(this.tot14 == 0){
      this.tot14 = null;
    }
    if(this.tot15 == 0){
      this.tot15 = null;
    }

  }

  clean2(){
    console.log("entra");
    
    if(this.tot21 == 0){
      this.tot21 = null;
    }
    if(this.tot22 == 0){
      this.tot22 = null;
    }
    if(this.tot23 == 0){
      this.tot23 = null;
    }
    if(this.tot24 == 0){
      this.tot24 = null;
    }
    if(this.tot25 == 0){
      this.tot25 = null;
    }
  }

  TotalColumn11() {
    if((this.data11 == 60) && (this.data31 == 0 || this.data31==null) && (this.data41== 0 || this.data41==null) && (this.data51 == 0 || this.data51==null) && (this.data71== 0 || this.data71==null) && (this.data81 == 60) && (this.tot11 == 60) && (this.tot21 == 60))  {
      this.save = true;
      this.saveStep = 1;
      this.bolFlecha = true;
      this.allowAnim = true;
      setTimeout(() => {
        this.allowAnim = false;
      }, 4000);
    }
    else{
      this.bolFlecha = false;
      this.save = false;
    }
  }

  TotalColumn12() {
    if((this.data12 == 40) && (this.data32 == 0 || this.data32 == null) && (this.data42 == 0 || this.data42 == null) && (this.data52 == 20) && (this.data72 == 0 || this.data72 == null ) && (this.data82 == 60) && (this.tot12 == 60) && (this.tot22 == 60))  {
      this.save = true;
      this.saveStep = 2;
      this.bolFlecha = true;
      this.allowAnim = true;
      setTimeout(() => {
        this.allowAnim = false;
      }, 4000);
    }
    else{
      this.bolFlecha = false;
      this.save = false;
    }
  }

  TotalColumn13() {
      if((this.data13 == 20) && (this.data33 == 0 ||this.data33 == null) && (this.data43 == 20) && (this.data53 == 20) && (this.data73 == 0 || this.data73==null) && (this.data83 == 60) && (this.tot13 == 60) && (this.tot23 == 60))  {
        this.save = true;
        this.saveStep = 3;
        this.bolFlecha = true;
        this.allowAnim = true;
        setTimeout(() => {
          this.allowAnim = false;
        }, 4000);
      }
      else{
        this.bolFlecha = false;
        this.save = false;
      }
  }

  TotalColumn14() {
      if((this.data14 == 5) && (this.data34== 15) && (this.data44== 20) && (this.data54 == 20) && (this.data74 == 0 ||this.data74==null) && (this.data84 == 60) && (this.tot14 == 60) && (this.tot24 == 60))  {
        this.save = true;
        this.saveStep = 4;
        this.bolFlecha = true;
        this.allowAnim = true;
        setTimeout(() => {
          this.allowAnim = false;
        }, 4000);
      }
      else{
        this.bolFlecha = false;
        this.save = false;
      }
  }

  TotalColumn15() {
      if((this.data15 == 25) && (this.data35 == 15) && (this.data45 == 20) && (this.data55 == 20) && (this.data75 == 20) && (this.data85 == 60) && (this.tot15 == 80) && (this.tot25 == 80))  {
        this.save = true;
        this.saveStep = 5;
        this.bolFlecha = true;
        this.allowAnim = true;
        setTimeout(() => {
          this.allowAnim = false;
        }, 4000);
      }
      else{
        this.bolFlecha = false;
        this.save = false;
      }
}


fnResultado(){
    console.log(this.tot11, this.tot12, this.tot13, this.tot14, this.tot15)
    if(!this.canEdit || !this.enableOk){
      return;
    }
    switch (this.step) {
      case 0:
        if(this.data11 == null){var data11 = 0;} else {data11 = this.data11}
        //if(this.data21 == null){var data21 = 0;} else {data21 = this.data21}
        if(this.data31 == null){var data31 = 0;} else {data31 = this.data31}
        if(this.data41 == null){var data41 = 0;} else {data41 = this.data41}
        if(this.data51 == null){var data51 = 0;} else {data51 = this.data51}
        if(this.data11 != null || this.data31 != null || this.data41 != null || this.data51 != null){
          this.tot11 = data11 + data31 + data41 + data51;
          this.fnChangeInput(this.tot11, 25,'tot11');
          this.TotalColumn11();
        }
        console.log('OP 1:',this.data11, this.data31, this.data41, this.data51, this.tot11);
        break;
      case 1:
        if(this.data12 == null){var data12 = 0;} else {data12 = this.data12}
        //if(this.data22 == null){var data22 = 0;} else {data22 = this.data22}
        if(this.data32 == null){var data32 = 0;} else {data32 = this.data32}
        if(this.data42 == null){var data42 = 0;} else {data42 = this.data42}
        if(this.data52 == null){var data52 = 0;} else {data52 = this.data52}
        if(this.data12 != null || this.data32 != null || this.data42 != null || this.data52 != null){
          this.tot12 = data12 + data32 + data42 + data52;
          this.fnChangeInput(this.tot12, 26,'tot12');
          this.TotalColumn12();
    
        }
        console.log('OP 2:',data12,this.data12,data32,this.data32,data42,this.data42,data52,this.data52, this.tot12);
        break;
      case 2:
        if(this.data13 == null){var data13 = 0;} else {data13 = this.data13}
        //if(this.data23 == null){var data23 = 0;} else {data23 = this.data23}
        if(this.data33 == null){var data33 = 0;} else {data33 = this.data33}
        if(this.data43 == null){var data43 = 0;} else {data43 = this.data43}
        if(this.data53 == null){var data53 = 0;} else {data53 = this.data53}
        if(this.data13 != null || this.data33 != null || this.data43 != null || this.data53 != null){
          this.tot13 = data13 + data33 + data43 + data53;
          this.fnChangeInput(this.tot13, 27,'tot13');
          this.TotalColumn13();
        }
        console.log('OP 3:',this.data13, this.data33, this.data43, this.data53, this.tot13);
        break;
      case 3:
        if(this.data14 == null){var data14 = 0;} else {data14 = this.data14}
        //if(this.data24 == null){var data24 = 0;} else {data24 = this.data24}
        if(this.data34 == null){var data34 = 0;} else {data34 = this.data34}
        if(this.data44 == null){var data44 = 0;} else {data44 = this.data44}
        if(this.data54 == null){var data54 = 0;} else {data54 = this.data54}
        if(this.data14 != null || this.data34 != null || this.data44 != null || this.data54 != null){
          this.tot14 = data14 + data34 + data44 + data54;
          this.fnChangeInput(this.tot14, 28,'tot14');
          this.TotalColumn14();
        }
        console.log('OP 4:',this.data14, this.data34, this.data44, this.data54, this.tot14);
        break;
      case 4:
        if(this.data15 == null){var data15 = 0;} else {data15 = this.data15}
        //if(this.data25 == null){var data25 = 0;} else {data25 = this.data25}
        if(this.data35 == null){var data35 = 0;} else {data35 = this.data35}
        if(this.data45 == null){var data45 = 0;} else {data45 = this.data45}
        if(this.data55 == null){var data55 = 0;} else {data55 = this.data55}
        if(this.data15 != null || this.data35 != null || this.data45 != null || this.data55 != null){
          this.tot15 = data15 + data35 + data45 + data55;
          this.fnChangeInput(this.tot15, 29,'tot15');
          this.TotalColumn15();
        }
        break;

      default:
        break;
    }
}

fnSegundoResultado(){
  if(!this.canEdit || !this.enableOk){
    return;
  }
  switch (this.step) {
    case 0:
      if(this.data71 == null){var data71 = 0;} else {data71 = this.data71}
      if(this.data81 == null){var data81 = 0;} else {data81 = this.data81}
      if(this.data71 != null || this.data81 != null){
        this.tot21 = data71 + data81;
        this.fnChangeInput(this.tot21, 40,'tot21');
        this.TotalColumn11();
      }
      break;
    case 1:
      if(this.data72 == null){var data72 = 0;} else {data72 = this.data72}
      if(this.data82 == null){var data82 = 0;} else {data82 = this.data82}
      if(this.data72 != null || this.data82 != null){
        this.tot22 = data72 + data82;
        this.fnChangeInput(this.tot22, 41,'tot22');
        this.TotalColumn12();
      }
      break;
    case 2:
      if(this.data73 == null){var data73 = 0;} else {data73 = this.data73}
      if(this.data83 == null){var data83 = 0;} else {data83 = this.data83}
      if(this.data73 != null || this.data83 != null){
        this.tot23 = data73 + data83;
        this.fnChangeInput(this.tot23, 42,'tot23');
        this.TotalColumn13();
      }
      break;
    case 3:
      if(this.data74 == null){var data74 = 0;} else {data74 = this.data74}
      if(this.data84 == null){var data84 = 0;} else {data84 = this.data84}
      if(this.data74 != null || this.data84 != null){
        this.tot24 = data74 + data84;
        this.fnChangeInput(this.tot24, 43,'tot24');
        this.TotalColumn14();
      }
      break;
    case 4:
      if(this.data75 == null){var data75 = 0;} else {data75 = this.data75}
      if(this.data85 == null){var data85 = 0;} else {data85 = this.data85}
      if(this.data75 != null || this.data85 != null){
        this.tot25 = data75 + data85;
        this.fnChangeInput(this.tot25, 44,'tot25');
        this.TotalColumn15();
      }
      break;

    default:
      break;
  }
}

fnSave(){
  if(this.enableOk){
    if(this.save == true){
      this.spinner.show('my-custom-spinner')
      // this.spinner("")
      this.statusboard == 2 || this.statusboard == 6 || this.statusboard == 11 || this.statusboard == 15 || this.statusboard == 17 ? this.statusboard = this.statusboard + 1 : this.statusboard = this.statusboard;

      this.balanceService.updateStep(this.data.workShopId, this.data.teamId, this.saveStep, this.statusboard).subscribe((response) => {
        console.log("updateStep: ",response);
        setTimeout(() => {
        this.spinner.hide('my-custom-spinner')
          
        }, 1000);
      });
  
      this.matDialogRef.close({
        status: 1
      });
    }
    else{
      this.clickSave = true;
      console.log("cantidades malas")
      switch(this.step){
        case 0:
            this.tot11 = 0;
            this.tot21 = 0;  
        break;
        case 1:
            this.tot12 = 0;
            this.tot22 = 0;
        break;
        case 2:
            this.tot13 = 0;
            this.tot23 = 0;
        break;
        case 3:
            this.tot14 = 0;
            this.tot24 = 0;
        break;
        case 4:
            this.tot15 = 0;
            this.tot25 = 0;
        break;
      }
      
    }
  }
  }

  validateKey(nextInput){
    console.log('Enter key pressed, moving to:', nextInput);
    document.getElementById(nextInput).focus();
  }
  fnChangeInput(value, id, strNgModel){
    this.balanceIncomeService.updateInputSimpleBalance(value, id, 'room'+this.data.teamId, strNgModel);
  }

  getSingleTextLabel(labelNumber: string): string {
    const tmp = this.data.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    return tmp ? tmp.Label : '';
  }

  get isProd(){
    return environment.isProd
  }

  validateInputNumber(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {    
        event.preventDefault();
    }
  }
}


