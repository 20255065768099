import { Component, OnInit,Inject, } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { ContentObserver } from '@angular/cdk/observers';
import { element } from 'protractor';
import Swal from 'sweetalert2';
import { UsuarioService } from '../../services/usuario/usuario.service';


@Component({
  selector: 'app-changeimprovement',
  templateUrl: './changeimprovement.component.html',
  styleUrls: ['./changeimprovement.component.scss']
})
export class ChangeimprovementComponent implements OnInit {
  selectedImprovement = 0;
  lstImprovements = [];

  constructor( 
    public matDialogRef: MatDialogRef<ChangeimprovementComponent>,
    private usuarioService: UsuarioService,
      @Inject(MAT_DIALOG_DATA) private _data: any
    ) {console.log("entre componente change BOARD!!!");
      console.log('this._data', this._data.lstImprovements);
      this.lstImprovements = this._data.lstImprovements;
      //this.lstImprovements =this._data.lstImprovements;
     /* if(this._data.actualTeam  != 0)
      {*/
      /*  this._data.lstImprovements.forEach(element => {
         
          if(element.TeamId == this._data.actualTeam){
           element.check = true;
            this.selectedImprovement = element;
            console.log("encontramos element asignamos a modelo this.selectedImprovement:",this.selectedImprovement)
          }else{
            element.check = false;
          }
          this.lstImprovements.push(element);
          console.log("this.lstImprovements", this.lstImprovements);
        });*/
     /* }*/
      
    }

  ngOnInit(): void {
    console.log("entre ngoninit improv");
  }


  async fnSelectImprov(){
   /* var jsnteamSelct = this.lstImprovements.filter(el=>{
      if(el.TeamId == this.selectedImprovement)//view board
        return el;
    });*/
    console.log("regresando:",this.selectedImprovement);

      //significa que el usuario es un facilitador
     
      this.matDialogRef.close({
        IdImprovSelected: this.selectedImprovement
      });
    
  }


  setValueSelected(teamId){
    
    this.selectedImprovement = teamId;
    console.log("entra a asignar teamid", this.selectedImprovement);
  }


  fnCancel() {
    this.matDialogRef.close({
      IdImprovSelected: 0
    });
  }

  getSingleTextLabel(labelNumber: string): string {
    const tmp = this._data.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    return tmp ? tmp.Label : '';
  }

}

