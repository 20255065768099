<div class="main">
    <div style="text-align: center; margin-top: 7%;">
        <label class="fontTitle FontMontserratTitles style12 calibriFont" style="margin-bottom: 0px; font-size: 18px;">{{data.message}}</label>
    </div>

    <div class="container" *ngIf="data.input == 'yes'" style="margin-top: 2%;">
        <div class="row">
            <div class="col-12">
                <input type="number" style="width: 100%;" [(ngModel)]="strGroup" min="0" pattern="[0-9]+" (keyup)="entra($event)" (input)="validarInput($event)">
            </div>
        </div>
    </div>

    <div class="container" style="text-align: center;">
        <div class="row justify-content-evenly" style="margin-top: 5%;">
            <div class="col-6">
                <button style="padding: 2px 6px;" class="btn btn-primary style8 calibriFont" (click)="fnSave()">{{ButtonsName[0]}}</button>
            </div>
            <div class="col-6">
                <button style="padding: 2px;" class="btn btn-secondary style9 calibriFont" (click)="fnCancel()">{{ButtonsName[1]}}</button>
            </div>
        </div>

        <!-- <input matInput type="number" autocomplete="off" class="w field" [(ngModel)]="data" (keyup)="entra($event)" onkeypress="return event.charCode >= 48" min="0"> -->
    </div>
</div>
