import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { _URL_ORDER } from './../../config/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  intervalId: NodeJS.Timeout | null = null; // Guarda la referencia del Interval del cronometro en el modal de Orders, esto para no perderla si se cierra el modal

  constructor(private http: HttpClient) {}

  getOrdersByWorkShopId(workShopId): Observable<any>  {
    return this.http.get(_URL_ORDER + '/getOrdersByWorkShopId/' + workShopId);
  }

  createOrdersByWorkShopId(
    workshopId, 
    teamsNumber,
    month
  ): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
      workshopId,
      teamsNumber,
      month
    });
    console.log(params);
    return this.http.post(
      _URL_ORDER + '/createOrders',
      params,
      { headers: httpHeaders }
    );
  }

  updateOrdersTeamsByWorkShopId(
    arrayOfOrders: any[],
    room
  ): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
      arrayOfOrders,
      room
    });
    return this.http.put(_URL_ORDER + '/updateOrdersTeams', params, {
      headers: httpHeaders,
    });
  }


  updateOrdersWiningTeamByWorkShopId(
    workshopId, 
    arrayOfOrders: any[]
  ): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
      workshopId,
      arrayOfOrders
    });
    return this.http.put(_URL_ORDER + '/updateOrdersWiningTeam', params, {
      headers: httpHeaders,
    });
  }

  getOpenMarketByWorkShopId(workShopId): Observable<any>  {
    return this.http.get(_URL_ORDER + '/getOpenMarketByWorkShopId/' + workShopId);
  }
  getFinishMarketByWorkshopId(workShopId): Observable<any>  {
    return this.http.get(_URL_ORDER + '/getFinishMarketByWorkshopId/' + workShopId);
  }
  updateFinishMarketByWorkshopId(
    workshopId,
    status
    ): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
      workshopId,
      status
    });
    return this.http.put(_URL_ORDER + '/updateFinishMarketByWorkshopId', params, {
      headers: httpHeaders,
    });
  }
  getValidationControlRol(userId, TeamId): Observable<any>  {
    return this.http.get(_URL_ORDER + '/getValidationControlRol/' + userId+'/'+ TeamId);
  }

  updateOpenMarketByWorkshopId(
    workshopId, 
    blnOpen
  ): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
      workshopId,
      blnOpen
    });
    return this.http.put(_URL_ORDER + '/updateOpenMarketByWorkshopId', params, {
      headers: httpHeaders,
    });
  }
  getMarketByParams(wsType,cycle,month,team): Observable<any>  {
    return this.http.get(_URL_ORDER + '/getMarketByParams/' + wsType + "/" + cycle + "/" + month + "/" + team);
  }
  updateMarketOrderByParams(
    MarketId,
    orderNum,
    quantity,
    terms,
    Customizing
  ): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',});
    const params = JSON.stringify({
      "MarketId":MarketId,
      "orderNum":orderNum,
      "quantity":quantity,
      "terms":terms,
      "Customizing":Customizing
    });
    return this.http.post(_URL_ORDER + '/updateMarketOrderByParams', params, {
      headers: httpHeaders,
    });
  }
  createMarketOrderByParams(
    wsType,
    cycle,
    month,
    teams,
    orderNum,
    quantity,
    terms,
    Customizing
  ): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',});
    const params = JSON.stringify({
      "wsType":wsType,
      "cycle":cycle,
      "month":month,
      "teams":teams,
      "orderNum":orderNum,
      "quantity":quantity,
      "terms":terms,
      "Customizing":Customizing
    });
    return this.http.post(_URL_ORDER + '/CreateMarketOrderByParams', params, {
      headers: httpHeaders,
    });
  }
  deleteMarketOrderByParams(MarketOrderId): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',});
    const params = JSON.stringify({
      "MarketOrderId":MarketOrderId
    });
    return this.http.post(_URL_ORDER + '/deleteMarketOrderByParams', params, {
      headers: httpHeaders,
    });
  }
  setDeletedOrder(orderId): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',});
    const params = JSON.stringify({
      "orderId":orderId
    });
    return this.http.post(_URL_ORDER + '/setDeletedOrder', params, {
      headers: httpHeaders,
    });
  }
  
}
