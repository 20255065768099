import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TeamService } from 'src/app/services/team/team.service';
import { ImprovementService } from 'src/app/services/improvement/improvement.service';
import { SocketioService } from 'src/app/services/socketio.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-dialog-all-teams',
  templateUrl: './dialog-all-teams.component.html',
  styleUrls: ['./dialog-all-teams.component.scss']
})
export class DialogAllTeamsComponent implements OnInit {

  strFacilitators="";
  strObservers="";
  arrMembers = [
    { id: 1, name: 'Get to work', bcolor: 'hotpink' },
    { id: 2, name: 'Pick up groceries', bcolor: 'blue' },
    { id: 3, name: 'Go home', bcolor: 'violet' },
    { id: 4, name: 'Go to club', bcolor: 'greenyellow' },
  ];
  arrImprovements = [];
  arrAllTeams = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  arrImprovementsTemp = [];
  businessModel: string = "Mfg EBIT"
  blnHas4Cycles = true;
  constructor(
    private teamService: TeamService,
    public matDialogRef: MatDialogRef<DialogAllTeamsComponent>,
    private improvementService: ImprovementService,
    private socketService : SocketioService,
    @Inject(MAT_DIALOG_DATA) private _data: any
  ) { 
    console.log("this._data", this._data);
    
    if(this._data['Cycles'] == 5 || this._data['Cycles'] == 6){
      this.blnHas4Cycles = false;
    }
    this.businessModel = this._data['businessModel']
  }

  async ngOnInit(): Promise<void> {
    const x = await this.fnGetImprovements(this._data['workShopId'])
    if(x){
      console.log("Se hizo!")
      this.fnGetAllTeamsAndMembers(this._data['workShopId']);
    }
    this.initSocket();

  }
  initSocket(){
    //this.socketService.setupSocketConnection();
    this.socketService._config
                .pipe(takeUntil(this.destroy$))
                .subscribe((response) => {
                  console.log(response);
                  if(response){
                    if(response.status == "changeTeamName" && response.data != undefined){
                      console.log("AQUIIIIIIII")
                      this.arrAllTeams.forEach(element => {
                        console.log(element['team'])
                        if(element['team']['TeamId'] == response.data.teamId){
                          element['team']['Avatar'] = response.data.name;
                        }
                      });
                      this.updateView();
                    }else if(response.status == "controlDisabledToFac" && response.data != undefined)  {
                      console.log("controlDisabled VIEW ALL TEAMS")
                      console.log(response)
                      let teamID = response.data.room
                      teamID = teamID.replace("room","")
                      console.log(teamID)
                      const objetoBuscado = this.arrAllTeams.find((objeto) => {
                        if (objeto.team.TeamId === parseInt(teamID)) {
                            // Realizar modificaciones en el objeto encontrado
                            console.log(objeto)
                            objeto.team.Name = "Nuevo Nombre"; // Por ejemplo, cambiar el nombre del equipo
                            objeto['members']['CEO'].forEach(element => {
                              if(element.UserID == response.data.userId){
                                element['hasControl'] = true
                              }else{
                                element['hasControl'] = false
                              }
                            });
                            objeto['members']['CFO'].forEach(element => {
                              if(element.UserID == response.data.userId){
                                element['hasControl'] = true
                              }else{
                                element['hasControl'] = false
                              }
                            });
                            objeto['members']['CMO'].forEach(element => {
                              if(element.UserID == response.data.userId){
                                element['hasControl'] = true
                              }else{
                                element['hasControl'] = false
                              }
                            });
                            objeto['members']['COO'].forEach(element => {
                              if(element.UserID == response.data.userId){
                                element['hasControl'] = true
                              }else{
                                element['hasControl'] = false
                              }
                            });
                            objeto['members']['CTO'].forEach(element => {
                              if(element.UserID == response.data.userId){
                                element['hasControl'] = true
                              }else{
                                element['hasControl'] = false
                              }
                            });
                            // También puedes realizar otras modificaciones en members u options si es necesario
                    
                            return true; // Devuelve true para indicar que se encontró el objeto
                        }
                        return false; // Devuelve false para continuar buscando
                    });
                    console.log(this.arrAllTeams)
                    }
                  }

                });
    
  }
  fnGetImprovements(workshopId){
    return new Promise((resolve, reject) => {
    this.improvementService.getImprovementsByWorkShopId(workshopId)
    .subscribe(response=>{
      try {
      //console.log("fnGetImprovements Original",JSON.stringify(response));
/*CAMBIO ARRAY DANI */
console.log(response);
this.arrImprovementsTemp =  response['improvements'];
this.arrImprovementsTemp.shift();
this.arrImprovements = this.arrImprovementsTemp;
console.log(this.arrImprovements)
  this.arrImprovementsTemp.forEach((imp, index) => {
    if(this.blnHas4Cycles){
     // console.log("IF ANTESSS DE BORRAR  option2:: ", JSON.stringify(this.arrImprovements[index]['options'][2]))
      this.arrImprovements[index]['options'].splice(2, 1);
    }else{
      //console.log("IF ANTESSS DE BORRAR  option1:: ", JSON.stringify(this.arrImprovements[index]['options'][1]))
      this.arrImprovements[index]['options'].splice(1, 1);
    }
  });
  console.log("DESPUES DE BORRAR:: ", this.arrImprovements, this.arrAllTeams)


/**CFINCAMBIO DANI */
      this.arrImprovements = response['improvements'];
      resolve(true);
        } catch (error) {
          // En caso de error durante la transformación
          reject(error);
        }
      },
      (error) => {
        // Rechazar la promesa si hay un error en la suscripción
        reject(error);
    })
  })
  }
  fnGetImprovement(option: number) {
    let strOption: string = "";
    switch (option) {
      case 1:
        if(this.businessModel === 'Mfg EBIT'){
          strOption = this.getSingleTextLabel('90');//"Raw Materials"
        }else if(this.businessModel === 'S&S EBITDA' || this.businessModel ==='S&S EBITDA-B'){
          strOption = this.getSingleTextLabel('88062.1');//"PS staff"
        }else{
          strOption = this.getSingleTextLabel('88062');//"Low Cost Royals (LCR)"
        }
        break;
      case 2:
        strOption = this.getSingleTextLabel('91');//"Efficiency"
        break;
      case 3:
        if(this.businessModel === 'S&S EBITDA' || this.businessModel === 'S&S EBITDA-B'){
          strOption = this.getSingleTextLabel('88062.2');//"PS Office"
        }else{
          strOption = this.getSingleTextLabel('92')//"Customizing"
        }
        break;
      case 4:
        strOption = this.getSingleTextLabel('93');//"Quality"
        break;
      case 5:
        strOption = this.getSingleTextLabel('94');//"Expansion"
        break;
      case 6:
        strOption = this.getSingleTextLabel('8067');//"No Improvement"
        break;
    }
    return strOption;
  }
  fnGetAllTeamsAndMembers(workShopId){
    this.teamService.getAllTeamsAndMembersById(workShopId).subscribe(resp=>{
      console.log("fnGetAllTeamsAndMembers",resp);
      this.arrAllTeams = resp['teams'];
      this.fnSetFacilitators(resp['facilitators']);
      this.fnSetObservers(resp['observers']);
      for (let index = 0; index < this.arrAllTeams.length; index++) {
        console.log(this.arrAllTeams[index])        
        this.arrAllTeams[index]['options'] = this.arrImprovements[index]['options'];
      }
      console.log(this.arrAllTeams)
      
    })
    
  }

  fnSetFacilitators(arrFacilitators: any[]){
    arrFacilitators.forEach((facilitator, i) => {
      if(i==0)
        this.strFacilitators+=facilitator['Facilitator'];
      else
        this.strFacilitators+=', '+facilitator['Facilitator'];
    });
  }

  fnSetObservers(arrObservers: any[]){
    arrObservers.forEach((observer, i) => {
      if(i==0)
        this.strObservers+=observer['Observer'];
      else
        this.strObservers+=', '+observer['Observer'];
    });
  }

  getSingleTextLabel(labelNumber: string): string {
    const tmp = this._data.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    return tmp ? tmp.Label : '';
  }
  ngOnDestroy(): void {
    console.log("AQUI")
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
  async updateView(){
    this.strFacilitators="";
    this.strObservers = "";
    const x = await this.fnGetImprovements(this._data['workShopId'])
    if(x){
      console.log("Se hizo!")
      this.fnGetAllTeamsAndMembers(this._data['workShopId']);
    }
    if(this._data['Cycles'] == 5 || this._data['Cycles'] == 6){
      this.blnHas4Cycles = false;
    }
    console.log("Actualizar con botón");
  }
  obtenerPuesto(teamMembears: any , members: any){
    // console.log(teamMembears)
    let userIdExiste = members['CEO'].some(objeto => objeto.UserID === teamMembears.UserID);
    if(userIdExiste){
      return true
    }
    userIdExiste = members['CFO'].some(objeto => objeto.UserID === teamMembears.UserID);
    if(userIdExiste){
      return true
    }
    userIdExiste = members['CMO'].some(objeto => objeto.UserID === teamMembears.UserID);
    if(userIdExiste){
      return true
    }
    userIdExiste = members['COO'].some(objeto => objeto.UserID === teamMembears.UserID);
    if(userIdExiste){
      return true
    }
    userIdExiste = members['CTO'].some(objeto => objeto.UserID === teamMembears.UserID);
    if(userIdExiste){
      return true
    }
    return false

  }
}
