<div *ngIf="type === 'operating income'">
    <h1 class="text-center style1 calibriFont" mat-dialog-title>"{{getSingleTextLabel('7103')}}"</h1>
    <div mat-dialog-content>
        <p class="style2b calibriFont p-1 m-0">{{getSingleTextLabel('7104')}}</p>
        <p class="style2b calibriFont p-1">{{getSingleTextLabel('7105')}}</p>
    </div>
    <div mat-dialog-actions class="row justify-content-center">
        <button mat-button class="btn btn-primary style8 calibriFont" mat-dialog-close>{{getSingleTextLabel('136')}}</button>
    </div>
</div>

<div *ngIf="type === 'income statement'" class="incomeStatementDiv">
    <div mat-dialog-title>
        <p class="style1 calibriFont" style="margin: 0px;">{{getSingleTextLabel('13')}}</p>
        <p class="style2b calibriFont p-1" style="line-height: 20px;">{{getSingleTextLabel('7106')}}</p>
    </div>
    <div mat-dialog-content class="row" style="display: flex; flex-wrap: nowrap;">
        <div class="col-3">
            <p class="style3 calibriFont entryTitle">{{getSingleTextLabel('25')}}</p>
            <p class="style2a calibriFont">{{getSingleTextLabel('7107')}}</p>
            <p class="style3 calibriFont entryTitle">{{getSingleTextLabel('7108')}}</p>
            <p class="style2a calibriFont">{{getSingleTextLabel('7109')}}</p>
            <p class="style3 calibriFont entryTitle">{{getSingleTextLabel('80')}}</p>
            <p class="style2a calibriFont">{{getSingleTextLabel('7111')}}</p>
            <p class="style3 calibriFont entryTitle">{{getSingleTextLabel('35')}}</p>
            <p class="style2a calibriFont">{{getSingleTextLabel('7112')}}</p>
        </div>
        <div class="col-3">
            <p class="style3 calibriFont entryTitle">{{getSingleTextLabel('7113')}}</p>
            <p class="style2a calibriFont">{{getSingleTextLabel('7114')}}</p>
            <p class="style3 calibriFont entryTitle">{{getSingleTextLabel('7115')}}</p>
            <p class="style2a calibriFont">{{getSingleTextLabel('7116')}}</p>
            <p class="style3 calibriFont entryTitle">{{getSingleTextLabel('42')}}</p>
            <p class="style2a calibriFont">{{getSingleTextLabel('7117')}}</p>
        </div>
        <div class="backGroundImageIncomeStatement">
        </div>
        <div class="col-3 incomeStamentColumn">
            <div>
                <p class="style3 calibriFont">{{getSingleTextLabel('7103')}}</p>
                <p class="style2b calibriFont entryTitle px-2 pt-2 font-weight-bold">{{getSingleTextLabel('7118')}}</p>
                <p class="style2b calibriFont px-2 pb-1">{{getSingleTextLabel('7119')}}</p>
            </div>
            <div class="mt-2">
                <p class="style3 calibriFont">{{getSingleTextLabel('7103')}}</p>
                <p class="style2b calibriFont entryTitle px-2 pt-2 font-weight-bold">{{getSingleTextLabel('96')}}</p>
                <p class="style2b calibriFont px-2 pb-1">{{getSingleTextLabel('7120')}}</p>
                <p class="style2b calibriFont entryTitle px-2 pt-2 font-weight-bold">{{getSingleTextLabel('7121')}}</p>
                <p class="style2b calibriFont px-2 pb-1">{{getSingleTextLabel('7122')}}</p>
            </div>
        </div>
    </div>
</div>

<div *ngIf="type === 'balance sheet'" class="balanceSheetDiv">
    <div mat-dialog-title>
        <p class="style1 calibriFont" style="margin: 0px;">{{getSingleTextLabel('44')}}</p>
        <p class="titleDescription style2b calibriFont font-weight-normal p-1">{{getSingleTextLabel('7123')}}</p>
    </div>
    <div style="min-width: 995px; flex-wrap: wrap; ">
        <div mat-dialog-content class="row" style="display: flex;">
            <div class="row col-12 mt-1" style="flex-wrap: nowrap;">
                <div class="backGroundImageBalanceSheet">
                    <span class="bsLabel3026 calibriFont">{{getSingleTextLabel('3026')}}:</span>
                    <span class="bsLabel79 calibriFont">{{getSingleTextLabel('79')}}</span>
                    <span class="bsLabel66 calibriFont">{{getSingleTextLabel('66')}}</span>
                    <span class="bsLabel3025 calibriFont">{{getSingleTextLabel('3025')}}:</span>
                    <span class="bsLabel45 calibriFont">{{getSingleTextLabel('45')}}</span>
                </div>
                <div class="col-7">
                    <p class="style3 calibriFont">{{getSingleTextLabel('7103')}}</p>
                    <p class="entryTitle style2b calibriFont px-2 pt-2 font-weight-bold">{{getSingleTextLabel('7124')}}</p>
                    <p class="entryTitle style2b calibriFont px-2">{{getSingleTextLabel('7125')}}</p>
                    <p class="entryTitle style2b calibriFont px-2 pt-1 font-weight-bold">{{getSingleTextLabel('7126')}}</p>
                    <p class="entryTitle style2b calibriFont px-2">{{getSingleTextLabel('7127')}}</p>
                    <p class="entryTitle style2b calibriFont px-2 pt-1 font-weight-bold">{{getSingleTextLabel('73')}}</p>
                    <p class="style2b calibriFont px-2 pb-1">{{getSingleTextLabel('7128')}}</p>
    
                    <p class="entryTitle style2b calibriFont px-2 pt-2 font-weight-bold">{{getSingleTextLabel('45')}}</p>
                    <p class="entryTitle style2b calibriFont px-2">{{getSingleTextLabel('7129')}}</p>
                    <p class="entryTitle style2b calibriFont px-2 pt-1 font-weight-bold">{{getSingleTextLabel('7130')}}</p>
                    <p class="style2b calibriFont px-2 pb-1">{{getSingleTextLabel('7131')}}</p>
                    <p class="style2b calibriFont px-2 py-1">{{getSingleTextLabel('7132')}}</p>
                </div>
            </div>
            <div class="row col-12 mt-2">
                <div class="col-6">
                    <p class="entryTitle style3 calibriFont">{{getSingleTextLabel('79')}}</p>
                    <p class="style2a calibriFont">{{getSingleTextLabel('7134')}}</p>
                </div>
                <div class="col-6">
                    <p class="entryTitle style3 calibriFont">{{getSingleTextLabel('66')}}</p>
                    <p class="style2a calibriFont">{{getSingleTextLabel('7135')}}</p>
                </div>
            </div>
        </div>
    </div>
</div>