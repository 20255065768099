<div class="main_market">

  <div class="row mb-3" >
    <div>
      <mat-tab-group [selectedIndex]="selectTab"
        (selectedTabChange)="fnCambiarContenido$($event['tab']['textLabel'])">

        <mat-tab label="Manufacturing Game">
          <ng-template mat-tab-label>
            <span style="opacity: initial !important;"
              [ngClass]="tabSelected === 'Manufacturing Game'? 'mat-tab-selected':'mat-header-cell-text'">
              Manufacturing Game
            </span>
          </ng-template>
        </mat-tab>
  
        <mat-tab label="Services Game">
          <ng-template mat-tab-label>
            <span style="opacity: initial !important;"
              [ngClass]="tabSelected === 'Services Game'? 'mat-tab-selected':'mat-header-cell-text'">
              Services Game
            </span>
          </ng-template>
        </mat-tab>
        
      </mat-tab-group>
    </div>
  </div>

  <div class="container containerForm">
    <h3>Select Market:</h3>
    <div class="row1">
      
    <form [formGroup]="marketForm" class="form">
      
        <mat-radio-group *ngIf="tabSelected === 'Manufacturing Game'" formControlName="wsType" (change)="updateType($event)">
          <!-- <label >WorkShop type</label> -->
          <mat-radio-button class="radio" value="NM">Manufacturing Normal Mode</mat-radio-button>
          <mat-radio-button class="radio" value="DM">Manufacturing Demo Mode</mat-radio-button>
        </mat-radio-group>
        <mat-radio-group *ngIf="tabSelected === 'Services Game'" formControlName="wsType" (change)="updateType($event)">
          <!-- <label >WorkShop type</label> -->
          <mat-radio-button class="radio" value="SSNM">Services Normal Mode</mat-radio-button>
          <mat-radio-button class="radio" value="SSDM">Services Demo Mode</mat-radio-button>
        </mat-radio-group>
        <mat-form-field   class="form_field div_input2" *ngIf="typeMode">
            <mat-label>Cycles</mat-label>
            <mat-select formControlName="cycle" (selectionChange)="updateMonth($event)" [disabled]="bandera">
              <mat-option [value]=4>
                  4
              </mat-option>
              <mat-option [value]=5>
                  5
              </mat-option>
              <mat-option [value]=6>
                  6
            </mat-option>
            </mat-select>
        </mat-form-field>
        
        <mat-form-field  class="form_field div_input2"  *ngIf="monthNum==4 && (typeMode=='NM' || typeMode=='SSNM')">
            <mat-label>Month</mat-label>
            <mat-select  formControlName="month"  (selectionChange)="updateMonth($event)">
                <mat-option  *ngFor="let month of months4" [value]="month.value">
                {{ month.viewValue }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="form_field div_input2"  *ngIf="monthNum==5 && (typeMode=='NM' || typeMode=='SSNM')">
          <mat-label>Month</mat-label>
          <mat-select  formControlName="month"  (selectionChange)="updateMonth($event)">
              <mat-option  *ngFor="let month of months5" [value]="month.value">
              {{ month.viewValue }}
              </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="form_field div_input2" *ngIf="monthNum==6 && (typeMode=='NM' || typeMode=='SSNM')">
            <mat-label>Month</mat-label>
            <mat-select  formControlName="month"  (selectionChange)="updateMonth($event)">
                <mat-option  *ngFor="let month of months6" [value]="month.value">
                {{ month.viewValue }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="form_field div_input2" *ngIf="monthNum==4 && (typeMode=='DM' || typeMode=='SSDM')">
          <mat-label>Month</mat-label>
          <mat-select  formControlName="month"  (selectionChange)="updateMonth($event)">
              <mat-option  *ngFor="let month of monthsDemo" [value]="month.value">
              {{ month.viewValue }}
              </mat-option>
          </mat-select>
      </mat-form-field>
        <mat-form-field class="form_field div_input2" *ngIf="typeMode=='NM' || typeMode=='SSNM'">
            <mat-label>Number of Teams</mat-label>
            <mat-select formControlName="team"  (selectionChange)="updateMonth($event)">
                <mat-option *ngFor="let team of teams" [value]="team.value">
                {{ team.viewValue }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="form_field div_input2" *ngIf="typeMode=='DM' || typeMode=='SSDM'">
          <mat-label>Number of Teams</mat-label>
          <mat-select formControlName="team"  (selectionChange)="updateMonth($event)">
              <mat-option *ngFor="let team of teamsDemo" [value]="team.value">
              {{ team.viewValue }}
              </mat-option>
          </mat-select>
      </mat-form-field>
    </form>
    </div>
  </div>

  <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="mat-elevation-z8">
              <table mat-table [dataSource]="dataSourceTable" style="border: 1px solid #D5DCE3" class="mt-2" matSort aria-label="Elements">
                <form [formGroup]="orderTableForm">
                <!-- OrderNum Column -->
                <ng-container matColumnDef="OrderNum">
                  <th class="header" mat-header-cell *matHeaderCellDef> No. Order </th>
                  <td class="cell" mat-cell *matCellDef="let row; let i = index"> 
                    <mat-form-field class="div_input">
                      <input class="input" formControlName="OrderNum"  matInput [(ngModel)]="myOrders[i]" type="number" (change)="changeRowOrderNum(this,row,i)" [placeholder]="row.OrderNum">
                    </mat-form-field>
                  </td>
                </ng-container>
            
                <!-- Quantity Column -->
                <ng-container matColumnDef="Quantity">
                  <th class="header" mat-header-cell *matHeaderCellDef> Quantity </th>
                  <td class="cell" mat-cell *matCellDef="let row; let i = index">
                    <mat-form-field class="div_input">
                      <input class="input" formControlName="Quantity"  matInput [(ngModel)]="myQuantities[i]" type="number" (change)="changeRowQuantity(this,row,i)" [placeholder]="row.Quantity">
                    </mat-form-field>
                  </td>
                </ng-container>
            
                <!-- Terms Column -->
                <ng-container matColumnDef="Terms">
                  <th class="header" mat-header-cell *matHeaderCellDef> Terms </th>
                  <td class="cell" mat-cell *matCellDef="let row; let i = index">
                    <mat-form-field class="div_input">
                      <mat-select class="input" formControlName="Terms" matInput [(ngModel)]="myTerms[i]" (selectionChange)="changeRowTerms(this,row,i)" [placeholder]="row.Terms">
                        <mat-option [value]=0>
                            Cash
                        </mat-option>
                        <mat-option [value]=30>
                            30
                        </mat-option>
                        <mat-option [value]=60>
                            60
                        </mat-option>
                        <mat-option [value]=90>
                            90
                        </mat-option>
                      </mat-select>                  
                    </mat-form-field>
                  </td>
                </ng-container>

                <!-- Customizing Column -->
                <ng-container matColumnDef="Customizing">
                  <th class="header" mat-header-cell *matHeaderCellDef> Customizing </th>
                  <td class="cell" mat-cell *matCellDef="let row; let i = index">
                    <!-- <mat-radio-group formControlName="Customizing" matInput  
                      [(ngModel)]="myCustomizing[i]" (change)="changeRowCustomizing(this,row,i)">
                      <mat-radio-button [value]=1>Yes</mat-radio-button>
                      <mat-radio-button [value]=0>No</mat-radio-button>
                    </mat-radio-group> -->
                    <mat-checkbox class="check" *ngIf="row.Customizing==0" (change)="changeRowCustomizing(this,row,i)"></mat-checkbox>
                    <mat-checkbox class="check" *ngIf="row.Customizing==1"  checked="true" (change)="changeRowCustomizing(this,row,i)"></mat-checkbox>

                  </td>
                </ng-container>
                <!-- actions Column -->
                <ng-container matColumnDef="actions">
                  <th class="header" mat-header-cell *matHeaderCellDef> Actions </th>
                  <td class="buttons cell" mat-cell *matCellDef="let row; let i = index"> 
                    <button class="btn btn-outline-info btn_actions"  (click)="fnEditOrder(row, i)">Update</button>
                    <button class="btn btn-outline-danger btn_actions"  (click)="fnDeleteOrder(row, i)">Delete</button>
                  </td> 
                </ng-container>
                <div class="tableContainer">
                  <tr class="tableHeader" mat-header-row *matHeaderRowDef="titulos"></tr>
                </div>
                <tr class="tableRow" mat-row *matRowDef="let row; columns: titulos;"></tr>
              </form>

              </table>
              <div *ngIf="dataSourceTable.data.length === 0"> <p class="text-center">No records found</p></div>
              <mat-paginator class="cell_paginator" [pageSizeOptions]="[10,15,20]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
      </div>
    </div>
    <div class="container" style="margin-top: 20px;">
          
    <mat-accordion class="example-headers-align" multi>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Add new order to this market
            <mat-icon>close</mat-icon>
          </mat-panel-title>
          <mat-panel-description>
            
          </mat-panel-description>
        </mat-expansion-panel-header>

        <form class="row1 second_form" [formGroup]="orderForm" >
          <mat-form-field class="form_field2 div_input2">
              <mat-label>Order Number</mat-label>
              <input type="number" matInput formControlName="OrderNum"
              placeholder="Order Number" name="OrderNum" id="OrderNum">
          </mat-form-field>
          <mat-form-field class="form_field2 div_input2">
            <mat-label>Quantity</mat-label>
            <input type="number" matInput formControlName="Quantity"
            placeholder="Quantity" name="Quantity" id="Quantity">
          </mat-form-field>
          <mat-form-field class="form_field2 div_input2">
            <mat-label>Terms</mat-label>
            <mat-select formControlName="Terms">
              <mat-option [value]=0>
                  Cash
              </mat-option>
              <mat-option [value]=30>
                  30
              </mat-option>
              <mat-option [value]=60>
                  60
              </mat-option>
              <mat-option [value]=90>
                  90
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-checkbox class="form_field2 check" formControlName="Customizing">Customizing</mat-checkbox>

          <div class="btn_save">
            <button id="btn_succ" class="btn btn-success mx-3 styleButtonOK" [disabled]="!orderForm.valid"
              (click)="fnAddOrder()" type="submit">&nbsp;&nbsp;Save&nbsp;&nbsp;</button>
          </div>
        
        </form>
      </mat-expansion-panel>
    </mat-accordion>

  </div>
</div>
