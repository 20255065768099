<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <label class="style3 calibriFont FinTitle" id="example-radio-group-label">Select an improvement for this team</label><br><br>
    <mat-radio-group 
      aria-labelledby="example-radio-group-label"
      class="example-radio-group"
      >
      <mat-radio-button  name="fieldName" ngDefaultControl  class="example-radio-button style2a calibriFont" *ngFor="let objectImp of lstImprovements" [value]="objectImp.improvementId" 
         (change)="setValueSelected(objectImp.improvementId)">
         {{objectImp.Description}}
      </mat-radio-button><br>
    </mat-radio-group>
    <!--<div>Your selection: {{selectedpeople}}</div>-->
    
            
             <button  type="button" class="btn mx-3 FontMontserratTextInputsDone calibriFont" id="btnsaveht" [disabled]="selectedImprovement == 0"
            (click)="fnSelectImprov()">&nbsp;&nbsp;{{getSingleTextLabel('136')}}&nbsp;&nbsp;</button>
            <!--<button  type="button" class="btn btn-outline-success mx-3" id="btnsaveht" [disabled]="selectedImprovement.length == 0"
            style="Background-color:#6FB45C;border-radius: 50px;color: white;font-family: 'Montserrat';"
            (click)="fnSelectTeam()">&nbsp;&nbsp;Cancel&nbsp;&nbsp;</button>-->
            <button class="btn btn-secondary mx-3 FontMontserratTextInputsCancel calibriFont"
                    (click)="fnCancel()">&nbsp;{{getSingleTextLabel('123')}}&nbsp;</button>
    
    </div>