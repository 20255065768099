import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { _URL_TEAMS } from './../../config/config';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  constructor(private http: HttpClient) {}

  getTeamAndMembersById(id): Observable<any>  {
    return this.http.get(_URL_TEAMS + '/getTeamAndMembers/' + id);
  }

  updateTeamByID(
    teamId, 
    workShopId, 
    teamAvatar, 
    teamColor, 
    arrayOfMembers : any[],
    strBackground,
    _data
  ): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
      teamId,
      workShopId,
      teamAvatar,
      teamColor,
      arrayOfMembers,
      strBackground,
      _data
    });
    return this.http.put(_URL_TEAMS + '/setUpTeamByID', params, {
      headers: httpHeaders,
    });
  }

  getAllTeamsAndMembersById(workShopId): Observable<any>  {
    return this.http.get(_URL_TEAMS + '/getAllTeamsAndMembers/' + workShopId);
  }

  getFacTeamCodeById(workShopId): Observable<any>  {
    return this.http.get(_URL_TEAMS + '/getFacTeamCodeById/' + workShopId);
  }

  getAllTeamsById(workShopId): Observable<any>  {
    return this.http.get(_URL_TEAMS + '/getAllTeams/' + workShopId);
  }


  getTeamById(teamId): Observable<any>  {
    return this.http.get(_URL_TEAMS + '/getTeamById/' + teamId);
  }

  getValidateTeamsSetUp(workshopId): Observable<any>  {
    return this.http.get(_URL_TEAMS + '/getValidTeamsSetUp/' + workshopId);
  }

  /* EFA 2*/
  getAssignDefaultTeamSetUp(workshopId, teamId, blnIsFacilitator) {
    return this.http.get(_URL_TEAMS + '/assignDefaultTeamNames/' + workshopId + '/' + teamId + '/' + blnIsFacilitator )
  }
  /* EFA 2 f*/

  ////////////////////Funciones Duplicadas para SS
  getAllTeamsByIdSS(workShopId): Observable<any>  {
    return this.http.get(_URL_TEAMS + '/getAllTeamsSS/' + workShopId);
  }

}
