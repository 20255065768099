<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
<label class="style3 calibriFont FinTitle" id="example-radio-group-label">{{getSingleTextLabel('134')}}</label><br><br>
<mat-radio-group 
  aria-labelledby="example-radio-group-label"
  class="example-radio-group"
  >
  <mat-radio-button  name="fieldName" ngDefaultControl [(ngModel)]="selectedteam" class="example-radio-button style2a calibriFont" *ngFor="let team of lstTeams" [value]="team.TeamId" 
   [checked]="team.check">
     {{team.Avatar}}
  </mat-radio-button><br>
</mat-radio-group>
<!--<div>Your selection: {{selectedpeople}}</div>-->

        <button  type="button" class="btn btn-warning mx-3 FontMontserratTextInputsHome calibriFont" *ngIf="showHome" [disabled]="selectedteam.length == 0"
        (click)="fngoHome()">&nbsp;&nbsp;{{'Go Home'}}&nbsp;&nbsp;</button>
         <button  type="button" class="btn mx-3 FontMontserratTextInputsDone calibriFont" id="btnsaveht" [disabled]="selectedteam.length == 0"
        (click)="fnSelectTeam()">&nbsp;&nbsp;{{getSingleTextLabel('136')}}&nbsp;&nbsp;</button>
        <!--<button  type="button" class="btn btn-outline-success mx-3" id="btnsaveht" [disabled]="selectedteam.length == 0"
        style="Background-color:#6FB45C;border-radius: 50px;color: white;font-family: 'Montserrat';"
        (click)="fnSelectTeam()">&nbsp;&nbsp;Cancel&nbsp;&nbsp;</button>-->
        <!-- <button class="btn btn-secondary mx-3 FontMontserratTextInputsCancel calibriFont"
                (click)="fnCancel()">&nbsp;{{getSingleTextLabel('123')}}&nbsp;</button> -->

</div>