import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { LabelsService } from './../../services/label/labels.service';
import * as $ from 'jquery';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';


class Label {
  Label: string;
  LabelBase: string;
  LabelBaseSplit: Array<string>;
  LabelId: number;
  ScreenId: number;
}

@Component({
  selector: 'app-editlabels',
  templateUrl: './editlabels.component.html',
  styleUrls: ['./editlabels.component.scss'],
})
export class EditlabelsComponent implements OnInit {
  strTypeView: any;
  // @Input("lstLabelsToEdit") lstLabelsToEdit:any;
  titleTables: string[] = ['label', 'labeldefaulttext', 'showAs'];
  titleTablesChief: string[] = ['label', 'labeldefaulttext', 'btnUpdate', 'showAs'];
  isChief: boolean = false;
  dataSourceTable: MatTableDataSource<any> = new MatTableDataSource([]);
  lblTitle = 'Editar';
  txtLanguageSelected = "Language";
  lstFinalEdit = [];
  lstFinalCreate = [];
  lstTmpData = [];
  
  // @Output() funcionOut = new EventEmitter<any>();
  blnEdit = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageSize = 150;
  pageSizeOptions: number[] = [1, 5, 10, 25, 100];
  intIdLangGlobal;
  isOwnerLanguage=1;
  sreenIdGlobal=0;
  viewOnly = false;
  constructor(
    public matDialogRef: MatDialogRef<EditlabelsComponent>,private labelService: LabelsService,
    @Inject(MAT_DIALOG_DATA) private _data: any
  ) {
    console.log('this._data', this._data);
    this.strTypeView = this._data.strTypeView;
    this.txtLanguageSelected = this._data.txtLanguageSelected;
    this.fnUpdateDataToEdit(
      this._data.arrViewList,
      this._data.val,
      this._data.bol,
      this._data.intIdLangGlobal
    );
    this.isChief = environment.idsChiefAdmin.includes(environment.UserID);
    if(this.isChief){
      this.titleTables = this.titleTablesChief;
    }
   console.log(this.isChief, this.blnEdit)
  }

  ngOnInit(): void {
    console.log('entramos a modal recibimos :: ', this.strTypeView);
    
  }

  fnUpdateDataToEdit(lstData, lblEditing, blnEdit, intIdLangGlobal) {
    this.lstFinalEdit = [];
    this.lstTmpData = [];
    this.lstFinalCreate = [];
    this.blnEdit = blnEdit;
    this.intIdLangGlobal = intIdLangGlobal;
    this.sreenIdGlobal = lstData[0].ScreenId;
    this.isOwnerLanguage = parseInt(sessionStorage.getItem("isOwnerLanguage"));

    if (this.blnEdit) {
      this.lblTitle = 'Labels of module: ' + lblEditing;
      this.lstTmpData = lstData;
    } else {
      this.lblTitle = 'Create Labels to module: ' + lblEditing;
      this.lstTmpData = lstData;
    }
    if(lblEditing == "Game Board & Fin Statements"){
      if(this.isChief){
        this.titleTables =  ['label', 'labeldefaulttext', 'btnUpdate', 'showAs'];
      } else {
        this.titleTables =  ['label', 'labeldefaulttext', 'showAs'];
      }
    }else{
      if(this.isChief){
        this.titleTables = ['label', 'labeldefaulttext', 'btnUpdate', 'showAs'];
      }else{
        this.titleTables = ['label', 'labeldefaulttext', 'showAs'];
      }
    }
    

    console.log('recibimos lstData:::', lstData);

    console.log('recibimos lstTmpData:::', this.lstTmpData);
    

    this.dataSourceTable = new MatTableDataSource(this.lstTmpData);

    if(lblEditing == "Messages"){
      this.lstTmpData.forEach((item: Label) => {
        item.LabelBaseSplit = item.LabelBase.split(": ");
      })
    }
    

  }

  updateLabelBase(label: Label){
    console.log("Actualizando a ", label);
    if(this._data.val == "Messages"){
      label.LabelBase = label.LabelBaseSplit[0]+": "+label.LabelBaseSplit[1];
      label.Label = label.LabelBaseSplit[1];
    } else{
      console.log(label.Label)
      console.log(label.LabelBase)
      var idEdit='entryTXT' + label.LabelId;
      const valueEdited = (document.getElementById(
        idEdit
      ) as HTMLInputElement);
      valueEdited.value = label.LabelBase
      label.Label = label.LabelBase;
    }

    this.labelService.updateLabelBase(label).subscribe((response: any) => {
      console.log(response);
      if(response.intResponse == 200){
      }
    });


  }

  ngAfterViewInit() {
    this.dataSourceTable.paginator = this.paginator;
    
    if(this.isOwnerLanguage == 1 && this._data.blnShowUpdate){
        var btnBackhtml= <HTMLButtonElement> document.getElementById("btnback");
        btnBackhtml.style.display= "none";
        var btnclshtml= <HTMLButtonElement> document.getElementById("btnclose");
        btnclshtml.style.display= "block";
        var btnsavehtml= <HTMLButtonElement> document.getElementById("btnsaveht");
      btnsavehtml.style.display= "block";
     console.log(this.intIdLangGlobal, this.isChief)
      if(this.intIdLangGlobal == 1){
        if(!this.isChief){
          var btnBackhtml= <HTMLButtonElement> document.getElementById("btnback");
          btnBackhtml.style.display= "block";
          var btnclshtml= <HTMLButtonElement> document.getElementById("btnclose");
          btnclshtml.style.display= "none";
          var btnsavehtml= <HTMLButtonElement> document.getElementById("btnsaveht");
        btnsavehtml.style.display= "none";
        var vrlblCheck= <HTMLLabelElement> document.getElementById("lblCheck");
        vrlblCheck.style.display = 'none';
        }
      }
     /*obtenemos si el modulo esta o no complete */
     this.fngetCompletedScreenStatus(this.intIdLangGlobal, this.sreenIdGlobal);
     /*fin modulo complete */
     }else{
      this.viewOnly = true;
      var btnBackhtml= <HTMLButtonElement> document.getElementById("btnback");
      btnBackhtml.style.display= "block";
      var btnclshtml= <HTMLButtonElement> document.getElementById("btnclose");
      btnclshtml.style.display= "none";
      var btnsavehtml= <HTMLButtonElement> document.getElementById("btnsaveht");
      btnsavehtml.style.display= "none";
      var vrlblCheck= <HTMLLabelElement> document.getElementById("lblCheck");
        vrlblCheck.style.display = 'none';
        /*
       document.getElementById('btnback').style.display = 'block'
       document.getElementById('btnclose').style.display = 'none'*/
     }


  }

  fngetCompletedScreenStatus(intIdLangGlobal, sreenIdGlobal){
    this.labelService.getScreenwasCompleted(intIdLangGlobal,sreenIdGlobal).subscribe((response) => {
      console.log('reponse getstatus screen', response);
      
      if(response.jsnAnswer[0].completed == 1){
        var vrlblCheck= <HTMLLabelElement> document.getElementById("lblCheck");
        vrlblCheck.style.display = 'block';
        var vrcheckComplete= <HTMLInputElement> document.getElementById("checkComplete");
        vrcheckComplete.checked = true;
         }else{
          var vrcheckComplete= <HTMLInputElement> document.getElementById("checkComplete");
          vrcheckComplete.checked = false;
         }
      
    });
  }

  fnSaveEdit() {
    console.log("fnSaveEdit");
    if (this.blnEdit) {
      if(this.isOwnerLanguage == 1){
        var vrcheckComplete= <HTMLInputElement> document.getElementById("checkComplete");
        var complet = vrcheckComplete.checked ?  1 : 0;
        this.labelService.setScreenwasCompleted(this.intIdLangGlobal,this.sreenIdGlobal,complet).subscribe((response) => {
          console.log('reponse getstatus screen', response);
          
        });

      }
      // this.funcionOut.emit({arrdata:this.lstFinalEdit, edit:true, idlang:this.intIdLangGlobal });
      this.matDialogRef.close({
        arrdata: this.lstFinalEdit,
        edit: true,
        idlang: this.intIdLangGlobal,
        close: false,
      });
    } else {
      // this.funcionOut.emit(this.lstFinalCreate);
    }

    // this.funcionOut.emit({arrdata:this.lstFinalEdit, edit: true, idlang:this.intIdLangGlobal });
    this.matDialogRef.close({
      arrdata: this.lstFinalEdit,
      edit: true,
      idlang: this.intIdLangGlobal,
      close: false,
    });
  }

  fnHasChanges() {
    console.log();
    if (this.blnEdit) {
      if (this.lstFinalEdit.length > 0) {
        Swal.fire({
          html:
            '<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> 112.- Do you want to exit without saving changes? </label>',
          imageUrl: '../../../../assets/iconPrevention.svg',
          showCancelButton: true,
          confirmButtonColor: '#6FB45C',
          cancelButtonColor: '#ED5050',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            // this.funcionOut.emit({arrdata:[], edit: false, idlang:this.intIdLangGlobal });
            this.matDialogRef.close({
              arrdata: [],
              edit: false,
              idlang: this.intIdLangGlobal,
              close: false,
            });
          }
        });
      } else {
        // this.funcionOut.emit({arrdata:[], edit: false});
        this.matDialogRef.close({ close: true });
      }

      // this.funcionOut.emit(this.lstFinalEdit);
    } else {
      // this.funcionOut.emit(this.lstFinalCreate);
      if (this.lstFinalCreate.length > 0) {
        Swal.fire({
          html:
            '<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> 112.- Do you want to exit without saving changes? </label>',
          imageUrl: '../../../../assets/iconPrevention.svg',
          showCancelButton: true,
          confirmButtonColor: '#6FB45C',
          cancelButtonColor: '#ED5050',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            /*this.funcionOut.emit({arrdata:[], edit: false, idlang:this.intIdLangGlobal });
          $('#btncloseHide').click();*/
            this.matDialogRef.close({
              arrdata: [],
              edit: false,
              idlang: this.intIdLangGlobal,
              close: false,
            });
          }
        });
      } else {
        /*this.funcionOut.emit({arrdata:[], edit: false, idlang:this.intIdLangGlobal });
      $('#btncloseHide').click();*/
        this.matDialogRef.close({
          arrdata: [],
          edit: false,
          idlang: this.intIdLangGlobal,
          close: false,
        });
      }
    }
  }

  fnReturn()
  {
     this.matDialogRef.close({ close: true });
  }

  fnPreUpdateList(objectEdited) {
    console.log('objectEdited xd---', objectEdited);
    var idEdit='entryTXT' + objectEdited.LabelId;
    console.log(
      'value entryTXT+objectEdited.LabelId::',
      idEdit
    );
    const valueEdited = (document.getElementById(
      idEdit
    ) as HTMLInputElement).value;
    console.log('value valuert::', valueEdited);
    if (this.lstFinalEdit.length == 0) {
      objectEdited.Label = valueEdited;
      this.lstFinalEdit.push(objectEdited);
      console.log('IF 0this.lstFinalEdit final: ', this.lstFinalEdit);
    } else {
      let lstFinalEditAux = [];
      lstFinalEditAux = this.lstFinalEdit;
      let countIter = 0;
      this.lstFinalEdit.forEach((element, index) => {
        console.log('Element', element);

        if (objectEdited.LabelId == element.LabelId) {
          lstFinalEditAux.splice(index, 1);
          objectEdited.Label = valueEdited;
          lstFinalEditAux.push(objectEdited);
          this.lstFinalEdit = lstFinalEditAux;
        } else {
          countIter++;
        }
      });

      console.log(
        'countIter == this.lstFinalEdit.length::',
        countIter,
        this.lstFinalEdit.length
      );
      if (countIter == this.lstFinalEdit.length) {
        objectEdited.Label = valueEdited;
        this.lstFinalEdit.push(objectEdited);
      }

      console.log('this.lstFinalEdit final: ', this.lstFinalEdit);
    }
  }

  /*
fnCompleteListWithDefalutoptions(){
  this.lstFinalEdit.forEach((element, index) => {
    console.log("Element", element);

    if(objectEdited.LabelCode ==  element.LabelId){
      lstFinalEditAux.splice(index,1);
      objectEdited.Label = valueEdited;
      lstFinalEditAux.push(objectEdited);
      this.lstFinalEdit= lstFinalEditAux;
    } else {
      countIter++;
    }
  });
}*/

  fnCreateList(objectEdited) {
    console.log('onblux xd---', objectEdited);
    console.log(
      'value entry+objectEdited.LabelId::',
      'entry' + objectEdited.LabelId
    );
    const valueEdited = (document.getElementById(
      'entry' + objectEdited.LabelId
    ) as HTMLInputElement).value;
    console.log('value valuert::', valueEdited);
    if (this.lstFinalCreate.length == 0) {
      objectEdited.Label = valueEdited;
      this.lstFinalCreate.push(objectEdited);
      console.log('IF 0this.lstFinalCreate final: ', this.lstFinalCreate);
    } else {
      let lstFinalCreateAux = [];
      lstFinalCreateAux = this.lstFinalCreate;
      let countIter = 0;
      this.lstFinalCreate.forEach((element, index) => {
        console.log('Element', element);

        if (objectEdited.LabelId == element.LabelId) {
          lstFinalCreateAux.splice(index, 1);
          objectEdited.Label = valueEdited;
          lstFinalCreateAux.push(objectEdited);
          this.lstFinalCreate = lstFinalCreateAux;
        } else {
          countIter++;
        }
      });

      console.log(
        'countIter == this.lstFinalCreate.length::',
        countIter,
        this.lstFinalCreate.length
      );
      if (countIter == this.lstFinalCreate.length) {
        objectEdited.Label = valueEdited;
        this.lstFinalCreate.push(objectEdited);
      }

      console.log('this.lstFinalCreate final: ', this.lstFinalCreate);
    }
  }

  fnSearch(event) {
    const arrTempFilt = this.lstTmpData;
    let arrTemp = [];
    if (arrTempFilt.length > 0) {
      const strSearchBoxRegex = new RegExp(`/*${event.target.value}`, 'i');
      arrTemp = arrTempFilt.filter(
        (jsnItem) =>
          strSearchBoxRegex.test(
            jsnItem.Label
          ) ||
        strSearchBoxRegex.test(
          jsnItem.LabelBase
          ) ||
          strSearchBoxRegex.test(
            jsnItem.LabelKeywords
            ) ||
            strSearchBoxRegex.test(
              jsnItem.LabelNumber
              ) 
      );
    }
    this.dataSourceTable = new MatTableDataSource(arrTemp);
    this.dataSourceTable.paginator = this.paginator;
  }
}
