import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { _URL_RULES} from './../../config/config';


@Injectable({
  providedIn: 'root'
})
export class RulesService {

  constructor(private http: HttpClient) { }

  getRulesByLanguageId(languageId): Observable<any> {
  return this.http.get(_URL_RULES + '/getRules/' + languageId);
  }
  
}