

<!--<img class="img-tablero" src="{{strBackground}}" height="670px">-->

<div style='position: absolute;left: 133px;' [ngStyle]="{'background-color': strBackground}" >
    <img  src="../../../assets/Tablero.png" height="670px">
  </div>
  <div id="divBoardVersion" style="color: #b4b6d1;">V 1.9.10</div>
  <div class="containerCheckBoxes" *ngIf="(currentMonth >= 2 && (blnCanMoveitems))">
    <section class="example-section">
      <h4 class="lbl4001 calibriFont">{{getSingleTextLabel('4001')}}:</h4>
      <div id="checkList-Scrollable" style="width: 100%; display: inline-flex;   overflow-y: scroll; overflow-x: hidden; height: 176px;">
        <!-- <div style="width: 15%; background-color: #d9ead3; height: 410px;">
          <p class="verticalDirection" style="margin-top: 30px; height: 220px; margin-bottom: 25px;">Before the market</p>
          <p class="verticalDirection" style="margin-top: 30px; height: 120px;  margin-bottom: 25px;">After the market</p>
        </div> -->
        <div style="width: 85%;">
          <p class="{{'checkBox '+'checkBoxScroll-'+checkBox.id}}" *ngFor="let checkBox of checkBoxes">
            <mat-checkbox  [disabled]="checkBox.disabled || isOpenMarketDisabledBoard" (change)="fnTest()" [(ngModel)]="checkBox.blnCheck">
              <span class="style2a calibriFont font-weight-normal">{{mapCheckboxTitleToLabel(checkBox.title)}}</span>
            </mat-checkbox>
          </p>
        </div>
      </div>
    </section>
  
  </div>
  <div class="containerCheckBoxes" *ngIf="showCheckListFacilitator">
    <section class="example-section">
      <h4 class="lbl4001 calibriFont">{{getSingleTextLabel('4001')}}:</h4>
      <div id="checkList-Scrollable" style="width: 100%; display: inline-flex;   overflow-y: scroll; overflow-x: hidden; height: 176px;">
        <!-- <div style="width: 15%; background-color: #d9ead3; height: 410px;">
          <p class="verticalDirection" style="margin-top: 30px; height: 220px; margin-bottom: 25px;">Before the market</p>
          <p class="verticalDirection" style="margin-top: 30px; height: 120px;  margin-bottom: 25px;">After the market</p>
        </div> -->
        <div style="width: 85%;">
          <p class="{{'checkBox '+'checkBoxScroll-'+checkBox.id}}" *ngFor="let checkBox of checkBoxesForFacilitator" [ngClass]="{'backForCheck' : checkBox.id == 0 || checkBox.id == 17 || checkBox.id == 18}" style="width: 119%;" >
            <mat-checkbox [disabled]="checkBox.disabled || isOpenMarketDisabledBoard">
              <span class="style2a calibriFont font-weight-normal">{{mapCheckboxTitleToLabel(checkBox.title)}}</span>
            </mat-checkbox>
          </p>
        </div>
      </div>
    </section>
    
  </div> 
  <!-- error paso 4 -->
  <div id="errorGeneral" *ngIf="(currentMonth >= 1)" class="container-styles-globals"></div>
  <!-- fin error paso 4 -->
  <div id="centered" *ngIf="emailNotExists">
    <p><strong>Verify that your email address corresponds to the link you received.</strong></p>
  </div>
  <!-- <div id="centered" *ngIf="blnCanMoveFacilitator">
    <p> <strong> You have to change to the {{monthFacilitator}} month to continue playing</strong> </p>
  </div> -->
  <div class="container backColorPage">
  <!--Div para evitar acciones en tablero-->
    <div *ngIf="!blnCanMoveitems && ((!blnFacilitador) || (blnFacilitador && currentMonth > 1))" class="container_NoMoveBoard">
      
    </div>
    <div *ngIf="blnCanMoveFacilitator" class="container2ToFacilitator">
      
    </div>
    <div *ngIf="blnCanMoveFacilitator" class="container3ToFacilitator">
      
    </div>
    <div *ngIf="userWithoutsession" class="container_NoMoveBoard">
    </div>
    
    <div class="container-IncomeStatement" style="background-color:black;">
      <div (click)="fnModalIncomeStatement()" class="container-IncomeStatement-styles container-styles-globals"
      [ngClass]="{'grow': status==34 }"  [ngStyle]="{'z-index': status == 0 || status == -1 ? 1501:1000}">
      </div>
    </div>
  
    <div *ngIf="status < 37 && currentMonth == 1" class="container-balanceSheet" style="background-color:transparent;"><!--{{status}} - {{currentMonth}} -{{isModeOpen}}-->
      <div [ngClass]="{'grow': (( status==2 || status == 6 || status == 11 || status == 17 || status == 24 || status == 37) && currentMonth == 1 )}" 
        (click)="fnTest()"
        class="container-balanceSheet-styles container-styles-globals">
      </div>
    </div>
  
    <div *ngIf="status >= 37 || currentMonth > 1" class="container-balanceSheet3" style="background-color:black;">
      <!--<div [ngClass]="{'grow': ((status==2 || status == 6 || status == 11 || status == 17 || status == 24 || status == 37) && currentMonth == 1) && !isModeOpen && !blnBackgroundBlue}-->
      <div [ngClass]="{'grow': ((status==2 || status == 6 || status == 11 || status == 17 || status == 24 || status == 37) && currentMonth == 1) && !isModeOpen }" 
        (click)="fnClickStandardBalanceSheet()" [ngStyle]="{'z-index': status == 0 || status == -1 ? 1501:1000}"
        class="container-balanceSheet-styles container-styles-globals">
        
      </div>
    </div>
    <label class="lblnum10 txtBoldWhitebtn calibriBold14 calibriFont"  (click)="fnTest()">{{getSingleTextLabel('10')}}</label>
    
    <div class="container-customers">
      <div  (click)="fnTest()" [ngStyle]="{'z-index': status == 0 || status == -1 ? 1501:1001}"
       class="container-shareholders-styles container-styles-globals">
      </div>
    </div>
    
    <label class="lblnum9 txtBoldWhitebtn calibriBold14 calibriFont" >{{getSingleTextLabel('9')}}</label>
    <label class="lblnum11 calibriBold20 calibriFont"  style="font-size: 20px;">{{getSingleTextLabel('11')}}</label>
    <label class="lblCopyright calibri10 calibriFont"  style="font-size: 20px;">{{getSingleTextLabel('153')}}</label>
    <div class="container-customers" style="background-color:black;">
      <div [ngClass]="{'grow': status == 29 }" (click)="fnTest()" [ngStyle]="{'z-index': status == 0 || status == -1 ? 1501:1000}"
       class="container-customers-styles container-styles-globals">
      </div>
    </div>
    
    <div class="container-customers" style="background-color:black;">
      <div  (click)="fnTest()" [ngClass]="{'growStrong': this.blnGrowNocontrol}"
       class="container-chat-styles container-styles-globals">
      </div>
    </div>
    
    <div class="divMenu">
        <button mat-icon-button [disabled]="!blnAccessCUSTOMERISBS" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon style="color: black !important;">more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          
          <button mat-menu-item class="mat-menu-item-height" (click)="fnTest()">
            <mat-icon>settings</mat-icon>
            <span>{{getSingleTextLabel('106')}}</span>
          </button>
          <button mat-menu-item class="mat-menu-item-height" (click)="fnTest()">
            <mat-icon>group_work</mat-icon>
            <span>{{getSingleTextLabel('124')}}</span>
          </button>
          <!--<button mat-menu-item class="mat-menu-item-height" (click)="fnSelectOption(3)">
            <mat-icon>assignment_late</mat-icon>
            <span>Rules</span>
          </button>-->
          <button *ngIf="blnFacilitador == true" mat-menu-item class="mat-menu-item-height" (click)="fnTest()">
            <mat-icon>face</mat-icon>
            <span>Simple Balance Sheet Record</span>
          </button>
          <button *ngIf="blnFacilitador == true" mat-menu-item class="mat-menu-item-height" (click)="fnTest()">
            <mat-icon>lock_open</mat-icon>
            <span>Open/Rigorous</span>
          </button>
          <button *ngIf="blnFacilitador == true" mat-menu-item class="mat-menu-item-height" (click)="fnTest()">
            <mat-icon>face</mat-icon>
            <span>Record Financial Statements</span>
          </button>
          <button *ngIf="blnFacilitador == true" mat-menu-item class="mat-menu-item-height" (click)="fnTest()">
            <mat-icon>face</mat-icon>
            <span>Record Budget</span>
          </button>
          <button mat-menu-item class="mat-menu-item-height" *ngIf="currentMonth > 1" (click)="fnTest()">
            <mat-icon>delete_outline</mat-icon>
            <span>{{getSingleTextLabel('125')}}</span>
          </button>
          <!--<button mat-menu-item class="mat-menu-item-height"  (click)="fnSelectOption(8)">
            <mat-icon>repeat</mat-icon>
            <span>Change Board</span>
          </button>-->
          <!--<button mat-menu-item class="mat-menu-item-height"  (click)="fnSelectOption(9)">
            <mat-icon>autorenew</mat-icon>
            <span>{{getSingleTextLabel('126')}}</span>
          </button>
          <button *ngIf="blnFacilitador == true" mat-menu-item class="mat-menu-item-height"  (click)="fnSelectOption(9)">
            <mat-icon>settings_backup_restore</mat-icon>
            <span>{{getSingleTextLabel('127')}}</span>
          </button>-->
          <button *ngIf="blnFacilitador == true && currentMonth >= 3" mat-menu-item class="mat-menu-item-height"  (click)="fnTest()">
            <mat-icon>subdirectory_arrow_right</mat-icon>
            <span>Projects</span>
          </button>
          <!-- <button *ngIf="currentMonth >= 4" [matMenuTriggerFor]="budgetExercise" mat-menu-item class="mat-menu-item-height"> -->
            <button *ngIf="currentMonth >= 4" (click)="fnTest()" mat-menu-item class="mat-menu-item-height">
            <mat-icon>assignment</mat-icon>
            <span>{{getSingleTextLabel('129')}}</span>
          </button>
          <!-- <button *ngIf="currentMonth >= 4" [matMenuTriggerFor]="cashExercise" mat-menu-item class="mat-menu-item-height"  > -->
            <button *ngIf="currentMonth >= 4" (click)="fnTest()" mat-menu-item class="mat-menu-item-height"  >
            <mat-icon>assignment</mat-icon>
            <span>{{getSingleTextLabel('130')}}</span>
          </button>
          <button *ngIf="blnFacilitador == true" mat-menu-item class="mat-menu-item-height"  (click)="fnTest()">
            <mat-icon>play_circle_filled</mat-icon>
            <span>Start Month {{currentMonth}}</span>
          </button>
          <button *ngIf="blnFacilitador == true && currentMonth >= 3" mat-menu-item class="mat-menu-item-height"  (click)="fnTest()">
            <mat-icon>navigate_before</mat-icon>
            <span>Go previous Month {{currentMonth - 1}}</span>
          </button>
          <button *ngIf="blnFacilitador == true && currentMonth >= 2 && (currentMonth +1) <= Cycles" mat-menu-item class="mat-menu-item-height"  (click)="fnTest()">
            <mat-icon>navigate_next</mat-icon>
            <span>Go next Month {{currentMonth + 1}}</span>
          </button>
          <button mat-menu-item class="mat-menu-item-height"  (click)="fnTest()">
            <mat-icon>book</mat-icon>
            <span>{{getSingleTextLabel('7133')}}</span>
          </button>
          <button mat-menu-item class="mat-menu-item-height"  (click)="fnTest()">
            <mat-icon>language</mat-icon>
            <span>{{getSingleTextLabel('120')}}</span>
          </button>
          <button *ngIf="blnFacilitador == true" mat-menu-item class="mat-menu-item-height"  (click)="fnTest()">
            <mat-icon>settings</mat-icon>
            <span>Add stack to Team board</span>
          </button>
          <button *ngIf="blnFacilitador == true"  mat-menu-item class="mat-menu-item-height" (click)="fnTest()">
            <mat-icon>settings</mat-icon>
            <span>Teams IS & BS</span>
          </button>
          <!--<button *ngIf="blnFacilitador == true" mat-menu-item class="mat-menu-item-height"  (click)="fnSelectOption(21)">
            <mat-icon>settings</mat-icon>
            <span>Create backup BD</span>
          </button>-->
          <!--
          <button *ngIf="blnFacilitador == true" mat-menu-item class="mat-menu-item-height"  (click)="fnSelectOption(22)">
            <mat-icon>settings</mat-icon>
            <span>Edit users</span>
          </button>
          -->
        <!--<span>
            {{this.status}} < 29 && {{this.currentMonth}} == 1 || {{this.status}} < 10 && {{this.currentMonth}} > 1 && {{this.status}} >= -1) && shouldBlockMagicButtonByImprovements:{{shouldBlockMagicButtonByImprovements}} && currentMonth:{{currentMonth}} <= 3 && (isFacilitatorTeamAccount:{{isFacilitatorTeamAccount}} || ({{blnFacilitador}} && {{this.currentMonth}} == 1
          </span>-->
          <button *ngIf="((this.status < 29 && this.currentMonth == 1) || this.status < 10 && this.currentMonth > 1 && this.status >= -1) && currentMonth <= 3 && (isFacilitatorTeamAccount || (blnFacilitador && this.currentMonth == 1)) || (blnFacilitador && this.currentMonth == 2 && this.status < 10)" mat-menu-item class="mat-menu-item-height"  (click)="fnTest()">
            <mat-icon>navigate_next</mat-icon>
            <span>Go to market (A)</span>
          </button>
          <button *ngIf="((this.status >= 29 && this.currentMonth == 1) || (this.status >= 10 && this.currentMonth >= 2)) && currentMonth <= 3 && (isFacilitatorTeamAccount || (blnFacilitador && this.currentMonth == 1))" mat-menu-item class="mat-menu-item-height"  (click)="fnTest()">
            <mat-icon>navigate_next</mat-icon>
            <span>Go to next month (B)</span>
          </button>
          <!-- Boton Checklist -->
          <button *ngIf="blnFacilitador == true" mat-menu-item class="mat-menu-item-height"  (click)="fnTest()">
            <mat-icon>settings</mat-icon>
            <span>Teams CheckLists</span>
          </button>
          <button *ngIf="blnFacilitador == true" mat-menu-item class="mat-menu-item-height"  (click)="fnTest()">
            <mat-icon>settings</mat-icon>
            <span>Logs</span>
          </button>
        </mat-menu>
    </div>
    <!-- <mat-menu #budgetExercise="matMenu">
      <button mat-menu-item class="mat-menu-item-height" (click)="fnSelectOption(13)">{{getSingleTextLabel('5013')}} 4</button>
      <button mat-menu-item class="mat-menu-item-height" (click)="fnSelectOption(14)">{{getSingleTextLabel('5013')}} 5</button>
      <button mat-menu-item class="mat-menu-item-height" (click)="fnSelectOption(15)">{{getSingleTextLabel('5013')}} 6</button>
    </mat-menu>
    <mat-menu #cashExercise="matMenu">
      <button mat-menu-item class="mat-menu-item-height" (click)="fnSelectOption(16)">{{getSingleTextLabel('5013')}} 4</button>
      <button mat-menu-item class="mat-menu-item-height" (click)="fnSelectOption(17)">{{getSingleTextLabel('5013')}} 5</button>
      <button mat-menu-item class="mat-menu-item-height" (click)="fnSelectOption(18)">{{getSingleTextLabel('5013')}} 6</button>
    </mat-menu> -->
    <div cdkDropListGroup style="background-color: transparent;">
      <!--Div para regresar dragg en areas no draggables-->
      <div  class="container_base_NoAreaDragg" cdkDropList id="cdkLstNoAreaDragg" [cdkDropListData]="cdkLstNoAreaDragg" 
          (cdkDropListDropped)="fnTest()">  
      </div>
    <!-- container para las ordenes -->
  
      <div class="container-orders" *ngIf="((status >= 31 && intOpenMarket ==2) || (currentMonth >= 2 && cdkLstAdvertasing.length != 0) || (status == 0 || status == -1) && currentMonth >= 2) || (blnFacilitador == true && status >= 31) ">
        <div cdkDropList  id="cdkLstOrders" [cdkDropListData]="orders" class="container-orders-styles container-styles-globals" [ngStyle]="{'margin-top.px':orders.length>=4?10:0}">
          <div class="example-box-destinity"
          *ngFor="let item of orders; let i = index" cdkDrag  [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard" (cdkDragStarted)="fnTest()"
          [matTooltip]="item.draggingBy">
            <!-- <img [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-cash" [ngClass]="{'animacion': (status==19 && item.valor==20 &&itemMove == item.id) || (blnMoveMonth2 && item.valor==20 &&itemMove == item.id) || (blnMoveMonth3 && item.valor==20 &&blnAllowAnim &&itemMove == item.id) || (blnMoveMonth4 && item.valor==20 &&blnAllowAnim &&itemMove == item.id) || (blnMoveMonth5 && item.valor==20 &&blnAllowAnim &&itemMove == item.id) || (blnMoveMonth6 && item.valor==20 &&blnAllowAnim &&itemMove == item.id)}" >   -->
            <div class="item-order" >
              <div class="row">
                <label class="col-4 my-auto" style="font-size: 24px; margin-top: 18px!important; color: rebeccapurple; font-weight: 700;">{{item.Bid}}</label>
                <div class="col-4" style="padding-left: 0px;">
                  <div style="margin-bottom: 0px; font-size: 12px; height: 13px; text-align: start; font-weight: 500;">{{item.OrderNum}}</div>
                  <div style="font-size: 12px; margin: 0% !important; height: 13px; text-align: start; font-weight: 500;">{{item.Terms != 0 ? item.Terms + ' days': 'Cash'}}</div>
                  <div style="margin-top: 7px;" [ngClass]="{'growOrder': status == 33 && !blnBackgroundBlue && !blnBackground2 && !blnBackground4 && !blnBackground5 && !blnBackground6 && !blnBackground7_8 && !blnBackground9, 'growOrderBackgroundBlue': status == 33 && blnBackgroundBlue, 'growOrderBackground2': status == 33 && blnBackground2, 'growOrderBackground4': status == 33 && blnBackground4, 'growOrderBackground5': status == 33 && blnBackground5, 'growOrderBackground6': status == 33 && blnBackground6, 'growOrderBackground7_8': status == 33 && blnBackground7_8, 'growOrderBackground9': status == 33 && blnBackground9}">
                    <a class="botonDeliver"  *ngIf="!item.Delivered" (click)="fnTest()" [ngClass]="{'botonDeliverdisable': status != 33}">deliver</a>
                  </div>
                </div>
                <label class="col-4 my-auto" style="font-size: 24px; margin-top: 16px!important; color: rebeccapurple; font-weight: 700; padding-right: 20px">{{item.Quantity}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
  
    <!-- <div class="container-orders" *ngIf="status >= 31 || (currentMonth >= 2 && checkBoxes[10].blnCheck && cdkLstAdvertasing.length != 0)">
      <div *ngFor="let item of orders; let i = index">
        <div style="background-image: url('./../../../assets/stacks/orderNew.svg');background-repeat: no-repeat;
            background-size: 175px 90px;" class="container-orders-styles container-styles-globals container"  
            [ngStyle]="{'left.px': 225+175*i}">
          <div class="row">
            <label class="col-4 my-auto" style="font-size: 24px; margin-top: 28px!important; color: rebeccapurple; font-weight: 700;">{{item.Bid}}</label>
            <div class="col-4" style="padding-left: 0px;">
              <div style="margin-top: 16px; margin-bottom: 0px; font-size: 12px; height: 13px; text-align: start; font-weight: 500;">{{item.OrderNum}}</div>
              <div style="font-size: 12px; margin: 0% !important; height: 13px; text-align: start; font-weight: 500;">{{item.Terms != 0 ? item.Terms + ' days': 'Cash'}}</div>
              <div style="margin-top: 7px;" [ngClass]="{'growOrder': status == 33 && !blnBackgroundBlue && !blnBackground2 && !blnBackground4 && !blnBackground5 && !blnBackground6 && !blnBackground7_8 && !blnBackground9, 'growOrderBackgroundBlue': status == 33 && blnBackgroundBlue, 'growOrderBackground2': status == 33 && blnBackground2, 'growOrderBackground4': status == 33 && blnBackground4, 'growOrderBackground5': status == 33 && blnBackground5, 'growOrderBackground6': status == 33 && blnBackground6, 'growOrderBackground7_8': status == 33 && blnBackground7_8, 'growOrderBackground9': status == 33 && blnBackground9}">
                <a class="botonDeliver"  *ngIf="!item.Delivered" (click)="fnDeliverOrder(item)" [ngClass]="{'botonDeliverdisable': status != 33}">deliver</a>
              </div>
            </div>
            <label class="col-4 my-auto" style="font-size: 24px; margin-top: 28px!important; color: rebeccapurple; font-weight: 700; padding-right: 20px">{{item.Quantity}}</label>
          </div>
        </div>
      </div>
    </div> -->
      <!-- container trash -->
      <div class="container-trash" *ngIf="isTrashContainer" style="background-color:black;">
  
        <img src="{{urlImgTrash}}"  cdkDropList id="cdkLstTrash" [cdkDropListData]="cdkLstTrash" 
          class="container-trash-styles container-styles-globals"
          (cdkDropListDropped)="fnTest()" (click)="isTrashContainer=false;">
          
      </div>
      <!-- container trash cash -->
      <div class="container-trashCash" *ngIf="currentMonth == 1" style="background-color:black;">
        <div cdkDropList id="cdkLstTrashCash" [cdkDropListData]="cdkLstTrashCash" 
          class="container-trashCash-styles container-styles-globals"
          (cdkDropListDropped)="fnTest()">
          <div class="box-piece" [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable}" 
            *ngFor="let item of cdkLstTrashCash; let i = index" cdkDrag id="{{item.id}}" (cdkDragEnded)="fnTest()"
             (cdkDragStarted)="fnTest()"
            [cdkDragDisabled]="item.draggable  || isOpenMarketDisabledBoard" [matTooltip]="item.draggingBy"
            >
            <img 
            [ngClass]="{'growCap': (status==0  && item.type == 1 || status==1  && item.type == 2 ) && currentMonth == 1 && !blnBackgroundBlue && !blnBackground2 && !blnBackground4 && !blnBackground5 && !blnBackground6 && !blnBackground7_8 && !blnBackground9,
                        'growBackgroundBlueTrash': (status==0  && item.type == 1 || status==1  && item.type == 2 ) && currentMonth == 1 && blnBackgroundBlue,
                        'growBackground2Cap': (status==0  && item.type == 1 || status==1  && item.type == 2 ) && currentMonth == 1 && blnBackground2,
                        'growBackground4Cap': (status==0  && item.type == 1 || status==1  && item.type == 2 ) && currentMonth == 1 && blnBackground4,
                        'growBackground5Cap': (status==0  && item.type == 1 || status==1  && item.type == 2 ) && currentMonth == 1 && blnBackground5,
                        'growBackground6Cap': (status==0  && item.type == 1 || status==1  && item.type == 2 ) && currentMonth == 1 && blnBackground6,
                        'growBackground7_8Cap': (status==0  && item.type == 1 || status==1  && item.type == 2 ) && currentMonth == 1 && blnBackground7_8,
                        'growBackground9Cap': (status==0  && item.type == 1 || status==1  && item.type == 2 ) && currentMonth == 1 && blnBackground9}"
            [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-trash">
          </div>
        </div>
      </div>
      <!--Container mensaje bloqueante para no permitir avanzar si tiene que escoger improvement-->
      <div *ngIf="blnShowerrorPasoImplementImprov" id="errorPasoImplementImprov" class="container-NoImprovError-styles" style="z-index: 1500;">
        <label style="font-size: 12px !important;margin:20px">{{textErrorImplementImprov}}</label>
        <button  type="button" class="btn btn-success mx-3 FontMontserratTextInputsDone calibriFont"  
              (click)="fnTest()">&nbsp;Select Improvement&nbsp;</button>
      </div>
  
      <!-- container mensaje de cash  -->
      <div id="errorPasoCash" class="container-styles-globals"></div>
      <!-- container trash Capital stock  -->
      <div class="container-trashCapitalS" *ngIf="currentMonth == 1" style="background-color:black;">
        <div cdkDropList id="cdkLstTrashCapitalS" [cdkDropListData]="cdkLstTrashCapitalS" 
          class="container-trashCapitalS-styles container-styles-globals"
          (cdkDropListDropped)="fnTest()">
          <div class="box-piece" [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable}" 
            *ngFor="let item of cdkLstTrashCapitalS; let i = index" cdkDrag 
            (cdkDragStarted)="fnTest()"
            [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard" [matTooltip]="item.draggingBy"
            >
            <img 
            [ngClass]="{'growCap': (status==0  && item.type == 1 || status==1  && item.type == 2) && !blnBackgroundBlue && !blnBackground2 && !blnBackground4 && !blnBackground5 && !blnBackground6 && !blnBackground7_8 && !blnBackground9, 
                        'growBackgroundBlueTrash': (status==0  && item.type == 1 || status==1  && item.type == 2) && blnBackgroundBlue,
                        'growBackground2Cap': (status==0  && item.type == 1 || status==1  && item.type == 2) && blnBackground2,
                        'growBackground4Cap': (status==0  && item.type == 1 || status==1  && item.type == 2) && blnBackground4,
                        'growBackground5Cap': (status==0  && item.type == 1 || status==1  && item.type == 2) && blnBackground5,
                        'growBackground6Cap': (status==0  && item.type == 1 || status==1  && item.type == 2) && blnBackground6,
                        'growBackground7_8Cap': (status==0  && item.type == 1 || status==1  && item.type == 2) && blnBackground7_8,
                        'growBackground9Cap': (status==0  && item.type == 1 || status==1  && item.type == 2) && blnBackground9}"
            [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-trash">
          </div>
        </div>
      </div>
  
      <label class="calibriBold14 calibriFont" *ngIf="currentMonth >= 3" style="position: absolute; top: 222px; left: 1108px; width: 70px; text-align: center; z-index: 1501; font-size: 9px;" (click)="fnTest()">{{getSingleTextLabel('141')}}</label>
      <div style="left: 1108px !important; top: 222px !important; position: absolute; z-index: 1500 !important; height: 18px;" *ngIf="currentMonth >= 3">
        <img src="../../../assets/button-improvement.png" class="buttonImprovement" *ngIf="improveStatus > 0 || improveStatus === -1" (click)="fnTest()" style="cursor: pointer;">
        <img src="../../../assets/button-improvement.png" class="buttonImprovement" *ngIf="improveStatus === 0" style="cursor: default;">
      </div>
   <!-- contaner rules -->
   <label class="lblnum1 calibriBold14 calibriFont" >{{getSingleTextLabel('1')}}</label>
      <div class="container-balanceSheet2">
        <div (click)="fnTest()" [ngStyle]="{'z-index': status == 0 || status == -1 ? 1501:1001}"
         class="container-balanceSheet-styles2 container-styles-globals">
          
        </div>
      </div>
      <!-- error Container Bank -->
      <div id="errorContainerBank" class="container-styles-globals"></div>
      <!-- fin error Container Bank -->
  
      <!-- contaner bank -->
      <label class="lblnum6 txtBoldBlackSmall calibriBold12 calibriFont" >{{getSingleTextLabel('6')}}</label>
      <label class="lblnum7 txtBoldBlackSmall calibriBold12 calibriFont" >{{getSingleTextLabel('7')}}</label>
      <label class="lblnum2 txtBoldBlackSmall calibriBold12 calibriFont" >{{getSingleTextLabel('2')}}</label>
      <label class="lblnum8 txtBoldBlackSmall calibriBold12 calibriFont" >{{getSingleTextLabel('8')}}</label>
      <div class="container-bank" style="background-color:black;">
        <div cdkDropList id="cdkLstBank" [cdkDropListData]="cdkLstBank" class="container-bank-styles container-styles-globals"
          (cdkDropListDropped)="fnTest()">
          <div class="example-box-destinity" *ngFor="let item of cdkLstBank; let i = index" cdkDrag 
             (cdkDragStarted)="fnTest()"
            [matTooltip]="item.draggingBy" [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable}" 
            [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard"
            [ngClass]="{'grow': status == 18 && !blnBackgroundBlue && !blnBackground2 && !blnBackground4 && !blnBackground5 && !blnBackground6 && !blnBackground7_8 && !blnBackground9,
                        'growBackgroundBlue': status == 18 && blnBackgroundBlue,
                        'growBackground2': status == 18 && blnBackground2,
                        'growBackground4': status == 18 && blnBackground4,
                        'growBackground5': status == 18 && blnBackground5,
                        'growBackground6': status == 18 && blnBackground6,
                        'growBackground7_8': status == 18 && blnBackground7_8,
                        'growBackground9': status == 18 && blnBackground9}">
            <img [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-4seleccionBank" >
            <!-- <img class="coinImg-4seleccion" src="../../../../assets/fondoCoin.png"> -->
            <!-- <label class="coinLabel-4seleccion" *ngIf="item.valor > 9"
              style="right:16px;">{{item.valor}}</label>
  
            <label *ngIf="item.valor <= 9"
              style="right:13px;">{{item.valor}}</label> -->
  
          </div>
        </div>
      </div>
      <!-- contaner capEx -->
      <div class="container-capex" style="background-color:black;">
        <div cdkDropList id="cdkLstCapEx" [cdkDropListData]="cdkLstCapEx" class="container-capEx-styles container-styles-globals"
          (cdkDropListDropped)="fnTest()"
          >
          <div class="example-box-destinity" *ngFor="let item of cdkLstCapEx; let i = index" cdkDrag 
             (cdkDragStarted)="fnTest()" (cdkDragEnded)="fnTest()" 
             [matTooltip]="item.draggingBy" [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable}" 
             [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard">
          <img [ngClass]=""
            [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" >
            <!-- <img class="coinImg-4seleccion" src="../../../../assets/fondoCoin.png"> -->
            <!-- <label class="coinLabel-4seleccion" *ngIf="item.valor > 9"
              style="right:16px;">{{item.valor}}</label>
  
            <label class="coinLabel-4seleccion" *ngIf="item.valor <= 9"
              style="right:13px;">{{item.valor}}</label> -->
  
          </div>
        </div>
      </div>
  
      <!-- to pay capex -->
      <div cdkDropList id="payCapex" [cdkDropListData]="paidCapEx" 
      [ngClass]="{
        'payCapex': status == 4 || status == 9 || jsonBlnOpenModeActions.isBuyBuild || jsonBlnOpenModeActions.isBuySecondMachine, 
        'payCapexPaied': status == 5 || status == 10 || !jsonBlnOpenModeActions.isBuyBuild || jsonBlnOpenModeActions.isBuySecondMachine}" 
      *ngIf="((status == 4 || status == 5 || status == 9 || status == 10) && currentMonth == 1) || jsonBlnOpenModeActions.showMessageOwe20"
      (cdkDropListDropped)="fnTest()" style="border-style: none;">
        <ng-container *ngIf="status == 4 || status == 9 || jsonBlnOpenModeActions.isBuyBuild || jsonBlnOpenModeActions.isBuySecondMachine; else userPaid">
          <div class="pLabelbox"><p class="labelBillPagarStep15">20</p></div>
        </ng-container>
        <ng-template #userPaid>
          <div class="pLabelboxPaid" style="margin-left: 25px;"> </div>
        </ng-template>
      </div>
  
      <div cdkDropList id="payCapex" [cdkDropListData]="paidCapEx"  class='payCapex'
     *ngIf="((jsonBlnOpenModeActions.showMessageOwe10) && currentMonth >= 3 && jsonBlnOpenModeActions.isEfficiency1)"
      (cdkDropListDropped)="fnTest()" style="border-style: none;"
      >
        <ng-container *ngIf="jsonBlnOpenModeActions.isPayBill; else userPaid">
          <div class="pLabelbox"><p class="labelBillPagarStep15">10</p></div>
        </ng-container>
        <ng-template #userPaid>
          <div class="pLabelboxPaid" style="margin-left: 25px;"> </div>
        </ng-template>
      </div>
  
      <!-- to pay capex machine customizing -->
      <div cdkDropList id="payCapex" [cdkDropListData]="paidCapEx" 
      
      *ngIf="jsonBlnOpenModeActions.showBill10 && currentMonth >= 3"
      (cdkDropListDropped)="fnTest()" style="border-style: none;">
        <ng-container *ngIf="jsonBlnOpenModeActions.isPayBill10 && currentMonth >= 3; else userPaid">
          <div class="pLabelbox"><p class="labelBillPagarStep15">10</p></div>
        </ng-container>
        <ng-template #userPaid>
          <div class="pLabelboxPaid" style="margin-left: 25px;"> </div>
        </ng-template>
      </div>
  
      <!-- error paso 4 -->
      <div id="errorPaso4" *ngIf="((status == 4 || status == 9) && currentMonth == 1)" class="container-styles-globals"></div>
      <!-- fin error paso 4 -->
  
      <!-- contaner LabourPool -->
      <div class="container-LabourPool" style="background-color:transparent;"> <!--{{blnBackgroundBlue}}-->
        <div cdkDropList id="cdkLstLabourP" [cdkDropListData]="cdkLstLabourP" class="container-labourP-styles container-styles-globals"
          (cdkDropListDropped)="fnTest()">
          <div class="example-box-destinity" *ngFor="let item of cdkLstLabourP; let i = index" cdkDrag 
              (cdkDragStarted)="fnTest()"
              [matTooltip]="item.draggingBy" [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable}" 
              
             [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard">
            <img [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+(item.type === 6 && (item.valor === 1 || item.valor === 12) ? '.png' : '.svg')" class="coinImg-4seleccionLabourPool" [ngClass]="{'coinImg-4seleccionLabourPool2': item.valor==12}">
            <!-- <img class="coinImg-4seleccion" src="../../../../assets/fondoCoin.png" > -->
            <!-- <label class="coinLabel-4seleccion" *ngIf="item.valor > 9"
              style="right:16px;">{{item.valor}}</label>
  
            <label class="coinLabel-4seleccion" *ngIf="item.valor <= 9"
              style="right:13px;">{{item.valor}}</label> -->
  
          </div>
        </div>
      </div>
  
      <!-- contaner suppliers -->
      <div class="container-suppliers" style="background-color:black;">
        <div cdkDropList id="cdkLstSuppliers" [cdkDropListData]="cdkLstSuppliers" class="container-suppliers-styles container-styles-globals"
          (cdkDropListDropped)="fnTest()">
          <div class="example-box-destinity" *ngFor="let item of cdkLstSuppliers; let i = index" cdkDrag 
            [ngClass]="{'grow': status == 14 && !blnBackgroundBlue && !blnBackground2 && !blnBackground4 && !blnBackground5 && !blnBackground6 && !blnBackground7_8 && !blnBackground9, 'growBackgroundBlue': status == 14 && blnBackgroundBlue, 'growBackground2': status == 14 && blnBackground2, 'growBackground4': status == 14 && blnBackground4, 'growBackground5': status == 14 && blnBackground5, 'growBackground6': status == 14 && blnBackground6, 'growBackground7_8': status == 14 && blnBackground7_8, 'growBackground9': status == 14 && blnBackground9 }" (click)="fnTest()"
            [matTooltip]="item.draggingBy" [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable, 'grow': status == 4 && currentMonth == 2}" 
             (cdkDragStarted)="fnTest()"
             [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard">
            <img [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'"
            [ngStyle]="{'width.px': (item.valor == 18 && item.type == 7) ? 66:((item.valor >= 12 && item.type == 7 || item.valor >= 4 && item.type == 14)) ? 60:30}"
              class="coinImg-suppliers" >
          </div>
        </div>
      </div>
      <!-- move materials to inside machine -->
      <div class="moveMaterials" cdkDropList id="cdkLstInsideMachinePeople" [cdkDropListData]="cdkLstInsideMachinePeople"  (cdkDropListDropped)="fnTest()"
      *ngIf="((status == 21 || status == 22 || status == 23) && currentMonth == 1)" style="border-style: none; z-index:1200">
        <!--<ng-container *ngIf="status == 21">
          Load Raw Materials to Machine
        </ng-container>
      -->
        <ng-container *ngIf="status == 22">
          <div class="pLabelbox"><p class="labelBillPagarStep15">12</p></div> 
        </ng-container>
        <ng-container *ngIf="status == 23">    
          <div [ngStyle]="{'pot': blnAnimCoinPeople == true}"  class="pLabelboxPaid" style="margin-left: 25px;"></div>
          <div *ngIf="blnAnimCoinPeople == true"><img [src]="'../../../../assets/stacks/12_1.svg'" /></div>
        </ng-container>
      </div>
  
      <!-- to pay suppliers -->
      <div cdkDropList id="paySuppliers" [cdkDropListData]="paySuppliers" 
      class="paySuppliers"
       *ngIf="((status == 15 || status == 16) && currentMonth == 1) || ((jsonBlnOpenModeActions.showMessageOwe12 || jsonBlnOpenModeActions.showMessageOwe18) && isModeOpen  && currentMonth >= 2) || (!isModeOpen && (jsonBlnOpenModeActions.showMessageOwe12 || jsonBlnOpenModeActions.showMessageOwe18)  && currentMonth >= 2)" 
      (cdkDropListDropped)="fnTest()" style="border-style: none;">
        <ng-container  *ngIf="((status == 15) && currentMonth == 1) || ((jsonBlnOpenModeActions.isBuyMaterials) && isModeOpen  && currentMonth >= 2) || ((!isModeOpen && status == 4) && currentMonth >= 2); else userPaidSuppliers">
          <!-- <div class="pLabelbox"><p class="labelBillPagarStep15">{{jsonBlnOpenModeActions.showMessageOwe12?18:12}}</p></div> -->
          <div [ngClass] = "{'grow' : (status==4) && currentMonth == 2}" class="pLabelbox"><p class="labelBillPagarStep15">{{jsonBlnOpenModeActions.showMessageOwe12?12:jsonBlnOpenModeActions.showMessageOwe18 ? 18:12}}</p></div>
          
        </ng-container>
        <ng-template #userPaidSuppliers>
          <div [ngClass]="{'pLabelboxPaidSup': cdkLstPayables60.length <= 0, 'estilo60Days': cdkLstPayables60.length > 0}"></div>
            <div *ngIf="cdkLstPayables60.length > 0">
              <label style="position: absolute; animation: rolloutdelete 5s"> See you in 60 Days! </label>
            </div>
        </ng-template>
      </div>
  
      <!-- to pay suppliers improvement raw materials -->
      <div cdkDropList id="paySuppliers" [cdkDropListData]="paySuppliers" 
      class="paySuppliers"
       *ngIf="(jsonBlnOpenModeActions.showMessageOwe4 && ((jsonBlnOpenModeActions.showMessageOwe4 && isModeOpen) && currentMonth >= 2) || (!isModeOpen && jsonBlnOpenModeActions.showMessageOwe4 && jsonBlnOpenModeActions.isRawMaterials1 && currentMonth >= 2))" 
      (cdkDropListDropped)="fnTest()" style="border-style: none;">
        <ng-container *ngIf="((status == 15) && currentMonth == 1) || ((jsonBlnOpenModeActions.isBuyMaterials && jsonBlnOpenModeActions.showMessageOwe4) && isModeOpen  && currentMonth >= 2) || ((!isModeOpen  && jsonBlnOpenModeActions.showMessageOwe4 && status == 4) && jsonBlnOpenModeActions.isRawMaterials1 && currentMonth >= 2); else userPaidSuppliers">
          <div class="pLabelbox"><p class="labelBillPagarStep15">{{jsonBlnOpenModeActions.isEfficiency1 && jsonBlnOpenModeActions.isRawMaterials1?6:4}}</p></div>
        </ng-container>
        <ng-template #userPaidSuppliers>
          <div [ngClass]="{'pLabelboxPaidSup': cdkLstPayables60.length <= 0, 'estilo60Days': cdkLstPayables60.length > 0}"></div>
            <div *ngIf="cdkLstPayables60.length > 0">
              <label style="position: absolute; animation: rolloutdelete 5s"> See you in 60 Days! </label>
            </div>
        </ng-template>
      </div>
  
      <!-- error paso 15 -->
      <div id="errorPaso15" *ngIf="status == 15" class="container-styles-globals"></div>
      <!-- fin error paso 15 -->
  
      <!-- error Container Trash -->
      <div id="errorContainerTrash" class="container-styles-globals"></div>
      <!-- fin error Container Trash -->
  
      <!-- contaner row Materials -->
      <div class="container-materials" style="background-color:black;">
        <div cdkDropList id="cdkLstMaterials" [cdkDropListData]="cdkLstMaterials" 
          class="container-materials-styles container-styles-globals"
          [ngClass]="{'grow': ((status == 14 || status == 21) && currentMonth == 1 && !blnBackgroundBlue && !blnBackground2 && !blnBackground4 && !blnBackground5 && !blnBackground6 && !blnBackground7_8 && !blnBackground9), 
                      'growBackgroundBlue': ((status == 14 || status == 21) && currentMonth == 1 && blnBackgroundBlue),
                      'growBackground2': ((status == 14 || status == 21) && currentMonth == 1 && blnBackground2),
                      'growBackground4': ((status == 14 || status == 21) && currentMonth == 1 && blnBackground4),
                      'growBackground5': ((status == 14 || status == 21) && currentMonth == 1 && blnBackground5),
                      'growBackground6': ((status == 14 || status == 21) && currentMonth == 1 && blnBackground6),
                      'growBackground7_8': ((status == 14 || status == 21) && currentMonth == 1 && blnBackground7_8),
                      'growBackground9': ((status == 14 || status == 21) && currentMonth == 1 && blnBackground9)}"
          (cdkDropListDropped)="fnTest()">
          <div class="example-box-destinity" *ngFor="let item of cdkLstMaterials; let i = index" cdkDrag 
             (cdkDragStarted)="fnTest()" (click)="fnTest()"
            [matTooltip]="item.draggingBy" [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable}" 
            [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard">
            <img [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-trash" 
            [ngStyle]="{'width.px': (item.valor == 18) ? 66:(item.valor >= 12 && item.type == 7 || item.valor >= 4 && item.type == 14) ? 60:30}" >
          </div>
        </div>
      </div>
  
      <!-- error paso 14 posicionado abajo de machinary-->
      <div id="errorPaso14" *ngIf="status == 14" class="container-styles-globals"></div>
      <!-- fin error paso 14 -->
  
      <!-- contaner land & build -->
      <label class="lblnum61 txtBoldWhiteMedium calibriBold15 calibriFont" [ngStyle]="{'color': blnBackground7_8 || blnBackground9 || blnBackground2 ? 'black' : 'white'}">{{getSingleTextLabel('61')}}</label>
      <div class="container-LandBuild" style="background-color:black;">
        <div cdkDropList id="cdkLstLandBuild" [cdkDropListData]="cdkLstLandBuild" 
        [ngClass]="{'grow': ((status==3) && currentMonth == 1 && !blnBackgroundBlue && !blnBackground2 && !blnBackground4 && !blnBackground5 && !blnBackground6 && !blnBackground7_8 && !blnBackground9), 
                    'growBackgroundBlue': ((status==3) && currentMonth == 1 && blnBackgroundBlue),
                    'growBackground2': ((status==3) && currentMonth == 1 && blnBackground2),
                    'growBackground4': ((status==3) && currentMonth == 1 && blnBackground4),
                    'growBackground5': ((status==3) && currentMonth == 1 && blnBackground5),
                    'growBackground6': ((status==3) && currentMonth == 1 && blnBackground6),
                    'growBackground7_8': ((status==3) && currentMonth == 1 && blnBackground7_8),
                    'growBackground9': ((status==3) && currentMonth == 1 && blnBackground9)}"
        class="container-build-styles container-styles-globals"
          (cdkDropListDropped)="fnTest()">
          <div style="position: absolute; margin-left:25px">
            <img *ngIf="cdkLstLandBuild[0]?.valor == 0 || cdkLstLandBuild.length == 0" [src]="'../../../assets/edificioapagado.png'" style="width:100px" />
            <img *ngIf="cdkLstLandBuild[0]?.valor == 20" [src]="'../../../assets/edificioencendido.png'" style="width:100px" />
          </div>
          <div style="width: 32px; height: 70px;" class="example-box-destinity box-right" *ngFor="let item of cdkLstLandBuild; let i = index" cdkDrag 
             (cdkDragStarted)="fnTest()"
             [matTooltip]="item.draggingBy" [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable}" 
             [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard">
            <img 
            [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-4seleccionLandBuilding">
            <!-- <img src="../../../../assets/fondoCoin.png" width="40" style="z-index: 5000 !important;" height="32">
            <label *ngIf="item.valor > 9"
              style="color: rgb(100, 71, 7);position: relative;font-size: 13px;font-weight:bold;right:27px;">{{item.valor}}</label>
  
            <label *ngIf="item.valor <= 9"
              style="color: rgb(100, 71, 7);position: relative;font-size: 13px;font-weight:bold;right:24px;">{{item.valor}}</label> -->
  
          </div>
        </div>
      </div>
      <!-- error paso 4 -->
      <div id="errorPaso3" *ngIf="((status == 3) && currentMonth == 1)" class="container-styles-globals"></div>
      <!-- fin error paso 4 -->
      <!-- contaner finished good inventory -->
      <label *ngIf="blnFacilitador == true" class="lblnumFacilitador txtBoldWhiteLarge" > Facilitator </label>
      <label class="lblnum52 txtBoldWhiteLarge calibriBold23 calibriFont" [ngStyle]="{'color': blnBackground7_8 || blnBackground9 || blnBackground2 ? 'black' : 'white'}">{{getSingleTextLabel('52')}}</label>
      <label class="lblnum57 txtBoldWhiteLarge calibriBold23 calibriFont" [ngStyle]="{'color': blnBackground7_8 || blnBackground9 || blnBackground2 ? 'black' : 'white'}">{{getSingleTextLabel('57')}}</label>
      <div class="container-finishedGI" style="background-color:black;">
        <div cdkDropList id="cdkLstFinishedFI" [cdkDropListData]="cdkLstFinishedFI" class="container-finishedGI-styles container-styles-globals"
          (cdkDropListDropped)="fnTest()"
          [ngClass]="{'grow': (status==31 || status == 32) && !blnBackgroundBlue && !blnBackground2 && !blnBackground4 && !blnBackground5 && !blnBackground6 && !blnBackground7_8 && !blnBackground9,
                      'growBackgroundBlue': (status==31 || status == 32) && blnBackgroundBlue,
                      'growBackground2': (status==31 || status == 32) && blnBackground2,
                      'growBackground4': (status==31 || status == 32) && blnBackground4,
                      'growBackground5': (status==31 || status == 32) && blnBackground5,
                      'growBackground6': (status==31 || status == 32) && blnBackground6,
                      'growBackground7_8': (status==31 || status == 32) && blnBackground7_8,
                      'growBackground9': (status==31 || status == 32) && blnBackground9}">
          <div class="example-box-destinity" style="display: inline-flex !important;margin-right:5px ; width:60px; height: 58px;"
          [ngStyle]="{'max-width.px': (item.valor >= 6 && item.type == 7 || item.valor >= 6 && item.type == 8 || item.valor >= 10 && item.type == 17 || 
          item.valor >= 2 && item.type == 14 || item.valor >= 8 && item.type == 16 || item.valor >= 6 && item.type == 18) ? 63:30, 
          'height.px':(item.valor>=15 && item.valor<=18 && item.type==8||item.valor>=20 && item.valor<=24 && item.type==16 ||item.valor>=25 && item.valor<=30 && item.type==17 || item.valor>=15 && item.valor<=18 && item.type==18)?85:
          (item.valor>=21 && item.valor<=24 && item.type==8||item.valor>=28 && item.valor<=32 && item.type==16 ||item.valor>=35 && item.valor<=40 && item.type==17 || item.valor>=21 && item.valor<=24 && item.type==18)?115:
          (item.valor>=27 && item.valor<=30 && item.type==8||item.valor>=36 && item.valor<=40 && item.type==16 ||item.valor>=45 && item.valor<=50 && item.type==17 || item.valor>=27 && item.valor<=30 && item.type==18)?145:
          (item.valor>=33 && item.valor<=36 && item.type==8||item.valor>=44 && item.valor<=48 && item.type==16 ||item.valor>=55 && item.valor<=60 && item.type==17 || item.valor>=33 && item.valor<=36 && item.type==18)?175:
          (item.valor>=39 && item.valor<=42 && item.type==8||item.valor>=52 && item.valor<=56 && item.type==16 ||item.valor>=65 && item.valor<=70 && item.type==17 || item.valor>=39 && item.valor<=42 && item.type==18)?205:
          (item.valor>=45 && item.valor<=48 && item.type==8||item.valor>=60 && item.valor<=64 && item.type==16 ||item.valor>=75 && item.valor<=80 && item.type==17 || item.valor>=45 && item.valor<=48 && item.type==18)?235:
          (item.valor>=51 && item.valor<=54 && item.type==8||item.valor>=68 && item.valor<=72 && item.type==16 ||item.valor>=85 && item.valor<=90 && item.type==17 || item.valor>=51 && item.valor<=54 && item.type==18)?265:
          (item.valor>=57 && item.valor<=60 && item.type==8||item.valor>=76 && item.valor<=80 && item.type==16 ||item.valor>=95 && item.valor<=100 && item.type==17 || item.valor>=57 && item.valor<=60 && item.type==18)?295:58}"
            *ngFor="let item of cdkLstFinishedFI; let i = index" cdkDrag 
             (cdkDragStarted)="fnTest()" (click)="fnTest()"
            [matTooltip]="item.draggingBy" [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable}" 
            [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard">
            <img 
            style="position:absolute"
              [ngStyle]="{'max-width.px': (item.valor >= 6 && item.type == 7 || item.valor >= 6 && item.type == 8 || item.valor >= 10 && item.type == 17 || 
              item.valor >= 2 && item.type == 14 || item.valor >= 8 && item.type == 16 || item.valor >= 6 && item.type == 18) ? 63:30}"
              [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-workInProgres">
            
          </div>
        </div>
      </div>
      <!-- error paso 31 posicionado debajo de finised good inventori -->
      <div id="errorPaso31" *ngIf="((status == 31) && currentMonth == 1) || ((status == 1) && currentMonth == 2)" class="container-styles-globals"></div>
      <!-- fin error paso 25 -->
      <!-- contaner Loans 90 days -->
      <label class="lblnum69 txtWhiteSmall calibri14 calibriFont" >{{getSingleTextLabel('69')}}</label>
      <label class="lblnum69 txtWhiteSmall calibriBold15 calibriFont" style="top: 627px !important; left: 540px !important; width: 132px; text-align: center;" >{{getSingleTextLabel('66')}}</label>
      <label class="lblnum51Loans txtWhiteTransp calibri16 calibriFont">{{getSingleTextLabel('51')}}</label>
      <label class="lblnumNumero90Loans txtBoldWhiteLargeNumeros ptSansBold36">90</label>
      <label class="lblnumNumero60Loans txtBoldWhiteLargeNumeros ptSansBold36">60</label>
      <label class="lblnumNumero30Loans txtBoldWhiteLargeNumeros ptSansBold36">30</label>
       <label class="lblnum50Loans txtWhiteTransp calibri16 calibriFont">{{getSingleTextLabel('50')}}</label>
       <label class="lblnum49Loans txtWhiteTransp calibri16 calibriFont">{{getSingleTextLabel('49')}}</label>
      <div class="container-Loans90" style="background-color:black;">
        <div cdkDropList id="cdkLstLoans90" [cdkDropListData]="cdkLstLoans90" class="container-loans90-styles container-styles-globals"
          (cdkDropListDropped)="fnTest()"
          [ngClass]="{'grow': status == 18 && !blnBackgroundBlue && !blnBackground2 && !blnBackground4 && !blnBackground5 && !blnBackground6 && !blnBackground7_8 && !blnBackground9, 'growBackgroundBlue': status == 18 && blnBackgroundBlue, 'growBackground2': status == 18 && blnBackground2, 'growBackground4': status == 18 && blnBackground4, 'growBackground5': status == 18 && blnBackground5, 'growBackground6': status == 18 && blnBackground6, 'growBackground7_8': status == 18 && blnBackground7_8, 'growBackground9': status == 18 && blnBackground9}">
          <div class="example-box-destinity-inclinacion-20" *ngFor="let item of cdkLstLoans90; let i = index" cdkDrag 
             (cdkDragStarted)="fnTest()"
             [matTooltip]="item.strDescription ? item.strDescription :   item.draggingBy" [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable,
             'grow': (status==2) && currentMonth == 2 && !blnBackgroundBlue && !blnBackground2 && !blnBackground4 && !blnBackground5 && !blnBackground6 && !blnBackground7_8 && !blnBackground9,
             'growBackgroundBlue': (status==2) && currentMonth == 2 && blnBackgroundBlue,
             'growBackground2': (status==2) && currentMonth == 2 && blnBackground2,
             'growBackground4': (status==2) && currentMonth == 2 && blnBackground4,
             'growBackground5': (status==2) && currentMonth == 2 && blnBackground5,
             'growBackground6': (status==2) && currentMonth == 2 && blnBackground6,
             'growBackground7_8': (status==2) && currentMonth == 2 && blnBackground7_8,
             'growBackground9': (status==2) && currentMonth == 2 && blnBackground9}" 
             [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard">
            <img [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-trash" >
          
          </div>
        </div>
      </div>
      <!-- contaner Loans animación mes 1-->
      <!--Esta animacion manda de loans 90 a cash-->
      <div *ngIf="currentMonth == 1 && status == 21 && blnAllowAnim" class="container-Loans60" style="background-color:black;">
        <div  id="slide" class="container-loans60-styles-move container-styles-globals-move">
   
            <img  [src]="'../../../../assets/stacks/20_1.svg'" class="coinImg-trash-move" >
         
        </div>
      </div> 
      <div *ngIf="currentMonth >=2 && blnAllowAnimCapEx && jsonBlnOpenModeActions.isSoldBuild == true " class="container-Loans60" style="background-color:black;">
        <div  id="slide" class="container-cap-to-cash container-styles-globals-move">
   
            <img  [src]="'../../../../assets/stacks/20_1.svg'" class="coinImg-trash-move-to-cash" >
         
        </div>
      </div> 
    <!-- contaner Loans animación mes 2-->
      <div *ngIf="currentMonth >= 2 && blnAllowAnim && this.jsonBlnOpenModeActions.isBuyLoan == true" class="container-Loans60" style="background-color:black;">
        <div  id="slide" class="container-loans60-styles-move container-styles-globals-move">
            <img  [src]="'../../../../assets/stacks/20_1.svg'" class="coinImg-trash-move" >
        </div>
      </div> 
  
      <!-- contaner Loans 60 days -->
      <div *ngIf="currentMonth >= 2" class="container-Loans60" style="background-color:black;">
        <div cdkDropList id="cdkLstLoans60" [cdkDropListData]="cdkLstLoans60" class="container-loans60-styles container-styles-globals"
          (cdkDropListDropped)="fnTest()">
          <div class="example-box-destinity-inclinacion-23" *ngFor="let item of cdkLstLoans60; let i = index" cdkDrag 
             (cdkDragStarted)="fnTest()"  [matTooltip]="item.strDescription ? item.strDescription :   item.draggingBy"
             [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard">
            <img [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-trash" >
          
          </div>
        </div>
      </div>
      <!-- contaner Loans 30 days -->
      <div *ngIf="currentMonth >= 2" class="container-Loans30" style="background-color:black;">
        <div cdkDropList id="cdkLstLoans30" [cdkDropListData]="cdkLstLoans30" class="container-loans30-styles container-styles-globals"
          (cdkDropListDropped)="fnTest()">
          <div class="example-box-destinity-inclinacion-23" *ngFor="let item of cdkLstLoans30; let i = index" cdkDrag 
             (cdkDragStarted)="fnTest()"  [matTooltip]="item.strDescription ? item.strDescription :   item.draggingBy"
             [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard">
            <img [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-trash" >
          
          </div>
        </div>
      </div>
      <!--Animacion COGS a Cash-->
      <div style="background-color:black;" *ngIf="lista2.length>0 && moveCash==true">
        <div id="slide" class="container-styles-globals-move" *ngFor="let item of lista2; let i = index;" style="top: 300px;" [ngStyle]="{'left.px':i==lista2.length-1?670:i==lista2.length-2?700:i==lista2.length-3?730:760}" [ngClass]="{'animacionReceivables30':moveCash == true}" >
          <img  [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-cash">
        </div>
      </div>
      <!--Animacion COGS a Cash-->
      <div class="lblnum47">
        <label class="txtWhiteLarge ptSansBold36" style="color: black;">{{getSingleTextLabel('47')}}</label>
      </div>
      <div class="container-cash" style="background-color:black;">
        <div cdkDropList id="cdkLstCash" [cdkDropListData]="cdkLstCash" class="container-cash-styles container-styles-globals" 
          (cdkDropListDropped)="fnTest()"
          [ngClass]="{
            'grow': (status==0 || status==4 || status==9 || status == 15 || status == 18 || status == 19 || status == 22 || status == 27 || status == 28 || status == 30) && currentMonth == 1 && !blnBackgroundBlue && !blnBackground2 && !blnBackground4 && !blnBackground5 && !blnBackground6 && !blnBackground7_8 && !blnBackground9,
            'growBackgroundBlue': (status==0 || status==4 || status==9 || status == 15 || status == 18 || status == 19 || status == 22 || status == 27 || status == 28 || status == 30) && currentMonth == 1 && blnBackgroundBlue,
            'growBackground2': (status==0 || status==4 || status==9 || status == 15 || status == 18 || status == 19 || status == 22 || status == 27 || status == 28 || status == 30) && currentMonth == 1 && blnBackground2,
            'growBackground4': (status==0 || status==4 || status==9 || status == 15 || status == 18 || status == 19 || status == 22 || status == 27 || status == 28 || status == 30) && currentMonth == 1 && blnBackground4,
            'growBackground5': (status==0 || status==4 || status==9 || status == 15 || status == 18 || status == 19 || status == 22 || status == 27 || status == 28 || status == 30) && currentMonth == 1 && blnBackground5,
            'growBackground6': (status==0 || status==4 || status==9 || status == 15 || status == 18 || status == 19 || status == 22 || status == 27 || status == 28 || status == 30) && currentMonth == 1 && blnBackground6,
            'growBackground7_8': (status==0 || status==4 || status==9 || status == 15 || status == 18 || status == 19 || status == 22 || status == 27 || status == 28 || status == 30) && currentMonth == 1 && blnBackground7_8,
            'growBackground9': (status==0 || status==4 || status==9 || status == 15 || status == 18 || status == 19 || status == 22 || status == 27 || status == 28 || status == 30) && currentMonth == 1 && blnBackground9,
            'cashContainerCircularShape': (status==0 || status==4 || status==9 || status == 15 || status == 18 || status == 19 || status == 22 || status == 27 || status == 28 || status == 30) && currentMonth == 1
          }"
          attr.style="{{cdkLstCash.length>=13 ? 'left: 690px !important; top: 85px !important' : cdkLstCash.length>=10 ? 'left: 690px !important; top: 135px !important' : cdkLstCash.length>=9 ? 'left: 690px !important; top: 205px !important' : 'left: 650px !important; top: 265px !important'}}"
          [ngStyle]="{
            'max-width.px': cdkLstCash.length>=9 ? 120 : 200,
            'height.px':  cdkLstCash.length>=13? 328 : cdkLstCash.length>=10? 280 : cdkLstCash.length>=9? 205 : 145
          }">
          <div class="example-box-destinity" style="top:20px; position:relative; width: 30px" 
          *ngFor="let item of cdkLstCash; let i = index;" cdkDrag id="{{item.id}}" (cdkDragEnded)="fnTest()" 
            (click)="fnTest()" (cdkDragStarted)="fnTest()"
            [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard" [matTooltip]="item.strDescription ? item.strDescription :   item.draggingBy" [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable, 'animacionRec60':itemsToMove==1 && moveCash==true && i==cdkLstCash.length-1 || itemsToMove==2 && moveCash==true && (i==cdkLstCash.length-1 ||i==cdkLstCash.length-2) || itemsToMove==3 && moveCash==true && (i==cdkLstCash.length-1 ||i==cdkLstCash.length-2 ||i==cdkLstCash.length-3) || itemsToMove==4 && moveCash==true && (i==cdkLstCash.length-1 ||i==cdkLstCash.length-2 ||i==cdkLstCash.length-3||i==cdkLstCash.length-4)}" >
            <img [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" style="margin-left: 0px; margin-right:0px" class="coinImg-cash" [ngClass]="{'animacion': (status==19 && item.valor==20 &&itemMove == item.id) || (blnMoveMonth2 && item.valor==20 &&itemMove == item.id) || (blnMoveMonth3 && item.valor==20 &&blnAllowAnim &&itemMove == item.id) || (blnMoveMonth4 && item.valor==20 &&blnAllowAnim &&itemMove == item.id) || (blnMoveMonth5 && item.valor==20 &&blnAllowAnim &&itemMove == item.id) || (blnMoveMonth6 && item.valor==20 &&blnAllowAnim &&itemMove == item.id)}" >  
          </div>
        </div>
      </div>
      <!-- error paso 1 -->
      <div id="errorPaso1" *ngIf="status == 0 || status == 18" class="container-styles-globals"></div>
      <!-- fin error paso 1 -->
       <!-- contaner Receibables 90 days -->
       <label class="lblnum48 txtWhiteSmall calibriBold15 calibriFont" >{{getSingleTextLabel('48')}}</label>
       <label class="lblnum51 txtWhiteTransp calibri16 calibriFont">{{getSingleTextLabel('51')}}</label>
       <label class="lblnumNumero90 txtBoldWhiteLargeNumeros ptSansBold36">90</label>
       <label class="lblnumNumero60 txtBoldWhiteLargeNumeros ptSansBold36">60</label>
       <label class="lblnumNumero30 txtBoldWhiteLargeNumeros ptSansBold36">30</label>
       <label class="lblnum50 txtWhiteTransp calibri16 calibriFont">{{getSingleTextLabel('50')}}</label>
       <label class="lblnum49 txtWhiteTransp calibri16 calibriFont">{{getSingleTextLabel('49')}}</label>
       <!--Animacion de receivables para 90-->
       <div style="background-color:black;" *ngIf="lista2.length>0 && move90 == true">
         <div id="slide" class="container-styles-globals-move" *ngFor="let item of lista2; let i = index" style="top: 90px; left: 600px" [ngStyle]="{'left.px':i==lista2.length-1?600:i==lista2.length-2?630:i==lista2.length-3?660:690}" [ngClass]="{'animacionReceivables90':move90 == true}" >
            <img  [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-cash">
         </div>
       </div>
       <div class="container-Receivables90" style="background-color:black;">
       <div *ngIf="currentMonth != 1 || boardMode == 'DM'" class="container-Receivables90" style="background-color:black;" >
        <div cdkDropList id="cdkLstReceivables90" [cdkDropListData]="cdkLstReceivables90" class="container-receivables90-styles container-styles-globals"
          (cdkDropListDropped)="fnTest()">
          <div class="example-box-destinity-inclinacion-pos20" *ngFor="let item of cdkLstReceivables90; let i = index" cdkDrag 
             (cdkDragStarted)="fnTest()"
             (click)="fnTest()"
             [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard" [matTooltip]="item.strDescription ? item.strDescription :   item.draggingBy"
             [ngClass]="{'animacionRec60':(itemsToMove == 1 && move90 == true && i==cdkLstReceivables90.length -1 || itemsToMove == 2 && move90 == true && (i==cdkLstReceivables90.length -1 || i == cdkLstReceivables90.length-2) || itemsToMove == 3 && move90 == true && (i==cdkLstReceivables90.length -1 || i == cdkLstReceivables90.length-2 || i == cdkLstReceivables90.length-3) || itemsToMove == 4 && move90 == true && (i==cdkLstReceivables90.length -1 || i == cdkLstReceivables90.length-2 || i == cdkLstReceivables90.length-3 || i == cdkLstReceivables90.length-4)),
                         'box-piece-enable': !item.draggable, 
                         'box-piece-disable': item.draggable, 
                         'grow': (status==1) && currentMonth == 2 && !blnBackgroundBlue && !blnBackground2 && !blnBackground4 && !blnBackground5 && !blnBackground6 && !blnBackground7_8 && !blnBackground9,
                         'growBackgroundBlue': (status==1) && currentMonth == 2 && blnBackgroundBlue,
                         'growBackground2': (status==1) && currentMonth == 2 && blnBackground2,
                         'growBackground4': (status==1) && currentMonth == 2 && blnBackground4,
                         'growBackground5': (status==1) && currentMonth == 2 && blnBackground5,
                         'growBackground6': (status==1) && currentMonth == 2 && blnBackground6,
                         'growBackground7_8': (status==1) && currentMonth == 2 && blnBackground7_8,
                         'growBackground9': (status==1) && currentMonth == 2 && blnBackground9}">
            <img 
            [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-cash">
          </div>
        </div>
      </div>
      <!--Animacion de receivables para 60 Mes 1  -->
      <div style="background-color:black;" *ngIf="cdkLstReceivables60Anim.length>0">
        <div id="slide" class="container-styles-globals-move" *ngFor="let item of cdkLstReceivables60Anim; let i = index" [ngStyle]="{'top.px':i==cdkLstReceivables60Anim.length-1?170:170, 'left.px':i==cdkLstReceivables60Anim.length-1?570:i==cdkLstReceivables60Anim.length-2?600:i==cdkLstReceivables60Anim.length-3?630:660}" [ngClass]="{'animacionReceivables60':move60 == true}">
            <img  [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-cash">
        </div>
      </div>
      <!--Animacion de receivables para 60 Mes 2 en adelante  -->
      <div style="background-color:black;" *ngIf="lista2.length>0 && move60==true">
        <div id="slide" class="container-styles-globals-move" *ngFor="let item of lista2; let i = index" [ngStyle]="{'top.px':i==lista2.length-1?170:170, 'left.px':i==lista2.length-1?570:i==lista2.length-2?600:i==lista2.length-3?630:660}" [ngClass]="{'animacionReceivables60':move60 == true}">
            <img  [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-cash">
        </div>
      </div>
      <!-- contaner Receivables 60 days -->
      <div class="container-Receivables60" style="background-color:black;">
        <div cdkDropList id="cdkLstReceivables60" [cdkDropListData]="cdkLstReceivables60" class="container-receivables60-styles container-styles-globals"
          (cdkDropListDropped)="fnTest()">
          <div class="example-box-destinity-inclinacion-pos22" *ngFor="let item of cdkLstReceivables60; let i = index" cdkDrag
             (cdkDragStarted)="fnTest()"
             (click)="fnTest()"
             [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard" [matTooltip]="item.strDescription ? item.strDescription :   item.draggingBy"
             [ngClass]="{'animacionRec60':(itemsToMove == 1 && move60 == true && i==cdkLstReceivables60.length-1 || itemsToMove == 2 && move60 == true && (i==cdkLstReceivables60.length -1 || i == cdkLstReceivables60.length-2) || itemsToMove == 3 && move60 == true && (i==cdkLstReceivables60.length -1 || i == cdkLstReceivables60.length-2 || i == cdkLstReceivables60.length-3) || itemsToMove == 4 && move60 == true && (i==cdkLstReceivables60.length -1 || i == cdkLstReceivables60.length-2 || i == cdkLstReceivables60.length-3 || i == cdkLstReceivables60.length-4)  || move2Items == true && (i==cdkLstReceivables60.length -1 || i == cdkLstReceivables60.length-2)),
                         'box-piece-enable': !item.draggable, 
                         'box-piece-disable': item.draggable, 
                         'grow': (status==1) && currentMonth == 2 && !blnBackgroundBlue && !blnBackground2 && !blnBackground4 && !blnBackground5 && !blnBackground6 && !blnBackground7_8 && !blnBackground9,
                         'growBackgroundBlue': (status==1) && currentMonth == 2 && blnBackgroundBlue,
                         'growBackground2': (status==1) && currentMonth == 2 && blnBackground2,
                         'growBackground4': (status==1) && currentMonth == 2 && blnBackground4,
                         'growBackground5': (status==1) && currentMonth == 2 && blnBackground5,
                         'growBackground6': (status==1) && currentMonth == 2 && blnBackground6,
                         'growBackground7_8': (status==1) && currentMonth == 2 && blnBackground7_8,
                         'growBackground9': (status==1) && currentMonth == 2 && blnBackground9
                        }" >
             <img 
             [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-cash pieceMoved">
          </div>
        </div>
      </div>
      <!--Animacion de receivables para 30  -->
      <div style="background-color:black;" *ngIf="lista2.length>0 && move30==true">
        <div id="slide" class="container-styles-globals-move" *ngFor="let item of lista2; let i = index;" style="top: 230px;" [ngStyle]="{'left.px':i==lista2.length-1?610:i==lista2.length-2?640:i==lista2.length-3?670:700}" [ngClass]="{'animacionReceivables30':move30 == true}" >
          <img  [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-cash">
        </div>
      </div>
      <!-- contaner Receivables 30 days -->
      <div *ngIf="currentMonth != 1 || boardMode == 'DM'" class="container-Receivables30" style="background-color:black;">
        <div cdkDropList id="cdkLstReceivables30" [cdkDropListData]="cdkLstReceivables30" class="container-receivables30-styles container-styles-globals"
          (cdkDropListDropped)="fnTest()">
          <div class="example-box-destinity-inclinacion-pos25" *ngFor="let item of cdkLstReceivables30; let i = index;" cdkDrag 
             (cdkDragStarted)="fnTest()"
             (click)="fnTest()"
             [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard" [matTooltip]="item.strDescription ? item.strDescription :   item.draggingBy" 
             [ngClass]="{'animacionRec30':(itemsToMove == 1 && move30 == true && i==cdkLstReceivables30.length -1 || itemsToMove == 2 && move30 == true && (i==cdkLstReceivables30.length -1 || i == cdkLstReceivables30.length-2) || itemsToMove == 3 && move30 == true && (i==cdkLstReceivables30.length -1 || i == cdkLstReceivables30.length-2 || i == cdkLstReceivables30.length-3) || itemsToMove == 4 && move30 == true && (i==cdkLstReceivables30.length -1 || i == cdkLstReceivables30.length-2 || i == cdkLstReceivables30.length-3 || i == cdkLstReceivables30.length-4)),
                         'box-piece-enable': !item.draggable, 
                         'box-piece-disable': item.draggable, 
                         'grow': (status==1) && currentMonth == 2 && !blnBackgroundBlue && !blnBackground2 && !blnBackground4 && !blnBackground5 && !blnBackground6 && !blnBackground7_8 && !blnBackground9,
                         'growBackgroundBlue': (status==1) && currentMonth == 2 && blnBackgroundBlue,
                         'growBackground2': (status==1) && currentMonth == 2 && blnBackground2,
                         'growBackground4': (status==1) && currentMonth == 2 && blnBackground4,
                         'growBackground5': (status==1) && currentMonth == 2 && blnBackground5,
                         'growBackground6': (status==1) && currentMonth == 2 && blnBackground6,
                         'growBackground7_8': (status==1) && currentMonth == 2 && blnBackground7_8,
                         'growBackground9': (status==1) && currentMonth == 2 && blnBackground9}" >
             <img 
             [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-cash">
          </div>
        </div>
      </div>
      <!-- contaner Capital stock -->
      <div class="container-CapitalStock" style="background-color:black;">
        <div cdkDropList id="cdkLstCapitalS" [cdkDropListData]="cdkLstCapitalS" class="container-capitalS-styles container-styles-globals"
          (cdkDropListDropped)="fnTest()"
          [ngClass]="{'growSinCuadrado': !blnBackgroundBlue && !blnBackground2 && !blnBackground4 && !blnBackground5 && !blnBackground6 && !blnBackground7_8 && !blnBackground9 && ((status==1) && currentMonth == 1) || (!isModeOpen && currentMonth >= 2 && status == 1000),
                      'growBackgroundBlueCuadrado': blnBackgroundBlue && ((status==1) && currentMonth == 1) || (!isModeOpen && currentMonth >= 2 && status == 1000),
                      'growBackground2Cuadrado': blnBackground2 && ((status==1) && currentMonth == 1) || (!isModeOpen && currentMonth >= 2 && status == 1000),
                      'growBackground4Cuadrado': blnBackground4 && ((status==1) && currentMonth == 1) || (!isModeOpen && currentMonth >= 2 && status == 1000),
                      'growBackground5Cuadrado': blnBackground5 && ((status==1) && currentMonth == 1) || (!isModeOpen && currentMonth >= 2 && status == 1000),
                      'growBackground6Cuadrado': blnBackground6 && ((status==1) && currentMonth == 1) || (!isModeOpen && currentMonth >= 2 && status == 1000),
                      'growBackground7_8Cuadrado': blnBackground7_8 && ((status==1) && currentMonth == 1) || (!isModeOpen && currentMonth >= 2 && status == 1000),
                      'growBackground9Cuadrado': blnBackground9 && ((status==1) && currentMonth == 1) || (!isModeOpen && currentMonth >= 2 && status == 1000)}">
          <div class="example-box-destinity" *ngFor="let item of cdkLstCapitalS; let i = index" cdkDrag 
             (cdkDragStarted)="fnTest()"
            [matTooltip]="item.draggingBy" [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable}" 
            [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard">
            <img 
            [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-capitalStock">
          </div>
        </div>
      </div>
       <!-- error paso 2 -->
       <div id="errorPaso2" *ngIf="status == 1" class="container-styles-globals"></div>
       <!-- fin error paso 2 -->
  
       <!-- error paso 35 posicionado al sobre shareholder equity -->
       <div id="errorPaso34" *ngIf="((status == 35) && currentMonth == 1) || ((status == 2) && currentMonth == 2)" class="container-styles-globals"></div>
       <!-- fin error paso 19 -->
       <label class="lblnum72 txtBoldBlackSmall calibriBold13 calibriFont" >{{getSingleTextLabel('72')}}</label>
       <label class="lblnum74 txtBoldBlackSmall calibri13 calibriFont" >{{getSingleTextLabel('74')}}</label>
       <label class="lblnum73 txtBoldBlackSmall calibri13 calibriFont" >{{getSingleTextLabel('73')}}</label>
       <!--<div class="moveNetIncome moveMaterialstAfter"
       *ngIf="status == 36" >
         <ng-container>
           <p class="my-auto">
            You have retained earnings
           </p>
         </ng-container>
       </div> -->
      
       <!-- contaner Retained earnings -->
       <div class="container-RetainedE" style="background-color:black;">
        <div cdkDropList id="cdkLstRetainedE" [cdkDropListData]="cdkLstRetainedE" class="container-retainedE-styles container-styles-globals" style="display: flex;" [ngStyle]="{'height.px':cdkLstRetainedE.length>=5 && cdkLstRetainedE.length<7 ?180:cdkLstRetainedE.length>=7?240:120, 'top.px':cdkLstRetainedE.length>=5 && cdkLstRetainedE.length<7?450:cdkLstRetainedE.length>=7?380:500 }"
          (cdkDropListDropped)="fnTest()"
          [ngClass]="{'grow': status==35 && !blnBackgroundBlue && !blnBackground2 && !blnBackground4 && !blnBackground5 && !blnBackground6 && !blnBackground7_8 && !blnBackground9, 'growBackgroundBlue': status==35 && blnBackgroundBlue, 'growBackground2': status==35 && blnBackground2 , 'growBackground4': status==35 && blnBackground4, 'growBackground5': status==35 && blnBackground5, 'growBackground6': status==35 && blnBackground6, 'growBackground7_8': status==35 && blnBackground7_8, 'growBackground9': status==35 && blnBackground9}">
          <div class="example-box-destinity alignobjectbottom" [id]="i" style="height:62px; display: flex !important; align-items:flex-end" *ngFor="let item of cdkLstRetainedE; let i = index" cdkDrag [ngStyle]="{'top.px':cdkLstRetainedE.length<5 && (i==0 || i==1)?58: cdkLstRetainedE.length<5 && (i==2 || i==3)?0:cdkLstRetainedE.length>=5 && cdkLstRetainedE.length<7 && (i==0 || i==1)?118:cdkLstRetainedE.length>=5 && cdkLstRetainedE.length<7 && (i==2 || i==3)?60:cdkLstRetainedE.length>=5 && cdkLstRetainedE.length<7 && (i==4 || i==5)?0:cdkLstRetainedE.length>=7 && (i==0 || i==1)?178:cdkLstRetainedE.length>=7 && (i==2 || i==3)?118:cdkLstRetainedE.length>=7 && (i==4 || i==5)?58:0, 'left.px':i==2 || i == 3?-60:i == 4 || i == 5?-120:i == 6 || i == 7?-180:0 }" 
             (cdkDragStarted)="fnTest()"
             [matTooltip]="item.draggingBy" [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable}" 
             [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard">
             <img 
             [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-capitalStock">
          </div>
        </div>
      </div>
      <!-- contaner Net Icome -->
      <label class="lblnum3 txtWhitebtnLarge calibriBold14 calibriFont">{{getSingleTextLabel('13')}}</label>
      <mat-icon class="lblnum3HelpIcon" (click)="fnTest()">visibility</mat-icon>
      <label class="lblnum44 txtWhitebtnLarge calibriBold14 calibriFont">{{getSingleTextLabel('44')}}</label>
      <mat-icon class="lblnum44HelpIcon" (click)="fnTest()">visibility</mat-icon>
      <div>
        <label class="lblteamName txtBoldWhiteLargeTeam calibri14 calibriFont" [ngStyle]="{'top.px': TeamNameArray.length > 3 ? 19 : 23 }"
          ><ng-container *ngFor="let item of TeamNameArray.slice(0,3)">
            {{ item }}
          </ng-container>
        </label>
        <label class="lblteamName2 txtBoldWhiteLargeTeam calibri14 calibriFont"
          ><ng-container *ngFor="let item of TeamNameArray.slice(3)">
            {{ item }}
          </ng-container>
        </label>
      </div>
      <label class="lblnum42 txtBlueLarge calibriBold28 calibriFont" >{{getSingleTextLabel('42')}}</label>
      <div class="container-NetIncome" style="background-color:black;">
        <div cdkDropList id="cdkLstNetIncome" [cdkDropListData]="cdkLstNetIncome" class="container-netIncome-styles container-styles-globals"
          (cdkDropListDropped)="fnTest()" 
          [ngClass]="{'grow': status==35 && !blnBackgroundBlue && !blnBackground2 && !blnBackground4 && !blnBackground5 && !blnBackground6 && !blnBackground7_8 && !blnBackground9, 'growBackgroundBlue': status==35 && blnBackgroundBlue, 'growBackground2': status==35 && blnBackground2, 'growBackground4': status==35 && blnBackground4, 'growBackground5': status==35 && blnBackground5, 'growBackground6': status==35 && blnBackground6, 'growBackground7_8': status==35 && blnBackground7_8, 'growBackground9': status==35 && blnBackground9}">
          <div class="example-box-destinity" *ngFor="let item of cdkLstNetIncome; let i = index" cdkDrag style="padding-right: 10px;"
             (cdkDragStarted)="fnTest()"
             [matTooltip]="item.draggingBy" [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable}" 
             [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard">
             <img 
             [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-capitalStock">
          </div>
        </div>
      </div>
      <!-- contaner taxes -->
      <!-- <div *ngIf="currentMonth != 1" class="container-Taxes" style="background-color:black;">
        <div cdkDropList id="cdkLstTaxes" [cdkDropListData]="cdkLstTaxes" class="container-taxes-styles container-styles-globals"
          (cdkDropListDropped)="fnDroppedOnTaxes($event)">
          <div class="example-box-destinity" *ngFor="let item of cdkLstTaxes; let i = index" cdkDrag 
             (cdkDragStarted)="fnTest()"
             [matTooltip]="item.draggingBy" [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable}" 
             [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard">
             <img 
             [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-capitalStock">
          </div>
        </div>
      </div> -->
      <!-- contaner Income Before Taxes -->
      <label class="lblnum40 txtBlueSmall calibriBold15 calibriFont" >{{getSingleTextLabel('40')}}</label>
      <label class="lblnum41 txtBlueSmall calibriBold13 calibriFont" >{{getSingleTextLabel('41')}}</label>
      <div class="container-IncomeBT" style="background-color:black;">
      <!-- <div *ngIf="currentMonth != 1" class="container-IncomeBT" style="background-color:black;">
        <div cdkDropList id="cdkLstIncomeBT" [cdkDropListData]="cdkLstIncomeBT" class="container-incomeBT-styles container-styles-globals"
          (cdkDropListDropped)="fnDroppedOnIncomeBT($event)">
          <div class="example-box-destinity" *ngFor="let item of cdkLstIncomeBT; let i = index" cdkDrag 
             (cdkDragStarted)="fnTest()"
             [matTooltip]="item.draggingBy" [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable}" 
             [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard">
             <img 
             [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-capitalStock">
          </div>
        </div>
      </div> -->
      <!-- contaner Interest -->
      <div class="container-Interest" style="background-color:black;">
        <div cdkDropList id="cdkLstInterest" [cdkDropListData]="cdkLstInterest" class="container-interest-styles container-styles-globals"
          (cdkDropListDropped)="fnTest()"
          [ngClass]="{'grow': (status == 19 && !blnBackgroundBlue && !blnBackground2 && !blnBackground4 && !blnBackground5 && !blnBackground6 && !blnBackground7_8 && !blnBackground9) || (this.jsonBlnOpenModeActions.showMessageInterest1 && this.isHintInterest >= 3), 'growBackgroundBlue': status == 19 && blnBackgroundBlue, 'growBackground2': status == 19 && blnBackground2, 'growBackground4': status == 19 && blnBackground4, 'growBackground5': status == 19 && blnBackground5, 'growBackground6': status == 19 && blnBackground6, 'growBackground7_8': status == 19 && blnBackground7_8, 'growBackground9': status == 19 && blnBackground9}">
          <div class="example-box-destinity itDrag" *ngFor="let item of cdkLstInterest; let i = index" cdkDrag 
             (cdkDragStarted)="fnTest()"
             [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard" [matTooltip]="item.draggingBy" [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable}" >
            <img 
            [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-interest">
          </div>
        </div>
      </div>
  
       <!-- error paso 19 posicionado al lado de operating income -->
       <div id="errorPaso19" *ngIf="((status == 19) && currentMonth == 1) || (currentMonth >= 2 && isModeOpen && jsonBlnOpenModeActions.showMessageInterest1)" class="container-styles-globals"></div>
       <!-- fin error paso 19 -->
  
      <!-- to pay interest -->
      <label class="lblnum35 txtBlueSmall calibriBold15 calibriFont" >{{getSingleTextLabel('35')}} <mat-icon class="lblnum35HelpIcon" (click)="fnTest()">help</mat-icon></label>
      <label class="lblnum39 txtBoldWhiteSmall calibri12 calibriFont" >{{getSingleTextLabel('39')}}</label>
      <label class="lblnum38 txtBoldWhiteSmall calibri12 calibriFont" >{{getSingleTextLabel('38')}}</label>
      <div class="payInterest"  cdkDropList id="cdkLstInterest" [cdkDropListData]="cdkLstInterest"  (cdkDropListDropped)="fnTest()"
      *ngIf="((status == 19 || status == 20) && currentMonth == 1) || (currentMonth >= 2 && jsonBlnOpenModeActions.showMessageInterest1)" style="border-style: none;">
        <ng-container *ngIf="((status == 19) && currentMonth == 1) || (currentMonth >= 2 && jsonBlnOpenModeActions.isBuyLoan); else userPaidInterest">
          <div class="pLabelbox"><p class="labelBillPagarStep15">1</p></div>
        </ng-container>
        <ng-template #userPaidInterest>
          <div class="pLabelboxPaid"></div>
        </ng-template>
      </div>
  
      <!-- contaner rent -->
      <div *ngIf="currentMonth != 1" class="container-Rent" style="background-color:black;">
        <div cdkDropList id="cdkLstRent" [cdkDropListData]="cdkLstRent" class="container-rent-styles container-styles-globals"
          (cdkDropListDropped)="fnTest()">
          <div class="example-box-destinity" *ngFor="let item of cdkLstRent; let i = index" cdkDrag 
             (cdkDragStarted)="fnTest()"
             [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard" [matTooltip]="item.draggingBy" [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable}" >
             <img 
             [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-interest">
          </div>
        </div>
      </div>
      <div class="payRent"  cdkDropList id="cdkLstRent" [cdkDropListData]="cdkLstRent"  (cdkDropListDropped)="fnTest()"
      *ngIf="jsonBlnOpenModeActions.isSoldBuild && cdkLstRent.length == 0 && status >= 3" style="border-style: none;">
        <ng-container *ngIf="cdkLstRent.length == 0; else userPaidRent">
          <div class="pLabelbox"><p class="labelBillPagarStep15">1</p></div>
        </ng-container>
        <ng-template #userPaidRent>
          <div class="pLabelboxPaid" style="margin-left: 24px;"></div>
        </ng-template>
      </div>
      <!-- contaner Recruitment & training -->
      <div *ngIf="currentMonth != 1" class="container-RecruitmentT" style="background-color:black;">
        <div cdkDropList id="cdkLstRecruitmentT" [cdkDropListData]="cdkLstRecruitmentT" class="container-recruitmentT-styles container-styles-globals"
          (cdkDropListDropped)="fnTest()">
          <div class="example-box-destinity" *ngFor="let item of cdkLstRecruitmentT; let i = index" cdkDrag 
             (cdkDragStarted)="fnTest()"
             [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard" [matTooltip]="item.draggingBy" [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable}" >
             <img 
             [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-interest">
          </div>
        </div>
      </div>
      <div class="payRecruitAndTraining" cdkDropList id="cdkLstRecruitmentT" [cdkDropListData]="cdkLstRecruitmentT"  (cdkDropListDropped)="fnTest()"
      *ngIf="jsonBlnOpenModeActions.showBill1" style="border-style: none;"
      >
        
      </div>
      <ng-container  *ngIf="status == 2 && currentMonth >= 4">
        <ng-container *ngFor="let loan of cdkLstLoans30Aux">
          <div *ngIf="loan.valor > 0" class="payLiabilitie" cdkDropList id="cdkLstPayLiabilitie" (cdkDropListDropped)="fnTest()"
           style="border-style: none;">
            <ng-container>
              <div class="pLabelbox"><p class="labelBillPagarStep15">{{loan.valor}}</p></div> 
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
      <ng-container  *ngIf="status == 2 && currentMonth >= 4">
        <ng-container *ngFor="let payable of cdkLstPayables30Aux">
          <div class="payLiabilitieP" cdkDropList id="cdkLstPayLiabilitieP" (cdkDropListDropped)="fnTest()"
           style="border-style: none;">
            <ng-container>
              <div class="pLabelbox"><p class="labelBillPagarStep15">{{payable.valor}}</p></div> 
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
      <div class="payRecruitAndTraining" cdkDropList id="cdkLstRecruitmentT" [cdkDropListData]="cdkLstRecruitmentT"  (cdkDropListDropped)="fnTest()"
      *ngIf="jsonBlnOpenModeActions.showBill6" style="border-style: none;"
      >
        <ng-container *ngIf="jsonBlnOpenModeActions.showBill6">
          <div class="pLabelbox"><p class="labelBillPagarStep15">6</p></div> 
        </ng-container>
      </div>
      <!-- contaner Admin overhead -->
      <div class="container-AdminO" style="background-color:black;">
        <div cdkDropList id="cdkLstAdminOver" [cdkDropListData]="cdkLstAdminOver" class="container-adminOver-styles container-styles-globals"
          (cdkDropListDropped)="fnTest()">
          <div class="example-box-destinity" *ngFor="let item of cdkLstAdminOver; let i = index" cdkDrag 
             (cdkDragStarted)="fnTest()"
             [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard" [matTooltip]="item.draggingBy" [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable}">
            <img [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-grossProfit" >
          </div>
          <div class="growGrossPDotContainer" [ngStyle]="{'height.px': cdkLstAdminOver.length==0?40:25}" *ngIf="status == 28">
            <div  class="growGrossPDot" 
            [ngStyle]="{'margin-top.px': cdkLstAdminOver.length==0?13:15, 'background-color': blnBackgroundBlue ? '#1bffce': blnBackground2 ? '#222b00' : blnBackground4 ? '#f76d60' : blnBackground5 ? '#1bffce' : blnBackground6 ? '#030efc': blnBackground7_8 ? '#fd3180': blnBackground9 ? '#172e7c' : '#ff3000' }"></div>
          </div>
        </div>
      </div>
  
      <div class="payExpensesGrossProfit" cdkDropList id="cdkLstAdminOver" [cdkDropListData]="cdkLstAdminOver"  (cdkDropListDropped)="fnTest()"
      *ngIf="status == 28" style="border-style: none;">
        <ng-container *ngIf="status == 28">
          <div class="pLabelbox"><p class="labelBillPagarStep15">4</p></div> 
        </ng-container>
      </div>
  
      <!-- contaner administration -->
      <div class="container-Administration" style="background-color:black;">
        <div cdkDropList id="cdkLstAdministration" [cdkDropListData]="cdkLstAdministration"
          class="container-administration-styles container-styles-globals "  [ngStyle]="{'height.px':cdkLstAdministration.length==0?30:30, 'top.px':cdkLstAdministration.length==0?362:362}"
          (cdkDropListDropped)="fnTest()" [ngClass]="{'growBackgroundBlue': status == 13 && (cdkLstAdministration.length == 0 || cdkLstMarketingS.length == 0 || cdkLstChangeI.length == 0)|| status == 28 && cdkLstAdministration.length == 1 || (currentMonth >= 3 && getCurrentImprovement === 'Quality' && isCheckListCompleteBeforeThan('Pay recruitment and Trainning'))}">
          <div style="width: 31px" [ngStyle]="{'height.px': item.type==12?33:33}" class="example-box-destinity" *ngFor="let item of cdkLstAdministration; let i = index" cdkDrag 
             (cdkDragStarted)="fnTest()"
            [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard" [matTooltip]="item.draggingBy" [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable}">
              <img [ngStyle]="{'width.px': item.type==12?28:31}" [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+(item.type === 6 && (item.valor === 1 || item.valor === 12) ? '.png' : '.svg')" class="coinImg-grossProfit" >
            
          </div>
          <div class="growGrossPDotContainer " [ngStyle]="{'height.px': cdkLstAdministration.length==0?40:25}" *ngIf="status == 13 && (cdkLstAdministration.length == 0 || cdkLstMarketingS.length == 0 || cdkLstChangeI.length == 0)|| status == 28 && cdkLstAdministration.length == 1 || (currentMonth >= 3 && getCurrentImprovement === 'Quality' && isCheckListCompleteBeforeThan('Pay recruitment and Trainning'))">
            <div  class="growGrossPDot" 
            [ngStyle]="{'margin-top.px': cdkLstAdministration.length==0?13:15, 'background-color': blnBackgroundBlue ? '#1bffce': blnBackground2 ? '#222b00' : blnBackground4 ? '#f76d60' : blnBackground5 ? '#1bffce' : blnBackground6 ? '#030efc': blnBackground7_8 ? '#fd3180': blnBackground9 ? '#172e7c' : '#ff3000' }"></div>
          </div>
        </div>
      </div>
      <!-- contaner advertising -->
      <div class="container-Advertising" style="background-color:black;">
        <div cdkDropList id="cdkLstAdvertasing" [cdkDropListData]="cdkLstAdvertasing" class="container-advertising-styles container-styles-globals"
          [ngClass]="{'container-advertising-styles-expand' : expandsAdvertising}"
          (cdkDropListDropped)="fnTest()">
          <div class="example-box-destinity" *ngFor="let item of cdkLstAdvertasing; let i = index" cdkDrag 
             (cdkDragStarted)="fnTest()" [ngStyle]="{'margin-right.px': !item.refound && expandsAdvertising ? 50:0}"
            [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard" [matTooltip]="item.draggingBy" [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable}">
              <img [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-grossProfit" >
          </div>
          <div class="growGrossPDotContainer" [ngStyle]="{'height.px': cdkLstAdvertasing.length==0?40:25}" *ngIf="status == 30">
            <div  class="growGrossPDot" 
            [ngStyle]="{'margin-top.px': cdkLstAdvertasing.length==0?13:15, 'background-color': blnBackgroundBlue ? '#1bffce': blnBackground2 ? '#222b00' : blnBackground4 ? '#f76d60' : blnBackground5 ? '#1bffce' : blnBackground6 ? '#030efc': blnBackground7_8 ? '#fd3180': blnBackground9 ? '#172e7c' : '#ff3000' }"></div>
          </div>
        </div>
      </div>
      <!-- contaner advertising refaound -->
      <!-- <div class="container-Advertising" *ngIf="expandsAdvertising">
        <div cdkDropList [cdkDropListEnterPredicate]="notDropped" id="cdkLstAdvertasingRefound" [cdkDropListData]="cdkLstAdvertasingRefound" 
        class="container-advertisingRefound-styles container-styles-globals">
          <div class="example-box-destinity" *ngFor="let item of cdkLstAdvertasingRefound; let i = index" cdkDrag 
             (cdkDragStarted)="fnTest()"
              [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard" [matTooltip]="item.draggingBy">
              <img [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-grossProfit" >
          </div>
        </div>
      </div> -->
      <div class="payAdvertisingOverHead"
      *ngIf="this.showOwedAdvertising && (currentMonth >= 2 || boardMode == 'DM') && this.owesAdvertising >0 && status>=10" style="border-style: none;">
        <ng-container *ngIf="this.showOwedAdvertising">
          <div class="pLabelbox"><p class="labelBillPagar">{{this.owesAdvertising}}</p></div>
        </ng-container>
        <ng-container *ngIf="this.showPaidAdvertising">
          <div class="pLabelboxPaid"></div>
        </ng-container>
      </div>
  
      <div class="payAdvertising" cdkDropList id="cdkLstAdvertasing" [cdkDropListData]="cdkLstAdvertasing"   (cdkDropListDropped)="fnTest()"
      *ngIf="status == 30" style="border-style: none;">
        <ng-container *ngIf="(advertising >= 2 || this.blnFacilitador) && (boardMode == 'NM' || blnFacilitador)" >
          <div class="pLabelbox"><p class="labelBillPagarStep15">2</p></div>  
        </ng-container>
      </div>
      <!-- contaner marketing -->
      <div class="container-Marketing" style="background-color:black;">
        <div cdkDropList id="cdkLstMarketingS" [cdkDropListData]="cdkLstMarketingS" 
          class="container-marketingS-styles container-styles-globals" [ngStyle]="{'height.px':cdkLstMarketingS.length==0?30:30, 'top.px':cdkLstMarketingS.length==0?298:298}"
          (cdkDropListDropped)="fnTest()" [ngClass]="{'growBackgroundBlue': status == 13 && (cdkLstAdministration.length == 0 || cdkLstMarketingS.length == 0 || cdkLstChangeI.length == 0)|| status == 28 && cdkLstAdministration.length == 1 || (currentMonth >= 3 && getCurrentImprovement === 'Quality' && isCheckListCompleteBeforeThan('Pay recruitment and Trainning') && isType12inLabor)}">
          <div style="width: 31px" [ngStyle]="{'height.px': item.type==12?33:33}"  class="example-box-destinity" *ngFor="let item of cdkLstMarketingS; let i = index" cdkDrag 
             (cdkDragStarted)="fnTest()"
            [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard" [matTooltip]="item.draggingBy" [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable}">
              <img [ngStyle]="{'width.px': item.type==12?28:31}" [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+(item.type === 6 && (item.valor === 1 || item.valor === 12) ? '.png' : '.svg')" class="coinImg-grossProfit" >
            
          </div>
          <div class="growGrossPDotContainer" [ngStyle]="{'height.px': cdkLstMarketingS.length==0?40:25}" *ngIf="status == 13 && (cdkLstAdministration.length == 0 || cdkLstMarketingS.length == 0 || cdkLstChangeI.length == 0) || status == 28 && cdkLstAdministration.length == 1 || (currentMonth >= 3 && getCurrentImprovement === 'Quality' && isCheckListCompleteBeforeThan('Pay recruitment and Trainning') && isType12inLabor)">
            <div  class="growGrossPDot" 
            [ngStyle]="{'margin-top.px': cdkLstMarketingS.length==0?13:15, 'background-color': blnBackgroundBlue ? '#1bffce': blnBackground2 ? '#222b00' : blnBackground4 ? '#f76d60' : blnBackground5 ? '#1bffce' : blnBackground6 ? '#030efc': blnBackground7_8 ? '#fd3180': blnBackground9 ? '#172e7c' : '#ff3000' }"></div>
          </div>
        </div>
      </div>
      <!-- contaner change & innovation -->
      <div class="lblnum28Container">
        <label class="lblnum28 txtBoldWhiteSmall calibri12 calibriFont" >{{getSingleTextLabel("28")}}</label>
      </div>
      <div class="lblnum29Container">
        <label class="lblnum29 txtBoldWhiteSmall calibri12 calibriFont" >{{getSingleTextLabel("29")}}</label>
      </div>
      <label class="lblnum30 txtBoldWhiteSmall calibri12 calibriFont" >{{getSingleTextLabel("30")}}</label>
      <label class="lblnum31 txtBoldWhiteSmall calibri12 calibriFont" >{{getSingleTextLabel("31")}}</label>
      <label class="lblnum32 txtBoldWhiteSmall calibri12 calibriFont" >{{getSingleTextLabel("32")}}</label>
      <div style="height: 37px; position: absolute; left: 200px !important; top: 425px !important; display: flex; justify-content: center; align-items: center;">
        <label class="lblnum33 txtBoldWhiteSmall calibri12 calibriFont" >{{getSingleTextLabel("33")}}</label>
      </div>
      <label class="lblnum34 txtBoldWhiteSmall calibri12 calibriFont" >{{getSingleTextLabel("34")}}</label>
      <label class="lblnum27 txtBoldWhiteSmall calibri12 calibriFont" >{{getSingleTextLabel("27")}}</label>
      <div class="container-ChangeI" style="background-color:black;">
        <div cdkDropList id="cdkLstChangeI" [cdkDropListData]="cdkLstChangeI"
          class="container-changeI-styles container-styles-globals" [ngStyle]="{'height.px':cdkLstChangeI.length==0?31:30, 'top.px':cdkLstChangeI.length==0?267:267}"
          (cdkDropListDropped)="fnTest()" [ngClass]="{'growBackgroundBlue': status == 13 && (cdkLstAdministration.length == 0 || cdkLstMarketingS.length == 0 || cdkLstChangeI.length == 0)|| status == 28 && cdkLstAdministration.length == 1 || (currentMonth >= 3 && getCurrentImprovement === 'Quality' && isCheckListCompleteBeforeThan('Pay recruitment and Trainning') && isType12inLabor)}">
          <div style="width: 31px" [ngStyle]="{'height.px': item.type==12?33:33}"  class="example-box-destinity" *ngFor="let item of cdkLstChangeI; let i = index" cdkDrag 
             (cdkDragStarted)="fnTest()"
            [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard" [matTooltip]="item.draggingBy" [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable}">
              <img [ngStyle]="{'width.px': item.type==12?28:31}" [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+(item.type === 6 && (item.valor === 1 || item.valor === 12) ? '.png' : '.svg')" class="coinImg-grossProfit">
            
          </div>
          <div class="growGrossPDotContainer" [ngStyle]="{'height.px': cdkLstChangeI.length==0?40:25}" *ngIf="status == 13 && (cdkLstAdministration.length == 0 || cdkLstMarketingS.length == 0 || cdkLstChangeI.length == 0) || status == 28 && cdkLstAdministration.length == 1 || (currentMonth >= 3 && getCurrentImprovement === 'Quality' && isCheckListCompleteBeforeThan('Pay recruitment and Trainning') && isType12inLabor)">
            <div  class="growGrossPDot" 
            [ngStyle]="{'margin-top.px': cdkLstChangeI.length==0?13:15, 'background-color': blnBackgroundBlue ? '#1bffce': blnBackground2 ? '#222b00' : blnBackground4 ? '#f76d60' : blnBackground5 ? '#1bffce' : blnBackground6 ? '#030efc': blnBackground7_8 ? '#fd3180': blnBackground9 ? '#172e7c' : '#ff3000' }"></div>
          </div>
        </div>
      </div>
  
      <!-- error paso 13 posicionado al lado de gross profit -->
      <div id="errorPaso13" *ngIf="((status == 13 || status == 28 || status == 30) && currentMonth ==1) || ((status == 0) && currentMonth == 2)" class="container-styles-globals"></div>
      <!-- fin error paso 1 -->
  
      <!-- contaner depreciation -->
      <label class="lblnum26 txtBlueSmall calibriBold15 calibriFont" >{{getSingleTextLabel('26')}}</label>
      <label class="lblnum25 calibri12 calibriFont" >{{getSingleTextLabel('25')}}</label>
      <label class="lblnum21 calibri12 calibriFont" >{{getSingleTextLabel('21')}}</label>
      <div class="container-Depreciation" style="background-color:black;">
        <div cdkDropList id="cdkLstDeprecation" [cdkDropListData]="cdkLstDeprecation" class="container-depreciation-styles container-styles-globals"
          (cdkDropListDropped)="fnTest()"
          [ngClass]="{'grow': status==25 && !blnBackgroundBlue && !blnBackground2 && !blnBackground4 && !blnBackground5 && !blnBackground6 && !blnBackground7_8 && !blnBackground9, 'growBackgroundBlue': status==25 && blnBackgroundBlue, 'growBackground2': status==25 && blnBackground2, 'growBackground4': status==25 && blnBackground4, 'growBackground5': status==25 && blnBackground5, 'growBackground6': status==25 && blnBackground6, 'growBackground7_8': status==25 && blnBackground7_8, 'growBackground9': status==25 && blnBackground9}" 
          >
          <div class="example-box-destinity" *ngFor="let item of cdkLstDeprecation; let i = index" cdkDrag 
             (cdkDragStarted)="fnTest()"
            [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard" [matTooltip]="item.draggingBy" [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable}">
            <img [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-interest" >
  
            <!-- <img src="../../../../assets/fondoCoin.png" width="40" style="z-index: 5000 !important;" height="32">
            <label *ngIf="item.valor > 9"
              style="color: rgb(100, 71, 7);position: relative;font-size: 13px;font-weight:bold;right:27px;">{{item.valor}}</label>
  
            <label *ngIf="item.valor <= 9"
              style="color: rgb(100, 71, 7);position: relative;font-size: 13px;font-weight:bold;right:24px;">{{item.valor}}</label> -->
  
          </div>
        </div>
      </div>
      <div class="payDeprecation" cdkDropList id="cdkLstDeprecation" [cdkDropListData]="cdkLstDeprecation" (cdkDropListDropped)="fnTest()"
      *ngIf="status == 25 || status == 26" style="border-style: none;">
        <ng-container *ngIf="status == 25">
          <div class="pArrowbox"><p class="labelBillArrowPagarStep15">1</p></div> 
        </ng-container>
        <ng-container *ngIf="status == 26">
          <div class="pArrowboxPaid"></div>
        </ng-container>
      </div>
      <!-- error paso 25 posicionado al lado de machine overhead -->
      <label class="lblnum23 calibri12 calibriFont" >{{getSingleTextLabel('23')}}</label>
      <div id="errorPaso25" *ngIf="status == 25 || status == 27 || status == 32" class="container-styles-globals"></div>
      <!-- fin error paso 25 -->
      <label class="lblnum20 txtBlueSmall calibriBold15 calibriFont" >{{getSingleTextLabel('20')}}</label>
      <!-- contaner machine overhead -->
      <div class="container-MachineOver" style="background-color:black;">
        <div cdkDropList id="cdkLstMachineOver" [cdkDropListData]="cdkLstMachineOver" class="container-machineOver-styles container-styles-globals"
          [ngClass]="{
            'grow': (!blnBackgroundBlue && !blnBackground2 && !blnBackground4 && !blnBackground5 && !blnBackground6 && !blnBackground7_8 && !blnBackground9) && (status==27 || (currentMonth >= 3 && getCurrentImprovement === 'Raw Materials' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment'))),
            'growBackgroundBlue': blnBackgroundBlue && (status==27 || (currentMonth >= 3 && getCurrentImprovement === 'Raw Materials' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment'))), 
            'growBackground2': blnBackground2 && (status==27 || (currentMonth >= 3 && getCurrentImprovement === 'Raw Materials' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment'))), 
            'growBackground4': blnBackground4 && (status==27 || (currentMonth >= 3 && getCurrentImprovement === 'Raw Materials' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment'))), 
            'growBackground5': blnBackground5 && (status==27 || (currentMonth >= 3 && getCurrentImprovement === 'Raw Materials' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment'))), 
            'growBackground6': blnBackground6 && (status==27 || (currentMonth >= 3 && getCurrentImprovement === 'Raw Materials' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment'))), 
            'growBackground7_8': blnBackground7_8 && (status==27 || (currentMonth >= 3 && getCurrentImprovement === 'Raw Materials' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment'))),
            'growBackground9': blnBackground9 && (status==27|| (currentMonth >= 3 && getCurrentImprovement === 'Raw Materials' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment'))) 
          }" 
          (cdkDropListDropped)="fnTest()">
          <div class="example-box-destinity" *ngFor="let item of cdkLstMachineOver; let i = index" cdkDrag 
             (cdkDragStarted)="fnTest()"
            [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard" [matTooltip]="item.draggingBy" [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable}">
            <img [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-interest" >
          </div>
        </div>
      </div>
      <div class="payMachineOverHead" cdkDropList id="cdkLstMachineOver" [cdkDropListData]="cdkLstMachineOver" (cdkDropListDropped)="fnTest()"
      *ngIf="status == 27" style="border-style: none;">
        <ng-container *ngIf="status == 27">
          <div class="pLabelbox"><p class="labelBillPagar">1</p></div>   
        </ng-container>
      </div>
      <div class="payMachineOverHead" cdkDropList id="cdkLstMachineOver" [cdkDropListData]="cdkLstMachineOver" (cdkDropListDropped)="fnTest()"
      *ngIf="jsonBlnOpenModeActions.showBill2" style="border-style: none;">
        <ng-container *ngIf="jsonBlnOpenModeActions.showBill2">
          <div class="pLabelbox"><p class="labelBillPagar">2</p></div>   
        </ng-container>
      </div>
      <!-- contaner cost of good solds -->
      <label class="lblnum15 txtBlueLarge calibriBold28 calibriFont" >{{getSingleTextLabel('15')}}</label>
      <label class="lblnum18 txtBoldWhiteSmall calibri12 calibriFont" >{{getSingleTextLabel('18')}}</label>
      <label class="lblnum16 txtBoldWhiteSmall calibri12 calibriFont" >{{getSingleTextLabel('16')}}</label>
      <div class="container-CostGS" style="background-color:black;">
        <div cdkDropList id="cdkLstCostGS" [cdkDropListData]="cdkLstCostGS" class="container-costGS-styles container-styles-globals" style="text-align: left !important;"
          (cdkDropListDropped)="fnTest()"
          [ngClass]="{'grow': status==32 && !blnBackgroundBlue && !blnBackground2 && !blnBackground4 && !blnBackground5 && !blnBackground6 && !blnBackground7_8 && !blnBackground9, 'growBackgroundBlue': status==32 && blnBackgroundBlue, 'growBackground2': status==32 && blnBackground2, 'growBackground4': status==32 && blnBackground4, 'growBackground5': status==32 && blnBackground5, 'growBackground6': status==32 && blnBackground6, 'growBackground7_8': status==32 && blnBackground7_8, 'growBackground9': status==32 && blnBackground9 }">
          <!-- style="max-width: 30px;"  -->
          <div class="example-box-destinity" [ngStyle]="{'width.px': item.valor >= 6 ? 74:item.valor == 3?33:30}"  
           *ngFor="let item of cdkLstCostGS; let i = index" cdkDrag 
             (cdkDragStarted)="fnTest()"
            [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard" [matTooltip]="item.draggingBy" [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable}">
            <!-- <div class="container-costGSSold-styles" [ngStyle]="{'max-width.px': item.valor >= 6 ? 60:30}" *ngIf="item.paid || status > 33"></div> -->
            <img 
              [ngStyle]="{'max-width.px': item.valor >= 6 ? 74:item.valor == 3?33:30}" 
              [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-costGS" >
          </div>
        </div>
      </div>
  
  
      <!-- raya negra? -->
      <!-- <div class="container-costGSSold-styles container-styles-globals2"
      *ngIf="status == 34" >
        <ng-container *ngIf="status == 34">
         Sold
        </ng-container>
      </div> -->
  
      <!-- container location machine -->
      <div *ngIf="status <= 7 " class="container-LocationMachine" style="background-color:black;">
        <div cdkDropList id="cdkLstLocationMachine" 
        [ngClass]="{
          'grow': (!blnBackgroundBlue && !blnBackground2 && !blnBackground4 && !blnBackground5 && !blnBackground6 && !blnBackground7_8 && !blnBackground9) && ((status == 7 && currentMonth == 1) || (currentMonth >= 3 && getCurrentImprovement === 'Expansion' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && isType5InCapEx)), 
          'growBackgroundBlue': blnBackgroundBlue && ((status == 7 && currentMonth == 1) || (currentMonth >= 3 && getCurrentImprovement === 'Expansion' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && isType5InCapEx)), 
          'growBackground2': blnBackground2 && ((status == 7 && currentMonth == 1) || (currentMonth >= 3 && getCurrentImprovement === 'Expansion' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && isType5InCapEx)), 
          'growBackground4': blnBackground4 && ((status == 7 && currentMonth == 1) || (currentMonth >= 3 && getCurrentImprovement === 'Expansion' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && isType5InCapEx)), 
          'growBackground5': blnBackground5 && ((status == 7 && currentMonth == 1) || (currentMonth >= 3 && getCurrentImprovement === 'Expansion' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && isType5InCapEx)), 
          'growBackground6': blnBackground6 && ((status == 7 && currentMonth == 1) || (currentMonth >= 3 && getCurrentImprovement === 'Expansion' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && isType5InCapEx)), 
          'growBackground7_8': blnBackground7_8 && ((status == 7 && currentMonth == 1) || (currentMonth >= 3 && getCurrentImprovement === 'Expansion' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && isType5InCapEx)), 
          'growBackground9': blnBackground9 && ((status == 7 && currentMonth == 1) || (currentMonth >= 3 && getCurrentImprovement === 'Expansion' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && isType5InCapEx))
        }"  [cdkDropListData]="cdkLstLocationMachine" class="container-locationMachine-styles container-styles-globals"
          (cdkDropListDropped)="fnTest()">
        </div>
      </div>
  
      <!-- error paso 7 posicionado al lado de machinary-->
      <div id="errorPaso7" *ngIf="(status == 7 || status == 8 || status == 21 || status == 22) && currentMonth == 1" class="container-styles-globals"></div>
      <!-- fin error paso 7 -->
      <!-- error paso 12 posicionado abajo de machinary-->
      <div id="errorPaso12" *ngIf="status == 12" class="container-styles-globals"></div>
      <!-- fin error paso 12 -->
  
      <label class="ioLogoText calibriFont" [ngStyle]="{'color': blnBackground7_8 || blnBackground9 || blnBackground2 ? 'black' : 'white'}">{{getSingleTextLabel('154')}}</label>
  
       <!-- container inside machinary capex -->
       <!--                                       MAQUINA 1                                                 -->
       <div *ngIf="status > 7 || currentMonth > 1 " class="container-insideMachineCapex" style="background-color:black;">
        <img class="img-machinary" [src]="
          currentMonth >= 4 && jsonBlnOpenModeActions.isEfficiency1 && jsonBlnOpenModeActions.isRawMaterials1 ? './../../../assets/stacks/1_50RawMaterials.svg' :
          currentMonth >= 3 && jsonBlnOpenModeActions.isRawMaterials1 ? './../../../assets/stacks/1_5RawMaterials.svg' : 
          currentMonth >= 3 && jsonBlnOpenModeActions.isEfficiency1 ? './../../../assets/stacks/1_50.svg' :
          './../../../assets/stacks/1_5.svg'"
          >
          <img class="img-machinary-wip-green"  [src]="
          isCompleteMachine(cdkLstInsideMachineWorkInProgress, 'machine1') && this.jsonBlnOpenModeActions.isRawMaterials1 ? './../../../assets/stacks/machine_WIP_green.png':
          isCompleteMachine(cdkLstInsideMachineWorkInProgress, 'machine1') && !this.jsonBlnOpenModeActions.isRawMaterials1 ? './../../../assets/stacks/machine_WIP_purple.png':
          currentMonth >= 4 && jsonBlnOpenModeActions.isEfficiency1 && jsonBlnOpenModeActions.isRawMaterials1 ? './../../../assets/stacks/machine_WIP_green-6.png' :
          currentMonth >= 3 && jsonBlnOpenModeActions.isRawMaterials1 ? './../../../assets/stacks/machine_WIP_green-4.png' : 
          currentMonth >= 3 && jsonBlnOpenModeActions.isEfficiency1 ? './../../../assets/stacks/machine_WIP_purple-6.png' :
          './../../../assets/stacks/machine_WIP_purple-4.png'">
        
        <label class="lblnumMachine txtBoldWhiteMedium calibri14 calibriFont" >{{getSingleTextLabel("23.1")}}</label>
        <p class="lblnumMachineTextoPeque calibri10 calibriFont" >{{getSingleTextLabel("53")}}</p>
        <div cdkDropList id="cdkLstInsideMachineCapex" [cdkDropListData]="cdkLstInsideMachineCapex" 
          class="container-insideMachineCapex-styles container-styles-globals2"
          [ngClass]="{
            'grow': (!blnBackgroundBlue && !blnBackground2 && !blnBackground4 && !blnBackground5 && !blnBackground6 && !blnBackground7_8 && !blnBackground9) && ((status==8 && currentMonth == 1) || (currentMonth >= 3 && getCurrentImprovement === 'Efficiency' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && isType4InCapEx)),
            'growBackgroundBlue': blnBackgroundBlue && ((status==8 && currentMonth == 1) || (currentMonth >= 3 && getCurrentImprovement === 'Efficiency' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && isType4InCapEx)),
            'growBackground2': blnBackground2 && ((status==8 && currentMonth == 1) || (currentMonth >= 3 && getCurrentImprovement === 'Efficiency' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && isType4InCapEx)),
            'growBackground4': blnBackground4 && ((status==8 && currentMonth == 1) || (currentMonth >= 3 && getCurrentImprovement === 'Efficiency' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && isType4InCapEx)),
            'growBackground5': blnBackground5&& ((status==8 && currentMonth == 1) || (currentMonth >= 3 && getCurrentImprovement === 'Efficiency' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && isType4InCapEx)),
            'growBackground6': blnBackground6 && ((status==8 && currentMonth == 1) || (currentMonth >= 3 && getCurrentImprovement === 'Efficiency' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && isType4InCapEx)),
            'growBackground7_8': blnBackground7_8 && ((status==8 && currentMonth == 1) || (currentMonth >= 3 && getCurrentImprovement === 'Efficiency' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && isType4InCapEx)),
            'growBackground9': blnBackground9 && ((status==8 && currentMonth == 1) || (currentMonth >= 3 && getCurrentImprovement === 'Efficiency' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && isType4InCapEx))
          }" 
          (cdkDropListDropped)="fnTest()">
          <div  [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable}" 
          *ngFor="let item of cdkLstInsideMachineCapex; let i = index" cdkDrag 
          [ngClass]="{'grow': status==25 && !blnBackgroundBlue && !blnBackground2 && !blnBackground4 && !blnBackground5 && !blnBackground6 && !blnBackground7_8 && !blnBackground9, 
                      'growBackgroundBlue': status==25 && blnBackgroundBlue,
                      'growBackground2': status==25 && blnBackground2,
                      'growBackground4': status==25 && blnBackground4,
                      'growBackground5': status==25 && blnBackground5,
                      'growBackground6': status==25 && blnBackground6,
                      'growBackground7_8': status==25 && blnBackground7_8,
                      'growBackground9': status==25 && blnBackground9}" 
          (click)="fnTest()" (cdkDragStarted)="fnTest()"
           [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard" [matTooltip]="item.draggingBy" style=" display: flex; align-items: flex-end;">
            <img 
            [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-4seleccionLandBuilding">
          </div>
        </div>
        <!-- container inside machinary people -->
        <div cdkDropList id="cdkLstInsideMachinePeople" [cdkDropListData]="cdkLstInsideMachinePeople"
          class="container-insideMachinePeople-styles container-styles-globals"
          [ngClass]="{'grow': (status == 12 || status == 22) && !blnBackgroundBlue && !blnBackground2 && !blnBackground4 && !blnBackground5 && !blnBackground6 && !blnBackground7_8 && !blnBackground9,
                      'growBackgroundBlue': (status == 12 || status == 22) && blnBackgroundBlue,
                      'growBackground2': (status == 12 || status == 22) && blnBackground2,
                      'growBackground4': (status == 12 || status == 22) && blnBackground4,
                      'growBackground5': (status == 12 || status == 22) && blnBackground5,
                      'growBackground6': (status == 12 || status == 22) && blnBackground6,
                      'growBackground7_8': (status == 12 || status == 22) && blnBackground7_8,
                      'growBackground9': (status == 12 || status == 22) && blnBackground9}"
          (cdkDropListDropped)="fnTest()">
          <div id="alignSeleccionMonito2" style="height:85%" [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable}" 
          *ngFor="let item of cdkLstInsideMachinePeople; let i = index" cdkDrag 
           (cdkDragStarted)="fnTest()"
           [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard" [matTooltip]="item.draggingBy">
            <img 
            [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+(item.type === 6 && (item.valor === 1 || item.valor === 12) ? '.png' : '.svg')" class="coinImg-4seleccion coinImg-5seleccionMonito2">
          </div>
        </div>
        <div style="width:50px; top:520px; position: absolute; left:950px; z-index:5500" class="pot" *ngIf="blnAnimCoinPeople == true && currentMonth == 1"><img style="width:30px" [src]="'../../../../assets/stacks/12_1.svg'" /></div>
        <!-- container inside machinary work in progress -->
        <div cdkDropList id="cdkLstInsideMachineWorkInProgress" [cdkDropListData]="cdkLstInsideMachineWorkInProgress" 
          class="container-insideMachineWorkInProgress-styles container-styles-globals"
          [ngStyle]="{'padding-top.px': jsonBlnOpenModeActions.isEfficiency1?42:jsonBlnOpenModeActions.isRawMaterials1?58:41}"
          [ngClass]="{
            'grow': (!blnBackgroundBlue && !blnBackground2 && !blnBackground4 && !blnBackground5 && !blnBackground6 && !blnBackground7_8 && !blnBackground9) && ((status == 21 || status==31) || (currentMonth >= 3 && getCurrentImprovement === 'Efficiency' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && isType11InCapEx)),
            'growBackgroundBlue': blnBackgroundBlue && ((status == 21 || status==31) || (currentMonth >= 3 && getCurrentImprovement === 'Efficiency' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && isType11InCapEx)),
            'growBackground2': blnBackground2 && ((status == 21 || status==31) || (currentMonth >= 3 && getCurrentImprovement === 'Efficiency' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && isType11InCapEx)),
            'growBackground4': blnBackground4 && ((status == 21 || status==31) || (currentMonth >= 3 && getCurrentImprovement === 'Efficiency' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && isType11InCapEx)),
            'growBackground5': blnBackground5 && ((status == 21 || status==31) || (currentMonth >= 3 && getCurrentImprovement === 'Efficiency' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && isType11InCapEx)),
            'growBackground6': blnBackground6 && ((status == 21 || status==31) || (currentMonth >= 3 && getCurrentImprovement === 'Efficiency' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && isType11InCapEx)),
            'growBackground7_8': blnBackground7_8 && ((status == 21 || status==31) || (currentMonth >= 3 && getCurrentImprovement === 'Efficiency' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && isType11InCapEx)),
            'growBackground9': blnBackground9 && ((status == 21 || status==31) || (currentMonth >= 3 && getCurrentImprovement === 'Efficiency' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && isType11InCapEx)),
            'animated': blnAnimRawMat == true, 'animatedFadeInUp': blnAnimRawMat == true, 'fadeInUp': blnAnimRawMat == true}"
          (cdkDropListDropped)="fnTest()">
          <div [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable, 
          'insideMachineWorkInProgressIndividual':  (item.valor == 3 && item.type == 7 && cdkLstInsideMachineWorkInProgress.length <=4 || item.valor == 4 && item.type == 14),
          'insideMachineWorkInProgressIndividualSizeSpecialType':  (item.valor == 4 && item.type == 16 || item.valor == 3 && item.type == 18 || item.valor == 1 && item.type == 14),
          'insideMachineWorkInProgres4':  (item.valor == 12 && item.type == 7 || item.valor == 12 && item.type == 8 || item.valor == 20 && item.type == 17 || item.valor == 16 && item.type == 16 ),  
          'insideMachineWorkInProgres4Type18': (item.valor == 12 && item.type == 18),
          'insideMachineWorkInProgressIndividualSize':  (item.valor == 3 && item.type == 8 && cdkLstInsideMachineWorkInProgress.length <=4 || item.valor == 5 && item.type == 17 && cdkLstInsideMachineWorkInProgress.length <=4),
          'insideMachineWorkInProgressIndividualSizeSix': (item.valor == 3 && item.type == 8 && cdkLstInsideMachineWorkInProgress.length >4 || item.valor == 5 && item.type == 17 && cdkLstInsideMachineWorkInProgress.length >4  || item.valor == 3 && item.type == 7 && cdkLstInsideMachineWorkInProgress.length >4),
          'insideMachineWorkInProgressSix': (item.valor == 30 && item.type == 17 || item.valor == 24 && item.type == 16 || item.valor == 18 && item.type == 8 || item.valor == 18 && item.type == 7 || item.valor == 6 && item.type==14 ),
          'insideMachineWorkInProgressSixType18': (item.valor == 18 && item.type == 18),
          'insideMachineWorkInProgres2':  (item.valor == 6 && item.type == 8)}"
            [ngStyle]="{'max-width.px': (item.valor >= 6 && item.type == 7  || item.valor >= 6 && item.type == 8 || item.valor >= 10 && item.type == 17 || 
             item.valor >= 2 && item.type == 14  || item.valor >= 8 && item.type == 16)  ? 60:30}"
              [ngStyle]="{'display': item.type == 17 && item.valor <= 9 ? 'inline' :'block', 'margin-top.px': (item.valor == 12 && item.type == 7 ? 10 : item.valor == 30 && item.type == 17 ? -10 : 0)}"
              *ngFor="let item of cdkLstInsideMachineWorkInProgress; let i = index" cdkDrag 
            (cdkDragStarted)="fnTest()"
            (click)="fnTest()"
           [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard" [matTooltip]="item.draggingBy">
            <img
              [ngStyle]="{'max-width.px':(item.valor >= 6 && item.type == 7 || item.valor >= 6 && item.type == 8 || item.valor >= 10 && item.type == 17 || 
                item.valor >= 2 && item.type == 14 || item.valor >= 8 && item.type == 16) ? 60:30, 'margin-top.px': (item.valor ==1 && item.type == 14)?4:item.valor==16 && item.type == 16?5:-0}"
                [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-workInProgres">
          </div>
        </div>
      </div>
      <div class="customizingDropDiv" *ngIf="(currentMonth >= 3 && getCurrentImprovement === 'Customizing' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment')) && !jsonBlnOpenModeActions.isCustomizingShow"
      [ngClass]="{
        'grow': (!blnBackgroundBlue && !blnBackground2 && !blnBackground4 && !blnBackground5 && !blnBackground6 && !blnBackground7_8 && !blnBackground9) && ((currentMonth >= 3 && getCurrentImprovement === 'Customizing' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment')) && !jsonBlnOpenModeActions.isCustomizingShow), 
        'growBackgroundBlue': blnBackgroundBlue && ((currentMonth >= 3 && getCurrentImprovement === 'Customizing' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment')) && !jsonBlnOpenModeActions.isCustomizingShow),
        'growBackground2': blnBackground2 && ((currentMonth >= 3 && getCurrentImprovement === 'Customizing' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment')) && !jsonBlnOpenModeActions.isCustomizingShow),
        'growBackground4': blnBackground4 && ((currentMonth >= 3 && getCurrentImprovement === 'Customizing' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment')) && !jsonBlnOpenModeActions.isCustomizingShow),
        'growBackground5': blnBackground5 && ((currentMonth >= 3 && getCurrentImprovement === 'Customizing' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment')) && !jsonBlnOpenModeActions.isCustomizingShow),
        'growBackground6': blnBackground6 && ((currentMonth >= 3 && getCurrentImprovement === 'Customizing' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment')) && !jsonBlnOpenModeActions.isCustomizingShow),
        'growBackground7_8': blnBackground7_8 && ((currentMonth >= 3 && getCurrentImprovement === 'Customizing' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment')) && !jsonBlnOpenModeActions.isCustomizingShow),
        'growBackground9': blnBackground9 && ((currentMonth >= 3 && getCurrentImprovement === 'Customizing' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment')) && !jsonBlnOpenModeActions.isCustomizingShow)
        }"
      ></div>
       <!--                                       MAQUINA de CUSTOMIZING                                                -->
       <div *ngIf="jsonBlnOpenModeActions.isCustomizingShow && currentMonth >= 3" class="container-insideMachineCapex" style="background-color:black;">
        <img class="img-machinaryCustomizing" src="./../../../assets/stacks/1_10.svg">
        
        <div cdkDropList id="cdkLstInsideMachineCapexCustomizing" [cdkDropListData]="cdkLstInsideMachineCapexCustomizing" 
          class="container-cdkLstInsideMachineCapexCustomizing-styles container-styles-globals2"
          (cdkDropListDropped)="fnTest()"
          [ngClass]="{
            'grow': (!blnBackgroundBlue && !blnBackground2 && !blnBackground4 && !blnBackground5 && !blnBackground6 && !blnBackground7_8 && !blnBackground9) && (currentMonth >= 3 && getCurrentImprovement === 'Customizing' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && cdkLstInsideMachineCapexCustomizing.length === 0), 
            'growBackgroundBlue': blnBackgroundBlue && (currentMonth >= 3 && getCurrentImprovement === 'Customizing' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && cdkLstInsideMachineCapexCustomizing.length === 0),
            'growBackground2': blnBackground2 && (currentMonth >= 3 && getCurrentImprovement === 'Customizing' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && cdkLstInsideMachineCapexCustomizing.length === 0),
            'growBackground4': blnBackground4 && (currentMonth >= 3 && getCurrentImprovement === 'Customizing' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && cdkLstInsideMachineCapexCustomizing.length === 0),
            'growBackground5': blnBackground5 && (currentMonth >= 3 && getCurrentImprovement === 'Customizing' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && cdkLstInsideMachineCapexCustomizing.length === 0),
            'growBackground6': blnBackground6 && (currentMonth >= 3 && getCurrentImprovement === 'Customizing' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && cdkLstInsideMachineCapexCustomizing.length === 0),
            'growBackground7_8': blnBackground7_8 && (currentMonth >= 3 && getCurrentImprovement === 'Customizing' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && cdkLstInsideMachineCapexCustomizing.length === 0),
            'growBackground9': blnBackground9 && (currentMonth >= 3 && getCurrentImprovement === 'Customizing' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && cdkLstInsideMachineCapexCustomizing.length === 0)
            }"
          >
          <div  [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable}" 
          *ngFor="let item of cdkLstInsideMachineCapexCustomizing; let i = index" cdkDrag 
          (click)="fnTest()" (cdkDragStarted)="fnTest()"
           [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard" [matTooltip]="item.draggingBy"  style="height: fit-content;">
            <img 
            [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-4seleccionLandBuilding">
          </div>
          <label class="txtBoldWhiteSmall calibri11 calibriFont" style="position: absolute; top: -15px; left: 0.5px">Customizing</label>
        </div>
      </div>
        <!--                                       MAQUINA 2                                                 -->
       <div *ngIf="jsonBlnOpenModeActions.isSecondMachine && currentMonth >= 3" class="container-insideMachineCapex">
        <img class="img-machinary2" [src]="
          jsonBlnOpenModeActions.isEfficiency2 && jsonBlnOpenModeActions.isRawMaterials2 ? './../../../assets/stacks/1_50RawMaterials.svg' :
          jsonBlnOpenModeActions.isRawMaterials2 ? './../../../assets/stacks/1_5RawMaterials.svg' : 
          jsonBlnOpenModeActions.isEfficiency2 ? './../../../assets/stacks/1_50.svg' :
          './../../../assets/stacks/1_5.svg'"
          >
          <img class="img-machinary-wip-green2"  [src]="
          isCompleteMachine(cdkLstInsideMachineWorkInProgress2, 'machine2') && this.jsonBlnOpenModeActions.isRawMaterials2 ? './../../../assets/stacks/machine_WIP_green.png':
          isCompleteMachine(cdkLstInsideMachineWorkInProgress2, 'machine2') && !this.jsonBlnOpenModeActions.isRawMaterials2 ? './../../../assets/stacks/machine_WIP_purple.png':
          currentMonth >= 4 && jsonBlnOpenModeActions.isEfficiency2 && jsonBlnOpenModeActions.isRawMaterials2 ? './../../../assets/stacks/machine_WIP_green-6.png' :
          currentMonth >= 3 && jsonBlnOpenModeActions.isRawMaterials2 ? './../../../assets/stacks/machine_WIP_green-4.png' : 
          currentMonth >= 3 && jsonBlnOpenModeActions.isEfficiency2 ? './../../../assets/stacks/machine_WIP_purple-6.png' :
          './../../../assets/stacks/machine_WIP_purple-4.png'">
        <label class="lblnumMachine2 txtBoldWhiteMedium calibri14 calibriFont" >{{getSingleTextLabel("23.1")}} 2</label>
        <p class="lblnumMachineTextoPequeMaq2 calibri10 calibriFont" >{{getSingleTextLabel("53")}}</p>
        <div cdkDropList id="cdkLstInsideMachineCapex2" [cdkDropListData]="cdkLstInsideMachineCapex2" 
          class="container-insideMachineCapex2-styles container-styles-globals2"
          (cdkDropListDropped)="fnTest()"
          [ngClass]="{
            'grow': (!blnBackgroundBlue && !blnBackground2 && !blnBackground4 && !blnBackground5 && !blnBackground6 && !blnBackground7_8 && !blnBackground9) && ((currentMonth >= 3 && getCurrentImprovement === 'Expansion' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && isType4InCapEx)),
            'growBackgroundBlue': blnBackgroundBlue && ((currentMonth >= 3 && getCurrentImprovement === 'Expansion' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && isType4InCapEx)),
            'growBackground2': blnBackground2 && ((currentMonth >= 3 && getCurrentImprovement === 'Expansion' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && isType4InCapEx)),
            'growBackground4': blnBackground4 && ((currentMonth >= 3 && getCurrentImprovement === 'Expansion' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && isType4InCapEx)),
            'growBackground5': blnBackground5&& ((currentMonth >= 3 && getCurrentImprovement === 'Expansion' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && isType4InCapEx)),
            'growBackground6': blnBackground6 && ((currentMonth >= 3 && getCurrentImprovement === 'Expansion' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && isType4InCapEx)),
            'growBackground7_8': blnBackground7_8 && ((currentMonth >= 3 && getCurrentImprovement === 'Expansion' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && isType4InCapEx)),
            'growBackground9': blnBackground9 && ((currentMonth >= 3 && getCurrentImprovement === 'Expansion' && isCheckListCompleteBeforeThan('Buy or Upgrade Equipment') && isType4InCapEx))
          }" >
          <div *ngFor="let item of cdkLstInsideMachineCapex2; let i = index" cdkDrag 
          (click)="fnTest()" (cdkDragStarted)="fnTest()"
           [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard" [matTooltip]="item.draggingBy"  style=" display: flex; align-items: flex-end;">
            <img 
            [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-4seleccionLandBuilding">
          </div>
        </div>
        <!-- container inside machinary people -->
        <div cdkDropList id="cdkLstInsideMachinePeople2" [cdkDropListData]="cdkLstInsideMachinePeople2"
          class="container-insideMachinePeople2-styles container-styles-globals"
          (cdkDropListDropped)="fnTest()"
          [ngClass]="{
            'grow': (!blnBackgroundBlue && !blnBackground2 && !blnBackground4 && !blnBackground5 && !blnBackground6 && !blnBackground7_8 && !blnBackground9) && ((currentMonth >= 3 && getCurrentImprovement === 'Expansion' && isCheckListCompleteBeforeThan('Pay Recruitment and trainning') && cdkLstInsideMachinePeople2.length == 0)), 
            'growBackgroundBlue': blnBackgroundBlue && ((currentMonth >= 3 && getCurrentImprovement === 'Expansion' && isCheckListCompleteBeforeThan('Pay Recruitment and trainning') && cdkLstInsideMachinePeople2.length == 0)),
            'growBackground2': blnBackground2 && ((currentMonth >= 3 && getCurrentImprovement === 'Expansion' && isCheckListCompleteBeforeThan('Pay Recruitment and trainning') && cdkLstInsideMachinePeople2.length == 0)),
            'growBackground4': blnBackground4 && ((currentMonth >= 3 && getCurrentImprovement === 'Expansion' && isCheckListCompleteBeforeThan('Pay Recruitment and trainning') && cdkLstInsideMachinePeople2.length == 0)),
            'growBackground5': blnBackground5 && ((currentMonth >= 3 && getCurrentImprovement === 'Expansion' && isCheckListCompleteBeforeThan('Pay Recruitment and trainning') && cdkLstInsideMachinePeople2.length == 0)),
            'growBackground6': blnBackground6 && ((currentMonth >= 3 && getCurrentImprovement === 'Expansion' && isCheckListCompleteBeforeThan('Pay Recruitment and trainning') && cdkLstInsideMachinePeople2.length == 0)),
            'growBackground7_8': blnBackground7_8 && ((currentMonth >= 3 && getCurrentImprovement === 'Expansion' && isCheckListCompleteBeforeThan('Pay Recruitment and trainning') && cdkLstInsideMachinePeople2.length == 0)),
            'growBackground9': blnBackground9 && ((currentMonth >= 3 && getCurrentImprovement === 'Expansion' && isCheckListCompleteBeforeThan('Pay Recruitment and trainning') && cdkLstInsideMachinePeople2.length == 0))
          }"
          >
          <div id="alignSeleccionMonito2"  [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable}" 
          *ngFor="let item of cdkLstInsideMachinePeople2; let i = index" cdkDrag 
          (cdkDragStarted)="fnTest()"
          [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard" [matTooltip]="item.draggingBy">
            <img 
            [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+(item.type === 6 && (item.valor === 1 || item.valor === 12) ? '.png' : '.svg')" class="coinImg-4seleccion coinImg-5seleccionMonito2">
          </div>
        </div>
        <!-- container inside machinary work in progress -->
        <div cdkDropList id="cdkLstInsideMachineWorkInProgress2" [cdkDropListData]="cdkLstInsideMachineWorkInProgress2" 
          class="container-insideMachineWorkInProgress2-styles container-styles-globals" style="text-align:start !important"
          [ngStyle]="{'padding-top.px': jsonBlnOpenModeActions.isEfficiency2?42:jsonBlnOpenModeActions.isRawMaterials2?58:41}"
          (cdkDropListDropped)="fnTest()">
          <div [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable, 
          'insideMachineWorkInProgressIndividual':  (item.valor == 3 && item.type == 7  && cdkLstInsideMachineWorkInProgress2.length <=4 ),
          'insideMachineWorkInProgres4':  (item.valor == 12 && item.type == 7 || item.valor == 12 && item.type == 8 || item.valor == 20 && item.type == 17 || item.valor == 16 && item.type == 16 ),  
          'insideMachineWorkInProgres4Type18': (item.valor == 12 && item.type == 18),
          'insideMachineWorkInProgressIndividualSizeSpecialType':  (item.valor == 4 && item.type == 16 || item.valor == 3 && item.type == 18 || item.valor == 1 && item.type == 14),
          'insideMachineWorkInProgressIndividualSize':  (item.valor == 3 && item.type == 8 && cdkLstInsideMachineWorkInProgress.length <=4 || item.valor == 5 && item.type == 17 && cdkLstInsideMachineWorkInProgress.length <=4),
          'insideMachineWorkInProgressIndividualSizeSix': (item.valor == 3 && item.type == 8 && cdkLstInsideMachineWorkInProgress.length >4 || item.valor == 5 && item.type == 17 && cdkLstInsideMachineWorkInProgress.length >4  || item.valor == 3 && item.type == 7 && cdkLstInsideMachineWorkInProgress2.length >4),
          'insideMachineWorkInProgressSix': (item.valor == 30 && item.type == 17 || item.valor == 24 && item.type == 16 || item.valor == 18 && item.type == 8 || item.valor == 18 && item.type == 7 || item.valor == 6 && item.type==14 ),
          'insideMachineWorkInProgressSixType18': (item.valor == 18 && item.type == 18),
          'insideMachineWorkInProgressSixType7': (item.valor == 18 && item.type == 7),
          'insideMachineWorkInProgres2':  (item.valor == 6 && item.type == 8),
          'animated': blnAnimRawMat2 == true, 'animatedFadeInUp': blnAnimRawMat2 == true, 'fadeInUp': blnAnimRawMat2 == true}"
            [ngStyle]="{'max-width.px': (item.valor >= 6 && item.type == 7  || item.valor >= 6 && item.type == 8 || item.valor >= 10 && item.type == 17 || 
             item.valor >= 2 && item.type == 14  || item.valor >= 8 && item.type == 16)  ? 60:30}"
              [ngStyle]="{'display': item.type == 17 && item.valor <= 9 ? 'inline' :'block', 'margin-top.px': (item.valor == 12 && item.type == 7 ? 10 :0)}"
            *ngFor="let item of cdkLstInsideMachineWorkInProgress2; let i = index" cdkDrag 
            (cdkDragStarted)="fnTest()"
            (click)="fnTest()"
           [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard" [matTooltip]="item.draggingBy">
            <img 
            [ngStyle]="{'max-width.px':(item.valor >= 6 && item.type == 7 || item.valor >= 6 && item.type == 8 || item.valor >= 10 && item.type == 17 || 
            item.valor >= 2 && item.type == 14 || item.valor >= 8 && item.type == 16) ? 60:30, 'margin-top.px': (item.valor >=1 && item.valor <4 && item.type == 14)?10:item.valor==16 && item.type == 16?5 :(item.valor ==4 && item.type == 14)?2:-2,'margin-left.px':(item.valor==4 && item.type == 14)?3:0}"
            [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-workInProgres">
          </div>
        </div>
      </div>
      <!-- container payables 60 -->
      <label class="lblnum67 txtWhiteSmall calibri14 calibriFont" >{{getSingleTextLabel("67")}}</label>
      <div class="container-Payables60" style="background-color:black;">
      <div *ngIf="currentMonth != 1" class="container-Payables60" style="background-color:black;">
        <div cdkDropList [ngStyle]="{'text-align':cdkLstPayables60.length>=2?'center':'right', 'width.px':cdkLstPayables60.length==2?70:cdkLstPayables60.length>2?110:65, 'left.px': cdkLstPayables60.length>2?665:685 }" id="cdkLstPayables60" [cdkDropListData]="cdkLstPayables60" class="container-payables60-styles container-styles-globals"
          (cdkDropListDropped)="fnTest()">
          <div [ngStyle]="{'margin-right.px':cdkLstPayables60.length>=2?0:5}" class="example-box-destinity-inclinacion-25" *ngFor="let item of cdkLstPayables60; let i = index" cdkDrag 
             (cdkDragStarted)="fnTest()"
            [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard" [matTooltip]="item.draggingBy" [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable}" >
             <img 
             [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-trash">
          </div>
        </div>
      </div>
      <!-- container payables 30 -->
      <div *ngIf="currentMonth != 1" class="container-Payables30" style="background-color:black;">
        <div cdkDropList id="cdkLstPayables30" [ngStyle]="{ 'width.px':cdkLstPayables30.length>=3?135:122}" [cdkDropListData]="cdkLstPayables30" class="container-payables30-styles container-styles-globals"
          (cdkDropListDropped)="fnTest()">
          <div [ngStyle]="{'margin-left.px':cdkLstPayables30.length==1?3:2}" class="example-box-destinity-inclinacion-28" *ngFor="let item of cdkLstPayables30; let i = index" cdkDrag 
             (cdkDragStarted)="fnTest()"
            [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard" [matTooltip]="item.draggingBy" [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable}" >
             <img 
             [src]="'../../../../assets/stacks/'+item.valor+'_'+item.type+'.svg'" class="coinImg-trash">
          </div>
        </div>
      </div>
      <!-- container other -->
      <label class="lblnum70 txtWhiteSmall calibri14 calibriFont" >{{getSingleTextLabel("70")}}</label>
      <div class="container-Other" style="background-color:black;">
      <div *ngIf="currentMonth != 1" class="container-Other" style="background-color:black;">
        <div cdkDropList id="cdkLstOther" [cdkDropListData]="cdkLstOther" class="container-other-styles container-styles-globals"
          (cdkDropListDropped)="fnTest()">
          <div class="example-box-destinity-inclinacion-pos47" *ngFor="let item of cdkLstOther; let i = index" cdkDrag 
             (cdkDragStarted)="fnTest()"
             [matTooltip]="item.draggingBy" [ngClass]="{'box-piece-enable': !item.draggable, 'box-piece-disable': item.draggable}" 
            [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard">
            <img src="../../../../assets/fondoCoin.png" width="40" style="z-index: 5000 !important;" height="32">
            <label *ngIf="item.valor > 9"
              style="color: rgb(100, 71, 7);position: relative;font-size: 13px;font-weight:bold;right:27px;">{{item.valor}}</label>
  
            <label *ngIf="item.valor <= 9"
              style="color: rgb(100, 71, 7);position: relative;font-size: 13px;font-weight:bold;right:24px;">{{item.valor}}</label>
  
          </div>
        </div>
      </div>
      
    
      <!-- <div class="example-container-destinity" style="background-color:transparent;">
  
        <div cdkDropList id="cdklistDestinity" [cdkDropListData]="listDestinity" class="example-list-destinity"
          (cdkDropListDropped)="droptodestinity($event)">
          <div class="example-box-destinity" *ngFor="let item of listDestinity; let i = index" cdkDrag 
             (cdkDragStarted)="fnTest()"
           [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard">
            <img src="../../../../assets/fondoCoin.png" width="40" style="z-index: 5000 !important;" height="32">
            <label *ngIf="item.valor > 9"
              style="color: rgb(100, 71, 7);position: relative;font-size: 13px;font-weight:bold;right:27px;">{{item.valor}}</label>
  
            <label *ngIf="item.valor <= 9"
              style="color: rgb(100, 71, 7);position: relative;font-size: 13px;font-weight:bold;right:24px;">{{item.valor}}</label>
  
          </div>
        </div>
  
        <div cdkDropList id="cdklstBank" [cdkDropListData]="listBanco" class="example-list"
          (cdkDropListDropped)="dropdestinityTObank($event)">
          <div class="example-box" *ngFor="let item of listBanco; let i = index" cdkDrag (click)="item.draggable ? $event.stopPropagation(): fnverdatos(item, 1); item.draggable ? false : null"
            [class.isDisabled]="item.draggable" 
           [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard" (cdkDragStarted)="fnTest()"  >
            <img [src]="!item.draggable ? '../../../../assets/fondoCoin.png': '../../../../assets/fondoCoinSeleccionada.png'"  width="40" style="z-index: 5000 !important;" height="32">
            
            <label [attr.title]="item.byUser" *ngIf="item.valor > 9"
              style="color: rgb(100, 71, 7);position: relative;font-size: 13px;font-weight:bold;right:27px;">{{item.valor}}</label>
  
            <label [attr.title]="item.byUser" *ngIf="item.valor <= 9"
              style="color: rgb(100, 71, 7);position: relative;font-size: 13px;font-weight:bold;right:24px;">{{item.valor}}</label>
            
          </div>
        </div>
  
        <!- -Nueva area draggable advertising- ->
        <div cdkDropList id="cdklistAdvertisement" [cdkDropListData]="listAdvertisement"
          class="example-list-advertisement" (cdkDropListDropped)="dropanylistTOAdvertisement($event)">
          <div class="example-box-advertisement" *ngFor="let item of listAdvertisement; let i = index" cdkDrag
            (click)="fnverdatos(item, 2)" (cdkDragStarted)="fnTest()"
           [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard">
            <img src="../../../../assets/fondoCoin.png" width="40" style="z-index: 5000 !important;" height="32">
  
            <label *ngIf="item.valor > 9"
              style="color: rgb(100, 71, 7);position: relative;font-size: 13px;font-weight:bold;right:27px;">{{item.valor}}</label>
  
            <label *ngIf="item.valor <= 9"
              style="color: rgb(100, 71, 7);position: relative;font-size: 13px;font-weight:bold;right:24px;">{{item.valor}}</label>
  
          </div>
        </div>
        <!- -fin nueva area draggable- ->
        <!- -Nueva area draggable sales&marketing- ->
        <div cdkDropList id="cdklistMarketing" [cdkDropListData]="listMarketing" class="example-list-Marketing"
          (cdkDropListDropped)="dropanylistTOMarketing($event)">
          <div class="example-box-Marketing" *ngFor="let item of listMarketing; let i = index" cdkDrag 
          (click)="fnverdatos(item, 3)" (cdkDragStarted)="fnTest()"
         [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard">
            <img src="../../../../assets/fondoCoin.png" width="40" style="z-index: 5000 !important;" height="32">
  
            <label *ngIf="item.valor > 9"
              style="color: rgb(100, 71, 7);position: relative;font-size: 13px;font-weight:bold;right:27px;">{{item.valor}}</label>
  
            <label *ngIf="item.valor <= 9"
              style="color: rgb(100, 71, 7);position: relative;font-size: 13px;font-weight:bold;right:24px;">{{item.valor}}</label>
  
          </div>
        </div>
        <!- - fin nueva area draggable- ->
        <! --Nueva area draggable sales&marketing- ->
        <div cdkDropList id="cdklistReceivable" [cdkDropListData]="listReceivable" class="example-list-Receivable"
          (cdkDropListDropped)="dropanylistTOReceivable($event)">
          <div class="example-box-Receivable" *ngFor="let item of listReceivable; let i = index" cdkDrag 
          (cdkDragStarted)="fnTest()" (click)="fnverdatos(item, 4)"
         [cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard">
            <img src="../../../../assets/fondoCoin.png" width="40" style="z-index: 5000 !important;" height="32">
  
            <label *ngIf="item.valor > 9"
              style="color: rgb(100, 71, 7);position: relative;font-size: 13px;font-weight:bold;right:27px;">{{item.valor}}</label>
  
            <label *ngIf="item.valor <= 9"
              style="color: rgb(100, 71, 7);position: relative;font-size: 13px;font-weight:bold;right:24px;">{{item.valor}}</label>
  
          </div>
        </div>
  
        <!- -Nueva area draggable margin-- >
          <div cdkDropList id="cdklistMargin" [cdkDropListData]="listMargin" class="example-list-margin"
          (cdkDropListDropped)="dropanylistTOMargin($event)">
            <div class="example-box-Receivable" *ngFor="let item of listMargin; let i = index" 
            disabled="false" cdkDrag (cdkDragStarted)="fnTest()" 
            (click)="fnverdatos(item, 5)"[cdkDragDisabled]="item.draggable || isOpenMarketDisabledBoard">
              <img src="../../../../assets/fondoCoin.png" width="40" style="z-index: 5000 !important;" height="32">
  
              <label *ngIf="item.valor > 9"
                style="color: rgb(100, 71, 7);position: relative;font-size: 13px;font-weight:bold;right:27px;">{{item.valor}}</label>
  
              <label *ngIf="item.valor <= 9"
                style="color: rgb(100, 71, 7);position: relative;font-size: 13px;font-weight:bold;right:24px;">{{item.valor}}</label>
  
          </div>
        <!- -fin nueva area draggable- ->
  
  
        <!- -fin nueva area draggable- ->
  
  
      </div> -->
  
  
  
      <!--<div class="example-container">
                <h5 style="color: blue;">Investment fund</h5>
            
                
            </div>-->
  
  
      <!--</div>-->
  
    
    </div>
    <label class="userwithControl txtBoldWhitebtnbluesmall calibri12 calibriFont"  style="text-align: center !important;" >{{lblUserwithControl}}</label>
    <div *ngIf="!isConnected" class="container_wifi" style="text-align: center;" >
      <!--<h1>Conexión a Internet Perdida: se ha deshabilitado el Board</h1> -->   
      <img width="28px" height="28px" src="assets/no-wifi.png" alt="Wifi Connected"  /> 
    </div>    
    <div *ngIf="isConnected" class="container_wifi" style="text-align: center;" >  
      <!--<h1>Conexión a Internet establecida: se ha Activado el Board</h1> -->
      <img width="28px" height="28px" src="assets/wifi.png" alt="Wifi Disconnected" />  
    </div>
     <!-- error WIFI -->
     <div id="errorConnection"  class="container_wifi_error"></div>
  
  </div>
  <ngx-spinner bdColor="rgba(0,0,0,0.75)" size="large" color="#ffffff" type="ball-clip-rotate-multiple"
          [fullScreen]="true">
          <p style="color: white"> Loading... </p>
      </ngx-spinner>
  <!--<p style="background-color: white;">{{isModeOpen ? 'open':'rigorous'}}</p>-->
  