import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SocketioService } from '../../socketio.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { _URL_BALANCE } from 'src/app/config/config';

@Injectable({
  providedIn: 'root'
})
export class BalanceIncomeService {

  private $balance: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private $income: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  _balance: Observable<any> = this.$balance.asObservable();
  _income: Observable<any> = this.$income.asObservable();
  socket = this.socketioService.socket;

  constructor(private socketioService: SocketioService,private http: HttpClient) { console.log("hola"); }

  setupSocketConnection() {
    this.socket.on('updateInputBalanceSimpleServer', (data) => {
      this.$balance.next({ data, status: 'updateInputBalanceSimpleServer' });
    });
    this.socket.on('updateInputBalanceServer', (data) => {
      this.$balance.next({ data, status: 'updateInputBalanceServer' });
    });
    this.socket.on('updateInputIncomeServer', (data) => {
      this.$income.next({ data, status: 'updateInputIncomeServer' });
    });
    this.socket.once('showToastResultNetIncomeServer', (data) => {
      this.$income.next({ data, status: 'showToastResultNetIncomeServer' });
    });
    this.socket.on('sbsStepCompleted', (data)=>{
      this.$balance.next({ data, status: 'sbsStepCompleted' });
    });
    this.socket.on('reloadTeamsChecklist', (data)=>{
      this.$balance.next({ data, status: 'reloadTeamsChecklist' });
    });
    this.socket.on('resetChecklist', (data)=>{
      this.$balance.next({ data, status: 'resetChecklist' });
    });
  }
  
  closeListener(){
    this.socket.off("updateInputBalanceSimpleServer", () => {});
    this.socket.off("updateInputBalanceServer", () => {});
    this.socket.off("updateInputIncomeServer", () => {});
    this.socket.off("sbsStepCompleted", () => {});
    this.socket.off("reloadTeamsChecklist", () => {});
    this.socket.off("resetChecklist", () => {});
    this.socket.off("updateInputBalanceSimpleServer");
    this.socket.off("updateInputBalanceServer");
    this.socket.off("updateInputIncomeServer");
    this.socket.off("sbsStepCompleted");
    this.socket.off("reloadTeamsChecklist");
    this.socket.off("resetChecklist");
  }

  
  updateInputSimpleBalance(value, id, room, strNgModel){
    const key = 'updateInputBalanceSimple';
    this.socket.emit(key, {
      value: value,
      id: id,
      room,
      strNgModel
    });
  }

  updateInputSimpleBalanceTotal(value, id, room, strNgModel){
    const key = 'updateInputBalanceSimpleTotal';
    this.socket.emit(key, {
      value: value,
      id: id,
      room,
      strNgModel
    });
  }

  updateInputIncomeStatement(value, id, room, i, workshopId, teamId, month){
    const key = 'updateInputIncome';
    this.socket.emit(key, {
      value: value,
      id: id,
      i,
      room,
      workshopId,
      teamId,
      month,
      column: id
    });
  }

  updateInputStandarBalance(value, id, room, i, workshopId, teamId, month){
    const key = 'updateInputBalance';
    this.socket.emit(key, {
      value: value,
      id: id,
      i,
      room,
      workshopId,
      teamId,
      month,
      column: id
    });
  }

  showToastResultNetIncome(txt, room, resultado5){
    this.socket.emit('showToastResultNetIncome', {
      room,
      txt,
      resultado5
    });
  }

  updateSize(
    type,
idUser,
size, 
  ): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
      type,
      idUser,
      size,
    });
    return this.http.post(_URL_BALANCE + '/updateSize', params, {
      headers: httpHeaders,
    });
  }
  getSize(type,idUser,): Observable<any>  {
    return this.http.get(_URL_BALANCE + '/getSize/' + type+'/'+idUser);
  }
}
