import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { _URL_IMPROVEMENT } from './../../config/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImprovementService {

  constructor(private http: HttpClient) {}

  getImprovementByTeamId(teamId): Observable<any>  {
    return this.http.get(_URL_IMPROVEMENT + '/getImprovement/' + teamId);
  }

  getImprovementsByWorkShopId(workshopId): Observable<any>  {
    return this.http.get(_URL_IMPROVEMENT + '/getImprovements/' + workshopId);
  }

  getAllmonthImprovementsByWorkShopId(workshopId): Observable<any>  {
    return this.http.get(_URL_IMPROVEMENT + '/getAllmonthImprovements/' + workshopId);
  }

  getImprovementStatus(workShopId): Observable<any>  {
    return this.http.get(_URL_IMPROVEMENT + '/getImprovementStatus/' + workShopId);
  }
  getProjectStatus(workShopId): Observable<any>  {
    return this.http.get(_URL_IMPROVEMENT + '/getProjectStatus/' + workShopId);
  }

  getMaxPerImprove(workShopId): Observable<any>  {
    return this.http.get(_URL_IMPROVEMENT + '/getMaxPerImprove/' + workShopId);
  }

  getImprovementOptionsByTeamId(teamId): Observable<any>  {
    return this.http.get(_URL_IMPROVEMENT + '/getImprovementOptions/' + teamId);
  }

  updateImprovementOptionById(
    workshopId,
    improveOptionId,
    improveOption, 
    improveStatus,
    month,
    _reselectImprovementPermission
  ): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
      workshopId,
      improveOptionId,
      improveOption, 
      improveStatus,
      month,
      _reselectImprovementPermission
    });
    return this.http.put(_URL_IMPROVEMENT + '/updateImprovementOption', params, {
      headers: httpHeaders,
    });
  }


  updateImprovementApproved(
    improveOptionId, 
    improveStatus,
    approved
  ): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
      improveOptionId, 
      improveStatus,
      approved
    });
    return this.http.put(_URL_IMPROVEMENT + '/updateImprovementApproved', params, {
      headers: httpHeaders,
    });
  }

  updateImprovementSelected(
    improveOptionId, 
    improveId
  ): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
      improveOptionId, 
      improveId
    });
    return this.http.put(_URL_IMPROVEMENT + '/updateImprovementSelected', params, {
      headers: httpHeaders,
    });
  }

  

  updateMaxPerImproveByWorkShopId(
    workShopId,
    maxPerImprove
  ): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
      workShopId,
      maxPerImprove
    });
    return this.http.put(_URL_IMPROVEMENT + '/updateMaxPerImprove', params, {
      headers: httpHeaders,
    });
  }

  updateImprovementStatusByWorkshopId(
    workshopId, 
    improveStatus, month
  ): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
      workshopId, 
      improveStatus,
      month
    });
    return this.http.put(_URL_IMPROVEMENT + '/updateImprovementStatus', params, {
      headers: httpHeaders,
    });
  }

  updateImproveOptionStatus(
    improveOptionId,
    improveStatus,
  ): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
      improveOptionId,
      improveStatus,
    });
    return this.http.put(_URL_IMPROVEMENT + '/updateImproveOptionStatus', params, {
      headers: httpHeaders,
    });
  }
  updatePendingProjectByWorkShopId(
    workshopId,
    pendingProject,
  ): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
      workshopId,
      pendingProject,
    });
    return this.http.put(_URL_IMPROVEMENT + '/updatePendingProjectByWorkShopId', params, {
      headers: httpHeaders,
    });
  }

  getPendingProjectByWorkShopId(workShopId): Observable<any>  {
    return this.http.get(_URL_IMPROVEMENT + '/getPendingProjectByWorkShopId/' + workShopId);
  }

  updateImproveOptionQuestion(
    improveOptionId,
    status,
  ): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
      improveOptionId,
      status,
    });
    return this.http.put(_URL_IMPROVEMENT + '/updateImproveOptionQuestion', params, {
      headers: httpHeaders,
    });
  }

  
  setAllTeamsUnApprovedImprovement(workshopId: number): Observable<any>{
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
      workshopId,
    });
    return this.http.post(_URL_IMPROVEMENT + '/setAllTeamsUnapprovedImprovement', params, {
      headers: httpHeaders,
    });
  }
  updateProjectsStatus(
    workshopId, 
    status
  ): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
      workshopId, 
      status
    });
    return this.http.post(_URL_IMPROVEMENT + '/updateProjectsStatus', params, {
      headers: httpHeaders,
    });
  }
}
