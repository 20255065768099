import { SocketioService } from './../../services/socketio.service';
import { BoardService } from './../../services/board/board.service';
import { TeamService } from './../../services/team/team.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-board-mode',
  templateUrl: './dialog-board-mode.component.html',
  styleUrls: ['./dialog-board-mode.component.scss']
})
export class DialogBoardModeComponent implements OnInit {

  teams = [
    // {TeamId: 10, WorkshopId: 63, Avatar: "tm1askfa ", Mode: 0, Color: "#ffffff"},
    // {TeamId: 10, WorkshopId: 63, Avatar: "tm2askfa ", Mode: 0, Color: "#ac584a"},
    // {TeamId: 10, WorkshopId: 63, Avatar: "tm3askfa ", Mode: 1, Color: "#58b325"},
    // {TeamId: 10, WorkshopId: 63, Avatar: "tm4askfa ", Mode: 1, Color: "#452987"},
    // {TeamId: 10, WorkshopId: 63, Avatar: "tm5askfa ", Mode: 0, Color: "#f7521a"},
    // {TeamId: 10, WorkshopId: 63, Avatar: "tm6askfa ", Mode: 0, Color: "#05f348"},
  ]


  constructor(
    private teamservice: TeamService,
    private socketioService: SocketioService,
    // private boardService: BoardService,
    public matDialogRef: MatDialogRef<DialogBoardModeComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any
  ) { }

  ngOnInit(): void {
    console.log(this._data);
    this.teamservice.getAllTeamsById(this._data['workshopId']).subscribe(responseTeams => {
      console.log(responseTeams, "response Teams");
      this.teams = responseTeams.teams as Array<any>;
    });
  }


  changeSwitch(event, item){
    console.log(event, item);
    const mode = event.checked ? 1 : 0;
    this.socketioService.updateMode(item.TeamId, mode);
  }
  
  /*getSingleTextLabel(labelNumber: string): string {
    const tmp = this._data.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    return tmp ? tmp.Label : '';
  }*/
}
