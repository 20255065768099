<div class="all">
<div *ngIf="jsonLabels[10] != undefined" mat-dialog-content cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>

    <div>
        <h2 class="style1 calibriFont">{{fnObtenerLabel('44')}}</h2>
        <p class="style2b calibriFont" style="padding: 10px;">{{fnObtenerLabel('3017')}}</p>
    </div>

    <div class="divstylesize">
        <p style="text-align: center;" class="style11 calibriFont">{{fnObtenerLabel('3018')}}</p>
        <!-- <div class="stylebtnsize"><i class="fa fa-search-plus" (click)="fnResize(true)" title="Expand Size"></i>
            &nbsp;&nbsp;&nbsp;<i class="fa fa-search-minus" (click)="fnResize(false)" title="Minimize Size"></i></div> -->
    </div>
    
    <div class="row" class="marginwhite">
        <div class="grid-container">
            <!-- <div class="marginblack style12 calibriFont" style="width: 359px; text-align: center;"> {{fnObtenerLabel('44')}} </div> -->
            <div class="marginblack style12 calibriFont verticalAligmentDiv" style="width: 389px; text-align: center;"> {{fnObtenerLabel('44')}} </div>
            <div class=" marginblack" [ngStyle]="{'width.px': 60*arrColumnas.length}"> 
                <div class="style2aBold calibriFont" style="text-align: center;">{{fnObtenerLabel('5013')}}</div>  
                <div class="grid-container" style="margin-left: -2px;">
                    <div class=" marginLeft style4 calibriFont" style="width: 60px; text-align: center;" *ngFor="let jsnTeam of arrColumnas; let i = index;" [ngClass]="{'borderRight': i === arrColumnas.length - 1}" [ngClass]="{'borderRight': i === arrColumnas.length - 1}"> {{i+1}} </div>   
                </div>  
            </div>
        </div>
    </div>
<!-- fin titulo-->

<!---------------------------------------------------------------INICIO 1------------------------------------------->
    <div class="row" class="marginwhite2">
        <div class="grid-container">
            <div class="marginRigth marginLeft style2b calibriFont textoazul verticalAligmentDiv" style="height: 153px; z-index: 2;">
                <p class="conDesbordado style2b calibriFont" style="margin: 0px;">{{fnObtenerLabel('3019')}}</p></div>
            <div class="textoblanco style3 calibriFont colorFontBlue2 verticalAligmentDiv">{{fnObtenerLabel('46')}}</div>
            <div class="abajo textoInput" *ngFor="let jsnTeam of arrColumnas; let i = index;" [ngClass]="{'borderRight': i === arrColumnas.length - 1}">
                
            </div>  
        </div>
    </div>
<!-- fin 1.1-->

<div class="row" class="marginwhite2">
    <div class="grid-container">
        <div class="marginRigth marginLeft colorblue textoazul"> </div>
        <div class="textoblanco style2a calibriFont verticalAligmentDiv">{{fnObtenerLabel('47')}}</div>
        <div class="margin4 textoInput style5b calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;" [ngClass]="{'borderRight': i === arrColumnas.length - 1}">
            <input [ngClass]="{'notSave': isInCurrentStatus}" (change)="fnChangeInput(jsnTeam['cash'], 'cash', i)"
             [ngStyle]="{'color': cash != jsnTeam['cash'] && intentos >= 3 ? 'red':'black',
            'border': cash != jsnTeam['cash'] && intentos >= 3 && jsnTeam['cash'] != null ? '2px solid #ff0000':'' }" *ngIf="i == mes && canEdit" type="number" id="input10" matInput class="w" [(ngModel)]="jsnTeam['cash']" (keyup.enter)="validateKey('input20')" onkeypress="return event.charCode >= 48" min="0" (keypress)="validateInputNumber($event)">
            <div *ngIf="i != mes || !canEdit">
                {{jsnTeam['cash']}}
            </div>
        </div> 
    </div>
</div>
<!-- fin 1.2-->

<div class="row" class="marginwhite2">
    <div class="grid-container">
        <div class="marginRigth marginLeft colorblue textoazul"> </div>
        <div class="textoblanco style2a calibriFont verticalAligmentDiv">{{fnObtenerLabel('48')}}</div>
        <div class="margin4 textoInput style5b calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;" [ngClass]="{'borderRight': i === arrColumnas.length - 1}">
            <input [ngClass]="{'notSave': isInCurrentStatus}" (change)="fnChangeInput(jsnTeam['receivables'], 'receivables', i)"
             [ngStyle]="{'color': receivables != jsnTeam['receivables'] && intentos >= 3 ? 'red':'black',
            'border': receivables != jsnTeam['receivables'] && intentos >= 3 && jsnTeam['receivables'] != null? '2px solid #ff0000':'' }"  *ngIf="i == mes && canEdit" type="number" id="input20" matInput class="w" [(ngModel)]="jsnTeam['receivables']" (keyup.enter)="validateKey('input30')" onkeypress="return event.charCode >= 48" min="0" (keypress)="validateInputNumber($event)">
            <div *ngIf="i != mes || !canEdit">
                {{jsnTeam['receivables']}}
            </div>
        </div> 
    </div>
</div>
<!-- fin 1.3-->


<div class="row" class="marginwhite2">
    <div class="grid-container">
        <div class="marginRigth marginLeft colorblue textoazul"> </div>
        <div class="textoblanco style2a calibriFont verticalAligmentDiv">{{fnObtenerLabel('52')}}</div>
        <div class="margin4 textoInput style5b calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;" [ngClass]="{'borderRight': i === arrColumnas.length - 1}">
            <input [ngClass]="{'notSave': isInCurrentStatus}" (change)="fnChangeInput(jsnTeam['finishGood'], 'finishGood', i)"
            [ngStyle]="{'color': finishGood != jsnTeam['finishGood'] && intentos >= 3 ? 'red':'black',
            'border': finishGood != jsnTeam['finishGood'] && intentos >= 3 && jsnTeam['finishGood'] != null? '2px solid #ff0000':'' }" *ngIf="i == mes && canEdit" type="number" id="input30" matInput class="w" [(ngModel)]="jsnTeam['finishGood']" (keyup.enter)="validateKey('input40')" onkeypress="return event.charCode >= 48" min="0" (keypress)="validateInputNumber($event)"> 
            <div *ngIf="i != mes || !canEdit">
                {{jsnTeam['finishGood']}}
            </div>
        </div> 
    </div>
</div>
<!-- fin 1.4-->

<div class="row" class="marginwhite2">
    <div class="grid-container">
        <div class="marginRigth marginLeft colorblue textoazul"> </div>
        <div class="textoblanco style2a calibriFont verticalAligmentDiv">{{fnObtenerLabel('53')}}</div>
        <div class="margin4 textoInput style5b calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;" [ngClass]="{'borderRight': i === arrColumnas.length - 1}">
            <input [ngClass]="{'notSave': isInCurrentStatus}" (change)="fnChangeInput(jsnTeam['workProcess'], 'workProcess', i)"
            [ngStyle]="{'color': workProcess != jsnTeam['workProcess'] && intentos >= 3 ? 'red':'black',
            'border': workProcess != jsnTeam['workProcess'] && intentos >= 3 && jsnTeam['workProcess'] != null ? '2px solid #ff0000':'' }"  *ngIf="i == mes && canEdit" type="number" id="input40" matInput class="w" [(ngModel)]="jsnTeam['workProcess']" (keyup.enter)="validateKey('input50')" onkeypress="return event.charCode >= 48" min="0" (keypress)="validateInputNumber($event)">
            <div *ngIf="i != mes || !canEdit">
                {{jsnTeam['workProcess']}}
            </div>
        </div> 
    </div>
</div>
<!-- fin 1.5-->


<div class="row" class="marginwhite2">
    <div class="grid-container">
        <div class="marginRigth marginLeft colorblue textoazul"> </div>
        <div class="textoblanco style2a calibriFont verticalAligmentDiv">{{fnObtenerLabel('57')}}</div>
        <div class="margin4 textoInput style5b calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;" [ngClass]="{'borderRight': i === arrColumnas.length - 1}">
            <input [ngClass]="{'notSave': isInCurrentStatus}" (change)="fnChangeInput(jsnTeam['rawMaterials'], 'rawMaterials', i)"
             [ngStyle]="{'color': rawMaterials != jsnTeam['rawMaterials'] && intentos >= 3 ? 'red':'black',
            'border': rawMaterials != jsnTeam['rawMaterials'] && intentos >= 3 && jsnTeam['rawMaterials'] != null ? '2px solid #ff0000':'' }"  *ngIf="i == mes && canEdit" type="number" id="input50" matInput class="w" [(ngModel)]="jsnTeam['rawMaterials']" (keyup.enter)="validateKey('input60')" onkeypress="return event.charCode >= 48" min="0" (keypress)="validateInputNumber($event)">
            <div *ngIf="i != mes || !canEdit">
                {{jsnTeam['rawMaterials']}}
            </div>
        </div> 
    </div>
</div>
<!-- fin 1.6-->

<div class="row" class="marginwhite2">
    <div class="grid-container">
        <div class="marginRigth marginLeft colorblue textoazul"> </div>
        <div class="textoblanco textRigth verticalAligmentDiv" style="display: inline-flex;">
            <div>
                <strong class="calibriFont" style="width: 90%;"> {{fnObtenerLabel('59')}} </strong>
                <span class="equal" (click)="fnResultado(1)" style="text-align: right; height: 1px; font-size: 14px; cursor: pointer; width: 10%;">&#61;</span>
            </div>
        </div>
        <div class="margin4 textoInput style6 calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;" [ngClass]="{'borderRight': i === arrColumnas.length - 1}" style="background-color: #ececf4">
            <input *ngIf="i == mes && canEdit" type="number" id="input60" matInput class="w" [(ngModel)]="jsnTeam['totalCurrent1']" style="background-color: #ececf4; border-color: #ececf4;" (keyup.enter)="validateKey('input70')" onkeypress="return event.charCode >= 48" min="0" (keypress)="validateInputNumber($event)">
            <div *ngIf="i != mes || !canEdit">
                {{jsnTeam['totalCurrent1']}}
            </div>
        </div> 
    </div>
</div>
<!-- fin 1.7-->
<!--------------------------------------------------------------- fin 1--------------------------------------------->

<!---------------------------------------------------------------INICIO 2------------------------------------------->
<div class="row" class="marginwhite2">
    <div class="grid-container">
        <div class="marginRigth marginLeft colorblue textoazul verticalAligmentDiv"  style="border-top: 1px solid #099cfd; height: 88px; z-index: 2;"><p class="conDesbordado style2b calibriFont " style="margin: 0px;">{{fnObtenerLabel('3020')}}</p></div>
        <div class="textoblanco style3 calibriFont colorFontBlue2 verticalAligmentDiv"  style="border-top: 1px solid #099cfd;">{{fnObtenerLabel('60')}}</div>
        <div class="abajo textoInput" *ngFor="let jsnTeam of arrColumnas; let i = index;" [ngClass]="{'borderRight': i === arrColumnas.length - 1}"  style="border-top: 1px solid #099cfd;">
            
        </div>  
    </div>
</div>
<!-- fin 2.1-->

<div class="row" class="marginwhite2">
<div class="grid-container">
    <div class="marginRigth marginLeft colorblue textoazul"> </div>
    <div class="textoblanco style2a calibriFont verticalAligmentDiv">{{fnObtenerLabel('23.1')}}</div>
    <div class="margin4 textoInput style5b calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;" [ngClass]="{'borderRight': i === arrColumnas.length - 1}">
        <input [ngClass]="{'notSave': isInCurrentStatus}" (change)="fnChangeInput(jsnTeam['machine'], 'machine', i)"
         [ngStyle]="{'color': machine != jsnTeam['machine'] && intentos >= 3 ? 'red':'black',
        'border': machine != jsnTeam['machine'] && intentos >= 3 && jsnTeam['machine'] != null ? '2px solid #ff0000':'' }" *ngIf="i == mes && canEdit" type="number" id="input70" matInput class="w" [(ngModel)]="jsnTeam['machine']" (keyup.enter)="validateKey('input80')" onkeypress="return event.charCode >= 48" min="0" (keypress)="validateInputNumber($event)">
        <div *ngIf="i != mes || !canEdit">
            {{jsnTeam['machine']}}
        </div>
    </div> 
</div>
</div>
<!-- fin 2.2-->

<div class="row" class="marginwhite2">
    <div class="grid-container">
        <div class="marginRigth marginLeft colorblue textoazul"> </div>
        <div class="textoblanco style2a calibriFont verticalAligmentDiv">{{fnObtenerLabel('61')}}</div>
        <div class="margin4 textoInput style5b calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;" [ngClass]="{'borderRight': i === arrColumnas.length - 1}">
            <input [ngClass]="{'notSave': isInCurrentStatus}" (change)="fnChangeInput(jsnTeam['land'], 'land', i)"
             [ngStyle]="{'color': landBuildings != jsnTeam['land'] && intentos >= 3 ? 'red':'black',
            'border': landBuildings != jsnTeam['land'] && intentos >= 3  && jsnTeam['land'] != null ? '2px solid #ff0000':'' }" *ngIf="i == mes && canEdit" type="number" id="input80" matInput class="w" [(ngModel)]="jsnTeam['land']" (keyup.enter)="validateKey('input90')" onkeypress="return event.charCode >= 48" min="0" (keypress)="validateInputNumber($event)">
            <div *ngIf="i != mes || !canEdit">
                {{jsnTeam['land']}}
            </div>
        </div> 
    </div>
    </div>
<!-- fin 2.3-->

<div class="row" class="marginwhite2">
<div class="grid-container">
    <div class="marginRigth marginLeft colorblue textoazul"> </div>
    <div class="textoblanco textRigth verticalAligmentDiv" style="display: inline-flex;">
        <div>
            <strong class=" calibriFont" style="width: 90%;"> {{fnObtenerLabel('64')}} </strong>
            <span class="equal" (click)="fnResultado(2)" style="text-align: right; height: 1px; font-size: 14px; cursor: pointer; width: 10%;">&#61;</span>
        </div>
    </div>
    <div class="margin4 textoInput style6 calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;" [ngClass]="{'borderRight': i === arrColumnas.length - 1}" style="background-color: #ececf4">
        <input *ngIf="i == mes && canEdit" type="number" id="input90" matInput class="w" [(ngModel)]="jsnTeam['totalFixed']" style="background-color: #ececf4; border-color: #ececf4;" (keyup.enter)="validateKey('input100')" onkeypress="return event.charCode >= 48" min="0" (keypress)="validateInputNumber($event)">
        <div *ngIf="i != mes || !canEdit">
            {{jsnTeam['totalFixed']}}
        </div>
    </div> 
</div>
</div>
<!-- fin 2.4-->
<!--------------------------------------------------------------- fin 2--------------------------------------------->

<!---------------------------------------------------------------INICIO 3------------------------------------------->
<div>
    <div class="grid-container">
        <div class="marginRigth marginLeft colorblue textoazul" style="padding-left: 7px; border-top: 1px solid #099cfd;"><p class="style2b calibriFont" style="margin: 0px;">{{getSingleTextLabel('3034')}}</p></div>
        <div class="textoblanco textRigth verticalAligmentDiv"  style="border-top: 1px solid #099cfd; display: inline-flex;">
            <div>
                <strong class=" calibriFont" style="width: 90%;"> {{fnObtenerLabel('65')}} </strong>
                <span class="equal" (click)="fnResultado(3)" style="text-align: right; height: 1px; font-size: 14px; cursor: pointer; width: 10%;">&#61;</span>
            </div>
        </div>
        <div class="margin4 textoInput style7b calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;" [ngClass]="{'borderRight': i === arrColumnas.length - 1}" style="background-color: #c3d8fe; border-top: 1px solid #099cfd !important">
            <input *ngIf="i == mes && canEdit" type="number" id="input100" matInput class="wblue" [(ngModel)]="jsnTeam['totalCurrent2']" style="border-top: 1px solid #099cfd !important" (keyup.enter)="validateKey('input110')" onkeypress="return event.charCode >= 48" min="0" (keypress)="validateInputNumber($event)">
            <div *ngIf="i != mes || !canEdit">
                {{jsnTeam['totalCurrent2']}}
            </div>
        </div>   
    </div>
</div>
<!--------------------------------------------------------------- fin 3--------------------------------------------->


<!---------------------------------------------------------------INICIO 4------------------------------------------->
<div class="row" class="marginwhite2">
    <div class="grid-container">
        <div class="marginRigth marginLeft colorblue textoazul verticalAligmentDiv"  style="border-top: 1px solid #099cfd; height: 109px; z-index: 2;"><p class="conDesbordado style2b calibriFont" style="margin: 0px;">{{fnObtenerLabel('3021')}}</p></div>
        <div class="textoblanco style3 calibriFont colorFontBlue2 verticalAligmentDiv"  style="border-top: 1px solid #099cfd;">{{fnObtenerLabel('66')}}</div>
        <div class="abajo textoInput" *ngFor="let jsnTeam of arrColumnas; let i = index;" [ngClass]="{'borderRight': i === arrColumnas.length - 1}"  style="border-top: 1px solid #099cfd;">
            
        </div>  
    </div>
</div>
<!-- fin 4.1-->

<div class="row" class="marginwhite2">
<div class="grid-container">
    <div class="marginRigth marginLeft colorblue textoazul"> </div>
    <div class="textoblanco style2a calibriFont verticalAligmentDiv">{{fnObtenerLabel('67')}}</div>
    <div class="margin4 textoInput style5b calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;" [ngClass]="{'borderRight': i === arrColumnas.length - 1}">
        <input [ngClass]="{'notSave': isInCurrentStatus}" (change)="fnChangeInput(jsnTeam['payables'], 'payables', i)"
         [ngStyle]="{'color': payables != jsnTeam['payables'] && intentos >= 3 ? 'red':'black',
        'border': payables != jsnTeam['payables'] && intentos >= 3 && jsnTeam['payables'] != null ? '2px solid #ff0000':'' }" *ngIf="i == mes && canEdit" type="number" id="input110" matInput class="w" [(ngModel)]="jsnTeam['payables']" (keyup.enter)="validateKey('input120')" onkeypress="return event.charCode >= 48" min="0" (keypress)="validateInputNumber($event)">
        <div *ngIf="i != mes || !canEdit">
            {{jsnTeam['payables']}}
        </div>
    </div> 
</div>
</div>
<!-- fin 4.2-->

<div class="row" class="marginwhite2">
    <div class="grid-container">
        <div class="marginRigth marginLeft colorblue textoazul"> </div>
        <div class="textoblanco style2a calibriFont verticalAligmentDiv">{{fnObtenerLabel('69')}}</div>
        <div class="margin4 textoInput style5b calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;" [ngClass]="{'borderRight': i === arrColumnas.length - 1}">
            <input [ngClass]="{'notSave': isInCurrentStatus}" (change)="fnChangeInput(jsnTeam['loans'], 'loans', i)"
             [ngStyle]="{'color': loans != jsnTeam['loans'] && intentos >= 3 ? 'red':'black',
            'border': loans != jsnTeam['loans'] && intentos >= 3 && jsnTeam['loans'] != null? '2px solid #ff0000':'' }" *ngIf="i == mes && canEdit" type="number" id="input120" matInput class="w" [(ngModel)]="jsnTeam['loans']" (keyup.enter)="validateKey('input130')" onkeypress="return event.charCode >= 48" min="0" (keypress)="validateInputNumber($event)">
            <div *ngIf="i != mes || !canEdit">
                {{jsnTeam['loans']}}
            </div>
        </div> 
    </div>
    </div>
<!-- fin 4.3-->

<div class="row" class="marginwhite2">
    <div class="grid-container">
        <div class="marginRigth marginLeft colorblue textoazul"> </div>
        <div class="textoblanco style2a calibriFont verticalAligmentDiv">{{fnObtenerLabel('78')}}</div>
        <div class="margin4 textoInput style5b calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;" [ngClass]="{'borderRight': i === arrColumnas.length - 1}">
            <input [ngClass]="{'notSave': isInCurrentStatus}" (change)="fnChangeInput(jsnTeam['taxes'], 'taxes', i)"
             [ngStyle]="{'color': taxes != jsnTeam['taxes'] && intentos >= 3 ? 'red':'black',
            'border': taxes != jsnTeam['taxes'] && intentos >= 3 && jsnTeam['taxes'] != null ? '2px solid #ff0000':'' }" *ngIf="i == mes && canEdit" type="number" id="input130" matInput class="w" [(ngModel)]="jsnTeam['taxes']" (keyup.enter)="validateKey('input140')" onkeypress="return event.charCode >= 48" min="0" (keypress)="validateInputNumber($event)">
            <div *ngIf="i != mes || !canEdit">
                {{jsnTeam['taxes']}}
            </div>
        </div> 
    </div>
    </div>
<!-- fin 4.4-->

<div class="row" class="marginwhite2">
<div class="grid-container">
    <div class="marginRigth marginLeft colorblue textoazul"> </div>
    <div class="textoblanco textRigth verticalAligmentDiv" style="display: inline-flex;"> 
        <div>
            <strong class=" calibriFont" style="width: 90%;"> {{fnObtenerLabel('71')}} </strong>
            <span class="equal" (click)="fnResultado(4)" style="text-align: right; height: 1px; font-size: 14px; cursor: pointer; width: 10%;">&#61;</span>
        </div>
    </div>
    <div class="margin4 textoInput style6 calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;" [ngClass]="{'borderRight': i === arrColumnas.length - 1}" style="background-color: #ececf4">
        <input *ngIf="i == mes && canEdit" type="number" id="input140" matInput class="w" [(ngModel)]="jsnTeam['totalLiabilities']" style="background-color: #ececf4; border-color: #ececf4;" (keyup.enter)="validateKey('input150')" onkeypress="return event.charCode >= 48" min="0" (keypress)="validateInputNumber($event)">
        <div *ngIf="i != mes || !canEdit">
            {{jsnTeam['totalLiabilities']}}
        </div>
    </div> 
</div>
</div>
<!-- fin 4.5-->
<!--------------------------------------------------------------- fin 4--------------------------------------------->

<!---------------------------------------------------------------INICIO 5------------------------------------------->
<div class="row" class="marginwhite2">
    <div class="grid-container">
        <div class="marginRigth marginLeft colorblue textoazul verticalAligmentDiv"  style="border-top: 1px solid #099cfd; height: 86px; z-index: 2;"><p class="conDesbordado style2b calibriFont" style="margin-top: 0px; margin-bottom: 0px;">{{fnObtenerLabel('3022')}}</p></div>
        <div class="textoblanco style3 calibriFont colorFontBlue2 verticalAligmentDiv"  style="border-top: 1px solid #099cfd;">{{fnObtenerLabel('72')}}</div>
        <div class="abajo textoInput" *ngFor="let jsnTeam of arrColumnas; let i = index;" [ngClass]="{'borderRight': i === arrColumnas.length - 1}" style="border-top: 1px solid #099cfd;">
            
        </div>  
    </div>
</div>
<!-- fin 5.1-->

<div class="row" class="marginwhite2">
<div class="grid-container">
    <div class="marginRigth marginLeft colorblue textoazul"> </div>
    <div class="textoblanco style2a calibriFont verticalAligmentDiv">{{fnObtenerLabel('73')}}</div>
    <div class="margin4 textoInput style5b calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;" [ngClass]="{'borderRight': i === arrColumnas.length - 1}">
        <input [ngClass]="{'notSave': isInCurrentStatus}" (change)="fnChangeInput(jsnTeam['capital'], 'capital', i)"
         [ngStyle]="{'color': capitalStock != jsnTeam['capital'] && intentos >= 3 ? 'red':'black',
        'border': capitalStock != jsnTeam['capital'] && intentos >= 3 && jsnTeam['capital'] != null? '2px solid #ff0000':'' }" *ngIf="i == mes && canEdit" type="number" id="input150" matInput class="w" [(ngModel)]="jsnTeam['capital']" (keyup.enter)="validateKey('input160')" onkeypress="return event.charCode >= 48" min="0" (keypress)="validateInputNumber($event)">
        <div *ngIf="i != mes || !canEdit">
            {{jsnTeam['capital']}}
        </div>
    </div> 
</div>
</div>
<!-- fin 5.2-->


<div class="row" class="marginwhite2">
    <div class="grid-container">
        <div class="marginRigth marginLeft colorblue textoazul"> </div>
        <div class="textoblanco style2a calibriFont verticalAligmentDiv">{{fnObtenerLabel('74')}}</div>
        <div class="margin4 textoInput style5b calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;" [ngClass]="{'borderRight': i === arrColumnas.length - 1}">
            <input [ngClass]="{'notSave': isInCurrentStatus}" (change)="fnChangeInput(jsnTeam['retained'], 'retained', i)"
            [ngStyle]="{'color': retained != jsnTeam['retained'] && intentos >= 3 ? 'red':'black',
            'border': retained != jsnTeam['retained'] && intentos >= 3 && jsnTeam['retained'] != null? '2px solid #ff0000':'' }" *ngIf="i == mes && canEdit" type="number" id="input160" matInput class="w" [(ngModel)]="jsnTeam['retained']" (keyup.enter)="validateKey('input170')" (keypress)="validateInputNumber($event)">
            <div *ngIf="i != mes || !canEdit">
                {{jsnTeam['retained']}}
            </div>
        </div> 
    </div>
    </div>
<!-- fin 5.3-->

<div class="row" class="marginwhite2">
<div class="grid-container">
    <div class="marginRigth marginLeft colorblue textoazul"> </div>
    <div class="textoblanco textRigth verticalAligmentDiv" style="display: inline-flex;"> 
        <div>
            <strong class="calibriFont" style="width: 90%;"> {{fnObtenerLabel('75')}} </strong>
            <span class="equal" (click)="fnResultado(5)" style="text-align: right; height: 1px; font-size: 14px; cursor: pointer; width: 10%;">&#61;</span>
        </div>
    </div>
    <div class="margin4 textoInput style6 calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;" [ngClass]="{'borderRight': i === arrColumnas.length - 1}"  style="background-color: #ececf4; ">
        <input *ngIf="i == mes && canEdit" type="number" id="input170" matInput class="w" [(ngModel)]="jsnTeam['totalShareholder']"  style="background-color: #ececf4; border-color: #ececf4;" (keyup.enter)="validateKey('input180')" onkeypress="return event.charCode >= 48" min="0" (keypress)="validateInputNumber($event)">
        <div *ngIf="i != mes || !canEdit">
            {{jsnTeam['totalShareholder']}}
        </div>
    </div> 
</div>
</div>
<!-- fin 5.4-->
<!--------------------------------------------------------------- fin 5--------------------------------------------->

<!---------------------------------------------------------------INICIO 6------------------------------------------->
    <div class="row" class="marginwhite3">
        <div class="grid-container">
            <div class="marginRigth marginLeft borderBottom colorblue textoazul style2b calibriFont" style="padding-left: 7px; border-top: 1px solid #099cfd;">{{fnObtenerLabel('3023')}}</div>
            <div class="textoblanco style2a borderBottom calibriFont verticalAligmentDiv"  style="border-top: 1px solid #099cfd; display: inline-flex; text-align: right;">
                <div>
                    <strong class="calibriFont" style="width: 90%; font-size: 13px !important;"> {{fnObtenerLabel('3024')}} </strong>
                    <span class="equal" (click)="fnResultado(6)" style="text-align: right; height: 1px; font-size: 14px; cursor: pointer; width: 10%;">&#61;</span>
                </div>
            </div>
            <div class="margin4 textoInput borderBottom style7b calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;" [ngClass]="{'borderRight': i === arrColumnas.length - 1}" style="background-color: #c3d8fe; border-top: 1px solid #099cfd;">
                <input *ngIf="i == mes && canEdit" type="number" id="input180" matInput class="wblue2" [(ngModel)]="jsnTeam['totalLiabilitiesAndEquity']" style="border-top: 1px solid #099cfd;" onkeypress="return event.charCode >= 48" min="0" (keypress)="validateInputNumber($event)">
                <div *ngIf="i != mes || !canEdit">
                    {{jsnTeam['totalLiabilitiesAndEquity']}}
                </div>
            </div>   
        </div>
    </div>
<!--------------------------------------------------------------- fin 6--------------------------------------------->



</div>
<div mat-dialog-actions style="text-align: center; display: block !important">
  <button *ngIf="canEdit" class="btn btn-primary mx-3 styleButtonOK calibriFont" type="submit" (click)="fnSave()">&nbsp;&nbsp;&nbsp;&nbsp;{{getSingleTextLabel('136')}}&nbsp;&nbsp;&nbsp;&nbsp;</button>
  <button class="btn btn-secondary mx-3 style9 calibriFont" (click)="fnCanel()" [mat-dialog-close]>&nbsp;{{getSingleTextLabel('123')}}&nbsp;</button>
</div>
  
</div>

<ngx-spinner bdColor="rgba(0,0,0,0.75)" size="large" color="#ffffff" type="ball-clip-rotate-multiple" [fullScreen]="true">
      <p style="color: white"> Loading... </p>
</ngx-spinner>
