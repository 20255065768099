import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { _URL_REPORTS } from 'src/app/config/config';
import { SocketioService } from '../socketio.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  private $report: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  _report: Observable<any> = this.$report.asObservable();
  private $ratios: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  _ratios: Observable<any> = this.$ratios.asObservable();
  socket = this.socketioService.socket;
  isMyCall : boolean;
  constructor(private http: HttpClient, private socketioService: SocketioService) { }

  setupSocketConnection() {
    this.socket.on('updateTxtAreas', (data) => {
      this.$report.next({ data, status: 'updateTxtAreas' });
    });
    this.socket.on('updateSelected', (data) => {
      this.$report.next({ data, status: 'updateSelected' });
    });
    this.socket.on('updateLightsServer', (data) => {
      console.log(data);
      this.$report.next({ data, status: 'updateLightsServer' });
    });
    this.socket.on('updateLightRadiosServer', (data) => {
      console.log(data);
      if(this.isMyCall){
        console.log("mi call")
        this.isMyCall = !this.isMyCall
        return
      }else{
        this.$ratios.next({ data, status: 'updateLightRadiosServer' });

      }
    });
    
  }
  closeListener(){
    this.socket.off("updateTxtAreas", () => {});
    this.socket.off("updateTxtAreas");
    
    this.socket.off("updateSelected", () => {});
    this.socket.off("updateSelected");

    this.socket.off("updateLightsServer", () => {});
    this.socket.off("updateLightsServer");

    this.socket.off("updateLightRadiosServer", () => {});
    this.socket.off("updateLightRadiosServer");
  }
  getQuantitiesRatioView(workshopId, month): Observable<any> {
    return this.http.get(_URL_REPORTS + `/getQuantitiesRatioView/${workshopId}/${month}`);
  }

  getTextAreaByWorkshopIdAndType(workshopId: number, type: number){
    return this.http.get(_URL_REPORTS + `/getTextAreasReportsByWorkshopIdAndType/${workshopId}/${type}`);
  }

  createUpdTextArea(workShopId: number, lstTextArea: Array<any>): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({ workshopId: workShopId, lstTextArea: lstTextArea});
    return this.http.post(_URL_REPORTS + '/createUpdateTextAreaReports', params, {
      headers: httpHeaders,
    });
  }
  createUpdSelect(workShopId: number, lstTextArea: Array<any>): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({ workshopId: workShopId, lstTextArea: lstTextArea});
    return this.http.post(_URL_REPORTS + '/createUpdateSelectReports', params, {
      headers: httpHeaders,
    });
  }

  updateLights(room, idElement, color){
    const key = 'updateLights';
    this.socket.emit(key, {
      idElement, 
      color,
      room
    });
  }
  updateLightRatios(room, idElement, color){
    const key = 'updateLightRatios';
    this.socket.emit(key, {
      idElement, color,
      room
    });
    this.isMyCall = true;
  }
}
