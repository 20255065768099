import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { BoardService } from 'src/app/services/board/board.service';
import { TeamService } from 'src/app/services/team/team.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { WorkshopService } from 'src/app/services/workshop/workshop.service';
import Swal from 'sweetalert2';

class TeamMember{
  Cycles: number;
  Email: string;
  FirstName: string;
  IsFacilitator: boolean;
  LastName: string;
  TeamId: number;
  UserID: number;
  completedname: string;
  invalid: number;
  type: number;

  decode(json:{
    Cycles: number,
    Email: string,
    FirstName: string,
    IsFacilitator: boolean,
    LastName: string,
    TeamId: number,
    UserID: number,
    completedname: string,
    invalid: number,
    type: number,}
  ){
      this.Cycles = json.Cycles;
      this.Email = json.Email;
      this.FirstName = json.FirstName;
      this.IsFacilitator = json.IsFacilitator;
      this.LastName = json.LastName;
      this.TeamId = json.TeamId;
      this.UserID = json.UserID;
      this.completedname = json.completedname;
      this.invalid = json.invalid;
      this.type = json.type;
    }
}
@Component({
  selector: 'app-dialog-edit-users',
  templateUrl: './dialog-edit-users.component.html',
  styleUrls: ['./dialog-edit-users.component.scss']
})


export class DialogEditUsersComponent implements OnInit {

  @Input() workshopId: number;
  @Input() lstGlobalTeamMemebers: Array<TeamMember> = [];
  lstOfTeams: Array<{TeamId: number, Avatar:string, TeamMembers: Array<TeamMember>}> = [];

  indexTeam = -1;
  indexTeamMember = -1;
  
  teamMemberForm: FormGroup = new FormGroup({
    FirstName: new FormControl('', [Validators.required]),
    LastName: new FormControl('', [Validators.required]),
    Email: new FormControl('', [Validators.required, Validators.email]),
    // JobTitle: new FormControl('', []),
  });

  


  constructor(
    //@Inject(MAT_DIALOG_DATA) private data: {workshopId: number, lstUsers: Array<TeamMember>},
    private spinner: NgxSpinnerService,
    private teamServise: TeamService,
    private userService: UsuarioService,
    private boardService: BoardService,
    private workshopService: WorkshopService,
    @Inject(MAT_DIALOG_DATA) private _data: any,

  ) { 
    //this.workshopId = this.data.workshopId;
    //this.lstGlobalTeamMemebers = [...this.data.lstUsers];
  }

  ngOnInit(): void {
    this.cargarTeams();
  }
  getSingleTextLabel(labelNumber: string): string {
    const tmp = this._data.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    return tmp ? tmp.Label : '';
  }


  async cargarTeams(){
    this.spinner.show();
    this.workshopService.getExistsTeamMeambersBoard(this.workshopId).subscribe(
      async (resp) => {
        if(resp.intResponse == 200){
          this.lstGlobalTeamMemebers = resp["data"];
          const response = await this.teamServise.getAllTeamsAndMembersById(this.workshopId).toPromise();
          console.log(response)
          response.teams.forEach(element => {
            this.lstOfTeams.push({
              TeamId: element.team.TeamId,
              TeamMembers: [],
              Avatar: element.team.Avatar
            });
          });
          this.cargarTeamMembers();
        }
      
      })
    
  }

  cargarTeamMembers(){
    let indexTeam = 0;
    for(let i = 0; i < this.lstGlobalTeamMemebers.length; i++){
      indexTeam = this.lstOfTeams.map((e) => {return e.TeamId}).indexOf(this.lstGlobalTeamMemebers[i].TeamId);
      if(indexTeam == -1){
        console.log("aqui", this.lstOfTeams);
        if(this.lstGlobalTeamMemebers[i].TeamId == 1){
          this.lstOfTeams.push({
            TeamId: this.lstGlobalTeamMemebers[i].TeamId,
            TeamMembers: [this.lstGlobalTeamMemebers[i]],
            Avatar: 'Links for Team Boards'
          });
        }else{
          this.lstOfTeams.push({
            TeamId: this.lstGlobalTeamMemebers[i].TeamId,
            TeamMembers: [this.lstGlobalTeamMemebers[i]],
            Avatar: 'Team Facilitator'
          });
        }
        
      } else {
        this.lstOfTeams[indexTeam].TeamMembers.push(this.lstGlobalTeamMemebers[i]);
      }
    }
    this.spinner.hide();
    console.log(this.lstOfTeams);
  }

  cargarForm(teamMember: TeamMember, indexTeam, indexTeamMember){
    console.log("editando",teamMember);
    this.indexTeam = indexTeam; this.indexTeamMember = indexTeamMember;
    this.teamMemberForm.setValue({
      FirstName: teamMember.FirstName,
      LastName: teamMember.LastName,
      Email: teamMember.Email
    });
    console.log(this.teamMemberForm.value);
  }

  editTeamMember(){
    this.spinner.show();
    const auxTeamMember = this.lstOfTeams[this.indexTeam].TeamMembers[this.indexTeamMember];
    this.lstOfTeams[this.indexTeam].TeamMembers[this.indexTeamMember].Email = this.teamMemberForm.controls.Email.value;
    this.lstOfTeams[this.indexTeam].TeamMembers[this.indexTeamMember].FirstName = this.teamMemberForm.controls.FirstName.value;
    this.lstOfTeams[this.indexTeam].TeamMembers[this.indexTeamMember].LastName = this.teamMemberForm.controls.LastName.value;
    this.updateTeamMemberBD(this.lstOfTeams[this.indexTeam].TeamMembers[this.indexTeamMember], auxTeamMember);
    document.getElementById('firstName').focus();
  } 

  updateTeamMemberBD(teamMemberNew: TeamMember, teamMemberOld: TeamMember){
    return new Promise((resolve, reject) => {
      this.userService.updateTeamMember(teamMemberNew).subscribe(response => {
        this.spinner.hide();
        if(response.intResponse == 200){
          this.teamMemberForm.reset();
          if(teamMemberNew.TeamId == teamMemberOld.TeamId){
            Swal.fire({
              text: '',
              imageUrl: '../../../../assets/iconSuccessfully.svg',
              imageAlt: 'icon successfully',
              confirmButtonColor: '#6FB45C',
              html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> 98.- Saved data successfully </label>`,
            });
          }
          resolve(true);
        } else {
          Swal.fire({
            text: '',
            icon: 'error',
            html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">99.- Something wrong </label>`,
          });
          this.lstOfTeams[this.indexTeam].TeamMembers[this.indexTeamMember] = teamMemberOld;
          resolve(false);
        }
    })
    });
  }

  async dropTeamMemeber(event: CdkDragDrop<Array<TeamMember>>, team: any){
    console.log(event);
    this.spinner.show();
    if(event.previousContainer != event.container){
      if(event.previousContainer.data.length == 1){
        Swal.fire({
          html: `<style>swal2-confirm{border-radius: 50px !important; } swal2-styled{border-radius: 50px !important; }</style><label  style="font-size: 22px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> 105.- Workshop has alredy started! </label>`,
          imageUrl: '../../../../assets/iconPrevention.svg',
        });
        this.spinner.hide();
        return;
      }
      console.log(event.previousContainer.data[event.previousIndex]);
      var auxTeamMember = {};
      Object.assign(auxTeamMember, event.previousContainer.data[event.previousIndex]);
      event.previousContainer.data[event.previousIndex].TeamId = team.TeamId;
      const x = await this.updateTeamMemberBD(event.previousContainer.data[event.previousIndex], (auxTeamMember as TeamMember));
      if(x){
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );
      }
    } else {
      this.spinner.hide();
    }
  }
  modificarSesion(teamMember: TeamMember, indexTeam, indexTeamMember){
    console.log(teamMember, indexTeam, indexTeamMember)
    if(teamMember['logged'] == 0){
      teamMember['logged'] = 1;
      this.boardService.setSessionforUser(teamMember.UserID, 1, teamMember.TeamId).subscribe((response:any) =>{
            if(response.intResponse == 200){
              console.log("registro SESIONNNNNN ..........................")
            }else{
              console.log("NO pudo registrar SESIONNNNNN  ")
            }
          })
    }else{
      if(teamMember['logged'] == 1){
        teamMember['logged'] = 0;
        this.boardService.setSessionforUser(teamMember.UserID, 0, teamMember.TeamId).subscribe((response:any) =>{
              if(response.intResponse == 200){
                console.log("registro SESIONNNNNN ..........................")
              }else{
                console.log("NO pudo registrar SESIONNNNNN  ")
              }
            })
          }

    }
    
  }
  modificarSesionTeams(team){
    console.log(team);
    team.TeamMembers.forEach(element => {
      console.log(element['logged'])
      element['logged'] = 0;
    });
    this.boardService.setSessionforTeam(team['TeamId'],0).subscribe((response:any) => {
      if(response.intResponse == 200){
        console.log("registro SESIONNNNNN ..........................")
      }else{
        console.log("NO pudo registrar SESIONNNNNN  ")
      }
    })
  }
  onDragStarted(event: CdkDragDrop<Array<TeamMember>>, team: any){
    console.log(team);
    if(team.FirstName == 'facilitator' || team.IsFacilitator == true){
      Swal.fire({
        html: `<style>swal2-confirm{border-radius: 50px !important; } swal2-styled{border-radius: 50px !important; }</style><label  style="font-size: 22px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> You can't move this member </label>`,
        imageUrl: '../../../../assets/iconPrevention.svg',
      });
    }
    
  }
}
