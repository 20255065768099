export const improvements = [
    {
        id : 1,
        title : "Raw Materials Option",
        benefit : " Switch your machine over to using a cheaper " 
        + "source of Raw Materials; the Direct Cost for Raw "
        + "Materials drops from 3/Royal to 1/Royal.",
        benefitUrlImage:"RAW MATERIALS BENEFIT IMAGE.svg",
        cost:"There is a one-time changeover expense of 2 to recalibrate a "
        + "machine (this is paid as a machine overhead). This expense is additional "
        + "to the regular machine overhead.",
        costUrlImage:"RAW MATERIALS COST IMAGE.svg",
        color:"#50972f",
        selected: false,
        disabled: false,
        zoom: false,
        height1:40,
        width1:100,
        height2:95,
        width2:140,
        benefitLabelNumber: '8048',
        costLabelNumber: '8057',
        titleLabelNumber: '8062',
    },
    {
        id : 2,
        title : "Efficiency Option",
        benefit : "Upgrade your machine to increase the capacity to 6 "
        +"Royals/month. You will need to buy additional Raw "
        +"Materials (for cash or credit), but the cost of the 12 "
        +"workers on the machine will be divided between 6 "
        +"Royals and the labor/unit cost is reduced from 3/"
        +"Royal to 2/Royal.",
        benefitUrlImage:"EFFICIENCY BENEFIT IMAGE.svg",
        cost:"The cost to upgrade your machine is 10 (copper coins "
        +"are added to machine value).",
        costUrlImage:"10_4.svg",
        color:"#925d9c",
        selected: false,
        disabled: false,
        zoom: false,
        height1:86,
        width1:160,
        height2:40,
        width2:160,
        benefitLabelNumber: '8050',
        costLabelNumber: '8058',
        titleLabelNumber: '8063',
    },
    {
        id : 3,
        title : "Customizing Option",
        benefit : "If you install customer-specific equipment, you"
        + " can provide premium packaging, allowing you to"
        + " bid on 'C' orders at the market. These customers"
        + " will entertain bids only from companies with"
        + " existing customizing capability. The maximum"
        + " price rule still applies.",
        benefitUrlImage:"CUSTOMIZING OPTION BENEFIT OPTION.svg",
        cost:"Customizing equipment costs 10. There will be no"
        + " additional depreciation or machine overheads",
        costUrlImage:"10_4.svg",
        color:"#873f60",
        selected: false,
        disabled: false,
        zoom: false,
        height1:86,
        width1:120,
        height2:40,
        width2:160,
        benefitLabelNumber: '8052',
        costLabelNumber: '8059',
        titleLabelNumber: '8064',
    },
    {
        id : 4,
        title : "Quality Option",
        benefit : "If you offer a Quality Sales and Service program"
        + " your customers are willing to pay a premium price."
        + " The maximum price rule still applies, and the"
        + " orders are still awarded to the lowest-priced"
        + " bidder. However, if you have a Quality program and"
        + " win an order, the facilitator will increase your bid"
        + " price by 20% and pay you the higher amount",
        benefitUrlImage:"QUALITY OPTION BENEFIT IMAGEsvg.svg",
        cost:"You will need to recruit an additional person"
        + " to manage the program (you can decide which department this person will work in)"
        + " There is one-time cost for recruitment and training of 1; the"
        + " ongoing salary cost is 1/month.",
        costUrlImage:"QUALITY OPTION COST IMAGE.svg",
        color:"#6a86b4",
        selected: false,
        disabled: false,
        zoom: false,
        height1:45,
        width1:252,
        height2:90,
        width2:162,
        benefitLabelNumber: '8054',
        costLabelNumber: '8060',
        titleLabelNumber: '8065',
    },
    {
        id : 5,
        title : "Expansion Option",
        benefit : "Installing a second machine will double your"
        + " existing capacity to 8 Royals/month.",
        benefitUrlImage:"expansion1.svg",
        cost:"The new machine will cost 20 (you can pay cash or the supplier will provide credit)."
        + " It will incur depreciation and machine overhead like"
        + " the first machine. You will need to hire 12 new"
        + " workers (recruitment and training cost is 6/crew),"
        + "and pay their full wages of 12 each month. You"
        + " will also have to purchase extra Raw Materials (you can pay cash or the supplier will provide).",
        costUrlImage:"EXPANSION OPTION COST IMAGE 2.svg",
        color:"#3e277b",
        selected: false,
        disabled: false,
        zoom: false,
        height1:95,
        width1:330,
        height2:93,
        width2:190,
        benefitLabelNumber: '8056',
        costLabelNumber: '8061',
        titleLabelNumber: '8066',
    }/*Se comenta por desición de eliza,
    {
        id : 6,
        title : "No improvement option this month",
        benefit : "",
        benefitUrlImage:"",
        cost:"",
        costUrlImage:"",
        color:"#ffa826",
        selected: false,
        disabled: false,
        zoom: false,
        height1:0,
        width1:0,
        height2:0,
        width2:0,
        titleLabelNumber: '8067',
    }*/
]

export const improvementsSS = [
    {
        id : 1,
        title : "Raw Materials Option",
        benefit : " Switch your machine over to using a cheaper " 
        + "source of Raw Materials; the Direct Cost for Raw "
        + "Materials drops from 3/Royal to 1/Royal.",
        benefitUrlImage:"SVC_RAW MATERIALS BENEFIT.svg",
        cost:"There is a one-time changeover expense of 2 to recalibrate a "
        + "machine (this is paid as a machine overhead). This expense is additional "
        + "to the regular machine overhead.",
        costUrlImage:"SVC_RAW MATERIALS COST.svg",
        color:"#50972f",
        selected: false,
        disabled: false,
        zoom: false,
        height1:40,
        width1:100,
        height2:95,
        width2:140,
        benefitLabelNumber: '88048',
        costLabelNumber: '88057',
        titleLabelNumber: '88062',
    },
    {
        id : 2,
        title : "Efficiency Option",
        benefit : "Upgrade your machine to increase the capacity to 6 "
        +"Royals/month. You will need to buy additional Raw "
        +"Materials (for cash or credit), but the cost of the 12 "
        +"workers on the machine will be divided between 6 "
        +"Royals and the labor/unit cost is reduced from 3/"
        +"Royal to 2/Royal.",
        benefitUrlImage:"SVC_EFFICIENCY BENEFIT.svg",
        cost:"The cost to upgrade your machine is 10 (copper coins "
        +"are added to machine value).",
        costUrlImage:"10_4.svg",
        color:"#925d9c",
        selected: false,
        disabled: false,
        zoom: false,
        height1:86,
        width1:160,
        height2:40,
        width2:160,
        benefitLabelNumber: '88050',
        costLabelNumber: '88058',
        titleLabelNumber: '8063',
    },
    {
        id : 3,
        title : "Customizing Option",
        benefit : "If you install customer-specific equipment, you"
        + " can provide premium packaging, allowing you to"
        + " bid on 'C' orders at the market. These customers"
        + " will entertain bids only from companies with"
        + " existing customizing capability. The maximum"
        + " price rule still applies.",
        benefitUrlImage:"CUSTOMIZING OPTION BENEFIT OPTION.svg",
        cost:"Customizing equipment costs 10. There will be no"
        + " additional depreciation or machine overheads",
        costUrlImage:"10_4.svg",
        color:"#873f60",
        selected: false,
        disabled: false,
        zoom: false,
        height1:86,
        width1:120,
        height2:40,
        width2:160,
        benefitLabelNumber: '8052',
        costLabelNumber: '8059',
        titleLabelNumber: '8064',
    },
    {
        id : 4,
        title : "Quality Option",
        benefit : "If you offer a Quality Sales and Service program"
        + " your customers are willing to pay a premium price."
        + " The maximum price rule still applies, and the"
        + " orders are still awarded to the lowest-priced"
        + " bidder. However, if you have a Quality program and"
        + " win an order, the facilitator will increase your bid"
        + " price by 20% and pay you the higher amount",
        benefitUrlImage:"QUALITY OPTION BENEFIT IMAGEsvg.svg",
        cost:"You will need to recruit an additional person"
        + " to manage the program (you can decide which department this person will work in)"
        + " There is one-time cost for recruitment and training of 1; the"
        + " ongoing salary cost is 1/month.",
        costUrlImage:"QUALITY OPTION COST IMAGE.svg",
        color:"#6a86b4",
        selected: false,
        disabled: false,
        zoom: false,
        height1:45,
        width1:252,
        height2:90,
        width2:162,
        benefitLabelNumber: '8054',
        costLabelNumber: '8060',
        titleLabelNumber: '8065',
    },
    {
        id : 5,
        title : "Expansion Option",
        benefit : "Installing a second machine will double your"
        + " existing capacity to 8 Royals/month.",
        benefitUrlImage:"SVC_EXPANSION-BENEFIT.svg",
        cost:"The new machine will cost 20 (you can pay cash or the supplier will provide credit)."
        + " It will incur depreciation and machine overhead like"
        + " the first machine. You will need to hire 12 new"
        + " workers (recruitment and training cost is 6/crew),"
        + "and pay their full wages of 12 each month. You"
        + " will also have to purchase extra Raw Materials (you can pay cash or the supplier will provide).",
        costUrlImage:"SVC_EXPANSION OPTION COST.svg",
        color:"#3e277b",
        selected: false,
        disabled: false,
        zoom: false,
        height1:95,
        width1:330,
        height2:93,
        width2:190,
        benefitLabelNumber: '88056',
        costLabelNumber: '88061',
        titleLabelNumber: '8066',
    }/*Se comenta por desición de eliza,
    {
        id : 6,
        title : "No improvement option this month",
        benefit : "",
        benefitUrlImage:"",
        cost:"",
        costUrlImage:"",
        color:"#ffa826",
        selected: false,
        disabled: false,
        zoom: false,
        height1:0,
        width1:0,
        height2:0,
        width2:0,
        titleLabelNumber: '8067',
    }*/
]