import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { _URL_AUTH } from './../../config/config';
import { ProfileInfo } from '../../models/profile.models';
import { first } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private $user: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  user: Observable<any> = this.$user.asObservable();

  public userProfile$: BehaviorSubject<Partial<ProfileInfo>> = new BehaviorSubject(
    null
  );
  private userProfile: ProfileInfo = new ProfileInfo();

  constructor(private http: HttpClient) {
    this.getInfo();
  }

  login(email: string, password: string): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({ strEmail: email, strPassword: password });
    return this.http.post(_URL_AUTH + '/auth', params, {
      headers: httpHeaders,
    });
  }

  updatePassword(email: string, _userId: any): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({ strEmail: email, userId: _userId });
    return this.http.post(_URL_AUTH + '/updatePassword', params, {
      headers: httpHeaders,
    });
  }

  firstLoginChangePassword(jsonUser) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify(jsonUser);
    return this.http.put(_URL_AUTH + '/firstLoginChangePassword', params, {
      headers: httpHeaders,
    });
  }

  emitUserLogin() {
    this.$user.next(true);
  }

  emitUserLogout() {
    this.$user.next(false);
  }

  private onProfileInfoLoaded(model) {
      this.userProfile = model;
      this.userProfile$.next({
        ...model
      });
      //this.setData(model);
  }


  /*setData(profile: ProfileInfo) {
    this.userProfile$.pipe(first()).subscribe((appcues) => {
      // Init user data for Appcues app >>
      appcues.identify(profile.UserID, {
        // Unique identifier for current user
        DistributorID: profile.DistributorID, // current user's first name
        IsFacilitator: profile.IsFacilitator, // current user's last name
        type: profile.type, // Current user's phone
        CompanyName: profile.CompanyName, // Current user's email
      });
    });
  }*/

  async getInfo() {
    console.log("GET IN getInfo");
    const user: ProfileInfo = await this.getInfoProfile();
    this.onProfileInfoLoaded(user);
  }

  getInfoProfile = (): ProfileInfo => {
      let user = JSON.parse(sessionStorage.getItem('User'));
      if(user === null)
        return null
      else
        return {
          UserID: user['UserID'],
          type: user['type'],
          DistributorID: user['DistributorID'],
          CompanyName: user['CompanyName'],
          IsFacilitator: user['IsFacilitator'],
        }
  };

}
