<div class="row w-100" style="margin: 30px 0px;">
    <div class="col text-center">
        <label class="FontMontserratTitles">Add / Remove Teams</label>
    </div>
</div>
<div id="errorGeneral2" class="container-styles-globals" style="position: absolute;"></div>

<div style="margin: 15px 0px;">
    <div class="container">
        <div class="row " style="justify-content: space-evenly;">
            <div *ngIf="!canDeleteOrAdd" class="my-auto">
                <button class="btn btn-ok" style="color: white;" (click)="blockTeams()">
                    Lock All Teams
                </button>
                <!-- <button *ngIf="blnBlockTeams" class="btn btn-ok" style="color: white;" (click)="unblockTeams()">
                    Unblock All Teams                                
                </button> -->
            </div>
            <div *ngIf="canDeleteOrAdd" class="my-auto" style="justify-content: center;">
                <button class="btn btn-ok" style="color: white;" (click)="addTeam()">
                    Add team
                </button>
            </div>
            <div *ngIf="canDeleteOrAdd || hasChanges" class="my-auto">
                <button class="btn btn-ok" style="color: white;" (click)="editTeamMember()">
                    &nbsp;{{getSingleTextLabel('3066.1')}}&nbsp;
                </button>
            </div>
            <div *ngIf="!canDeleteOrAdd && !hasChanges" class="my-auto">
                <button class="btn btn-ok" style="color: white;" (click)="unblockTeams()">
                    &nbsp;{{getSingleTextLabel('3066.2')}}&nbsp;
                </button>
            </div>
            <div style="text-align: center; display: block !important">
                <button class="btn btn-secondary mx-3 style9 calibriFont"
                    (click)="closeDialog()">&nbsp;{{getSingleTextLabel('135')}}&nbsp;</button>
            </div>
        </div>
        <div *ngIf="hasChanges" class="row" style = "justify-content: center; color: red; margin-top: 5px;"> <strong>&nbsp;{{getSingleTextLabel('3066.3')}}&nbsp;</strong>  </div>
        <div *ngIf="blnBlockTeams " class="row" style = "justify-content: center; color: red; margin-top: 5px;"> <strong>&nbsp;{{getSingleTextLabel('3066.4')}}&nbsp;</strong>  </div>
    </div>
</div>
<div class="w-100 mt-4 seccionCaptura2 FontMontserrat">
    <div class="row" style="display: flex; justify-content: space-between; align-items: center;">
        <span style="background-color: #ffffff; border: none; padding: .375rem .75rem; color: #6FB45C; font-weight: bold; font-size: 1.3em;">
            Workshop Security Code - {{facCode}}
        </span>
        <button (click)="toggleForm()" style="background-color: #ffffff; border: none; color: #6FB45C; font-weight: bold; font-size: 1.3em;">
            <i class="fas fa-plus" style="color: #6FB45C; font-size: 1.5em;"></i> Add team member
        </button>
    </div>    
</div>
<div *ngIf="mostrarFormulario">
    <div class="w-100 mt-4 seccionCaptura FontMontserrat">
        <div class="row">
            <form class="container FontMontserratTextInputs" style="padding-left: 10%; padding-right: 10%"
                [formGroup]="teemMemberForm">
                <div class="row">
                    <div class="col-12">
                        Create team members manually
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
                        <label for="firstName" class="">First name <span style="color: red;">*</span>
                        </label>
                        <br>
                        <mat-form-field class="w-100" appearance="fill">
                            <mat-label>First name</mat-label>
                            <input matInput formControlName="firstName"
                                [ngClass]="{ 'is-invalid': !teemMemberForm.get('firstName').valid }"
                                placeholder="First name" name="firstName" id="firstName" />
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
                        <label for="lastName" class="">Last name <span style="color: red;">*</span> </label>
                        <br>
                        <mat-form-field class="w-100" appearance="fill">
                            <mat-label>Last name</mat-label>
                            <input matInput formControlName="lastName"
                                [ngClass]="{ 'is-invalid': !teemMemberForm.get('lastName').valid }" placeholder="Last name"
                                name="lastName" id="lastName" />
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
                        <label for="email" class="">Job Title</label>
                        <br>
                        <mat-form-field class="w-100" appearance="fill">
                            <mat-label>Job Title</mat-label>
                            <input matInput formControlName="jobTitle"
                                [ngClass]="{ 'is-invalid': !teemMemberForm.get('jobTitle').valid }"
                                placeholder="Job Title" name="jobTitle" id="jobTitle" />
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
                        <label for="email" class="">Email <span style="color: red;">*</span> </label>
                        <br>
                        <mat-form-field class="w-100" appearance="fill">
                            <mat-label>Email</mat-label>
                            <input matInput formControlName="email"
                                [ngClass]="{ 'is-invalid': !teemMemberForm.get('email').valid }"
                                placeholder="email@example.com" name="email" id="email" />
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
                        <label for="email" class="">Team Number</label>
                        <br>
                        <mat-form-field class="w-100" appearance="fill">
                            <mat-label>Team Number</mat-label>
                            <input type="number" min="1" [max]="lstOfTeams.length" matInput formControlName="teamNumber"
                                [ngClass]="{ 'is-invalid': !teemMemberForm.get('teamNumber').valid }"
                                placeholder="0" name="teamNumber" id="teamNumber" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-12 text-right my-auto">
                        <button class="text-right" [disabled]="teemMemberForm.invalid" (click)="addContactTable()"
                            style="background-color: #ffffff;border: 0;color: #6FB45C;font-weight: bold;font-size: 130%;">
                            <ng-container *ngIf="isEditContact==-1; else elseLabelSaveContact">
                                <i class="fas fa-user-plus " style="color: #6FB45C;font-size: 150%;"></i> Add player
                            </ng-container>
                            <ng-template #elseLabelSaveContact>
                                <i class="fas fa-user-plus " style="color: #6FB45C;font-size: 150%;"></i> Save changes                                
                            </ng-template>
                        </button>
                    </div>
                </div>
    
    
            </form>
            <ngx-spinner bdColor="rgba(0,0,0,0.75)" size="large" color="#ffffff" type="ball-clip-rotate-multiple"
                [fullScreen]="true">
                <p style="color: white"> Loading... </p>
            </ngx-spinner>
    
            <div class="row my-1 w-100" style="padding-left: 10%; font-size: 11px">
                <div class="col-12 FontMontserrat" style="font-size: 11px;">
                    <span style="color: red;"><strong>*</strong></span><strong> Mandatory fields.</strong>
                </div>
            </div>
        </div>
    
        <!-- <div  class="row">
            <div class="col-12">
                <table mat-table [dataSource]="dataSourceTable"
                    style="border: 1px solid #D5DCE3;margin-left: 2.5%;margin-bottom: 2.5%;width: 95%;margin-right: 2.5%;"
                    class="mt-2" matSort aria-label="Elements">
    
                    <ng-container matColumnDef="firstName">
                        <th mat-header-cell *matHeaderCellDef>
                            <div class="mat-header-cell-text">
                                First name
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <label class="FontMontserratTextTables"> {{ row.FirstName }}</label>
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="lastName">
                        <th mat-header-cell *matHeaderCellDef>
                            <div class="mat-header-cell-text">
                                Last name
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <label class="FontMontserratTextTables"> {{ row.LastName }}</label>
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="jobTitle">
                        <th mat-header-cell *matHeaderCellDef>
                            <div class="mat-header-cell-text">
                                Job title
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <label class="FontMontserratTextTables">{{row.JobTitle}}</label>
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef>
                            <div class="mat-header-cell-text">
                                Email
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <label class="FontMontserratTextTables">{{row.Email}}</label>
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="teamNumber">
                        <th mat-header-cell *matHeaderCellDef>
                            <div class="mat-header-cell-text">
                                Team Number
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <label class="FontMontserratTextTables">{{(row.TeamId !=-1)?row.TeamId:'Unassigned'}}</label>
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>
                            <div class="mat-header-cell-text">
                                Actions
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let row; let i = index">
                            <img (click)="fnEditUser(row, i)" src="../../../assets/icon-edit.png" alt="Edit"
                                class="iconTable" data-toggle="tooltip" data-placement="top" title="Edit Contact">&nbsp;
                            <img (click)="fnDeleteUser(row)" src="../../../assets/icon-delete.png" alt="Edit"
                                class="iconTable" data-toggle="tooltip" data-placement="top" [attr.title]="'Delete'">
                        </td>
                    </ng-container>
                    <tr class="tableHeader" mat-header-row *matHeaderRowDef="titulos"></tr>
                    <tr mat-row *matRowDef="let row; columns: titulos;"></tr>
                </table>
                <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons></mat-paginator>
            </div>
        </div> -->
    </div>
</div>


<!-- drag and drop team members -->
<div class="row mx-0" cdkDropListGroup style="margin: 15px 0px;">
    <div class="example-container col-4" *ngFor="let team of lstOfTeams; let i = index"
        [ngClass]="{'showTeam' : team['blnShow'] == false }">
        <!-- <div class="example-container" *ngFor="let team of ltsOfTeams.reverse()"> -->
        <div>
            <div class="headerTeam">
                <h3>{{team['Avatar']}}</h3><br>
                <button *ngIf="!canDeleteOrAdd" class="btn btn-warning mx-3 myButtomstyle9 calibriFont"
                    (click)="blockTeam(team)">&nbsp;Lock Team&nbsp;</button>
                <button *ngIf="canDeleteOrAdd" class="btn btn-danger mx-3 myButtomstyle9 calibriFont"
                    (click)="deleteTeam(team)">&nbsp;Delete Team&nbsp;</button>
            </div>
            <div cdkDropList #doneList="cdkDropList" [cdkDropListData]="team.TeamMembers" class="example-list"
 (cdkDropListDropped)="dropTeamMemeber($event, team)" >
                <div class="example-box" *ngFor="let item of team.TeamMembers; let j = index" cdkDrag (cdkDragStarted)="onDragStarted($event, team)"
                    style="border-radius: 10px;padding-top: 2px;padding-bottom: 2px;">
                    <div [class]="'bgTeam'+i+' example-card' " class=""
                        style="margin-left: 2px !important; margin-right: 2px; text-align: left;color: white;width: 600px !important;border-radius: 10px;">

                        <p class="TextCards">&nbsp;&nbsp;<b>{{item.FirstName + ' ' + item.LastName}}</b>
                            <br>
                            &nbsp;&nbsp;{{item.Email}}<span style="margin-right: 15px;float: right;">&nbsp;&nbsp;
                                {{item.JobTitle}}</span>

                            <span (click)="fnDeleteUser(item)" style="margin-right: 15px;float: right;">&nbsp;&nbsp;<i class="fas fa-trash-alt    "></i>Delete</span>
                            <span (click)="fnCopyLinkUser(item.Email)" 
                                [matTooltip]="getLinkForEmail(item.Email)" 
                                matTooltipPosition="above" 
                                style="margin-right: 15px; float: right; cursor: pointer;">
                            <i class="fas fa-copy"></i> Copy link
                            </span>


                            <!-- <span (click)="cargarForm(item, i, j)" style="margin-right: 15px;float: right;">&nbsp;&nbsp;<i class="fas fa-edit    "></i>Edit</span>
                            
                            <mat-checkbox *ngIf="item['logged']==1" (click)="modificarSesion(item, i, j)" checked="true"  style="margin-top: 3px;margin-right: 10px;float: right;"  ></mat-checkbox>
                            <mat-checkbox *ngIf="item['logged']==0" (click)="modificarSesion(item, i, j)"   style="margin-top: 3px;margin-right: 10px;float: right;"  ></mat-checkbox> -->

                            <!-- <span style="margin-right: 10px;float: right;font-family: 'Montserrat';">Session</span> -->

                        </p>


                    </div>
                </div>
            </div>
        </div>
    </div>
</div>