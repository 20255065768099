export const startMonth = [
    {td1:"Rules", td2: 2, td3:1, td4:1, labelNumber: '1'},
    {td1:"Projects", td2: 2, td3:1, td4:3, labelNumber: '5006'},
    {td1:"Customers", td2: 2, td3:2, td4:1, labelNumber: '9'},
    {td1:"Shareholder Reports", td2: 1, td3:2, td4:1, labelNumber: '10'},
    {td1:"CheckList", td2: 1, td3:1, td4:1, labelNumber: '4001'},
    {td1:"Income Statement", td2: 1, td3:2, td4:1, labelNumber: '13'},
    {td1:"Balance Sheet", td2: 1, td3:2, td4:1, labelNumber: '44'},
    {td1:"Learning Slides", td2: 1, td3:1, td4:1, labelNumber: '7133'}
]

