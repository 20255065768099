import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ImprovementService } from 'src/app/services/improvement/improvement.service';
import { SocketImprovementService } from 'src/app/services/sockets/socket-improvement/socket-improvement.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ChangeimprovementComponent } from '../changeimprovement/changeimprovement.component';

@Component({
  selector: 'app-dialog-setup-improvements',
  templateUrl: './dialog-setup-improvements.component.html',
  styleUrls: ['./dialog-setup-improvements.component.scss']
})
export class DialogSetupImprovementsComponent implements OnInit {

  month = 0;
  destroy$: Subject<boolean> = new Subject<boolean>();
  workshopId = 0;
  businessModel = '';
  arrCheckBoxes = [
    {id: 0, title: 'Unlimited', selected: false},
    {id: 1, title: `Max 1`, selected: false},
    {id: 2, title: `Max 2`, selected: false},
  ];
  arrImprovements = [];
  dialogRef: any;
  intCycles = 4;
  blnHas4Cycles = true;
  arrImprovementsTemp = [];
  constructor(
    public matDialogRef: MatDialogRef<DialogSetupImprovementsComponent>,
    private spinner: NgxSpinnerService,
    private improvementService: ImprovementService,
    private socketImprovement: SocketImprovementService,
    private _matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private _data: any
  ) { 
    console.log("SETUP IMP", this._data);
    this.workshopId = this._data['workshopId'];
    this.month = this._data['month'];
    this.businessModel = this._data['businessModel'];
    this.intCycles = this._data['Cycles'];
  }

  ngOnInit(): void {
    if(this.intCycles == 5 || this.intCycles == 6){
      this.blnHas4Cycles = false;
    }
    this.fnGetImprovements(this.workshopId);
    this.fnInitObserverImprovements();
    
  }

  fnGetImprovements(workshopId){
    this.improvementService.getImprovementsByWorkShopId(workshopId)
    .subscribe(response=>{
      console.log(response['improvements'])
      console.log(this.arrImprovementsTemp)
      console.log(this.arrImprovements)
      //console.log("fnGetImprovements Original",JSON.stringify(response));
/*CAMBIO ARRAY DANI */
this.arrImprovementsTemp =  response['improvements'];
this.arrImprovements = this.arrImprovementsTemp;
//console.log("ANTESSS DE BORRAR:: ", JSON.stringify(this.arrImprovements))
  this.arrImprovementsTemp.forEach((imp, index) => {
    console.log("INDEX::", index,this.intCycles)
    if(this.arrImprovementsTemp[index]['options'].length >= 3){
      if(this.intCycles == 6){
        console.log("IF ANTESSS DE BORRAR  option2:: ", JSON.stringify(this.arrImprovements[index]['options'][2]))
        this.arrImprovements[index]['options'].splice(1, 1);
      }else{
        this.arrImprovementsTemp[index]['options'].pop()

      }
     // console.log("IF ANTESSS DE BORRAR  option2:: ", JSON.stringify(this.arrImprovements[index]['options'][2]))
      //
    }//else{
      //console.log("IF ANTESSS DE BORRAR  option1:: ", JSON.stringify(this.arrImprovements[index]['options'][1]))
      // this.arrImprovements[index]['options'].splice(1, 1);
    //}
  });
  console.log("DESPUES DE BORRAR:: ", this.arrImprovements)


/**CFINCAMBIO DANI */
      this.arrImprovements = response['improvements'];
      this.fnGetMaxPerImprovement(this.workshopId);
    })
  }

  fnGetMaxPerImprovement(workshopId){
    this.improvementService.getMaxPerImprove(workshopId)
    .subscribe(response=>{
      console.log("fnGetMaxPerImprovement",response);
      this.arrCheckBoxes.forEach(jsonCheckBox =>{
        if(response["MaxPerImprove"] === jsonCheckBox.id)
          jsonCheckBox.selected = true;
      })
    })
  }

  fnCheckBoxChange(jsonCheck){
    console.log("jsonCheck",jsonCheck);
    this.arrCheckBoxes.forEach(improvement => {
      improvement.selected = false;
    });
    jsonCheck.selected = true;
    this.improvementService.updateMaxPerImproveByWorkShopId(this.workshopId,jsonCheck.id)
    .subscribe(response =>{
      console.log("updateMaxPerImproveByWorkShopId",response);
      this.fnAlertSuccess(this.getErrorLabel('1086'));
    });
  }

  fnGetImprovement(option:number){
    let strOption:string="";
    switch(option){
      case 1:
        if(this.businessModel === 'Mfg EBIT'){
          strOption = this.getSingleTextLabel('90');//"Raw Materials"
        }else if(this.businessModel === "S&S EBITDA" || this.businessModel == "S&S EBITDA-B"){
          strOption = this.getSingleTextLabel('88062.1');//"Low Cost Royals (LCR)"
        }else{
          strOption = this.getSingleTextLabel('88062');
        }
      break;
      case 2:
        strOption = this.getSingleTextLabel('91');//"Efficiency"
      break;
      case 3:
        if(this.businessModel === "S&S EBITDA" || this.businessModel == "S&S EBITDA-B"){
          strOption = this.getSingleTextLabel('88062.2'); //PS office
        }else{
          strOption = this.getSingleTextLabel('92');//"Customizing"
        }
      break;
      case 4:
        strOption = this.getSingleTextLabel('93');//"Quality"
      break;
      case 5:
        strOption = this.getSingleTextLabel('94');//"Expansion"
      break;
      case 6:
        strOption = "No Improvement";//"No Improvement"
      break;
    }
    return strOption;
  }
  fnReselectImprovement(teamId, workshopId, improveOption){
    console.log(teamId, workshopId, improveOption)
    let team = this.arrImprovements.filter(x => x['TeamId'] == teamId);
    console.log(team, this.arrImprovements)
    improveOption['improveOption'] = 0;
    improveOption['improveOptionId'] = improveOption['ImproveOptionId'];
    this.fnSetImprovement(improveOption);
    this.improvementService.updateImprovementSelected(improveOption['ImproveOptionId'], 0)
            .subscribe(response=>{
              console.log("fn updateImprovementSelected::::",response);
              Swal.fire({
                text: '',
                imageUrl: '../../../../assets/iconSuccessfully.svg',
                imageAlt: 'icon successfully',
                confirmButtonColor: '#5EA0CC',
                html: `Team ${team[0]['Avatar']} can reselect improvement`,
              });
            })
    
    this.socketImprovement.reselectImprovement(teamId,("roomWorkShop"+workshopId));

  }
  fnApproveImprovement(teamId, workshopId, improveOption, status){
    console.log("fnApprovem",improveOption, status);
    let approved = status === 2 ? 1 : 0;
    console.log(approved, status)
    var IdImprovementPrevious = improveOption.Option;
    if(improveOption.Option == 6 && approved == 0){
      //significa que rechazo que el team no implemente improvement, asi que le damos modal para que le asigne uno
      //******************************************************************* */
    console.log("entra cambio de improvement:::::::")
        let lstImprovs = [];
        lstImprovs.push({improvementId:1, Description: "Raw Materials"});
        lstImprovs.push({improvementId:2, Description: "Efficiency"});
        lstImprovs.push({improvementId:3, Description: "Customizing"});
        lstImprovs.push({improvementId:4, Description: "Quality"});
        lstImprovs.push({improvementId:5, Description: "Expansion"});
    
  
        this.dialogRef = this._matDialog.open(ChangeimprovementComponent, {
          width: '400px',
          height: '330px',
          panelClass: 'event-form-dialog',
          data: {
            lstImprovements: lstImprovs,
            arrTextLabels: this._data.arrTextLabels,
          },
        });
  
        this.dialogRef.afterClosed().subscribe((resp) => {
          console.log("RETURNED DIALOG change IMprovement", resp.IdImprovSelected);
          //validamos si cambió de team para recargar todo resp.IdImprovSelected
         // crear servicio que actualice el improvement para el equipo dani
         console.log("resp.IdImprovSelected:", resp.IdImprovSelected)
         if(resp.IdImprovSelected == 0)
         {
          console.log("if entra afterClosed :Solo cerramos el modal")
         }
         else{
          this.improvementService.updateImprovementSelected(improveOption['ImproveOptionId'], resp.IdImprovSelected)
            .subscribe(response=>{
              console.log("fn updateImprovementSelected::::",response);
              
              this.socketImprovement.updateImprovementApproved(teamId,("roomWorkShop"+workshopId), improveOption['ImproveOptionId'], status, IdImprovementPrevious);
              let strApproved = approved === 1 ? this.getErrorLabel('1088') : this.getErrorLabel('1087');
              this.fnAlertSuccess(strApproved);
            })
          }
  
        })
      }
      //*************************************************************** */
    else{
      this.improvementService.updateImproveOptionQuestion(improveOption['ImproveOptionId'], 0).subscribe(resp => {
        console.log("fnSaveStatus", resp);
      })
          this.improvementService.updateImprovementApproved(improveOption['ImproveOptionId'], status, approved)
          .subscribe(response=>{
            console.log("fnApproveImprovement",response);
            this.socketImprovement.updateImprovementApproved(teamId,("roomWorkShop"+workshopId), improveOption['ImproveOptionId'], status, IdImprovementPrevious);
            let strApproved = approved === 1 ? this.getErrorLabel('1088') : this.getErrorLabel('1087');
            this.fnAlertSuccess(strApproved);
          })
        }
  }

  fnAlertSuccess(strMessage){
    Swal.fire({
      text: '',
      imageUrl: '../../../../assets/iconSuccessfully.svg',
      imageAlt: 'icon successfully',
      confirmButtonColor: '#5EA0CC',
      html: strMessage,
    });
  }

  fnInitObserverImprovements() {
    //this.socketImprovement.setupSocketConnection();
    console.log("ENTRA fnInitObserverImprovements SET UP");
    this.socketImprovement._improvement
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (response) {
          console.log('RESPONSE IMPROVEMENTS SET UP', response);
          if (response.status == 'improveOptionServer' && response.data != undefined) {
            console.log('improveOptionServer', response);
            // response.data.improveOption = 1 for Raw materials
            // 2 for efficiency
            // 3 for customizing
            // 4 for quality
            // 5 for expansion 
            // 6 for no improvement
            this.fnSetImprovement(response.data);
  
          }else if (response.status == 'quitBotom'){
            console.log("LEGOOOOO",this.arrImprovements)
            this.arrImprovements.forEach(x => {
              if(x.TeamId == response.data.teamId){
                x.Status = response.data.status
              }
            })
          }else if (response.status == "aceptDeclineImprovementServer"){
            console.log(this.arrImprovements);
            this.fnSetImprovement2(response.data);
          }
        }
      })
  }

  fnSetImprovement(improvement){
    console.log(improvement)
    this.arrImprovements.forEach(imp => {
      imp['options'].forEach(option => {
        if(option['ImproveOptionId'] === improvement['improveOptionId']){
          console.log("ES esteeee", imp)
          option['Option'] = improvement['improveOption'];
          option['Status'] = improvement['improveStatus'];  
          option['isQuestion'] = 1 
          if(option['Status'] == 1){
            imp['Status']  = 0
          }else if(option['Status'] == 2){
            imp['Status']  = 3
          }
        }
      });
    });
  }
  fnSetImprovement2(improvement){
    this.arrImprovements.forEach(imp => {
      imp['options'].forEach(option => {
        
        if(option['ImproveOptionId'] === improvement['improveOptionId']){
          console.log("ES esteeee", imp)
          option['Option'] = improvement['IdImprovementPrevious'];
          option['Status'] = improvement['improveStatus'];
          option['isQuestion'] = 0 

          if(improvement['improveStatus'] == 2){
            option['Approved'] = 1
          }
        }
      });
    });
  }

  getSingleTextLabel(labelNumber: string): string {
    const tmp = this._data.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    return tmp ? tmp.Label : '';
  }

  getErrorLabel(labelNumber: string, code: string | null = null): string {
    const tmp = this._data.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    let msg = ''
    if (tmp) {
      const LabelBase = tmp.LabelBase;
      let errorCode = code;
      if (!code) {
        const array = LabelBase.split(':');
        errorCode = array.length > 0 ? array[0] : '';
      }
      const className = environment.isProd ? 'lightgray' : 'gray';
      msg = `<div><span style="color:${className}; font-size: 12px">${errorCode}: </span>${tmp.Label}</div>`;
    }
    return msg;
  }
  casoEspecial(options){
    if(options['Status'] == 2 && options['Month'] == 3 && this.month >= 4){
      return false
    }
  }
  updateView(){
    this.fnGetImprovements(this.workshopId);
    this.fnInitObserverImprovements();
  }
}
