<div  id="divBoardVersion" style="color: #b4b6d1;">    {{VERSION}}</div>
<div class="container">
<div class="row">
  <div class="col">
    <div ngbDropdown class="d-inline-block">
      <button class="btn btn-primary m-3" id="dropdownBasic1" ngbDropdownToggle>
        <i class="fas fa-bars"></i>
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <div *ngIf="blnAdministrators"><button [routerLink]="['/usuarios/listado-usuarios']" routerLinkActive="router-link-active"  ngbDropdownItem><i class="fas fa-users iconMenu"></i> <span class="ml-2">Administrators</span></button></div>
        <div *ngIf="blnLanguages"><button [routerLink]="['/languages']" routerLinkActive="router-link-active"  ngbDropdownItem><i class="fa fa-language iconMenu"></i> <span class="ml-2">Languages</span></button></div>
        <div *ngIf="isSuperAdmin"> <button [routerLink]="['/emails/edit-templates']" routerLinkActive="router-link-active"  ngbDropdownItem><i class="fa fa-envelope iconMenu"></i> <span class="ml-2">Mail Templates</span></button></div>
        <div *ngIf="blnWorkshops"> <button [routerLink]="['/workshops/workshops-list']" routerLinkActive="router-link-active"  ngbDropdownItem><i class="fa fa-briefcase iconMenu"></i> <span class="ml-2">WorkShops</span></button></div>
        <div *ngIf="isSuperAdmin"> <button [routerLink]="['/market']" routerLinkActive="router-link-active"  ngbDropdownItem><i class="fas fa-university iconMenu"></i> <span class="ml-2">Markets</span></button></div>
        <button [routerLink]="['/home/login']" routerLinkActive="router-link-active"  (click)="fnCerrarSesion()" ngbDropdownItem><i class="fas fa-sign-out-alt iconMenu"></i> <span class="ml-2">Log out</span></button>
      </div>
    </div>
    <img src="../../../assets/IOlogo_linear2-1.png" class="m-3 imgLogo"  alt="IO_Logo"/>
  
    <div class="d-inline-block" style="align-items: flex-end; width: 60%; text-align: end;">
      {{nombre}}
      <div>
        {{email}} 
      </div>
    </div> 
  </div>
</div>
</div> 