import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(private router: Router) {}

  canActivate() {
    if (!environment.userLoggedIn) {
      this.router.navigate(['/home/login']);
      return false;
    }
    return true;
  }
}
