import { Component, OnInit } from '@angular/core';
import { FormBuilder,Validators  } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { OrderService } from 'src/app/services/order/order.service';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2';

export interface MarketElement {
  MarketOrderId:number;
  Cycle:number;
  OrderNum: number;
  Quantity: number;
  Terms: number;
  Month:number;
  Teams:number;
  Customizing:number
}
export interface Month {
  value: number;
  viewValue: number;
}
export interface Team {
  value: number;
  viewValue: number;
}
@Component({
  selector: 'app-market',
  templateUrl: './market.component.html',
  styleUrls: ['./market.component.scss']
})
export class MarketComponent implements OnInit {
  dataSourceTable: MatTableDataSource<any> = new MatTableDataSource([]);
  myOrders : any = {};
  myQuantities : any = {};
  myTerms : any = {};
  myCustomizing : any = {};
  //market:MarketElement[]=[]
  market =[];
  bandera=false;
  monthNum: number;
  tabSelected = "Manufacturing Game";
  typeMode:string;
  selectTab = 0;
  titulos: string[] = [
    'OrderNum',
    'Quantity',
    'Terms',
    'Customizing',
    'actions',
  ];
  teams:Team[] = [
    { value: 1, viewValue: 1 },
    { value: 2, viewValue: 2 },
    { value: 3, viewValue: 3 },
    { value: 4, viewValue: 4 },
    { value: 5, viewValue: 5 },
    { value: 6, viewValue: 6 }
  ];
  months4:Month[] = [
    { value: 2, viewValue: 2 },
    { value: 3, viewValue: 3 },
    { value: 4, viewValue: 4 }
  ];
  months5:Month[] = [
    { value: 2, viewValue: 2 },
    { value: 3, viewValue: 3 },
    { value: 4, viewValue: 4 },
    { value: 5, viewValue: 5 }
  ];
  months6:Month[] = [
    { value: 2, viewValue: 2 },
    { value: 3, viewValue: 3 },
    { value: 4, viewValue: 4 },
    { value: 5, viewValue: 5 },
    { value: 6, viewValue: 6 }
  ];
  monthsDemo:Month[] = [
    { value: 1, viewValue: 1 },
    { value: 2, viewValue: 2 },
    { value: 3, viewValue: 3 },
    { value: 4, viewValue: 4 }
  ];
  teamsDemo:Team[] = [
    { value: 2, viewValue: 2 },
    { value: 3, viewValue: 3 },
    { value: 4, viewValue: 4 },
  ];
  constructor(private formBuilder: FormBuilder, private orderService:OrderService) { }
  marketForm = this.formBuilder.group({
    wsType:[null,Validators.required],
    cycle: [null, Validators.required],
    month: [null, Validators.required],
    team: [null, Validators.required]
  });
  orderForm = this.formBuilder.group({
    OrderNum: [null, [Validators.required,Validators.min(101),Validators.max(699)]],
    Quantity: [null, [Validators.required,Validators.min(1),Validators.max(50)]],
    Terms: [null, Validators.required],
    Customizing: [null],
  });
  orderTableForm = this.formBuilder.group({
    OrderNum: [null, [Validators.min(101),Validators.max(699)]],
    Quantity: [null, [Validators.min(1),Validators.max(50)]],
    Terms: [null],
    Customizing: []
  });
  ngOnInit(): void {
  }
  updateType(ob){
    console.log(this.marketForm.value);
    this.marketForm.patchValue({
      cycle:null,
      month:null,
      team:null
    });
    if(this.marketForm.value.wsType=='NM'){
      this.monthNum = 4;
      this.typeMode = "NM";
      this.bandera=false;
    }
    if(this.marketForm.value.wsType=='DM'){
      this.monthNum = 4;
      this.typeMode = "DM";
      this.marketForm.value.cycle = 4
      this.marketForm.patchValue({
        cycle:4,
      })   
      this.bandera=true;
    }
    if(this.marketForm.value.wsType=='SSNM'){
      this.monthNum = 4;
      this.typeMode = "SSNM";
      this.bandera=false;
    }
    if(this.marketForm.value.wsType=='SSDM'){
      this.monthNum = 4;
      this.typeMode = "SSDM";
      this.marketForm.value.cycle = 4
      this.marketForm.patchValue({
        cycle:4,
      })   
      this.bandera=true;
    }
    if(this.marketForm.value.wsType=='DM' && this.marketForm.value.cycle>4){
      console.log("Modo DEMO");
      this.monthNum = 4;
      this.marketForm.value.cycle = 4
      this.marketForm.patchValue({
        cycle:4,
      })   
      this.bandera=true;
    }
    if(this.marketForm.value.wsType=='SSDM' && this.marketForm.value.cycle>4){
      console.log("Modo Servicios DEMO");
      this.monthNum = 4;
      this.marketForm.value.cycle = 4
      this.marketForm.patchValue({
        cycle:4,
      })   
      this.bandera=true;
    }
    this.orderService.getMarketByParams(0,0,0,0).subscribe(response => {
      console.log("getMarketByParams", response);
      if (response['intResponse'] == 200) {
        console.log("OK")
        this.market = response.orders;
        console.log(this.market);
        this.market.forEach(order =>{
          console.log(order['Terms']);
          if(order['Terms']==0){
            order['Terms'] = 'Cash'
          }
        })
        this.dataSourceTable = new MatTableDataSource(this.market);
        console.log(this.dataSourceTable);
        console.log(this.myCustomizing)
        
      }else{
        Swal.fire({icon: 'error', title:"Something go wrong"});
      }
    })
    this.orderForm.patchValue({
      OrderNum:null,
      Quantity:null,
      Terms:null,
      Customizing:null
    })
  }

  fnCambiarContenido$(nuevoContenido){
    //this.emailsService.fnTabTable(nuevoContenido);
    this.fnCambiarContenido(nuevoContenido)
  }

  fnCambiarContenido(nuevoContenido) {
    console.log("nuevoContenido", nuevoContenido);
    this.tabSelected = nuevoContenido;

    switch (nuevoContenido) {
      case 'Manufactiring Game':
        console.log('tab manufactura')
        break;
      case 'Services Game':
        console.log('tab servicios')
        break;
      default:
        console.log('tab manufactura')
        break;
    }
  }

  updateMonth(ob){
    console.log(this.marketForm.value);
    this.monthNum = this.marketForm.value.cycle;
    this.typeMode = this.marketForm.value.wsType;
    if(this.marketForm.value.cycle<this.marketForm.value.month){
      console.log("Meses mayor a ciclo!!")
      this.marketForm.value.month = this.marketForm.value.cycle
      this.marketForm.patchValue({
        month:this.marketForm.value.cycle
      })   
    }
    console.log(this.monthNum);
    console.log("Cambio", ob.value,this.marketForm.invalid);
    if(this.marketForm.invalid){
      console.log("Faltan datos")
    }else{
      console.log("Llamada a funcion",this.marketForm.value.cycle,this.marketForm.value.month,this.marketForm.value.team)
      this.loadTable();
    }
    this.myOrders = {}
    this.myQuantities = {}
    this.myTerms = {}
    this.myCustomizing = {}
    console.log(this.marketForm.value);
  }
  fnAddOrder(){
    if(this.orderForm.value.Customizing){
      this.orderForm.value.Customizing=1;
    }else{
      this.orderForm.value.Customizing=0;
    }
    console.log(this.orderForm.value);
    if(this.orderForm.value.Terms==0||this.orderForm.value.Terms==30||this.orderForm.value.Terms==60||this.orderForm.value.Terms==90){
      if((this.orderForm.value.OrderNum<(this.marketForm.value.month+1)*100)&&(this.orderForm.value.OrderNum>this.marketForm.value.month*100)){
        console.log("OK")
        this.orderService.createMarketOrderByParams(this.marketForm.value.wsType,
                                                    this.marketForm.value.cycle,
                                                    this.marketForm.value.month,
                                                    this.marketForm.value.team,
                                                    this.orderForm.value.OrderNum,
                                                    this.orderForm.value.Quantity,
                                                    this.orderForm.value.Terms,
                                                    this.orderForm.value.Customizing).subscribe(response => {
          console.log("createMarketOrderByParams", response);
          if (response['intResponse'] == 200) {
            console.log("OK")
            this.loadTable();
            this.myOrders = {}
            this.myQuantities = {}
            this.myTerms = {}
            this.myCustomizing = {}
            Swal.fire({icon: 'success', title:"Order Added"});
            this.orderForm.reset()
          }else{
            if (response['intResponse'] == 205) {
              console.log("Order Exists!!")
              Swal.fire({icon: 'error', title:"This order already exists"});

            }else{
              if(response['intResponse'] != 200){
                console.log("Faltan datos")
                Swal.fire({icon: 'error', title:"Error adding order"});

              }
            }
          }
        })
      }else{
        Swal.fire({icon: 'error', title:"The order number is incorrect"});

      }
    }else{
      alert("Error en los terminos")
    }
    
  }
  fnEditOrder(row,i){
    
    console.log(row,i);
      if(row.Terms=="Cash"){
        row.Terms = 0;
      }
      if((row.OrderNum<(this.marketForm.value.month+1)*100)&&(row.OrderNum>this.marketForm.value.month*100)){
        console.log("OK");
        this.orderService.updateMarketOrderByParams(row.MarketOrderId,row.OrderNum,row.Quantity,row.Terms,row.Customizing).subscribe(response => {
          console.log("updateMarketOrderByParams", response);
          if (response['intResponse'] == 200) {
            console.log("OK")
            this.loadTable();
            this.myOrders = {}
            this.myQuantities = {}
            this.myTerms = {}
            this.myCustomizing = {}
            Swal.fire({icon: 'success', title:"Order Updated"});
          }else{
            Swal.fire({icon: 'error', title:"Error: "+response.strAnswer});
          }
        })
      }else{
        Swal.fire({icon: 'error', title:"The order number is incorrect"});

      }

  }
  fnDeleteOrder(row,i){
    this.orderService.deleteMarketOrderByParams(row.MarketOrderId).subscribe(response => {
      console.log("deleteMarketOrderByParams", response);
      if (response['intResponse'] == 200) {
        console.log("OK")
        this.loadTable();
        this.myOrders = {}
        this.myQuantities = {}
        this.myTerms = {}
        this.myCustomizing = {}
        Swal.fire({icon: 'success', title:"Order Deleted"});
      }else{
          Swal.fire({icon: 'error', title:"Error: "+response.strAnswer});   
      }
    })
  }
  changeRowOrderNum(ob,row,i){
    console.log(ob,row);
    console.log(this.myOrders)
    row.OrderNum = this.myOrders[i]
    console.log(row);
  }
  changeRowQuantity(ob,row,i){
    console.log(ob,row);
    console.log(this.myQuantities)
    row.Quantity = this.myQuantities[i]
    console.log(row);
  }
  changeRowTerms(ob,row,i){
    console.log(ob,row);
    console.log(this.myTerms)
    row.Terms = this.myTerms[i]
    console.log(row);
  }
  changeRowCustomizing(ob,row,i){
    console.log(row,i);
    if(row.Customizing==0){
      row.Customizing=1;
    }else{
      row.Customizing=0;
    }
    console.log(row);
  }
  loadTable(){
    this.orderService.getMarketByParams(this.marketForm.value.wsType,this.marketForm.value.cycle,this.marketForm.value.month,this.marketForm.value.team).subscribe(response => {
      console.log("getMarketByParams", response);
      if (response['intResponse'] == 200) {
        console.log("OK")
        this.market = response.orders;
        console.log(this.market);
        this.market.forEach(order =>{
          console.log(order['Terms']);
          if(order['Terms']==0){
            order['Terms'] = 'Cash'
          }
        })
        this.dataSourceTable = new MatTableDataSource(this.market);
        console.log(this.dataSourceTable);
        console.log(this.myCustomizing)
        
      }else{
        Swal.fire({icon: 'error', title:"Something go wrong"});
      }
    })
  }

  fnSetCheckButtons(row){
    console.log("Check")
    if(row.Customizing==0){
      return true;
    }
    this.myCustomizing={}
  }
}
