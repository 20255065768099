<label class="FontMontserratTitles" id="example-radio-group-label">Can you tell us who are you?</label><br><br>
<mat-radio-group
  aria-labelledby="example-radio-group-label"
  class="example-radio-group"
  [(ngModel)]="selectedpeople">
  <mat-radio-button class="example-radio-button" *ngFor="let person of awesomepeople" [value]="person.completedname" >
    {{person.completedname}}
  </mat-radio-button><br>
</mat-radio-group>
<!--<div>Your selection: {{selectedpeople}}</div>-->

<button  type="button" class="btn btn-outline-success mx-3" id="btnsaveht" [disabled]="selectedpeople.length == 0"
        style="Background-color:#6FB45C;border-radius: 50px;color: white;font-family: 'Montserrat';"
        (click)="fnSelectPerson()">&nbsp;&nbsp;Done&nbsp;&nbsp;</button>