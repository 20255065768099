import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { BalanceSheetService } from 'src/app/services/balance-sheet/balance.service';
import { IncomeStatementService } from 'src/app/services/IncomeStatement/IncomeStatement.service';
import { TeamService } from 'src/app/services/team/team.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-manualedit-isbs',
  templateUrl: './manualedit-isbs.component.html',
  styleUrls: ['./manualedit-isbs.component.scss']
})
export class ManualeditISBSComponent implements OnInit {
  arrColumnas = [];
  arrTextLabels = [];
  arrColumnasBalance = [];
  blnIsColorSelected = false;
  blnEnableEditAvatar = false;
  canEdit = true;
  teamId = 0;
  teamIdOriginal = 0;
  localTeamName="";
  strBackground="";
  monthTeam = 0;
  receivedName="";
  receivedBackground="";
  blnFacilitador=false;
  workShopId;
  strFacilitators="";
  strObservers="";
  jsonLabels = [];
  jsonLabelsBalance = [];
  blnSwitch = false;
  teamForm: FormGroup;
  arrTeams= [];
  selectedAvatar="Facilitator";
  toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });
  
  constructor(private teamService: TeamService,
    public matDialogRef: MatDialogRef<ManualeditISBSComponent>,
    private spinner: NgxSpinnerService,
    private incomeService: IncomeStatementService,
    private balanceService: BalanceSheetService,
    @Inject(MAT_DIALOG_DATA) private _data: any) {
      console.log("recibimos en data::",_data)
      this.arrTeams = this._data["arrTeams"]
      this.arrTextLabels = this._data['arrTextLabels']
      console.log("this.arrTeams",this.arrTeams)
      this.incomeService.getLabelsByLanguageId(this._data.languageId).subscribe((response) => {
        this.jsonLabels = response['data'];
        console.log("Labels: ",this.jsonLabels );
        //this.spinner.hide();
      });
      this.balanceService.getLabelsByLanguageId(this._data.languageId).subscribe((response) => {
        this.jsonLabelsBalance = response['data'];
        console.log("Labels Obtenidas: ",this.jsonLabelsBalance );
        //this.spinner.hide();
      });
     }

  ngOnInit(): void {
    console.log("ngonninit de edit isbs this.arrTeams:",this.arrTeams);
   
    this.fnCrearForm();
  }

  fnCrearForm() {
    this.teamForm = new FormGroup({
      lstteamsCtrl : new FormControl(),//;new FormControl({},[]),
      //teamName: new FormControl(this.blnFacilitador?'ACME':'', [Validators.required]),
      
    });
    console.log(this.teamForm);
  }
  fnObtenerText(id){
    let valor = this.arrTextLabels.filter(x=>x.LabelNumber === id)
    let label = valor[0].Label;
    return label;
  }
  fnObtenerLabel(id){
    let valor = this.jsonLabels.filter(x=>x.LabelNumber === id)
    let label = valor[0].Label;
    return label;
  }
  fnObtenerLabelBalance(id){
    let valor = this.jsonLabelsBalance.filter(x=>x.LabelNumber === id)
    let label = valor[0].Label;
    return label;
  }
  selectTeam(objteam){
    console.log("team selected::", objteam.team);
    this.selectedAvatar = objteam.team.Avatar;
    this.teamId = objteam.team.TeamId;
    this.monthTeam = objteam.team['Month']
    //hacer aqui la carga de las tablas si se seleccionó team
    this.incomeService.getCantidadesLlenado(this.teamId).subscribe((response) => {
      console.log(response);
      this.arrColumnas = response['data'];
      this.spinner.hide();
    });
    this.balanceService.getCantidadesLlenado(this.teamId).subscribe((response) => {
      console.log(response['data']);
      this.arrColumnasBalance = response['data'];
      this.spinner.hide();
    });
    
  }
  fnChangeInput(i){
    console.log(this.arrColumnas , i);
    let month = i+1;
    console.log(this.arrColumnas[i].month, this.monthTeam);
    if (this.arrColumnas[i].month < this.monthTeam) {
      this.incomeService.updateManualyIncomeStatement(this.arrColumnas[i]['WokshopId'],
        this.arrColumnas[i]['teamId'],
        month,
        this.arrColumnas[i]['total'],
        this.arrColumnas[i]['costGood'],
        this.arrColumnas[i]['contribution'],
        this.arrColumnas[i]['factory'],
        this.arrColumnas[i]['gross'],
        this.arrColumnas[i]['selling'],
        this.arrColumnas[i]['operating'],
        this.arrColumnas[i]['interest'],
        this.arrColumnas[i]['income'],
        this.arrColumnas[i]['taxes'],
        this.arrColumnas[i]['netIncome']).subscribe((response) => {
          console.log(response);
          this.toast.fire({
            icon: 'success',
            title: 'Data saved',
          });
        });
    } else {
      this.toast.fire({
        icon: 'error',
        title: 'You can not do this now',
      });
    }

  }
  fnChangeInputBalance(i){
    console.log(this.arrColumnasBalance, i);
    let month = i + 1;
    if (this.arrColumnas[i].month < this.monthTeam) {
      this.balanceService.updateManuallyBalanceSheet(this.arrColumnasBalance[i]['WokshopId'],
        this.arrColumnasBalance[i]['teamId'],
        month,
        this.arrColumnasBalance[i]['cash'],
        this.arrColumnasBalance[i]['receivables'],
        this.arrColumnasBalance[i]['finishGood'],
        this.arrColumnasBalance[i]['workProcess'],
        this.arrColumnasBalance[i]['rawMaterials'],
        this.arrColumnasBalance[i]['totalCurrent1'],
        this.arrColumnasBalance[i]['machine'],
        this.arrColumnasBalance[i]['land'],
        this.arrColumnasBalance[i]['totalFixed'],
        this.arrColumnasBalance[i]['totalCurrent2'],
        this.arrColumnasBalance[i]['payables'],
        this.arrColumnasBalance[i]['loans'],
        this.arrColumnasBalance[i]['taxes'],
        this.arrColumnasBalance[i]['totalLiabilities'],
        this.arrColumnasBalance[i]['capital'],
        this.arrColumnasBalance[i]['retained'],
        this.arrColumnasBalance[i]['totalShareholder'],
        this.arrColumnasBalance[i]['totalLiabilitiesAndEquity']).subscribe((response) => {
          console.log(response);
          this.toast.fire({
            icon: 'success',
            title: 'Data saved',
          });

        });
    } else {
      this.toast.fire({
        icon: 'error',
        title: 'You can not do this now',
      });
    }

    
  }
  fnGetTeamById(teamId) {
    console.log("fnGetTeamById", teamId);
    this.teamService.getTeamAndMembersById(teamId).subscribe(async resp => {
      console.log("getTeamAndMembersById", resp);
      this.workShopId = resp['team']['WokshopId'];
      //this.fnSetRol(resp['members']);
      this.fnSetForm(resp['team']['Avatar']);
      //resp['team']['Avatar'] === null ? this.blnEnableEditAvatar = true: false;
      //const setColor = await this.fnSetColor(resp['team']['Color']);
      //this.fnIsColorSelected();
      //this.fnDisableColors(resp['colors']);
      //this.fnSetFacilitators(resp['facilitators']);
      //this.fnSetObservers(resp['observers']);
      this.spinner.hide();
    },error=>this.spinner.hide())
  }

  fnSetForm(teamName) {
    if(this.blnFacilitador && teamName === null){
      return;
    }
    this.teamForm.setValue({
      teamName: teamName,
      lstteamsCtrl: {}
    });
    if(!this.blnFacilitador){    
    // teamName !== null && teamName !== '' ? 
    // this.teamForm.controls['teamName'].disable(): 
    // this.teamForm.controls['teamName'].enable();
    }else{
      this.teamForm.controls['teamName'].enable();
    }

  }


}
