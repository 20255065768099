import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { _URL_IncomeStatement} from './../../config/config';


@Injectable({
  providedIn: 'root'
})
export class IncomeStatementService {

  constructor(private http: HttpClient) { }

  getLabelsByLanguageId(languageId): Observable<any> {
    return this.http.get(_URL_IncomeStatement + '/getLabels/' + languageId);
  }

  getCantidadesBoard(teamId): Observable<any> {
    return this.http.get(_URL_IncomeStatement + '/getCountBoard/' + teamId);
  }

  newRegister(WokshopId: number, teamId: number, month:number): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({WokshopId: WokshopId, teamId: teamId, month: month});
    return this.http.post(_URL_IncomeStatement  + '/newQuantities', params, {
      headers: httpHeaders,
    });
  }

  getCantidadesLlenado(teamId): Observable<any> {
    return this.http.get(_URL_IncomeStatement + '/getQuantities/' + teamId);
  }

  getCantidadesLlenadobyMonth(workshop, month): Observable<any> {
    return this.http.get(_URL_IncomeStatement + '/getQuantitiesbyMonth/' + workshop + '/'+month);
  }

  updateCantidades(WokshopId: number, teamId: number, month: number, total: string, costGood: string, contribution: string, factory: string, gross:string, selling: string, operating: string, interest: string, income: string, taxes: string, netIncome: string): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({WokshopId: WokshopId, teamId: teamId, month: month, total: total, costGood: costGood, contribution: contribution, factory: factory, gross: gross, selling: selling, operating: operating, interest: interest, income: income, taxes: taxes, netIncome: netIncome});
    return this.http.post(_URL_IncomeStatement + '/updateQuantities', params, {
      headers: httpHeaders,
    });
  }
  updateManualyIncomeStatement(WokshopId: number, teamId: number, month: number, total: string, costGood: string, contribution: string, factory: string, gross:string, selling: string, operating: string, interest: string, income: string, taxes: string, netIncome: string): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({WokshopId: WokshopId, teamId: teamId, month: month, total: total, costGood: costGood, contribution: contribution, factory: factory, gross: gross, selling: selling, operating: operating, interest: interest, income: income, taxes: taxes, netIncome: netIncome});
    return this.http.post(_URL_IncomeStatement + '/updateManualyIncomeStatement', params, {
      headers: httpHeaders,
    });
  }
  
  updateMonth(workShopId: number,teamId: number, month: number): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({ workShopId: workShopId, teamId: teamId,  month: month});
    return this.http.post(_URL_IncomeStatement + '/modifyMonth', params, {
      headers: httpHeaders,
    });
  }


  getCountCantidades(teamId): Observable<any> {
    return this.http.get(_URL_IncomeStatement + '/getRegistros/' + teamId);
  }

  deleteMonth(teamId: number, cantidad: number): Observable<any> {
    return this.http.delete(
      `${_URL_IncomeStatement}/deleteMonth/${teamId}/${cantidad}`
    );
  }

  getCountCantidadesTemp(teamId, month): Observable<any> {
    return this.http.get(`${_URL_IncomeStatement}/getQuantitiesIncomeTemp/${teamId}/${month}`);
  }
}