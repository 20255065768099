<div class="container mt-3 FontMontserratText pb-3 p-0">
    <div class="myClass">
        <div class="text-center">
            <label class="FontMontserratTitles">Edit Financial Statements</label>
        </div>
        <div>
        <form [formGroup]="teamForm" class="container FontMontserratTextInputs"
            style="padding-left: 5%; padding-right: 5%">
            <div  class="row">
                <div class="col-10" style="display: inline-flex; align-items: center;">
                    
                    
                <mat-form-field appearance="fill" style="padding-right: 30px;">
                        <mat-label>First select team:</mat-label>
                    <mat-select formControlName="lstteamsCtrl" >
                          <mat-option *ngFor="let objteam of arrTeams" [value]="objteam.team.Avatar" (click)="selectTeam(objteam)" >
                              <ng-container *ngIf="objteam.team.Avatar == null || objteam.team.Avatar == ''">
                                  <label>{{objteam.team.Name}}</label>
                              </ng-container>   
                              <ng-container *ngIf="objteam.team.Avatar != null || objteam.team.Avatar != ''">             
                                    <label>{{objteam.team.Avatar}}</label>
                                </ng-container> 
                           </mat-option>
                                   
                    </mat-select>
                </mat-form-field>
                <label for="lstteamsCtrl" style="padding-right: 5px;">Edit Team data directly. Changes are saved immediately. To undo an edit, re-enter the original data.
                </label>
                
                </div>
            </div>
           

           

            
        </form>
        </div>
        <br>
   
    </div>
    <label class="labelWarning" for="">A team must ‘complete’ both financial statements before the facilitator can use this tool to edit those financial statements. (i.e. Teams must click OK on their Balance Sheet.)</label>
    <div class="w-100 mt-4 FontMontserratText">
        
        <div class="divTable" style="margin-top: 10px;">
            <div class="divTableBody">
                <div class="divTableRow">                    
                    <div class="divTableCell textoInput2 style4">Income Statment Fields</div>
                    <div class="divTableCell style4" style="text-align: center;" *ngFor="let jsnTeam of arrColumnas, let i = index">Month {{i+1}}</div>
                </div>
                <div class="divTableRow">
                    <div class="divTableCell textoInput2 black">{{fnObtenerLabel('14')}}</div>
                    <div class="divTableCell textoInput" *ngFor="let jsnTeam of arrColumnas, let i = index">
                        <input (change)="fnChangeInput(i)"
                            type="number" id="input110" matInput class="w black" 
                            [(ngModel)]="jsnTeam['total']">
                    </div>
                </div>
                <div class="divTableRow">
                    <div class="divTableCell textoInput2 black">{{fnObtenerLabel('18')}}</div>
                    <div class="divTableCell textoInput" *ngFor="let jsnTeam of arrColumnas, let i = index">
                        <input (change)="fnChangeInput(i)"
                            type="number" id="input110" matInput class="w black" 
                            [(ngModel)]="jsnTeam['costGood']">
                    </div>
                </div>
                <div class="divTableRow">
                    <div class="divTableCell textoInput2 style6 black">{{fnObtenerLabel('20')}}</div>
                    <div class="divTableCell textoInput style6" *ngFor="let jsnTeam of arrColumnas, let i = index">
                        <input (change)="fnChangeInput(i)"
                            type="number" id="input110" matInput class="wblue black" 
                            [(ngModel)]="jsnTeam['contribution']">
                    </div>
                </div>
                <div class="divTableRow">
                    <div class="divTableCell textoInput2 black">{{fnObtenerLabel('21')}}</div>
                    <div class="divTableCell textoInput" *ngFor="let jsnTeam of arrColumnas, let i = index">
                        <input (change)="fnChangeInput(i)"
                            type="number" id="input110" matInput class="w black" 
                            [(ngModel)]="jsnTeam['factory']">
                    </div>
                </div>
                <div class="divTableRow">
                    <div class="divTableCell textoInput2 style6 black">{{fnObtenerLabel('26')}}</div>
                    <div class="divTableCell textoInput style6" *ngFor="let jsnTeam of arrColumnas, let i = index">
                        <input (change)="fnChangeInput(i)"
                            type="number" id="input110" matInput class="wblue black" 
                            [(ngModel)]="jsnTeam['gross']">
                    </div>
                </div>
                <div class="divTableRow">
                    <div class="divTableCell textoInput2 black">{{fnObtenerLabel('27')}}</div>
                    <div class="divTableCell textoInput" *ngFor="let jsnTeam of arrColumnas, let i = index">
                        <input (change)="fnChangeInput(i)"
                            type="number" id="input110" matInput class="w black" 
                            [(ngModel)]="jsnTeam['selling']">
                    </div>
                </div>
                <div class="divTableRow">
                    <div class="divTableCell textoInput2 style6 black">{{fnObtenerLabel('35')}}</div>
                    <div class="divTableCell textoInput style6" *ngFor="let jsnTeam of arrColumnas, let i = index">
                        <input (change)="fnChangeInput(i)"
                            type="number" id="input110" matInput class="wblue black" 
                            [(ngModel)]="jsnTeam['operating']">
                    </div>
                </div>
                <div class="divTableRow">
                    <div class="divTableCell textoInput2 black">{{fnObtenerLabel('39')}}</div>
                    <div class="divTableCell textoInput" *ngFor="let jsnTeam of arrColumnas, let i = index">
                        <input (change)="fnChangeInput(i)"
                            type="number" id="input110" matInput class="w black" 
                            [(ngModel)]="jsnTeam['interest']">
                    </div>
                </div>
                <div class="divTableRow">
                    <div class="divTableCell textoInput2 style6 black">{{fnObtenerLabel('40')}}</div>
                    <div class="divTableCell textoInput style6" *ngFor="let jsnTeam of arrColumnas, let i = index">
                        <input (change)="fnChangeInput(i)"
                            type="number" id="input110" matInput class="wblue black" 
                            [(ngModel)]="jsnTeam['income']">
                    </div>
                </div>
                <div class="divTableRow">
                    <div class="divTableCell textoInput2 black">{{fnObtenerLabel('41')}}</div>
                    <div class="divTableCell textoInput" *ngFor="let jsnTeam of arrColumnas, let i = index">
                        <input (change)="fnChangeInput(i)"
                            type="number" id="input110" matInput class="w black" 
                            [(ngModel)]="jsnTeam['taxes']">
                    </div>
                </div>
                <div class="divTableRow">
                    <div class="divTableCell textoInput2 style7b black">{{fnObtenerLabel('42')}}</div>
                    <div class="divTableCell textoInput style7b" *ngFor="let jsnTeam of arrColumnas, let i = index">
                        <input (change)="fnChangeInput(i)"
                            type="number" id="input110" matInput class="wblue black" 
                            [(ngModel)]="jsnTeam['netIncome']">
                    </div>
                </div>
            </div>
        </div>

        <div class="divTable" style="margin-top: 30px;">
            <div class="divTableBody">
                <div class="divTableRow">                    
                    <div class="divTableCell textoInput2 style4">Balance Sheet Fields</div>
                    <div class="divTableCell style4" style="text-align: center;" *ngFor="let jsnTeam of arrColumnasBalance, let i = index">Month {{i+1}}</div>
                </div>
            </div>
            <div class="divTableRow">
                <div class="divTableCell textoInput2 black">{{fnObtenerLabelBalance('47')}}</div>
                <div class="divTableCell textoInput" *ngFor="let jsnTeam of arrColumnasBalance, let i = index">
                    <input (change)="fnChangeInputBalance(i)"
                        type="number" id="input110" matInput class="w black" 
                        [(ngModel)]="jsnTeam['cash']">
                </div>
            </div>
            <div class="divTableRow">
                <div class="divTableCell textoInput2 black">{{fnObtenerLabelBalance('48')}}</div>
                <div class="divTableCell textoInput" *ngFor="let jsnTeam of arrColumnasBalance, let i = index">
                    <input (change)="fnChangeInputBalance(i)"
                        type="number" id="input110" matInput class="w black" 
                        [(ngModel)]="jsnTeam['receivables']">
                </div>
            </div>
            <div class="divTableRow">
                <div class="divTableCell textoInput2 black">{{fnObtenerLabelBalance('52')}}</div>
                <div class="divTableCell textoInput" *ngFor="let jsnTeam of arrColumnasBalance, let i = index">
                    <input (change)="fnChangeInputBalance(i)"
                        type="number" id="input110" matInput class="w black" 
                        [(ngModel)]="jsnTeam['finishGood']">
                </div>
            </div>
            <div class="divTableRow">
                <div class="divTableCell textoInput2 black">{{fnObtenerLabelBalance('53')}}</div>
                <div class="divTableCell textoInput" *ngFor="let jsnTeam of arrColumnasBalance, let i = index">
                    <input (change)="fnChangeInputBalance(i)"
                        type="number" id="input110" matInput class="w black" 
                        [(ngModel)]="jsnTeam['workProcess']">
                </div>
            </div>
            <div class="divTableRow">
                <div class="divTableCell textoInput2 black">{{fnObtenerLabelBalance('57')}}</div>
                <div class="divTableCell textoInput" *ngFor="let jsnTeam of arrColumnasBalance, let i = index">
                    <input (change)="fnChangeInputBalance(i)"
                        type="number" id="input110" matInput class="w black" 
                        [(ngModel)]="jsnTeam['rawMaterials']">
                </div>
            </div>
            <div class="divTableRow">
                <div class="divTableCell textoInput2 style6 black">{{fnObtenerLabelBalance('59')}}</div>
                <div class="divTableCell textoInpu style6" *ngFor="let jsnTeam of arrColumnasBalance, let i = index">
                    <input (change)="fnChangeInputBalance(i)"
                        type="number" id="input110" matInput class="wblue black" 
                        [(ngModel)]="jsnTeam['totalCurrent1']">
                </div>
            </div>
            <div class="divTableRow">
                <div class="divTableCell textoInput2 black">{{fnObtenerLabelBalance('23.1')}}</div>
                <div class="divTableCell textoInput" *ngFor="let jsnTeam of arrColumnasBalance, let i = index">
                    <input (change)="fnChangeInputBalance(i)"
                        type="number" id="input110" matInput class="w black" 
                        [(ngModel)]="jsnTeam['machine']">
                </div>
            </div>
            <div class="divTableRow">
                <div class="divTableCell textoInput2 black">{{fnObtenerLabelBalance('61')}}</div>
                <div class="divTableCell textoInput" *ngFor="let jsnTeam of arrColumnasBalance, let i = index">
                    <input (change)="fnChangeInputBalance(i)"
                        type="number" id="input110" matInput class="w black" 
                        [(ngModel)]="jsnTeam['land']">
                </div>
            </div>
            <div class="divTableRow">
                <div class="divTableCell textoInput2 style6 black">{{fnObtenerLabelBalance('64')}}</div>
                <div class="divTableCell textoInpu style6" *ngFor="let jsnTeam of arrColumnasBalance, let i = index">
                    <input (change)="fnChangeInputBalance(i)"
                        type="number" id="input110" matInput class="wblue black" 
                        [(ngModel)]="jsnTeam['totalFixed']">
                </div>
            </div>
            <div class="divTableRow">
                <div class="divTableCell textoInput2 style7b black">{{fnObtenerLabelBalance('65')}}</div>
                <div class="divTableCell textoInpu style7b" *ngFor="let jsnTeam of arrColumnasBalance, let i = index">
                    <input (change)="fnChangeInputBalance(i)"
                        type="number" id="input110" matInput class="wblue black" 
                        [(ngModel)]="jsnTeam['totalCurrent2']">
                </div>
            </div>
            <div class="divTableRow">
                <div class="divTableCell textoInput2 black">{{fnObtenerLabelBalance('67')}}</div>
                <div class="divTableCell textoInput" *ngFor="let jsnTeam of arrColumnasBalance, let i = index">
                    <input (change)="fnChangeInputBalance(i)"
                        type="number" id="input110" matInput class="w black" 
                        [(ngModel)]="jsnTeam['payables']">
                </div>
            </div>
            <div class="divTableRow">
                <div class="divTableCell textoInput2 black">{{fnObtenerLabelBalance('69')}}</div>
                <div class="divTableCell textoInput" *ngFor="let jsnTeam of arrColumnasBalance, let i = index">
                    <input (change)="fnChangeInputBalance(i)"
                        type="number" id="input110" matInput class="w black" 
                        [(ngModel)]="jsnTeam['loans']">
                </div>
            </div>
            <div class="divTableRow">
                <div class="divTableCell textoInput2 black">{{fnObtenerLabelBalance('78')}}</div>
                <div class="divTableCell textoInput" *ngFor="let jsnTeam of arrColumnasBalance, let i = index">
                    <input (change)="fnChangeInputBalance(i)"
                        type="number" id="input110" matInput class="w black" 
                        [(ngModel)]="jsnTeam['taxes']">
                </div>
            </div>
            <div class="divTableRow">
                <div class="divTableCell textoInput2 style6 black">{{fnObtenerLabelBalance('71')}}</div>
                <div class="divTableCell textoInpu style6" *ngFor="let jsnTeam of arrColumnasBalance, let i = index">
                    <input (change)="fnChangeInputBalance(i)"
                        type="number" id="input110" matInput class="wblue black" 
                        [(ngModel)]="jsnTeam['totalLiabilities']">
                </div>
            </div>
            <div class="divTableRow">
                <div class="divTableCell textoInput2 black">{{fnObtenerLabelBalance('73')}}</div>
                <div class="divTableCell textoInpu" *ngFor="let jsnTeam of arrColumnasBalance, let i = index">
                    <input (change)="fnChangeInputBalance(i)"
                        type="number" id="input110" matInput class="wblue black" 
                        [(ngModel)]="jsnTeam['capital']">
                </div>
            </div>
            <div class="divTableRow">
                <div class="divTableCell textoInput2 black">{{fnObtenerLabelBalance('74')}}</div>
                <div class="divTableCell textoInpu" *ngFor="let jsnTeam of arrColumnasBalance, let i = index">
                    <input (change)="fnChangeInputBalance(i)"
                        type="number" id="input110" matInput class="wblue black" 
                        [(ngModel)]="jsnTeam['retained']">
                </div>
            </div>
            <div class="divTableRow">
                <div class="divTableCell textoInput2 style6 black">{{fnObtenerLabelBalance('75')}}</div>
                <div class="divTableCell textoInpu style6" *ngFor="let jsnTeam of arrColumnasBalance, let i = index">
                    <input (change)="fnChangeInputBalance(i)"
                        type="number" id="input110" matInput class="wblue black" 
                        [(ngModel)]="jsnTeam['totalShareholder']">
                </div>
            </div>
            <div class="divTableRow">
                <div class="divTableCell textoInput2 style7b black">{{fnObtenerLabelBalance('3024')}}</div>
                <div class="divTableCell textoInpu style7b" *ngFor="let jsnTeam of arrColumnasBalance, let i = index">
                    <input (change)="fnChangeInputBalance(i)"
                        type="number" id="input110" matInput class="wblue black"  
                        [(ngModel)]="jsnTeam['totalLiabilitiesAndEquity']">
                </div>
            </div>
        </div>
        <!--<div class="row">
            <div class="col-12 text-right" style="padding-right: 8%;">
              
                
                <button *ngIf="!blnFacilitador" class="btn btn-primary mx-3 btn-ok" 
                [disabled]="!blnIsColorSelected || teamForm.invalid"
                type="submit" (click)="fnSaveTeam()">&nbsp;&nbsp;{{getSingleTextLabel('136')}}&nbsp;&nbsp;</button>
                <button *ngIf="blnFacilitador" class="btn btn-primary mx-3 btn-ok" 
                [disabled]="!blnIsColorSelected || teamForm.invalid"
                type="submit" (click)="fnSaveTeamFacilitator()">&nbsp;&nbsp;{{getSingleTextLabel('136')}}&nbsp;&nbsp;</button>
                <button class="btn btn-secondary mx-3 FontMontserratTextInputsCancel"
                (click)="fnCancel()">&nbsp;{{getSingleTextLabel('123')}}&nbsp;</button>
            </div>
        </div>-->
        <ngx-spinner bdColor="rgba(0,0,0,0.75)" size="large" color="#ffffff" type="ball-clip-rotate-multiple"
            [fullScreen]="true">
            <p style="color: white"> Loading... </p>
        </ngx-spinner>
    </div>
</div>
