/// General Information
/// new-language.component.ts : < Componente creado para crear un nuevo lenguaje mostrados para las labels de los Boards y reportes>
///
///
/// Author:
///   <Daniel de la Cruz Marcial>
///
/// Copyright(C) <Linkthinks>
///
///

//#region Imports
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import Swal from 'sweetalert2';
import { EditlabelsComponent } from '../../views/editlabels/editlabels.component';
import { LabelsService } from './../../services/label/labels.service';
import { LanguageService } from './../../services/language/language.service';
import { environment } from './../../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
////#endregion

@Component({
  selector: 'app-newlanguage',
  templateUrl: './newlanguage.component.html',
  styleUrls: ['./newlanguage.component.scss'],
  styles: [`.modal{background: rgba(0,0,0, .5);`],
})
export class NewlanguageComponent implements OnInit {
  //#region Variables
  dialogRef: any;
  newIdLanguage = 0;
  display = 'none';
  lblCurrentViewEdit = '';
  languageSeleccionado = [];
  lblplaceholderlstlanguages: string = 'Select some language';
  lstLanguages = []; // {id: 2,name:"Spanish"}
  // lstLabelsToEdit=[];
  /*dropDownSettingsLng = {
    singleSelection: true,
    idField: 'LanguageId',
    textField: 'Language',
    selectAllText: 'Seleccionar Todo',
    unSelectAllText: 'Deseleccionar Todo',
    //allowSearchFilter: true,
    searchPlaceholderText: 'Buscar...',
    closeDropDownOnSelection: true
  };*/

  lblTeamMembers: string = 'Team members';
  lblName: string = 'Name';
  lblEmail: string = 'Email';
  lblActions: string = 'Actions';
  lblTooltipEditarUsuario = 'Edit User';
  lblTooltipBorrarUsuario = 'Delete User';

  titulos: string[] = ['name', 'email', 'actions'];
  tabSelected: string = '0';
  strTypeView = null;
  lstViewBoard = [];
  lstViewSetUp = [];
  lstNotesFinStatem = [];
  lstGENERALBack = [];
  lstViewMonthlyDebriefResults = [];
  lstViewChecklist = [];
  lstViewMarkets = [];
  lstViewLearningSlides = [];
  lstViewRulesImprovOpt = [];
  lstViewWorkingCopyImprovOpt = [];
  lstViewMessages = [];
  isPrivate = 1;
  blnisAdminUser=false;
  lstDataPivote = [];
  // isEdit= true;
  @ViewChild(EditlabelsComponent, { static: false })
  viwChildEditLabels: EditlabelsComponent;
  //#endregion

  constructor(
    private languageService: LanguageService,
    private labelService: LabelsService,
    private spinner: NgxSpinnerService,
    private _matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    console.log('iniciamos carga:environment.type:',environment.type);
    if(environment.type == 1){
      this.blnisAdminUser= true;
    }
    // this.fnChargeLanguage();
    // this.lstLanguages.push({id: 1,name:"English"})
    // this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = 'yourColor';
  }

  fnChargeLanguagebyId(idLanguage) {
    this.lstGENERALBack = [];
    console.log('language select::', 1);
    this.languageService
      .getLabelsByLanguageId(idLanguage)
      .subscribe((response) => {
        this.spinner.show();
        console.log('reponse labels:: ', response);
        if (response['intResponse'] == 200) {
          this.lstGENERALBack = response['jsnAnswer'];
          this.fnSetListViews();
        } else {
        }
        this.spinner.hide();
        // this.lstLanguages = response['jsnAnswer'];
      });
  }


  fnResetListViews() {
    this.lstViewBoard = [];
    this.lstViewSetUp = [];
    this.lstNotesFinStatem = [];
    this.lstViewMonthlyDebriefResults = [];
    this.lstViewChecklist = [];
    this.lstViewMarkets = [];
    this.lstViewLearningSlides = [];
    this.lstViewRulesImprovOpt = [];
    this.lstViewWorkingCopyImprovOpt = [];
    return true;
  }

  async fnSetListViews() {
    const ready = await this.fnResetListViews();
    //hacemos el procesamiento necesario para reemplazar las keys de las de palabras clave
    /******************************************************************************************************************************* */
   
    this.lstDataPivote = this.lstGENERALBack;


    this.lstDataPivote.forEach(elementIterative => {

      this.lstGENERALBack.forEach((elementChange, index) => {
        if(elementChange.LabelKeywords != ''){
           if(elementChange.LabelKeywords?.includes(elementIterative.LabelCode)){
             var str1Change = "";
             str1Change =  elementIterative.LabelCode+"0";
             var str2Change = "";
             str2Change =  elementIterative.LabelCode+"1";
             this.lstGENERALBack[index].LabelKeywords = elementChange.LabelKeywords.replace(str1Change , elementIterative.LabelBase);
             this.lstGENERALBack[index].LabelKeywords = elementChange.LabelKeywords.replace(str2Change , elementIterative.Label);
             this.lstGENERALBack[index].LabelBase = elementChange.LabelBase.replace(elementIterative.LabelCode,elementIterative.LabelBase )
           // this.lstGENERALBack[index]=elementChange;
           }
        }
      });
      
    });
console.log("Resultado formateo::",this.lstGENERALBack);

    /******************************************************************************************************************************* */
    this.lstViewBoard = this.lstGENERALBack.filter((el) => {
      if (el.ScreenId == 1) {
        // view board
        return el;
      }
    });
    // console.log("this.lstViewBoard",this.lstViewBoard);
    this.lstViewSetUp = this.lstGENERALBack.filter((el) => {
      if (el.ScreenId == 2) {
        // view setup
        return el;
      }
    });

    this.lstNotesFinStatem = this.lstGENERALBack.filter((el) => {
      if (el.ScreenId == 3) {
        // view market customers
        return el;
      }
    });

    this.lstViewChecklist = this.lstGENERALBack.filter((el) => {
      if (el.ScreenId == 4) {
        // view income statement
        return el;
      }
    });

    this.lstViewMonthlyDebriefResults = this.lstGENERALBack.filter((el) => {
      if (el.ScreenId == 5) {
        // view income statement
        return el;
      }
    });
    this.lstViewMarkets = this.lstGENERALBack.filter((el) => {
      if (el.ScreenId == 6) {
        // view ratios slides
        return el;
      }
    });
    this.lstViewLearningSlides = this.lstGENERALBack.filter((el) => {
      if (el.ScreenId == 7) {
        // brief tab
        return el;
      }
    });
    this.lstViewRulesImprovOpt = this.lstGENERALBack.filter((el) => {
      if (el.ScreenId == 8) {
        // public info
        return el;
      }
    });
    this.lstViewWorkingCopyImprovOpt = this.lstGENERALBack.filter((el) => {
      if (el.ScreenId == 9) {
        // results
        return el;
      }
    });
    this.lstViewMessages = this.lstGENERALBack.filter((el) => {
      if (el.ScreenId == 10) {
        // results
        return el;
      }
    });
    console.log('listas obtenidas', this.lstViewBoard, this.lstViewSetUp);
  }

  fnSaveNew(lstDataEditedok) {
    console.log('**********regreso de modal y obtengo: ', lstDataEditedok);
    console.log('this.newIdLanguage::', this.newIdLanguage);
    if (lstDataEditedok.edit) {
      // this.LabelService.createLabels({dataUpd: lstDataEditedok, languageId: this.newIdLanguage }).subscribe(response => {
      this.labelService
        .updateLabel({
          dataUpd: lstDataEditedok.arrdata,
        })
        .subscribe((response) => {
          console.log(response);
          if (response.intResponse == 200) {
            this.fnChargeLanguagebyId(lstDataEditedok.idlang);

            Swal.fire({
              text: '',
              imageUrl: '../../../../assets/iconSuccessfully.svg',
              imageAlt: 'icon successfully',
              confirmButtonColor: '#6FB45C',
              html:
                '<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> 128.- The label list has been updated successfully </label>',
            });
          } else {
            Swal.fire('Some wrong!', '129.- The action is not complete', 'error');
          }
        });
    } else {
      //validar si es necesario descomentar el refresh si no hizo cambiothis.fnChargeLanguagebyId(lstDataEditedok.idlang);
    }
  }

  fnNewLanguage() {
    const valueEdited = (document.getElementById(
      'txtDescLanguage'
    ) as HTMLInputElement).value;
    console.log('valueDesc', valueEdited);
    if (valueEdited == '') {
      /* Swal.fire(
          'Must be fill entry of Name Laguage!',
          'The action is not complete',
          'error'
        );
*/
      Swal.fire({
        text: '',
        imageUrl: '../../../../assets/iconPrevention.svg',
        confirmButtonColor: '#6FB45C',
        html:
          '<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> 130.- the action cannot be completed, must be fill entry of Name Laguage!!</label>',
      });
      return;
    }
    this.labelService
      .createLanguage({ languageDesc: valueEdited,idDistributor:environment.DistributorID, isPrivate: this.isPrivate})
      .subscribe((response) => {
        console.log('response create language--------', response);
        if (response['intResponse'] == 200) {
          this.newIdLanguage = response['idLanguage'];
          sessionStorage.setItem("isOwnerLanguage", "1");
          this.fnChargeLanguagebyId(this.newIdLanguage);
          /*Swal.fire(
            'Done!',
            'The language has been create successfully',
            'success'
          );*/
          Swal.fire({
            text: '',
            imageUrl: '../../../../assets/iconSuccessfully.svg',
            imageAlt: 'icon successfully',
            confirmButtonColor: '#6FB45C',
            html:
              '<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> 131.- The language has been create successfully </label>',
          }).then((result) => {
            Swal.fire({
              html:
              '<style>swal2-confirm{border-radius: 50px !important; } swal2-styled{border-radius: 50px !important; }</style><label  style="font-size: 22px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> 132.- Suggestion: Start working with the module "Game Board & Fin Statements" </label>',
              imageUrl: '../../../../assets/iconPrevention.svg',
              imageAlt: 'icon Prevention',
              confirmButtonColor: '#6FB45C',
            });
          });
        } else {
          Swal.fire('Some wrong!', '129.- The action is not complete', 'error');
        }
      });
  }

  async fnModalEdit(value) {
    this.strTypeView = value;
    const arrViewList = await this.fnGetListView(value);
    this.dialogRef = this._matDialog.open(EditlabelsComponent, {
      disableClose: true,
      width: '1400px',
      height: '600px',
      panelClass: 'event-form-dialog',
      data: {
        strTypeView: this.strTypeView,
        arrViewList,
        val: value,
        bol: true,
        intIdLangGlobal: this.newIdLanguage,
      },
    });

    this.dialogRef.afterClosed().subscribe((resp) => {
      console.log('CLOSED MODAL', resp);
      if (!resp.close) {
        this.fnSaveNew(resp);
      }
    });
  }

  fnGetListView(value) {
    console.log('entro fn edit this.lstGENERALBack::', this.lstGENERALBack);
    /*actualizamos las listas con lo que tenga la variable global */
    // filtramos las etiquetas por vista

    let arrView;
    switch (value) {
      case 'Game Board & Fin Statements':
        arrView = this.lstViewBoard;
        break;
      case 'SetUp':
        arrView = this.lstViewSetUp;
        break;
      case 'Notes on Financial Statements':
        arrView = this.lstNotesFinStatem;
        break;
      case 'Checklist':
        arrView = this.lstViewChecklist;
        break;
      case 'Monthly Debrief/Results':
        arrView = this.lstViewMonthlyDebriefResults;
        break;
      case 'Markets':
        arrView = this.lstViewMarkets;
        break;
      case 'Learning Slides':
        arrView = this.lstViewLearningSlides;
        break;
      case 'Improvement Options':
        arrView = this.lstViewRulesImprovOpt;
        break;
      case 'Rules':
        arrView = this.lstViewWorkingCopyImprovOpt;
        break;
      case 'Messages':
        arrView = this.lstViewMessages;
        break;
      default:
        arrView = this.lstViewBoard;
        break;
    }
    return arrView;
  }

  
  radioChangetypeLang(event){
    console.log("radioChangetypeLang",event);
    this.isPrivate= event.value;
  
  }

}
