/// General Information
/// language.component.ts : < Componente creado para la administración de los lenguages mostrados en los Boards y reportes>
///
///
/// Author:
///   <Daniel de la Cruz Marcial>
///
/// Copyright(C) <Linkthinks>
///
///

//#region Imports
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';
//import { threadId } from 'node:worker_threads';
import Swal from 'sweetalert2';
import { EditlabelsComponent } from '../../views/editlabels/editlabels.component';
import { LabelsService } from './../../services/label/labels.service';
import { LanguageService } from './../../services/language/language.service';
import { environment } from './../../../environments/environment';
import { DistributorService } from './../../services/distributor/distributor.service';
import { element } from 'protractor';
////#endregion

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss'],
  styles: [`.modal{background: rgba(0,0,0, .5);`],
})
export class LanguagesComponent implements OnInit {
  //#region Variables
  dialogRef: any;
  display = 'none';
  lblCurrentViewEdit = '';
  languageSeleccionado = [];
  languageSeleccionadoOrg=[];
  languageSeleccionadoIntern = [];
  languageSeleccionadoPrivate = [];
  DistSeleccionado = [];
  lblplaceholderlstlanguages: string = 'Select some language';
  lblplaceholderlstOrganization: string = 'Select an Organization';
  lstLanguagesInternationalAux = [];
  lstLanguagesPrivateAux = [];
  languageName: string = '';
  lstLanguages = []; // {id: 2,name:"Spanish"}
  lstLanguagesOrganization = [];
  lstLangInternationals=[];
  ratioSelected = "1";
  // lstLabelsToEdit=[];
  dropDownSettingsLng = {
    singleSelection: true,
    idField: 'LanguageId',
    textField: 'Language',
    selectAllText: 'Seleccionar Todo',
    unSelectAllText: 'Deseleccionar Todo',
    allowSearchFilter: true,
    searchPlaceholderText: 'Search...',
    closeDropDownOnSelection: true,
  };
  dropDownSettingsLngPrivate = {
    singleSelection: true,
    idField: 'LanguageId',
    textField: 'Language',
    selectAllText: 'Seleccionar Todo',
    unSelectAllText: 'Deseleccionar Todo',
    allowSearchFilter: true,
    searchPlaceholderText: 'Search...',
    closeDropDownOnSelection: true,
  };
  dropDownSettingsDist = {
    singleSelection: true,
    idField: 'DistributorID',
    textField: 'CompanyName',
    selectAllText: 'Seleccionar Todo',
    unSelectAllText: 'Deseleccionar Todo',
    allowSearchFilter: true,
    searchPlaceholderText: 'Search...',
    closeDropDownOnSelection: true,
  };

  dropDownSettingsLngOrg = {
    singleSelection: true,
    idField: 'LanguageId',
    textField: 'Language',
    selectAllText: 'Seleccionar Todo',
    unSelectAllText: 'Deseleccionar Todo',
    allowSearchFilter: true,
    searchPlaceholderText: 'Search...',
    closeDropDownOnSelection: true,
  };
  dropDownSettingsLngInter = {
    singleSelection: true,
    idField: 'LanguageId',
    textField: 'Language',
    selectAllText: 'Seleccionar Todo',
    unSelectAllText: 'Deseleccionar Todo',
    allowSearchFilter: true,
    searchPlaceholderText: 'Search...',
    closeDropDownOnSelection: true,
  };

  lblTeamMembers: string = 'Team members';
  lblName: string = 'Name';
  lblEmail: string = 'Email';
  lblActions: string = 'Actions';
  lblTooltipEditarUsuario = 'Edit User';
  lblTooltipBorrarUsuario = 'Delete User';

  titulos: string[] = ['name', 'email', 'actions'];
  tabSelected: string = '0';
  strTypeView = null;
  lstViewBoard = [];
  lstViewSetUp = [];
  lstNotesFinStatem = [];
  lstGENERALBack = [];
  lstViewMonthlyDebriefResults = [];
  lstViewChecklist = [];
  lstViewMarkets = [];
  lstViewLearningSlides = [];
  lstViewServicesVersion = [];
  lstViewRulesImprovOpt = [];
  lstViewWorkingCopyImprovOpt = [];
  lstViewMessages = [];
  intIdLangGlobal = 0;
  txtLanguageSelected="";
  blnisAdminUser=false;
  blnisfacilitator=false;
  isPrivate = 3;
  lblTitleAndromeda= "Andromeda Languages";
  lblDistributors = "Organizations";
  lblTitleLangOrgs = "Languages";
  lstDistributors=[];
  lstDataPivote = [];
  lstChange=[];
  blnShowbtnCopy=false;
  blnShowUpdate = false;
  blnChangeddlAndromeda = true;
  blnShowDelete = false;
  isSuperAdmin = false;
  
  // @ViewChild(EditlabelsComponent, {static: false}) viwChildEditLabels : EditlabelsComponent;
  //#endregion

  constructor(
    private languageService: LanguageService,
    private labelService: LabelsService,
    private spinner: NgxSpinnerService,
    private _matDialog: MatDialog,
    private DistributorService: DistributorService
  ) {}

  ngOnInit(): void {
   
    console.log('iniciamos carga:');
    this.fnChargeLanguages();
    console.log(environment);
    if(environment.type == 1){
      this.blnisAdminUser= true;
      this.blnisfacilitator = false;
      this.lblTitleAndromeda = "Andromeda Languages";
      this.fnChargeDistributors();
    }else if(environment.type == 2)
    {
      this.blnisAdminUser= false;
      this.blnisfacilitator = true;
      this.lblTitleAndromeda = "International Languages";

    }else{
      this.blnisAdminUser= false;
      this.blnisfacilitator=false;
      this.lblTitleAndromeda = "International Languages";
      this.fnChargeLanguagesInternationals();
    }
    // this.lstLanguages.push({id: 1,name:"English"})
    // this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = 'yourColor';
    console.log(environment.UserID, environment.idsChiefAdmin[0])
    if(environment.UserID == environment.idsChiefAdmin[0]){
      this.isSuperAdmin = true;
    }
    if(environment.DistributorID != 1){
      this.blnShowDelete = true;
    }
    console.log(this.isSuperAdmin)
  }

  fnChargeLanguages() {
    this.languageService.getAllLanguages(environment.DistributorID,1, 1).subscribe((response) => {
      console.log('reponse getAllDistributors', response);
      /*damos fomrato de descripcion + (tipo) */
      var tmpLang = response.jsnAnswer;
      this.lstLanguages = response.jsnAnswer;
      tmpLang.forEach((element, index) => {
        // element.Language = element.Language + " ("+ (element.isPrivate == 1 ? "Private":"International") + ")";
        element.Language = element.Language + " - ("+element.NumLabels+")"
        this.lstLanguages[index] = element;
      });
      this.lstLanguagesPrivateAux = this.lstLanguages.filter(x => x.isPrivate == 1);
      this.lstLanguagesInternationalAux = this.lstLanguages.filter(x => x.isPrivate == 0);
      console.log(this.lstLanguages);
      console.log(this.lstLanguagesPrivateAux, this.lstLanguagesInternationalAux);
    });
  }

  fnChargeLanguagesInternationals() {
    this.languageService.getAllLanguages(environment.DistributorID,0, 0).subscribe((response) => {
      console.log('reponse getAllDistributors', response);
      /*damos fomrato de descripcion + (tipo) */
      setTimeout(() => {
        if(this.lstLanguagesInternationalAux.length>0){
          console.log("Añade")
          this.lstLanguagesInternationalAux = [...this.lstLanguagesInternationalAux, ...response.jsnAnswer];
        }else{
          console.log("Pon")

          this.lstLanguagesInternationalAux = response.jsnAnswer;

        }

        this.lstLangInternationals = response.jsnAnswer;
        console.log(this.lstLanguagesInternationalAux,this.lstLangInternationals);
      }, 1100);
      
      
      
    });
  }

  fnChargeDistributors() {
    this.DistributorService.getAllDistributors().subscribe((response) => {
      console.log('reponse getAllDistributors', response);
      this.lstDistributors = response.distributors;
      
      this.lstDistributors.forEach(element =>{
        element.CompanyName = element.CompanyName + " (" + element.NumberOfPrivate  +")"
      })
    });
  }

  fnSelectDistributor(distribSelect) {
    this.lstLanguagesOrganization = [];
    //console.log('language select:', distribSelect);
    //this.languageService.getAllLanguages(1,1, 1).subscribe((response) => {
    this.languageService.getAllLanguages(distribSelect.DistributorID,1, 1).subscribe((response) => {
      console.log('reponse getAllDistributors', response);
      response.jsnAnswer.for
      this.lstLanguagesOrganization = response.jsnAnswer;
      this.lstLanguagesOrganization.forEach(x =>{
        if(x.Language.includes('(Master)')){
          x.Language = "English";
        }
      })
      this.lstLanguagesOrganization = this.lstLanguagesOrganization.filter(x => x.isPrivate == 1);
    });
        
        
  }

  fnSelectDistributorbyModel(distribSelect) {
    this.lstLanguagesOrganization = [];
    console.log('language select', distribSelect);
    //this.languageService.getAllLanguages(1,1, 1).subscribe((response) => {
    this.languageService.getAllLanguages(distribSelect[0].DistributorID,1, 1).subscribe((response) => {
      console.log('reponse getAllDistributors', response);
      this.lstLanguagesOrganization = response.jsnAnswer;
    });
        
        
  }


  fnChangeLanguage(language) {
    console.log(language)
    this.lstGENERALBack = [];
    this.blnChangeddlAndromeda = true;
    sessionStorage.setItem("isOwnerLanguage", "1");
    this.intIdLangGlobal = this.languageSeleccionado[0].LanguageId;
    /*obtenemos el tipo para setearlo en el switch */
    var tmpSelectedLang = this.lstLanguagesInternationalAux.filter((obj) => {
      if (obj.LanguageId == this.languageSeleccionado[0].LanguageId) {
        return obj;
      } 
    }); 
    this.isPrivate = tmpSelectedLang.length > 0 ? tmpSelectedLang[0].isPrivate : 3;
    console.log('language select', language);
    this.txtLanguageSelected=language.Language.replace("(Private)", "").replace("(International)","").replace(/-\s*\(\d+\)/, "");
    this.languageService
      .getLabelsByLanguageId(language.LanguageId)
      .subscribe((response) => {
        console.log('reponse labels ', response);
        if (response['intResponse'] == 200) {
          this.lstGENERALBack = response['jsnAnswer'];
          let aux = this.lstLanguagesInternationalAux.filter(x => x['LanguageId'] == this.intIdLangGlobal)
          console.log(aux);
          if (aux.length > 0) {
            console.log(aux[0]['DistributorID'],environment.DistributorID );

            if (aux[0]['DistributorID'] == environment.DistributorID) {
              this.blnShowUpdate = true;
              this.blnShowbtnCopy = false;

            }else{
              this.blnShowUpdate = false;
            this.blnShowbtnCopy = true;
            }
          } else {
            this.blnShowUpdate = false;
            this.blnShowbtnCopy = true;

          }
          // filtramos las etiquetas por vista
          this.fnSetListViews();
          this.lstViewSetUp = response['jsnAnswer'].filter((el) => {
            if (el.ScreenId == 2) {
              // view setup
              return el;
            }
          });
          /* this.lstViewSetUpAUX = response['jsnAnswer'].filter(el=>{
            if(el.ScreenId == 2)//view setup
              return el;
          });*/
          console.log('this.lstViewSetUp', this.lstViewSetUp);
        } else {
        }
        // this.lstLanguages = response['jsnAnswer'];
      });
  }
  fnChangeLanguagePrivate(language){
    console.log(language)
    this.lstGENERALBack = [];
    this.blnChangeddlAndromeda = true;
    sessionStorage.setItem("isOwnerLanguage", "1");
    this.intIdLangGlobal = this.languageSeleccionadoPrivate[0].LanguageId;
    /*obtenemos el tipo para setearlo en el switch */
    var tmpSelectedLang = this.lstLanguagesPrivateAux.filter((obj) => {
      if (obj.LanguageId == this.languageSeleccionadoPrivate[0].LanguageId) {
        return obj;
      } 
    }); 
    this.isPrivate = tmpSelectedLang.length > 0 ? tmpSelectedLang[0].isPrivate : 3;
    console.log('language select', language);
    this.txtLanguageSelected=language.Language.replace("(Private)", "").replace("(International)","").replace(/-\s*\(\d+\)/, "");
    this.languageService
      .getLabelsByLanguageId(language.LanguageId)
      .subscribe((response) => {
        console.log('reponse labels ', response);
        if (response['intResponse'] == 200) {
          this.lstGENERALBack = response['jsnAnswer'];
          this.blnShowbtnCopy=false;
          this.blnShowUpdate = true;
          // filtramos las etiquetas por vista
          this.fnSetListViews();
          this.lstViewSetUp = response['jsnAnswer'].filter((el) => {
            if (el.ScreenId == 2) {
              // view setup
              return el;
            }
          });
          /* this.lstViewSetUpAUX = response['jsnAnswer'].filter(el=>{
            if(el.ScreenId == 2)//view setup
              return el;
          });*/
          console.log('this.lstViewSetUp', this.lstViewSetUp);
        } else {
        }
        // this.lstLanguages = response['jsnAnswer'];
      });
  }
  fnChargeLanguagesOfOrganization(language) {
    this.intIdLangGlobal = language.LanguageId;
    console.log('language select', language);
    this.blnChangeddlAndromeda = false;
    sessionStorage.setItem("isOwnerLanguage", "0");
    if(this.blnisAdminUser){
        this.txtLanguageSelected=language.Language.replace("(Private)", "").replace("(International)","");
      
      /*obtenemos el tipo para setearlo en el switch */
      var tmpSelectedLang = this.lstLanguagesOrganization.filter((obj) => {
        if (obj.LanguageId == language.LanguageId) {
          return obj;
        } 
      }); 
    }
    this.isPrivate = tmpSelectedLang.length > 0 ? tmpSelectedLang[0].isPrivate : 3;
    this.languageService
      .getLabelsByLanguageId(language.LanguageId)
      .subscribe((response) => {
        console.log('reponse labels ', response);
        if (response['intResponse'] == 200) {
          this.lstGENERALBack = response['jsnAnswer'];
          this.blnShowbtnCopy=true;
          this.blnShowUpdate = false;
          // filtramos las etiquetas por vista
          this.fnSetListViews();
          this.lstViewSetUp = response['jsnAnswer'].filter((el) => {
            if (el.ScreenId == 2) {
              // view setup
              return el;
            }
          });
          console.log('this.lstViewSetUp', this.lstViewSetUp);
        } else {
        }
      });
  }

  fnChargeLanguagesInternational(language) {
    this.intIdLangGlobal = language.LanguageId;
    console.log('language select', language);
    sessionStorage.setItem("isOwnerLanguage", "0");
    this.txtLanguageSelected=language.Language.replace("(Private)", "").replace("(International)","");
    
    
    var tmpSelectedLang = this.lstLangInternationals.filter((obj) => {
      if (obj.LanguageId == this.languageSeleccionadoIntern[0].LanguageId) {
        return obj;
      } 
    });
    this.isPrivate = tmpSelectedLang.length > 0 ? tmpSelectedLang[0].isPrivate : 3;
    this.languageService
      .getLabelsByLanguageId(language.LanguageId)
      .subscribe((response) => {
        console.log('reponse labels ', response);
        if (response['intResponse'] == 200) {
          this.lstGENERALBack = response['jsnAnswer'];
          this.blnShowbtnCopy=true;
          this.blnShowUpdate = false;
          // filtramos las etiquetas por vista
          this.fnSetListViews();
          this.lstViewSetUp = response['jsnAnswer'].filter((el) => {
            if (el.ScreenId == 2) {
              // view setup
              return el;
            }
          });
          console.log('this.lstViewSetUp', this.lstViewSetUp);
        } else {
        }
      });
  }


  fnChangeLanguagebyId(language) {
    this.lstGENERALBack = [];
    this.intIdLangGlobal = language;
    console.log('language select', language);
    this.languageService
      .getLabelsByLanguageId(language)
      .subscribe((response) => {
        this.spinner.show();
        console.log('reponse labels ', response);
        if (response['intResponse'] == 200) {
          this.lstGENERALBack = response['jsnAnswer'];
          this.blnShowbtnCopy=false;
          this.blnShowUpdate = true;
          this.fnSetListViews();
        } else {
        }
        this.spinner.hide();
      });
  }

  fnResetListViews() {
    this.lstViewBoard = [];
    this.lstViewSetUp = [];
    this.lstNotesFinStatem = [];
    this.lstViewMonthlyDebriefResults = [];
    this.lstViewChecklist = [];
    this.lstViewMarkets = [];
    this.lstViewLearningSlides = [];
    this.lstViewServicesVersion = [];
    this.lstViewRulesImprovOpt = [];
    this.lstViewWorkingCopyImprovOpt = [];
    return true;
  }

  async fnSetListViews() {
    const ready = await this.fnResetListViews();
    //hacemos el procesamiento necesario para reemplazar las keys de las de palabras clave
    /******************************************************************************************************************************* */
    this.spinner.show();
    this.lstDataPivote = this.lstGENERALBack;


    this.lstDataPivote.forEach(elementIterative => {

      this.lstGENERALBack.forEach((elementChange, index) => {
        if(elementChange.LabelKeywords != '' && elementChange.LabelKeywords != null ){
           if(elementChange.LabelKeywords.includes(elementIterative.LabelCode)){
             var str1Change = "";
             str1Change =  elementIterative.LabelCode+"0";
             var str2Change = "";
             str2Change =  elementIterative.LabelCode+"1";
             this.lstGENERALBack[index].LabelKeywords = elementChange.LabelKeywords.replace(str1Change , elementIterative.LabelBase);
             this.lstGENERALBack[index].LabelKeywords = elementChange.LabelKeywords.replace(str2Change , elementIterative.Label);
             this.lstGENERALBack[index].LabelBase = elementChange.LabelBase.replace(elementIterative.LabelCode,elementIterative.LabelBase )
           // this.lstGENERALBack[index]=elementChange;
           }
        }
      });
      
    });
console.log("Resultado formateo",this.lstGENERALBack);
this.spinner.hide();
    /******************************************************************************************************************************* */
    this.lstViewBoard = this.lstGENERALBack.filter((el) => {
      if (el.ScreenId == 1 && el.version == 'M') {
        // view board
        return el;
      }
    });
    // console.log("this.lstViewBoard",this.lstViewBoard);
    this.lstViewSetUp = this.lstGENERALBack.filter((el) => {
      if (el.ScreenId == 2 && el.version == 'M') {
        // view setup
        return el;
      }
    });

    this.lstNotesFinStatem = this.lstGENERALBack.filter((el) => {
      if (el.ScreenId == 3 && el.version == 'M') {
        // view market customers
        return el;
      }
    });

    this.lstViewChecklist = this.lstGENERALBack.filter((el) => {
      if (el.ScreenId == 4 && el.version == 'M') {
        // view income statement
        return el;
      }
    });

    this.lstViewMonthlyDebriefResults = this.lstGENERALBack.filter((el) => {
      if (el.ScreenId == 5 && el.version == 'M') {
        // view income statement
        return el;
      }
    });
    this.lstViewMarkets = this.lstGENERALBack.filter((el) => {
      if (el.ScreenId == 6 && el.version == 'M') {
        // view ratios slides
        return el;
      }
    });
    this.lstViewLearningSlides = this.lstGENERALBack.filter((el) => {
      if (el.ScreenId == 7 && el.version == 'M') {
        // brief tab
        return el;
      }
    });
    this.lstViewServicesVersion = this.lstGENERALBack.filter((el) => {
      if (el.version == 'S') {
        // brief tab
        return el;
      }
    });
    console.log('Los elementos de services version son',this.lstViewServicesVersion);
    this.lstViewRulesImprovOpt = this.lstGENERALBack.filter((el) => {
      if (el.ScreenId == 8 && el.version == 'M') {
        // public info
        return el;
      }
    });
    this.lstViewWorkingCopyImprovOpt = this.lstGENERALBack.filter((el) => {
      if (el.ScreenId == 9 && el.version == 'M') {
        // results
        return el;
      }
    });
    this.lstViewMessages = this.lstGENERALBack.filter((el) => {
      if (el.ScreenId == 10 && el.version == 'M') {
        // results
        return el;
      }
    });
    //console.log('listas obtenidas', this.lstViewBoard, this.lstViewSetUp);
  }
/*
  async fnUpdateListViews() {
    var  arrTmpUpdateLists = [];
    var  AuxarrTmpUpdateLists = [];
    arrTmpUpdateLists.concat(this.lstViewBoard);
    arrTmpUpdateLists.concat(this.lstViewSetUp);
    arrTmpUpdateLists.concat(this.lstNotesFinStatem);
    arrTmpUpdateLists.concat(this.lstViewChecklist);
    arrTmpUpdateLists.concat(this.lstViewMonthlyDebriefResults);
    arrTmpUpdateLists.concat(this.lstViewMarkets);
    arrTmpUpdateLists.concat(this.lstViewLearningSlides);
    arrTmpUpdateLists.concat(this.lstViewRulesImprovOpt);
    arrTmpUpdateLists.concat(this.lstViewWorkingCopyImprovOpt);
    AuxarrTmpUpdateLists = arrTmpUpdateLists;
   
    this.spinner.show();
    
    AuxarrTmpUpdateLists.forEach(elementIterative => {

      arrTmpUpdateLists.forEach((elementChange, index) => {
        if(elementChange.LabelKeywords != ''){
           if(elementChange.LabelKeywords.includes(elementIterative.LabelCode)){
             var str1Change = "";
             str1Change =  elementIterative.LabelCode+"0";
             var str2Change = "";
             str2Change =  elementIterative.LabelCode+"1";
             arrTmpUpdateLists[index].LabelKeywords = elementChange.LabelKeywords.replace(str1Change , elementIterative.LabelBase);
             arrTmpUpdateLists[index].LabelKeywords = elementChange.LabelKeywords.replace(str2Change , elementIterative.Label);
             arrTmpUpdateLists[index].LabelBase = elementChange.LabelBase.replace(elementIterative.LabelCode,elementIterative.LabelBase )
           
           }
        }
      });
      
    });
console.log("Resultado formateo y update",arrTmpUpdateLists);
this.spinner.hide();
const ready = await this.fnResetListViews();
    
    this.lstViewBoard = arrTmpUpdateLists.filter((el) => {
      if (el.ScreenId == 1) {
        // view board
        return el;
      }
    });
    
    this.lstViewSetUp = arrTmpUpdateLists.filter((el) => {
      if (el.ScreenId == 2) {
        // view setup
        return el;
      }
    });

    this.lstNotesFinStatem = arrTmpUpdateLists.filter((el) => {
      if (el.ScreenId == 3) {
        
        return el;
      }
    });

    this.lstViewChecklist = arrTmpUpdateLists.filter((el) => {
      if (el.ScreenId == 4) {
        // view income statement
        return el;
      }
    });

    this.lstViewMonthlyDebriefResults = arrTmpUpdateLists.filter((el) => {
      if (el.ScreenId == 5) {
        // view income statement
        return el;
      }
    });
    this.lstViewMarkets = arrTmpUpdateLists.filter((el) => {
      if (el.ScreenId == 6) {
        // view ratios slides
        return el;
      }
    });
    this.lstViewLearningSlides = arrTmpUpdateLists.filter((el) => {
      if (el.ScreenId == 7) {
        // brief tab
        return el;
      }
    });
    this.lstViewRulesImprovOpt = arrTmpUpdateLists.filter((el) => {
      if (el.ScreenId == 8) {
        // public info
        return el;
      }
    });
    this.lstViewWorkingCopyImprovOpt = arrTmpUpdateLists.filter((el) => {
      if (el.ScreenId == 9) {
        // results
        return el;
      }
    });

  }
*/


  fnSaveEdited(lstDataEditedok) {
    console.log('**********regreso de modal y obtengo ', lstDataEditedok);
    /*
        var tmpChange= JSON.parse(this.strBacklstGeneral);
        this.lstGENERALBack = [];
        this.lstGENERALBack = tmpChange;
      }*/
    if (lstDataEditedok.edit) {

      this.labelService
        .updateLabel({
          dataUpd: lstDataEditedok.arrdata
        })
        .subscribe((response) => {
          console.log(response);
          if (response.intResponse == 200) {
            this.fnChangeLanguagebyId(lstDataEditedok.idlang);
            
            Swal.fire({
              text: '',
              imageUrl: '../../../../assets/iconSuccessfully.svg',
              imageAlt: 'icon successfully',
              confirmButtonColor: '#6FB45C',
              html:
                '<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> 122.- The label list has been updated successfully</label>',
            });

            
          } else {
            Swal.fire('Some wrong!', '123.- The action is not complete', 'error');
          }
        });
    } else {
      this.fnChangeLanguagebyId(lstDataEditedok.idlang);
    }
  }

  async fnModalEdit(value) {
    this.strTypeView = value;
    const arrViewList = await this.fnGetListView(value);
    this.dialogRef = this._matDialog.open(EditlabelsComponent, {
      disableClose: true,
      width: '1400px',
      height: '681px',
      panelClass: 'event-form-dialog',
      data: {
        strTypeView: this.strTypeView,
        arrViewList,
        val: value,
        bol: true,
        intIdLangGlobal: this.intIdLangGlobal,
        txtLanguageSelected: this.txtLanguageSelected,
        blnShowUpdate: this.blnShowUpdate
      },
    });

    this.dialogRef.afterClosed().subscribe((resp) => {
      console.log('CLOSED MODAL', resp);
      if (!resp.close) {
        this.fnSaveEdited(resp);
      }
    });
  }

  fnGetListView(value) {
    //console.log('entro fn edit this.lstGENERALBack', this.lstGENERALBack);
    /*actualizamos las listas con lo que tenga la variable global */
    // filtramos las etiquetas por vista
    //console.log('listas obtenidas', this.lstViewBoard, this.lstViewSetUp);
    let arrView;
    switch (value) {
      case 'Game Board & Fin Statements':
        arrView = this.lstViewBoard;
        break;
      case 'SetUp':
        arrView = this.lstViewSetUp;
        break;
      case 'Notes on Financial Statements':
        arrView = this.lstNotesFinStatem;
        break;
      case 'Checklist':
        arrView = this.lstViewChecklist;
        break;
      case 'Monthly Debrief/Results':
        arrView = this.lstViewMonthlyDebriefResults;
        break;
      case 'Markets':
        arrView = this.lstViewMarkets;
        break;
      case 'Learning Slides':
        arrView = this.lstViewLearningSlides;
        break;
      case 'Services Version':
        arrView = this.lstViewServicesVersion;
        break;
      case 'Improvement Options':
        arrView = this.lstViewRulesImprovOpt;
        break;
      case 'Rules':
        arrView = this.lstViewWorkingCopyImprovOpt;
        break;
      case 'Messages':
        arrView = this.lstViewMessages;
        break;
      default:
        arrView = this.lstViewBoard;
        break;
    }
    return arrView;
  }

  fnChangeNameLanguage() {
    const valueEdited = (document.getElementById(
      'textNameEdit'
    ) as HTMLInputElement).value;
    if (valueEdited == '') {
      Swal.fire({
        text: '',
        imageUrl: '../../../../assets/iconPrevention.svg',
        confirmButtonColor: '#6FB45C',
        html:
          '<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">124.- Name Language empty!, the action cannot be completed</label>',
      });

      return;
    } else {
      this.languageService
        .updateNameLanguage({
          strNameUpd: valueEdited,
          languageId: this.intIdLangGlobal,
          isPrivate: this.isPrivate
        })
        .subscribe((response) => {
          console.log(response);
          if (response['intResponse'] == 200) {
            const objNew = {
              Language: valueEdited,
              LanguageId: this.intIdLangGlobal,
            };
            if(this.blnChangeddlAndromeda){
              console.log(
                'justo antes de cambiar language----------',
                this.languageSeleccionado
              );
              if (this.isPrivate == 1) {
                this.languageSeleccionadoPrivate = [];
                this.languageSeleccionadoPrivate.push(objNew);
                this.languageSeleccionado = [];

              } else {
                this.languageSeleccionado = [];
                this.languageSeleccionadoPrivate = [];
                this.languageSeleccionado.push(objNew);
              }

            console.log(
              'justo depues de cambiar language----------',
              this.languageSeleccionado
            );
            this.lstLanguages = [];
            this.languageSeleccionadoOrg = [];
            this.lstLanguagesOrganization = [];  
            this.lstLanguagesInternationalAux = [];
            this.lstLanguagesPrivateAux = [];
            this.fnChargeLanguages();
            }
            else{
              this.languageSeleccionadoOrg = [];
              this.fnSelectDistributorbyModel(this.DistSeleccionado);
              this.languageSeleccionadoOrg.push(objNew);
              
            } 
            
            Swal.fire({
              text: '',
              imageUrl: '../../../../assets/iconSuccessfully.svg',
              imageAlt: 'icon successfully',
              confirmButtonColor: '#6FB45C',
              html:
                '<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">125.- This language has been updated successfully</label>',
            });
          } else {
            Swal.fire(
              'Some wrong!',
              '126.- The action is not complete, try again later.',
              'error'
            );
          }
        });
    }
  }

  showInput() {
    Swal.fire({
  title: 'Set a language name for your copy:',
  html: `
    <input id="swal-input1" type="text" placeholder="Enter language name" class="swal2-input">
    <div style="display: flex;justify-content: space-evenly;">
    
    <label>
      <input type="radio" name="option" value="1"> Private
    </label>
    <label>
      <input type="radio" name="option" value="0"> International
    </label>
    </div>

  `,
  showCancelButton: true,
  confirmButtonText: 'OK',
  cancelButtonText: 'Cancel',
  preConfirm: () => {
    const input1 = document.getElementById('swal-input1') as HTMLInputElement;
    const selectedOption = document.querySelector('input[name="option"]:checked') as HTMLInputElement;
    console.log(selectedOption)
    // console.log(selectedOption.value)
    this.languageName = input1.value ;
    if(selectedOption){
      this.ratioSelected =  selectedOption.value

    }
  },
  didOpen: () => {
    const input1 = document.getElementById('swal-input1') as HTMLInputElement;
    input1.focus();
  }
}).then((result) => {
  if (result.isConfirmed) {
    if (this.languageName !== ''){
      console.log(this.languageName)
      console.log(this.ratioSelected)
      this.fnCopyLanguage();
    } 
  }
});




  }

  fnCopyLanguage(){
    console.log("fnCopy language");
    this.languageService
        .fnCopyLanguage({
          idDistributor: environment.DistributorID,
          languageId: this.intIdLangGlobal,
          nameNewLanguage: this.languageName,
          isPrivate:this.ratioSelected
        })
        .subscribe((response) => {
          console.log(response);
          if (response['intResponse'] == 200) {
            this.intIdLangGlobal = 0;
            this.fnChargeLanguages();
            Swal.fire({
              text: '',
              imageUrl: '../../../../assets/iconSuccessfully.svg',
              imageAlt: 'icon successfully',
              confirmButtonColor: '#6FB45C',
              html:
                '<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> 127.- The language has been copied successfully</label>',
            });
          } else {
            Swal.fire(
              'Some wrong!',
              '126.- The action is not complete, try again later.',
              'error'
            );
          }
        });
        this.languageName = '';
  }

  fnDeselectLang(){
    this.intIdLangGlobal = 0;
    //this.lstGENERALBack = [];
  }

  radioChangetypeLang(event){
    console.log("radioChangetypeLang",event);
    this.isPrivate= event.value;
  
  }
  fnDeleteLanguage() {
    console.log(this.intIdLangGlobal,this.languageSeleccionado)
    this.languageService.deleteLanguage(this.intIdLangGlobal, 0).subscribe((response) => {
      console.log(response);
      if (response.jsnAnswer.total > 0) {
        Swal.fire({
          text: '',
          imageUrl: '../../../../assets/iconPrevention.svg',
          confirmButtonColor: '#6FB45C',
          html:
            '<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">There are ' + response.jsnAnswer.total + ' active Workshops using this language</label>',
        });
      } else {
        Swal.fire({
          html: '<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">This action will delete ' + this.txtLanguageSelected + ' language and labels</label>',
          imageUrl: '../../../../assets/iconPrevention.svg',
          showCancelButton: true,
          confirmButtonColor: '#6FB45C',
          cancelButtonColor: '#ED5050',
          confirmButtonText: 'OK!',
          cancelButtonText: 'CANCEL',
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            console.log("Deleted");
            this.languageService.deleteLanguage(this.intIdLangGlobal, 1).subscribe((response) => {
              console.log(response);
              console.log(this.lstLanguages)
              if (response.intResponse == 200) {
                this.fnChargeLanguages();
                console.log(this.lstLanguages)
                this.languageSeleccionado = [];
                this.languageSeleccionadoOrg = [];
                this.languageSeleccionadoIntern = [];
                this.languageSeleccionadoPrivate = [];
                this.intIdLangGlobal = 0;
                
                Swal.fire({
                  text: '',
                  imageUrl: '../../../../assets/iconPrevention.svg',
                  confirmButtonColor: '#6FB45C',
                  html:
                    '<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">Language has been deleted correctly</label>',
                });
              }
            })
          }

        });
      }
    })
  }
}
