import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-partition-objects',
  templateUrl: './partition-objects.component.html',
  styleUrls: ['./partition-objects.component.scss'],
})
export class PartitionObjectsComponent implements OnInit {

  data: any;

  constructor( public matDialogRef: MatDialogRef<PartitionObjectsComponent>,@Inject(MAT_DIALOG_DATA) private _data: any) { 
              console.log(this._data);
              }

  ngOnInit(): void {}

  entra(event){
    //console.log(event)
    if(event.keyCode == 13){
      this.fnSave();
    }
  }

  fnSave(){
    console.log("save");
    this._data["isConfirmed"] = true;
    this._data["strPartTake"] = this.data.toString();
    this.matDialogRef.close({isConfirmed: this._data["isConfirmed"], strPartTake: this._data["strPartTake"] });
  }

  fnCancel(){
    console.log("cancel");
    this._data["isConfirmed"] = false;
    this.matDialogRef.close({isConfirmed: this._data["isConfirmed"], strPartTake: this._data["strPartTake"] });
  }

  getSingleTextLabel(labelNumber: string): string {
    const tmp = this._data.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    return tmp ? tmp.Label : '';
  }
}
