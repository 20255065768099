//#region Imports
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './services/auth/auth.service';
import { SetupTeamComponent } from 'src/app/views/setup-team/setup-team.component';
import { DialogAllTeamsComponent } from 'src/app/views/dialog-all-teams/dialog-all-teams.component';
import { RulesGameComponent } from 'src/app/views/rules-game/rules-game.component';
import { DialogOrdersComponent } from 'src/app/views/dialog-orders/dialog-orders.component';
import { OrderService } from 'src/app/services/order/order.service';
import { DialogImprovementsComponent } from 'src/app/views/dialog-improvements/dialog-improvements.component';
////#endregion

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isLogged = false;

  observador;

  dialogRef:any;

  constructor(private authService: AuthService, private _matDialog: MatDialog, private orderService: OrderService) {}

  ngOnInit() {
    this.observador = this.authService.user.subscribe((bln) => {
      this.isLogged = bln;
    });
  }

  ngOnDestroy(): void {
    this.observador.unsubscribe();
  }

  fnModalTeam(teamId){
    this.dialogRef = this._matDialog.open(SetupTeamComponent, {
      disableClose: false,
      width: '1400px',
      height: '600px',
      panelClass: 'event-form-dialog',
      data: {
        teamId: teamId
      },
    });

    this.dialogRef.afterClosed().subscribe((resp) => {
      console.log("RETURNED DIALOG")
    });
  }

  fnModalTeams(workShopId){
    this.dialogRef = this._matDialog.open(DialogAllTeamsComponent, {
      disableClose: true,
      width: '1400px',
      height: '600px',
      panelClass: 'event-form-dialog',
      data: {
        workShopId: 48
      },
    });

    this.dialogRef.afterClosed().subscribe((resp) => {
      console.log("RETURNED DIALOG")
    });
  }

  fnModalRules(languageId){
    this.dialogRef = this._matDialog.open(RulesGameComponent, {
      width: '1400px',
      height: '600px',
      panelClass: 'event-form-dialog',
      data: {
        llanguageId: languageId
      },
    });

    this.dialogRef.afterClosed().subscribe((resp) => {
      console.log("RETURNED DIALOG")
    });
  }

  fnModalOrders(workShopId, teamId, blnIsFacilitator, view){
    this.dialogRef = this._matDialog.open(DialogOrdersComponent, {
      disableClose: false,
      width: '1400px',
      height: '600px',
      panelClass: 'event-form-dialog',
      data: {
        workShopId: 48,
        blnIsFacilitator: blnIsFacilitator,
        teamId: teamId,
        view: view
      },
    });

    this.dialogRef.afterClosed().subscribe((resp) => {
      console.log("RETURNED DIALOG")
    });
  }

  fnModalImprovements(){
    this.dialogRef = this._matDialog.open(DialogImprovementsComponent, {
      disableClose: false,
      width: '1400px',
      height: '600px',
      panelClass: 'event-form-dialog',
      data: {
        enableSelect: false,
      },
    });

    this.dialogRef.afterClosed().subscribe((resp) => {
      console.log("RETURNED DIALOG")
    });
  }

  /*fnCreateOrders(){
    console.log("fnCreateOrders");
    this.orderService.createOrdersByWorkShopId(48,4,1).subscribe(resp=>{
      console.log("fnCreateOrders",resp);
    })
  }*/

}
