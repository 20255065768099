<div mat-dialog-title style="width: 100% !important; text-align: center; height: 50px !important; margin-bottom: 10px;" *ngIf="(!blnIsFacilitator || month < 2) || (blnIsFacilitator || month < 2 && tipoBoton == 'customers' && view != 3)" [ngStyle]="{'display': tipoBoton == 'menu' ? 'none' : 'block'}">
    <mat-tab-group (selectedTabChange)="fnChangeView($event['tab']['textLabel'])"  *ngIf="month === 1 && (boardMode == 'NM' || boardMode == 'SSNM')">
        <mat-tab label="{{getSingleTextLabel('6013')}}" *ngIf="!blnIsFacilitator" [disabled]="!blnIsFacilitator"></mat-tab>
        <mat-tab label="{{getSingleTextLabel('6013')}}" *ngIf="blnIsFacilitator && tipoBoton == 'customers'" [disabled]="blnIsFacilitator"></mat-tab>

        <mat-tab label="{{getSingleTextLabel('4')}}"  *ngIf="blnIsFacilitator  && tipoBoton == 'menu'"></mat-tab>
    </mat-tab-group>
    <mat-tab-group [selectedIndex]="monthHistoric-1" scrollDistance="300px" (selectedTabChange)="fnChangeMonthHistoric($event.index+1)"  *ngIf="(month > 1 && !blnIsFacilitator) || (month > 1 && blnIsFacilitator && tipoBoton == 'customers' && view < 3)">
        <mat-tab label="{{getSingleTextLabel('5013')}} {{i+1}}" *ngFor="let item of numbers; let i = index;" ></mat-tab>
    </mat-tab-group>
</div>

<mat-dialog-content style="overflow: hidden;" [ngStyle]="{'min-height': blnIsFacilitator && month > 1 ? '90%':'80%', 'max-height': blnIsFacilitator && month > 1 ? '90vh':'80vh'}" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <div class="w-100">
        <div *ngIf="view <= 2 && blnAvalibleBid" class="calibri16 calibriFont" style="font-size: 20px !important; font-weight: 600; color: chocolate; width: 100%; padding-left: 20px;">
            {{time}}
        </div>
        <div *ngIf="view >= 1"  style="display: inline-flex; width: 100%;">
            <div class="text-center" style="align-self: flex-end; width:220px">
                <ng-container *ngIf="monthHistoric == month">
                    <label class="FontMontserratText style2a calibriFont" *ngIf="month >= 1 || boardMode == 'DM'">{{getSingleTextLabel('6005')}} {{month}}</label><br>
                    <label class="FontMontserratText style3 calibriFont" *ngIf="month >= 1 || boardMode == 'DM'">{{getSingleTextLabel('6006')}}</label><br>
                    <label class="FontMontserratText style12 calibriFont" *ngIf="month >= 1 || boardMode == 'DM'">{{16-month}}/{{getSingleTextLabel('200.1')}}</label>
                </ng-container>
                
                <ng-container *ngIf="monthHistoric != month">
                    <label class="FontMontserratText style2a calibriFont" *ngIf="month >= 1 || boardMode == 'DM'">{{getSingleTextLabel('6005')}} {{monthHistoric}}</label><br>
                    <label class="FontMontserratText style3 calibriFont" *ngIf="month >= 1 || boardMode == 'DM'">{{getSingleTextLabel('6006')}}</label><br>
                    <label class="FontMontserratText style12 calibriFont" *ngIf="month >= 1 || boardMode == 'DM'">{{16-monthHistoric}}/{{getSingleTextLabel('200.1')}}</label>
                </ng-container>
                
            </div>
            <div class="first-table">
                <table>
                    <tr>
                        <div class="padding-rigth">
        
                        </div>
                        <th *ngFor="let jsnTeam of arrAllTeams" style="height: 20px !important; width: 81px !important;" [ngStyle]="{'background-color': jsnTeam['Color']?jsnTeam['Color']:'#1b3f89'}">
                            <div class="example-containerType">
                                <h2 class="titleTeam calibriFont" style="line-height: 23px; width: 114.5px !important;" [ngStyle]="{'color': jsnTeam['Color'] === '#ffe998' || jsnTeam['Color'] === '#84eeeb' || jsnTeam['Color'] === '#bec3ff' || jsnTeam['Color'] === '#e4e4ec' || jsnTeam['Color'] === '#7faeff' ? 'black' : 'white'}">
                                    {{jsnTeam['Avatar']}}</h2>
                            </div>
                        </th>
                    </tr>
                    <!--
                    <tr *ngIf="blnIsFacilitator">
                        <td class="fontSize FontMontserratTextTables calibri16 calibriFont" style="text-align: left; height: 20px !important;">
                            {{getSingleTextLabel('6014')}}:
                        </td>
                        <td class="fontSize FontMontserratTextTables calibri16 calibriFont" style=" height: 20px !important;" *ngFor="let jsnTeam of arrAllTeams">
                            {{jsnTeam['Supplies']}}
                        </td>
                    </tr>
                    -->
                    <tr>
                        <td class="fontSize FontMontserratTextTables calibri14 calibriFont" style="text-align: left; height: 20px !important;">
                            {{getSingleTextLabel('6014')}}:
                        </td>
                        <td class="fontSize FontMontserratTextTables calibri16 calibriFont" style=" height: 20px !important;" *ngFor="let jsnTeam of arrAllTeams">
                            {{monthHistoric === month ? jsnTeam['maxSupplies'] : jsnTeam.historicMaxSupplies}}
                        </td>
                    </tr>
                    <!-- <tr *ngIf="view > 1 || monthHistoric < month">
                        <td class="fontSize FontMontserratTextTables calibri16 calibriFont" style="text-align: left; height: 20px !important;">
                            {{getSingleTextLabel('6011')}}:
                        </td>
                        <td class="fontSize FontMontserratTextTables calibri16 calibriFont" style=" height: 20px !important;" *ngFor="let jsnTeam of arrAllTeams">
                            {{jsnTeam['Sold']}}
                        </td>
                    </tr> -->
                    <!-- <tr *ngIf="view > 1 || monthHistoric < month">
                        <td class="fontSize FontMontserratTextTables calibri16 calibriFont" style="text-align: left; height: 20px !important;">
                            {{getSingleTextLabel('6012')}}:
                        </td>
                        <td class="fontSize FontMontserratTextTables calibri16 calibriFont" style=" height: 20px !important;" *ngFor="let jsnTeam of arrAllTeams">
                            {{(monthHistoric === month ? jsnTeam.Supplies : jsnTeam.historySupplies) - jsnTeam.Sold}}
                        </td>
                    </tr> -->
                    <tr>
                        <td class="fontSize FontMontserratTextTables calibri14 calibriFont" style="text-align: left; height: 20px !important;">
                            {{getSingleTextLabel('30')}}:
                        </td>
                        <td class="fontSize FontMontserratTextTables calibri16 calibriFont" style=" height: 20px !important;" *ngFor="let jsnTeam of arrAllTeams">
                            {{jsnTeam['Advertising']}}
                        </td>
                    </tr>
                </table>
            </div>
            <div *ngIf="blnIsFacilitator">
                <button *ngIf="view === 2" type="button" class="btn btn-secondary style9 calibri12 calibriFont" style="font-size: 12px; width: 146px;"
                (click)="fnTimer()">&nbsp;&nbsp;{{getSingleTextLabel('6018')}}&nbsp;&nbsp;</button>
                <br>
                <button *ngIf="view === 2" type="button" class="btn btn-secondary style9 calibri12 calibriFont" style="font-size: 12px; margin-top: 10px; width: 146px;" [disabled]="marketStep !== 1"
                (click)="fnCheckTeams()">&nbsp;&nbsp;{{getSingleTextLabel('6019')}}&nbsp;&nbsp;</button>                                                                
                <!-- <button *ngIf="(view-2) === (arrOrders.length)" type="button" class="btn btn-secondary style9 calibri12 calibriFont" style="font-size: 12px; margin-top: 10px; width: 146px;"
                (click)="fnCloseMarket()">&nbsp;&nbsp;{{getSingleTextLabel('6020')}}&nbsp;&nbsp;</button> -->
                <div *ngIf="view > 2 && ranTeam != null" class="div-slogan row calibriFont" style="font-size: 16px;">
                    <label style="margin-bottom: 0;" [ngStyle]="{'color': fnColor(ranTeam.Color) }" >{{ranTeam.Slogan}}</label>
                </div>
            </div>
            <div style="width: 23%; text-align: end;" *ngIf="!blnIsFacilitator">
                <div style=" display: inline-flex; width: 100%; height: 22px; margin-top:26px" *ngIf="!blnIsFacilitator && monthHistoric === month">
                    <div class="row">
                        <div class="col-12">
                            <div class="FontMontserratTextTables calibri16 calibriFont" style="width: 100%; text-align: left !important;">{{getSingleTextLabel('6021')}}:</div>
                        </div>
                        <div class="col-12">
                            <ng-container style="width: 70%;" *ngFor="let team of arrAllTeams">
                                <input class="inputTable calibri16 calibriFont" *ngIf="team.TeamId === teamId" [(ngModel)]="team.Slogan" (change)="fnAddSlogan(team)"
                                style="height: 100% !important; width: 100%;" matInput type="text" maxlength="100"/>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="modal-body modal-body-media FontMontserrat" style="overflow: auto;" 
        [ngStyle]="{'max-height': blnIsFacilitator && month > 1 ? '445px':'420px'}">
        <table mat-table [dataSource]="dataSourceTableOfOrders" style="border: 1px solid #D5DCE3; width: 100%; margin-top: 0px !important;"
            class="mt-2 tableBorder" matSort aria-label="Elements">

            <ng-container matColumnDef="order">
                <th mat-header-cell *matHeaderCellDef style="width: 100px;">
                    <div class="mat-header-cell-text title-table style2a calibriFont" style="width: 99px !important; font-weight: bold !important;">
                        {{getSingleTextLabel('85')}}
                    </div>
                </th>

                <td mat-cell *matCellDef="let row;  let i = index;" class="rowHeight">
                    <label class="FontMontserratTextTables calibri16 calibriFont" style="display: inline-flex;"  [ngStyle]="{'color': teamId === row['Team1'] || teamId === row['Team2'] ? strColor : ''}"> 
                        <div style="color: #ad5a82; font-weight: 700;">{{row['PS'] === 1 ? 'PS-':'' }}</div> {{ row['OrderNum'] }}
                    </label>
                </td>
                <td mat-footer-cell *matFooterCellDef class="noBorderRight style7b calibriFont rowHeight">{{getSingleTextLabel('104')}}</td>
            </ng-container>

            <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef style="width: 100px;">
                    <div class="mat-header-cell-text title-table style2a calibriFont" style="width: 99px !important; font-weight: bold !important;">
                        {{getSingleTextLabel('6015')}}
                    </div>
                </th>
                <td mat-cell *matCellDef="let row;  let i = index;" class="rowHeight">
                    <label class="FontMontserratTextTables calibri16 calibriFont" [ngStyle]="{'color': teamId === row['Team1'] || teamId === row['Team2'] ? strColor : ''}">
                        {{ row['Quantity'] }}
                    </label>
                </td>
                <td mat-footer-cell *matFooterCellDef class="noBorderRight style7b calibriFont rowHeight">{{getTotalQuantities()}}</td>
            </ng-container>

            <ng-container matColumnDef="terms">
                <th mat-header-cell *matHeaderCellDef style="width: 100px;">
                    <div class="mat-header-cell-text title-table style2a calibriFont" style="width: 99px !important; font-weight: bold !important;">
                        {{getSingleTextLabel('6016')}}
                    </div>
                </th>
                <td mat-cell *matCellDef="let row;  let i = index;" class="rowHeight">
                    <label class="FontMontserratTextTables calibri16 calibriFont"  [ngStyle]="{'color': teamId === row['Team1'] || teamId === row['Team2'] ? strColor : ''}">
                        {{ row['Terms'] == 0 ? 'Cash' :  row['Terms']}}
                    </label>
                </td>
                <td mat-footer-cell *matFooterCellDef class="noBorderRight rowHeight"></td>
            </ng-container>

            <ng-container matColumnDef="team1">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="mat-header-cell-text title-table style2a calibriFont" style="font-weight: bold !important;">
                        {{getSingleTextLabel('111')}}
                    </div>
                </th>
                <td mat-cell *matCellDef="let row; let i = index;" class="rowHeight">
                    <mat-form-field appearance="fill" class="matSelectHeight noPaddingSelect">
                        <mat-label class="calibri16 calibriFont">{{getSingleTextLabel('111')}}</mat-label>
                        <mat-select class="calibri16 calibriFont" [(ngModel)]="row['Bids'][0]['TeamId']" [disabled]="!blnIsFacilitator">
                            <mat-option class="calibri16 calibriFont" *ngFor="let team of arrAllTeams" [value]="team['TeamId']">
                                {{team['Avatar']}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
                <td mat-footer-cell *matFooterCellDef class="noBorderRight rowHeight"></td>
            </ng-container>

            <ng-container matColumnDef="team2">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="mat-header-cell-text title-table style2a calibriFont" style="font-weight: bold !important;">
                        {{getSingleTextLabel('111')}}
                    </div>
                </th>
                <td mat-cell *matCellDef="let row; let i = index;" class="rowHeight noPaddingSelect">
                    <mat-form-field appearance="fill" class="matSelectHeight">
                        <mat-label class="calibri16 calibriFont">{{getSingleTextLabel('111')}}</mat-label>
                        <mat-select class="calibri16 calibriFont" [(ngModel)]="row['Bids'][1]['TeamId']" [disabled]="!blnIsFacilitator">
                            <mat-option class="calibri16 calibriFont" *ngFor="let team of arrAllTeams" [value]="team['TeamId']" [disabled]="row['Bids'][0]['TeamId'] === team['TeamId']">
                                {{team['Avatar']}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
                <td mat-footer-cell *matFooterCellDef class="noBorderRight rowHeight"></td>
            </ng-container>

            <ng-container matColumnDef="bid">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="mat-header-cell-text title-table style2a calibriFont" style="font-weight: bold;">
                        {{getSingleTextLabel('6023')}}
                    </div>
                </th>
                <td mat-cell *matCellDef="let row; let i = index;" class="rowHeight">
                    <div *ngFor="let bid of row['Bids']">
                        <!--NOT FACILITATOR AND TEAM1 MONTH 1 && 2-->
                        <ng-container *ngIf="!(!blnAvalibleBid || (row['Customizing'] && !cutomizingOption)); else elseBidShow">
                            <div
                                [matTooltip]="isNotAllStepsCompleted ? 'Complete all steps before': ''"
                            >
                                <input 
                                    class="inputTable style10 calibriFont" 
                                    *ngIf="(!blnIsFacilitator && teamId === bid['TeamId'])||(blnIsFacilitator && (row['OrderNum'] == 101 || row['OrderNum'] == 102) && bid['TeamId'] == 0 )" 
                                    matInput 
                                    type="number" 
                                    autocomplete="off" 
                                    placeholder="0"    
                                    min="0"                                
                                    onfocus="this.select()"
                                    oninput="validity.valid||(value='');" 
                                    [(ngModel)]="bid['Bid']"
                                    [disabled]="!blnAvalibleBid || (row['Customizing'] && !cutomizingOption) || isNotAllStepsCompleted || month != monthHistoric || !hasControl"
                                    (keypress)="validateInputNumberForMonth($event,bid)"
                                    (blur)="desFocus(row['OrderId'],bid,row)"
                                />
                        </div>
                    </ng-container>
                        <ng-template #elseBidShow>
                            <p *ngIf="!blnIsFacilitator && teamId === bid['TeamId']">
                                {{bid['Bid']}}
                            </p>
                        </ng-template>
                        
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef class="noBorderRight rowHeight"></td>
            </ng-container>

            <ng-container matColumnDef="unitprice">
                <th mat-header-cell *matHeaderCellDef style="padding-right: 0px;">
                    <div class="mat-header-cell-text title-table style2a calibriFont" style="font-weight: bold;">
                        {{getSingleTextLabel('6024')}} (Max Price = {{16-monthHistoric}})
                    </div>
                </th>
                <td mat-cell *matCellDef="let row;  let i = index;" class="rowHeight" style="padding-right: 0px;">
                    <div *ngFor="let bid of row['Bids']">
                        <label class="FontMontserratTextTables calibri16 calibriFont" *ngIf="!blnIsFacilitator && teamId === bid['TeamId']">{{fnUnitPrice(bid['Bid'] / row['Quantity'])}}</label>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef class="noBorderRight rowHeight"></td>
            </ng-container>

            <ng-container matColumnDef="winingTeam">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="mat-header-cell-text title-table style2a calibriFont" style="font-weight: bold;">
                        {{getSingleTextLabel('6001')}}
                    </div>
                </th>
                <td mat-cell *matCellDef="let row; let i = index;" class="rowHeight" style="padding: 0px;">
                    <mat-form-field appearance="fill" [hidden]="view > 2 && !row['blnStatusOrder']" style="width: 90%;" class="matSelectHeight">
                        <mat-label class="style2a calibriFont">{{getSingleTextLabel('6001')}}</mat-label>
                        <mat-select class="style2a calibriFont" (selectionChange)="fnSelectWiningTeam(row)" [(ngModel)]="row['WiningTeam']" [disabled]="!blnIsFacilitator || view > 2 || intOpenMarket === 0 || intOpenMarket === 2 || (month == 1 && (boardMode == 'NM' || boardMode == 'SSNM')) || blnEmpate || this.monthHistoric < this.month">
                            <mat-option class="style2a calibriFont" *ngFor="let team of arrAllTeamsAux" [value]="team['TeamId']" [hidden]="fnHideWiningTeam(row['Bids'],team['TeamId'], row.Quantity)" [ngStyle]="{'border':fnCheckTie(row,team['TeamId'])?'3px solid rgb(184, 134, 11)' :'none'}">
                                {{team['Avatar']}} <!--[hidden]="row['Team1'] !== team['TeamId'] && row['Team2'] !== team['TeamId']" -->
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <i class="fas fa-trophy icon-winer" (click)="fnClickCoin(row)" *ngIf="(month > 1 || boardMode == 'DM') && row['Winers'].length > 1"></i>
                    <!--button mat-button class="btn btn-primary" style="Background-color:#5EA0CC; color: white;font-family: 'Montserrat';" 
                    (click)="fnClickCoin(row)" *ngIf="month > 1 && row['Winers'].length > 1">
                        &nbsp;&nbsp;&nbsp;&nbsp;Flip&nbsp;&nbsp;&nbsp;&nbsp;
                    </button-->
                </td>
                <td mat-footer-cell *matFooterCellDef class="noBorderRight rowHeight"></td>
            </ng-container>

            <ng-container matColumnDef="{{team['Avatar'] ? team['Avatar'] : 'matColumnDef ' + i}}" *ngFor="let team of arrAllTeams; let i = index">
                <th mat-header-cell *matHeaderCellDef style="width: 81px !important;" [ngStyle]="{'background-color': team['Color'] ? team['Color'] : '#1b3f89'}">
                    <div class="mat-header-cell-text title-table-teams style2a calibriFont" [ngClass]="{'colorBlack': team['Color'] === '#ffe998' || team['Color'] === '#84eeeb' || team['Color'] === '#bec3ff' || team['Color'] === '#e4e4ec' || team['Color'] === '#7faeff', 'colorWhite': team['Color'] !== '#50b1a8' || team['Color'] !== '#ffe998' && team['Color'] !== '#84eeeb' && team['Color'] !== '#af753a' }">
                        {{team['Avatar']}}
                    </div>
                </th>
                <td mat-cell *matCellDef="let row;  let i = index;" class="rowHeight">
                    <!-- aqui van las apuestas? -->
                    <div (click)="fnCheckTie(row,bid['TeamId'])" *ngFor="let bid of row['Bids']" [ngStyle]="{'background-color':bid['winner']=== true || (checkWinner(bid['TeamId'], bid['OrderId'])) || fnCheckTie(row,bid['TeamId'])?'rgb(195, 216, 254)' :'transparent'}" [ngClass]="{'bidPrepareHasQuality2': (monthHistoric>=team['monthQualityImplemented'] && (checkWinner(bid['TeamId'], bid['OrderId'])))}">
                        <label class="FontMontserratTextTables calibri16 calibriFont" *ngIf="team['TeamId'] === bid['TeamId']" [hidden]="view > 2 && bid['intStatusTeam'] < 1">
                            <ng-container *ngIf="(team['isImpQuality'] && bid['Bid'] > 0 && monthHistoric>=team['monthQualityImplemented'] && monthHistoric != month) || (checkWinner(bid['TeamId'], bid['OrderId']) && team['isImpQuality'] && monthHistoric>=team['monthQualityImplemented']); else labelNoImpQuality">
                                <del (click)="fnCheckTie(row,bid['TeamId'])">
                                    {{ team['TeamId'] === bid['TeamId'] && bid['Bid'] > 0 && !bid.blnNotSupplies ? bid['Bid'] : 
                                        team['TeamId'] === bid['TeamId'] && bid['Bid'] > 0 && bid.blnNotSupplies && teamId == bid['TeamId'] ? bid['Bid'] : 
                                        team['TeamId'] === bid['TeamId'] && bid['Bid'] > 0 && bid.blnNotSupplies && teamId != bid['TeamId'] ? '?' : '-'}}
                                </del>
                                &nbsp;&nbsp;{{ team['TeamId'] === bid['TeamId'] && bid['BidQuality'] > 0 && !bid.blnNotSupplies ? getValueQuality(bid['Bid']) : '-'}}
                            </ng-container>
                            <ng-template #labelNoImpQuality>
                                <span id="spanWD"
                                    [ngClass]="{
                                        'bidPrepareHasQuality': (monthHistoric>=team['monthQualityImplemented']),
                                        'bidPrepareHasExceedsMaxPrice': ((bid['Bid'] / row['Quantity']) > (16-monthHistoric) && !bid.blnNotSupplies),
                                        'bidPrepareHasWithdrawn': (bid.blnNotSupplies || bid.isWithdrawn) && (monthHistoric===month && team['TeamId'] === teamId && bid['Bid'] > 0),
                                        'bidPrepareHasWithdrawnHistory': ((bid.blnNotSupplies || bid.isWithdrawn) && team['TeamId'] === teamId && bid['Bid'] > 0),
                                        'bidPrepareFromFacilitator': ((bid.blnNotSupplies || bid.isWithdrawn) && tipoBoton == 'menu' && blnIsFacilitator && bid['Bid'] > 0),
                                        'redExeed' : (blnIsFacilitator && tipoBoton == 'menu' && ((bid['Bid'] / row['Quantity']) > (16-monthHistoric)))}"
                                    
                                >
                                    <span (click)="prueba(team,row)" *ngIf="!(bid.blnNotSupplies || bid.isWithdrawn) else withdrawnTemplate" >
                                       <span *ngIf="(team['maxSupplies'] < row['Quantity'] && blnIsFacilitator && bid['Bid'] > 0 && monthHistoric==month) || (team['historicMaxSupplies'] < row['Quantity'] && blnIsFacilitator && bid['Bid'] > 0 && monthHistoric<month) ">W</span> 
                                       {{ team['TeamId'] === bid['TeamId'] && bid['Bid'] > 0 ? bid['Bid'] : '-'}}
                                    </span>
                                    <ng-template #withdrawnTemplate>
                                        <span *ngIf="(bid.blnNotSupplies) else showWithdrawn">
                                            {{
                                            
                                                team['TeamId'] === bid['TeamId'] && bid['Bid'] > 0 ? 
                                                ((monthHistoric===month) && intOpenMarket == 1 && bid.intStatusTeam != 1 && tipoBoton == 'menu' && blnIsFacilitator) ? 'W '+bid['Bid'] :
                                                ((monthHistoric===month) && intOpenMarket !== 2 && bid.intStatusTeam != 1) ? bid['Bid'] :  (teamId == bid['TeamId'] || (tipoBoton == 'menu' && blnIsFacilitator) ) ? 'W '+bid['Bid'] : '?'
                                                : '-'
                                            }}
                                        </span>
                                         <ng-template #showWithdrawn> <!-- Mostrar precio excedido -->
                                            <span *ngIf="(bid.isWithdrawn)" [ngClass]="{'redExeed' : blnIsFacilitator && tipoBoton == 'menu'}">
                                                {{
                                                
                                                    team['TeamId'] === bid['TeamId'] && bid['Bid'] > 0 ? 
                                                    ((monthHistoric===month) && intOpenMarket !== 2) ? bid['Bid'] :  (teamId == bid['TeamId'] || (tipoBoton == 'menu' && blnIsFacilitator) ) ?  bid['Bid'] : bid['Bid']
                                                    : '-'
                                                }}
                                            </span>
                                        </ng-template>
                                    </ng-template><!--
                                        WD:  {{bid.isWithdrawn}} > {{bid.blnNotSupplies}} > {{ monthHistoric }} > {{month}} > {{intOpenMarket}} mes > {{team['TeamId']}} == {{bid['TeamId']}}
                                        pruebas teamActual:{{teamId}} >{{team['TeamId']}} > {{ bid['TeamId'] }} > {{bid['Bid']}}
                                        > {{ monthHistoric }} > {{month}} > {{intOpenMarket }}-->
                                </span>
                            </ng-template>
                        </label>
                        <label class="FontMontserratTextTables calibri16 calibriFont" *ngIf="team['TeamId'] === bid['TeamId'] && bid['Bid'] > 0" [hidden]="view <= 2 || bid['intStatusTeam'] >= 1">
                            ?
                        </label>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef class="noBorderRight rowHeight"></td>
            </ng-container>

            <tr class="tableHeader" mat-header-row *matHeaderRowDef="titulosOfOrders; sticky: true"></tr>
            <tr mat-row style="height: 30px !important;" *matRowDef="let row; columns: titulosOfOrders;" [ngStyle]="{'box-shadow': view > 2 && row['OrderId'] === currentOrder['OrderId'] ? '0px 0px 18px #4b4bff':''}"></tr>
            <tr mat-footer-row *matFooterRowDef="titulosOfOrders;" class="style7b calibriFont rowHeight"></tr>
        </table>
    </div>
    <div style="padding-left: 1rem; padding-right: 1rem;">
        <div style=" display: inline-flex; width: 100%; height: 22px;" *ngIf="view == 2 || monthHistoric < month && blnIsFacilitator">
            <div class="FontMontserratTextTables suppliesLeftBox calibri16 calibriFont" style="width: 300px;">{{getSingleTextLabel('6017')}}: </div>
            <div class="FontMontserratTextTables suppliesLeftBox calibri16 calibriFont" style="display: inline-flex; width: 116px; justify-content: center;" *ngFor="let jsnTeam of arrAllTeams">
                {{ jsnTeam['maxSupplies'] - jsnTeam['Sold']}}
            </div>
        </div>
        <!-- <div style=" display: inline-flex; width: 100%; height: 22px;" *ngIf="!blnIsFacilitator && monthHistoric === month">
            <div class="FontMontserratTextTables" style="width: 30%; text-align: left !important;">{{getSingleTextLabel('6021')}}:</div>
            <ng-container style="width: 70%;" *ngFor="let team of arrAllTeams">
                <input class="inputTable" *ngIf="team.TeamId === teamId" [(ngModel)]="team.Slogan" (change)="fnAddSlogan(team)"
                style="height: 100% !important; width: 100%;" matInput type="text" maxlength="100"/>
            </ng-container>
        </div> -->
        <div class="content-button-winner" *ngIf="view > 2">
            <div *ngFor="let bid of currentOrder.Bids; let i = index;">
                <div class="button-winner row" [ngClass]="{'winnerBid': bid.intStatusTeam >= 2}" *ngIf="bid['TeamId'] === 0" 
                    [ngStyle]="{'cursor': ((teamId === currentOrder.WiningTeam)?'pointer':'default'),'background-color': fnGetColorByTeamId(teamId), 'color': fnGetTextColorByTeamId(teamId)}"
                    (click)="fnNextStep(currentOrder,i)">
                    <label class="calibri17 calibriFont" *ngIf="bid.intStatusTeam < 2" style="font-size: 14px;" [ngStyle]="{'cursor': ((teamId === currentOrder.WiningTeam)?'pointer':'default')}">{{fnGetTeamVersus(teamId)}}</label>
                    <label class="calibri17 calibriFont" *ngIf="bid.intStatusTeam >= 2" style="cursor: pointer; font-size: 14px;"> {{getSingleTextLabel('6022')}}: {{fnGetTeamVersus(teamId)}}</label>
                </div>
                <div class="button-winner row" [ngClass]="{'winnerBid': bid.intStatusTeam >= 2}" *ngIf="bid['Bid']>0" 
                    [ngStyle]="{'cursor': ((bid.intStatusTeam === 0 || bid.TeamId === currentOrder.WiningTeam)?'pointer':'default'),'background-color': fnGetColorByTeamId(bid.TeamId), 'color': fnGetTextColorByTeamId(bid.TeamId)}" 
                    (click)="fnNextStep(currentOrder,i)">
                    <label class="calibri17 calibriFont" *ngIf="bid.intStatusTeam === 0" style="cursor: pointer; font-size: 14px;">{{fnGetTeamVersus(bid.TeamId)}}</label>
                    <label class="calibri17 calibriFont" *ngIf="bid.intStatusTeam === 1 && bid.blnNotSupplies && !bid.isWithdrawn" style="transform: rotateZ(25deg); background-color: black; font-size: 14px; width: 170px; margin-top: 5px; color: white;">{{getSingleTextLabel('138')}}</label>
                    <label class="calibri17 calibriFont" *ngIf="bid.intStatusTeam === 1 && bid.blnNotSupplies && bid.isWithdrawn" style="transform: rotateZ(25deg); background-color: black; font-size: 14px; width: 170px; margin-top: 5px; color: white;">{{getSingleTextLabel('138')}}</label>
                    <!-- <label class="calibri17 calibriFont" *ngIf="bid.intStatusTeam === 0 && fnGetRemaining(bid.TeamId) < currentOrder.Quantity && (bid.Bid/currentOrder.Quantity) <= (16 - this.month)" style="transform: rotateZ(25deg); background-color: black; font-size: 14px; width: 180px; margin-top: 5px;color:white !important">{{getSingleTextLabel('138')}}</label> -->
                    <label class="calibri17 calibriFont" *ngIf="bid.intStatusTeam === 1 && (bid.Bid/currentOrder.Quantity) <= (16 - this.month) && !bid.blnNotSupplies" style="font-size: 14px;" [ngStyle]="{'cursor': ((bid.TeamId === currentOrder.WiningTeam)?'pointer':'default')}">{{bid.Bid}}<span class="revealBidCardQualityLabel">{{(fnGetQualityOption2(bid.TeamId)) === true ? 'Q':''}}</span></label>
                    
                    <label class="calibri17 calibriFont" *ngIf="bid.intStatusTeam === 1 && (bid.Bid/currentOrder.Quantity) > (16 - this.month) && !bid.blnNotSupplies && bid.showExceedBid" style="font-size: 14px;">{{bid.Bid}}</label>
                    <label class="calibri17 calibriFont" *ngIf="bid.intStatusTeam === 1 && (bid.Bid/currentOrder.Quantity) > (16 - this.month) && !bid.blnNotSupplies && !bid.showExceedBid" style="transform: rotateZ(25deg); background-color: black; font-size: 14px; width: 180px; margin-top: 5px;color:white !important">{{getSingleTextLabel('139')}}</label>
                    
                    <label class="calibri17 calibriFont" *ngIf="bid.intStatusTeam >= 2" style="cursor: pointer; font-size: 14px; width: 100%;"> {{getSingleTextLabel('82')}}: <span class="bidPrepareHasExceedsMaxPrice" *ngIf="fnGetQualityOption2(bid.TeamId) == true"> {{bid.Bid  }} </span> <span *ngIf="fnGetQualityOption2(bid.TeamId) == true">-</span> {{(fnGetQualityOption2(bid.TeamId)) === true ? bid['BidQuality']:bid.Bid}}<span class="revealBidCardQualityLabel">{{(fnGetQualityOption2(bid.TeamId)) === true ? 'Q':''}}</span></label> <br>
                    <label class="calibri17 calibriFont" *ngIf="bid.intStatusTeam >= 2" style="cursor: pointer; font-size: 14px;"> {{getSingleTextLabel('6022')}}: {{fnGetTeamVersus(bid.TeamId)}}</label>
                </div>
            </div>
        </div>    
    </div>

    <ngx-spinner bdColor="rgba(0,0,0,0.75)" size="large" color="#ffffff" type="ball-clip-rotate-multiple"
        [fullScreen]="true">
        <p style="color: white"> Loading... </p>
    </ngx-spinner>


    <!--div id="coin" [ngClass]="strClassCoin" style="position: absolute;top: 300px; left: 650px;" *ngIf="true">
        <img src="../../../../assets/flipCoin.png" style="width: 150px; height: 150px; position: relative;" alt="">
        <div class="side head"><label class="row" style="justify-content: center; padding-top: 65px; font-weight: 700; font-size: 12px;">FIRST</label></div>
        <div class="side tail"><label class="row" style="justify-content: center; padding-top: 65px; font-weight: 700; font-size: 12px;">SECOND</label></div>
    </div-->

    <div id="roulette" style="position: absolute;top: 200px; left: 550px; z-index: 100;" *ngIf="blnIsFacilitator && strClassRoulette !== ''">
        <img src="../../../../assets/rueda-de-color.png" [ngClass]="strClassRoulette" style="width: 200px; height: 200px; position: relative;" alt="">
        <img src="../../../../assets/punta-de-flecha.png" style="width: 40px; height: 40px; position: relative;" alt="">
        <div class="side head div-text-roulette"><label class="row text-roulette calibri16 calibriFont">{{strWinnerRoulette}}</label></div>
    </div>


</mat-dialog-content>

<mat-dialog-actions align="center" style="margin-bottom: 0 !important;">
    <i class="fas fa-arrow-circle-left mx-3" style="cursor: pointer; font-size: 40px;" 
    *ngIf="view > 2"  (click)="fnPreviousView()"></i>
    <!-- <button *ngIf="blnIsFacilitator && view <= 2" type="button" class="btn btn-primary mx-3 style8 calibriFont" id="btnsaveht"
    (click)="fnSave()">&nbsp;&nbsp;&nbsp;{{getSingleTextLabel('136')}}&nbsp;&nbsp;&nbsp;</button> -->
    <button *ngIf="blnIsFacilitator && view == 1" type="button" class="btn btn-primary mx-3 style8 calibriFont" id="btnsaveht" [disabled]="view === 1 && marketStep !== 2 || finishMarket == 0"
    (click)="fnSave()">&nbsp;&nbsp;&nbsp;{{view === 1 ? 'Run Market' : getSingleTextLabel('136')}}&nbsp;&nbsp;&nbsp;</button>
    <button *ngIf="blnIsFacilitator && view == 2 && !blnEmpate" type="button" class="btn btn-primary mx-3 style8 calibriFont" id="btnsaveht" [disabled]="marketStep !== 2"
    (click)="fnFinishMarket()">&nbsp;&nbsp;&nbsp;{{getSingleTextLabel('136')}}&nbsp;&nbsp;&nbsp;</button>
    <button *ngIf="blnIsFacilitator" class="btn btn-secondary mx-3 style9 calibriFont" id="btncloseHide" (click)="matDialogRef.close()" >{{getSingleTextLabel('123')}}</button>
    <button *ngIf="(view-2) === (arrOrders.length)" type="button" class="btn btn-primary mx-3 style8 calibriFont"
    (click)="fnCloseMarket()">&nbsp;&nbsp;{{getSingleTextLabel('6020')}}&nbsp;&nbsp;</button>
    <button *ngIf="!blnIsFacilitator" type="button" class="btn btn-primary style8 calibriFont" id="btncloseHide" (click)="fnCloseDialog()"
    > &nbsp;&nbsp;&nbsp;&nbsp;Save&nbsp;&nbsp;&nbsp;&nbsp; </button>
    <i class="fas fa-arrow-circle-right mx-3" style="cursor: pointer; font-size: 40px;"  
    *ngIf="view > 2 && (view-2) < (arrOrders.length) && this.blnArrow" (click)="fnNextView()"></i>
</mat-dialog-actions>

<ng-template #timer id="timer">
    <div matDialogTitle>
        <h3 class="titleTimerDialog calibri16 calibriFont">{{getSingleTextLabel('6025')}}</h3>
        <h3 class="titleTimerDialog calibri16 calibriFont">{{getSingleTextLabel('112')}} {{month === 1 ? '5' : "15"}} {{getSingleTextLabel('140')}}</h3>
        <h3 class="titleTimerDialog calibri16 calibriFont">{{getSingleTextLabel('113')}} {{month === 1 ? '0' : '10'}} {{getSingleTextLabel('140')}}</h3>
    </div>
    <mat-dialog-content style="display: contents;">
        <div style="display: inline-flex; text-align: center; width: 100%; padding: 20px; place-content: center;">
            <input class="inputTime calibri16 calibriFont" matInput type="number" min="0" max="60" maxlength="2" oninput="validity.valid||(value='');"
            value="00" (change)="fnSetMinutes($event.target.value)" [(ngModel)]="minutes" (keypress)="validateInputNumber($event)"/> : 
            <input class="inputTime calibri16 calibriFont" matInput type="number" min="0" max="60" maxlength="2" oninput="validity.valid||(value='');"
            value="00" (change)="fnSetSeconds($event.target.value)" [(ngModel)]="seconds" (keypress)="validateInputNumber($event)"/>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" style="place-content: center;">
        <button mat-button class="btn btn-primary style8 calibriFont"
        [disabled]="fnValidTime()" (click)="fnGoTimer()">
            &nbsp;&nbsp;&nbsp;&nbsp;{{getSingleTextLabel('136')}}&nbsp;&nbsp;&nbsp;&nbsp;
        </button>
        <button mat-button class="btn btn-secondary mx-3 style9 calibriFont" (click)="fnCloseTimer()">{{getSingleTextLabel('123')}}</button>
    </mat-dialog-actions>
</ng-template>