import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CdkDragDrop, copyArrayItem, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { TeamService } from 'src/app/services/team/team.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, findIndex } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
//import { threadId } from 'node:worker_threads';

@Component({
  selector: 'app-setup-team',
  templateUrl: './setup-team.component.html',
  styleUrls: ['./setup-team.component.scss']
})
export class SetupTeamComponent implements OnInit {
  Cycles = 0;
  room = "";
  canDeleteOrAdd = false;
  blnIsColorSelected = false;
  blnEnableEditAvatar = false;
  canEdit = true;
  teamId = 0;
  teamIdOriginal = 0;
  localTeamName="";
  strBackground="";
  receivedName="";
  receivedBackground="";
  blnFacilitador=false;
  workShopId;
  strFacilitators="";
  strObservers="";
  boardMode: ""
  blnSwitch = false;
  teamForm: FormGroup;
  arrTeams= [];
  teamsColors = [
    { id: 1, selected: false, color: '#7faeff', disabled: false },
    { id: 2, selected: false, color: '#50b1a8', disabled: false },
    { id: 3, selected: false, color: '#af753a', disabled: false },
    { id: 4, selected: false, color: '#7f2378', disabled: false },
    { id: 5, selected: false, color: '#083584', disabled: false },
    { id: 6, selected: false, color: '#4b0fae', disabled: false },
    { id: 7, selected: false, color: '#bec3ff', disabled: false },
    { id: 8, selected: false, color: '#84eeeb', disabled: false },
    { id: 9, selected: false, color: '#ffe998', disabled: false },
    { id: 10, selected: false, color: '#e4e4ec', disabled: false }
    //El color con id 10 está para seleccionarse por defecto en la vista setup-team
  ];

  colors = [
    "#5EA0CC",
    "#6FB45C",
    "#A7C158",
    "#40874D",
    "#4EA7C8",
    "#244C91",
    "#925D9C",
    "#685D9F",
  ]

  arrMembers = [
  ];

  arrCeo = [];

  arrCfo = [];

  arrCmo = [];

  arrCoo = [];

  arrCto = [];

  arrDeleted = [];

  urlTrash = " ../../../assets/botecerrado.svg";
  selectedAvatar="Facilitator";
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private teamService: TeamService,
    public matDialogRef: MatDialogRef<SetupTeamComponent>,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public _data: any
  ) { 
    console.log(this._data);
    this.teamId = this._data['teamId'];
    this.teamIdOriginal = this._data['teamId'];
    this.receivedBackground = this._data["backgroundActual"];
    this.receivedName = this._data["nameActual"];
    this.blnFacilitador = this._data["blnFacilitador"];
    this.boardMode = this._data["boardMode"];
    this.canEdit = this._data.canEdit;
    // this.workShopId = this._data["workShopId"];
    this.Cycles = this._data["Cycles"];
    this.room = this._data["room"];
    this.canDeleteOrAdd = this._data["canDeleteOrAdd"];
    //gestion de equipos
    //this.arrTeams = this._data["arrTeams"]
    var objFacilitator ={team: {Avatar: this._data["nameActual"], TeamId : this.teamId, Name: "Facilitator"}}
    
    if(this.blnFacilitador)
    {
       this.arrTeams = this._data["arrTeams"].filter(element=>{
          return element.team;
      });
      this.arrTeams.unshift(objFacilitator);
      console.log("arrTeams___:", this.arrTeams)
      /*var lstteamsfiltered = this.arrTeams.filter(el=>{
        if(el.TeamId == this.selectedLang)//view board
          return element;
      }); */
    }
  }

  ngOnInit() {
    this.spinner.show();
    this.fnCrearForm();
    this.fnGetTeamById(this.teamId);
  }


  fnGetTeamById(teamId) {
    console.log("fnGetTeamById", teamId);
    this.teamService.getTeamAndMembersById(teamId).subscribe(async resp => {
      console.log("getTeamAndMembersById", resp);
      this.workShopId = resp['team']['WokshopId'];
      this.fnSetRol(resp['members']);
      if(this.blnFacilitador){
        this.fnSetForm(resp['team']['Avatar']);
        this.fnSetColor(resp['team']['Color']);
        //console.log(resp['team']['Color']);
      }else{
        this.fnSetForm(resp['team']['Avatar']);
      }
      //resp['team']['Avatar'] === null ? this.blnEnableEditAvatar = true: false;
      const setColor = await this.fnSetColor(resp['team']['Color']);
      this.fnIsColorSelected();
      this.fnDisableColors(resp['colors']);
      this.fnSetFacilitators(resp['facilitators']);
      this.fnSetObservers(resp['observers']);
      this.spinner.hide();
    },error=>this.spinner.hide())
  }

  async fnSetRol(arrMembers: any[]) {
    const init = await this.fnInitializeMemberArrays();
    arrMembers.forEach((member,i) => {
      //member['bcolor'] = "#" +  (Math.floor(Math.random()* (9000000))).toString(16);
      member['bcolor'] = this.colors[Math.floor(Math.random() * (this.colors.length ))];
      console.log("member['bcolor']",member['bcolor']);
      switch (member['Role']) {
        case 'CEO':
          this.arrCeo.push(member);
          break;
        case 'CFO':
          this.arrCfo.push(member);
          break;
        case 'CMO':
          this.arrCmo.push(member);
          break;
        case 'COO':
          this.arrCoo.push(member);
          break;
        case 'CTO':
          this.arrCto.push(member);
          break;
        case null || '':
          this.arrMembers.push(member);
          break;
        default:
          this.arrMembers.push(member);
      }
    });
    if(arrMembers.length === 0 && this.blnFacilitador){
      this.arrMembers.push({Role: "",bcolor: "#925D9C", nameComplete: "Name 1"});
      this.arrMembers.push({Role: "",bcolor: "#925D9C", nameComplete: "Name 2"});
    }
  }

  fnInitializeMemberArrays(){
    this.arrCeo = [];
    this.arrCfo = [];
    this.arrCmo = [];
    this.arrCoo = [];
    this.arrCto = [];
    this.arrMembers = [];
    return true
  }

  fnCrearForm() {
    this.teamForm = new FormGroup({
      lstteamsCtrl : new FormControl(),//;new FormControl({},[]),
      teamName: new FormControl( '', [Validators.required]),
      
    });
    console.log(this.teamForm);
  }

  fnSetForm(teamName) {
    if(this.blnFacilitador && teamName === null){
      return;
    }
    this.teamForm.setValue({
      teamName: teamName,
      lstteamsCtrl: {}
    });
    if(!this.blnFacilitador){    
    // teamName !== null && teamName !== '' ? 
    // this.teamForm.controls['teamName'].disable(): 
    // this.teamForm.controls['teamName'].enable();
    }else{
      this.teamForm.controls['teamName'].enable();
    }

  }

  fnSetColor(color) {
    this.teamsColors.forEach(teamColor => {
      if(teamColor.color === color)
        teamColor.selected = true;
    });
  }

  fnSaveTeam() {
    this.spinner.show();
    let jsnTeam = this.teamForm.getRawValue();
    console.log("jsnTeam SAVE", jsnTeam);
    let members: any[];
    let color = this.teamsColors.find(color => color.selected === true);
    console.log("color SAVE", color);
    if(!this.blnFacilitador){
    this.arrCeo.forEach(ceo => ceo['Role'] = 'CEO')
    let arrCeo = JSON.parse(JSON.stringify([...this.arrCeo]));
    this.arrCfo.forEach(cfo => cfo['Role'] = 'CFO')
    let arrCfo = JSON.parse(JSON.stringify([...this.arrCfo]));
    this.arrCmo.forEach(cmo => cmo['Role'] = 'CMO')
    let arrCmo = JSON.parse(JSON.stringify([...this.arrCmo]));
    this.arrCoo.forEach(cOo => cOo['Role'] = 'COO')
    let arrCoo = JSON.parse(JSON.stringify([...this.arrCoo]));
    this.arrCto.forEach(cto => cto['Role'] = 'CTO')
    let arrCto = JSON.parse(JSON.stringify([...this.arrCto]));
     members = arrCeo.concat(arrCfo.concat(arrCmo.concat(arrCoo.concat(arrCto))));
    console.log("members", members); 
    }
    this.localTeamName =jsnTeam['teamName'];
    //validamos que si  estaba inhabilitado el campo de name team no se cambie por otro que pudieron meter desde el inspeccionador 
    if(!this.blnEnableEditAvatar && this.canEdit && !this.blnFacilitador){
      // if(this.receivedName !== null && this.receivedName !== ''){
      //   jsnTeam['teamName'] = this.receivedName;
      //   this.localTeamName = this.receivedName;
      // }
      
    }
    //backgournd
    switch(color.color) { 
      case null: { 
        
        this.strBackground = "#4b0fae";
        //this.strBackground = "./../../../assets/DARKTHEME2.jpg";
        this._data["blnBackgroundBlue"] = true;
        this._data["blnBackground2"] = false;
        this._data["blnBackground4"] = false;
        this._data["blnBackground5"] = false;
        this._data["blnBackground6"] = false;
        this._data["blnBackground7_8"] = false;
        this._data["blnBackground9"] = false;
         break; 
      } 
      case "#7faeff": { 
        this.strBackground = "#7faeff";
        //this.strBackground = "./../../../assets/MIDTHEME3.jpg";
        this._data["blnBackgroundBlue"] = false;
        this._data["blnBackground2"] = false;
        this._data["blnBackground4"] = false;
        this._data["blnBackground5"] = false;
        this._data["blnBackground6"] = false;
        this._data["blnBackground7_8"] = false;
        this._data["blnBackground9"] = false;
         break; 
      } 
      case "#50b1a8" : { 
        this.strBackground = "#50b1a8";
        //this.strBackground = "./../../../assets/MIDTHEME2.jpg";
        this._data["blnBackgroundBlue"] = false;
        this._data["blnBackground2"] = true;
        this._data["blnBackground4"] = false;
        this._data["blnBackground5"] = false;
        this._data["blnBackground6"] = false;
        this._data["blnBackground7_8"] = false;
        this._data["blnBackground9"] = false;
         break; 
      }
      case "#af753a" : { 
        this.strBackground = "#af753a";
        //this.strBackground = "./../../../assets/MIDTHEME1.jpg";
        this._data["blnBackgroundBlue"] = false;
        this._data["blnBackground2"] = false;
        this._data["blnBackground4"] = false;
        this._data["blnBackground5"] = false;
        this._data["blnBackground6"] = false;
        this._data["blnBackground7_8"] = false;
        this._data["blnBackground9"] = false;
         break; 
      }
      case "#7f2378" : { 
        this.strBackground = "#7f2378";
        //this.strBackground = "./../../../assets/DARKTHEME3.jpg";
        this._data["blnBackgroundBlue"] = false;
        this._data["blnBackground2"] = false;
        this._data["blnBackground4"] = true;
        this._data["blnBackground5"] = false;
        this._data["blnBackground6"] = false;
        this._data["blnBackground7_8"] = false;
        this._data["blnBackground9"] = false;
         break; 
      }
      case "#083584" : { 
        this.strBackground = "#083584";
        //this.strBackground = "./../../../assets/DARKTHEME2.jpg";
        this._data["blnBackgroundBlue"] = false;
        this._data["blnBackground2"] = false;
        this._data["blnBackground4"] = false;
        this._data["blnBackground5"] = true;
        this._data["blnBackground6"] = false;
        this._data["blnBackground7_8"] = false;
        this._data["blnBackground9"] = false;
         break; 
      }
      case "#4b0fae" : { 
        this.strBackground = "#4b0fae";
        //this.strBackground = "./../../../assets/DARKTHEME1.jpg";
        this._data["blnBackgroundBlue"] = false;
        this._data["blnBackground2"] = false;
        this._data["blnBackground4"] = false;
        this._data["blnBackground5"] = false;
        this._data["blnBackground6"] = true;
        this._data["blnBackground7_8"] = false;
        this._data["blnBackground9"] = false;
         break; 
      }
      case "#bec3ff" : { 
        this.strBackground = "#bec3ff";
        //this.strBackground = "./../../../assets/LIGHTTHEME3.jpg";
        this._data["blnBackgroundBlue"] = false;
        this._data["blnBackground2"] = false;
        this._data["blnBackground4"] = false;
        this._data["blnBackground5"] = false;
        this._data["blnBackground6"] = false;
        this._data["blnBackground7_8"] = true;
        this._data["blnBackground9"] = false;
         break; 
      }
      case "#84eeeb" : { 
        this.strBackground = "#84eeeb";
        //this.strBackground = "./../../../assets/LIGHTTHEME2.jpg";
        this._data["blnBackgroundBlue"] = false;
        this._data["blnBackground2"] = false;
        this._data["blnBackground4"] = false;
        this._data["blnBackground5"] = false;
        this._data["blnBackground6"] = false;
        this._data["blnBackground7_8"] = true;
        this._data["blnBackground9"] = false;
         break; 
      }
      case "#ffe998" : { 
        this.strBackground = "#ffe998";
        //this.strBackground = "./../../../assets/LIGHTTHEME1.jpg";
        this._data["blnBackgroundBlue"] = false;
        this._data["blnBackground2"] = false;
        this._data["blnBackground4"] = false;
        this._data["blnBackground5"] = false;
        this._data["blnBackground6"] = false;
        this._data["blnBackground7_8"] = false;
        this._data["blnBackground9"] = true;
         break; 
      }
      default: { 
        
        this.strBackground = "#4b0fae";
        //this.strBackground = "./../../../assets/DARKTHEME2.jpg";
        this._data["blnBackgroundBlue"] = true;
         break; 
      }
    }

    if(this.blnFacilitador){
      this.teamService.updateTeamByID(this.teamId,this.workShopId,jsnTeam['teamName'],color['color'],[],this.strBackground,this._data).pipe(finalize(() => this.spinner.hide())).subscribe(resp => {
        console.log("updateTeamByID",resp);
        let message = ''
        if (resp['strAnswer'] === 'The Team was edited successfully!') {
          message = this.getErrorLabel('1092')
        } else if (resp['strAnswer'] === 'Company already exists!') {
          message = this.getErrorLabel('1093')
        } else if (resp['strAnswer'] === 'Color already exists!') {
          message = this.getErrorLabel('1094')
        } else if (resp['strAnswer'] === 'Company already exists!Color already exists!') {
          message = this.getErrorLabel('1095')
        }else{
          message = resp['strAnswer']
        }
        this.fnShowMessageResponse(resp['intResponse'], message);
      })
    }else{
      this.teamService.updateTeamByID(this.teamId,this.workShopId,jsnTeam['teamName'],color['color'],members,this.strBackground,this._data).pipe(finalize(() => this.spinner.hide())).subscribe(resp => {
        console.log("updateTeamByID",resp);
        let message = ''
        if (resp['strAnswer'] === 'The Team was edited successfully!') {
          message = this.getErrorLabel('1092')
        } else if (resp['strAnswer'] === 'Company already exists!') {
          message = this.getErrorLabel('1093')
        } else if (resp['strAnswer'] === 'Color already exists!') {
          message = this.getErrorLabel('1094')
        } else if (resp['strAnswer'] === 'Company already exists!Color already exists!') {
          message = this.getErrorLabel('1095')
        }else{
          message = resp['strAnswer']
        }
        this.fnShowMessageResponse(resp['intResponse'], message);
      })
    }
    
  }

  fnShowMessageResponse(intResponse, swalmessage){
    let strImg = '';
    if(intResponse === 203)
      strImg = '../../../../assets/iconPrevention.svg'
    else{
      strImg = '../../../../assets/iconSuccessfully.svg'
      console.log("showresponse fn teamOrig:", this.teamIdOriginal, this.teamId);
      console.log("this.localTeamName",this.localTeamName, " this.receivedName:", this.receivedName);
      if(this.teamIdOriginal == this.teamId){
        this.matDialogRef.close({teamName:this.localTeamName,teamId:this.teamId, strBackground: this.strBackground, blnBackgroundBlue: this._data["blnBackgroundBlue"], blnBackground2: this._data["blnBackground2"], blnBackground4: this._data["blnBackground4"], blnBackground5: this._data["blnBackground5"], blnBackground6: this._data["blnBackground6"], blnBackground7_8: this._data["blnBackground7_8"], blnBackground9: this._data["blnBackground9"] });
      }else
      {//regresamos los datos del facilitador como los originales ya que no cambio
        this.matDialogRef.close({teamName: this.receivedName, strBackground: this.receivedBackground, blnBackgroundBlue: this._data["blnBackgroundBlue"], blnBackground2: this._data["blnBackground2"], blnBackground4: this._data["blnBackground4"], blnBackground5: this._data["blnBackground5"], blnBackground6: this._data["blnBackground6"], blnBackground7_8: this._data["blnBackground7_8"], blnBackground9: this._data["blnBackground9"] }); 
      }
     
    }
    Swal.fire({
      text: '',
      imageUrl: strImg,
      //imageAlt: 'icon successfully',
      confirmButtonColor: '#6FB45C',
      html: swalmessage,
    });
  }

  fnCancel() {
    this.matDialogRef.close({teamName: this.receivedName, strBackground: this.receivedBackground, blnBackgroundBlue: this._data["blnBackgroundBlue"], blnBackground2: this._data["blnBackground2"], blnBackground4: this._data["blnBackground4"], blnBackground5: this._data["blnBackground5"], blnBackground6: this._data["blnBackground6"], blnBackground7_8: this._data["blnBackground7_8"], blnBackground9: this._data["blnBackground9"] });  
  }


  fnSelectColor(idColor) {
    if(!this.canEdit && !this.blnFacilitador){
      return;
    }
    this.teamsColors.forEach(teamColor => teamColor.selected = false);
    let teamColor = this.teamsColors.find(teamColor => teamColor.id == idColor);
    console.log("teamColor", teamColor);
    teamColor.selected = true;
    this.fnIsColorSelected();
  }

  fnUnableColor(){
    Swal.fire({
      icon: 'info',
      text: '',
      confirmButtonColor: '#6FB45C',
      html: this.getErrorLabel('1094'),
    });
  }

  fnIsColorSelected(){
    this.blnIsColorSelected = false;
    this.teamsColors.forEach(team =>{ if(team.selected == true) this.blnIsColorSelected = true  });
  }

  drop(event: CdkDragDrop<any>) {
    console.log("event",event);
    if(!this.canEdit && !this.blnFacilitador){
      return;
    }
    let i = event.container.data.findIndex(user => user['UserID'] === event.previousContainer.data[event.previousIndex]['UserID']);
    console.log("INDEX",i);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else if(i !== -1){//WHEN EXIST IN THE CONTAINET
      console.log("eventI",event);
    } else if(event.previousContainer.id === "memberList") {//WHEN NOT EXIST IN THE CONTAINET
      copyArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }else{
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  dropDeleted(event: CdkDragDrop<any>) {
    console.log("dropDeleted",event);
    transferArrayItem(event.previousContainer.data,
      event.container.data,
      event.previousIndex,
      event.currentIndex);
    
  }

  fnDisableColors(arrColors: any[]){
    arrColors.forEach(color => {
      for(var i = 0; i <= (this.teamsColors.length -1); i++){
        if(color === this.teamsColors[i].color){
          this.teamsColors[i].disabled = true;
          break;
        }
      }
    });
  }

  fnSetFacilitators(arrFacilitators: any[]){
    arrFacilitators.forEach((facilitator, i) => {
      if(i==0)
        this.strFacilitators+=facilitator['Facilitator'];
      else
        this.strFacilitators+=', '+facilitator['Facilitator'];
    });
  }

  fnSetObservers(arrObservers: any[]){
    arrObservers.forEach((observer, i) => {
      if(i==0)
        this.strObservers+=observer['Observer'];
      else
        this.strObservers+=', '+observer['Observer'];
    });
  }

  cdkStartMoving(){
    console.log("START MOVING");
    this.urlTrash = " ../../../assets/boteabierto.svg"
  }

  cdkEndMoving(){
    console.log("End MOVING");
    this.urlTrash = " ../../../assets/botecerrado.svg"
  }

  getSingleTextLabel(labelNumber: string): string {
    const tmp = this._data.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    return tmp ? tmp.Label : '';
  }

  getErrorLabel(labelNumber: string, code: string | null = null): string {
    const tmp = this._data.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    let msg = ''
    if (tmp) {
      const LabelBase = tmp.LabelBase;
      let errorCode = code;
      if (!code) {
        const array = LabelBase.split(':');
        errorCode = array.length > 0 ? array[0] : '';
      }
      const className = environment.isProd ? 'lightgray' : 'gray';
      msg = `<div><span style="color:${className}; font-size: 12px">${errorCode}: </span>${tmp.Label}</div>`;
    }
    return msg;
  }
  

  selectTeam(objteam){
    console.log("team selected::", objteam.team);
    this.selectedAvatar = objteam.team.Avatar;
    this.teamId = objteam.team.TeamId;
    this.fnGetTeamByIdFacilitator(objteam.team.TeamId);//this.teamId)
    
  }
  fnGetTeamByIdFacilitator(teamId) {
    console.log("fnGetTeamById", teamId);
    this.teamService.getTeamAndMembersById(teamId).subscribe(async resp => {
      console.log("getTeamAndMembersById", resp);
      this.workShopId = resp['team']['WokshopId'];
      this.fnSetRol(resp['members']);
      this.fnSetForm(resp['team']['Avatar']);
      this. fnCleanColor();
      const setColor = await this.fnSetColor(resp['team']['Color']);
      this.fnIsColorSelected();
      this.fnDisableColors(resp['colors']);
      //this.fnSetFacilitators(resp['facilitators']);
      //this.fnSetObservers(resp['observers']);
      this.spinner.hide();
    },error=>this.spinner.hide())
  }

  fnSaveTeamFacilitator() {
    this.spinner.show();
    let jsnTeam = this.teamForm.getRawValue();
    console.log("Facilitator buttoon jsnTeam SAVE:", jsnTeam);
    let members: any[];
    let color = this.teamsColors.find(color => color.selected === true);
    console.log("color SAVE", color);
    if(this.teamIdOriginal != this.teamId ){//!this.blnFacilitador
    this.arrCeo.forEach(ceo => ceo['Role'] = 'CEO')
    let arrCeo = JSON.parse(JSON.stringify([...this.arrCeo]));
    this.arrCfo.forEach(cfo => cfo['Role'] = 'CFO')
    let arrCfo = JSON.parse(JSON.stringify([...this.arrCfo]));
    this.arrCmo.forEach(cmo => cmo['Role'] = 'CMO')
    let arrCmo = JSON.parse(JSON.stringify([...this.arrCmo]));
    this.arrCoo.forEach(cOo => cOo['Role'] = 'COO')
    let arrCoo = JSON.parse(JSON.stringify([...this.arrCoo]));
    this.arrCto.forEach(cto => cto['Role'] = 'CTO')
    let arrCto = JSON.parse(JSON.stringify([...this.arrCto]));
     members = arrCeo.concat(arrCfo.concat(arrCmo.concat(arrCoo.concat(arrCto))));
    console.log("members", members); 
    }
    this.localTeamName =jsnTeam['teamName'];
    /*//validamos que si  estaba inhabilitado el campo de name team no se cambie por otro que pudieron meter desde el inspeccionador 
    if(!this.blnEnableEditAvatar && this.canEdit && !this.blnFacilitador){
      if(this.receivedName !== null && this.receivedName !== ''){
        jsnTeam['teamName'] = this.receivedName;
        this.localTeamName = this.receivedName;
      }
      
    }*/
    //backgournd
    switch(color.color) { 
      case null: { 
        
        this.strBackground = "#4b0fae";
        //this.strBackground = "./../../../assets/DARKTHEME2.jpg";
        this._data["blnBackgroundBlue"] = true;
        this._data["blnBackground2"] = false;
        this._data["blnBackground4"] = false;
        this._data["blnBackground5"] = false;
        this._data["blnBackground6"] = false;
        this._data["blnBackground7_8"] = false;
        this._data["blnBackground9"] = false;
         break; 
      } 
      case "#7faeff": { 
        this.strBackground = "#7faeff";
        //this.strBackground = "./../../../assets/MIDTHEME3.jpg";
        this._data["blnBackgroundBlue"] = false;
        this._data["blnBackground2"] = false;
        this._data["blnBackground4"] = false;
        this._data["blnBackground5"] = false;
        this._data["blnBackground6"] = false;
        this._data["blnBackground7_8"] = false;
        this._data["blnBackground9"] = false;
         break; 
      } 
      case "#50b1a8" : { 
        this.strBackground = "#50b1a8";
        //this.strBackground = "./../../../assets/MIDTHEME2.jpg";
        this._data["blnBackgroundBlue"] = false;
        this._data["blnBackground2"] = true;
        this._data["blnBackground4"] = false;
        this._data["blnBackground5"] = false;
        this._data["blnBackground6"] = false;
        this._data["blnBackground7_8"] = false;
        this._data["blnBackground9"] = false;
         break; 
      }
      case "#af753a" : { 
        this.strBackground = "#af753a";
        //this.strBackground = "./../../../assets/MIDTHEME1.jpg";
        this._data["blnBackgroundBlue"] = false;
        this._data["blnBackground2"] = false;
        this._data["blnBackground4"] = false;
        this._data["blnBackground5"] = false;
        this._data["blnBackground6"] = false;
        this._data["blnBackground7_8"] = false;
        this._data["blnBackground9"] = false;
         break; 
      }
      case "#7f2378" : { 
        this.strBackground = "#7f2378";
        //this.strBackground = "./../../../assets/DARKTHEME3.jpg";
        this._data["blnBackgroundBlue"] = false;
        this._data["blnBackground2"] = false;
        this._data["blnBackground4"] = true;
        this._data["blnBackground5"] = false;
        this._data["blnBackground6"] = false;
        this._data["blnBackground7_8"] = false;
        this._data["blnBackground9"] = false;
         break; 
      }
      case "#083584" : { 
        this.strBackground = "#083584";
        //this.strBackground = "./../../../assets/DARKTHEME2.jpg";
        this._data["blnBackgroundBlue"] = false;
        this._data["blnBackground2"] = false;
        this._data["blnBackground4"] = false;
        this._data["blnBackground5"] = true;
        this._data["blnBackground6"] = false;
        this._data["blnBackground7_8"] = false;
        this._data["blnBackground9"] = false;
         break; 
      }
      case "#4b0fae" : { 
        this.strBackground = "4b0fae";
        //this.strBackground = "./../../../assets/DARKTHEME1.jpg";
        this._data["blnBackgroundBlue"] = false;
        this._data["blnBackground2"] = false;
        this._data["blnBackground4"] = false;
        this._data["blnBackground5"] = false;
        this._data["blnBackground6"] = true;
        this._data["blnBackground7_8"] = false;
        this._data["blnBackground9"] = false;
         break; 
      }
      case "#bec3ff" : { 
        this.strBackground = "#bec3ff";
        //this.strBackground = "./../../../assets/LIGHTTHEME3.jpg";
        this._data["blnBackgroundBlue"] = false;
        this._data["blnBackground2"] = false;
        this._data["blnBackground4"] = false;
        this._data["blnBackground5"] = false;
        this._data["blnBackground6"] = false;
        this._data["blnBackground7_8"] = true;
        this._data["blnBackground9"] = false;
         break; 
      }
      case "#84eeeb" : { 
        this.strBackground = "#84eeeb";
        //this.strBackground = "./../../../assets/LIGHTTHEME2.jpg";
        this._data["blnBackgroundBlue"] = false;
        this._data["blnBackground2"] = false;
        this._data["blnBackground4"] = false;
        this._data["blnBackground5"] = false;
        this._data["blnBackground6"] = false;
        this._data["blnBackground7_8"] = true;
        this._data["blnBackground9"] = false;
         break; 
      }
      case "#ffe998" : { 
        this.strBackground = "#ffe998";
        //this.strBackground = "./../../../assets/LIGHTTHEME1.jpg";
        this._data["blnBackgroundBlue"] = false;
        this._data["blnBackground2"] = false;
        this._data["blnBackground4"] = false;
        this._data["blnBackground5"] = false;
        this._data["blnBackground6"] = false;
        this._data["blnBackground7_8"] = false;
        this._data["blnBackground9"] = true;
         break; 
      }
      default: { 
        
        this.strBackground = "#4b0fae";
        //this.strBackground = "./../../../assets/DARKTHEME2.jpg";
        this._data["blnBackgroundBlue"] = true;
         break; 
      }
    }

    if(this.blnFacilitador && this.teamIdOriginal == this.teamId){
      this.teamService.updateTeamByID(this.teamId,this.workShopId,jsnTeam['teamName'],color['color'],[],this.strBackground,this._data).pipe(finalize(() => this.spinner.hide())).subscribe(resp => {
        console.log("updateTeamByID",resp);
        let message = ''
        if (resp['strAnswer'] === 'The Team was edited successfully!') {
          message = this.getErrorLabel('1092')
        } else if (resp['strAnswer'] === 'Company already exists!') {
          message = this.getErrorLabel('1093')
        } else if (resp['strAnswer'] === 'Color already exists!') {
          message = this.getErrorLabel('1094')
        } else if (resp['strAnswer'] === 'Company already exists!Color already exists!') {
          message = this.getErrorLabel('1095')
        }else{
          message = resp['strAnswer']
        }
        this.fnShowMessageResponse(resp['intResponse'], message);
      })
    }else{
      this.teamService.updateTeamByID(this.teamId,this.workShopId,jsnTeam['teamName'],color['color'],members,this.strBackground,this._data).pipe(finalize(() => this.spinner.hide())).subscribe(resp => {
        console.log("updateTeamByID",resp);
        let message = ''
        if (resp['strAnswer'] === 'The Team was edited successfully!') {
          message = this.getErrorLabel('1092')
        } else if (resp['strAnswer'] === 'Company already exists!') {
          message = this.getErrorLabel('1093')
        } else if (resp['strAnswer'] === 'Color already exists!') {
          message = this.getErrorLabel('1094')
        } else if (resp['strAnswer'] === 'Company already exists!Color already exists!') {
          message = this.getErrorLabel('1095')
        }else{
          message = resp['strAnswer']
        }
        this.fnShowMessageResponse(resp['intResponse'], message);
      })
    }
    
  }

  fnCleanColor() {
    this.teamsColors.forEach(teamColor => {
        teamColor.selected = false;
    });
  }

  get lstUsers() {
    return this._data.lstUsers;
  }

  /*
  The Team was edited successfully!
  Company already exists!
  Color already exists!
  Company already exists!Color already exists!
  */
}
