import { Component, OnInit,Inject, Input, } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { ContentObserver } from '@angular/cdk/observers';
import { element } from 'protractor';
import Swal from 'sweetalert2';
import { UsuarioService } from '../../services/usuario/usuario.service';


@Component({
  selector: 'app-changeboard',
  templateUrl: './changeboard.component.html',
  styleUrls: ['./changeboard.component.scss']
})
export class ChangeboardComponent implements OnInit {
  selectedteam= [];
  lstTeams = [];
  showHome = false;
  @Input() userTeamID: number;
  constructor( 
    public matDialogRef: MatDialogRef<ChangeboardComponent>,
    private usuarioService: UsuarioService,
      @Inject(MAT_DIALOG_DATA) private _data: any
    ) {console.log("entre componente change BOARD!!!");
      console.log('this._data', this._data,this._data.actualTeam );
      //this.lstTeams =this._data.teamsList;
      if(this._data.actualTeam  != 0)
      {
        console.log("idteam diferente 0");
        this._data.teamsList.forEach(element => {
         
          if(element.TeamId == this._data.actualTeam){
           element.check = true;
            this.selectedteam = element;
            console.log("encontramos element asignamos a modelo this.selectedteam:",this.selectedteam)
          }else{
            element.check = false;
          }
          this.lstTeams.push(element);
          console.log("this.lstTeams", this.lstTeams);
        });
      }
      
    }

  ngOnInit(): void {
    if(this.userTeamID == this._data.actualTeam){
      console.log("MI EQUIPOOOOOOO")
    }else{
      console.log("EQUIPO DISTINTO")
      this.showHome = true;
    }
    console.log("entre ViewAuthUserBoardComponent"+this.userTeamID);
  }


  async fnSelectTeam(){
    var jsnteamSelct = this.lstTeams.filter(el=>{
      if(el.TeamId == this.selectedteam)//view board
        return el;
    });
    console.log("entra fn person",jsnteamSelct);

      //significa que el usuario es un facilitador
     
      this.matDialogRef.close({
        jsnTeamSelected: jsnteamSelct
      });
    
  }
  async fngoHome(){
    var jsnteamSelct = this.lstTeams.filter(el=>{
    if(el.TeamId == this.userTeamID)//view board
      return el;
  });
    this.matDialogRef.close({
      jsnTeamSelected: jsnteamSelct
    });
  }
  fnCancel() {
    this.matDialogRef.close({
      jsnTeamSelected: 0
    });
  }

  getSingleTextLabel(labelNumber: string): string {
    const tmp = this._data.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    return tmp ? tmp.Label : '';
  }

}
