<div mat-dialog-title style="border-bottom: 1px solid #f3f3f3; width: 100% !important">
  &nbsp; &nbsp; &nbsp; &nbsp;<label class="FontMontserratSubTitles">{{txtLanguageSelected}} <span *ngIf="viewOnly">(View Only)</span></label>
  <br>

  <div class="row">
    <div class="col-8">
      &nbsp; &nbsp; &nbsp; &nbsp;<label class="FontMontserratSubTitles">{{lblTitle}}</label>

    </div>
    <div class="col-4 text-right" style="padding-right: 40px;">
      <div class="" style="display: block;">
        <div class="header center-input mt-20" fxLayout="row">
          <div class="search-wrapper">
            <div style="display: inline-flex;">
              <mat-icon class="secondary-text" style="display: inline-flex; align-self: center;">search</mat-icon>
              <input class="search" placeholder="Search" (keyup)="fnSearch($event)" value="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<mat-dialog-content class="mat-typography" style="height: 80% !important;">
  <div *ngIf="blnEdit == true"> &nbsp; &nbsp;<label class="FontMontserratText">Modify the text in the right column of
      the desired label, then click on the 'Save' button to save the changes.</label>
  </div>
  <div *ngIf="blnEdit == false"> &nbsp; &nbsp;<label class="FontMontserratText">Fill the text in the right column of
      the desired label, then click on the 'Save' button to save the changes.</label>
  </div>
  
  <div class="container">

    <table mat-table [dataSource]="dataSourceTable" style="border: 1px solid #D5DCE3" class="data-row" matSort
      aria-label="Elements">

      <ng-container matColumnDef="label" style="width: 15% !important;">
        <th mat-header-cell *matHeaderCellDef>
          <div class="mat-header-cell-text" align="center" style="width: 95% !important;">
            Label Number
          </div>
        </th>

        <td mat-cell *matCellDef="let row" style="width: 15% !important;">
          <label class="FontMontserratTextTables">{{ row.LabelNumber }}</label>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container  matColumnDef="labelkeyword" style="width: 25% !important;">
        <th mat-header-cell *matHeaderCellDef>
          <div class="mat-header-cell-text" align="center" style="width: 95% !important;">
            Keywords
          </div>
        </th>

        <td mat-cell *matCellDef="let row" style="width: 25% !important;" class="FontMontserratTextTables">
          {{ row.LabelKeywords }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="labeldefaulttext" style="width: 30% !important;">
        <th mat-header-cell *matHeaderCellDef>
          <div class="mat-header-cell-text" align="center" style="width: 95% !important;">
            Default Text
          </div>
        </th>

        <td mat-cell *matCellDef="let row" style="width: 30% !important;" class="FontMontserratTextTables">
          <!-- temp -->
          <ng-container *ngIf="isChief; else editDefaultText">
            
            <ng-container *ngIf="row.ScreenId == 10 && row.version != 'S'; else elseScreen">
              <span>{{row.LabelBaseSplit[0]}}</span><textarea id="entry{{row.LabelId}}" maxlength="500" rows="5" cols="50"
                style="width: 100% !important;font-family: 'Montserrat';color: #334D6E;font-weight: 500;align-content:center; overflow:auto;"
                [(ngModel)]="row.LabelBaseSplit[1]">{{row.LabelBaseSplit[1]}}</textarea>
            </ng-container>
            <ng-template #elseScreen>
              <textarea id="entry{{row.LabelId}}" maxlength="500" rows="5" cols="50"
                style="width: 100% !important;font-family: 'Montserrat';color: #334D6E;font-weight: 500;align-content:center; overflow:auto;"
                [(ngModel)]="row.LabelBase">{{row.LabelBase}}</textarea>
            </ng-template>
          </ng-container>
          <ng-template #editDefaultText>
            
            {{ row.LabelBase }}
          </ng-template>
          
          
          <!-- temp -->
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <!-- temp -->
      <ng-container *ngIf="isChief">
        <ng-container matColumnDef="btnUpdate" style="width: 15% !important;">
          <th mat-header-cell *matHeaderCellDef>
            <div class="mat-header-cell-text" align="center" style="width: 95% !important;">
              Update
            </div>
          </th>
  
          <td mat-cell *matCellDef="let row" style="width: 15% !important;" class="FontMontserratTextTables">
            <button class="btn btn-primary" (click)="updateLabelBase(row)" >Update</button>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
      </ng-container>
      
      <!-- temp -->

      <ng-container matColumnDef="showAs" style="width: 30% !important;">
        <th mat-header-cell *matHeaderCellDef>
          <div class="mat-header-cell-text" align="center" style="width: 95% !important;">
            Translation
          </div>
        </th>
        <td mat-cell class="row-table" *matCellDef="let row" style="width: 30% !important;">
          <div *ngIf="blnEdit == true">
            <textarea id="entryTXT{{row.LabelId}}" maxlength="500" rows="5" cols="50"
              style="width: 100% !important;font-family: 'Montserrat';color: #334D6E;font-weight: 500;align-content:center; overflow:auto;"
              (keyup)="fnPreUpdateList(row)">{{row.Label}}</textarea>
          </div>
          <div *ngIf="blnEdit == false">
            <textarea id="entryTXT{{row.LabelId}}" maxlength="500" rows="5" cols="50"
              style="width: 100% !important;font-family: 'Montserrat';color: #334D6E;font-weight: 500;align-content:center; overflow:auto;"
              (keyup)="fnPreUpdateList(row)">{{row.Label}}</textarea>
            <!--(change)="fnCreateList(row)"-->
          </div>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <!--<ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef>
                    <div align="center">
                      <label class="mat-header-cell-text">{{lblActions}}</label>
                    </div>
                  </th>
                  <td mat-cell class="row-table" *matCellDef="let row; let i = index">
                    <i (click)="fnEditUser(row, i)" class="fas fa-user-edit" style="color: #F9AE03;" data-toggle="tooltip"
                      data-placement="top" [attr.title]="lblTooltipEditarUsuario"></i>&nbsp;
                    <i (click)="fnDeleteUser(row, i)" class="fas fa-trash-alt" style="color: #d10808;" data-toggle="tooltip"
                      data-placement="top" [attr.title]="lblTooltipBorrarUsuario"></i>&nbsp;
                    <i (click)="fnChangeStatusUser(row,0, i)" *ngIf="row.Active == 1" class="fas fa-ban" style="color: #f3b020" data-toggle="tooltip"
                    data-placement="top" title="Block User"></i>&nbsp;
                    <i (click)="fnChangeStatusUser(row,1, i)" *ngIf="row.Active == 0" class="fas fa-unlock" style="color: #f3b020" data-toggle="tooltip"
                    data-placement="top" title="Unlock User"></i>&nbsp;
                  </td>
                  <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>-->
      <tr class="tableHeader" mat-header-row *matHeaderRowDef="titleTables"></tr>
      <tr mat-row *matRowDef="let row; columns: titleTables;"></tr>

    </table>
    <mat-paginator style="border: 1px solid #D5DCE3" [pageSize]="pageSize"
      [pageSizeOptions]="[5, 10, 30, 50, 80, 100, 130, 150, 180, 200]" showFirstLastButtons></mat-paginator>
    <!--<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>-->
    <div *ngIf="dataSourceTable.data.length === 0">No records found</div>

  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <!--<div id="divbtnBack">-->
  <button   type="button" class="btn btn-outline-info mx-3" id="btnback" (click)="fnReturn()"
    style="border-radius: 50px;font-family: 'Montserrat';">Back</button>
  <!--</div>
    <div id="divbtnedit">-->
      <label id="lblCheck" name="lblCheck" class="mat-header-cell-text"> Completed <input style="width:25px;height:25px; top: 5px !important" type="checkbox" id="checkComplete" name="checkComplete"></label>
        <button  type="button" class="btn btn-outline-danger mx-3" id="btnclose" (click)="fnHasChanges()"
        style="border-radius: 50px;font-family: 'Montserrat';">Cancel</button>
      &nbsp;&nbsp;
      <button *ngIf="isChief else botonNormal" type="button" class="btn btn-outline-success mx-3" id="btnsaveht"
        style="Background-color:#6FB45C;border-radius: 50px;color: white;font-family: 'Montserrat';"
        (click)="fnSaveEdit()">&nbsp;&nbsp;Save&nbsp;&nbsp;</button>

        <ng-template #botonNormal>
          <button *ngIf="!isChief" type="button" class="btn btn-outline-success mx-3" id="btnsaveht"
          style="Background-color:#6FB45C;border-radius: 50px;color: white;font-family: 'Montserrat';"
          (click)="fnSaveEdit()">&nbsp;&nbsp;Save&nbsp;&nbsp;</button>
        </ng-template>

    <!--</div>-->
    <button type="button" class="btn btn-secondary" id="btncloseHide" (click)="matDialogRef.close({close: true})" hidden>Close</button>
</mat-dialog-actions>