<div class="all" [ngStyle]="{'margin-left.%': data.cycles === 4 ? 6 : 0}">
    <div *ngIf="jsonLabels[10] != undefined" mat-dialog-content cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    
    <!--    <div class="colorblue">
            <h2>{{fnObtenerLabel('44')}}</h2>
            <p>{{fnObtenerLabel('3017')}}</p>
        </div>
    
        <div>
            <p style="text-align: center; font-weight: 500;">{{fnObtenerLabel('3018')}}</p>
        </div>-->
        
    <div class="row" class="marginwhite">
        <div class="grid-container">
            <!-- <div class="marginblack style12 calibriFont" style="width: 359px; text-align: center;"> {{fnObtenerLabel('44')}} </div> -->
            <div class="marginblack style12 calibriFont" style="width: 389px; text-align: center;"> {{fnObtenerLabel('44')}} </div>
            <div class=" marginLeft marginblack" [ngStyle]="{'width.px': 60*arrColumnas.length}"> 
                <div class="style2aBold calibriFont" style="text-align: center;">{{fnObtenerLabel('5013')}}</div>  
                <div class="grid-container" style="margin-left: -2px;">
                    <div class=" marginLeft marginblack style4 calibriFont" style="width: 60px; text-align: center;" *ngFor="let jsnTeam of arrColumnas; let i = index;"> {{i+1}} </div>   
                </div>  
            </div>
        </div>
    </div>
<!-- fin titulo-->

<!---------------------------------------------------------------INICIO 1------------------------------------------->
    <div class="row" class="marginwhite2">
        <div class="grid-container">
            <div class="marginRigth style2b calibriFont textoazul verticalAligmentDiv" style="height: 153px; z-index: 2;">
                <p class="conDesbordado style2b calibriFont" style="margin: 0px;">{{fnObtenerLabel('3019')}}</p></div>
            <div class="textoblanco style3 calibriFont colorFontBlue2 verticalAligmentDiv">{{fnObtenerLabel('46')}}</div>
            <div class="abajo textoInput" *ngFor="let jsnTeam of arrColumnas; let i = index;">
                
            </div>  
        </div>
    </div>
<!-- fin 1.1-->

<div class="row" class="marginwhite2">
    <div class="grid-container">
        <div class="marginRigth colorblue textoazul"> </div>
        <div class="textoblanco style2a calibriFont verticalAligmentDiv">{{fnObtenerLabel('47')}}</div>
        <div class="margin4 textoInput style5b calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;">
            <input (change)="fnChangeInput(jsnTeam['cash'], 'cash', i)"
             [ngStyle]="{'color': cash != jsnTeam['cash'] && intentos >= 3 ? 'red':'black',
            'border': cash != jsnTeam['cash'] && intentos >= 3 ? '2px solid #ff0000':'' }" *ngIf="i == mes && canEdit" type="number" id="input10" matInput class="w" [(ngModel)]="jsnTeam['cash']" (keyup.enter)="validateKey('input20')" onkeypress="return event.charCode >= 48" min="0">
            <div *ngIf="i != mes">
                {{jsnTeam['cash']}}
            </div>
        </div> 
    </div>
</div>
<!-- fin 1.2-->

<div class="row" class="marginwhite2">
    <div class="grid-container">
        <div class="marginRigth colorblue textoazul"> </div>
        <div class="textoblanco style2a calibriFont verticalAligmentDiv">{{fnObtenerLabel('48')}}</div>
        <div class="margin4 textoInput style5b calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;">
            <input (change)="fnChangeInput(jsnTeam['receivables'], 'receivables', i)"
             [ngStyle]="{'color': receivables != jsnTeam['receivables'] && intentos >= 3 ? 'red':'black',
            'border': receivables != jsnTeam['receivables'] && intentos >= 3 ? '2px solid #ff0000':'' }"  *ngIf="i == mes && canEdit" type="number" id="input20" matInput class="w" [(ngModel)]="jsnTeam['receivables']" (keyup.enter)="validateKey('input30')" onkeypress="return event.charCode >= 48" min="0">
            <div *ngIf="i != mes">
                {{jsnTeam['receivables']}}
            </div>
        </div> 
    </div>
</div>
<!-- fin 1.3-->


<div *ngIf="data.businessModel === 'Mfg EBIT'" class="row" class="marginwhite2">
    <div class="grid-container">
        <div class="marginRigth colorblue textoazul"> </div>
        <div class="textoblanco style2a calibriFont verticalAligmentDiv">{{fnObtenerLabel('52')}}</div>
        <div class="margin4 textoInput style5b calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;">
            <input (change)="fnChangeInput(jsnTeam['finishGood'], 'finishGood', i)"
            [ngStyle]="{'color': finishGood != jsnTeam['finishGood'] && intentos >= 3 ? 'red':'black',
            'border': finishGood != jsnTeam['finishGood'] && intentos >= 3 ? '2px solid #ff0000':'' }" *ngIf="i == mes && canEdit" type="number" id="input30" matInput class="w" [(ngModel)]="jsnTeam['finishGood']" (keyup.enter)="validateKey('input40')" onkeypress="return event.charCode >= 48" min="0"> 
            <div *ngIf="i != mes">
                {{jsnTeam['finishGood']}}
            </div>
        </div> 
    </div>
</div>
<!-- fin 1.4-->

<div class="row" class="marginwhite2">
    <div class="grid-container">
        <div class="marginRigth colorblue textoazul"> </div>
        <div class="textoblanco style2a calibriFont verticalAligmentDiv">{{data.businessModel === 'Mfg EBIT' ? fnObtenerLabel('53') : fnObtenerLabel('54')}}</div>
        <div class="margin4 textoInput style5b calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;">
            <input (change)="fnChangeInput(jsnTeam['workProcess'], 'workProcess', i)"
            [ngStyle]="{'color': workProcess != jsnTeam['workProcess'] && intentos >= 3 ? 'red':'black',
            'border': workProcess != jsnTeam['workProcess'] && intentos >= 3 ? '2px solid #ff0000':'' }"  *ngIf="i == mes && canEdit" type="number" id="input40" matInput class="w" [(ngModel)]="jsnTeam['workProcess']" (keyup.enter)="validateKey('input50')" onkeypress="return event.charCode >= 48" min="0">
            <div *ngIf="i != mes">
                {{jsnTeam['workProcess']}}
            </div>
        </div> 
    </div>
</div>
<!-- fin 1.5-->


<div *ngIf="data.businessModel === 'Mfg EBIT'" class="row" class="marginwhite2">
    <div class="grid-container">
        <div class="marginRigth colorblue textoazul"> </div>
        <div class="textoblanco style2a calibriFont verticalAligmentDiv">{{fnObtenerLabel('57')}}</div>
        <div class="margin4 textoInput style5b calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;">
            <input (change)="fnChangeInput(jsnTeam['rawMaterials'], 'rawMaterials', i)"
             [ngStyle]="{'color': rawMaterials != jsnTeam['rawMaterials'] && intentos >= 3 ? 'red':'black',
            'border': rawMaterials != jsnTeam['rawMaterials'] && intentos >= 3 ? '2px solid #ff0000':'' }"  *ngIf="i == mes && canEdit" type="number" id="input50" matInput class="w" [(ngModel)]="jsnTeam['rawMaterials']" (keyup.enter)="validateKey('input60')" onkeypress="return event.charCode >= 48" min="0">
            <div *ngIf="i != mes">
                {{jsnTeam['rawMaterials']}}
            </div>
        </div> 
    </div>
</div>
<!-- fin 1.6-->

<div class="row" class="marginwhite2">
    <div class="grid-container">
        <div class="marginRigth colorblue textoazul"> </div>
        <div class="textoblanco textRigth verticalAligmentDiv" style="display: inline-flex;">
            <div>
                <strong class="calibriFont" style="width: 90%;"> {{fnObtenerLabel('59')}} </strong>
                <span class="equal" style="text-align: right; height: 1px; font-size: 14px; cursor: pointer; width: 10%;">&#61;</span>
            </div>
        </div>
        <div class="margin4 textoInput style6 calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;" style="background-color: #ececf4">
            <input *ngIf="i == mes && canEdit" type="number" id="input60" matInput class="w" [(ngModel)]="jsnTeam['totalCurrent1']" style="background-color: #ececf4; border-color: #ececf4;" (keyup.enter)="validateKey('input70')" onkeypress="return event.charCode >= 48" min="0">
            <div *ngIf="i != mes">
                {{jsnTeam['totalCurrent1']}}
            </div>
        </div> 
    </div>
</div>
<!-- fin 1.7-->
<!--------------------------------------------------------------- fin 1--------------------------------------------->

<!---------------------------------------------------------------INICIO 2------------------------------------------->
<div class="row" class="marginwhite2">
    <div class="grid-container">
        <div class="marginRigth colorblue textoazul verticalAligmentDiv"  style="border-top: 1px solid #099cfd; height: 88px; z-index: 2;"><p class="conDesbordado style2b calibriFont " style="margin: 0px;">{{fnObtenerLabel('3020')}}</p></div>
        <div class="textoblanco style3 calibriFont colorFontBlue2 verticalAligmentDiv"  style="border-top: 1px solid #099cfd;">{{fnObtenerLabel('60')}}</div>
        <div class="abajo textoInput" *ngFor="let jsnTeam of arrColumnas; let i = index;"  style="border-top: 1px solid #099cfd;">
            
        </div>  
    </div>
</div>
<!-- fin 2.1-->

<div class="row" class="marginwhite2">
<div class="grid-container">
    <div class="marginRigth colorblue textoazul"> </div>
    <div class="textoblanco style2a calibriFont verticalAligmentDiv">{{data.businessModel === 'Mfg EBIT' ? fnObtenerLabel('23.1') : fnObtenerLabel('63')}}</div>
    <div class="margin4 textoInput style5b calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;">
        <input (change)="fnChangeInput(jsnTeam['machine'], 'machine', i)"
         [ngStyle]="{'color': machine != jsnTeam['machine'] && intentos >= 3 ? 'red':'black',
        'border': machine != jsnTeam['machine'] && intentos >= 3 ? '2px solid #ff0000':'' }" *ngIf="i == mes && canEdit" type="number" id="input70" matInput class="w" [(ngModel)]="jsnTeam['machine']" (keyup.enter)="validateKey('input80')" onkeypress="return event.charCode >= 48" min="0">
        <div *ngIf="i != mes">
            {{jsnTeam['machine']}}
        </div>
    </div> 
</div>
</div>
<!-- fin 2.2-->

<div class="row" class="marginwhite2">
    <div class="grid-container">
        <div class="marginRigth colorblue textoazul"> </div>
        <div class="textoblanco style2a calibriFont verticalAligmentDiv">{{fnObtenerLabel('61')}}</div>
        <div class="margin4 textoInput style5b calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;">
            <input (change)="fnChangeInput(jsnTeam['land'], 'land', i)"
             [ngStyle]="{'color': landBuildings != jsnTeam['land'] && intentos >= 3 ? 'red':'black',
            'border': landBuildings != jsnTeam['land'] && intentos >= 3 ? '2px solid #ff0000':'' }" *ngIf="i == mes && canEdit" type="number" id="input80" matInput class="w" [(ngModel)]="jsnTeam['land']" (keyup.enter)="validateKey('input90')" onkeypress="return event.charCode >= 48" min="0">
            <div *ngIf="i != mes">
                {{jsnTeam['land']}}
            </div>
        </div> 
    </div>
    </div>
<!-- fin 2.3-->

<div class="row" class="marginwhite2">
<div class="grid-container">
    <div class="marginRigth colorblue textoazul"> </div>
    <div class="textoblanco textRigth verticalAligmentDiv" style="display: inline-flex;"> 
        <div>
            <strong class=" calibriFont" style="width: 90%;"> {{fnObtenerLabel('64')}} </strong>
            <span class="equal" style="text-align: right; height: 1px; font-size: 14px; cursor: pointer; width: 10%;">&#61;</span>
        </div>
    </div>
    <div class="margin4 textoInput style6 calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;" style="background-color: #ececf4">
        <input *ngIf="i == mes && canEdit" type="number" id="input90" matInput class="w" [(ngModel)]="jsnTeam['totalFixed']" style="background-color: #ececf4; border-color: #ececf4;" (keyup.enter)="validateKey('input100')" onkeypress="return event.charCode >= 48" min="0">
        <div *ngIf="i != mes">
            {{jsnTeam['totalFixed']}}
        </div>
    </div> 
</div>
</div>
<!-- fin 2.4-->
<!--------------------------------------------------------------- fin 2--------------------------------------------->

<!---------------------------------------------------------------INICIO 3------------------------------------------->
<div class="row" class="marginwhite2">
    <div class="grid-container">
        <div class="marginRigth colorblue textoazul"><p class="style2b calibriFont">{{fnObtenerLabel('65')}} {{fnObtenerLabel('3025')}}</p></div>
        <div class="textoblanco textRigth verticalAligmentDiv"  style="border-top: 1px solid #099cfd; display: inline-flex;">
            <div>
                <strong class=" calibriFont" style="width: 90%;"> {{fnObtenerLabel('65')}} </strong>
                <span class="equal" style="text-align: right; height: 1px; font-size: 14px; cursor: pointer; width: 10%;">&#61;</span>
            </div>
        </div>
        <div class="margin4 textoInput style7b calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;" style="background-color: #c3d8fe; border-top: 1px solid #099cfd !important">
            <input *ngIf="i == mes && canEdit" type="number" id="input100" matInput class="wblue" [(ngModel)]="jsnTeam['totalCurrent2']" style="border-top: 1px solid #099cfd !important" (keyup.enter)="validateKey('input110')" onkeypress="return event.charCode >= 48" min="0">
            <div *ngIf="i != mes">
                {{jsnTeam['totalCurrent2']}}
            </div>
        </div>   
    </div>
</div>
<!--------------------------------------------------------------- fin 3--------------------------------------------->


<!---------------------------------------------------------------INICIO 4------------------------------------------->
<div class="row" class="marginwhite2">
    <div class="grid-container">
        <div class="marginRigth colorblue textoazul verticalAligmentDiv"  style="border-top: 1px solid #099cfd; height: 109px; z-index: 2;"><p class="conDesbordado style2b calibriFont" style="margin: 0px; height: 109px;">{{fnObtenerLabel('3021')}}</p></div>
        <div class="textoblanco style3 calibriFont colorFontBlue2 verticalAligmentDiv"  style="border-top: 1px solid #099cfd;">{{fnObtenerLabel('66')}}</div>
        <div class="abajo textoInput" *ngFor="let jsnTeam of arrColumnas; let i = index;"  style="border-top: 1px solid #099cfd;">
            
        </div>  
    </div>
</div>
<!-- fin 4.1-->

<div class="row" class="marginwhite2">
<div class="grid-container">
    <div class="marginRigth colorblue textoazul"> </div>
    <div class="textoblanco style2a calibriFont verticalAligmentDiv">{{fnObtenerLabel('67')}}</div>
    <div class="margin4 textoInput style5b calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;">
        <input (change)="fnChangeInput(jsnTeam['payables'], 'payables', i)"
         [ngStyle]="{'color': payables != jsnTeam['payables'] && intentos >= 3 ? 'red':'black',
        'border': payables != jsnTeam['payables'] && intentos >= 3 ? '2px solid #ff0000':'' }" *ngIf="i == mes && canEdit" type="number" id="input110" matInput class="w" [(ngModel)]="jsnTeam['payables']" (keyup.enter)="validateKey('input120')" onkeypress="return event.charCode >= 48" min="0">
        <div *ngIf="i != mes">
            {{jsnTeam['payables']}}
        </div>
    </div> 
</div>
</div>
<!-- fin 4.2-->

<div class="row" class="marginwhite2">
    <div class="grid-container">
        <div class="marginRigth colorblue textoazul"> </div>
        <div class="textoblanco style2a calibriFont verticalAligmentDiv">{{fnObtenerLabel('69')}}</div>
        <div class="margin4 textoInput style5b calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;">
            <input (change)="fnChangeInput(jsnTeam['loans'], 'loans', i)"
             [ngStyle]="{'color': loans != jsnTeam['loans'] && intentos >= 3 ? 'red':'black',
            'border': loans != jsnTeam['loans'] && intentos >= 3 ? '2px solid #ff0000':'' }" *ngIf="i == mes && canEdit" type="number" id="input120" matInput class="w" [(ngModel)]="jsnTeam['loans']" (keyup.enter)="validateKey('input130')" onkeypress="return event.charCode >= 48" min="0">
            <div *ngIf="i != mes">
                {{jsnTeam['loans']}}
            </div>
        </div> 
    </div>
    </div>
<!-- fin 4.3-->

<div class="row" class="marginwhite2">
    <div class="grid-container">
        <div class="marginRigth colorblue textoazul"> </div>
        <div class="textoblanco style2a calibriFont verticalAligmentDiv">{{fnObtenerLabel('78')}}</div>
        <div class="margin4 textoInput style5b calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;">
            <input (change)="fnChangeInput(jsnTeam['taxes'], 'taxes', i)"
             [ngStyle]="{'color': taxes != jsnTeam['taxes'] && intentos >= 3 ? 'red':'black',
            'border': taxes != jsnTeam['taxes'] && intentos >= 3 ? '2px solid #ff0000':'' }" *ngIf="i == mes && canEdit" type="number" id="input130" matInput class="w" [(ngModel)]="jsnTeam['taxes']" (keyup.enter)="validateKey('input140')" onkeypress="return event.charCode >= 48" min="0">
            <div *ngIf="i != mes">
                {{jsnTeam['taxes']}}
            </div>
        </div> 
    </div>
    </div>
<!-- fin 4.4-->

<div class="row" class="marginwhite2">
<div class="grid-container">
    <div class="marginRigth colorblue textoazul"> </div>
    <div class="textoblanco textRigth verticalAligmentDiv" style="display: inline-flex;"> 
        <div>
            <strong class=" calibriFont" style="width: 90%;"> {{fnObtenerLabel('71')}} </strong>
            <span class="equal" style="text-align: right; height: 1px; font-size: 14px; cursor: pointer; width: 10%;">&#61;</span>
        </div>
    </div>
    <div class="margin4 textoInput style6 calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;" style="background-color: #ececf4">
        <input *ngIf="i == mes && canEdit" type="number" id="input140" matInput class="w" [(ngModel)]="jsnTeam['totalLiabilities']" style="background-color: #ececf4; border-color: #ececf4;" (keyup.enter)="validateKey('input150')" onkeypress="return event.charCode >= 48" min="0"
        >
        <div *ngIf="i != mes">
            {{jsnTeam['totalLiabilities']}}
        </div>
    </div> 
</div>
</div>
<!-- fin 4.5-->
<!--------------------------------------------------------------- fin 4--------------------------------------------->

<!---------------------------------------------------------------INICIO 5------------------------------------------->
<div class="row" class="marginwhite2">
    <div class="grid-container">
        <div class="marginRigth colorblue textoazul verticalAligmentDiv"  style="border-top: 1px solid #099cfd; height: 88px; z-index: 2;"><p class="conDesbordado style2b calibriFont" style="margin: 0px; height: 88px;">{{fnObtenerLabel('3022')}}</p></div>
        <div class="textoblanco style3 calibriFont colorFontBlue2 verticalAligmentDiv"  style="border-top: 1px solid #099cfd;">{{fnObtenerLabel('72')}}</div>
        <div class="abajo textoInput" *ngFor="let jsnTeam of arrColumnas; let i = index;" style="border-top: 1px solid #099cfd;">
            
        </div>  
    </div>
</div>
<!-- fin 5.1-->

<div class="row" class="marginwhite2">
<div class="grid-container">
    <div class="marginRigth colorblue textoazul"> </div>
    <div class="textoblanco style2a calibriFont verticalAligmentDiv">{{fnObtenerLabel('73')}}</div>
    <div class="margin4 textoInput style5b calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;">
        <input (change)="fnChangeInput(jsnTeam['capital'], 'capital', i)"
         [ngStyle]="{'color': capitalStock != jsnTeam['capital'] && intentos >= 3 ? 'red':'black',
        'border': capitalStock != jsnTeam['capital'] && intentos >= 3 ? '2px solid #ff0000':'' }" *ngIf="i == mes && canEdit" type="number" id="input150" matInput class="w" [(ngModel)]="jsnTeam['capital']" (keyup.enter)="validateKey('input160')" onkeypress="return event.charCode >= 48" min="0">
        <div *ngIf="i != mes">
            {{jsnTeam['capital']}}
        </div>
    </div> 
</div>
</div>
<!-- fin 5.2-->


<div class="row" class="marginwhite2">
    <div class="grid-container">
        <div class="marginRigth colorblue textoazul"> </div>
        <div class="textoblanco style2a calibriFont verticalAligmentDiv">{{fnObtenerLabel('74')}}</div>
        <div class="margin4 textoInput style5b calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;">
            <input (change)="fnChangeInput(jsnTeam['retained'], 'retained', i)"
            [ngStyle]="{'color': retained != jsnTeam['retained'] && intentos >= 3 ? 'red':'black',
            'border': retained != jsnTeam['retained'] && intentos >= 3 ? '2px solid #ff0000':'' }" *ngIf="i == mes && canEdit" type="number" id="input160" matInput class="w" [(ngModel)]="jsnTeam['retained']" (keyup.enter)="validateKey('input170')" >
            <div *ngIf="i != mes">
                {{jsnTeam['retained']}}
            </div>
        </div> 
    </div>
    </div>
<!-- fin 5.3-->

<div class="row" class="marginwhite2">
<div class="grid-container">
    <div class="marginRigth colorblue textoazul"> </div>
    <div class="textoblanco textRigth verticalAligmentDiv" style="display: inline-flex;"> 
        <div>
            <strong class="calibriFont" style="width: 90%;"> {{fnObtenerLabel('75')}} </strong>
            <span class="equal" style="text-align: right; height: 1px; font-size: 14px; cursor: pointer; width: 10%;">&#61;</span>
        </div>
    </div>
    <div class="margin4 textoInput style6 calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;"  style="background-color: #ececf4; ">
        <input *ngIf="i == mes && canEdit" type="number" id="input170" matInput class="w" [(ngModel)]="jsnTeam['totalShareholder']"  style="background-color: #ececf4; border-color: #ececf4;" (keyup.enter)="validateKey('input180')" onkeypress="return event.charCode >= 48" min="0">
        <div *ngIf="i != mes">
            {{jsnTeam['totalShareholder']}}
        </div>
    </div> 
</div>
</div>
<!-- fin 5.4-->
<!--------------------------------------------------------------- fin 5--------------------------------------------->

<!---------------------------------------------------------------INICIO 6------------------------------------------->
    <div class="row" class="marginwhite3">
        <div class="grid-container">
            <div class="marginRigth colorblue textoazul style2b calibriFont">{{fnObtenerLabel('3023')}}</div>
            <div class="textoblanco style2a calibriFont verticalAligmentDiv" style="border-top: 1px solid #099cfd; display: inline-flex; text-align: right;">
                <div>
                    <strong class="calibriFont" style="width: 90%; font-size: 13px;"> {{fnObtenerLabel('3024')}} </strong>
                    <span class="equal" style="text-align: right; height: 1px; font-size: 14px; cursor: pointer; width: 10%;">&#61;</span>
                </div>
            </div>
            <div class="margin4 textoInput style7b calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;" style="background-color: #c3d8fe; border-top: 1px solid #099cfd;">
                <input *ngIf="i == mes && canEdit" type="number" id="input180" matInput class="wblue2" [(ngModel)]="jsnTeam['totalLiabilitiesAndEquity']" style="border-top: 1px solid #099cfd;" onkeypress="return event.charCode >= 48" min="0">
                <div *ngIf="i != mes">
                    {{jsnTeam['totalLiabilitiesAndEquity']}}
                </div>
            </div>   
        </div>
    </div>
<!--------------------------------------------------------------- fin 6--------------------------------------------->


    
    </div>
   <!-- <div mat-dialog-actions style="text-align: center; display: block !important">
      <button *ngIf="canEdit" class="btn btn-primary mx-3 styleButtonOK" type="submit" (click)="fnSave()">&nbsp;&nbsp;&nbsp;&nbsp;{{getSingleTextLabel('136')}}&nbsp;&nbsp;&nbsp;&nbsp;</button>
      <button class="btn btn-secondary mx-3 FontMontserratTextInputsCancel" (click)="fnCanel()" [mat-dialog-close]>&nbsp;{{getSingleTextLabel('123')}}&nbsp;</button>
    </div>-->
      
    </div>
    
    <ngx-spinner bdColor="rgba(0,0,0,0.75)" size="large" color="#ffffff" type="ball-clip-rotate-multiple" [fullScreen]="true">
          <p style="color: white"> Loading... </p>
    </ngx-spinner>
    
