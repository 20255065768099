import { Injectable, OnDestroy, HostListener } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { io } from 'socket.io-client';
import { environment } from '../../../../environments/environment';
import { SocketioService } from '../../socketio.service';
@Injectable({
  providedIn: 'root'
})
export class SocketOrderService implements OnDestroy{


  private $order: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  _order: Observable<any> = this.$order.asObservable();
  private isMyCall = false;
  private isMyCallTimer = false;
  private isMyCallStop = false;
  private isMyCallSlogan = false;
  socket = this.socketioService.socket;

  constructor(private socketioService: SocketioService) { }

  /*@HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {    
    this.socket.close();
  }*/

  setupSocketConnection(teamId, roomToJoin) {
    console.log("Room a unirse: ", roomToJoin);
    /*this.socket.emit('joinWorkShop', {
      room: roomToJoin,
      teamId: teamId,
    });

    this.socket.on('connectedSuccesfullyWorkShop', (data) => {
      console.log("connectedSuccesfullyWorkShop",data);
    });*/
    this.socket.on('notificaNewMessage', (data) => {
      console.log("RECIBIMOS SOCKETCKKK");
      if(!this.isMyCall){
        this.$order.next({ data, status: 'notificaNewMessage' });
      } 
      this.isMyCall = false;
    });

    this.socket.on('updateBidServer', (data) => {
      if(!this.isMyCall){
        this.$order.next({ data, status: 'updateBidServer' });
      } 
      this.isMyCall = false;
    });

    this.socket.on('timerServer', (data) => {
      console.log("timerServer",data);
      if(!this.isMyCallTimer){
        this.$order.next({ data, status: 'timerServer' });
      } 
      this.isMyCallTimer = false;
    });

    this.socket.on('timerServer', (data) => {
      console.log("timerServerON ORDERS FROM BOARD",data);
      if(!this.isMyCallTimer){
        this.$order.next({ data, status: 'timerServer' });
      } 
      this.isMyCallTimer = false;
    });

    this.socket.on('openMarketServer', (data) => {
      if(!this.isMyCallStop){
        this.$order.next({ data, status: 'openMarketServer' });
      } 
      this.isMyCallStop = false;
    });
    
    this.socket.on('updateMarketStep', (data) => {
      if(!this.isMyCallStop){
        this.$order.next({ data, status: 'updateMarketStep' });
      } 
      this.isMyCallStop = false;
    });
    this.socket.on('setTeamSloganServer', (data) => {
      if(!this.isMyCallSlogan){
        this.$order.next({ data, status: 'setTeamSloganServer' });
      } 
      this.isMyCallSlogan = false;
    });

    this.socket.on('updateOrdersTeamsServer', (data) => {
      console.log("updateOrdersTeamsServer",data);
      if(!this.isMyCallSlogan){
        this.$order.next({ data, status: 'updateOrdersTeamsServer' });
      } 
      this.isMyCallSlogan = false;
    });
    this.socket.on('updateSuppliesServer', (data) => {
      this.$order.next({ data, status: 'updateSuppliesServer' });
    });
  }

  closeListeners(){
    this.socket.off('notificaNewMessage');
    this.socket.off('updateBidServer');
    this.socket.off('timerServer');
    this.socket.off('timerServer');
    this.socket.off('openMarketServer');
    this.socket.off('updateMarketStep');
    this.socket.off('setTeamSloganServer');
    this.socket.off('updateOrdersTeamsServer');
    this.socket.off('updateSuppliesServer');
  }


  sendMessage(
    teamIdEnviar,userId,avatar,valueMessage,isFacilitator,dformat,typechat , workshopId, roomId
  ){
    console.log("vamos a enviar fnsendMesagge");
    this.socket.emit('sendMessage', {
      teamIdEnviar,userId,avatar,valueMessage,isFacilitator,dformat,typechat , workshopId, roomId
    });
    this.isMyCall = true;
  }

  updateBid(
    room,
    orderId,
    bid,
    bidId
  ){
    this.socket.emit('updateBid', {
      room,
      orderId,
      bid,
      bidId
    });
    this.isMyCall = true;
  }

  updateAdvertising$(
    teamId,
    advertising,
    userId,
  ) {
    this.$order.next({ data: { teamId, advertising, userId }, status: 'updateAdvertising' });
  }

  timer(
    room,
    time
  ){
    this.socket.emit('timer', {
      room,
      time
    });
    this.isMyCallTimer = true;
  }

  openMarket( 
    workShopId,     
    room,
    blnOpen
    ){
    this.socket.emit('openMarket', {
      workShopId,
      room,
      blnOpen
    });
    this.isMyCallStop = true;
    this.openMarket$(blnOpen);
  }

  openMarket$(      
    blnOpen
    ){
    this.$order.next({ data:{blnOpen}, status: 'openMarket' });
  }

  ngOnDestroy() {
    //this.socket.close();
  }
  
  terminarConexion() {
    // this.socket.close();
  }

  setSlogan(
    room,
    teamId,
    strTeamSlogan
  ){
    this.socket.emit('setTeamSlogan', {
      room,
      teamId,
      strTeamSlogan
    });
    this.isMyCallSlogan = true;
  }
}
