import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//import { AppRoutingModule } from './app-routing.module';
import { routingModule } from './main.routes';
import { AppComponent } from './app.component';
import { SocketioService } from './services/socketio.service';
import { BoardComponent } from './views/board/board.component';
/*import { ForgotPasswordComponent } from './views/home/forgot-password/forgot-password.component';
import { LoginComponent } from './views/home/login/login.component';*/

// listado de usuarios
// import { MatAutocompleteModule, MatTabsModule, MatIconModule, MatInputModule } from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import {MatExpansionModule} from '@angular/material/expansion';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// captura usuarios
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { EditlabelsComponent } from './views/editlabels/editlabels.component';
import { LanguagesComponent } from './views/languages/languages.component';
// import { ArchivosTablaComponent } from './views/archivos-tabla/archivos-tabla.component';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BulkLoadComponent } from './views/bulk-load/bulk-load.component';
import { NewlanguageComponent } from './views/newlanguage/newlanguage.component';
import { SetupTeamComponent } from './views/setup-team/setup-team.component';
import { DialogAllTeamsComponent } from './views/dialog-all-teams/dialog-all-teams.component';
import { RulesGameComponent } from './views/rules-game/rules-game.component';
import { ViewAuthUserBoardComponent } from './views/viewAuthUserBoard/view-auth-user-board/view-auth-user-board.component';
import { SimpleBalanceSheetComponent } from './views/simple-balance-sheet/simple-balance-sheet.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatListModule} from '@angular/material/list';
import { MatMenuModule} from '@angular/material/menu';
import { RecordBalanceSheetComponent } from './views/record-balance-sheet/record-balance-sheet.component';
import { IncomeStatementComponent } from './views/income-statement/income-statement.component';
import { DialogOrdersComponent } from './views/dialog-orders/dialog-orders.component';
import { StandardBalanceSheetComponent } from './views/standard-balance-sheet/standard-balance-sheet.component';
import { DialogBoardModeComponent } from './views/dialog-board-mode/dialog-board-mode.component';
import { RecordIncomeAndBalanceComponent } from './views/record-income-and-balance/record-income-and-balance.component';
import { ChangeboardComponent } from './views/changeboard/changeboard.component';
import { PartitionObjectsComponent } from './views/partition-objects/partition-objects.component';
import { DialogImprovementsComponent } from './views/dialog-improvements/dialog-improvements.component';
import { DialogMonthStatusComponent } from './views/dialog-month-status/dialog-month-status.component';
import { DialogSetupImprovementsComponent } from './views/dialog-setup-improvements/dialog-setup-improvements.component';
import { ChatComponent } from './views/chat/chat.component';
import { ChangeLanguageComponent } from './views/change-language/change-language.component';
import { ReportsComponent } from './views/reports/reports.component';
import { IncomestatementreportComponent } from './views/incomestatementreport/incomestatementreport.component';
import { BalancesheetreportComponent } from './views/balancesheetreport/balancesheetreport.component';
import { DialogBudgetExerciseComponent } from './views/dialog-budget-exercise/dialog-budget-exercise.component';
import { DialogCashFlowExerciseComponent } from './views/dialog-cash-flow-exercise/dialog-cash-flow-exercise.component';
import { ResultViewComponent } from './views/result-view/result-view.component';
import { ResultRatiosComponent } from './views/result-ratios/result-ratios.component';
import { PurchaseObjectsComponent } from './views/purchase-objects/purchase-objects.component';
import { DialogHelpInfoComponent } from './views/dialog-help-info/dialog-help-info.component';
import { LearningSlidesComponent } from './views/learning-slides/learning-slides.component';
import { PiecesTeamsBoardComponent } from './views/pieces-teams-board/pieces-teams-board.component';
import { BudgetreportComponent } from './views/budgetreport/budgetreport.component';
import { DialogEditUsersComponent } from './views/dialog-edit-users/dialog-edit-users.component';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { ChangeimprovementComponent } from './views/changeimprovement/changeimprovement.component';
import { DialogChecklistComponent } from './views/dialog-checklist/dialog-checklist.component';
import { DialogLogsComponent } from './views/dialog-logs/dialog-logs.component';
import { ManualeditISBSComponent } from './views/manualedit-isbs/manualedit-isbs.component';
import { ConfirmUserComponent } from './views/confirm-user/confirm-user.component';
import { BoardImageComponent } from './views/board-image/board-image.component';
import { DialogEditTeamsComponent } from './views/dialog-edit-teams/dialog-edit-teams.component';
import { BoardServiceComponent } from './views/board-service/board-service.component';
import { BalancesheetreportServiceComponent } from './views/balancesheetreport-service/balancesheetreport-services.component';
import { IncomeStatementServiceComponent } from './views/income-statement-service/income-statement-services.component';
import { LearningSlidesServicesComponent } from './views/learning-slides-service/learning-slides-services.component';
import { StandardBalanceSheetServicesComponent } from './views/standard-balance-sheet-service/standard-balance-sheet-services.component';
import { SimpleBalanceSheetServicesComponent } from './views/simple-balance-sheet-service/simple-balance-sheet-services.component';
import { DialogChecklistServiceComponent } from './views/dialog-checklist-service/dialog-checklist-service.component';
import { DialogOrdersServiceComponent } from './views/dialog-orders-service/dialog-orders-service.component';
import { BoardImageServiceComponent } from './views/board-image-service/board-image-service.component';
@NgModule({
  declarations: [
    AppComponent,
    BoardComponent,
    /*LoginComponent,
    ForgotPasswordComponent,*/
    LanguagesComponent,
    NavbarComponent,
    EditlabelsComponent,
    // ArchivosTablaComponent,
    NewlanguageComponent,
    BulkLoadComponent,
    SetupTeamComponent,
    DialogAllTeamsComponent,
    RulesGameComponent,
    ViewAuthUserBoardComponent,
    SimpleBalanceSheetComponent,
    RecordBalanceSheetComponent,
    IncomeStatementComponent,
    DialogOrdersComponent,
    StandardBalanceSheetComponent,
    DialogBoardModeComponent,
    RecordIncomeAndBalanceComponent,
    ChangeboardComponent,
    PartitionObjectsComponent,
    DialogImprovementsComponent,
    DialogMonthStatusComponent,
    DialogSetupImprovementsComponent,
    ChatComponent,
    ChangeLanguageComponent,
    ReportsComponent,
    IncomestatementreportComponent,
    BalancesheetreportComponent,
    DialogBudgetExerciseComponent,
    DialogCashFlowExerciseComponent,
    ResultViewComponent,
    ResultRatiosComponent,
    PurchaseObjectsComponent,
    DialogHelpInfoComponent,
    LearningSlidesComponent,
    PiecesTeamsBoardComponent,
    BudgetreportComponent,
    DialogEditUsersComponent,
    ChangeimprovementComponent,
    DialogLogsComponent,
    DialogChecklistComponent,
    ManualeditISBSComponent,
    ConfirmUserComponent,
    BoardImageComponent,
    DialogEditTeamsComponent,
    BoardServiceComponent,
    BalancesheetreportServiceComponent,
    IncomeStatementServiceComponent,
    LearningSlidesServicesComponent,
    StandardBalanceSheetServicesComponent,
    SimpleBalanceSheetServicesComponent,
    DialogChecklistServiceComponent,
    DialogOrdersServiceComponent,
    BoardImageServiceComponent
  ],
  imports: [
    MatToolbarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    BrowserModule,
    //AppRoutingModule,
    routingModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    HttpClientModule,
    // listado y tabs usuario
    BrowserAnimationsModule,
    MatTableModule,
    MatPaginatorModule,
    MatTabsModule,
    // MatPaginator *se requiere actualizar la version de angular material
    NgMultiSelectDropDownModule,
    MatCardModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatTabsModule,
    MatButtonModule,
    MatCheckboxModule,
    NgxSpinnerModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatTooltipModule,
    MatGridListModule,
    MatListModule,
    MatMenuModule,
    MatExpansionModule,
    MomentDateModule
    // MatToolbarModule
  ],
  entryComponents: [SimpleBalanceSheetComponent ],
  providers: [
    MatDatepickerModule, 
    SocketioService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
