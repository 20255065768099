<app-navbar *ngIf="isLogged"></app-navbar>
<router-outlet></router-outlet>
<!--button class="btn btn-outline-success mx-3 styleButtonOK"
(click)="fnModalTeam(1)" type="submit">&nbsp;&nbsp;ModalTeam&nbsp;&nbsp;</button-->
<!--
<button class="btn btn-outline-success mx-3 styleButtonOK"
(click)="fnModalRules(1)" type="submit">&nbsp;&nbsp;Rules&nbsp;&nbsp;</button>
<button class="btn btn-outline-success mx-3 styleButtonOK mt-3"
(click)="fnModalTeam(1)" type="submit">&nbsp;&nbsp;ModalTeam&nbsp;&nbsp;</button>

<button class="btn btn-outline-success mx-3 styleButtonOK  mt-3"
(click)="fnModalTeams(1)" type="submit">&nbsp;&nbsp;ModalTeams&nbsp;&nbsp;</button>

<button class="btn btn-outline-success mx-3 styleButtonOK"
(click)="fnAssign(1)" type="submit">&nbsp;&nbsp;Assing&nbsp;&nbsp;</button>

<button class="btn btn-outline-success mx-3 styleButtonOK  mt-3"
(click)="fnModalOrders(1,90)" type="submit">&nbsp;&nbsp;ModalOrders1&nbsp;&nbsp;</button>

<button class="btn btn-outline-success mx-3 styleButtonOK  mt-3"
(click)="fnModalOrders(1,91)" type="submit">&nbsp;&nbsp;ModalOrders2&nbsp;&nbsp;</button>

-->
<!--button class="btn btn-outline-success mx-3 styleButtonOK  mt-3"
(click)="fnModalImprovements()" type="submit">&nbsp;&nbsp;ModalImprove&nbsp;&nbsp;</button-->