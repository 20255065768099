import { Component, OnInit,Inject, } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { ContentObserver } from '@angular/cdk/observers';
import { element } from 'protractor';
import Swal from 'sweetalert2';
import { UsuarioService } from '../../services/usuario/usuario.service';
import { ChatService } from '../../services/chat/chat.service';
import { _IP } from './../../config/config';
import { TeamService } from 'src/app/services/team/team.service';
//import { SocketchatService } from 'src/app/services/sockets/chat/socketchat.service'
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SocketOrderService } from 'src/app/services/sockets/socket-order/socket-order.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {

  //selectedteam= [];
  //lstTeams = [];
  lstGroupChat = [];
  teamId=0;
  userId=0;
  userName="";
  teamName="";
  workshopId=0;
  Iphost= _IP+"/profiles/"; //_IP;
  selectedChat;
  typechat=0;
  isFacilitator=0;
  lstTeams = [];
  blnShowTeams=false;
  selectedteam= [];
  teamSelectforFacilitator=0;
  roomId="";
  destroy$: Subject<boolean> = new Subject<boolean>();
  lstTms = [];
  lblplaceholderlsTeam: string = 'Select Team';
  dropDownSettingslstTms= {
    singleSelection: true,
    idField: 'TeamId',
    textField: 'Avatar',
    selectAllText: 'Select All',
    unSelectAllText: 'DeSelect All',
    allowSearchFilter: true,
    searchPlaceholderText: 'Search...',
    closeDropDownOnSelection: true,
  };
  lblImgLogo:string  = "";
  Logo="";
  //txtAvatar="Nameless";
  
  

  constructor( 
    public matDialogRef: MatDialogRef<ChatComponent>,
    private usuarioService: UsuarioService,
    private chatService: ChatService,
    private teamService: TeamService,
   // private socketChat: SocketchatService,
    private socketOrder: SocketOrderService,
      @Inject(MAT_DIALOG_DATA) private _data: any
    ) {
      console.log('this._data.TeamName', this._data.TeamName );
      this.teamId=this._data.actualTeam;
      this.userId = this._data.userId;
      this.userName = this._data.userName;
      this.workshopId = this._data.workshopId;
      this.teamName = this._data.teamName;
      this.isFacilitator = this._data.isFacilitator;
    
      
    }

  ngOnInit(): void {
    console.log("entre asignar keyup a input del chat");
    $('.chat-input input').keyup(function(e) {
      if ($(this).val() == '')
        $(this).removeAttr('good');
      else
        $(this).attr('good', '');
    });
    console.log("ya agrego evento keyup");

    this.fnGetMessagesfromServer(2,0);//la primera vez que entramos vemos el chat de mi team
    this.fnInitSocket();

    /*ENTRAMOSA VERS IS TIENE IMAGEN DE PERFIL */
    // Sample usage
    var imageUrl = "https://"+this.Iphost+this.userId+".png"
    this.imageExists(imageUrl, function(exists) {
      console.log("Entramos a fnImageExists:::::");
      console.log('RESULT: url=' + imageUrl + ', exists=' + exists);
    });
    /*FIN IMAGEN PERFIL */

  }
  
  fnInitSocket() {
    this.roomId = "roomWorkShop" + this.workshopId;
    this.socketOrder.setupSocketConnection(
      this.teamId,
      this.roomId
    );
    // observador para el socket que controla las monedas
    this.socketOrder._order
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (response) {
          console.log('RESPUESTAAAAAA', response);
          if (response.status == 'notificaNewMessage' && response.data != undefined) {
            console.log("entra respuesta notificaNewMessage");
            this.fnSetChatEntrante(response.data);
          }
          /*if (response.status == 'timerServer' &&response.data != undefined) {
            this.fnGetTime(response.data.time);
          }
          if (response.status == 'openMarketServer' &&response.data != undefined) {
            console.log("openMarketServer",response.data);
            this.fnDisableBets();
            this.socketOrder.openMarket$(response.data['blnOpen']);
            if(!response.data['blnOpen'])
              this.fnCloseDialog();
          }*/
        }
      })
  }

  async fnSetChatEntrante(data:any){
    console.log("entramos fncontrolar chat entrante",data);
    if(this.typechat == parseInt(data['typechat'])){//el type lo esta treayendo en 0 cuando es usuario del mismo team
      if(this.userId != data['userId']){
        this.lstGroupChat.push(
          {
           teamId: data['teamIdEnviar'],
           UserId : data['userId'],
           avatar : data['Avatar'],
           message: data['valueMessage'],
           isFaciltator: data['isFacilitator'],
           datemessage: data['dformat'],
           typeChat: data['typechat'] 
   
         });
      }
      
    }
    /*for(var order of this.arrOrders) {
      if(order.OrderId === data['orderId']) {
        for(let bid of order.Bids) {
          if(bid.BidId === data['bidId']) {
            bid.Bid = data['bid'];
            break;
          }
        }
      }
    }
    ///For Demo, change
    const advertising = await  this.arrAllTeams.forEach((team,index) => this.fnSetAdvertisingSpend(team.TeamId,index));
    console.log("fnSetBid fnSetAdvertisingSpend");
    this.fnSetWiningTeam(data['orderId']);
    console.log("fnSetBid",this.arrOrders);
    this.dataSourceTableOfOrders = new MatTableDataSource(
      this.arrOrders
    );*/
  }


  ngOnDestroy() {
    // this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    // this.destroy$.unsubscribe();
    // console.log("Closing WS...");
    //this.socketOrder.terminarConexion();
  }

fnGetMessagesfromServer(typechat, teamidifisFacilitator){
  this.chatService.getMessages(typechat, this.workshopId, teamidifisFacilitator == 0 ? this.teamId : teamidifisFacilitator).subscribe(
    (response) => {
      console.log("respuesta de chat:", response)
      if(response["intResponse"] == "200"){

        
        var  lstChatstmp = response["messages"];
        lstChatstmp.forEach(element => {
          /*
          var d = new Date,
          dformat = [element.datemessage.getFullYear(), element.datemessage.getMonth() + 1,
            element.datemessage.getDate(),
          ].join('-') + ' ' +
            [element.datemessage.getHours(),
              element.datemessage.getMinutes(),
              element.datemessage.getSeconds()].join(':');

            element.datemessage = dformat;*/
            this.lstGroupChat.push(element);
            if(element.UserId == this.userId){
              this.userName=element.avatar;
            }
            

        });
        //this.lstGroupChat = response["messages"];
        
    }
  });
}

  fnSendMessage(){
    console.log("entre a enviar mns");
    //var today = new Date();
      var d = new Date,
        dformat = [d.getFullYear(), d.getMonth() + 1,
        d.getDate(),
        ].join('-') + ' ' +
          [d.getHours(),
          d.getMinutes(),
          d.getSeconds()].join(':');
      const valueMessage = (document.getElementById("txtMessage") as HTMLInputElement).value;
      if(valueMessage==""){
return;
      }

      console.log("datefinal::", dformat, valueMessage);
      console.log(" antes enviar mensage::", this.teamId,this.userId,this.userName,valueMessage,0,dformat,2)
      
      //let date = moment(today).format("YYYY-MM-DD");
      let teamIdEnviar=0;
      if(this.blnShowTeams){
        if(this.teamSelectforFacilitator > 0){
          teamIdEnviar = this.teamSelectforFacilitator;
        }else{
            return;
        }
       
      }else{
        teamIdEnviar = this.teamId;
      }
      console.log("this.userName", this.userName);
      this.chatService.createMessage(teamIdEnviar,this.userId,this.userName,valueMessage,this.isFacilitator,dformat,this.typechat , this.workshopId).subscribe(
        (response) => {
          console.log("respuesta de chat:", response)
          if(response["intResponse"] == "200"){
             
             this.lstGroupChat.push(
             {
              teamId: this.teamId,
              UserId : this.userId,
              avatar : this.userName,
              message: valueMessage,
              isFaciltator: 0,
              datemessage: dformat,
              typeChat: this.typechat 
      
            });
            (document.getElementById("txtMessage") as HTMLInputElement).value= "";
            console.log("se guardo OK", this.lstGroupChat);

          }
                
        });

        this.socketOrder.sendMessage(teamIdEnviar,this.userId,this.userName,valueMessage,this.isFacilitator,dformat,this.typechat , this.workshopId, this.roomId);

        
  }

  fnCheckChat(e){
    if(e.value != 5){
      this.blnShowTeams=false;
      console.log("entra check", e.value);
      this.lstGroupChat=[];
      this.typechat = e.value;
      this.fnGetMessagesfromServer(e.value, 0);
    }else{
      this.blnShowTeams=true;
      this.lstTeams=[];
      this.typechat = 2;//aunque llega un 5 el tipo debe ser 2 para obtener un chat de grupo, el 5 solo es para abrir el div de grupos
      this.teamSelectforFacilitator = 0;
      this.teamService.getAllTeamsAndMembersById(this.workshopId).subscribe(resp => {
        console.log("fnGetAllTeamsAndMembers", resp);
        
        resp['teams'].forEach(element => {
          this.lstTeams.push(element.team)
        });
      })


    }
  }
/*
  fnCheckChatbyTeamId(team){
    
      console.log("entra check select team:", team.value);
      this.teamSelectforFacilitator= team.value;
      this.lstGroupChat=[];
      this.fnGetMessagesfromServer(this.typechat, team.value);
    
  }*/
  
  selectTeamtosend(team) {
    console.log(
      'fnChange fnChangeTeamddl select::',
      team
    );
   
    /*this.distributorService.getOnlyContactsByDistributorID(event['DistributorID']).subscribe(response => {
      console.log("reponse only contacts:: ",response, " ['contacts']:",response['contacts']);
      if(response['intResponse'] == 200){
        console.log("ok 200");

        this.lstContacts = response['contacts'];

      }else{

      }
    });*/
  }
  selectTeamtosend2(team) {
    console.log(
      'fnChange fnChangeTeamddl select::',
      team
    );

    console.log("entra check select team:", team);
      this.teamSelectforFacilitator= team;
      this.lstGroupChat=[];
      this.fnGetMessagesfromServer(this.typechat, team);
  }

  onFileChange(event) {
    console.log("entra file", event)
    if (event.target.files.length > 0) {
      /*
        this.lblImgLogo= event.target.files[0].name;
        this.Logo=event.target.files[0];*/
      
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
          console.log("base64:",reader.result);
          this.chatService.createphoto(reader.result, this.userId+".png",).subscribe(
            (response) => {
              console.log("respuesta de chat:", response)
              if(response["intResponse"] == "200"){
                console.log("limpiamos el chat actualchat actual")
                //this.fnGetMessagesfromServer(this.typechat, this.)
                this.lstGroupChat=[];
              }
                    
            });
      };
    }
    /*var Formdata = new FormData();
    Formdata.append('file', this.Logo);
    Formdata.append('filename',"avatardefault.png");

    */



  }

  focusOutFunctionAvatar(){
    console.log("entre focusend")
    const valueMessage = (document.getElementById("txtAvatar") as HTMLInputElement).value;
      if(valueMessage==""){
          return;
      }
      else{
          console.log("vamos a guardar el avatar:", valueMessage)
          this.userName= valueMessage;
      }

  }

  
  imageExists(url, callback) {
    var img = new Image();
    img.onload = function() { callback(true); };
    img.onerror = function() { callback(false); };
    img.src = url;
  }
  
  



}

