<label class="style1 calibriFont" id="example-radio-group-label">Confirm your user</label><br><br>
<mat-radio-group 
  aria-labelledby="example-radio-group-label"
  class="example-radio-group"
  >
  <p>What type of user are you?</p>
  <mat-radio-button  name="fieldName" ngDefaultControl [(ngModel)]="selectedLang" class="example-radio-button style2a calibriFont" value="Facilitador" 
   >
    Facilitator
  </mat-radio-button><br>
  <mat-radio-button  name="fieldName" ngDefaultControl [(ngModel)]="selectedLang" class="example-radio-button style2a calibriFont" value='User' 
   >
    User
  </mat-radio-button><br>
</mat-radio-group>
<mat-form-field class="w-25 textColorBase" appearance="fill" *ngIf="selectedLang == 'Facilitador'">
    <mat-label>Password</mat-label>
    <input matInput [(ngModel)]="pass" type="password"
    maxlength="30" />
    <!-- name="teamName" id="teamName" [disabled]="canEdit" maxlength="18" /> -->
        <!--!blnEnableEditAvatar: {{!blnEnableEditAvatar}} canedit:{{canEdit}} !blnfacilitator: {{!this.blnFacilitador}}-->
</mat-form-field>
<!--<div>Your selection: {{selectedpeople}}</div>-->
<br>
<button  type="button" class="btn mx-3 FontMontserratTextInputsDone" id="btnsaveht"
        
        (click)="fnLogin()">&nbsp;&nbsp; Confirm &nbsp;&nbsp;</button>
        <!--<button  type="button" class="btn btn-outline-success mx-3" id="btnsaveht" [disabled]="selectedteam.length == 0"
        style="Background-color:#6FB45C;border-radius: 50px;color: white;font-family: 'Montserrat';"
        (click)="fnSelectTeam()">&nbsp;&nbsp;Cancel&nbsp;&nbsp;</button>-->
        

