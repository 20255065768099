import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { _URL_Chat } from './../../config/config';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(private http: HttpClient) {}


  createMessage(_TeamId,_UserId,_avatar,_message,_isFaciltator,_dateMessage,_typeChat, _workshopId) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
      TeamId : _TeamId, 
      UserId : _UserId,
      avatar : _avatar,
      message : _message,
      isFaciltator : _isFaciltator, 
      dateMessage : _dateMessage, 
      typeChat : _typeChat,
      workshopId:_workshopId
    });
    return this.http.post(_URL_Chat + '/message', params, {
      headers: httpHeaders,
    });
  }


  createphoto(file, filename) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
      file : file, 
      filename : filename
    });
    return this.http.post(_URL_Chat + '/photo', params, {
      headers: httpHeaders,
    });
  }

  getMessages(typeChat,workshopId,teamId): Observable<any> {
    console.log("recuperando mns:");
    return this.http.get(

      _URL_Chat + '/getMessages/' + typeChat+'/' +workshopId+'/'+teamId
    );
  }


}
