import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BoardService } from 'src/app/services/board/board.service';
import { ExerciseService } from 'src/app/services/sockets/exercises/exercise.service';
import { TeamService } from 'src/app/services/team/team.service';
import { Team } from '../dialog-orders/dialog-orders.component';
import { ModelColumnBudget } from '../dialog-budget-exercise/dialog-budget-exercise.component';

@Component({
  selector: 'app-budgetreport',
  templateUrl: './budgetreport.component.html',
  styleUrls: ['./budgetreport.component.scss']
})
export class BudgetreportComponent implements OnInit {
  canUseButtomsTarget: boolean = true;
  destroy$: Subject<boolean> = new Subject<boolean>();
  workshopId: number;
  arrAllTeams: Array<{members: Array<any>, team: Team}>;
  cycles: number;
  numbers = [];
  auxMes : number = 0
  forecastExc:number;
  monthHistoric: number
  mes: number
  constructor(
    public matDialogRef: MatDialogRef<BudgetreportComponent>,
    private teamService: TeamService,
    private boardService: BoardService,
    private spinner: NgxSpinnerService,
    private exerciseService: ExerciseService,
    @Inject(MAT_DIALOG_DATA) private _data: any
  ) { }

  async ngOnInit(){
      console.log("hola");
      this.workshopId = this._data['workshopId'];
      this.cycles = this._data['cycles'];
      this.forecastExc = this._data['forecastExc'];
      this.mes = this._data['month'];
      this.monthHistoric = this.mes;

      switch (this.cycles) {
        case 4:
          this.numbers = Array(1)
          this.auxMes = 4
          break;
        case 5:
          this.numbers = this.forecastExc == 1 ? Array(2) : Array(1)
          this.auxMes = this.forecastExc == 1 ? 4 : 5
          break;
        case 6:
          this.numbers = this.forecastExc == 1 ? Array(3) : Array(2)
          this.auxMes = this.forecastExc == 1 ? 4 : 5
          break;
      
        default:
          break;
      }
      this.fnGetAllTeamsAndMembers();
      this.initSocket();
  }

  async fnChangeMonthHistoric(view: string) {
    this.monthHistoric = parseInt(view.slice(5));
    console.log(this.monthHistoric)
    this.arrAllTeams.forEach(async team =>{
      const responseData = await this.exerciseService.getSaveQuantitiesTargetColumn(team.team.TeamId, this.monthHistoric);
      console.log(responseData)
      // console.log(responseData.data.netIncome)
      if (responseData.intResponse == 200 && responseData.data != null) {
        team.team.BudgetCompleteNetIncome = responseData.data.netIncome;

      }else{
        team.team.BudgetCompleteNetIncome = null;
      }
    })
    if (this.monthHistoric < this.mes) {
      this.canUseButtomsTarget = false
      console.log("No")
    } else {
      this.canUseButtomsTarget = true

    }    
  }
  getSingleTextLabel(labelNumber: string): string {
    const tmp = this._data.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    return tmp ? tmp.Label : '';
  }
  fnGetAllTeamsAndMembers(){
    this.spinner.show();
    this.teamService.getAllTeamsAndMembersById(this.workshopId).subscribe(resp => {
      this.arrAllTeams = resp['teams'];
      console.log(this.arrAllTeams);
      this.arrAllTeams.forEach(async team =>{
        const responseData = await this.exerciseService.getSaveQuantitiesTargetColumn(team.team.TeamId, this.mes);
        console.log(responseData)
        // console.log(responseData.data.netIncome)
        if (responseData.intResponse == 200 && responseData.data != null) {
          team.team.BudgetCompleteNetIncome = responseData.data.netIncome;
  
        }else{
          team.team.BudgetCompleteNetIncome = null;
        }
      })
      this.spinner.hide();
    });
  }

  fnUpdateLockBudgetStatus(teamId : number , status: number){
    console.log(teamId)
    this.boardService.updateBudgetLockStatus(this.workshopId, teamId, status).subscribe(response => {
      console.log(response);
      if(status == 0){
        this.arrAllTeams.forEach(item => {
          if(item.team.TeamId == teamId){
            item.team.BudgetCompleteNetIncome = null;
            item.team['BudgetLock'] = 0;
          }
        });
        let datos : ModelColumnBudget = {
          priceRoyal: 0,
          numberRoyals: 0,
          totalSale: 0,
          costRoyal: 0,
          numberRoyals2: 0,
          totalCOGS: 0,
          contribution: 0,
          factoryOverhead: 0,
          SGA: 0,
          Finance: 0,
          totalFiexed: 0,
          netIncome: 0,
          decode: function (json: any): void {
            throw new Error('Function not implemented.');
          },
          isComplete: function (): boolean {
            throw new Error('Function not implemented.');
          }
        }
        this.exerciseService.saveQuantitiesTargetColumn(datos, teamId, 0).subscribe(response => {
          console.log(response);
        });
      }else{
        this.arrAllTeams.forEach(item => {
          if(item.team.TeamId == teamId){
            item.team['BudgetLock'] = 1;
          }
        });
      }

    });
  }

  initSocket(){
    this.exerciseService.setupSocketConnection();
    this.exerciseService._budget.pipe(takeUntil(this.destroy$)).subscribe(response => {
      if(response){ 
        if(response.status == 'notifyFacilitatorBudgetServer'){
          // Swal.fire('Team Finished',`The team ${response.data.teamId} has finished your budget excersice`, 'success');
          const team: {members: Array<any>, team: Team} = this.arrAllTeams.find(x => x.team.TeamId == response.data.teamFinish);
          team.team.BudgetCompleteNetIncome = response.data.netIncome;
        }
      }
    })
  }
  updateView(){
    this.fnGetAllTeamsAndMembers();
    this.initSocket();
  }
}
