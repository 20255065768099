import { Component, OnInit, Inject} from '@angular/core';
import { IncomeStatementService } from 'src/app/services/IncomeStatement/IncomeStatement.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { BalanceIncomeService } from 'src/app/services/sockets/balance-income/balance-income.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TeamService } from 'src/app/services/team/team.service';

@Component({
  selector: 'app-incomestatementreport',
  templateUrl: './incomestatementreport.component.html',
  styleUrls: ['./incomestatementreport.component.scss']
})
export class IncomestatementreportComponent implements OnInit {
  //destroy$: Subject<boolean> = new Subject<boolean>();
  
  arrColumnas=[];
  jsonLabels = [];
  jsonCantidades = [];
  mes: number;
  deleteMes: number;
  blnShowAlert = false;
  canEdit = true;
  netIncome = 0;
  intentos = 0;
  /*áreas del board*/
  totalSales: number = 0;
  costGoodSold: number = 0;
  factotyOverheads: number = 0;
  selling: number = 0;
  interest: number = 0;
  taxes: number = 0;
  businessModel = 'Mfg EBIT';

  constructor(private incomeService: IncomeStatementService, private spinner: NgxSpinnerService, private teamService: TeamService,
      public matDialogRef: MatDialogRef<IncomestatementreportComponent>,
      @Inject(MAT_DIALOG_DATA) public data: { teamId: any , languageId: any, currentMonth: any, cycles: any, workshopId: any, totSales: any, canEdit: boolean, control: boolean, arrTextLabels: any, businessModel: any},
      private balanceIncomeService: BalanceIncomeService) { }
               
   /*ngOnDestroy(): void {
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
    this.balanceIncomeService.closeListener();
  }*/
  ngOnInit(): void {
    /*this.matDialogRef.keydownEvents().subscribe(event => {
      if (event.key === "Escape") {
          this.onCancel();
      }
    });*/

    this.spinner.show();
    this.fnLabels();
    console.log("team",this.data.teamId);
    console.log("mes ",this.data.currentMonth);
    console.log("Cycles ",this.data.cycles , this.data.workshopId);
    console.log("totSales ",this.data.totSales);
    this.totalSales = this.data.totSales;
    this.businessModel = this.data.businessModel;
    this.canEdit = this.data.canEdit;
    
    //this.data.cycles=6;
    //this.data.currentMonth = 2;
    this.mes = this.data.currentMonth;

    this.teamService.getTeamById(this.data.teamId).subscribe(res =>{
      console.log(res)
      if(res.intResponse == 200 ){
        this.mes = res.team['Month'] ;
        this.fnCantidadesBoard();

        this.incomeService.getCountCantidades(this.data.teamId).subscribe(response => {
          console.log("mio",response.data.total); 
          if(response.data.total == 0){
            console.log("ciclos, no habia registro");
            this.fnLlenado();
          }
          else{
            if(response.data.total == this.data.cycles){
              console.log("ciclos igual");
              this.fnObtenerDatos();
            } else{
                console.log("ciclos cambiaron");
                if(response.data.total>this.data.cycles){
                  this.deleteMes = response.data.total - this.data.cycles;
                  console.log("total",this.deleteMes)
                  //this.fnDelete();
                }
                else{
                  this.fnLlenado();
                }
               
            }
          }
        });
      }
    
    })

    
   //this.initSocket();
  }
/*
  initSocket(){
    this.balanceIncomeService.setupSocketConnection();
    this.balanceIncomeService._income
    .pipe(takeUntil(this.destroy$)).subscribe(response => {
      if(response){
        if(response.status == 'updateInputIncomeServer'){
          const data = this.arrColumnas[response.data.i];
          if(data){
            this.arrColumnas[response.data.i][response.data.id] = response.data.value
          }
        }
      }
    });
  }*/

  onCancel(): void {
    this.matDialogRef.close();
  }

  /*fnDelete(){
    this.incomeService.deleteMonth(this.data.teamId,this.deleteMes ).subscribe(response => {
      console.log("delete mes: ",response);
      this.fnObtenerDatos();
    });
  }*/
  
  fnLlenado(){
      this.incomeService.newRegister(this.data.workshopId, this.data.teamId, this.data.cycles).subscribe(response => {
        console.log("newRecord: ",response);
        this.fnObtenerDatos();
      });
  }

  fnObtenerDatos(){
    this.incomeService.getCantidadesLlenado(this.data.teamId).subscribe((response) => {
      console.log(response);
      this.arrColumnas = response['data'];
      this.spinner.hide();
    });
  }

  /*obtener etiquetas de lenguaje*/
  fnLabels(){
    this.incomeService.getLabelsByLanguageId(this.data.languageId).subscribe((response) => {
      this.jsonLabels = response['data'];
      console.log("Labels: ",this.jsonLabels );
      //this.spinner.hide();
    });
  }

  fnObtenerLabel(id){
    let valor = this.jsonLabels.filter(x=>x.LabelNumber === id)
    let label = valor[0].Label;
    return label;
  }

  /*obtencion de datos del board*/
  fnCantidadesBoard(){
    this.incomeService.getCantidadesBoard(this.data.teamId).subscribe((response) => {
      this.jsonCantidades = response['data'];
      console.log("Cantidades: ",this.jsonCantidades);
      let costGoodSold = this.jsonCantidades.filter(x=>x.AreaDrag === 31);
      costGoodSold.forEach(x =>{
        if(x.tipo == 8){
          this.costGoodSold += (x.cantidad) *2 ;
        } else {
          this.costGoodSold += (x.cantidad);
        }
      });
  
      let factotyOverheads = this.jsonCantidades.filter(x=>x.AreaDrag === 20 || x.AreaDrag === 21);
      factotyOverheads.forEach(x =>{
        this.factotyOverheads = (this.factotyOverheads) + (x.cantidad);
      });
     
      let selling = this.jsonCantidades.filter(x=>x.AreaDrag === 22 || x.AreaDrag === 23 || x.AreaDrag === 24|| x.AreaDrag === 25|| x.AreaDrag === 26|| x.AreaDrag === 27|| x.AreaDrag === 28);
      selling.forEach(x =>{
        this.selling = (this.selling) + (x.cantidad);
      });
  
      let interest = this.jsonCantidades.filter(x=>x.AreaDrag === 29);
      interest.forEach(x =>{
        this.interest = x.cantidad;
      });
    
      let  taxes = this.jsonCantidades.filter(x=>x.AreaDrag === 36);
      taxes.forEach(x =>{
        this.taxes = x.cantidad;
      });
    
    });
  }

  validateKey(nextInput){
    document.getElementById(nextInput).focus();
  }

  

  fnValidateInput(valueInp){
    //console.log(valueInp);
    let varStrId= "input1"+valueInp;
    const valueEdited = (document.getElementById(varStrId) as HTMLInputElement);
  }

  /*calcular suma*/
  /*fnResultado(uno, dos, tres){
    console.log("val1:" + uno + "val2:" + dos);
    let varUno = "input"+uno;
    let varDos = "input"+dos;
    let varTres = "input"+tres;
    let val1,val2;

    console.log(varUno + varDos);

    if(parseInt((document.getElementById(varUno) as HTMLInputElement).value)){
      console.log("si hay valor");
      val1 = parseInt((document.getElementById(varUno) as HTMLInputElement).value)
    }else{
      val1 = 0;
    }

    if(parseInt((document.getElementById(varDos) as HTMLInputElement).value)){
      console.log("si hay valor");
      val2 = parseInt((document.getElementById(varDos) as HTMLInputElement).value)
    }else{
      val2 = 0;
    }
    var resultado = val1 - val2;
    (document.getElementById(varTres) as HTMLInputElement).value = resultado.toString();
    switch(varTres){
      case "input30":
        this.fnChangeInput(resultado, 'contribution', this.mes);
        break;
      case "input50":
        this.fnChangeInput(resultado, 'gross', this.mes);
        break;
      case "input70":
        this.fnChangeInput(resultado, 'operating', this.mes);
        break;
      case "input90":
        this.fnChangeInput(resultado, 'income', this.mes);
        break;
      case "input110":
        this.fnChangeInput(resultado, 'netIncome', this.mes);
        break;
        
    }

  }
*//*
  fnSave(){
  console.log("cantidades board: ", "totalSales", this.totalSales,"costGoodSold:",this.costGoodSold, "factotyOverheads:", this.factotyOverheads,"selling:",this.selling,"interest:",this.interest ,"taxes:",this.taxes)

  let totalsales,costGoodSold,factoryOverheads,selling,interest,taxes;

  if(parseInt((document.getElementById('input10') as HTMLInputElement).value)){
    totalsales = parseInt((document.getElementById('input10') as HTMLInputElement).value)
  }else{
    totalsales = 0;
  }

  if(parseInt((document.getElementById('input20') as HTMLInputElement).value)){
    costGoodSold = parseInt((document.getElementById('input20') as HTMLInputElement).value)
  }else{
    costGoodSold = 0;
  }

  if(parseInt((document.getElementById('input40') as HTMLInputElement).value)){
    factoryOverheads = parseInt((document.getElementById('input40') as HTMLInputElement).value)
  }else{
    factoryOverheads = 0;
  }

  if(parseInt((document.getElementById('input60') as HTMLInputElement).value)){
    selling = parseInt((document.getElementById('input60') as HTMLInputElement).value)
  }else{
    selling = 0;
  }

  if(parseInt((document.getElementById('input80') as HTMLInputElement).value)){
    interest = parseInt((document.getElementById('input80') as HTMLInputElement).value)
  }else{
    interest = 0;
  }

  if(parseInt((document.getElementById('input100') as HTMLInputElement).value)){
    taxes = parseInt((document.getElementById('input100') as HTMLInputElement).value)
  }else{
    taxes = 0;
  }


  console.log("input",(parseInt((document.getElementById('input10') as HTMLInputElement).value)),(parseInt((document.getElementById('input20') as HTMLInputElement).value)),
              (parseInt((document.getElementById('input30') as HTMLInputElement).value)),(parseInt((document.getElementById('input40') as HTMLInputElement).value)),
              (parseInt((document.getElementById('input50') as HTMLInputElement).value)),(parseInt((document.getElementById('input60') as HTMLInputElement).value)),
              (parseInt((document.getElementById('input70') as HTMLInputElement).value)),(parseInt((document.getElementById('input80') as HTMLInputElement).value)),
              (parseInt((document.getElementById('input90') as HTMLInputElement).value)),(parseInt((document.getElementById('input100') as HTMLInputElement).value)),
              (parseInt((document.getElementById('input110') as HTMLInputElement).value))); 

  let resultado = totalsales - costGoodSold;
  let resultado2 = resultado - factoryOverheads;
  let resultado3 = resultado2 - selling;
  let resultado4 = resultado3 - interest;
  let resultado5 = resultado4 - taxes;
  console.log(resultado, resultado2, resultado3, resultado4,resultado5);

  if(this.data.control){
    console.log("no tiene el control");
    return;
  }

   if((totalsales == this.totalSales) && 
      (costGoodSold == this.costGoodSold) &&
      (factoryOverheads == this.factotyOverheads) &&
      (selling == this.selling) &&
      (interest == this.interest) &&
      (taxes == this.taxes) &&
      (parseInt((document.getElementById('input30') as HTMLInputElement).value)== resultado) && 
      (parseInt((document.getElementById('input50') as HTMLInputElement).value)== resultado2)  && 
      (parseInt((document.getElementById('input70') as HTMLInputElement).value)== resultado3) &&
      (parseInt((document.getElementById('input90') as HTMLInputElement).value)== resultado4) &&
      (parseInt((document.getElementById('input110') as HTMLInputElement).value)== resultado5)){
        console.log("si es igual")
    
        this.incomeService.updateCantidades(
          this.data.workshopId, 
          this.data.teamId,
          this.data.currentMonth, 
          this.totalSales.toString(),
          this.costGoodSold.toString(),
          resultado.toString(),
          this.factotyOverheads.toString(),
          resultado2.toString(),
          this.selling.toString(),
          resultado3.toString(),
          this.interest.toString(),
          resultado4.toString(),
          this.taxes.toString(),
          resultado5.toString()).subscribe((response) => {
          console.log("updateRecord: ",response);
        });


        console.log(this.data.workshopId, this.data.teamId, this.data.currentMonth);
        this.incomeService.updateMonth(this.data.workshopId, this.data.teamId, this.data.currentMonth).subscribe((response) => {
          console.log("update month: ",response);
        });
        this.blnShowAlert = true;
        this.netIncome = resultado5;
        let text = "";
        if(this.netIncome > 0){
          text = `120.- Congratulations! You made a profit of ${this.netIncome}!`;
        }else{
          text = `121.- This is sad, you made a loss.`;
        }
        Swal.mixin({
          // target: "#alert",
          customClass: {
            container: 'position-absolute'
          },
          toast: true,
          timerProgressBar: true,
          showConfirmButton: false,
          timer: 3000,
          position: 'center',
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        }).fire({
          text: text
        })
        this.matDialogRef.close({
          netIncome: resultado5
        });
   }
   else{
    console.log("NO es igual");
    (document.getElementById('input110') as HTMLInputElement).value = '0';
    (document.getElementById('input30') as HTMLInputElement).value = '0';
    (document.getElementById('input50') as HTMLInputElement).value = '0';
    (document.getElementById('input70') as HTMLInputElement).value = '0';
    (document.getElementById('input90') as HTMLInputElement).value = '0';
    this.intentos += 1;
    
   }
  }*/

  fnChangeInput(value, id, i){
    // this.balanceIncomeService.updateInputIncomeStatement(value, id, 'room'+this.data.teamId,i);
  }

  getSingleTextLabel(labelNumber: string): string {
    const tmp = this.data.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    return tmp ? tmp.Label : '';
  }

}
