// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
/*
export const environment = {
  production: false,
  SOCKET_ENDPOINT: "http://192.168.1.68:6002"
};*/

/* endpoint for beto*/
export const environment = {
  production: false,
  SOCKET_ENDPOINT: "https://app.income-outcome.com:6007",//Production Andromeda
  // SOCKET_ENDPOINT: "https://qa.income-outcome.com:6010",//QA Andromeda
  // SOCKET_ENDPOINT: 'http://localhost:6007',
  //SOCKET_ENDPOINT: "http://3.15.18.129",//Production Andromeda
  //SOCKET_ENDPOINT: 'http://3.142.90.167:6007',//PEQUEÑA Porduccion Linkthinks
  //SOCKET_ENDPOINT: 'http://18.219.10.167:6007',//MEDIANA no disponible
  //SOCKET_ENDPOINT: 'http://3.143.219.23:6007',//GRANDE no disponible
  userLoggedIn: false,
  UserID: 0,
  type: 0,
  DistributorID: 0,
  CompanyName: '',
  IsFacilitator:0,
  socketStatus : true,
  isProd: true,
  idsChiefAdmin: [216]
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
