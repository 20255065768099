import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BoardService } from 'src/app/services/board/board.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { WorkshopService } from 'src/app/services/workshop/workshop.service';
import Swal from 'sweetalert2';
import { IncomeStatementComponent } from '../income-statement/income-statement.component';
import { StandardBalanceSheetComponent } from '../standard-balance-sheet/standard-balance-sheet.component';
import { IncomeStatementServiceComponent } from '../income-statement-service/income-statement-services.component';
import { StandardBalanceSheetServicesComponent } from '../standard-balance-sheet-service/standard-balance-sheet-services.component';
import { CdkDrag } from '@angular/cdk/drag-drop';
@Component({
  selector: 'app-board-image-service',
  templateUrl: './board-image-service.component.html',
  styleUrls: ['./board-image-service.component.scss']
})
export class BoardImageServiceComponent implements OnInit {
  lstLanguagesBoard = [];
  improveStatus = -1;
  checkBoxes = [
    { id: 1, blnCheck: false, title: "Clear Income Statement", idBd: null, disabled: true, show: 1 },
    { id: 2, blnCheck: false, title: "Update Receivables", idBd: null, disabled: true, show: 1 },
    { id: 3, blnCheck: false, title: "Update Liabilities", idBd: null, disabled: true, show: 1 },
    { id: 4, blnCheck: false, title: "Buy Raw Materials", idBd: null, disabled: true, show: 1 },
    { id: 5, blnCheck: false, title: "Load Raw Materials to machine", idBd: null, disabled: true, show: 1 },
    { id: 6, blnCheck: false, title: "Pay Labor", idBd: null, disabled: true, show: 1 },
    { id: 7, blnCheck: false, title: "Take Depreciation", idBd: null, disabled: true, show: 1 },
    { id: 8, blnCheck: false, title: "Pay Expenses", idBd: null, disabled: true, show: 1 },
    { id: 9, blnCheck: false, title: "Prepare Bids", idBd: null, disabled: true, show: 1 },
    { id: 10, blnCheck: false, title: "Pay Advertising", idBd: null, disabled: true, show: 1 },
    { id: 11, blnCheck: false, title: "The Market", idBd: null, disabled: true, show: 1 },
    { id: 12, blnCheck: false, title: "Take Product from Machine", idBd: null, disabled: true, show: 1 },
    { id: 13, blnCheck: false, title: "Make Delivery & Recieve Payment", idBd: null, disabled: true, show: 1 },
    { id: 14, blnCheck: false, title: "Complete the Income Statement", idBd: null, disabled: true, show: 1 },
    { id: 15, blnCheck: false, title: "Adjust Retained Earnings", idBd: null, disabled: true, show: 1 },
    { id: 16, blnCheck: false, title: "Complete the Balance Sheet", idBd: null, disabled: true, show: 1 }
  ];
  arrTextLabels = [];
  improvementsOptionsSelected = [];
  improvementSelected = false;
  improvementImplemented = false;
  isProcessing = true;
  boardDisable = true;
  isHintInterest = 0;
  jsonBlnOpenModeActions = {
    'isGreenRoyals': false,
    'isBlueRoyals' :false,
    'isHaveToBuyMat': false,
    'isDepreciateMachine1': false,
    'isDepreciateMachine2': false,
    'isDepreciation': false,
    'isBuyMaterials': false,
    'showMessageOwe12': false,
    'showMessageOwe18': false,
    'isBuyLoan': false,
    'showMessageInterest1': false,
    'isSoldBuild': false,
    'showMessageOwe20': false,
    'showMessageOwe10': false,
    'isPayBill': false,
    'isBuyBuild': false,
    'isBuySecondMachine': false,
    // para hacer mas grande una maquina
    'isEfficiency1': false,
    'isEfficiency2': false,
    // para mostrar maquina de customizing
    'isCustomizingShow': false,
    // para mostrar segunda maquina
    'isSecondMachine': false,
    'showBill20': false,
    'isPayBill20': false,
    'showBill10': false,
    'isPayBill10': false,
    'showBill1': false,
    'isPayBill1': false,
    'showBill6': false,
    'showBill2': false,
    'showBill2Aux1': false,
    'showBill2Aux2': false,
    'isRawMaterials1': false,
    'isRawMaterials2': false,
    'showMessageOwe4': false,
    'isActiveImprovement':false,
    'isLoansPaid':false,
    'isPayablesPaid':false
  }
  checkBoxesForFacilitator = [];
  checkBoxesForFacilitatorImprovement = [
    { id: 0, blnCheck: false, title: "Select Improvement Option", idBd: null, disabled: true },
    { id: 1, blnCheck: false, title: "Clear Income Statement", idBd: null, disabled: true },
    { id: 2, blnCheck: false, title: "Update Receivables", idBd: null, disabled: true },
    { id: 3, blnCheck: false, title: "Update Liabilities", idBd: null, disabled: true },
    { id: 17, blnCheck: false, title: "Buy or Upgrade Equipment", idBd: null, disabled: true },
    { id: 18, blnCheck: false, title: "Pay recruitment and Trainning", idBd: null, disabled: true },
    { id: 4, blnCheck: false, title: "Buy Raw Materials", idBd: null, disabled: true },
    { id: 5, blnCheck: false, title: "Load Raw Materials to machine", idBd: null, disabled: true },
    { id: 6, blnCheck: false, title: "Pay Labor", idBd: null, disabled: true },
    { id: 7, blnCheck: false, title: "Take Depreciation", idBd: null, disabled: true },
    { id: 8, blnCheck: false, title: "Pay Expenses", idBd: null, disabled: true },
    { id: 9, blnCheck: false, title: "Prepare Bids", idBd: null, disabled: true },
    { id: 10, blnCheck: false, title: "Pay Advertising", idBd: null, disabled: true },
    { id: 11, blnCheck: false, title: "The Market", idBd: null, disabled: true },
    { id: 12, blnCheck: false, title: "Take Product from Machine", idBd: null, disabled: true },
    { id: 13, blnCheck: false, title: "Make Delivery & Recieve Payment", idBd: null, disabled: true },
    { id: 14, blnCheck: false, title: "Complete the Income Statement", idBd: null, disabled: true },
    { id: 15, blnCheck: false, title: "Adjust Retained Earnings", idBd: null, disabled: true },
    { id: 16, blnCheck: false, title: "Complete the Balance Sheet", idBd: null, disabled: true }
  ];
  checkBoxesForFacilitatorNoImprovement = [
    { id: 1, blnCheck: false, title: "Clear Income Statement", idBd: null, disabled: true },
    { id: 2, blnCheck: false, title: "Update Receivables", idBd: null, disabled: true },
    { id: 3, blnCheck: false, title: "Update Liabilities", idBd: null, disabled: true },
    { id: 4, blnCheck: false, title: "Buy Raw Materials", idBd: null, disabled: true },
    { id: 5, blnCheck: false, title: "Load Raw Materials to machine", idBd: null, disabled: true },
    { id: 6, blnCheck: false, title: "Pay Labor", idBd: null, disabled: true },
    { id: 7, blnCheck: false, title: "Take Depreciation", idBd: null, disabled: true },
    { id: 8, blnCheck: false, title: "Pay Expenses", idBd: null, disabled: true },
    { id: 9, blnCheck: false, title: "Prepare Bids", idBd: null, disabled: true },
    { id: 10, blnCheck: false, title: "Pay Advertising", idBd: null, disabled: true },
    { id: 11, blnCheck: false, title: "The Market", idBd: null, disabled: true },
    { id: 12, blnCheck: false, title: "Take Product from Machine", idBd: null, disabled: true },
    { id: 13, blnCheck: false, title: "Make Delivery & Recieve Payment", idBd: null, disabled: true },
    { id: 14, blnCheck: false, title: "Complete the Income Statement", idBd: null, disabled: true },
    { id: 15, blnCheck: false, title: "Adjust Retained Earnings", idBd: null, disabled: true },
    { id: 16, blnCheck: false, title: "Complete the Balance Sheet", idBd: null, disabled: true }
  ];
  countQuantitysDelivered = 0;
  TeamName :string = "Team Name";
  TeamNameArray: string[] = [];
  blnErrorContainer = true;
  isModeOpen = true;
  isOpenMarket = null;
  isOpenMarketDisabledBoard = false;
  intOpenMarket = 0;
  isTrashContainer = false;
  urlImgTrash = ' ../../../assets/boteblancoabierto.svg';
  currentMonth = 2;
  arrOrders = [];
  totalCashMAchine1 = 0;
  totalCashMAchine2 = 0;
  mejorasXMes = 0;
  dialogRef: any;
  userId = 0;
  abrioModal = false;
  expandsAdvertising = false;
  workshopID:number;
  workshopCurrentMonthTeamAvanzado = 0;
  jsnUserSelected;
  owesAdvertising = 0;
  auxValTotalAdvertising = 0;
  advertising = 0;
  monthAuxMonthByMonth = 0;
  AdvertisingExtra = 0;
  projects = 1;
  IdStatusBid = 0;
  monthFacilitator = 0;
  showOwedAdvertising = false;
  showPaidAdvertising = false;
  isDialogMarketOpen = false;
  emailNotExists = false;
  roomId = "";
  roomWorkShopId = "";
  teamId;
  Cycles = 0;
  businessModel = '';
  userData;
  isClickBalanceSheet = false;
  isClickCustomers = false;
  blockAllTeams = false;
  itemDragged = null;
  indexItemDragged = -1;
  listAux;
  contadorSecreto = 0
  users = ['Usuario1', 'Usuario2', 'Usuario3', 'Usuario4', 'Usuario5'];
  contUser = 0;
  room = ['Room1', 'Room2', 'Room3', 'Room4', 'Room5'];
  contRoom = 0;
  strBackground = "#4b0fae";
  /* para maquina de estados */
  status = 0;
  // opcion de deshabilitacion de botones
  statusBeforeMonth = 0;
  //isChrome;

  blnFacilitador: boolean = false  /* bandera para saber si es facilitador */
  blnMove: boolean = false;
  blnAllowAnim: boolean = false;
  blnAllowAnimCapEx: boolean = false
  blnAnimRawMat: boolean = false;
  blnAnimRawMat2: boolean = false;
  blnAnimCoinPeople: boolean = false;
  blnMoveMonth2: boolean = false;
  blnMoveMonth3: boolean = false;
  blnMoveMonth4: boolean = false;
  blnMoveMonth5: boolean = false;
  blnMoveMonth6: boolean = false;
  move30: boolean = false;
  move60: boolean = false;
  move90: boolean = false;
  moveCash: boolean = false;
  blnBackgroundBlue: boolean = false;
  blnBackground2: boolean = false;
  blnBackground4: boolean = false;
  blnBackground5: boolean = false;
  blnBackground6: boolean = false;
  blnBackground7_8: boolean = false;
  blnBackground9: boolean = false;
  showBillAnimationStep16: boolean = false;
  showDepreciationAnimationStep26: boolean = false;
  showBillAnimationStep25: boolean = false;
  itemMove: number = 0;
  idLenguage: number = 1;  // ID del lenguaje (1=ingles)
  /*++++++++++++VARIABLES PARA CONTROL DE CAMBIO DE BOARD */
  //estas variable no se borra en cambio de Board,
  // variable global de todos los usuarios del board, observers members y facilitadores para usarla en el cambio de board
  lstGloblalUsersBoard = [];
  blnCanMoveitems: boolean = true;
  showCheckListFacilitator : boolean = false;
  blnCanMoveFacilitator : boolean = false;
  isAndromeda: boolean = false; //Indica si el ws es de Andromeda
  /*++++++++++++ FIN VARIABLES PARA CONTROL DE CAMBIO DE BOARD */
  lblUserwithControl = "";
  isConnected = true;

  boardMode: "DM" | "NM";

  isFacilitatorTeamAccount: boolean = false; //Indica si la cuenta del usuario pertenece a las cuentas del Facilitador para cada equipo ej: facilitator_teamN@io.com

  blnShowerrorPasoImplementImprov = false;
  textErrorImplementImprov = "";

  userWithoutsession = false;
  canSavebackup = false;
  initialDate;
  blnChangeOpenBoard = true;
  liabilitiesReceivablesBeforeUpdates: {
    id: number | null;
    liabilities30: number | null;
    liabilities60: number | null;
    liabilities90: number | null;
    payables30: number | null;
    payables60: number | null;
    receivables30: number | null;
    receivables60: number | null;
    receivables90: number | null;
  } = {
      id: null,
      liabilities30: null,
      liabilities60: null,
      liabilities90: null,
      payables30: null,
      payables60: null,
      receivables30: null,
      receivables60: null,
      receivables90: null,
    }
  constructor(private activatedRoute: ActivatedRoute,
    private languageservice: LanguageService,
    private boardService: BoardService,
    private workshopService : WorkshopService,
    private _matDialog: MatDialog,
    ) { }
    lstLoans = [];
    lstPayables = [];
    cdkLstTrash = [];
    cdkLstTrashCash = [];
    cdkLstNoAreaDragg = [];
    currentImprovement = "";
    cdkLstTrashCapitalS = [];
    cdkLstBank = [];
    cdkLstCapEx = [];
    paidCapEx = [];
    paySuppliers = [];
    orders = [];
    cdkLstLabourP = [];
    cdkLstSuppliers = [];
    cdkLstMaterials = [];
    cdkLstLandBuild = [];
    cdkLstFinishedFI = [];
    cdkLstLoans90 = [];
    cdkLstLoans60 = [];
    cdkLstLoans30 = [];
    cdkLstLoans90Aux = [];
    cdkLstLoans60Aux = [];
    cdkLstLoans30Aux = [];
    cdkLstCash = [];
    cdkLstReceivables90 = [];
    cdkLstReceivables60 = [];
    cdkLstReceivables90Anim = [];
    cdkLstReceivables60Anim = [];
    cdkLstReceivables30Anim = [];
    cdkLstReceivablesCashAnim = [];
    lista2 = [];
    itemsToMove: number = 0;
    move2Items: boolean = false;
    move3Items: boolean = false;
    move1Items: boolean = false;
    cdkLstReceivables30 = [];
    totCdkLstReceivables90 = 0;
    totCdkLstReceivables60 = 0;
    totCdkLstReceivables30 = 0;
    cdkLstCapitalS = [];
    cdkLstRetainedE = [];
    cdkLstNetIncome = [];
    cdkLstTaxes = [];
    cdkLstIncomeBT = [];
    cdkLstInterest = [];
    cdkLstRent = [];
    cdkLstRecruitmentT = [];
    cdkLstAdminOver = [];
    cdkLstAdministration = [];
    cdkLstAdvertasing = [];
    cdkLstMarketingS = [];
    cdkLstGoldenS = [];
    cdkLstChangeI = [];
    cdkLstDeprecation = [];
    cdkLstMachineOver = [];
    cdkLstLaborBlue = [];
    facilitatorOfBoard;
    cdkRles = [];
    cdkLstCostGS = [];
    cdkLstLocationMachine = [];
    cdkLstInsideMachineCapex = [];
    cdkLstInsideMachinePeople = [];
    cdkLstInsideMachineWorkInProgress = [];
    cdkLstInsideMachineCapex2 = [];
    cdkLstInsideMachinePeople2 = [];
    cdkLstInsideMachineWorkInProgress2 = [];
    cdkLstInsideMachineCapexCustomizing = [];
    cdkLstInsideMachineCapex3 = [];
    cdkLstInsideMachinePeople3 = [];
    cdkLstInsideMachineWorkInProgress3 = [];
    cdkLstPayables60 = [];
    cdkLstPayables30 = [];
    cdkLstPayables60Aux = [];
    cdkLstPayables30Aux = [];
    cdkLstOther = [];
    lstPositions = [];
    lstPositionBefore = [];
    //#endregion listas areas draggables
    blnGrowNocontrol = false;
    growNocontrolTaked = false;
    blnAccessCUSTOMERISBS = true;
    blnAllowSeeResultTabs = true;
    teamIdOriginal = 0;
    lstTeamsGlobal: any;
    isPendingProject  = 0; 
    showOption = false;
    blnGoToMonth = false;
    lstTeamsBalance = []
    teamsCompleted = []  
    lastStartMonth
    forecastExc = 0
    isNoProjectsSocketServer = false;
    facilitatorEmails = new Set([
      "Fac1", "fac1", "Fac2", "fac2", "Fac3", "fac3", "Fac4", "fac4", "Fac5", "fac5", "Fac6", "fac6",
      "Fac1a", "fac1a", "Fac2a", "fac2a", "Fac3a", "fac3a", "Fac4a", "fac4a", "Fac5a", "fac5a", "Fac6a", "fac6a",
      "Fac1b", "fac1b", "Fac2b", "fac2b", "Fac3b", "fac3b", "Fac4b", "fac4b", "Fac5b", "fac5b", "Fac6b", "fac6b",
      "Fac1c", "fac1c", "Fac2c", "fac2c", "Fac3c", "fac3c", "Fac4c", "fac4c", "Fac5c", "fac5c", "Fac6c", "fac6c",
      "Fac10","Fac20","Fac30","fac10","fac20","fac30"
    ]);
    
  ngOnInit(): void {

    this.activatedRoute.params.subscribe(async (params) => {
      console.log('PARAMETROS:  ', params);
      this.workshopID = params.workshopID;
      this.teamId = params.teamId;

      this.arrTextLabels = (await this.languageservice.getValidationLabels(parseInt(this.workshopID.toString())).toPromise()).textLabels;
      console.log(this.arrTextLabels)
      this.boardService.getLiabilitiesReceivablesUpdates(this.workshopID, this.teamId).subscribe(res => {
        if (res.intResponse === 200) {
          this.liabilitiesReceivablesBeforeUpdates = res.jsonData;
        }
      });
      this.workshopService.getWorkshopById(this.workshopID).subscribe(response => {
        console.log("getWorkshopById", response);
        if (response['intResponse'] == 200) {
          this.Cycles = response['data'][0]['Cycles'];
          this.workshopCurrentMonthTeamAvanzado = response['data'][0]['currentMonth'];
          this.improveStatus = response['data'][0]['ImproveOption'];
          this.statusBeforeMonth = response['data'][0]['ImproveOption'] + 1;
          var lngInitial = { LanguageId: response['data'][0]['LanguageId'], Language: response['data'][0]['Language'], langBase: 1 }
          this.lstLanguagesBoard.push(lngInitial);
          this.idLenguage = lngInitial.LanguageId;

          this.boardMode = response['data'][0]['BoardMode'];

          //buscamos si hay algun valor de lenguaje guardado previamente por el usuario
          this.languageservice.getLanguageSaved(this.workshopID,this.userId)
          .subscribe(respLangSaved => {
            console.log("resp languageSAVED:", respLangSaved);
            if(respLangSaved["jsnAnswer"]["LanguageId"] != 0){
              this.getTextLabelsByLanguage(respLangSaved["jsnAnswer"]["LanguageId"]);
            }else{
              this.getTextLabelsByLanguage(lngInitial.LanguageId);
            }
            
          })
        
        }
      });

      this.boardService.getInitBoard(this.teamId).subscribe(async responseBoard => {
        console.log("responseBoard", responseBoard);
        if (responseBoard.teamInf.intResponse == 200 && responseBoard.board.intResponse == 200) {
          this.TeamName = responseBoard.teamInf.team.Avatar;
                  console.log(this.TeamName,"aquiiii");
                  // if (!this.TeamName && this.blnFacilitador) {
                  //   this.TeamName = 'ACME';
                  // }
                  this.TeamNameArray = this.TeamName != null ? this.TeamName.split(" ") : [];
                  this.fnLoadBoardBackground(responseBoard.teamInf.team.Color);
                  const checklist = responseBoard.checkList.response as Array<any>
                  this.checkBoxes = [];
                  checklist.forEach((item, i) => {                    
                    this.checkBoxes.push({
                      id: item.idCheck,
                      blnCheck: item.valor,
                      title: item.title,
                      idBd: item.CheckListId,
                      disabled: true,
                      show: item.show
                    });
                  })
                  // para las actions del board;
                  const temArr = responseBoard.actionsBoard.response as Array<any>
                  temArr.forEach(item => {
                    this.jsonBlnOpenModeActions[item.Name] = item.Value as boolean;
                  });
                  console.log(this.jsonBlnOpenModeActions);
                  // fin para las actions del board;


                  await this.fnAssignPiecesArea(responseBoard.board.board);
                  console.log('cdkLstGoldenS',this.cdkLstGoldenS);
                  // this.jsonBlnOpenModeActions.isSoldBuild = this.cdkLstLandBuild.length == 0;
                  // this.cdkLstReceivables90Aux = [...this.cdkLstReceivables90];
                  // this.cdkLstReceivables60Aux = [...this.cdkLstReceivables60];
                  // this.cdkLstReceivables30Aux = [...this.cdkLstReceivables30];
                  this.totCdkLstReceivables30 = this.returnTotalLst(this.cdkLstReceivables30);
                  this.totCdkLstReceivables60 = this.returnTotalLst(this.cdkLstReceivables60);
                  this.totCdkLstReceivables90 = this.returnTotalLst(this.cdkLstReceivables90);
                  this.cdkLstLoans90Aux = [...this.cdkLstLoans90];
                  this.cdkLstLoans60Aux = [...this.cdkLstLoans60];
                  this.cdkLstLoans30Aux = [...this.cdkLstLoans30];
                  this.cdkLstPayables60Aux = [...this.cdkLstPayables60];
                  this.cdkLstPayables30Aux = [...this.cdkLstPayables30];

                  this.status = responseBoard.teamInf.team.Status == null ? 0 : responseBoard.teamInf.team.Status;
                  this.currentMonth = responseBoard.teamInf.team.Month == null ? 1 : responseBoard.teamInf.team.Month;

                  if (this.currentMonth >= 4 && this.status == 2) {
                    this.lstLoans = this.cdkLstLoans30;
                  }
                  this.boardService.getAdvertisingBill(this.teamId, this.currentMonth).subscribe(response => {
                    console.log("Response advertising", response);
                    this.owesAdvertising = response.Advertising;
                    this.advertising = response.Advertising;
                    let tot = 0;
                    this.cdkLstAdvertasing.forEach(item => {
                      tot += item.valor;
                    })
                    this.owesAdvertising -= tot;
                    if (this.owesAdvertising > 0)
                      this.showOwedAdvertising = true;
                    else
                      this.showOwedAdvertising = false;
                  })
                  const x = await this.getOrders();
          console.log('Mes', this.currentMonth)
          if (this.status >= 6 && this.currentMonth >= 2) {
            if (this.cdkLstAdvertasing[0] != undefined) {
              this.auxValTotalAdvertising = this.cdkLstAdvertasing[0].valor
            } else {
              this.auxValTotalAdvertising = 0
            }

          }
          this.isModeOpen = responseBoard.teamInf.team.Mode == 1 ? true : false;
          console.log(this.isModeOpen, "ismodeopen?");
          this.boardService.getLiabilitiesReceivablesUpdates(this.workshopID, this.teamId).subscribe(res => {
            if (res.intResponse === 200) {
              this.liabilitiesReceivablesBeforeUpdates = res.jsonData;
            }
          });
        }else {
          Swal.fire('Error!', responseBoard.strAnswer, 'error');
        }
      })


    })
  }
  chargeotherLanguages(strLanguages) {//atrLanguages format like 1,5,n,
    console.log("recibimos strlanguages:", strLanguages);
    var arrintIdLangs = strLanguages.split(",");
    if (arrintIdLangs.length > 0) {
      for (let index = 0; index < (arrintIdLangs.length - 1); index++) {
        console.log("obteniendo datos del arrintIdLangs[x]", arrintIdLangs[index])
        this.languageservice.getDescByLanguageId(arrintIdLangs[index])
          .subscribe(response => {
            console.log("resp languagesdata:", response);
            this.lstLanguagesBoard.push(response["jsnAnswer"]);
            console.log("this.lstLanguagesBoard actual:", this.lstLanguagesBoard);
          })

      }
    }

  }
  getTextLabelsByLanguage(languageId: number) {
    this.languageservice.getScreenLabelsByLanguage(languageId).subscribe(res => {
      this.arrTextLabels = res.textLabels ? res.textLabels : [];
    });
  }

  getOrders() {
    return new Promise((resolve, reject) => {
      this.boardService.getOrdersByTeamId(this.teamId, (this.status == 0 || this.status == -1) ? this.currentMonth - 1 : this.currentMonth).subscribe(response => {

        console.log(response, "response orders");
        if (response.intResponse == 200 && response.orders) {
          this.orders = response.orders as Array<any>;
          this.orders.forEach(item => {
            if (item.Delivered == 1) {
              item.showOrder = false;
              this.countQuantitysDelivered += item.Quantity;
            }
          });
          for (let i = 0; i < this.countQuantitysDelivered; i++) {
            this.cdkLstCostGS[i].paid = true;
          }
          if (!this.blnFacilitador) {
            if (this.currentMonth >= 3 && this.improvementsOptionsSelected.length > 0) {
              if (this.improvementsOptionsSelected.length == 1) {
                this.improvementsOptionsSelected.push({
                  improvement: '',
                  value: 0,
                  ImproveId: -1,
                  option: 0,
                  question: ''
                })
              }
              if ((this.improvementsOptionsSelected[0].improvement == "Quality" && this.improvementsOptionsSelected[0].value == 3
                || this.improvementsOptionsSelected[1].improvement == "Quality" && this.improvementsOptionsSelected[1].value == 3)) {
                this.orders.forEach(item => {
                  item.Bid = Math.round(item.Bid * 1.20);
                });
              }
            }
          }
        }
        if (this.blnFacilitador && this.orders.length == 0 && this.currentMonth == 1) {
          this.orders.push(
            { Terms: 60, OrderNum: 101, Bid: 40, Quantity: 3, Delivered: false },
          )
        }
        //Cuando se recarga la página los valores se pierden, por lo que aquí en getOrders son asignados nuevamente
        if (this.blnFacilitador && this.orders.length == 1 && this.currentMonth == 1) {
          console.log(this.orders)
          if (this.orders[0]) {
            if(this.status <= 33){
              this.orders[0].Delivered = false;
            this.orders[0].showOrder = true; 
            this.orders[0].blnDeleted = 0;  
            }else{
              this.orders[0].Delivered = true;
            this.orders[0].showOrder = false; 
            this.orders[0].blnDeleted = 0;    
          }
            }
            
        }
        resolve(true);
      });
    });
  }

  returnTotalLst(lst: Array<any>) {
    let tot = 0;
    lst.forEach(item => {
      tot += item.valor;
    });
    return tot;
  }
  fnAssignPiecesArea(board: Array<any>) {
    return new Promise(resolve => {
      board.forEach(item => {
        if (item.Valor == 0)
          return;
        switch (item.AreaDrag) {
          case 1:
            this.cdkLstTrashCash.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 2:
            this.cdkLstBank.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 3:
            this.cdkLstCapEx.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 4:
            this.cdkLstLabourP.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 5:
            this.cdkLstSuppliers.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 6:
            this.cdkLstMaterials.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 7:
            this.cdkLstInsideMachineCapex.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 8:
            this.cdkLstInsideMachinePeople.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 9:
            this.cdkLstInsideMachineWorkInProgress.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 10:
            this.cdkLstLandBuild.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 11:
            this.cdkLstFinishedFI.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 12:
            this.cdkLstLoans90.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: "",
                strDescription: item.strDescription
              }
            );
            break;
          case 13:
            this.cdkLstLoans60.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: "",
                strDescription: item.strDescription
              }
            );
            break;
          case 14:
            this.cdkLstLoans30.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: "",
                strDescription: item.strDescription
              }
            );
            break;
          case 15:
            this.cdkLstReceivables90.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: "",
                strDescription: item.strDescription
              }
            );
            break;
          case 16:
            this.cdkLstReceivables60.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: "",
                strDescription: item.strDescription
              }
            );
            break;
          case 17:
            this.cdkLstReceivables30.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: "",
                strDescription: item.strDescription
              }
            );
            break;
          case 18:
            this.cdkLstCapitalS.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 19:
            this.cdkLstRetainedE.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 20:
            this.cdkLstMachineOver.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 21:
            this.cdkLstDeprecation.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 22:
            this.cdkLstChangeI.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 23:
            this.cdkLstMarketingS.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 24:
            this.cdkLstAdvertasing.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 25:
            this.cdkLstAdministration.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 26:
            this.cdkLstAdminOver.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 27:
            this.cdkLstRecruitmentT.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 28:
            this.cdkLstRent.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 29:
            this.cdkLstInterest.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 30:
            this.cdkLstCash.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                strDescription: item.strDescription
              }
            );
            break;
          case 31:
            this.cdkLstCostGS.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                paid: false
              }
            );
            break;
          case 32:
            this.cdkLstOther.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 34:
            this.cdkLstIncomeBT.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 35:
            this.cdkLstNetIncome.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 36:
            this.cdkLstTaxes.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 37:
            this.cdkLstPayables60.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 38:
            this.cdkLstPayables30.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 39:
            this.cdkRles.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 40:
            this.cdkLstTrashCapitalS.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 41:
            this.cdkLstInsideMachineCapexCustomizing.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 47:
            this.cdkLstInsideMachineCapex2.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 48:
            this.cdkLstInsideMachinePeople2.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 49:
            this.cdkLstInsideMachineWorkInProgress2.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
            case 50:
              this.cdkLstLaborBlue.push(
                {
                  // descripcion: 'bulto 20', 
                  type: item.tipo,
                  valor: item.Valor,
                  id: item.StatusBoardId,
                  draggable: false,
                  draggingBy: ""
                }
              );
              break;  
          case 51:
            this.cdkLstGoldenS.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break; 
          case 57:
            this.cdkLstInsideMachineCapex3.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 58:
            this.cdkLstInsideMachinePeople3.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 59:
            this.cdkLstInsideMachineWorkInProgress3.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
        }
      })
      resolve('listo');
    })

  }

  fnLoadBoardBackground(colorTeam) {
    /* { id: 1, selected: false, color: '#54a4bc', disabled: false },
       { id: 2, selected: false, color: '#accc94', disabled: false },
       { id: 3, selected: false, color: '#8ccce4', disabled: false },
       { id: 4, selected: false, color: '#7f2378', disabled: false },
       { id: 5, selected: false, color: '#083584', disabled: false },
       { id: 6, selected: false, color: '#4b0fae', disabled: false },
       { id: 7, selected: false, color: '#af753a', disabled: false },
       { id: 8, selected: false, color: '#84eeeb', disabled: false },
       { id: 9, selected: false, color: '#ffe998', disabled: false }*/
    switch (colorTeam) {
      case null: {
        this.strBackground = "#4b0fae";
        //this.strBackground = "./../../../assets/DARKTHEME2.jpg";
        this.blnBackgroundBlue = true;
        this.blnBackground2 = false;
        this.blnBackground4 = false;
        this.blnBackground5 = false;
        this.blnBackground6 = false;
        this.blnBackground7_8 = false;
        this.blnBackground9 = false;
        break;
      }
      case "#7faeff": {
        this.strBackground = "#7faeff";
        //this.strBackground = "./../../../assets/MIDTHEME3.jpg";
        this.blnBackgroundBlue = false;
        this.blnBackground2 = false;
        this.blnBackground4 = false;
        this.blnBackground5 = false;
        this.blnBackground6 = false;
        this.blnBackground7_8 = false;
        this.blnBackground9 = false;
        break;
      }
      case "#50b1a8": {
        this.strBackground = "#50b1a8";
        //this.strBackground = "./../../../assets/MIDTHEME2.jpg";
        this.blnBackgroundBlue = false;
        this.blnBackground2 = false;//true;
        this.blnBackground4 = false;
        this.blnBackground5 = false;
        this.blnBackground6 = false;
        this.blnBackground7_8 = false;
        this.blnBackground9 = false;
        break;
      }
      case "#af753a": {
        this.strBackground = "#af753a";
        //this.strBackground = "./../../../assets/MIDTHEME1.jpg";
        this.blnBackgroundBlue = false;
        this.blnBackground2 = false;
        this.blnBackground4 = false;
        this.blnBackground5 = false;
        this.blnBackground6 = false;
        this.blnBackground7_8 = false;
        this.blnBackground9 = false;
        break;
      }
      case "#7f2378": {
        this.strBackground = "#7f2378";
        //this.strBackground = "./../../../assets/DARKTHEME3.jpg";
        this.blnBackgroundBlue = false;
        this.blnBackground2 = false;
        this.blnBackground4 = true;
        this.blnBackground5 = false;
        this.blnBackground6 = false;
        this.blnBackground7_8 = false;
        this.blnBackground9 = false;
        break;
      }
      case "#083584": {
        this.strBackground = "#083584";
        //this.strBackground = "./../../../assets/DARKTHEME2.jpg";
        this.blnBackgroundBlue = false;
        this.blnBackground2 = false;
        this.blnBackground4 = false;
        this.blnBackground5 = true;
        this.blnBackground6 = false;
        this.blnBackground7_8 = false;
        this.blnBackground9 = false;
        break;
      }
      case "#4b0fae": {
        this.strBackground = "4b0fae";
        //this.strBackground = "./../../../assets/DARKTHEME1.jpg";
        this.blnBackgroundBlue = false;
        this.blnBackground2 = false;
        this.blnBackground4 = false;
        this.blnBackground5 = false;
        this.blnBackground6 = true;
        this.blnBackground7_8 = false;
        this.blnBackground9 = false;
        break;
      }
      case "#bec3ff": {
        this.strBackground = "#bec3ff";
        //this.strBackground = "./../../../assets/LIGHTTHEME3.jpg";
        this.blnBackgroundBlue = false;
        this.blnBackground2 = false;
        this.blnBackground4 = false;
        this.blnBackground5 = false;
        this.blnBackground6 = false;
        this.blnBackground7_8 = true;
        this.blnBackground9 = false;
        break;
      }
      case "#84eeeb": {
        this.strBackground = "#84eeeb";
        //this.strBackground = "./../../../assets/LIGHTTHEME2.jpg";
        this.blnBackgroundBlue = false;
        this.blnBackground2 = false;
        this.blnBackground4 = false;
        this.blnBackground5 = false;
        this.blnBackground6 = false;
        this.blnBackground7_8 = true;
        this.blnBackground9 = false;
        break;
      }
      case "#ffe998": {
        this.strBackground = "#ffe998";
        //this.strBackground = "./../../../assets/LIGHTTHEME1.jpg";
        this.blnBackgroundBlue = false;
        this.blnBackground2 = false;
        this.blnBackground4 = false;
        this.blnBackground5 = false;
        this.blnBackground6 = false;
        this.blnBackground7_8 = false;
        this.blnBackground9 = true;
        break;
      }
      default: {
        this.strBackground = "#4b0fae";
        //this.strBackground = "./../../../assets/DARKTHEME2.jpg";
        this.blnBackgroundBlue = true;
        break;
      }
    }
  }

  getSingleTextLabel(labelNumber: string): string {
    const tmp = this.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    return tmp ? tmp.Label : '';
  }

  mapCheckboxTitleToLabel(title: string): string {
    switch (title) {
      case 'Clear Income Statement': return this.getSingleTextLabel('4004');
      case 'Update Receivables': return this.getSingleTextLabel('4005');
      case 'Update Liabilities': return this.getSingleTextLabel('4006');
      case 'Buy Raw Materials': return this.getSingleTextLabel('4010');
      case 'Load Raw Materials to machine': return this.getSingleTextLabel('4012');
      case 'Pay Labor': return this.getSingleTextLabel('4014');
      case 'Take Depreciation': return this.getSingleTextLabel('4015');
      case 'Pay Expenses': return this.getSingleTextLabel('4016');
      case 'Prepare Bids': return this.getSingleTextLabel('4029');
      case 'Pay Advertising': return this.getSingleTextLabel('4017');
      case 'The Market': return this.getSingleTextLabel('4018');
      case 'Take Product from Machine': return this.getSingleTextLabel('4019');
      case 'Make Delivery & Recieve Payment': return `${this.getSingleTextLabel('4020')} & ${this.getSingleTextLabel('4022')}`;
      case 'Complete the Income Statement': return this.getSingleTextLabel('4024');
      case 'Adjust Retained Earnings': return this.getSingleTextLabel('4025');
      case 'Adjust Retained Earnings*(Black Chips)': return this.getSingleTextLabel('4025');
      case 'Complete the Balance Sheet': return this.getSingleTextLabel('4026');
      case 'Select Improvement Option': return this.getSingleTextLabel('4030');
      case 'Buy or Upgrade Equipment': return this.getSingleTextLabel('4008');
      case 'Pay recruitment and Trainning': return this.getSingleTextLabel('4009');
      default: return title;
    }
  }
  get getCurrentImprovement(): 'Customizing' | 'Quality' | 'Expansion' | 'Raw Materials' | 'Efficiency' | '' | undefined {
    try {
      let position = 0;
      if (this.currentMonth == 3 || this.currentMonth == 4 && this.improvementsOptionsSelected[0]?.value == 1 && this.improvementsOptionsSelected[0]?.option != 6) {
        position = 0;
      } else if (this.currentMonth == 4 && this.improvementsOptionsSelected[0]?.value == 3 || (this.improvementsOptionsSelected[0]?.value == 1 && this.improvementsOptionsSelected[0]?.option == 6)) {
        position = 1;
      } else if (this.currentMonth >= 4) {
        if (
          ((this.improvementsOptionsSelected[0]?.value == 1 || this.improvementsOptionsSelected[0]?.value == 2) && this.improvementsOptionsSelected[0]?.option == 5 && this.improvementsOptionsSelected[0]?.Approved == 0) ||
          ((this.improvementsOptionsSelected[1]?.value == 1 || this.improvementsOptionsSelected[1]?.value == 2) && this.improvementsOptionsSelected[1]?.option == 5 && this.improvementsOptionsSelected[1]?.Approved == 0)
        ) {
          position = this.improvementsOptionsSelected[0]?.option == 5 ? 0 : 1;
        } else if (
          ((this.improvementsOptionsSelected[0]?.value == 1 || this.improvementsOptionsSelected[0]?.value == 2) && this.improvementsOptionsSelected[0]?.option == 2 && this.improvementsOptionsSelected[0]?.Approved == 0) ||
          ((this.improvementsOptionsSelected[1]?.value == 1 || this.improvementsOptionsSelected[1]?.value == 2) && this.improvementsOptionsSelected[1]?.option == 2 && this.improvementsOptionsSelected[1]?.Approved == 0)
        ) {
          position = this.improvementsOptionsSelected[0]?.option == 2 ? 0 : 1;
        } else if (
          ((this.improvementsOptionsSelected[0]?.value == 1 || this.improvementsOptionsSelected[0]?.value == 2) && this.improvementsOptionsSelected[0]?.option == 1 && this.improvementsOptionsSelected[0]?.Approved == 0) ||
          ((this.improvementsOptionsSelected[1]?.value == 1 || this.improvementsOptionsSelected[1]?.value == 2) && this.improvementsOptionsSelected[1]?.option == 1 && this.improvementsOptionsSelected[1]?.Approved == 0)
        ) {
          position = this.improvementsOptionsSelected[0]?.option == 1 ? 0 : 1;
        } else if (
          ((this.improvementsOptionsSelected[0]?.value == 1 || this.improvementsOptionsSelected[0]?.value == 2) && this.improvementsOptionsSelected[0]?.option == 3 && this.improvementsOptionsSelected[0]?.Approved == 0) ||
          ((this.improvementsOptionsSelected[1]?.value == 1 || this.improvementsOptionsSelected[1]?.value == 2) && this.improvementsOptionsSelected[1]?.option == 3 && this.improvementsOptionsSelected[1]?.Approved == 0)
        ) {
          position = this.improvementsOptionsSelected[0]?.option == 3 ? 0 : 1;
        } else {
          position = this.improvementsOptionsSelected[0]?.value == 3 || this.improvementsOptionsSelected[0]?.option == 6 ? 1 : 0;
        }
      }
      const result = this.improvementsOptionsSelected[position]?.improvement ? this.improvementsOptionsSelected[position]?.improvement : ''
      return result;
    } catch (error) {
      console.log(error);
      return '';
    }
  }
  isCheckListCompleteBeforeThan(title: string): boolean {
    const index = this.checkBoxes.findIndex(checkBox => checkBox.title === title);
    if (index !== -1) {
      let res = true;
      for (let i = 0; i < index; i++) {
        res = res && this.checkBoxes[i].blnCheck;
      }
      return res && !this.checkBoxes[index].blnCheck;
    } else {
      return false;
    }
  }
  get isType12inLabor(): boolean {
    return this.cdkLstLabourP.some(el => el.type == 12);
  }
  get isType5InCapEx(): boolean {
    return this.cdkLstCapEx.some(el => el.type == 5);
  }
  get isType11InCapEx(): boolean {
    return this.cdkLstCapEx.some(el => el.type == 11);
  }

  get isType4InCapEx(): boolean {
    return this.cdkLstCapEx.some(el => el.type == 4);
  }
  isCompleteMachine(list: Array<any>, id: string): boolean {
    let totMaterials = 0;
    let tipo = -1;
    let impE = id == "machine1" ? "isEfficiency1" : "isEfficiency2";
    list.forEach(item => {
      totMaterials += item.valor;
      tipo = item.type;
    });
    return (totMaterials == 12 && tipo == 7 && !this.jsonBlnOpenModeActions[impE]) ? true :
      (totMaterials == 18 && tipo == 7 && this.jsonBlnOpenModeActions[impE]) ? true :
        (totMaterials == 12 && tipo == 8) ? true :
          (totMaterials == 4 && tipo == 14 && !this.jsonBlnOpenModeActions[impE]) ? true :
            (totMaterials == 6 && tipo == 14 && this.jsonBlnOpenModeActions[impE]) ? true :
              (totMaterials == 16 && tipo == 16) ? true :
                (totMaterials >= 20 && tipo == 17) ? true :
                  (totMaterials >= 12 && tipo == 18) ? true :
                    false;
  }

  fnModalIncomeStatement() {
    console.log(this.improvementsOptionsSelected)
 
    let width = "";
    let height = "";
    let blnCanEdit = false
    let totSales = 0;
    this.orders.forEach(item => {
      totSales += item.Bid;
    })
        // this.userData['sizeIS'] = "632.992px,526.991px"
        
          width = '915px';
          height = '600px';
        
        console.log("ES FACILITADORR",this.blnFacilitador);
        this.dialogRef = this._matDialog.open(IncomeStatementServiceComponent, {
          disableClose: false,
          width: width,
          height: height,
          panelClass: ['resize-checklist'],//'event-form-dialog',
          data: {
            languageId: this.idLenguage,
            teamId: this.teamId,
            currentMonth: this.currentMonth,
            cycles: this.Cycles,
            workshopId: this.workshopID,
            totSales: totSales,
            canEdit: blnCanEdit,
            control: this.isOpenMarketDisabledBoard,
            arrTextLabels: this.arrTextLabels,
            isfacilitador: this.blnFacilitador,
            isInCurrentStatus: false,
            userId: this.userId
          },
        });
        this.dialogRef.afterClosed().subscribe((resp: { netIncome: number, createNetIncome: boolean }) => {
          if (!resp || !resp.createNetIncome) {
            return;
          }
          console.log("RETURNED DIALOG");
          console.log(resp)
        });
    
  }

  fnClickStandardBalanceSheet() {
    this.boardService.getBackOfTeam(this.teamId,this.currentMonth).subscribe((response) => {
      if(response.intResponse == 200 && response.back != null){

        let blnCanEdit =false;
        // Proceso para llenado de  Standard balance sheet aqui
        this.dialogRef = this._matDialog.open(StandardBalanceSheetServicesComponent, {
          disableClose: true,
          width: '777px',
          height: '860px',
          panelClass: 'event-form-dialog',
          data: {
            userId: this.userId,
            languageId: this.idLenguage,
            teamId: this.teamId,
            currentMonth: this.currentMonth,
            cycles: this.Cycles,
            workshopId: this.workshopID,
            canEdit: blnCanEdit,
            isInCurrentStatus: false,
            arrTextLabels: this.arrTextLabels,
          },
        });

        this.dialogRef.afterClosed().subscribe((resp) => {
          console.log("RETURNED DIALOG", resp);
        });

      }else{
        console.log("Entra else")
        
        let blnCanEdit = false;
        // Proceso para llenado de  Standard balance sheet aqui
        this.dialogRef = this._matDialog.open(StandardBalanceSheetServicesComponent, {
          disableClose: true,
          width: '777px',
          height: '860px',
          panelClass: 'event-form-dialog',
          data: {
            userId: this.userId,
            languageId: this.idLenguage,
            teamId: this.teamId,
            currentMonth: this.currentMonth,
            cycles: this.Cycles,
            workshopId: this.workshopID,
            canEdit: blnCanEdit,
            isInCurrentStatus:false,
            arrTextLabels: this.arrTextLabels,
          },
        });


        this.dialogRef.afterClosed().subscribe((resp) => {
          console.log("RETURNED DIALOG", resp);
        });
      }
    })

}

fnTest(){
  console.log("hey")
}
notDropped(item: CdkDrag<number>) {
  return false;
}
}
