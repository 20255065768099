<div mat-dialog-title class="style1 calibriFont">
    &nbsp; &nbsp; &nbsp; &nbsp;<label>Team mode</label>
    <!--<button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
        (click)="matDialogRef.close()">
        <span aria-hidden="true">&times;</span>
    </button>  -->
</div>
<mat-dialog-content style="height: 60% !important;">
    <div class="container h-100">
        <div class="row h-100">
            <div class="col-12" style="flex-direction: row; display: flex; align-items:center;">
                <div style="flex: 1; display:flex; flex-direction:column; text-align:center;"
                    *ngFor="let item of teams">
                    <div class="row">
                        <div class="col-12 w-100" style="text-align: center;">
                            <p style="margin: 10%; padding-top:5%;padding-bottom:5%" class="style4SinBackground calibriFont" [ngStyle]="{'background-color': item.Color, 'color': item.Color === '#ffe998' || item.Color === '#84eeeb' || item.Color === '#bec3ff' || item.Color == '#e4e4ec' || item.Color == '#7faeff' ? 'black' : 'white'}">{{item.Avatar}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 style2a calibriFont" style="text-align: center;">
                            Rigorous &nbsp;
                            <mat-slide-toggle [disabled]="item.Status >= 3" (change)="changeSwitch($event, item)" [checked]="item.Mode == 1" class="FontMontserratTextInputs"><span class="style2a calibriFont">Open</span>
                            </mat-slide-toggle>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button type="button" class="btn btn-secondary" id="btncloseHide" (click)="matDialogRef.close()">Close</button>
</mat-dialog-actions>