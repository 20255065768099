import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { SocketImprovementService } from 'src/app/services/sockets/socket-improvement/socket-improvement.service';
import { improvements, improvementsSS } from 'src/app/views/helpers/improvements';
import { ImprovementService } from 'src/app/services/improvement/improvement.service';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dialog-improvements',
  templateUrl: './dialog-improvements.component.html',
  styleUrls: ['./dialog-improvements.component.scss']
})
export class DialogImprovementsComponent implements OnInit {

  workshopId = 0;
  improveOptionId = 0;
  currentMonth = 0;
  teamId = 0;
  statusBoard = 0;
  enableSelect = true;
  improvements = JSON.parse(JSON.stringify(improvements));
  improvementZoom = improvements[0];
  destroy$: Subject<boolean> = new Subject<boolean>();
  blnEnableSelection = true;
  blnCanReSelect = false;
  intCycles = 4;
  boardMode:string =  "";
  blnHas4Cycles = true;
  constructor(
    public matDialogRef: MatDialogRef<DialogImprovementsComponent>,
    private spinner: NgxSpinnerService,
    private socketImprovement: SocketImprovementService,
    private improvementService: ImprovementService,
    private socketImprovementService: SocketImprovementService,
    @Inject(MAT_DIALOG_DATA) private _data: any
  ) { 
    console.log("this._data", this._data);
    this.teamId = this._data['teamId'];
    this.currentMonth = this._data['currentMonth'];
    this.workshopId = this._data['workshopId'];
    this.statusBoard = this._data['statusBoard'];
    this.enableSelect = this._data['enableSelect'];
    this.blnCanReSelect = this._data['canReSelect'];
    this.intCycles = this._data['Cycles'];
    this.boardMode = this._data['boardType'];
    console.log(this.boardMode)
    if(this.boardMode == "S&S EBIT"){
      console.log("Entra")
      this.improvements = JSON.parse(JSON.stringify(improvementsSS));
    }
    if(!this._data['enableSelect']){
      console.log("ENTRA AL DESHABILITAR");
      this.improvements.forEach(improvement => improvement.disabled = true);
    }
    if(this.intCycles == 5 || this.intCycles == 6){
      this.blnHas4Cycles = false;
    }

    console.log("this.improvements",this.improvements);
    
  }

  ngOnInit(): void {
    this.spinner.show();
    this.fnInitObserver();
    this.fnGetImprovement(this.teamId);
  }

  fnInitObserver() {
    this.socketImprovement.setupSocketConnection();
    this.socketImprovement._improvement
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (response) {
          console.log('RESPONSE SOCKET SERVER MODAL', response);
          if (response.status == 'improveStatusServer' && response.data != undefined) {
            //this.fnSetBid(response.data);
            console.log('improveStatusServer', response);
            if(response.data['improveStatus'] === 0)
              this.matDialogRef.close({status: 0});
          }
          /*else if (response.status == 'improveStatusServer' && response.data != undefined) {
            alert("IF LISENER DIALOG IMPORVEMENTS")
              this.matDialogRef.close({status: 1});
          }*/
           else if (response.status == 'askFacilitatorImprovementDeniedByTeamServer' && response.data != undefined) {
            //this.fnSetBid(response.data);
            console.log('askFacilitatorImprovementDeniedByTeamServer', response);
            Swal.fire({
              title: 'Acept?',
              showCancelButton: true,
              confirmButtonText: `Yes`,
              cancelButtonText: `No`,
            }).then(result => {
              if(result.isConfirmed){
                this.socketImprovement.declineAceptImprovementByTeam(response.data.teamId,response.data.improveOption, true);
              } else {
                this.socketImprovement.declineAceptImprovementByTeam(response.data.teamId,response.data.improveOption, false);
              }
            })
          }
          // else if (response.status == 'recelectImprovement' && response.data != undefined){
          //   if (response.data.teamId == this.teamId) {
          //   }
            
          // }
        }
      })
  }

  fnGetImprovement(teamId){
    this.improvementService.getImprovementByTeamId(teamId).pipe(finalize(()=>this.spinner.hide())).subscribe(response=>{
      console.log("response",response);
      //this.blnEnableSelection = response['improvement']['EnableSelection'] === 1 ? true:false;
      console.log("this.blnEnableSelection",this.blnEnableSelection);
      response['improvement']['options'].forEach(option => {
        if(option['Month'] === this.currentMonth)
          this.improveOptionId = option['ImproveOptionId'];
        for (const improvement of this.improvements) {
          if(option['Option'] === improvement['id'] && option['Status'] > 0){
            improvement['disabled'] = true;
          }
        }
      });
      /*this.improvements[0]['disabled'] = response['improvement']['RowMaterial'] === 1 ? true:false;

      this.improvements[1]['disabled'] = response['improvement']['Efficiency'] === 1 ? true:false;

      this.improvements[2]['disabled'] = response['improvement']['Customizing'] === 1 ? true:false;

      this.improvements[3]['disabled'] = response['improvement']['Quality'] === 1 ? true:false;

      this.improvements[4]['disabled'] = response['improvement']['Expansion'] === 1 ? true:false;*/
    })
  }

  fnSelectImprovement(improvement){
    this.improvements.forEach(improvement => {
      improvement.selected = false;
    });
    improvement.selected = true;
  }

  fnImprovementView(improvement, blnView){
    console.log("fnImprovementView");
    improvement.zoom = blnView;
    this.improvementZoom = improvement;
    console.log("fnImprovementView", this.improvementZoom);
  }

  fnSave(){
    let improvementId = 0;
    const selectedImprovement = this.improvements.find(improvement => improvement.selected && !improvement.disabled);
  
    if (!selectedImprovement) {
      this.fnAlertInfo(this.getErrorLabel('4030.1')); 
      return; 
    }
    if(this.blnEnableSelection){
              for (const improvement of this.improvements) {
                if(improvement['selected'] && !improvement['disabled']){
                  improvementId = improvement['id'];
                  break;
                }
              }
              console.log("fnSave", improvementId);
              /*necesitamos ver como brincar validacion
        creo que si metemos una variable al procedure que indique si venimos desde el nuevo modal y que si es así haga el update _reselectImprovementPermission = 1 */
              let _reselectImprovementPermission =  this.blnCanReSelect == true ? 1 : 0;
              if(this.blnCanReSelect == true && (improvementId == 6 || improvementId == 0)){
                  console.log("IF no selecciono algo diferente en reseleccion this.improveOptionId ==> ", this.improveOptionId)
                  this.matDialogRef.close({status: 0});
              }else{
                    console.log("else no selecion en reselect");

                    console.log("this.workshopId, this.improveOptionId,improvementId,1, this.currentMonth, _reselectImprovementPermission ++++", this.workshopId, this.improveOptionId,improvementId,1, this.currentMonth, _reselectImprovementPermission)
                    this.improvementService.updateImprovementOptionById(this.workshopId, this.improveOptionId,improvementId,1, this.currentMonth, _reselectImprovementPermission).subscribe(resp =>{
                              console.log("fnSave",resp);

                              if(resp['intResponse'] === 200){
                                if(this.blnCanReSelect == true){
                                    console.log("SOLO FALTA MANDAR SOCKET QUE actulice otros teams");
                                    //this.fnAlertSuccess(this.getErrorLabel('1080'));
                                    this.socketImprovementService.updateImproveOptionbyReselect(this.teamId,("roomWorkShop"+this.workshopId),improvementId, this.improveOptionId, 1);
                                    this.matDialogRef.close({status: 1, improveOptionId:this.improveOptionId, improvementId :improvementId});
                                }
                                else{
                                  this.socketImprovementService.updateImproveOption(this.teamId,("roomWorkShop"+this.workshopId),improvementId, this.improveOptionId, 1);
                                  this.fnAlertSuccess(this.getErrorLabel('1080'));
                                  this.matDialogRef.close({status: 1,});
                                }
                                
                              }
                              else
                                this.fnAlertInfo(
                                  resp['strResponse'] === 'You can not select another improvement' ? this.getErrorLabel('1079') :
                                    (resp['strResponse'] === 'Try again! Two other teams already chose this option before you!' ? this.getErrorLabel('1081') : this.getErrorLabel('1082'))
                                );
                      });

              }
    }else{
      this.fnAlertInfo(this.getErrorLabel('1079'));
    }
  }

  fnAlertInfo(strMessage){
    Swal.fire({
      text: '',
      icon: 'info',
      confirmButtonColor: '#5EA0CC',
      html: strMessage,
    });
  }

  fnAlertSuccess(strMessage){
    Swal.fire({
      text: '',
      imageUrl: '../../../../assets/iconSuccessfully.svg',
      imageAlt: 'icon successfully',
      confirmButtonColor: '#5EA0CC',
      html: strMessage,
    });
  }

  getSingleTextLabel(labelNumber: string): string {
    const tmp = this._data.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    return tmp ? tmp.Label : '';
  }

  getErrorLabel(labelNumber: string, code: string | null = null): string {
    const tmp = this._data.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    let msg = ''
    if (tmp) {
      const LabelBase = tmp.LabelBase;
      let errorCode = code;
      if (!code) {
        const array = LabelBase.split(':');
        errorCode = array.length > 0 ? array[0] : '';
      }
      const className = environment.isProd ? 'lightgray' : 'gray';
      msg = `<div><span style="color:${className}; font-size: 12px">${errorCode}: </span>${tmp.Label}</div>`;
    }
    return msg;
  }
  /*
  Improvement selection saved successfuly!
  You can not select another improvement
  Try again! Two other teams already chose this option before you!
  Try again! Another team chose this first!
  */
}
