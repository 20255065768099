import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { _URL_DISTRIBUTORS } from './../../config/config';

@Injectable({
  providedIn: 'root',
})
export class DistributorService {
  constructor(private http: HttpClient) {}

  createDistributorAndContacts(
    companyName: string,
    arrayOfContacts: any[],
    isResponseErrorEmailAlreadyRegister: boolean
  ): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const jsonDistributor = {
      companyName,
      arrayOfContacts,
      isResponseErrorEmailAlreadyRegister,
    };
    const params = JSON.stringify(jsonDistributor);
    console.log(params);
    return this.http.post(
      _URL_DISTRIBUTORS + '/createDistributorAndContacts',
      params,
      { headers: httpHeaders }
    );
  }

  getAllDistributors(): Observable<any> {
    return this.http.get(_URL_DISTRIBUTORS + '/getAllDistributors');
  }

  getAllContactsByDistributorID(id): Observable<any> {
    return this.http.get(
      _URL_DISTRIBUTORS + '/getContactsByDistributorID/' + id
    );
  }

  getAllContactsofAllDistributors(): Observable<any> {
    return this.http.get(
      _URL_DISTRIBUTORS + '/getAllcontactsofAllDistributors'
    );
  }

  getOnlyContactsByDistributorID(id): Observable<any> {
    return this.http.get(
      _URL_DISTRIBUTORS + '/getOnlyContactsByDistributorID/' + id
    );
  }

  deleteDistributorByID(id: string): Observable<any> {
    return this.http.delete(`${_URL_DISTRIBUTORS}/deleteDistributorById/${id}`);
  }

  getFuturesWorkshopsByID(id: string, dateUTC): Observable<any>{
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const jsonReq = {
      DistributorID: id,
      DateUTC: dateUTC,
    };
    const params = JSON.stringify(jsonReq);
    console.log('Parametros a enviar Distributor::::', params);
    return this.http.post(_URL_DISTRIBUTORS + '/getFuturesWorkshopsByDistributorID', params, {
      headers: httpHeaders,
    });
  }

  updateDistributorByID(
    distributorID,
    companyName,
    lstOfContacts: any[],
    lstOfNewContacts: any[]
  ): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
      DistributorID: distributorID,
      arrayOfContacts: lstOfContacts,
      arrayOfNewContacts: lstOfNewContacts,
      companyName,
    });
    return this.http.put(_URL_DISTRIBUTORS + '/updateDistributorByID', params, {
      headers: httpHeaders,
    });
  }

  // createContactDistributor(companyName: String, arrayOfContacts: any[], isResponseErrorEmailAlreadyRegister: boolean): Observable<any>{
  //   let httpHeaders = new HttpHeaders({
  //     'Content-Type': 'application/json'
  //   });
  //   let jsonDistributor = {
  //     companyName: companyName,
  //     arrayOfContacts: arrayOfContacts,
  //     isResponseErrorEmailAlreadyRegister: isResponseErrorEmailAlreadyRegister
  //   }
  //   let params = JSON.stringify(jsonDistributor);
  //   console.log(params);
  //   return this.http.post(_URL_DISTRIBUTORS+'/createDistributorAndContacts', params, {headers: httpHeaders});
  // }
}
