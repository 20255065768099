import { Injectable, OnDestroy, HostListener } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { io } from 'socket.io-client';
import { environment } from '../../../../environments/environment';
import { SocketioService } from '../../socketio.service';

@Injectable({
  providedIn: 'root'
})
export class SocketImprovementService {

  private $improvement: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  _improvement: Observable<any> = this.$improvement.asObservable();
  isMyCall = false;
  socket = this.socketioService.socket;

  constructor(private socketioService: SocketioService) { }

  setupSocketConnection() {
    console.log("setupSocketConnection IMPROVEMENTS");
    this.socket.on('improveStatusServer', (data) => {
      console.log("improveStatusServer",data);
      if(!this.isMyCall){
        this.$improvement.next({ data, status: 'improveStatusServer' });
      } 
      this.isMyCall = false;
    });
    this.socket.on('noProjectsSocketServer', (data) => {
      console.log("noProjectsSocketServer",data);
      if(!this.isMyCall){
        this.$improvement.next({ data, status: 'noProjectsSocketServer' });
      } 
      this.isMyCall = false;
    });
    this.socket.on('updateAvatarServer', (data) => {
      console.log("updateAvatarServer",data);
      if(!this.isMyCall){
        this.$improvement.next({ data, status: 'updateAvatarServer' });
      } 
      this.isMyCall = false;
    });
    this.socket.on('improveOptionServer', (data) => {
      console.log("improveOptionServer",data);
      this.$improvement.next({ data, status: 'improveOptionServer' });
    });

    this.socket.on('improveOptionServerbyReselect', (data) => {
      console.log("2.-improveOptionServerbyReselect",data);
      this.$improvement.next({ data, status: 'improveOptionServerbyReselect' });
    });

    this.socket.on('improveOptionStatusServer', (data) => {
      this.$improvement.next({ data, status: 'improveOptionStatusServer' });
    });

    this.socket.on('askFacilitatorImprovementDeniedByTeamServer', (data) => {
      this.$improvement.next({ data, status: 'askFacilitatorImprovementDeniedByTeamServer' });
    });

    this.socket.on('aceptDeclineImprovementServer', (data) => {
      this.$improvement.next({ data, status: 'aceptDeclineImprovementServer' });
    });
    this.socket.on('reselectImprovement', (data) => {
      this.$improvement.next({ data, status: 'reselectImprovement' });
    });

    this.socket.on('quitBotom',(data) => {
      this.$improvement.next({ data, status: 'quitBotom' });
    })
    
    
  }

  updateImproveStatus( 
    workShopId,     
    room,
    improveStatus,
    month
    ){
    this.socket.emit('improveStatus', {
      workShopId,
      room,
      improveStatus,
      month
    });
    this.isMyCall = true;
    this.improveOptionStatus$(improveStatus);
  }

  noProjectsSocket( 
    workShopId,     
    room,
    status
    ){
    this.socket.emit('noProjectsSocket', {
      workShopId,
      room,
      status    
    });
    this.isMyCall = true;
  }

  updateAvatar( 
    workShopId,     
    room,
    arrTeams
    ){
    this.socket.emit('updateAvatar', {
      workShopId,
      room,
      arrTeams
    });
    this.isMyCall = true;
  }

  updateImproveOption( 
    teamId,     
    room,
    improveOption,
    improveOptionId,
    improveStatus
    ){
    this.socket.emit('improveOption', {
      teamId,
      room,
      improveOption,
      improveOptionId,
      improveStatus
    });
  }

  updateImproveOptionbyReselect( 
    teamId,     
    room,
    improveOption,
    improveOptionId,
    improveStatus
    ){
      console.log("1.-Enviamos a consumir socket")
    this.socket.emit('improveOptionbyReselect', {
      teamId,
      room,
      improveOption,
      improveOptionId,
      improveStatus
    });
  }

  updateImprovementApproved( 
    teamId,     
    room,
    improveOptionId,
    improveStatus,
    IdImprovementPrevious
    ){
    this.socket.emit('aceptDeclineImprovement', {
      teamId,
      room,
      improveOptionId,
      improveStatus,
      IdImprovementPrevious
    });
  }
  reselectImprovement(teamId,     
    room){
    this.socket.emit('reselectImprovement', {
      teamId,
      room
    });
  }
  updateImprovementOptionStatus( 
    teamId,     
    room,
    improveOption,
    status
    ){
    this.socket.emit('improveOptionStatus', {
      teamId,
      room,
      improveOption,
      status
    });
  }

  askFacilitatorImprovementDeniedByTeam(
    teamId, 
    room,
    improveOption,
  ){
    this.socket.emit('askFacilitatorImprovementDeniedByTeam', {
      teamId,
      room,
      improveOption
    });
  }

  declineAceptImprovementByTeam(
    teamId,
    improveOption,
    acepted
  ){
    this.socket.emit('declineAceptImprovement', {
      teamId,
      improveOption,
      acepted
    });
  }

  improveOptionStatus$(      
    improveStatus
    ){
    this.$improvement.next({ data:{improveStatus}, status: 'improveStatus' });
  }
}
