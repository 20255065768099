import { NgLocalization } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { io } from 'socket.io-client';
import { _URL_BALANCE, _URL_BOARD, _URL_IncomeStatement } from '../config/config';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SocketioService {
  private $coin: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  _coin: Observable<any> = this.$coin.asObservable();
  private $config: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  _config: Observable<any> = this.$config.asObservable();
  private $openWs: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  _openWs: Observable<any> = this.$openWs.asObservable();
  private isMyCall = false;
  socket = io(environment.SOCKET_ENDPOINT, {
    query: {
      // userId: userId,
    },
  });

  constructor(
    private http: HttpClient
  ) {}

  setupSocketConnection(userId, roomToJoin, roomWorkShopToJoin) {
    
    console.log("Room a unirse TEAM: ", roomToJoin);
    this.socket.emit('join', {
      room: roomToJoin,
      userId: userId,
    });

    this.socket.emit('joinchat', {
      room: roomToJoin,
      userId: userId,
    });

    console.log("Room a unirse WORKSHOP: ", roomWorkShopToJoin);
    this.socket.emit('joinWorkShop', {
      room: roomWorkShopToJoin,
      teamId: userId,
    });

    this.socket.on('connectedSuccesfully', (data) => {
      console.log("TEAM",data);
    });

    this.socket.on('connectedSuccesfullyWorkShop', (data) => {
      console.log("WORKSHOP",data);
    });

    this.socket.on('improveOptionServer', (data) => {
      console.log("improveOptionServer",data);
      //this.$improvement.next({ data, status: 'improveOptionServer' });
    });

    /*this.socket.on('timerServer', (data) => {
      console.log("TIMER BOARD",data);
    });*/

    this.socket.on('CoinDragginByOther', (data) => {
      if(!this.isMyCall){
        this.$coin.next({ data, status: 'dragging' });
      }
      this.isMyCall = false;
    });
    this.socket.on('CoinDroppedByOther', (data) => {
      if(!this.isMyCall){
        this.$coin.next({ data, status: 'dropped' });
      }
      this.isMyCall = false;
    });
    this.socket.on('createLoanCoin', (data) =>{
      this.$coin.next({ data, status: 'createLoanCoin' })
    });
    this.socket.on('pieceDisabledByOther', (data) => {
      if(!this.isMyCall){
        this.$coin.next({ data, status: 'pieceDisabled' });
      } 
      this.isMyCall = false;
    });
    this.socket.on('controlDisabledByOther', (data) => {
      console.log("recibimos respuesta del socket controlDisabledByOther");
      //if(!this.isMyCall){
        this.$coin.next({ data, status: 'controlDisabled' });
      //} 
     // this.isMyCall = false;
    });
    this.socket.on('controlDisabledByOtherToFac', (data) => {
      console.log("controlDisabledByOtherToFac");
      //if(!this.isMyCall){
        this.$config.next({ data, status: 'controlDisabledToFac' });
      //} 
     // this.isMyCall = false;
    });
    
    this.socket.on('changeStatus', (data) => {
        this.$coin.next({ data, status: 'changeStatus' });
    });
    this.socket.on('sbsStepCompletedServer', (data)=>{
      console.log("entra a sbsStepCompleted")
      this.$coin.next({ data, status: 'sbsStepCompletedServer' });
    });
    this.socket.on('editedTeams', (data) => {
      this.$coin.next({ data, status: 'editedTeams' });
    });
    this.socket.on('editedTeamsByFac', (data) => {
      this.$coin.next({ data, status: 'editedTeamsByFac' });
    });
    this.socket.on('blockTeam', (data) => {
      this.$coin.next({ data, status: 'blockTeam' });
    });
    this.socket.on('blockAllTeams', (data) => {
      this.$coin.next({ data, status: 'blockAllTeams' });
    });
    this.socket.on('splitCoinServer', (data) => {
      this.$coin.next({ data, status: 'splitCoinServer' });
      this.isMyCall = false;
    });
    this.socket.on('setListServer', (data) => {
      // if(!this.isMyCall){
        this.$coin.next({ data, status: 'setListServer' });
      // } 
      this.isMyCall = false;
    });
    this.socket.on('setListOrderDeliverServer', (data) => {
      // if(!this.isMyCall){
        this.$coin.next({ data, status: 'setListOrderDeliverServer' });
      // } 
      this.isMyCall = false;
    });
    this.socket.on('borrowFromBankServer', (data) => {
      this.$coin.next({ data, status: 'borrowFromBankServer' });
      this.isMyCall = false;
    });
    this.socket.on('createCoinServer', (data) => {
      this.$coin.next({ data, status: 'createCoinServer' });
      this.isMyCall = false;
    });
    this.socket.on('deleteCoinServer', (data) => {
      this.$coin.next({ data, status: 'deleteCoinServer' });
      this.isMyCall = false;
    });
    this.socket.on('deleteOrderServer', (data) => {
      this.$coin.next({ data, status: 'deleteOrderServer' });
    });
    this.socket.on('changeBoardModeServer', (data) => {
      console.log(data);
      this.$config.next({ data, status: 'changeBoardModeServer' });
    });
    this.socket.on('payLoanServer', (data) => {
      console.log(data);
      this.$coin.next({ data, status: 'payLoanServer' });
    });
    this.socket.on('payGrossProfitServer', (data) => {
      console.log(data);
      this.$coin.next({ data, status: 'payGrossProfitServer' });
    });
    this.socket.on('setBlnControlModeOpenServer', (data) => {
      console.log(data);
      this.$coin.next({ data, status: 'setBlnControlModeOpenServer' });
    });
    this.socket.on('setBlnControlModeOpenArrayServer', (data) => {
      console.log(data);
      this.$coin.next({ data, status: 'setBlnControlModeOpenArrayServer' });
    });
    this.socket.on('updateCoinServer', (data) => {
      console.log(data);
      this.$coin.next({ data, status: 'updateCoinServer' });
    });
    this.socket.on('updateCheckListServer', (data) => {
      console.log(data);
      this.$coin.next({ data, status: 'updateCheckListServer' });
    });
    this.socket.on('deliverOrderServer', (data) => {
      console.log(data);
      this.$coin.next({ data, status: 'deliverOrderServer' });
    });
    this.socket.on('askImprovementServer', (data) => {
      console.log(data);
      this.$coin.next({ data, status: 'askImprovementServer' });
    });
    this.socket.on('implementExpansionWithRawMaterialServer', (data) => {
      console.log(data);
      this.$coin.next({ data, status: 'implementExpansionWithRawMaterialServer' });
    });
    this.socket.on('implementExpansionWithEfficiencyServer', (data) => {
      console.log(data);
      this.$coin.next({ data, status: 'implementExpansionWithEfficiencyServer' });
    });
    this.socket.on('disableOptionModeServer', (data) => {
      console.log(data);
      this.$config.next({ data, status: 'disableOptionModeServer' });
    });
    this.socket.on('setUpTeamByIDServer', (data) => {
      console.log(data);
      this.$config.next({ data, status: 'setUpTeamByIDServer' });
    });
    this.socket.on('assignDefaultTeamNames', (data) => {
      this.$config.next({ data, status: 'assignDefaultTeamNames' });
    });
    this.socket.on('liabilitiesReceivablesUpdates', (data) => {
      this.$config.next({ data, status: 'liabilitiesReceivablesUpdates' });
    });
    this.socket.on('disconnect', function () {
      console.log("SOCKET DESCONECTADO........");
      environment.socketStatus = false;
      //location.reload();
      this.$coin.next({ jsnTmp: true, status: 'socketdisconected' });
    });
    this.socket.on('changeTeamName', (data) => {
      this.$config.next({ data, status: 'changeTeamName' });
    });

    this.socket.on('respuestaReloadDemo', (data) => {
      console.log("Datos recibidos del evento nuevo", data);
      this.$config.next({ data, status: 'reloadDemo' });
    });

    this.socket.on('respuestaReloadFinishDemo', (data) => {
      console.log("Datos recibidos del evento nuevo", data);
      this.$config.next({ data, status: 'reloadFinishDemo' });
    });

    this.socket.on('respuestaReloadFinishNormal', (data) => {
      console.log("Datos recibidos del evento nuevo", data);
      this.$config.next({ data, status: 'reloadFinishNormal' });
    });

    this.socket.on('Change openTimeZone', (data) => {
      console.log('Cambio en openWS', data);
      this.$openWs.next({ data, status: 'Change openTimeZone' });
    });
    this.socket.on('finishBalanceServer', (data) => {
      console.log('Equipo terminó balance', data);
      this.$openWs.next({ data, status: 'finishBalanceServer' });
    });
    this.socket.on('updatePendingProject',(data) => {
      this.$coin.next({ data, status: 'updatePendingProject' });
    })
    this.socket.on('usersessionServer', (data) => {
      console.log(data);
      this.$openWs.next({ data, status: 'usersessionServer' });
    });
    this.socket.on('blockTeamBoardServer', (data) => {
      console.log(data);
      this.$openWs.next({ data, status: 'blockTeamBoardServer' });
    });
    this.socket.on('updateFacilitatorMonth', (data) => {
      console.log(data);
      this.$openWs.next({ data, status: 'updateFacilitatorMonth' });
    });
    
  }

  dragACoin(list, jsonCoinDescription, index, room, userName, userId) {
    try{
        this.socket.emit('coinDraggingBySelf', {
        container: list,
        data: jsonCoinDescription,
        index,
        room,
        userName,
        userId
      });
      this.isMyCall = true;
    }
    catch{
      console.log("Something went wrong when dragging notification sent to Socket")  
    }
  }

  dropACoin(previousContainer, container, jsonCoinDescription, index, room, userId, userName, teamId, status) {
    try{
      this.socket.emit('coinDroppedBySelf', {
        previousContainer,
        container,
        data: jsonCoinDescription,
        index,
        room,
        userName,
        teamId,
        status,
        userId
      });
      this.isMyCall = true;
    }
    catch{
      console.log("Something went wrong when drop notification sent to Socket")  
    }
  }

  disablePiece(container, index, room, userId, userName, status, pieceData){
    try{
      this.socket.emit('disablePiece', {
        container,
        index,
        room,
        userId,
        userName,
        statusPiece: status,
        pieceData
      });
      this.isMyCall = true;
    }
    catch {
      console.log("Something went wrong when disable notification sent to Socket")  
    }
  }

  updateStatus( room, teamId, status, month){
    try{
      this.socket.emit('updateTeamStatus', {
        room,
        status,
        teamId,
        month
      });
      this.isMyCall = true;
    }
    catch {
      console.log("Something went wrong when update notification sent to Socket")  
    }
  }
  blockTeamBoard(room, teamId){
    try{
      this.socket.emit('blockTeamBoard', {
        room,
        teamId,
      });
      this.isMyCall = true;
    }
    catch {
      console.log("Something went wrong when update notification sent to Socket")  
    }
  }
  blockTeam(teamId, status){
    try{
      this.socket.emit('blockTeam', {
        status,
        teamId,
      });
      this.isMyCall = true;
    }
    catch {
      console.log("Something went wrong when update notification sent to Socket")  
    }
  }
  blockAllTeams(workShopId, status){
    try{
      this.socket.emit('blockAllTeams', {
        workShopId,
        status,
      });
      this.isMyCall = true;
    }
    catch {
      console.log("Something went wrong when update notification sent to Socket")  
    }
  }
  updateStatusAPI( room = '0', teamId, status, month): Observable<any>{
    try{
      const httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      const params = JSON.stringify({ room,
        status,
        teamId,
        month});
      return this.http.post(_URL_BOARD + '/updateTeamStatus', params, {
        headers: httpHeaders,
      });
    }
    catch {
      console.log("Something went wrong when update notification sent to Socket")  
    }
  }
  splitCoin(newList: Array<any>,idList, room, teamId){
    console.log('entra', newList, idList, room, teamId)
    this.socket.emit('splitCoin', {
      room,
      teamId,
      newList,
      idList
    });
    this.isMyCall = true;
  }

  setList(newList: Array<any>, idList, oldIdList, oldItem, room, teamId, ){
    console.log("mandar");
    console.log(newList, idList, oldIdList, oldItem, room, teamId);
    this.socket.emit('setList', {
      room,
      teamId,
      newList,
      idList,
      oldIdList,
      oldItem
    });
    this.isMyCall = true;
  }

  setStacksOrder(newList: Array<any>, idList, oldIdList, oldItem, room, teamId, orderId){
    console.log("mandar");
    console.log(newList, idList, oldIdList, oldItem, room, teamId);
    this.socket.emit('setListOrderDeliver', {
      room,
      teamId,
      newList,
      idList,
      oldIdList,
      oldItem,
      orderId
    });
    this.isMyCall = true;
  }

  borrowFromBank(oldIdList, idList, item, room, teamId, month, idBlns, values ){
    console.log("mandar");
    console.log(oldIdList, idList, room, teamId);
    this.socket.emit('borrowFromBank', {
      room,
      teamId,
      idList,
      oldIdList,
      item, 
      month,
      idBlns,
      values
    });
    this.isMyCall = true;
  }

  createCoin(idList, item, room, teamId){
    this.socket.emit('createCoin', {
      room,
      teamId,
      idList,
      item
    });
    this.isMyCall = true;
  }

  deleteCoin(idList, item, room, teamId){
    this.socket.emit('deleteCoin', {
      room,
      teamId,
      idList,
      item
    });
    this.isMyCall = true;
  }

  deleteOrder(item, room, teamId){
    this.socket.emit('deleteOrder', {
      room,
      teamId,
      item
    });
  }

  updateCoin(idList, item, room, teamId){
    this.socket.emit('updateCoin', {
      room,
      teamId,
      idList,
      item
    });
    this.isMyCall = true;
  }
  updateMode(teamId, isModeOpen) {
    console.log(teamId, isModeOpen, "board service")
    this.socket.emit('updateBoardMode', {
      isModeOpen,
      teamId,
    });
    this.isMyCall = true;
  }

  payLoan(idList, item, room, teamId){
    this.socket.emit('payLoan', {
      idList, 
      item,
      room,
      teamId
    });
  }

  payGrossProfit(idListsToPay, listValoresToPay, item, room, teamId, userId){
    this.socket.emit('payGrossProfit', {
      idListsToPay,
      listValoresToPay,
      item,
      room,
      teamId,
      userId
    })
  }

  setBlnControlModeOpen(idBln, value, room, teamId){
    this.socket.emit('setBlnControlModeOpen', {
      idBln,
      value,
      room,
      teamId
    })
  }

  setBlnControlModeOpenArray(idBlns, value, room, teamId){
    this.socket.emit( 'setBlnControlModeOpenArray', {
      idBlns,
      value,
      room,
      teamId
    })
  }

  updateCheckList(checkList: string,  room, teamId){
    this.socket.emit('updateCheckList', {
      checkList,
      room,
      teamId
    });
    this.updateTeamsChecklist(teamId).subscribe(resp=>{
      console.log("updateTeamsChecklist", resp);
    })
  }
  updateTeamsChecklist(teamId: number): Observable<any> {
    return this.http.get(_URL_BALANCE + '/updateTeamsChecklist/'+teamId);
  }
  deliverOrder(jsonOrder, piecesSold, room, teamId){
    this.socket.emit('deliverOrder', {
      jsonOrder,
      piecesSold,
      room,
      teamId
    });
  }

  askImprovement(room, teamId){
    this.socket.emit('askImprovement', {
      room,
      teamId
    });
  }
  updateSuppliers(room, teamId){
    this.socket.emit('updateSuppliers', {
      room,
      teamId
    });
  }
  changeTeamName(room, name, teamId){
    this.socket.emit('changeTeamName', {
      room,
      name,
      teamId
    });
  }

  implementExpansionWithRawMaterial(room, teamId, machine){
    this.socket.emit('implementExpansionWithRawMaterial', {
      room,
      teamId,
      machine
    });
  }

  implementExpansionWithEfficiency(room, teamId, machine){
    this.socket.emit('implementExpansionWithEfficiency', {
      room,
      teamId,
      machine
    });
  }

  terminarConexion() {
    this.socket.close();
  }

  
  closeListeners(room: string) {
    this.socket.emit("leave",{
      room
    });
    this.socket.off();
  }

  disableControl(room, userId, name){
    try{
      this.socket.emit('disableControl', {
        room,
        userId,
        name
      });
     // this.isMyCall = true;
    }
    catch {
      console.log("Something went wrong when disable notification sent to Socket")  
    }
  }

  magicBtnDemo(workShopId, teamId, businessModel = "Mfg EBIT"){
    console.log('MANDAMOS', businessModel);
    try {
      this.socket.emit('reloadDemo',{
        workShopId, teamId, businessModel
      });
    } catch {
      console.log('Could not updete teams boards');
    }
  }

  magicBtnFinishDemo(workShopId, teamId){
    try {
      this.socket.emit('reloadFinishDemo',{
        workShopId, teamId
      });
    } catch {
      console.log('Could not updete teams boards');
    }
  }

  magicBtnFinishNormal(workShopId, teamId, businessModel = "Mfg EBIT"){
    try {
      this.socket.emit('reloadFinishNormal',{
        workShopId, teamId, businessModel
      });
    } catch {
      console.log('Could not updete teams boards');
    }
  }

  finishBalance(workShopId, teamId, month){
    try {
      this.socket.emit('finishBalance',{
        workShopId, teamId, month
      });
    } catch {
      console.log('Could not updete teams boards');
    }
  }
}
