import { Component, Inject, OnInit } from '@angular/core';
import { TeamService } from 'src/app/services/team/team.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { LanguageService } from 'src/app/services/language/language.service';
@Component({
  selector: 'app-record-income-and-balance',
  templateUrl: './record-income-and-balance.component.html',
  styleUrls: ['./record-income-and-balance.component.scss']
})
export class RecordIncomeAndBalanceComponent implements OnInit {
  arrTextLabels = [];
  arrAllTeams = [];
  count : number;
  workshopID = 0;
  constructor(private teamService: TeamService,
    private activatedRoute: ActivatedRoute,
    public matDialogRef: MatDialogRef<RecordIncomeAndBalanceComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private spinner: NgxSpinnerService,
    private languageservice: LanguageService
    ) {
      console.log("this._data", this._data);
      this.count = this._data['cycles'];
      //this.count = 6;
      console.log(this.count)
     }

  async ngOnInit(): Promise<void> {
  
        this.workshopID = this._data.workshopId;
        this.count = parseInt(this._data['cycles']);
        console.log(this.count);
        this.arrTextLabels = (await this.languageservice.getValidationLabels(parseInt(this.workshopID.toString())).toPromise()).textLabels;

      
    
      this.fnGetAllTeamsAndMembers();
  }
  getSingleTextLabel(labelNumber: string): string {
    const tmp = this.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    return tmp ? tmp.Label : '';
  }
  fnGetAllTeamsAndMembers(){
      this.spinner.show();
      this.teamService.getAllTeamsAndMembersById(this.workshopID).subscribe(resp=>{
      console.log("fnGetAllTeamsAndMembers",resp['teams']);
      this.arrAllTeams = resp['teams'];
      console.log(this.arrAllTeams,resp['teams']);
      /*this.matDialogRef.updateSize('854px', this.arrAllTeams.length === 6 ? '340px' : this.arrAllTeams.length === 5 ? '320px' : '300px');*/
      this.spinner.hide();
    })
  }

  counter(i: number) {
    return new Array(i);
  }

  fnResultado(step:number, mes){
    let label = "";
    const doneLabel = 'DONE';
    switch(mes){
      case 1:
        if(step >= mes){
          label = doneLabel;
        }
        else{
          label ='';
        }
        break;
      case 2:
        if(step >= mes){
          label = doneLabel;
        }
        else{
          label = '';
        }
        break;
      case 3:
        if(step >= mes){
          label = doneLabel;
        }
        else{
          label = '';
        }
        break;
      case 4:
        if(step >= mes){
          label = doneLabel;
        }
        else{
          label = '';
        }
        break;
      case 5:
        if(step >= mes){
          label = doneLabel;
        }
        else{
          label = '';
        }
        break;
      case 6:
        if(step >= mes){
          label = doneLabel;
        }
        else{
          label = '';
        }
        break;
    }
    return label;
  }

  fnResultado2(step:number, mes){
    let blnicon = false;
    const doneLabel = true;
    switch(mes){
      case 1:
        if(step >= mes){
          blnicon = doneLabel;
        }
        else{
          blnicon = false;
        }
        break;
      case 2:
        if(step >= mes){
          blnicon = doneLabel;
        }
        else{
          blnicon = false;
        }
        break;
      case 3:
        if(step >= mes){
          blnicon = doneLabel;
        }
        else{
          blnicon = false;
        }
        break;
      case 4:
        if(step >= mes){
          blnicon = doneLabel;
        }
        else{
          blnicon = false;
        }
        break;
      case 5:
          if(step >= mes){
            blnicon = doneLabel;
          }
          else{
            blnicon = false;
          }
          break;
        case 6:
          if(step >= mes){
            blnicon = doneLabel;
          }
          else{
            blnicon = false;
          }
          break;
    }
    return blnicon;
  }
  /*getSingleTextLabel(labelNumber: string): string {
    const tmp = this._data.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    return tmp ? tmp.Label : '';
  }*/
  close(){
    this.matDialogRef.close()
  }
}
