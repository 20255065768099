<div> 
  <p class="style1 calibriFont">{{getSingleTextLabel('10')}}</p>
</div>
  <mat-tab-group #tabGroup (selectedTabChange)="onTabClick($event)">
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="style3 calibriFont" style="color: black;">{{getSingleTextLabel('5014')}}</span>
      </ng-template>
      <ng-template matTabContent>
        <app-changeboard [userTeamID] = "userTeam" *ngIf="opcSelect == 0" ></app-changeboard>
      </ng-template>
    </mat-tab>
    <mat-tab [disabled]="!control">
      <ng-template mat-tab-label>
        <span class="style3 calibriFont" style="color: black;">{{getSingleTextLabel('5015')}}</span>
      </ng-template>
      
      <ng-template matTabContent>
        <label class="FinTitle calibriFont style3">{{FinStatMessage}}</label>

        <div class="container">
          <div class="row">
            <div class="col-7">
              <mat-radio-group 
              aria-labelledby="example-radio-group-label"
              class="example-radio-group"
              >
              <mat-radio-button  name="fieldName" ngDefaultControl [(ngModel)]="selectedteamR" class="example-radio-button style2a calibriFont" *ngFor="let team of lstTeamsR " [value]="team.TeamId" 
              (change)="radioChange($event)">
                {{team.Avatar}}
              </mat-radio-button><br>
            </mat-radio-group><!--
            <button (click)="fnchargedata()">Cargar</button>-->
            </div>
    
            <div class="col-3" >
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>{{selectManage[0]}}</mat-label>
                <mat-select #Options name="Options">
                  <mat-option>{{getSingleTextLabel('5017')}}</mat-option>
                  <mat-option value="IS">
                    {{selectManage[1]}}
                  </mat-option>
                  <mat-option value = "BS">
                    {{selectManage[2]}}
                </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

      </div>

        <div class="incomeBorders">
          <app-incomestatementreport *ngIf="opcSelect == 1 && Options.value=='IS'"></app-incomestatementreport>
        </div>
        <div class="balanceBorders">
          <app-balancesheetreport *ngIf="opcSelect == 1 && Options.value=='BS'"></app-balancesheetreport>
        </div>
      </ng-template>
    </mat-tab>
    
    <mat-tab [disabled]="!control">
      <ng-template mat-tab-label>
        <span class="style3 calibriFont" style="color: black;">{{getSingleTextLabel('5011')}}</span>
      </ng-template>
      <ng-template matTabContent>
        <app-result-view *ngIf="opcSelect == 2"></app-result-view>
      </ng-template>
    </mat-tab>
    <!-- <mat-tab label="result ratios">
      <ng-template matTabContent>
        <app-result-ratios></app-result-ratios>
      </ng-template>
    </mat-tab> -->
  </mat-tab-group>
  
  <mat-dialog-actions align="center">
    <button type="button" class="btn btn-secondary" id="btncloseHide" (click)="matDialogRef.close()">{{getSingleTextLabel('135')}}</button> 
 </mat-dialog-actions> 
 

  <!--<label>ESTE ES EL COMPONENTE DE REPORTES</label>

<ng-container *ngIf="opcSelect == 1" style="text-align: center !important;align-content: center !important;">
    <app-balancesheetreport></app-balancesheetreport><br>
    <app-incomestatementreport></app-incomestatementreport>
</ng-container>
-->