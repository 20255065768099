<div style="width: 100%;align-content: flex-end;text-align: end;" *ngIf="blnFacilitador">
  <img src="../../../assets/goldenCup.png" height="80" width="60" cdkDrag>
  <img src="../../../assets/silverCup.png" height="70" width="54" cdkDrag>
</div>
<table class="table">

  <thead>
    <tr>
      <ng-container *ngFor="let jsnTeam of lstTeams; let i = index;">
          <th scope="col" [ngStyle]="{'background-color': jsnTeam.Color, 'color': jsnTeam.Color === '#ffe998' || jsnTeam.Color === '#84eeeb' || jsnTeam.Color === '#bec3ff' || jsnTeam.Color  == '#e4e4ec' || jsnTeam.Color  == '#7faeff' ? 'black' : 'white'}" class="text-center style4SinBackground calibriFont">{{jsnTeam.Avatar}}</th>

      </ng-container>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of data; let j = index;">
      <th scope="row" class="style5b border calibriFont">{{item.strLabel}}</th>
      <ng-container *ngFor="let jsnTeam of lstTeamsData; let i = index;">
        <td id="cell{{j}}{{i}}" [ngStyle]="{'color': jsnTeam[item.key] == '- ' || jsnTeam['colorColumn'] == 'red'  ? 'red' : 'black'}" (click)="fnClickSelect(j,i)" [style.background-color]="backGround" class="style5b border calibriFont">{{jsnTeam[item.key]}}<ng-container *ngIf="item.key == 'profitability' || item.key == 'returnOnAssets'">%
          </ng-container>
        </td>
      </ng-container>
    </tr>
    <tr *ngFor="let item of lstTextArea; let i=index">
      <th scope="row">
        <mat-form-field class="w-100" appearance="fill">
          <textarea class="textAreaFormField" [disabled]="!blnFacilitador" (change)="changeTextArea()"
            [id]="item[0].idtextAreaReports" name="notes" matInput rows="3"
            [(ngModel)]="item[0].text">{{item[0].text}}</textarea>
        </mat-form-field>
      </th>
      <ng-container *ngFor="let textArea of item; let i=index">
        <ng-container *ngIf="i != 0">
          <td>
            <mat-form-field appearance="fill">
              <textarea class="textAreaFormField" [disabled]="!blnFacilitador" (change)="changeTextArea()"
                [id]="textArea.idtextAreaReports" name="notes" matInput rows="3" cols="17"
                [(ngModel)]="textArea.text">{{textArea.text}}</textarea>
            </mat-form-field>
          </td>
        </ng-container>
      </ng-container>
    </tr>

  </tbody>
</table>