import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { _URL_USERS } from './../../config/config';

@Injectable({
  providedIn: 'root',
})
export class UsuarioService {
  constructor(private http: HttpClient) {}

  getAllUsers(): Observable<any> {
    return this.http.get(_URL_USERS + '/getAllUsers');
  }

  deleteUserById(id): Observable<any> {
    return this.http.delete(`${_URL_USERS}/deleteUserById/${id}`);
  }

  createUser(jsonUser): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify(jsonUser);
    return this.http.post(_URL_USERS + '/createUser', params, {
      headers: httpHeaders,
    });
  }

  getUserById(id) {
    return this.http.get(_URL_USERS + '/getUserById/' + id);
  }

  getUserInfo(IdUser) {
    return this.http.get(_URL_USERS + '/getUserInfo/' + IdUser);
  }

  updateUserById(jsonUser) {
    console.log('UserUpdate', jsonUser);
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify(jsonUser);
    return this.http.put(_URL_USERS + '/updateUserById', params, {
      headers: httpHeaders,
    });
  }

  blockUserByID(jsonUser) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify(jsonUser);
    return this.http.post(_URL_USERS + '/blockUser', params, {
      headers: httpHeaders,
    });
  }

  createUsersByBulkLoad(
    workshopID,
    arrayOfTeams: any[],
    isResponseErrorEmailAlreadyRegister: boolean,
    teamId,
    cycles,
    BoardMode
  ) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const jsonTeamMembers = {
      workshopID,
      arrayOfTeams,
      isResponseErrorEmailAlreadyRegister,
      teamId,
      cycles,
      BoardMode
    };
    const params = JSON.stringify(jsonTeamMembers);
    return this.http.post(
      _URL_USERS + '/createTeamAndTeamMemebersByArray',
      params,
      { headers: httpHeaders }
    );
  }

  createUsersByBulkLoadDemo(
    workshopID,
    arrayOfTeams: any[],
    isResponseErrorEmailAlreadyRegister: boolean,
    teamId,
    cycles,
    BoardMode
  ) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const jsonTeamMembers = {
      workshopID,
      arrayOfTeams,
      isResponseErrorEmailAlreadyRegister,
      teamId,
      cycles,
      BoardMode
    };
    const params = JSON.stringify(jsonTeamMembers);
    return this.http.post(
      _URL_USERS + '/createTeamAndTeamMemebersByArrayDemoMode',
      params,
      { headers: httpHeaders }
    );
  }
  updateUsersByBulkLoad(
    workshopID,
    arrayOfTeams: any[],
    isResponseErrorEmailAlreadyRegister: boolean,
    teamId,
    cycles,
    BoardMode,
    blnUpdBoardMode: boolean
  ) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const jsonTeamMembers = {
      workshopID,
      arrayOfTeams,
      isResponseErrorEmailAlreadyRegister,
      teamId,
      cycles,
      BoardMode,
      blnUpdBoardMode
    };
    const params = JSON.stringify(jsonTeamMembers);
    return this.http.post(
      _URL_USERS + '/updateTeamAndTeamMemebersByArray',
      params,
      { headers: httpHeaders }
    );
  }
  
  updateTeamAndTeamMemebersByArrayFacilitator(
    workshopID,
    arrayOfTeams: any[],
    isResponseErrorEmailAlreadyRegister: boolean,
    teamId,
    cycles,
    BoardMode,
    blnUpdBoardMode: boolean
  ) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const jsonTeamMembers = {
      workshopID,
      arrayOfTeams,
      isResponseErrorEmailAlreadyRegister,
      teamId,
      cycles,
      BoardMode,
      blnUpdBoardMode
    };
    const params = JSON.stringify(jsonTeamMembers);
    return this.http.post(
      _URL_USERS + '/updateTeamAndTeamMemebersByArrayFacilitator',
      params,
      { headers: httpHeaders }
    );
  }
  createUserObserver(name, lastname, email, type) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
      firstName: name,
      lastName: lastname,
      email,
      type,
    });
    return this.http.post(_URL_USERS + '/createUserObserver', params, {
      headers: httpHeaders,
    });
  }

  getAllObserverByWorkshopID(workshopID) {
    return this.http.get(
      _URL_USERS + '/getAllObserversByWorkshopID/' + workshopID
    );
  }

  getAllContactsByWorkshopID(workshopID) {
    return this.http.get(
      _URL_USERS + '/getAllContactsByWorkshopID/' + workshopID
    );
  }

  createObservers(
    workshopID,
    arrayOfContacts: any[],
    isResponseErrorEmailAlreadyRegister: boolean,
    teamId: number
  ): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    console.log(arrayOfContacts);
    const jsonDistributor = {
      workshopID,
      arrayOfContacts,
      isResponseErrorEmailAlreadyRegister,
      teamId,
    };
    const params = JSON.stringify(jsonDistributor);
    console.log(params);
    return this.http.post(_URL_USERS + '/createUsersObservers', params, {
      headers: httpHeaders,
    });
  }

  createregistryWS(
    idOfWorkshop,
    client,
    createupdate,
    forecastExc
    ): Observable<any> {
      const httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      const jsonSend = {
        idOfWorkshop,
        client,
        createupdate,
        forecastExc
      };
      const params = JSON.stringify(jsonSend);
      console.log(params);
      return this.http.post(_URL_USERS + '/createRegistryWS', params, {
        headers: httpHeaders,
      });
    }


  updateObservers(
    workshopId,
    lstOfObservers: any[],
    lstOfNewObservers: any[],
    teamId
  ): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
      workshopId,
      lstOfObservers,
      lstOfNewObservers,
      teamId,
    });
    console.log(params);
    return this.http.put(_URL_USERS + '/editObservers', params, {
      headers: httpHeaders,
    });
  }

  getAllTeamMembersByWorkshopID(workshopID): Observable<any> {
    return this.http.get(
      _URL_USERS + '/getAllTeamMembersByWorkshopId/' + workshopID
    );
  }
  updateMemberRolByID(OLDuserId,userId): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
      OLDuserId,
      userId
    });
    return this.http.post(_URL_USERS + '/updateMemberRolByID', params, {
      headers: httpHeaders,
    });
  }

  updateTeamMembers(
    workshopId,
    lstOfTeamMembers: any[],
    lstOfNewTeamMembers: any[],
    teamId
  ): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
      workshopId,
      lstOfTeamMembers,
      lstOfNewTeamMembers,
      teamId,
    });
    return this.http.put(_URL_USERS + '/editTeamMembers', params, {
      headers: httpHeaders,
    });
  }

  updateTeamMember(
    teamMember: any
  ): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify(teamMember);
    return this.http.put(_URL_USERS + '/updateTeamMemeber', params, {
      headers: httpHeaders,
    });
  }

  deleteMembersandTeamsbyWorkshopId(id): Observable<any> {
    return this.http.delete(
      `${_URL_USERS}/deleteTeamsAndMembersbyWorkShop/${id}`
    );
  }


validateFacilitatorPasswordFromBoard(userId,pass): any {
  const httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  const params = JSON.stringify({
    pass: pass, userId: userId
  });
  return this.http.post(_URL_USERS + '/validateFacilitatorBoard', params, {
    headers: httpHeaders,
  });
}


}