import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit, Input, ViewChild} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { BoardService } from 'src/app/services/board/board.service';
import { SocketioService } from 'src/app/services/socketio.service';
import { _IP } from '../../config/config';
import { TeamService } from 'src/app/services/team/team.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { WorkshopService } from 'src/app/services/workshop/workshop.service';
import Swal from 'sweetalert2';

class TeamMember{
  Cycles: number;
  Email: string;
  FirstName: string;
  IsFacilitator: boolean;
  LastName: string;
  TeamId: number;
  UserID: number;
  completedname: string;
  invalid: number;
  type: number;
  

  decode(json:{
    Cycles: number,
    Email: string,
    FirstName: string,
    IsFacilitator: boolean,
    LastName: string,
    TeamId: number,
    UserID: number,
    completedname: string,
    invalid: number,
    type: number,}
  ){
      this.Cycles = json.Cycles;
      this.Email = json.Email;
      this.FirstName = json.FirstName;
      this.IsFacilitator = json.IsFacilitator;
      this.LastName = json.LastName;
      this.TeamId = json.TeamId;
      this.UserID = json.UserID;
      this.completedname = json.completedname;
      this.invalid = json.invalid;
      this.type = json.type;
    }
}

@Component({
  selector: 'app-dialog-edit-teams',
  templateUrl: './dialog-edit-teams.component.html',
  styleUrls: ['./dialog-edit-teams.component.scss']
})

export class DialogEditTeamsComponent implements OnInit {
  indexTeam = -1;
  hasChanges = false;
  lstOfTeamsAux = [];
  indexTeamMember = -1;
  teemMemberForm: FormGroup;
  isEditContact = -1;
  dataSourceTable = new MatTableDataSource([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  titulos: string[] = ['firstName', 'lastName', 'jobTitle', 'email', 'teamNumber', 'actions'];
  pageSize = 20;
  pageSizeOptions: number[] = [1, 5, 10, 25, 100];
  lstGlobalTeamMemebersAux = [];
  isEdited = false;
  blnTeamDeleted = false;
  auxRoles = []
  indiceLstOfTeams = [];
  lstFacs = [];
  lstContacts = [];
  indicesLstOdAllMembers = -1
  facCode: any = 0;
  linksDeUsuarios: { email: string, link: string }[] = [];
  // room:string;
  // canDeleteOrAdd:boolean = false;
  blnBlockTeams:boolean = false;
  lstOfTeams: Array<{TeamId: number, Avatar:string, TeamMembers: Array<any>, teamCode: number}> = [];
  // lstGlobalTeamMemebers: Array<TeamMember> = [];
  // workshopId:number
  // Cycles:number
  @Input() workshopId: number;
  @Input() lstGlobalTeamMemebers: Array<TeamMember> = [];
  @Input() Cycles : number;
  @Input() canDeleteOrAdd:boolean;
  @Input() room : string;
  @Input() arrTextLabels : Array<any>;
  @Input() boardMode : string;

  

  
  constructor(@Inject(MAT_DIALOG_DATA) private _data: any,
  private spinner: NgxSpinnerService,
  private teamServise: TeamService,
  private userService: UsuarioService,
  private boardService: BoardService,
  public matDialogRef: MatDialogRef<DialogEditTeamsComponent>,
  private workshopService: WorkshopService,
  private socketioService: SocketioService,
  private usuarioService: UsuarioService


  ) { }

  ngOnInit(): void {
    console.log(this._data);
    console.log(this.boardMode);
    // this.workshopId = this._data['workShopId'];
    // this.Cycles = this._data['Cycles'];
    // this.canDeleteOrAdd = this._data['canDeleteOrAdd'];
    // this.room = this._data['room'];
    // this.lstGlobalTeamMemebers = this._data['lstUsers'];
    this.fnCrearForm('', '', '', '',null);
    this.cargarTeams();
    this.getAllteamMembers();
    this.cargarTeamMembersParaRoles();
    this.getFacs();
  }
  getSingleTextLabel(labelNumber: string): string {
    const tmp = this.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    return tmp ? tmp.Label : '';
  }
  cargarTeamMembersParaRoles(){
    console.log(this.auxRoles);
    this.usuarioService
    .getAllTeamMembersByWorkshopID(this.workshopId)
    .subscribe((responseTeam) => { 
      this.auxRoles = responseTeam.teamMembers
      console.log(this.auxRoles);

    })
  }
  getAllteamMembers(){
    this.usuarioService
            .getAllTeamMembersByWorkshopID(this.workshopId)
            .subscribe((responseTeam) => { 
              console.log(responseTeam)
              this.lstGlobalTeamMemebersAux = [];
              const lstArrays = [];
                const lstStructureFormatted = [];
                responseTeam.teamMembers.forEach((element) => {
                  // Generar link para cada miembro y agregarlo a linksDeUsuarios
                  const email = element.Email;
                  let domainUrl = _IP;
                  if (domainUrl == 'localhost'){
                    domainUrl = 'localhost:4200'
                  }
                  const link = `${domainUrl}/play/board/${this.workshopId}/${email}`;
                  this.linksDeUsuarios.push({ email, link });
                  if (lstArrays.length == 0) {
                    lstArrays.push(element.TeamId);
                  } else {
                    if (!lstArrays.includes(element.TeamId)) {
                      lstArrays.push(element.TeamId);
                    }
                  }
                });
                for (
                  let index = 1;
                  index <= lstArrays.length;
                  index++
                ) {
                  const tmpLst = responseTeam.teamMembers.filter(
                    (team) => {
                      let tempTeamId = team.TeamId;
                      if (team.TeamId == lstArrays[index - 1]) {
                        team.TeamId = index;
                        console.log('entrainf team :', team);
                        team.TeamIdBD = tempTeamId;
                        return team;
                      }
                    }
                  );
                  if (tmpLst.length > 0) {
                    lstStructureFormatted.push({
                      TeamId: index,
                      TeamName: tmpLst[0].TeamName,
                      TeamIdBD: tmpLst[0].TeamIdBD,
                      teamMembers: tmpLst,
                    });
                  } else {
                    console.log('Entra ELSEE', tmpLst);
                  }
                }
              console.log(lstStructureFormatted)
              lstStructureFormatted.forEach((value) => {
                this.lstGlobalTeamMemebersAux = this.lstGlobalTeamMemebersAux.concat(
                  value.teamMembers
                );
              });
              console.log(this.lstGlobalTeamMemebersAux)
              this.dataSourceTable = new MatTableDataSource(this.lstGlobalTeamMemebersAux);
              this.dataSourceTable.paginator = this.paginator;
            });
  }

  getFacs() {
    console.log('llega');
    this.workshopService.getFacilitatorsById(this.workshopId).subscribe(
      response => {
        if (response.intResponse === 200) {
          this.lstFacs = response.data; 
          console.log('facs',this.lstFacs); 
        } else {
          console.error('Failed to fetch facilitators:', response);
          // Manejo de errores, por ejemplo, mostrar un mensaje al usuario
        }
      }
    );
  }
  

  async cargarTeams(isSave = false) {
    this.spinner.show();

    this.workshopService.getExistsTeamMeambersBoard(this.workshopId).subscribe(
      async (resp) => {
        if (resp.intResponse == 200) {
          this.lstGlobalTeamMemebers = resp["data"];
          const response = await this.teamServise.getAllTeamsAndMembersById(this.workshopId).toPromise();
          console.log(response);
          response.teams.forEach(team => {
            console.log(team.team.Name,team.team.code);
          });
          if (response.observers) {
            this.lstContacts = response.observers; 
            console.log('contacts',this.lstContacts); 
          }
          
          this.teamServise.getFacTeamCodeById(this.workshopId).toPromise()
          .then(response => {
            this.facCode = response.code['code']; 
            console.log(this.facCode);
          })
          .catch(error => {
            console.error('Error fetching facilitator team code:', error);
          });
      
          
          response.teams.forEach(element => {
            this.lstOfTeams.push({
              TeamId: element.team.TeamId,
              TeamMembers: [],
              Avatar: element.team.Avatar,
              teamCode: element.team.code,
            });
          });
          this.cargarTeamMembers(isSave);
        }

      })

   
  }
  cargarTeamMembers(isSave=false){
    let indexTeam = 0;
    for(let i = 0; i < this.lstGlobalTeamMemebers.length; i++){
      indexTeam = this.lstOfTeams.map((e) => {return e.TeamId}).indexOf(this.lstGlobalTeamMemebers[i].TeamId);
      if(indexTeam == -1){
        // this.lstOfTeams.push({
        //   TeamId: this.lstGlobalTeamMemebers[i].TeamId,
        //   TeamMembers: [this.lstGlobalTeamMemebers[i]],
        //   Avatar: 'Team Facilitator'
        // });
      } else {
        this.lstOfTeams[indexTeam].TeamMembers.push(this.lstGlobalTeamMemebers[i]);
      }
    }
    this.spinner.hide();
    let i = 1;
    this.lstOfTeams.forEach((team, index) => {
      team.Avatar = "Team " + i++
      console.log(team.Avatar)
    })
    if(isSave){
      this.actualizarRoles()
    }
  }
  actualizarRoles(){
    console.log(this.lstOfTeams);
    console.log(this.auxRoles);
    this.lstOfTeams.forEach(team =>{
      team.TeamMembers.forEach(teamMeamber =>{
        let auxMeamber = this.auxRoles.filter(x => x.Email == teamMeamber.Email);
        console.log(auxMeamber);
        if (auxMeamber.length > 0) {
          if (auxMeamber[0].TeamId == teamMeamber.TeamId) {
            console.log(auxMeamber[0].Email, "Aun existe en mismo equipo")
            this.usuarioService
              .updateMemberRolByID(auxMeamber[0].UserID,teamMeamber.UserID)
              .subscribe((responseTeam) => {
                console.log(responseTeam);
              })
          } else {
            console.log(auxMeamber[0].Email, "Aun existe en OTRO equipo")

          }

        }else{
          console.log("Ya no existe!")
        }
      })
    })
    setTimeout(() => {
      this.cargarTeamMembersParaRoles();
    }, 1000);
   
    // this.auxRoles.forEach(meamber => {
    //   if(meamber.TeamId !=1 ){
    //     let auxTeam = this.lstOfTeams.filter(team => team.TeamId == meamber.TeamId)
    //     console.log(auxTeam)
    //     if(auxTeam[0].TeamId == meamber.TeamId){
    //       if(auxTeam.length > 0){
    //         let auxTeamMeamber = auxTeam[0].TeamMembers.filter(teamMeamber  => teamMeamber.Email == meamber.Email);
    //         console.log(auxTeamMeamber)
    //         if(auxTeam.length > 0){
    //           console.log(meamber.Email,"Existe en el nuevo equipo")
    //         }else{
    //           console.log(meamber.Email,"NO existe en el nuevo equipo")
    //         }
            
    //       }else{
    //         console.log("NO existe  el  equipo")
  
    //       }
    //     }else{
    //       console.log(meamber.Email,"NO existe en el nuevo equipo");
    //     }
        

    //   }
      
    // });

  }
  getLinkForEmail(email: string): string {
    const usuario = this.linksDeUsuarios.find(u => u.email === email);
    return usuario ? usuario.link : 'Link no encontrado';
  }
  
  onDragStarted(event: CdkDragDrop<Array<TeamMember>>, team: any){
    console.log(event,team);
    if(team['lockTeam'] != true && !this.canDeleteOrAdd){
      Swal.fire({
        html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">Lock the team first to make moves</label>`,
        showConfirmButton: true,
        confirmButtonText: ' &nbsp;&nbsp;&nbsp;' + 'Ok' + '&nbsp;&nbsp;&nbsp; ',
        confirmButtonColor: '#5EA0CC',
        allowOutsideClick: false,
        icon: 'warning'
      })
      return
    }
  }
  async dropTeamMemeber(event: CdkDragDrop<Array<TeamMember>>, team: any){
    console.log(event,team);
    if(event.previousContainer != event.container){
      if(team['lockTeam'] != true && !this.canDeleteOrAdd){
        Swal.fire({
          html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">Lock the team first to make moves</label>`,
          showConfirmButton: true,
          confirmButtonText: ' &nbsp;&nbsp;&nbsp;' + 'Ok' + '&nbsp;&nbsp;&nbsp; ',
          confirmButtonColor: '#5EA0CC',
          allowOutsideClick: false,
          icon: 'warning'
        })
        return
      }
      if(event.previousContainer.data.length == 1 && !this.canDeleteOrAdd){
        Swal.fire({
          html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">You can't leave a team empty now  </label>`,
          showConfirmButton: true,
          confirmButtonText: ' &nbsp;&nbsp;&nbsp;' + 'Ok' + '&nbsp;&nbsp;&nbsp; ',
          confirmButtonColor: '#5EA0CC',
          allowOutsideClick: false,
          icon: 'warning'
        })
        return
      }

      if(event.previousContainer.data.length == 1){
        await Swal.fire({
          html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> This action will delete the team if you Save Changes</label>`,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: ' &nbsp;&nbsp;&nbsp;' + 'Ok' + '&nbsp;&nbsp;&nbsp; ',
          confirmButtonColor: '#5EA0CC',
          allowOutsideClick: false,
          icon: 'warning'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.hasChanges = true;
            this.spinner.show();
            console.log(event.previousContainer.data[event.previousIndex]);
            var auxTeamMember = {};
            Object.assign(auxTeamMember, event.previousContainer.data[event.previousIndex]);
            event.previousContainer.data[event.previousIndex].TeamId = team.TeamId;
            const x = await this.updateTeamMemberBD(event.previousContainer.data[event.previousIndex], (auxTeamMember as TeamMember));
            if (x) {
              transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex
              );
              this.getAllteamMembers();
            }
          } else {
            return;
          }
        });
      }else{
        this.spinner.show();
        console.log(event.previousContainer.data[event.previousIndex]);
        var auxTeamMember = {};
        Object.assign(auxTeamMember, event.previousContainer.data[event.previousIndex]);
        event.previousContainer.data[event.previousIndex].TeamId = team.TeamId;
        const x = await this.updateTeamMemberBD(event.previousContainer.data[event.previousIndex], (auxTeamMember as TeamMember));
        if(x){
          transferArrayItem(
            event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex
          );
          this.getAllteamMembers();

        }
      }
      
    } else {
      this.spinner.hide();
    }
  }

  updateTeamMemberBD(teamMemberNew: TeamMember, teamMemberOld: TeamMember){
    return new Promise((resolve, reject) => {
      this.userService.updateTeamMember(teamMemberNew).subscribe(response => {
        this.spinner.hide();
        if(response.intResponse == 200){
          if(teamMemberNew.TeamId == teamMemberOld.TeamId){
            Swal.fire({
              text: '',
              imageUrl: '../../../../assets/iconSuccessfully.svg',
              imageAlt: 'icon successfully',
              confirmButtonColor: '#6FB45C',
              html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> 98.- Saved data successfully </label>`,
            });
          }
          console.log(this.lstOfTeams);
          this.hasChanges = true;
          resolve(true);
        } else {
          Swal.fire({
            text: '',
            icon: 'error',
            html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">99.- Something wrong </label>`,
          });
          this.lstOfTeams[this.indexTeam].TeamMembers[this.indexTeamMember] = teamMemberOld;
          resolve(false);
        }
    })
    });
  }

  deleteTeam(team) {
    console.log("Numero de teams: ",this.lstOfTeams.length)
    if(this.lstOfTeams.length <= 2){
      Swal.fire({
        html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">There must be at least 2 teams to play</label>`,
        showConfirmButton: true,
        confirmButtonText: ' &nbsp;&nbsp;&nbsp;' + 'Ok' + '&nbsp;&nbsp;&nbsp; ',
        confirmButtonColor: '#5EA0CC',
        allowOutsideClick: false,
        icon: 'warning'
      })
    }else{
      if(team.TeamMembers.length>0){
        if (team.TeamMembers.length > 0) {
          // Definir un arreglo de opciones basado en la longitud de team.TeamMembers
          let options = []
          let option
          let i = 1;
          this.lstOfTeams.forEach((element, index) => {
            if(element['blnShow'] != false){
              option = {value : element.TeamId, text: `Team ${i++}`}
              console.log(option)
              options.push(option);
            }
          });
          Swal.fire({
            html: `
              <label style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">
                You can't delete team members. <br> Do you want to move members to:
              </label>
              <br>
              <select id="selectOptions" class="style2a calibriFont" style = "display: inline-block;
              border: 1px solid #adadad;
              width: 30%;
              padding: 6px 12px;
              margin-bottom: 0;
              font-weight: 400;
              line-height: 1.52857143;
              text-align: left;
              vertical-align: middle;
              cursor: pointer;
              background-image: none;
              border-radius: 4px;">
                ${options.map(option => `<option value="${option.value}">${option.text}</option>`).join('')}
              </select>
            `,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: ' &nbsp;&nbsp;&nbsp;' + 'Ok' + '&nbsp;&nbsp;&nbsp; ',
            confirmButtonColor: '#5EA0CC',
            allowOutsideClick: false,
            icon: 'warning',
          }).then(async (result) => {
            if (result.isConfirmed) {
              let aux = this.lstOfTeams.indexOf(team);
              // Obtener el valor seleccionado del select
              const selectedOption = document.getElementById('selectOptions')['value'];
              console.log(selectedOption);
              // Resto del código aquí...
              if(team.TeamId == Number(selectedOption)){
  
              } else {
                let auxIndex
                this.lstOfTeams.forEach((team, index) =>{
                  if(team.TeamId == Number(selectedOption)){
                    auxIndex = index;
                  }
  
                })
                this.lstOfTeams[auxIndex].TeamMembers = [...this.lstOfTeams[auxIndex].TeamMembers, ...this.lstOfTeams[aux].TeamMembers];
                this.lstOfTeams[aux].TeamMembers = [];
                console.log(this.lstOfTeams);
                this.hasChanges = true;
                this.blnTeamDeleted = true;
                this.lstOfTeams[aux]['blnShow'] = false;
                this.lstOfTeams[aux]['Avatar'] = "Sin avatar";
  
                let i = 1;
                this.lstOfTeams.forEach((team, index) => {
                  if (team['blnShow'] != false) {
                    team.Avatar = "Team " + i++
                    console.log(team.Avatar)
                  }
                })
              }
  
            }
          });
        }
      }else{
        Swal.fire({
          html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> This action will delete the team if you Save Changes  </label>`,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: ' &nbsp;&nbsp;&nbsp;' + 'Ok' + '&nbsp;&nbsp;&nbsp; ',
          confirmButtonColor: '#5EA0CC',
          allowOutsideClick: false,
          icon: 'warning'
        }).then(async (result) => {
          if (result.isConfirmed) {
            console.log(result)
            console.log(team);
            let aux = this.lstOfTeams.indexOf(team);
            console.log(aux);
            this.lstOfTeams[aux].TeamMembers = [];
            console.log(this.lstOfTeams);
            this.hasChanges = true;
            this.lstOfTeams[aux]['blnShow'] = false;
            this.lstOfTeams[aux]['Avatar'] = "Sin avatar";
  
            let i = 1;
            this.lstOfTeams.forEach((team, index) =>{
              if(team['blnShow'] != false){
                team.Avatar = "Team "+i++
                console.log(team.Avatar)
              }
            })        
          }
        });
    
      }
    }

    
  }

  async editTeamMember(){
    return new Promise((resolve, reject) => { 
    console.log(this.lstOfTeams, this.lstOfTeamsAux);
 
      this.lstOfTeams.forEach((team , indice) =>{
        if(team.TeamId == 0){
          team['TeamName'] = team.Avatar
          team['teamMembers'] = team.TeamMembers

        }else{
          team['TeamName'] = team.Avatar
          team['teamMembers'] = team.TeamMembers
          team['TeamIdBD'] = team.TeamId
        }
        
      })
      console.log("Borrar y crear",this.lstOfTeams);
      this.spinner.show();
      this.userService.deleteMembersandTeamsbyWorkshopId(this.workshopId).subscribe(async res =>{
        if (res.intResponse == 200) {
          console.log(res)

            const responseUserBulk =  await this.fnUpdateUsersByBulkLoad();
            if(responseUserBulk){

              setTimeout(() => {
                // var today = new Date();
                // var d = today,
                //   dformat = [d.getFullYear(), d.getMonth() + 1,
                //   d.getDate(),
                //   ].join('-') + ' ' +
                //     [d.getHours(),//ponemos +1 ay que el requerimiento pide dar acceso incluso una hora antes al board
                //     d.getMinutes(),
                //     d.getSeconds()].join(':');
                this.workshopService.getExistsTeamMeambersBoard(this.workshopId).subscribe(
                  (response) => {
                    if(res.intResponse == 200){
                      this.blnTeamDeleted = false;
                      this.lstGlobalTeamMemebers = response["data"];
                      this.lstOfTeams = [];
                      this.cargarTeams(true);    
                      this.hasChanges = false; 
                      this.getAllteamMembers();
                      Swal.fire({
                        text: '',
                        icon: 'success',
                        html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">All teams were saved and reloaded </label>`,
                      });
                      resolve(true); 
                    }else{
                      Swal.fire({
                        text: '',
                        icon: 'error',
                        html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">Something went wrong! </label>`,
                      });
                    }
                          
                  })
              }, 500);
              

            }
        }
      });
    });
  }

  fnUpdateUsersByBulkLoad() {
    return new Promise(resolve=>{
      this.userService
      .updateTeamAndTeamMemebersByArrayFacilitator(
        this.workshopId,
        this.lstOfTeams,
        false,
        -1,
        this.Cycles,
        this.boardMode,
        this.blnTeamDeleted
        )
      .subscribe(
        (responseTeams: any) => {
          console.log('responseTeams resp:', responseTeams);
          if (
            responseTeams.intResponse != 200 &&
            responseTeams.strAnswer == 'User already exists!'
          ) {

            Swal.fire({
              text: '',
              icon: 'error',
              html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">221.- The user with the email ${responseTeams.emailInvalid} is already registered! </label>`,
            });
          }
          // this.spinner.hide();
          // this.fnShowMessageResponse(response, 'teams');
          resolve(true);
        },
        (error) => {
          this.spinner.hide();
          resolve(false);
        }
      );
    });
  }
  closeDialog(){
    if (this.hasChanges == true){
      Swal.fire({
        html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> You have changes without save.<br> Do you want to close?</label>`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: ' &nbsp;&nbsp;&nbsp;' + 'Ok' + '&nbsp;&nbsp;&nbsp; ',
        confirmButtonColor: '#5EA0CC',
        allowOutsideClick: false,
        icon: 'warning'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.matDialogRef.close();
        }
      });
    }else{
      this.matDialogRef.close();
    }
  }
  addTeam(){
    var canAdd = false;
    var needToSave = false;
    this.lstOfTeams.forEach(team => {
      if(team.TeamMembers.length == 0){
        canAdd = true;
      }
      if(team['blnShow'] == false){
        needToSave = true;
      }
      console.log(team)
    })
    console.log(canAdd, needToSave)

    if(canAdd && needToSave){
      Swal.mixin({
        target: '#errorGeneral2',
        customClass: {
          container: 'position-absolute',
          content: '',
        },
        toast: true,
        timerProgressBar: true,
        showConfirmButton: false,
        timer: 8000,
        position: 'center-right',
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      }).fire({
        html: "You need to save changes after deleting a team if you want to create a new one"
      }).then(resp => /*this.blnErrorContainer = false*/ console.log(""));
      return
    }
    if(canAdd){
      Swal.fire({
        html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">Add a team member to the new team first</label>`,
        showConfirmButton: true,
        confirmButtonText: ' &nbsp;&nbsp;&nbsp;' + 'Ok' + '&nbsp;&nbsp;&nbsp; ',
        confirmButtonColor: '#5EA0CC',
        allowOutsideClick: false,
        icon: 'warning'
      })
      return
    }
    if(this.lstOfTeams.length > 5){
      Swal.fire({
        html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> You can't add more than 6 teams </label>`,
        showConfirmButton: true,
        confirmButtonText: ' &nbsp;&nbsp;&nbsp;' + 'Ok' + '&nbsp;&nbsp;&nbsp; ',
        confirmButtonColor: '#5EA0CC',
        allowOutsideClick: false,
        icon: 'warning'
      })
      return
    }else{
      this.spinner.show();
      let aux : {TeamId: number, Avatar:string, TeamMembers: Array<any>, teamCode: number} = {TeamId:0,Avatar:"Nuevo team", TeamMembers : [], teamCode:0 };
      this.lstOfTeams.push(aux);
      let i = 1;
            this.lstOfTeams.forEach((team, index) =>{
              if(team['blnShow'] != false){
                team.Avatar = "Team "+i++
                console.log(team.Avatar)
              }
            })
      console.log(this.lstOfTeams)
      const x = this.editTeamMember();
      if(x){
        console.log("LISTOOOOOOOOOOOOO")
        this.spinner.show();
        this.hasChanges = false;
        Swal.fire({
          text: '',
          icon: 'success',
          html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">Team created succesfully </label>`,
        });
        // setTimeout(() => {
        //   var today = new Date();
        //   var d = today,
        //     dformat = [d.getFullYear(), d.getMonth() + 1,
        //     d.getDate(),
        //     ].join('-') + ' ' +
        //       [d.getHours(),//ponemos +1 ay que el requerimiento pide dar acceso incluso una hora antes al board
        //       d.getMinutes(),
        //       d.getSeconds()].join(':');
        //   this.workshopService.getExistsWorkshopBoard(this.workshopId, dformat).subscribe(
        //     (response) => {
        //       this.lstOfTeams = [];
        //       this.lstGlobalTeamMemebers = response["data"];
        //       this.cargarTeams();
  
        //     })
        // }, 500);
      }
      
    }

  }
  blockTeams(){
    this.socketioService.blockAllTeams(this.workshopId,1);
    this.blnBlockTeams = true;
    // this.hasChanges = true;
    this.lstOfTeams.forEach(team =>{
      team['lockTeam'] = true;
    })
    Swal.fire({
      html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">All teams are locked now. <br>  When finished, click on "Reload teams". </label>`,
      showConfirmButton: true,
      confirmButtonText: ' &nbsp;&nbsp;&nbsp;' + 'Ok' + '&nbsp;&nbsp;&nbsp; ',
      confirmButtonColor: '#5EA0CC',
      allowOutsideClick: true,
      icon: 'warning'
    })
  }
  blockTeam(team){
    team['lockTeam'] = true;
    this.socketioService.blockTeam(team.TeamId,1);
    this.blnBlockTeams = true;
    // this.hasChanges = true;
    Swal.fire({
      html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">The team is locked now. <br> When finished, click on "Unlock all boards"</label>`,
      showConfirmButton: true,
      confirmButtonText: ' &nbsp;&nbsp;&nbsp;' + 'Ok' + '&nbsp;&nbsp;&nbsp; ',
      confirmButtonColor: '#5EA0CC',
      allowOutsideClick: true,
      icon: 'warning'
    })

  }
  unblockTeams(){
    if(this.hasChanges){
      Swal.fire({
        html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">Save your previous changes first. Press "Save changes & Reload"</label>`,
        showConfirmButton: true,
        confirmButtonText: ' &nbsp;&nbsp;&nbsp;' + 'Ok' + '&nbsp;&nbsp;&nbsp; ',
        confirmButtonColor: '#5EA0CC',
        allowOutsideClick: true,
        icon: 'warning'
      })
      return;
    }
    this.cargarTeamMembersParaRoles();
    this.socketioService.blockAllTeams(this.workshopId,0);
    this.blnBlockTeams = false;
    this.hasChanges = false;
    this.lstOfTeams.forEach(team =>{
      team['lockTeam'] = false;
    })
    Swal.fire({
      html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">All teams are  unlocked now</label>`,
      showConfirmButton: true,
      confirmButtonText: ' &nbsp;&nbsp;&nbsp;' + 'Ok' + '&nbsp;&nbsp;&nbsp; ',
      confirmButtonColor: '#5EA0CC',
      allowOutsideClick: true,
      icon: 'success'
    })
  }
  fnCrearForm(firstName, lastName, email, jobTitle, teamNumber) {
    this.teemMemberForm = new FormGroup({
      firstName: new FormControl(firstName, [Validators.required]),
      lastName: new FormControl(lastName, [Validators.required]),
      email: new FormControl(email, [
        Validators.required,
        Validators.email,
        Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      ]),
      file: new FormControl(undefined, []),
      jobTitle: new FormControl(jobTitle, []),
      teamNumber: new FormControl(teamNumber, [Validators.required])
    });
  }
  addContactTable(){
    console.log(this.teemMemberForm.get('teamNumber').value);
    if(this.blnBlockTeams != true && !this.canDeleteOrAdd){
      Swal.fire({
        html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">Press "Lock All Teams" button first to add new members</label>`,
        showConfirmButton: true,
        confirmButtonText: ' &nbsp;&nbsp;&nbsp;' + 'Ok' + '&nbsp;&nbsp;&nbsp; ',
        confirmButtonColor: '#5EA0CC',
        allowOutsideClick: false,
        icon: 'warning'
      })
      return
    }
    if(this.teemMemberForm.get('teamNumber').value > this.lstOfTeams.length){
      Swal.fire({
        text: '',
        icon: 'error',
        html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">94.- This team not exists</label>`,
      });
      return;
    }
    // this.loading = true;
  if(this.isEditContact != -1){
    console.log(this.teemMemberForm.value);
    console.log(this.lstOfTeams, this.lstGlobalTeamMemebersAux);
    console.log(this.indiceLstOfTeams,this.indicesLstOdAllMembers);
    this.lstOfTeams[this.indiceLstOfTeams[0]['conjunto']].TeamMembers.splice(this.indiceLstOfTeams[0]['indice'],1);
    this.lstGlobalTeamMemebersAux.splice(this.indicesLstOdAllMembers,1);
    console.log(this.lstOfTeams, this.lstGlobalTeamMemebersAux);
    if(this.teemMemberForm.get('teamNumber').value){
        this.lstOfTeams[this.teemMemberForm.get('teamNumber').value-1].TeamMembers.unshift({
          UserID: -1,
          FirstName: this.teemMemberForm.get('firstName').value,
          LastName: this.teemMemberForm.get('lastName').value,
          Email: this.teemMemberForm.get('email').value,
          JobTitle: this.teemMemberForm.get('jobTitle').value,
          type: 3,
          TeamId: (this.teemMemberForm.get('teamNumber').value)?this.teemMemberForm.get('teamNumber').value:-1,
          // id: this.consecutiveId,
        });
        this.lstGlobalTeamMemebersAux.unshift({
          UserID: -1,
          FirstName: this.teemMemberForm.get('firstName').value,
          LastName: this.teemMemberForm.get('lastName').value,
          Email: this.teemMemberForm.get('email').value,
          JobTitle: this.teemMemberForm.get('jobTitle').value,
          type: 3,
          TeamId: (this.teemMemberForm.get('teamNumber').value)?this.teemMemberForm.get('teamNumber').value:-1,
          // id: this.consecutiveId,
        });
        this.isEditContact = -1
        Swal.fire({
          html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">Team member edited. <br> When finished, click on "Save changes & Reload". </label>`,
          showConfirmButton: true,
          confirmButtonText: ' &nbsp;&nbsp;&nbsp;' + 'Ok' + '&nbsp;&nbsp;&nbsp; ',
          confirmButtonColor: '#5EA0CC',
          allowOutsideClick: false,
          icon: 'success'
        })
    }
    

  } else {
    // inicio else nuevo contacto
    console.log("TEAM NUMBERR: ",this.teemMemberForm.get('teamNumber').value)
    let existsUser = false;
    this.lstGlobalTeamMemebersAux.forEach((value) => {
      if (value.Email.toLowerCase() == this.teemMemberForm.get('email').value.toLowerCase()) {
        Swal.fire({
          html:
            '<style>swal2-confirm{border-radius: 50px !important; } swal2-styled{border-radius: 50px !important; }</style><label  style="font-size: 22px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> 95.- User already registered at the table! </label>',
          imageUrl: '../../../../assets/iconPrevention.svg',
        });
        existsUser = true;
        return;
      }
    });
    this.lstFacs.forEach((value) => {
      if (value.Email.toLowerCase() == this.teemMemberForm.get('email').value.toLowerCase()) {
        Swal.fire({
          html:
            '<style>swal2-confirm{border-radius: 50px !important; } swal2-styled{border-radius: 50px !important; }</style><label  style="font-size: 22px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> 95.- This user is already playing as a facilitator! </label>',
          imageUrl: '../../../../assets/iconPrevention.svg',
        });
        existsUser = true;
        return;
      }
    });
    // REVISAR QUE NO SE ENCUENTRA EN LOS CONTACTOS // 
    this.lstContacts.forEach((value) => {
      if (value.Email.toLowerCase() == this.teemMemberForm.get('email').value.toLowerCase()) {
        Swal.fire({
          html:
            '<style>swal2-confirm{border-radius: 50px !important; } swal2-styled{border-radius: 50px !important; }</style><label  style="font-size: 22px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> 95.- This user email have been selected a an observer! </label>',
          imageUrl: '../../../../assets/iconPrevention.svg',
        });
        existsUser = true;
        return;
      }
    });
    //
    if (existsUser) {
      return;
    }
    console.log('add contact');
    if(!this.teemMemberForm.get('teamNumber').value)
      this.lstGlobalTeamMemebersAux.unshift({
        UserID: -1,
        FirstName: this.teemMemberForm.get('firstName').value,
        LastName: this.teemMemberForm.get('lastName').value,
        Email: this.teemMemberForm.get('email').value,
        JobTitle: this.teemMemberForm.get('jobTitle').value,
        type: 3,
        TeamId: (this.teemMemberForm.get('teamNumber').value)?this.teemMemberForm.get('teamNumber').value:-1,
        // id: this.consecutiveId,
      });
    if(this.teemMemberForm.get('teamNumber').value){
        this.lstOfTeams[this.teemMemberForm.get('teamNumber').value-1].TeamMembers.unshift({
          UserID: -1,
          FirstName: this.teemMemberForm.get('firstName').value,
          LastName: this.teemMemberForm.get('lastName').value,
          Email: this.teemMemberForm.get('email').value,
          JobTitle: this.teemMemberForm.get('jobTitle').value,
          type: 3,
          TeamId: (this.teemMemberForm.get('teamNumber').value)?this.teemMemberForm.get('teamNumber').value:-1,
          // id: this.consecutiveId,
        });
        this.lstGlobalTeamMemebersAux.unshift({
          UserID: -1,
          FirstName: this.teemMemberForm.get('firstName').value,
          LastName: this.teemMemberForm.get('lastName').value,
          Email: this.teemMemberForm.get('email').value,
          JobTitle: this.teemMemberForm.get('jobTitle').value,
          type: 3,
          TeamId: (this.teemMemberForm.get('teamNumber').value)?this.teemMemberForm.get('teamNumber').value:-1,
          // id: this.consecutiveId,
        });
        Swal.fire({
          html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">Team member added. <br> When finished, click on "Save changes & Reload". </label>`,
          showConfirmButton: true,
          confirmButtonText: ' &nbsp;&nbsp;&nbsp;' + 'Ok' + '&nbsp;&nbsp;&nbsp; ',
          confirmButtonColor: '#5EA0CC',
          allowOutsideClick: false,
          icon: 'success'
        })
    } else {
      this.lstGlobalTeamMemebersAux.unshift({
        UserID: -1,
        FirstName: this.teemMemberForm.get('firstName').value,
        LastName: this.teemMemberForm.get('lastName').value,
        Email: this.teemMemberForm.get('email').value,
        JobTitle: this.teemMemberForm.get('jobTitle').value,
        type: 3,
        TeamId: (this.teemMemberForm.get('teamNumber').value)?this.teemMemberForm.get('teamNumber').value:-1,
        // id: this.consecutiveId,
      });
    }
    // this.lstOfNewTeamMembers.unshift({
    //   UserID: -1,
    //   FirstName: this.teemMemberForm.get('firstName').value,
    //   LastName: this.teemMemberForm.get('lastName').value,
    //   Email: this.teemMemberForm.get('email').value,
    //   JobTitle: this.teemMemberForm.get('jobTitle').value,
    //   type: 3,
    //   TeamId: (this.teemMemberForm.get('teamNumber').value) ? this.teemMemberForm.get('teamNumber').value : -1,
    //   id: this.consecutiveId,
    // });
    // this.consecutiveId++;
    // fin else nuevo contacto
  }
  this.isEditContact = -1;
  // this.auxTeamMembers = this.lstGlobalTeamMemebersAux
  console.log(this.lstGlobalTeamMemebersAux, this.lstGlobalTeamMemebersAux,this.lstOfTeams)
  this.dataSourceTable = new MatTableDataSource(this.lstGlobalTeamMemebersAux);
  this.dataSourceTable.paginator = this.paginator;
  this.teemMemberForm.get('firstName').setValue('');
  this.teemMemberForm.get('lastName').setValue('');
  this.teemMemberForm.get('email').setValue('');
  this.teemMemberForm.get('jobTitle').setValue('');
  this.teemMemberForm.get('teamNumber').setValue(null);
  document.getElementById('firstName').focus();
  this.hasChanges = true;


  }
  fnEditUser(row, i){
    if (
      this.teemMemberForm.get('firstName').value != '' ||
      this.teemMemberForm.get('lastName').value != '' ||
      this.teemMemberForm.get('email').value != '' ||
      this.teemMemberForm.get('jobTitle').value != ''
    ) {
      Swal.fire({
        html:
          '<style>swal2-confirm{border-radius: 50px !important; } swal2-styled{border-radius: 50px !important; }</style><label  style="font-size: 22px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> 104.- You still do not save the user entered! </label>',
        imageUrl: '../../../../assets/iconPrevention.svg',
        showCancelButton: true,
        confirmButtonColor: '#6FB45C',
        cancelButtonColor: '#ED5050',
        confirmButtonText: 'Yes, edit this user',
        cancelButtonText: 'No, save first',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.replaceInputsContactForm(row);
          this.isEditContact = i;
        } else {
          this.isEditContact = -1;
        }
      });
    } else {
      this.replaceInputsContactForm(row);
      this.isEditContact = i;
    }

  }
  replaceInputsContactForm(row) {
    this.isEdited = true;
    this.teemMemberForm.get('firstName').setValue(row.FirstName);
    this.teemMemberForm.get('lastName').setValue(row.LastName);
    this.teemMemberForm.get('email').setValue(row.Email);
    this.teemMemberForm.get('jobTitle').setValue(row.JobTitle);
    this.teemMemberForm.get('teamNumber').setValue(row.TeamId>0?row.TeamId:null);
    console.log(this.encontrarIndicesPorEmail_lstOfTeams(row.Email));
    console.log(this.encontrarIndicesPorEmail_lstGlobalTeamMemebersAux(row.Email));
    this.indiceLstOfTeams = this.encontrarIndicesPorEmail_lstOfTeams(row.Email)
    this.indicesLstOdAllMembers = this.encontrarIndicesPorEmail_lstGlobalTeamMemebersAux(row.Email)
  }

  fnDeleteUser(row){
    console.log(row,this.lstOfTeams)
    let auxNumberUsers = this.lstOfTeams.filter(x => x.TeamId == row['TeamId'])
    if(auxNumberUsers[0].TeamMembers.length == 1 && !this.canDeleteOrAdd){
      Swal.fire({
        html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">You can't leave a team empty now  </label>`,
        showConfirmButton: true,
        confirmButtonText: ' &nbsp;&nbsp;&nbsp;' + 'Ok' + '&nbsp;&nbsp;&nbsp; ',
        confirmButtonColor: '#5EA0CC',
        allowOutsideClick: false,
        icon: 'warning'
      })
      return
    }
    if(this.blnBlockTeams != true && !this.canDeleteOrAdd){
      Swal.fire({
        html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">Press "Lock All Teams" button first to delete members</label>`,
        showConfirmButton: true,
        confirmButtonText: ' &nbsp;&nbsp;&nbsp;' + 'Ok' + '&nbsp;&nbsp;&nbsp; ',
        confirmButtonColor: '#5EA0CC',
        allowOutsideClick: false,
        icon: 'warning'
      })
      return
    }
    Swal.fire({
      html:
        '<style>swal2-confirm{border-radius: 50px !important; } swal2-styled{border-radius: 50px !important; }</style><label  style="font-size: 22px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> 101.- Are you sure you want to remove this team member from this workshop? </label>',
      imageUrl: '../../../../assets/iconPrevention.svg',
      showCancelButton: true,
      confirmButtonColor: '#6FB45C',
      cancelButtonColor: '#ED5050',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        this.indiceLstOfTeams = this.encontrarIndicesPorEmail_lstOfTeams(row.Email)
        this.indicesLstOdAllMembers = this.encontrarIndicesPorEmail_lstGlobalTeamMemebersAux(row.Email)
        console.log(this.lstGlobalTeamMemebersAux, this.lstGlobalTeamMemebersAux,this.lstOfTeams)
        this.lstOfTeams[this.indiceLstOfTeams[0]['conjunto']].TeamMembers.splice(this.indiceLstOfTeams[0]['indice'],1);
        this.lstGlobalTeamMemebersAux.splice(this.indicesLstOdAllMembers,1);
        this.dataSourceTable = new MatTableDataSource(this.lstGlobalTeamMemebersAux);
        this.dataSourceTable.paginator = this.paginator;
        this.hasChanges = true;
        this.teemMemberForm.get('firstName').setValue('');
        this.teemMemberForm.get('lastName').setValue('');
        this.teemMemberForm.get('email').setValue('');
        this.teemMemberForm.get('jobTitle').setValue('');
        Swal.fire({
          html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">Team member deleted. <br> When finished, click on "Save changes & Reload". </label>`,
          showConfirmButton: true,
          confirmButtonText: ' &nbsp;&nbsp;&nbsp;' + 'Ok' + '&nbsp;&nbsp;&nbsp; ',
          confirmButtonColor: '#5EA0CC',
          allowOutsideClick: false,
          icon: 'success'
        })
      }
    });
  }

  encontrarIndicesPorEmail_lstOfTeams(email) {
    const indices = [];
  
    // Recorre cada conjunto de datos en la lista
    for (let i = 0; i < this.lstOfTeams.length; i++) {
      const data = this.lstOfTeams[i];
      if (data.TeamMembers && Array.isArray(data.TeamMembers)) {
        const teamMembers = data.TeamMembers;
  
        // Recorre la lista de teamMembers en el conjunto de datos actual
        for (let j = 0; j < teamMembers.length; j++) {
          const member = teamMembers[j];
          if (member.Email === email) {
            indices.push({ conjunto: i, indice: j });
          }
        }
      }
    }
  
    return indices;
  }

  encontrarIndicesPorEmail_lstGlobalTeamMemebersAux(email) {
    let indices = -1;
  
    // Recorre cada objeto en el conjunto de datos
    for (let i = 0; i < this.lstGlobalTeamMemebersAux.length; i++) {
      const member = this.lstGlobalTeamMemebersAux[i];
      if (member.Email === email) {
        indices = i;
      }
    }
  
    return indices;
  }

  fnCopyLinkUser(email: string) {
    console.log('Vamos a generar el link', email);
    const link = this.getLinkForEmail(email); // Obtener el link pregenerado
  
    const tempInput = document.createElement('input');
    document.body.appendChild(tempInput);
    tempInput.value = link;
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
  
    Swal.fire({
      position: 'top-end', 
      icon: 'success', 
      html: '<strong>Link:</strong> <br>' + link,
      showConfirmButton: false,
      timer: 3500, 
      customClass: {
        popup: 'swal2-popup-min', 
        title: 'swal2-title-min' 
      }
    });
    
  }
  

  mostrarFormulario = false;

  toggleForm() {
    this.mostrarFormulario = !this.mostrarFormulario;
  }
}
