<div mat-dialog-title style="border-bottom: 1px solid #f3f3f3; width: 100% !important; text-align: center;">
    &nbsp; &nbsp; &nbsp; &nbsp;<label class="FontMontserratSubTitles">{{getSingleTextLabel('110')}}</label>
    <!--<button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
    (click)="matDialogRef.close()">
    <span aria-hidden="true">&times;</span>
  </button> -->
</div>
<mat-dialog-content style="height: 80% !important;">
    <div class="row w-100 mt-1">
        <div class="col text-right">
            <label class="FontMontserratText">{{getSingleTextLabel('2002')}}: {{strFacilitators}}</label>
        </div>
    </div>
    <div class="row w-100 mt-1">
        <div class="btnRefresh">
            <button class=" btn btn-primary " (click)="updateView()" style=" margin: 5px 27px;">Refresh</button>
    
        </div>
        <div class="col text-right">
            <label class="FontMontserratText">{{getSingleTextLabel('107')}}: {{strObservers}}</label>
        </div>
    </div>
    <div class="grid-container">
        <div class="pl-0 pr-0" *ngFor="let jsnTeam of arrAllTeams">
            <div class="example-containerType">
                <p class="titleType">First Improvement</p>
                <div style="border: solid 1px #ccc;">
                    <div class="FontMontserratText" style="text-align: center; color: black;">
                       {{fnGetImprovement(jsnTeam['options'][0]['Option'])}}
                    </div>
                </div>
            </div>
            <div class="example-containerType">
                <p class="titleType">Second Improvement</p>
                <div style="border: solid 1px #ccc;">
                    <div class="FontMontserratText" style="text-align: center; color: black;">
                        {{fnGetImprovement(jsnTeam['options'][1]['Option'])}}
                    </div>
                </div>
            </div>
            <div class="example-containerType" style="margin-top: 15px;">
                <p class="titleTeam" [ngStyle]="{'background-color':jsnTeam['team']['Color']?jsnTeam['team']['Color']:'#1b3f89','color':jsnTeam['team']['Color']=='#bec3ff' || jsnTeam['team']['Color']=='#84eeeb' || jsnTeam['team']['Color']=='#ffe998' || jsnTeam['team']['Color'] == '#e4e4ec' || jsnTeam['team']['Color'] == '#7faeff' ?'black':'white'}">{{jsnTeam['team']['Avatar']}}</p>
    
            </div>
            <div class="example-containerType" *ngIf="jsnTeam['members']['CEO'].length>0">
                <p class="titleType">{{getSingleTextLabel('2003')}}</p>
                <div style="border: solid 1px #ccc;">
                    <div class="FontMontserratText" style="text-align: center; color: black;" *ngFor="let item of jsnTeam['members']['CEO']">
                        {{item.nameComplete}}<span *ngIf="item['hasControl']" class="hasControlC">*</span>
                    </div>
                </div>
            </div>

            <div class="example-containerType" *ngIf="jsnTeam['members']['CFO'].length>0">
                <p class="titleType">{{getSingleTextLabel('2004')}}</p>
                <div style="border: solid 1px #ccc;">
                    <div class="FontMontserratText" style="text-align: center; color: black;" *ngFor="let item of jsnTeam['members']['CFO']">
                        {{item.nameComplete}}<span *ngIf="item['hasControl']" class="hasControlC">*</span>
                    </div>
                </div>
            </div>
            <div class="example-containerType" *ngIf="jsnTeam['members']['CMO'].length>0">
                <p class="titleType">{{getSingleTextLabel('2005')}}</p>
                <div style="border: solid 1px #ccc;">
                    <div class="FontMontserratText"  style="text-align: center; color: black;" *ngFor="let item of jsnTeam['members']['CMO']">
                        {{item.nameComplete}} <span *ngIf="item['hasControl']" class="hasControlC">*</span>
                    </div>
                </div>
            </div>
            <div class="example-containerType" *ngIf="jsnTeam['members']['COO'].length>0">
                <p class="titleType">{{getSingleTextLabel('2006')}}</p>
                <div style="border: solid 1px #ccc;">
                    <div class="FontMontserratText" style="text-align: center; color: black;" *ngFor="let item of jsnTeam['members']['COO']">
                        {{item.nameComplete}}<span *ngIf="item['hasControl']" class="hasControlC">*</span>
                    </div>
                </div>
            </div>
            <div class="example-containerType" *ngIf="jsnTeam['members']['CTO'].length>0">
                <p class="titleType">{{getSingleTextLabel('2007')}}</p>
                <div style="border: solid 1px #ccc;">
                    <div class="FontMontserratText"  style="text-align: center; color: black;" *ngFor="let item of jsnTeam['members']['CTO']">
                        {{item.nameComplete}}<span *ngIf="item['hasControl']" class="hasControlC">*</span>
                    </div>
                </div>
            </div>
            <div class="example-containerType" *ngIf="jsnTeam['members']['members'].length>0">
                <p class="titleType">Members</p>
                <div style="border: solid 1px #ccc;">
                    <div [ngStyle]="{'display':obtenerPuesto(item,jsnTeam['members'])?'none':'block'}" class="FontMontserratText"  style="text-align: center; color: black;" *ngFor="let item of jsnTeam['members']['members']">
                        {{item.nameComplete}} 
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button type="button" class="btn btn-ok" id="btncloseHide" (click)="matDialogRef.close()">{{getSingleTextLabel('136')}}</button>
</mat-dialog-actions>
