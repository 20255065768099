<label class="style1 calibriFont" id="example-radio-group-label">{{getSingleTextLabel('121')}}</label><br><br>
<mat-radio-group 
  aria-labelledby="example-radio-group-label"
  class="example-radio-group"
  >
  <mat-radio-button  name="fieldName" ngDefaultControl [(ngModel)]="selectedLang" class="example-radio-button style2a calibriFont" *ngFor="let language of lstLanguages" [value]="language.LanguageId" 
   [checked]="language.check">
    {{language.Language}}<label *ngIf="language.langBase == 1"> ({{getSingleTextLabel('122')}})</label>
  </mat-radio-button><br>
</mat-radio-group>
<!--<div>Your selection: {{selectedpeople}}</div>-->

<button  type="button" class="btn mx-3 FontMontserratTextInputsDone" id="btnsaveht" [disabled]="selectedLang.length == 0"
        
        (click)="fnSelectLanguage()">&nbsp;&nbsp;{{getSingleTextLabel('136')}}&nbsp;&nbsp;</button>
        <!--<button  type="button" class="btn btn-outline-success mx-3" id="btnsaveht" [disabled]="selectedteam.length == 0"
        style="Background-color:#6FB45C;border-radius: 50px;color: white;font-family: 'Montserrat';"
        (click)="fnSelectTeam()">&nbsp;&nbsp;Cancel&nbsp;&nbsp;</button>-->
        <button class="btn btn-secondary mx-3 FontMontserratTextInputsCancel"
                (click)="fnCancel()">&nbsp;{{getSingleTextLabel('123')}}&nbsp;</button>
