<div class="container mt-3">
    <div class="row">
        <div class="col text-center">
            <label class="FontMontserratTitles">Create Language</label>
        </div>
    </div>
    
    <div class="row text-right">
        <div class="col text-right">
            <button class="btn btn-info bntInfo" [routerLink]="['/languages']" routerLinkActive="router-link-active">
                <i class="fa fa-arrow-alt-circle-left fa-lg" title="Back to Languages view" ></i>
                <span>&nbsp;&nbsp;Back&nbsp;&nbsp;</span>
            </button>
        </div>
    </div>
    <!--<div class="row-">
        <div class="col text-right">
            <button class="btn btn-outline-success" [routerLink]="['/crear-usuario']" routerLinkActive="router-link-active">
                <i class="fas fa-plus-circle fa-lg"  title="Detalles del usuario" ></i>
                <span >  Create Language</span>
            </button>
        </div>
    </div>-->
    <!--<div class="row">
        <div class="col-sm-3 col-md-3 col-lg-3"></div>
        <div class="col-sm-6 col-md-6 col-lg-6">
            <label for="lstLang">To create a new language, first fill box 'Name', then must be fill the labels on every card </label>
                <br>
            <ng-multiselect-dropdown [settings]="dropDownSettingsLng" class="ddlSelect" [(ngModel)]="languageSeleccionado" [data]="lstLanguages"
            name="lstLang"  [placeholder]="lblplaceholderlstlanguages" (onSelect)="fnChangeLanguage($event)"></ng-multiselect-dropdown>
        </div>
        <div class="col-sm-3 col-md-3 col-lg-3"></div>
    </div>
    <hr>-->
    <br>
    <div class="row text-center" >
        <div class="col-sm-12 col-md-12 col-lg-12 text-left"  class="FontMontserratText" >To create a new language, first fill box 'Name', then you must be fill the labels on every card.</div>
        
    </div><br>
    <div class="row text-left">
        
            <!--<label for="lstLang">Name of Language selected:</label><br>-->
            <div class="col-sm-4 col-md-4 col-lg-4" >
                <input id="txtDescLanguage" name="name" class="form-control" type="text" placeholder="Name of new language" >
                <br>
            <mat-radio-group  *ngIf="blnisAdminUser" >
                <mat-radio-button style="margin: 0 5px;" [checked]="isPrivate == 1" class="example-margin" value="1" (change)="radioChangetypeLang($event)">
                    Private </mat-radio-button>
                <mat-radio-button style="margin: 0 5px;" [checked]="isPrivate == 0" class="example-margin" value="0" (change)="radioChangetypeLang($event)">
                    International</mat-radio-button>
                
            </mat-radio-group>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4 text-left" *ngIf="newIdLanguage == 0">
               <button class="btn btn-outline-success btnOK"  (click)="fnNewLanguage()"  >
                   <i class="fas fa-plus-circle fa-lg"  title="Clic to create new Language" ></i>
                   <span >&nbsp;&nbsp;Create&nbsp;&nbsp;</span></button>
                
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4" >
               
            </div>
        
    </div><br>
    
    <div class="row text-left" *ngIf="newIdLanguage > 0">
        <div class="col-sm-12 col-md-12 col-lg-12 text-left" class="FontMontserratText">Cards of pages to fill required labels </div>
        
    </div>
    <hr style="color: rgb(57, 95, 218);">
    <br>

    <div class="row" *ngIf="newIdLanguage > 0">
        <!--BOAR view-->
        <div class="col-sm-2 col-md-2 col-lg-2">
                
            <mat-card class="example-card" style="background-color:#244C91;border-width: 40px;height: 100px !important;cursor: pointer;text-align: center;" (click)="fnModalEdit('Game Board & Fin Statements')">
               <!--<mat-card-header>
                <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;"><a (click)="fnModalEdit('Board')">Main Board View</a>&nbsp;&nbsp;&nbsp;<i class="fa fa-pencil fa-lg"  (click)="fnModalEdit('Board')" title="Edit labels of Main Board" style="color:green;position:relative; top:4px"></i></mat-card-title>
                <mat-card-subtitle>&nbsp;</mat-card-subtitle>
                </mat-card-header>-->
                
                <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">
                    Game Board & Fin Statements
                </mat-card-title>
            </mat-card>
        </div>
    <!--SetUp view-->  

    <div class="col-sm-2 col-md-2 col-lg-2">
        
        <mat-card class="example-card" style="background-color:#A7C158;border-width: 40px;height: 100px !important;cursor: pointer;text-align: center;" (click)="fnModalEdit('SetUp')">
            <!-- <mat-card-header> -->
            <!--<div mat-card-avatar class="example-header-imageSU"></div>-->
            <!-- <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">Team SetUp&nbsp;&nbsp;&nbsp;<i class="fa fa-pencil fa-lg"  (click)="fnModalEdit('SetUp')" title="Edit labels of Set Up view" style="color:green;position:relative; top:4px"></i></mat-card-title> -->
            <!-- <mat-card-subtitle>&nbsp;</mat-card-subtitle>
            </mat-card-header> --> 
            <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">
                Team SetUp
            </mat-card-title>
            <!--<mat-card-content>
                <img mat-card-image src="../../../assets/setup.png" alt="Actually View">
            <p>
                Manage configuration view labels.
            </p>
            </mat-card-content>-->
        </mat-card>
    </div>
    <!--BOAR Markets Customers-->
    <div class="col-sm-2 col-md-2 col-lg-2" >
            
        <mat-card class="example-card" style="background-color:#685D9F;border-width: 40px;height: 100px !important;cursor: pointer;text-align: center;" (click)="fnModalEdit('Notes on Financial Statements')">
            <!-- <mat-card-header> -->
            <!--<div mat-card-avatar class="example-header-imageMC"></div>-->
            <!-- <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">Notes on Financial Statements&nbsp;&nbsp;&nbsp;<i class="fa fa-pencil fa-lg"  (click)="fnModalEdit('MarketsCustomers')" title="Edit labels of Markets Customers View" style="color:green;position:relative; top:4px"></i></mat-card-title> -->
            <!-- <mat-card-subtitle>&nbsp;</mat-card-subtitle>
            </mat-card-header> -->
            
            <!--<mat-card-content>
                <img mat-card-image src="../../../assets/marketbk.jpg" alt="Actually View">
            <p>
                Page to manage Markets Customers.
            </p>
            </mat-card-content>-->
            <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">
                Notes on Financial Statements
            </mat-card-title>
        </mat-card>
    </div>

    <!--
    <div class="col-sm-2 col-md-2 col-lg-2" >
            
        <mat-card class="example-card" style="background-color:#4EA7C8;border-width: 40px;height: 100px !important;cursor: pointer;text-align: center;" (click)="fnModalEdit('Checklist')">
            <mat-card-header>
            <!- -<div mat-card-avatar class="example-header-imageMF"></div>- ->
            <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">Checklist<!--&nbsp;<i class="fa fa-pencil fa-lg"  (click)="fnModalEdit('Marketfacilitator')" title="Edit labels of Market Facilitators master sheet" style="color:green;position:relative; top:4px"></i>- -></mat-card-title>
            <mat-card-subtitle>&nbsp;</mat-card-subtitle>
            </mat-card-header>
            
            <!- -<mat-card-content>
                <img mat-card-image src="../../../assets/bkmanage.png" alt="Actually View">
            <p>
                Page to manage Market (Facilitator master sheet) View.
            </p>
            </mat-card-content>- ->
        </mat-card>
    </div>
    -->
    <div class="col-sm-2 col-md-2 col-lg-2" >
            
        <mat-card class="example-card" style="background-color:#40874D;border-width: 40px;height: 100px !important;cursor: pointer;text-align: center;" (click)="fnModalEdit('Monthly Debrief/Results')">
            <!-- <mat-card-header> -->
            <!--<div mat-card-avatar class="example-header-imageIO"></div>-->
            <!-- <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">Monthly Debrief/Results<i class="fa fa-pencil fa-lg"  (click)="fnModalEdit('Incomestatement')" title="Edit labels of Income statement View" style="color:green;position:relative; top:4px"></i></mat-card-title> -->
            <!-- <mat-card-subtitle>&nbsp;</mat-card-subtitle>
            </mat-card-header> -->
            
            <!--<mat-card-content>
                <img mat-card-image src="../../../assets/iobk.png" alt="Actually View">
            <p>
                Page to manage Income Statement View.
            </p>
            </mat-card-content>-->
            <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">
                Monthly Debrief/Results
            </mat-card-title>
        </mat-card>
    </div>
    <div class="col-sm-2 col-md-2 col-lg-2">
            
        <mat-card class="example-card" style="background-color:#925D9C;border-width: 40px;height: 100px !important;cursor: pointer;text-align: center;" (click)="fnModalEdit('Markets')">
            <!-- <mat-card-header> -->
            <!--<div mat-card-avatar class="example-header-image"></div>-->
            <!-- <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">Markets&nbsp;&nbsp;&nbsp;<i class="fa fa-pencil fa-lg"  (click)="fnModalEdit('RatioSlides')" title="Edit labels of Ratio Slides view" style="color:green;position:relative; top:4px"></i></mat-card-title> -->
            <!-- <mat-card-subtitle>&nbsp;</mat-card-subtitle>
            </mat-card-header> -->
            
            <!--<mat-card-content>
                <img mat-card-image src="../../../assets/bkmanage.png" alt="Actually View">
            <p>
                Page to Ratio Slides.
            </p>
            </mat-card-content>-->
            <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">
                Markets
            </mat-card-title>
        </mat-card>
    </div>

    </div>
<br><br>
    <div class="row" *ngIf="newIdLanguage > 0">
         <!-- view-->  

         <div class="col-sm-2 col-md-2 col-lg-2">
            
            <mat-card class="example-card" style="background-color:#40874D;border-width: 40px;height: 100px !important;cursor: pointer;text-align: center;" (click)="fnModalEdit('Learning Slides')">
                <!-- <mat-card-header> -->
                <!--<div mat-card-avatar class="example-header-imageSU"></div>-->
                <!-- <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">Learning Slides&nbsp;&nbsp;&nbsp;<i class="fa fa-pencil fa-lg"  (click)="fnModalEdit('DebriefTab')" title="Edit labels of Debrief Tab" style="color:green;position:relative; top:4px"></i></mat-card-title> -->
                <!-- <mat-card-subtitle>&nbsp;</mat-card-subtitle>
                </mat-card-header> -->
                
                <!--<mat-card-content>
                    <img mat-card-image src="../../../assets/bkmanage.png" alt="Actually View">
                <p>
                    Manage labels of Debrief Tab.
                </p>
                </mat-card-content>-->
                <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">
                    Learning Slides
                </mat-card-title>
            </mat-card>
        </div>
        <!-- -->
        <div class="col-sm-2 col-md-2 col-lg-2" >
                
            <mat-card class="example-card" style="background-color:#5ea0cc;border-width: 40px;height: 100px !important;cursor: pointer;text-align: center;" (click)="fnModalEdit('Improvement Options')">
                <!-- <mat-card-header> -->
                <!--<div mat-card-avatar class="example-header-imageMC"></div>-->
                <!-- <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">Improvement Options&nbsp;&nbsp;&nbsp;<i class="fa fa-pencil fa-lg"  (click)="fnModalEdit('PublicInfo')" title="Edit labels of  Public Info View" style="color:green;position:relative; top:4px"></i></mat-card-title> -->
                <!-- <mat-card-subtitle>&nbsp;</mat-card-subtitle>
                </mat-card-header> -->
                
                <!--<mat-card-content>
                    <img mat-card-image src="../../../assets/bkmanage.png" alt="Actually View">
                <p>
                    Page to manage Public Info view.
                </p>
                </mat-card-content>-->
                <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">
                    Improvement Projects
                </mat-card-title>
            </mat-card>
        </div>
        
        <div class="col-sm-2 col-md-2 col-lg-2" >
                
            <mat-card class="example-card" style="background-color:#6FB45C;border-width: 40px;height: 100px !important;cursor: pointer;text-align: center;" (click)="fnModalEdit('Rules')">
                <!-- <mat-card-header> -->
                <!--<div mat-card-avatar class="example-header-imageIO"></div>-->
                <!-- <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">Rules&nbsp;&nbsp;&nbsp;<i class="fa fa-pencil fa-lg"  (click)="fnModalEdit('ResultsView')" title="Edit labels of Results View" style="color:green;position:relative; top:4px"></i></mat-card-title> -->
                <!-- <mat-card-subtitle>&nbsp;</mat-card-subtitle>
                </mat-card-header> -->
                
               <!-- <mat-card-content>
                    <img mat-card-image src="../../../assets/iobk.png" alt="Actually View">
                <p>
                    Page to manage labels of Results View.
                </p>
                </mat-card-content>-->
                <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">
                    Rules
                </mat-card-title>
            </mat-card>
        </div>
        
        
        <div class="col-sm-2 col-md-2 col-lg-2" >
                
            <mat-card class="example-card" style="background-color:#3dbea2;border-width: 40px;height: 100px !important;cursor: pointer;text-align: center;" (click)="fnModalEdit('Messages')">
                <!--<mat-card-header>
                <div mat-card-avatar class="example-header-imageIO"></div>
                <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">Working Copy for Improvement Options &nbsp;&nbsp;&nbsp;<i class="fa fa-pencil fa-lg"  (click)="fnModalEdit('ResultsView')" title="Edit labels of Results View" style="color:green;position:relative; top:4px"></i></mat-card-title>
                <mat-card-subtitle>&nbsp;</mat-card-subtitle>
                </mat-card-header>
                
                <mat-card-content>
                    <img mat-card-image src="../../../assets/iobk.png" alt="Actually View">
                <p>
                    Page to manage labels of Results View.
                </p>
                </mat-card-content>-->
                <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">
                    Messages
                </mat-card-title>
            </mat-card>
        </div>
        <div class="col-sm-2 col-md-2 col-lg-2" >
                
            <mat-card class="example-card" style="background-color:#4EA7C8;border-width: 40px;height: 100px !important;cursor: pointer;text-align: center;" (click)="fnModalEdit('Checklist')">
                <!--<mat-card-header>
                <div mat-card-avatar class="example-header-imageIO"></div>
                <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">Working Copy for Improvement Options &nbsp;&nbsp;&nbsp;<i class="fa fa-pencil fa-lg"  (click)="fnModalEdit('ResultsView')" title="Edit labels of Results View" style="color:green;position:relative; top:4px"></i></mat-card-title>
                <mat-card-subtitle>&nbsp;</mat-card-subtitle>
                </mat-card-header>
                
                <mat-card-content>
                    <img mat-card-image src="../../../assets/iobk.png" alt="Actually View">
                <p>
                    Page to manage labels of Results View.
                </p>
                </mat-card-content>-->
                <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">
                    Checklist
                </mat-card-title>
            </mat-card>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" ></div>


    </div>

<br><br>
<br><br>
</div>


  

<button type="button" class="btn btn-primary" id="btnExampleModal" data-toggle="modal" data-target="#exampleModal"   data-backdrop="static" data-keyboard="false" hidden></button>
<!--app-editlabels [strTypeView]="strTypeView"  (funcionOut)="fnSaveNew($event)"></app-editlabels-->
