import { Component, OnInit,Inject, } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { ContentObserver } from '@angular/cdk/observers';
import { element } from 'protractor';
import Swal from 'sweetalert2';
import { UsuarioService } from '../../../services/usuario/usuario.service';

@Component({
  selector: 'app-view-auth-user-board',
  templateUrl: './view-auth-user-board.component.html',
  styleUrls: ['./view-auth-user-board.component.scss']
})
export class ViewAuthUserBoardComponent implements OnInit {

  selectedpeople= [];
  awesomepeople = [];

  constructor(
    public matDialogRef: MatDialogRef<ViewAuthUserBoardComponent>,
    private usuarioService: UsuarioService,
    @Inject(MAT_DIALOG_DATA) private _data: any
  ) {
    console.log('this._data', this._data.usersList);
    this._data.usersList.forEach(element => {
      element.completedname = element.FirstName + " " + element.LastName;
      if (element.TeamId !== 1) {
        this.awesomepeople.push(element);
      }
    });
  }
  

  ngOnInit(): void {
    console.log("entre ViewAuthUserBoardComponent");
  }


  async fnSelectPerson(){
    console.log("entra fn person", this.selectedpeople);
    var jsnUserSelct = this.awesomepeople.filter(el=>{
      if(el.completedname == this.selectedpeople)//view board
        return el;
    });

      //significa que el usuario es un facilitador
      if((jsnUserSelct[0].type == 2) ||  (jsnUserSelct[0].type == 6 && jsnUserSelct[0].IsFacilitator == 1) || (jsnUserSelct[0].type == 1 && jsnUserSelct[0].IsFacilitator == 1)){
        const { value: strPassUserSlected, isConfirmed: isConfirmed } = await Swal.fire({
          title: 'Confirm',
          input: 'password',
          text: "214.- This account is for the facilitator, an access password is required",
          inputValue: '',
          showCancelButton: true,
          confirmButtonText: 'Validate',
          cancelButtonText: 'Cancel',
          /*inputValidator: (value) => {
            console.log("xd value validator:", value);
          }*/
        });
        if(isConfirmed && strPassUserSlected !=""){
          this.usuarioService
          .validateFacilitatorPasswordFromBoard(jsnUserSelct[0].UserID, strPassUserSlected)
          .subscribe((response: any) => {
                console.log('respueta valida facilitator: ', response);
                if (response.intResponse == 200) {
                  if(response.UserID > 0){
                    this.matDialogRef.close({
                      jsnUserSelected: jsnUserSelct
                    });
                  }else{
                    Swal.fire({
                      html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">215.- the password entered is incorrect, please try again.</label>`,
                      imageUrl: '../../../../assets/iconPrevention.svg',
                      showCancelButton: false,
                      confirmButtonColor: '#6FB45C',
                      cancelButtonColor: '#ED5050',
                      confirmButtonText: 'Ok!'
                    })

                  }
                  
                
              } else {
                Swal.fire(
                  'Error!',
                  '216.- Error with connection, try later.',
                  'error'
                );
              }
            });


        }else{ 
          return;
        }
        console.log("valores faciliatdor:",isConfirmed,strPassUserSlected );

      }else{
        //en member, solicitamos el correo para validar que sea el mismo
        const { value: strMailMember, isConfirmed: isConfirmed } = await Swal.fire({
          title: 'Confirm',
          input: 'text',
          text: "To continue enter your email",//text: "217.- To continue enter your email",
          inputValue: '',
          showCancelButton: true,
          confirmButtonText: 'Validate',
          cancelButtonText: 'Cancel',
          /*inputValidator: (value) => {
            console.log("xd value validator:", value);
          }*/
        });
        if(isConfirmed){
            //validamos si el mail existe
            if(strMailMember.toString().toLowerCase() == jsnUserSelct[0].Email.toString().toLowerCase())
            {
              this.matDialogRef.close({
                jsnUserSelected: jsnUserSelct
              });
            }
            else{
              Swal.fire({
                text: '',
                icon: 'error',
                html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">The email entered does not correspond to the selected user, please correct it and try again.</label>`,//218.- The email entered does not correspond to the selected user, please correct it and try again.
              });
            }

        }else{ 
          return;
        }

         
      }
      
    
    
  }


}
