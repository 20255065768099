
   
<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle  >
    

<div mat-dialog-title style="border-bottom: 1px solid #f3f3f3; width: 100% !important; text-align: center; margin: 0 0 10px;">
    &nbsp; &nbsp; &nbsp; &nbsp;<label *ngIf="blnHas4Cycles" class="style1">{{getSingleTextLabel('2011')}}</label>
    <label class="style1" *ngIf="!blnHas4Cycles">{{getSingleTextLabel('2011')}}</label>
</div>
<mat-dialog-content style="    display: block;
margin: 0 -24px;
padding: 0 24px;
max-height: fit-content;
height: 89%;overflow: hidden;
-webkit-overflow-scrolling: touch;">
    <div style="text-align: -webkit-center;">
        <div style="display: inline-flex; width: 750px;">
            <div class="style5a calibriFont" [ngClass]="businessModel === 'Mfg EBIT' ? 'textTable' : 'textTableLCR'">
                {{ businessModel === 'Mfg EBIT' ? getSingleTextLabel('90') : businessModel === 'S&S EBITDA' || businessModel === 'S&S EBITDA-B'  ? getSingleTextLabel('88062.1') : getSingleTextLabel('88062') }}
            </div>              
            <div class="textTable style5a calibriFont">{{getSingleTextLabel('91')}}</div>
            <div class="textTable style5a calibriFont">
                {{ businessModel === 'S&S EBITDA' || businessModel === 'S&S EBITDA-B' ? getSingleTextLabel('88062.2') : getSingleTextLabel('92')}}
            </div>
            <div class="textTable style5a calibriFont">{{getSingleTextLabel('93')}}</div>
            <div class="textTable style5a calibriFont">{{getSingleTextLabel('94')}}</div>
        </div>
        <br>
        <div style="display: inline-flex;">
            <div>
                <mat-checkbox class="textTableCheck w-table1 style2a calibriFont" (change)="fnCheckBoxChange(jsonCheck)" 
                    *ngFor="let jsonCheck of arrCheckBoxes" [(ngModel)]="jsonCheck.selected" [disabled]="month === 4 || (arrImprovements.length == 7 && jsonCheck.id == 1)">
                    {{jsonCheck['title']}}
                </mat-checkbox>
            </div>
        </div>
        <br>
    </div>
    <hr class="solid">
    <div style="height: 20px;">
        <div style="text-align: center;">
            <label class="style3 calibriFont">Results</label>

            <button class=" btn btn-primary btn-primary2 " (click)="updateView()" style="margin: 3px 42px;">Refresh</button>

        </div>
        <br>
        <!-- <div style="text-align: center;">
            <label class="style3 calibriFont" style="color: #26ac34; padding-right: 50px;">Complete complete</label>
            <label class="style3 calibriFont" style="color: #a33dff; padding-left: 50px;">Incomplete complete</label>
        </div> -->
    </div>
    <div class="mt-2">
        <table *ngIf="arrImprovements.length > 0">
            <tr>
                <th> </th>
                <th class="textTableCheck style2a calibriFont" *ngFor="let improvement of arrImprovements; let i = index">
                    {{improvement['Avatar']}}
                </th>
            </tr>
            <ng-container *ngFor="let option of arrImprovements[0]['options']; let j = index" >
                <tr>
                    <td class="textTableCheck style5a calibriFont">Option</td>
                    <td class="style5a calibriFont" *ngFor="let improvement of arrImprovements; let i = index" style="text-align: center;">
                        <label class="textTableCheck1 style2a calibriFont"  *ngIf="(improvement['options'][j]['Option'] > 0 && improvement['options'][j]['Status'] === 2 && improvement['options'][j]['isQuestion'] == 1) && improvement['Status'] >= 3">Team does not not want to </label> <br>
                        <label class="textTableCheck1 style2a calibriFont"  *ngIf="(improvement['options'][j]['Option'] > 0 && improvement['options'][j]['Status'] === 2 && improvement['options'][j]['isQuestion'] == 1) && improvement['Status'] >= 3">implement a project this month</label>
                        <br>
                        <!-- <i class="fas fa-check icon-option" [ngClass]="{'selected':  improvement['options'][j]['Status'] === 2}"
                        *ngIf="(improvement['options'][j]['Option'] > 0 && improvement['options'][j]['Status'] === 2) || improvement['options'][j]['Option'] === 6" 
                        style="color: #019e11;" (click)="fnApproveImprovement(improvement['TeamId'], improvement['WorkShopId'], improvement['options'][j], 2)"></i>
                        <i class="fas fa-times icon-option" 
                        *ngIf="(improvement['options'][j]['Option'] > 0 && improvement['options'][j]['Status'] === 2)  || improvement['options'][j]['Option'] === 6" 
                        style="color: #cf2a28;" (click)="fnApproveImprovement(improvement['TeamId'], improvement['WorkShopId'], improvement['options'][j], 1)"></i> -->
                        <button class=" btn btn-primary " style="background-color: #019e11; padding: 1.5px; margin: 3px; font-size: 13px;" (click)="fnApproveImprovement(improvement['TeamId'], improvement['WorkShopId'], improvement['options'][j], 2)"
                        *ngIf="(improvement['options'][j]['Option'] > 0 && improvement['options'][j]['Status'] === 2 && improvement['options'][j]['isQuestion'] == 1)  && improvement['Status'] >= 3" >Accept</button>
                        <button class=" btn btn-primary " style="background-color: #cf2a28; padding: 1.5px; margin: 3px; font-size: 13px;" (click)="fnApproveImprovement(improvement['TeamId'], improvement['WorkShopId'], improvement['options'][j], 1)"
                        *ngIf="(improvement['options'][j]['Option'] > 0 && improvement['options'][j]['Status'] === 2 && improvement['options'][j]['isQuestion'] == 1)  && improvement['Status'] >= 3" >Reject</button>

                    </td>
                </tr>
                <tr>
                    <td class="textTableCheck style2a calibriFont">Month {{option.Month}}</td>
                    <td class="textTableCheck style2a calibriFont" style="text-align: center;" 
                    [ngStyle]="{'color': improvement['options'][j]['Option'] === 6 ? '#ffb148':(improvement['options'][j]['Status'] === 3 ? '#26ac34' : improvement['options'][j]['Status'] === 2 ? '#a33dff': 'grey')}"
                    *ngFor="let improvement of arrImprovements; let i = index">
                        {{fnGetImprovement(improvement['options'][j]['Option'])}}  
                        <button class="btn btn-primary " style="background-color: #cf2a28; padding: 1.5px; margin: 3px; font-size: 13px; border-color: #cf2a28;" *ngIf="(improvement['Status'] == 0 || improvement['Status'] == -1) && improvement['options'][j]['Month'] == month && fnGetImprovement(improvement['options'][j]['Option']) != 'No Improvement' && improvement['options'][j]['Option'] > 0 && improvement['options'][j]['Status'] != 3" (click)="fnReselectImprovement(improvement['TeamId'], improvement['WorkShopId'], improvement['options'][j])">Undo</button>
                    </td>
                </tr>
            </ng-container>
          </table>
    </div>
    <mat-dialog-actions align="center" class="mat-dialog-actions2">
        <button mat-button class="btn btn-secondary style9 calibriFont mx-3" (click)="matDialogRef.close()">{{getSingleTextLabel('135')}}
        </button>
    </mat-dialog-actions>
</mat-dialog-content>

</div>
