import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-purchase-objects',
  templateUrl: './purchase-objects.component.html',
  styleUrls: ['./purchase-objects.component.scss']
})
export class PurchaseObjectsComponent implements OnInit {

  data: any;
  strGroup: number;
  ButtonsName: string[] = [this.getSingleTextLabel('136'), this.getSingleTextLabel('123')];

  constructor( public matDialogRef: MatDialogRef<PurchaseObjectsComponent>,@Inject(MAT_DIALOG_DATA) private _data: any) { 
              this.data = _data;
              }


  ngOnInit(): void {
  }

  entra(event){
    //console.log(event)
    if(event.keyCode == 13){
      this.fnSave();
    }
  }

  fnSave(){
    if(this.data.input == 'yes'){
      this._data["isConfirmed"] = true;
      this._data["strGroup"] = this.strGroup.toString();
      this.matDialogRef.close({isConfirmed: this._data["isConfirmed"], strGroup: this._data["strGroup"] });
    }else{
      this._data["isConfirmed"] = true;
      this.matDialogRef.close({isConfirmed: this._data["isConfirmed"]});
    }
  }

  fnCancel(){
    this._data["isConfirmed"] = false;
    this.matDialogRef.close({isConfirmed: this._data["isConfirmed"]});
  }

  getSingleTextLabel(labelNumber: string): string {
    const tmp = this._data.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    return tmp ? tmp.Label : '';
  }
  validarInput(event: any) {
    // Obtén el valor actual del input
    let inputValue = event.target.value;

    // Remueve cualquier signo negativo
    inputValue = inputValue.replace(/-/g, '');

    // Asigna el nuevo valor al modelo
    this.strGroup = parseInt(inputValue, 10);
  }
}
