
<!-- confirm-dialog.component.html -->
<mat-tab-group style="margin-bottom: 20px;" [selectedIndex]="mes-auxMes" scrollDistance="300px" (selectedTabChange)="fnChangeMonthHistoric($event['tab']['textLabel'])">
  <mat-tab label="{{getSingleTextLabel('5013')}} {{auxMes+(i)}}" *ngFor="let item of numbers; let i = index;" [disabled]="(i+auxMes) > mes"></mat-tab>
</mat-tab-group>
<div style="display: flex;justify-content: space-between; margin: 0 28px;" >
  <p cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="style1 calibriFont">
    Budget Month
  </p>
  <div class="btnRefresh">
    <button class=" btn btn2 btn-primary " [disabled]="!canUseButtomsTarget" (click)="updateView()">Refresh</button>
  </div>
</div>

  <div class="container">

      <div class="row" >

        <div class="mx-auto" style="width: auto;">

            <!-- <div class="grid-container main">
              <div class="marginRigth style2b calibriFont" style="text-align: center;">{{getSingleTextLabel('111')}}</div>
              <div class="marginRigth style2b calibriFont" style="text-align: center;">{{getSingleTextLabel('2004')}}</div>
              <div class="marginRigth style2b calibriFont" style="text-align: center;">{{getSingleTextLabel('105')}} 1</div>
              <div class="marginRigth style2b calibriFont" style="text-align: center;">{{getSingleTextLabel('105')}} 2</div>
              <div class="marginRigth style2b calibriFont" style="text-align: center;">{{getSingleTextLabel('105')}} 3</div>
              <div class="marginRigth style2b calibriFont" style="text-align: center;">{{getSingleTextLabel('105')}} 4</div>
              <div class="style2b calibriFont" style="text-align: center;">{{getSingleTextLabel('105')}} 5</div>
            </div> -->
            <div class="grid-container main margin">
                <div class="marginRigth style2b calibriFont" style="text-align: center;">Team</div>
                <div class="marginRigth style2b calibriFont" style="text-align: center;">CFO</div>
                <div class="marginRigth style2b calibriFont" style="text-align: center;">Net income</div>
                <div class="marginRigth style2b calibriFont" style="text-align: center;">Actions</div>
              </div>
          
            <div class="grid-container margin" *ngFor="let jsnTeam of arrAllTeams">
              <div class="marginRigth paddingLeft style2a calibriFont">{{jsnTeam['team']['Avatar']}}</div>
          
              <div class="marginRigth" *ngIf="jsnTeam['members']['CFO'].length >0" >
                <div *ngFor="let item of jsnTeam['members']['CFO']" class="style2a paddingLeft calibriFont">{{item.nameComplete}}</div>
              </div>
              <!-- <div class="marginRigth paddingLeft style2a calibriFont"  *ngIf="jsnTeam['members']['CFO'].length == 0"> </div>
              <div class="marginRigth paddingLeft style2a calibriFont"> {{fnResultado(jsnTeam['team']['Simpleb_step'],1)}}</div>
              <div class="marginRigth paddingLeft style2a calibriFont"> {{fnResultado(jsnTeam['team']['Simpleb_step'],2)}}</div>
              <div class="marginRigth paddingLeft style2a calibriFont"> {{fnResultado(jsnTeam['team']['Simpleb_step'],3)}}</div>
              <div class="marginRigth paddingLeft style2a calibriFont"> {{fnResultado(jsnTeam['team']['Simpleb_step'],4)}}</div>
              <div class="style2a paddingLeft calibriFont"> {{fnResultado(jsnTeam['team']['Simpleb_step'],5)}}</div> -->
              <div class="marginRigth paddingLeft style2a calibriFont"  *ngIf="jsnTeam['members']['CFO'].length == 0"> </div>
              <div class="marginRigth paddingLeft style2a calibriFont" style="text-align: center; padding: 0px;"> {{jsnTeam['team']['BudgetCompleteNetIncome'] != null ? jsnTeam['team']['BudgetCompleteNetIncome']:"UnFinished"}} </div>
              <div class="marginRigth paddingLeft style2a calibriFont" > 
                <button [ngClass]="{'grow': jsnTeam['team']['BudgetLock'] == 1}" [disabled]="!canUseButtomsTarget" type="button" class="btn btn-primary style8 calibriFont" style="color: white; margin: 4px;"  (click)="fnUpdateLockBudgetStatus(jsnTeam['team']['teamId'], 1)">Lock Target</button>
                <button [disabled]="!canUseButtomsTarget" type="button" class="btn btn-secondary style9 calibriFont" style="color: white; margin: 4px;" (click)="fnUpdateLockBudgetStatus(jsnTeam['team']['teamId'], 0)">Clear Target</button>
              </div>
            </div>
        </div>
      </div>
      <!-- <div class="row">
          <div class="col-12" style="text-align: right;"> -->
            <!-- <button type="button" class="btn btn-primary mr-2 style8 calibriFont" (click)="fnGetAllTeamsAndMembers()">Refresh</button>
            <button type="button" class="btn btn-primary m-4 style8 calibriFont" style="color: white;"  (click)="fnUpdateLockBudgetStatus(1)">Lock Target</button>
            <button type="button" class="btn btn-secondary style9 calibriFont" style="color: white;" (click)="fnUpdateLockBudgetStatus(0)">Clear Target</button>
          </div>
      </div> -->
  </div>
 
  <mat-dialog-actions align="center">
     <button type="button" class="btn btn-secondary" id="btncloseHide" (click)="matDialogRef.close()">{{getSingleTextLabel('135')}}</button> 
  </mat-dialog-actions> 
  
  <ngx-spinner bdColor="rgba(0,0,0,0.75)" size="large" color="#ffffff" type="ball-clip-rotate-multiple"
      [fullScreen]="false">
      <p style="color: white"> Loading... </p>
  </ngx-spinner>
  <!-- rest of the dialog -->