import { Component, OnInit,Inject, } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { ContentObserver } from '@angular/cdk/observers';
import { element } from 'protractor';
import Swal from 'sweetalert2';
import { UsuarioService } from '../../services/usuario/usuario.service';

@Component({
  selector: 'app-change-language',
  templateUrl: './change-language.component.html',
  styleUrls: ['./change-language.component.scss']
})
export class ChangeLanguageComponent implements OnInit {
  selectedLang= [];
  lstLanguages = [];
  constructor(
    public matDialogRef: MatDialogRef<ChangeLanguageComponent>,
    private usuarioService: UsuarioService,
      @Inject(MAT_DIALOG_DATA) private _data: any
      ) { 
        /*actualLanguage: this.idLenguage,
          lstLanguages: this.lstLanguagesBoard */
        console.log('this._data:', this._data.actualLanguage,this._data.lstLanguages );
        
        if(this._data.actualTeam  != 0)
        {
          console.log("idteam diferente 0");
          this._data.lstLanguages.forEach(element => {
          
            if(element.LanguageId == this._data.actualLanguage){
            element.check = true;
              this.selectedLang = element;
              console.log("encontramos element asignamos a modelo this.lstLanguages:",this.selectedLang)
            }else{
              element.check = false;
            }
            this.lstLanguages.push(element);
            console.log("this.lstLanguages:::", this.lstLanguages);
          });
        }
        
      }

  ngOnInit(): void {
    console.log("entre ViewChangeLanguageComponent");
  }



  async fnSelectLanguage(){
    console.log("this.selectedLang:",this.selectedLang);
    var jsLangSelct = this.lstLanguages.filter(el=>{
      if(el.LanguageId == this.selectedLang)//view board
        return el;
    });
    console.log("entra fn jsLangSelct:",jsLangSelct);

      //significa que el usuario es un facilitador
     
      this.matDialogRef.close({
        jsnLangSelected: jsLangSelct
      });
    
  }

  fnCancel() {
    var jsLangSelct = this.lstLanguages.filter(el=>{
      if(el.TeamId == this.selectedLang)//view board
        return el;
    });
    this.matDialogRef.close({
      jsnLangSelected: jsLangSelct
    });
  }

  getSingleTextLabel(labelNumber: string): string {
    const tmp = this._data.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    return tmp ? tmp.Label : '';
  }
}
