<div class="container">
    <mat-tab-group>
        <mat-tab  *ngFor="let team of lstTeamsBoard; let indexTeam = index" [label]="team.Avatar">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
                        <label for="value" class="">Status <span style="color: red;">*</span> </label>
                        <br>
                        <mat-form-field class="w-100" appearance="fill">
                            <mat-label>Status</mat-label>
                            <input matInput type="number" placeholder="Status" [(ngModel)]="team.Status" />
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
                        <label for="value" class="">Month <span style="color: red;">*</span> </label>
                        <br>
                        <mat-form-field class="w-100" appearance="fill">
                            <mat-label>Month</mat-label>
                            <input matInput type="number" placeholder="Month" [(ngModel)]="team.Month" />
                        </mat-form-field>
                    </div>
                </div>
            </div>
            

            <button class="btn btn-outline-primary" (click)="sendMonthAndStatus(indexTeam)"> Update</button>

            <mat-tab-group>
                <mat-tab label="Board">
                    <form class="container FontMontserratTextInputs" style="padding-left: 10%; padding-right: 10%"
                    [formGroup]="formPiece">
                    <div class="row">
                        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
                            <label for="AreaDrag" class="">Area Draggable <span style="color: red;">*</span>
                            </label>
                            <br>
                            <mat-form-field appearance="fill">
                                <mat-label>Area Drag</mat-label>
                                <mat-select formControlName="AreaDrag"
                                    [ngClass]="{ 'is-invalid': !formPiece.get('AreaDrag').valid }">
                                    <ng-container *ngFor="let area of AREASDRAG; let areaDrag = index">
                                        <mat-option *ngIf="area != ''" [value]="areaDrag+1">
                                            <label>{{area}}</label>
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
                            <label for="value" class="">Value <span style="color: red;">*</span> </label>
                            <br>
                            <mat-form-field class="w-100" appearance="fill">
                                <mat-label>Value</mat-label>
                                <input matInput type="number" formControlName="Valor" max="20"
                                    [ngClass]="{ 'is-invalid': !formPiece.get('Valor').valid }" />
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
                            <label for="email" class="">Type<span style="color: red;">*</span></label>
                            <br>
                            <mat-form-field appearance="fill">
                                <mat-label>Type</mat-label>
                                <mat-select formControlName="tipo"
                                    [ngClass]="{ 'is-invalid': !formPiece.get('tipo').valid }">
                                    <ng-container *ngFor="let type of TYPES; let typeId = index">
                                        <mat-option *ngIf="type != ''" [value]="typeId+1">
                                            <label>{{type}}</label>
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <!-- <button [disabled]="formPiece.invalid" type="button" class="btn btn-outline-primary" (click)="addPieceToBoard(indexTeam)">Add</button> -->
                        <button [disabled]="formPiece.invalid" type="button" class="btn btn-outline-primary" (click)="addPieceSendBD(indexTeam)">Add</button>
                    </div>
                </form>
                <div class="container">
                    <div class="row">
                        <mat-form-field class="w-100" appearance="fill">
                            <mat-label>Search</mat-label>
                            <input matInput type="text" (keyup)="serchArea(indexTeam)" [(ngModel)]="valueInputSerch"/>
                        </mat-form-field>
                    </div>
                    <div class="row" style="align-items: end;" *ngFor="let piece of team.board; let i=index">
                        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Area Drag</mat-label>
                                <mat-select [value]="piece.AreaDrag" disabled
                                    (valueChange)="fnChangePieceAreaDrag(indexTeam, i, $event, piece)">
                                    <ng-container *ngFor="let area of AREASDRAG; let areaDrag = index">
                                        <mat-option *ngIf="area != ''" [value]="areaDrag+1">
                                            <label>{{area}}</label>
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 ">
                        <mat-form-field class="w-100" appearance="fill">
                            <mat-label>Value</mat-label>
                            <input matInput type="number"  placeholder="Value" max="20" [(ngModel)]="piece.Valor" />
                        </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 ">
                            <mat-form-field appearance="fill">
                                <mat-label>Type</mat-label>
                                <mat-select [value]="piece.tipo" disabled
                                    (valueChange)="fnChangePieceType(indexTeam, i, $event, piece)">
                                    <ng-container *ngFor="let type of TYPES; let typeId = index">
                                        <mat-option *ngIf="type != ''" [value]="typeId+1">
                                            <label>{{type}}</label>
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-1 col-lg-1 col-xl-1 ">
                            <!-- <button type="button" class="btn btn-outline-danger" (click)="deletePiece(indexTeam,i, piece)">&nbsp;&nbsp;&nbsp; - &nbsp;&nbsp;&nbsp;</button> -->
                            <button type="button" class="btn btn-outline-danger" style="margin-bottom: 40%;" (click)="deletePieceSendBD(indexTeam,i, piece)"> Delete </button>
                        </div>
                        <div class="col-sm-12 col-md-1 col-lg-1 col-xl-1 ">
                            <button type="button" class="btn btn-outline-primary" style="margin-bottom: 40%;" (click)="modifyPieceSendBD(indexTeam,i, piece)"> Update </button>
                        </div>
                    </div>
                </div>
                </mat-tab>
                <mat-tab label="Check List">
                    <div class="checklist" *ngFor="let check of team.checkList.checkList; let j=index">
                        <mat-checkbox [(ngModel)]="check.valor">{{check.title}}</mat-checkbox>
                    </div>
                    <div>
                        <button class="btn btn-outline-primary" (click)="sendCheckList(indexTeam)"> Update</button>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-tab>
    </mat-tab-group>
</div>


<ngx-spinner bdColor="rgba(0,0,0,0.75)" size="large" color="#ffffff" type="ball-clip-rotate-multiple"
    [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>

<ngx-spinner bdColor="rgba(0,0,0,0.75)" size="large" color="#ffffff" type="ball-clip-rotate-multiple"
    [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>