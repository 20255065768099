import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BoardService } from 'src/app/services/board/board.service';
import { IncomeStatementService } from 'src/app/services/IncomeStatement/IncomeStatement.service';
import { ExerciseService } from 'src/app/services/sockets/exercises/exercise.service';
import { TeamService } from 'src/app/services/team/team.service';
import { WorkshopService } from 'src/app/services/workshop/workshop.service';
import Swal from 'sweetalert2';

export class ModelColumnBudget{
  priceRoyal: number;
  numberRoyals: number;
  totalSale: number;
  costRoyal: number;
  numberRoyals2: number;
  totalCOGS: number;
  contribution: number;
  factoryOverhead: number;
  SGA: number;
  Finance: number;
  totalFiexed: number;
  netIncome: number;

  constructor(){
    this.priceRoyal = null;
    this.numberRoyals = null;
    this.totalSale = null;
    this.costRoyal = null;
    this.totalCOGS = null;
    this.contribution = null;
    this.factoryOverhead = null;
    this.SGA = null;
    this.Finance = null;
    this.totalFiexed = null;
    this.netIncome = null;
  }

  decode(json: any){
    this.priceRoyal = json.priceRoyal;
    this.numberRoyals = json.numberRoyals;
    this.totalSale = json.totalSales;
    this.costRoyal = json.costRoyal;
    this.totalCOGS = json.totalCOGS;
    this.contribution = json.contribution;
    this.factoryOverhead = json.factoryOverhead;
    this.SGA = json.SGA;
    this.Finance = json.Finance;
    this.totalFiexed = json.totalFiexed;
    this.netIncome = json.netIncome;
  }

  isComplete():boolean{
    return (this.priceRoyal != null && this.numberRoyals != null && this.totalSale != null && this.costRoyal != null && this.totalCOGS != null &&
      this.contribution != null && this.factoryOverhead != null && this.SGA != null && this.Finance != null && this.totalFiexed != null && this.netIncome != null);
  }
}

@Component({
  selector: 'app-dialog-budget-exercise',
  templateUrl: './dialog-budget-exercise.component.html',
  styleUrls: ['./dialog-budget-exercise.component.scss']
})
export class DialogBudgetExerciseComponent implements OnInit, OnDestroy {
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  destroy$: Subject<boolean> = new Subject<boolean>();
  arrColumnas = [];
  monthHistoric = 0;
  isfinished = false;
  numbers = [];
  jsonLabels = [];
  mes: number = 0;
  canEdit = true;
  forecastExc : number = 0
  cycles : number = 0
  businessModel = "Mfg EBIT";
  auxMes : number = 0
  auxNumber : number = 0
  BudgetLock : number = 0;
  datos = {
    topDownApproch: new ModelColumnBudget,
    bottomUpApproch: new ModelColumnBudget,
    Target: new ModelColumnBudget,
    Actual: new ModelColumnBudget,
    Raw: new ModelColumnBudget,
    PorCiento: new ModelColumnBudget,
  }
  canEditTarget = false;
  dataIncomestatement;
  orders = [];
  teamId = 0;
  workshopId: number;
  statusBoard: number;
  constructor(
    public matDialogRef: MatDialogRef<DialogBudgetExerciseComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private incomestatementService: IncomeStatementService,
    private boardService: BoardService,
    private workshopService: WorkshopService,
    private exerciseService: ExerciseService,
    private teamService : TeamService
  ) {
    this.mes = this.data.month;
    this.monthHistoric = this.mes;
    this.teamId = this.data.teamId;
    this.workshopId = this.data.workshopId;
    this.statusBoard = this.data.status;
    this.forecastExc = this.data.forecastExc
    this.cycles = this.data.cycles
    this.businessModel = this.data.businessModel
    console.log(this.teamId);
    console.log(this.businessModel);
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
    this.exerciseService.closeListener();
  }

  async ngOnInit(): Promise<void> {
    switch (this.cycles) {
      case 4:
        this.numbers = Array(1)
        this.auxMes = 4
        break;
      case 5:
        this.numbers = this.forecastExc == 1 ? Array(2) : Array(1)
        this.auxMes = this.forecastExc == 1 ? 4 : 5
        break;
      case 6:
        this.numbers = this.forecastExc == 1 ? Array(3) : Array(2)
        this.auxMes = this.forecastExc == 1 ? 4 : 5
        break;
    
      default:
        break;
    }
    if(this.mes>this.cycles){
      this.isfinished = true;
      this.isfinishedWS()
      return;
    }
    const responseData = await this.exerciseService.getSaveQuantitiesTargetColumn(this.teamId, this.mes);
    console.log(responseData)
    if(responseData.intResponse == 200 && responseData.data !=null){
      let aux = new ModelColumnBudget();
      aux.decode(responseData.data);
      this.datos.Target = aux;
    }else{
      let data = {"priceRoyal" : null,
        "numberRoyals" : null,
        "totalSale" : null,
        "costRoyal" : null,
        "totalCOGS" : null,
        "contribution" : null,
        "factoryOverhead" : null,
        "SGA" : null,
        "Finance" :null,
        "totalFiexed" : null,
        "netIncome": null,}
      let aux = new ModelColumnBudget();
      aux.decode(data);
      this.datos.Target = aux;
    }
    //Get top
    const responseDataTop = await this.exerciseService.getSaveQuantitiesTopColumn(this.teamId, this.mes);
    console.log(responseDataTop)
    if(responseDataTop.intResponse == 200 && responseDataTop.data !=null){
      let aux = new ModelColumnBudget();
      aux.decode(responseDataTop.data);
      this.datos.topDownApproch = aux;
    }else{
      let data = {"priceRoyal" : null,
        "numberRoyals" : null,
        "totalSale" : null,
        "costRoyal" : null,
        "totalCOGS" : null,
        "contribution" : null,
        "factoryOverhead" : null,
        "SGA" : null,
        "Finance" :null,
        "totalFiexed" : null,
        "netIncome": null,}
      let aux = new ModelColumnBudget();
      aux.decode(data);
      this.datos.topDownApproch = aux;
    }
    //get bot
    const responseDataBot = await this.exerciseService.getSaveQuantitiesBotColumn(this.teamId, this.mes);
    console.log(responseDataBot)
    if(responseDataBot.intResponse == 200 && responseDataBot.data !=null){
      let aux = new ModelColumnBudget();
      aux.decode(responseDataBot.data);
      this.datos.bottomUpApproch = aux;
    }else{
      let data = {"priceRoyal" : null,
        "numberRoyals" : null,
        "totalSale" : null,
        "costRoyal" : null,
        "totalCOGS" : null,
        "contribution" : null,
        "factoryOverhead" : null,
        "SGA" : null,
        "Finance" :null,
        "totalFiexed" : null,
        "netIncome": null,}
      let aux = new ModelColumnBudget();
      aux.decode(data);
      this.datos.bottomUpApproch = aux;
    }
    let responseWork: any = await this.workshopService.getWorkshopById(this.workshopId).toPromise();
    this.canEditTarget = responseWork.data[0].BudgetLock == 0;
    const x = await this.obtenerDatosIncomestatement();
    this.teamService.getTeamById(this.teamId).subscribe(res =>{
      console.log(res );
      if(res.intResponse == 200){
        if(res.team.BudgetLock == 1){
          console.log("vamos a bloquear");
          this.BudgetLock = 1;
          this.canEditTarget = false;
        }else if (res.team.BudgetLock == 0){
          console.log("vamos a desbloquear y borrar");
          // this.datos.Target = new ModelColumnBudget();
          this.canEditTarget = true;
          this.BudgetLock = 0;
        }
      }
    })
    
    setTimeout(() => {
      this.llenarDatos();
      this.initSocket();
      }, 500);
    
  }
  async isfinishedWS(){
    const responseData = await this.exerciseService.getSaveQuantitiesTargetColumn(this.teamId, this.mes-1);
    console.log(responseData)
    if(responseData.intResponse == 200 && responseData.data !=null){
      let aux = new ModelColumnBudget();
      aux.decode(responseData.data);
      this.datos.Target = aux;
    }else{
      let data = {"priceRoyal" : null,
        "numberRoyals" : null,
        "totalSale" : null,
        "costRoyal" : null,
        "totalCOGS" : null,
        "contribution" : null,
        "factoryOverhead" : null,
        "SGA" : null,
        "Finance" :null,
        "totalFiexed" : null,
        "netIncome": null,}
      let aux = new ModelColumnBudget();
      aux.decode(data);
      this.datos.Target = aux;
    }
    //Get top
    const responseDataTop = await this.exerciseService.getSaveQuantitiesTopColumn(this.teamId, this.mes-1);
    console.log(responseDataTop)
    if(responseDataTop.intResponse == 200 && responseDataTop.data !=null){
      let aux = new ModelColumnBudget();
      aux.decode(responseDataTop.data);
      this.datos.topDownApproch = aux;
    }else{
      let data = {"priceRoyal" : null,
        "numberRoyals" : null,
        "totalSale" : null,
        "costRoyal" : null,
        "totalCOGS" : null,
        "contribution" : null,
        "factoryOverhead" : null,
        "SGA" : null,
        "Finance" :null,
        "totalFiexed" : null,
        "netIncome": null,}
      let aux = new ModelColumnBudget();
      aux.decode(data);
      this.datos.topDownApproch = aux;
    }
    //get bot
    const responseDataBot = await this.exerciseService.getSaveQuantitiesBotColumn(this.teamId, this.mes-1);
    console.log(responseDataBot)
    if(responseDataBot.intResponse == 200 && responseDataBot.data !=null){
      let aux = new ModelColumnBudget();
      aux.decode(responseDataBot.data);
      this.datos.bottomUpApproch = aux;
    }else{
      let data = {"priceRoyal" : null,
        "numberRoyals" : null,
        "totalSale" : null,
        "costRoyal" : null,
        "totalCOGS" : null,
        "contribution" : null,
        "factoryOverhead" : null,
        "SGA" : null,
        "Finance" :null,
        "totalFiexed" : null,
        "netIncome": null,}
      let aux = new ModelColumnBudget();
      aux.decode(data);
      this.datos.bottomUpApproch = aux;
    }
    let responseWork: any = await this.workshopService.getWorkshopById(this.workshopId).toPromise();
    this.canEditTarget = responseWork.data[0].BudgetLock == 0;
    this.canEditTarget = false;
    const x = await this.obtenerDatosIncomestatementWhenFinished();
    if(x){
      this.llenarDatos();
    }
  }
  initSocket(){
    this.exerciseService.setupSocketConnection();
    this.exerciseService._budget.pipe(takeUntil(this.destroy$)).subscribe(response => {
      if(response){
        if(response.status == 'updateInputBudgetServer'){
          this.datos[response.data.column][response.data.row] = response.data.value;
          if(response.data.resultado != -1){
            this.fnResultado(response.data.resultado);
          }
        } else if(response.status == 'notifyFacilitatorBudgetServer'){
          // Swal.fire('Team Finished',`The team ${response.data.teamId} has finished your budget excersice`, 'success');
        } else if(response.status == 'updateBudgetLockStatus'){
          if(response.data.status == 0){
            if(this.teamId == response.data.teamId){
              this.BudgetLock = 0;
              this.tabGroup.selectedIndex = this.mes-this.auxMes;
              console.log(this.mes-this.auxMes)
              console.log("vamos a desbloquear y borrar", response.data);
              this.datos.Target = new ModelColumnBudget();
              this.canEditTarget = true;
            }else{
              console.log("No toca");
            }
            
          } else if(response.data.status == 1){
            if(this.teamId == response.data.teamId){
              console.log("vamos a bloquear", response.data);
              this.BudgetLock = 1;
              this.tabGroup.selectedIndex = this.mes-this.auxMes;
              console.log(this.mes-this.auxMes)
              this.canEditTarget = false;
              }else{
              console.log("No toca");
            }
          }
        }
      }
    })
  }
  obtenerDatosIncomestatementWhenFinished(){
    return new Promise((resolve) => {
      this.incomestatementService.getCantidadesLlenado(this.teamId).subscribe((response) => {
        console.log(response);
        this.dataIncomestatement = response['data'][this.monthHistoric-2];
        console.log(this.dataIncomestatement);
        this.boardService.getOrdersByTeamId(this.teamId,this.monthHistoric-1).subscribe(response => {
          console.log(response)
          if(response.intResponse == 200){
            this.orders = response.orders as Array<any>;
            let totRoyals = 0;
            this.orders.forEach(item => {
              totRoyals += item.Quantity;
            });
                this.datos.Actual.numberRoyals = totRoyals;
                console.log(this.datos.Actual.numberRoyals);

            
          }
          resolve(true);
        });
      })
    })
  }
  obtenerDatosIncomestatement(){
    return new Promise((resolve) => {
      this.incomestatementService.getCantidadesLlenado(this.teamId).subscribe((response) => {
        console.log(response);
        if(this.statusBoard == 15){
          this.dataIncomestatement = response['data'][this.monthHistoric-1];
          console.log(this.dataIncomestatement);
          this.boardService.getOrdersByTeamId(this.teamId,this.monthHistoric).subscribe(response => {
            console.log(response)
            if(response.intResponse == 200){
              this.orders = response.orders as Array<any>;
              let totRoyals = 0;
              this.orders.forEach(item => {
                totRoyals += item.Quantity;
              });
                if(this.mes >= 4){
                  this.datos.Actual.numberRoyals = totRoyals;
                  console.log(this.datos.Actual.numberRoyals);

              }
            }
            resolve(true);
          });
        }else{
          if(this.monthHistoric < this.mes ){
            this.dataIncomestatement = response['data'][this.monthHistoric-1];
            this.boardService.getOrdersByTeamId(this.teamId,this.monthHistoric).subscribe(response => {
              console.log(response)
              if(response.intResponse == 200){
                this.orders = response.orders as Array<any>;
                let totRoyals = 0;
                this.orders.forEach(item => {
                  totRoyals += item.Quantity;
                });
                
                  if(this.mes > 4){
                    this.datos.Actual.numberRoyals = totRoyals;
    
                  }
                
              }
              resolve(true);
            });
          }else{
            this.dataIncomestatement = response['data'][this.mes-2];
            this.boardService.getOrdersByTeamId(this.teamId,this.mes-1).subscribe(response => {
              if(response.intResponse == 200){
                this.orders = response.orders as Array<any>;
                let totRoyals = 0;
                this.orders.forEach(item => {
                  totRoyals += item.Quantity;
                });
                if(this.statusBoard == 0 || this.statusBoard == -1){
                  if(this.mes > 4){
                    // this.datos.Actual.numberRoyals = totRoyals;
    
                  }
                }
              }
              resolve(true);
            });
          }
          
        }

      });
    });
  }

  llenarDatos(){
    if(this.statusBoard == 15 || (this.monthHistoric < this.mes) || (this.isfinished)){
      if(this.mes > 4 || (this.mes == 4 && this.statusBoard == 15) || (this.isfinished)){
        console.log(this.dataIncomestatement.total)
        this.datos.Actual.totalSale = this.dataIncomestatement.total;
        console.log(this.datos.Actual.totalSale)
        console.log(this.datos.Actual.numberRoyals)
        console.log(this.datos.Actual.numberRoyals)
        console.log(this.datos.Actual.numberRoyals != 0 ? this.datos.Actual.numberRoyals : 1)
        console.log(Number ((this.datos.Actual.totalSale / (this.datos.Actual.numberRoyals != 0 ? this.datos.Actual.numberRoyals : 1)).toFixed(1)))

        this.datos.Actual.priceRoyal = Number ((this.datos.Actual.totalSale / (this.datos.Actual.numberRoyals != 0 ? this.datos.Actual.numberRoyals : 1)).toFixed(1));
        this.datos.Actual.totalCOGS = this.dataIncomestatement.costGood;
        this.datos.Actual.costRoyal = Number ((this.datos.Actual.totalCOGS / (this.datos.Actual.numberRoyals != 0 ? this.datos.Actual.numberRoyals : 1)).toFixed(1));
        this.datos.Actual.contribution = this.dataIncomestatement.contribution;
        this.datos.Actual.factoryOverhead = this.dataIncomestatement.factory;
        this.datos.Actual.SGA = this.dataIncomestatement.selling;
        this.datos.Actual.Finance = this.dataIncomestatement.interest;
        this.datos.Actual.totalFiexed = 
        this.datos.Actual.factoryOverhead +
        this.datos.Actual.SGA +
        this.datos.Actual.Finance;
        this.datos.Actual.netIncome = this.dataIncomestatement.netIncome
        console.log(this.datos.Actual);
      }
      if(this.statusBoard == 15 || (this.monthHistoric < this.mes) || (this.isfinished)){
        console.log(this.datos.Raw)
        this.datos.Raw.priceRoyal = Number((this.datos.Actual.priceRoyal - this.datos.Target.priceRoyal).toFixed(1));
      this.datos.Raw.costRoyal = Number ((this.datos.Actual.costRoyal - this.datos.Target.costRoyal).toFixed(1));
      this.datos.Raw.totalSale = this.datos.Actual.totalSale - (this.datos.Target.totalSale ?? 0);
      console.log('raw tot sales',this.datos.Raw.totalSale, this.datos.Actual.totalSale, this.datos.Target.totalSale);
      //NaN 100 Undefined
      console.log('types',typeof(this.datos.Raw.totalSale), typeof(this.datos.Actual.totalSale), typeof(this.datos.Target.totalSale));
      //Number Number Undefined
      this.datos.Raw.numberRoyals = this.datos.Actual.numberRoyals - this.datos.Target.numberRoyals;
      this.datos.Raw.totalCOGS = this.datos.Actual.totalCOGS - this.datos.Target.totalCOGS;
      this.datos.Raw.contribution = this.datos.Actual.contribution - this.datos.Target.contribution;
      this.datos.Raw.factoryOverhead = this.datos.Actual.factoryOverhead - this.datos.Target.factoryOverhead;
      this.datos.Raw.SGA = this.datos.Actual.SGA - this.datos.Target.SGA;
      this.datos.Raw.Finance = this.datos.Actual.Finance - this.datos.Target.Finance;
      this.datos.Raw.totalFiexed = this.datos.Actual.totalFiexed - this.datos.Target.totalFiexed;
      this.datos.Raw.netIncome = this.datos.Actual.netIncome - this.datos.Target.netIncome;
      console.log(this.datos.Raw);

      console.log(this.datos.PorCiento)
  
      this.datos.PorCiento.priceRoyal =Number (((this.datos.Raw.priceRoyal / this.datos.Target.priceRoyal) * 100).toFixed(1));
      console.log(this.datos.PorCiento.priceRoyal)

      this.datos.PorCiento.costRoyal = Number (((this.datos.Raw.costRoyal / this.datos.Target.costRoyal) * 100).toFixed(1));
      console.log(this.datos.PorCiento.costRoyal)

      this.datos.PorCiento.totalSale = Number (((this.datos.Raw.totalSale / this.datos.Target.totalSale) * 100).toFixed(1));
      console.log(this.datos.PorCiento.totalSale)

      this.datos.PorCiento.numberRoyals = Number (((this.datos.Raw.numberRoyals / this.datos.Target.numberRoyals) * 100).toFixed(1));
      console.log(this.datos.PorCiento.numberRoyals)

      this.datos.PorCiento.totalCOGS = Number (((this.datos.Raw.totalCOGS / this.datos.Target.totalCOGS) * 100).toFixed(1));
      console.log(this.datos.PorCiento.totalCOGS)

      this.datos.PorCiento.contribution = Number (((this.datos.Raw.contribution / this.datos.Target.contribution) * 100).toFixed(1));
      console.log(this.datos.PorCiento.contribution)

      this.datos.PorCiento.factoryOverhead = Number (((this.datos.Raw.factoryOverhead / this.datos.Target.factoryOverhead) * 100).toFixed(1));
      console.log(this.datos.PorCiento.factoryOverhead)

      this.datos.PorCiento.SGA = Number (((this.datos.Raw.SGA / this.datos.Target.SGA) * 100).toFixed(1));
      console.log(this.datos.PorCiento.SGA)
      console.log(this.datos.Raw.Finance,this.datos.Target.Finance)
      this.datos.PorCiento.Finance = Number (((this.datos.Raw.Finance / (this.datos.Target.Finance != 0 ? this.datos.Target.Finance : 1)) * 100).toFixed(1));
      console.log(this.datos.PorCiento.Finance)

      this.datos.PorCiento.totalFiexed = Number (((this.datos.Raw.totalFiexed / this.datos.Target.totalFiexed) * 100).toFixed(1));
      console.log(this.datos.PorCiento.totalFiexed)

      this.datos.PorCiento.netIncome = Number (((this.datos.Raw.netIncome / this.datos.Target.netIncome) * 100).toFixed(1));
      console.log(this.datos.PorCiento.netIncome)

      console.log(this.datos.PorCiento)

      this.datos.Raw.Finance = isNaN(this.datos.Raw.Finance) || !isFinite(this.datos.Raw.Finance) ? 0 : this.datos.Raw.Finance;
      this.datos.Raw.priceRoyal = isNaN(this.datos.Raw.priceRoyal) || !isFinite(this.datos.Raw.priceRoyal) ? 0 : this.datos.Raw.priceRoyal;
      this.datos.Raw.costRoyal = isNaN(this.datos.Raw.costRoyal) || !isFinite(this.datos.Raw.costRoyal) ? 0 : this.datos.Raw.costRoyal;
      this.datos.Raw.totalSale = isNaN(this.datos.Raw.totalSale) || !isFinite(this.datos.Raw.totalSale) ? 0 : this.datos.Raw.totalSale;
      this.datos.Raw.numberRoyals = isNaN(this.datos.Raw.numberRoyals) || !isFinite(this.datos.Raw.numberRoyals) ? 0 : this.datos.Raw.numberRoyals;
      this.datos.Raw.totalCOGS = isNaN(this.datos.Raw.totalCOGS) || !isFinite(this.datos.Raw.totalCOGS) ? 0 : this.datos.Raw.totalCOGS;
      this.datos.Raw.contribution = isNaN(this.datos.Raw.contribution) || !isFinite(this.datos.Raw.contribution) ? 0 : this.datos.Raw.contribution;
      this.datos.Raw.factoryOverhead = isNaN(this.datos.Raw.factoryOverhead) || !isFinite(this.datos.Raw.factoryOverhead) ? 0 : this.datos.Raw.factoryOverhead;
      this.datos.Raw.SGA = isNaN(this.datos.Raw.SGA) || !isFinite(this.datos.Raw.SGA) ? 0 : this.datos.Raw.SGA;
      this.datos.Raw.totalFiexed = isNaN(this.datos.Raw.totalFiexed) || !isFinite(this.datos.Raw.totalFiexed) ? 0 : this.datos.Raw.totalFiexed;
      this.datos.Raw.netIncome = isNaN(this.datos.Raw.netIncome) || !isFinite(this.datos.Raw.netIncome) ? 0 : this.datos.Raw.netIncome;

      this.datos.PorCiento.Finance = isNaN(this.datos.PorCiento.Finance) || !isFinite(this.datos.PorCiento.Finance) ? 0 : this.datos.PorCiento.Finance;
      this.datos.PorCiento.priceRoyal = isNaN(this.datos.PorCiento.priceRoyal) || !isFinite(this.datos.PorCiento.priceRoyal) ? 0 : this.datos.PorCiento.priceRoyal;
      this.datos.PorCiento.costRoyal = isNaN(this.datos.PorCiento.costRoyal) || !isFinite(this.datos.PorCiento.costRoyal) ? 0 : this.datos.PorCiento.costRoyal;
      this.datos.PorCiento.totalSale = isNaN(this.datos.PorCiento.totalSale) || !isFinite(this.datos.PorCiento.totalSale) ? 0 : this.datos.PorCiento.totalSale;
      this.datos.PorCiento.numberRoyals = isNaN(this.datos.PorCiento.numberRoyals) || !isFinite(this.datos.PorCiento.numberRoyals) ? 0 : this.datos.PorCiento.numberRoyals;
      this.datos.PorCiento.totalCOGS = isNaN(this.datos.PorCiento.totalCOGS) || !isFinite(this.datos.PorCiento.totalCOGS) ? 0 : this.datos.PorCiento.totalCOGS;
      this.datos.PorCiento.contribution = isNaN(this.datos.PorCiento.contribution) || !isFinite(this.datos.PorCiento.contribution) ? 0 : this.datos.PorCiento.contribution;
      this.datos.PorCiento.factoryOverhead = isNaN(this.datos.PorCiento.factoryOverhead) || !isFinite(this.datos.PorCiento.factoryOverhead) ? 0 : this.datos.PorCiento.factoryOverhead;
      this.datos.PorCiento.SGA = isNaN(this.datos.PorCiento.SGA) || !isFinite(this.datos.PorCiento.SGA) ? 0 : this.datos.PorCiento.SGA;
      this.datos.PorCiento.totalFiexed = isNaN(this.datos.PorCiento.totalFiexed) || !isFinite(this.datos.PorCiento.totalFiexed) ? 0 : this.datos.PorCiento.totalFiexed;
      this.datos.PorCiento.netIncome = isNaN(this.datos.PorCiento.netIncome) || !isFinite(this.datos.PorCiento.netIncome) ? 0 : this.datos.PorCiento.netIncome;
      console.log(this.datos.PorCiento)

      }     
      /*this.datos.PorCiento.netIncome = Math.round(Number (((this.datos.Raw.netIncome / this.datos.Target.netIncome)).toFixed(1)));*/
    }
  }

  fnChangeInput(value, id, i){
    // this.balanceIncomeService.updateInputIncomeStatement(value, id, 'room'+this.data.teamId,i);
  }
  fnResultado(resultado){
    // switch (resultado) {
    //   case 1:
        this.datos.topDownApproch.priceRoyal = Number((this.datos.topDownApproch.totalSale / (this.datos.topDownApproch.numberRoyals != 0 ? this.datos.topDownApproch.numberRoyals : 1)).toFixed(1));
      //   break;
      // case 2:
        this.datos.topDownApproch.costRoyal = Number((this.datos.topDownApproch.totalCOGS / (this.datos.topDownApproch.numberRoyals != 0 ? this.datos.topDownApproch.numberRoyals : 1)).toFixed(1));
        this.datos.topDownApproch.contribution = this.datos.topDownApproch.totalSale - this.datos.topDownApproch.totalCOGS;
        //validacion de eliza, si el resultado es cero en contribution de topDownApproch no ponemos nada
        this.datos.topDownApproch.contribution = this.datos.topDownApproch.contribution == 0 ? null : this.datos.topDownApproch.contribution;
        // ahora se calcula el costo por royal
        // this.datos.bottomUpApproch.totalCOGS = this.datos.bottomUpApproch.costRoyal * this.datos.bottomUpApproch.numberRoyals;
        this.datos.bottomUpApproch.costRoyal = Number((this.datos.bottomUpApproch.totalCOGS / this.datos.bottomUpApproch.numberRoyals).toFixed(1));
        this.datos.bottomUpApproch.totalSale = this.datos.bottomUpApproch.contribution + this.datos.bottomUpApproch.totalCOGS;
        //validacion de eliza, si el resultado es cero en totalSale de bottomUpApproch no ponemos nada
        this.datos.bottomUpApproch.totalSale = this.datos.bottomUpApproch.totalSale == 0 ? null : this.datos.bottomUpApproch.totalSale;
        this.datos.bottomUpApproch.priceRoyal = Number((this.datos.bottomUpApproch.totalSale / (this.datos.bottomUpApproch.numberRoyals != 0 ? this.datos.bottomUpApproch.numberRoyals : 1)).toFixed(1));
        // ahora se calcula el costo por royal
        // this.datos.Target.totalCOGS = this.datos.Target.costRoyal * this.datos.Target.numberRoyals;
        this.datos.Target.costRoyal = Number((this.datos.Target.totalCOGS / this.datos.Target.numberRoyals).toFixed(1));
        this.datos.Target.totalSale = this.datos.Target.contribution + this.datos.Target.totalCOGS;
        //validacion de eliza, si el resultado es cero en totalSale de Target no ponemos nada
        this.datos.Target.totalSale = this.datos.Target.totalSale == 0 ? null : this.datos.Target.totalSale;
        this.datos.Target.priceRoyal = Number((this.datos.Target.totalSale / (this.datos.Target.numberRoyals != 0 ? this.datos.Target.numberRoyals : 1)).toFixed(1));

      //   break;
      // case 3:
        this.datos.topDownApproch.totalFiexed = 
          this.datos.topDownApproch.factoryOverhead +
          this.datos.topDownApproch.SGA +
          this.datos.topDownApproch.Finance;
          //validacion de eliza, si el resultado es cero en totalFiexed de topDownApproch no ponemos nada
          this.datos.topDownApproch.totalFiexed = this.datos.topDownApproch.totalFiexed == 0 ? null : this.datos.topDownApproch.totalFiexed;
        this.datos.topDownApproch.netIncome = 
          this.datos.topDownApproch.contribution -
          this.datos.topDownApproch.totalFiexed;
        this.datos.bottomUpApproch.totalFiexed = 
          this.datos.bottomUpApproch.factoryOverhead +
          this.datos.bottomUpApproch.SGA +
          this.datos.bottomUpApproch.Finance;
          //validacion de eliza, si el resultado es cero en total fixed de bottomUp no ponemos nada
          this.datos.bottomUpApproch.totalFiexed = this.datos.bottomUpApproch.totalFiexed == 0 ? null : this.datos.bottomUpApproch.totalFiexed;
        this.datos.bottomUpApproch.contribution =
          this.datos.bottomUpApproch.netIncome +
          this.datos.bottomUpApproch.totalFiexed;
          //validacion de eliza, si el resultado es cero en contribution de bottomUpApproch no ponemos nada
          this.datos.bottomUpApproch.contribution = this.datos.bottomUpApproch.contribution == 0 ? null : this.datos.bottomUpApproch.contribution;
        this.datos.Target.totalFiexed = 
          this.datos.Target.factoryOverhead +
          this.datos.Target.SGA +
          this.datos.Target.Finance;
          //validacion de eliza, si el resultado es cero en total fixed de bottomUp no ponemos nada
          this.datos.Target.totalFiexed = this.datos.Target.totalFiexed == 0 ? null : this.datos.Target.totalFiexed;
        this.datos.Target.contribution =
          this.datos.Target.netIncome +
          this.datos.Target.totalFiexed;
          //validacion de eliza, si el resultado es cero en contribution de Target no ponemos nada
          this.datos.Target.contribution = this.datos.Target.contribution == 0 ? null : this.datos.Target.contribution;
      //   break;
      // case 90:
      if(resultado == 90){
        this.llenarDatos();
        this.exerciseService.notifyFacilitatorBudget(1,this.teamId)
      }
    //     break;
    //   default:
    //     break;
    // }

  }
  updateInput(column, renglon, resultado){
    this.exerciseService.updateInputBudget(column, renglon, this.datos[column][renglon], resultado, 'room'+this.teamId, this.teamId);
    console.log(this.datos.Target)
    console.log(this.datos.topDownApproch)
    console.log(this.datos.bottomUpApproch) 
    // console.log(this.datos.Target.isComplete())
    // console.log(this.datos.topDownApproch.isComplete())
    // console.log(this.datos.bottomUpApproch.isComplete())
    if(resultado != -1){
      this.fnResultado(resultado);
    }

      console.log("mandar datos, cambiar estado en tabla teams, mandar socket a facilitador");
      this.exerciseService.saveQuantitiesTargetColumn(this.datos.Target, this.teamId, this.mes, false).subscribe(response => {
        console.log(response);
      });

      console.log("mandar datos, cambiar estado en tabla teams, mandar socket a facilitador");
      this.exerciseService.saveQuantitiesbottomUpColumn(this.datos.bottomUpApproch, this.teamId, this.mes).subscribe(response => {
        console.log(response);
      });
    
      console.log("mandar datos, cambiar estado en tabla teams, mandar socket a facilitador");
      this.exerciseService.saveQuantitiestopDownColumn(this.datos.topDownApproch, this.teamId, this.mes).subscribe(response => {
        console.log(response);
      });
    
  }

  fnCloseModal() {
    // Esto funciona pero es guardarlo 2 veces 
    this.exerciseService.saveQuantitiesTargetColumn(this.datos.Target, this.teamId, this.mes, true).subscribe(response => {
      console.log(response);
      this.matDialogRef.close({
        status: 'cancel'
      })
    });

  }

  getSingleTextLabel(labelNumber: string): string {
    const tmp = this.data.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    return tmp ? tmp.Label : '';
  }
  async fnChangeMonthHistoric(view: string) {
    this.monthHistoric = parseInt(view.slice(5));
    console.log(this.monthHistoric)
    const responseData = await this.exerciseService.getSaveQuantitiesTargetColumn(this.teamId, this.monthHistoric);
    console.log(responseData)
    if (responseData.intResponse == 200 && responseData.data != null) {
      let aux = new ModelColumnBudget();
      aux.decode(responseData.data);
      this.datos.Target = aux;
    } else {
      let data = {
        "priceRoyal": null,
        "numberRoyals": null,
        "totalSale": null,
        "costRoyal": null,
        "totalCOGS": null,
        "contribution": null,
        "factoryOverhead": null,
        "SGA": null,
        "Finance": null,
        "totalFiexed": null,
        "netIncome": null,
      }
      let aux = new ModelColumnBudget();
      aux.decode(data);
      this.datos.Target = aux;
    }
    //Get top
    const responseDataTop = await this.exerciseService.getSaveQuantitiesTopColumn(this.teamId, this.monthHistoric);
    console.log(responseDataTop)
    if (responseDataTop.intResponse == 200 && responseDataTop.data != null) {
      let aux = new ModelColumnBudget();
      aux.decode(responseDataTop.data);
      this.datos.topDownApproch = aux;
    } else {
      let data = {
        "priceRoyal": null,
        "numberRoyals": null,
        "totalSale": null,
        "costRoyal": null,
        "totalCOGS": null,
        "contribution": null,
        "factoryOverhead": null,
        "SGA": null,
        "Finance": null,
        "totalFiexed": null,
        "netIncome": null,
      }
      let aux = new ModelColumnBudget();
      aux.decode(data);
      this.datos.topDownApproch = aux;
    }
    //get bot
    const responseDataBot = await this.exerciseService.getSaveQuantitiesBotColumn(this.teamId, this.monthHistoric);
    console.log(responseDataBot)
    if (responseDataBot.intResponse == 200 && responseDataBot.data != null) {
      let aux = new ModelColumnBudget();
      aux.decode(responseDataBot.data);
      this.datos.bottomUpApproch = aux;
    } else {
      let data = {
        "priceRoyal": null,
        "numberRoyals": null,
        "totalSale": null,
        "costRoyal": null,
        "totalCOGS": null,
        "contribution": null,
        "factoryOverhead": null,
        "SGA": null,
        "Finance": null,
        "totalFiexed": null,
        "netIncome": null,
      }
      let aux = new ModelColumnBudget();
      aux.decode(data);
      this.datos.bottomUpApproch = aux;
    }
    let data = {
      "priceRoyal": null,
      "numberRoyals": null,
      "totalSale": null,
      "costRoyal": null,
      "totalCOGS": null,
      "contribution": null,
      "factoryOverhead": null,
      "SGA": null,
      "Finance": null,
      "totalFiexed": null,
      "netIncome": null,
    }
    let aux = new ModelColumnBudget();
    let aux1 = new ModelColumnBudget();
    let aux2 = new ModelColumnBudget();
    aux.decode(data);
    aux1.decode(data);
    aux2.decode(data);
    this.datos.Raw = aux;
    this.datos.PorCiento = aux1;
    this.datos.Actual = aux2;
    console.log(this.monthHistoric, this.mes)
    if(this.BudgetLock == 0){
      if (this.monthHistoric < this.mes) {
        this.canEditTarget = false
        console.log("No")
      } else {
        this.canEditTarget = true
  
      }
    }
    const x = await this.obtenerDatosIncomestatement();
    setTimeout(() => {
      this.llenarDatos()

    }, 800);
  }
}